import { Injectable } from "@angular/core";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ImportError } from "@bitwarden/common/importers/import-error";
import { TransactionCsvImporter } from "@bitwarden/web-vault/app/importers/transaction-csv-importer";
import { TransactionImporter } from "@bitwarden/web-vault/app/importers/transaction-importer";
import { TransactionPlaidImporter } from "@bitwarden/web-vault/app/importers/transaction-plaid-importer";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionImportType } from "@bitwarden/web-vault/app/models/enum/transactionImportType";
import { UnrecognizedAccount } from "@bitwarden/web-vault/app/models/types/account.types";
import { MarketDataService } from "@bitwarden/web-vault/app/services/DataService/market-data/market-data.service";
import { SourceImportService } from "@bitwarden/web-vault/app/services/DataService/source-import/source-import.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";

@Injectable({
  providedIn: "root",
})
export class TransactionImportService {
  constructor(
    private i18nService: I18nService,
    private transactionService: TransactionService,
    private marketDataService: MarketDataService,
    private sourceImportService: SourceImportService,
  ) {}

  async import(rawTransactions: any[], newAccounts: UnrecognizedAccount[]): Promise<ImportError> {
    if (rawTransactions.length === 0) {
      return new ImportError(this.i18nService.t("importNothingError"));
    }

    const categorisedTransactions = await this.transactionService.createCategoriesFromTransaction(
      DataTransformer.castToTransactionArray(rawTransactions),
    );

    const classifiedTransactions =
      await this.transactionService.createClassificationsFromTransaction(categorisedTransactions);

    let finalisedTransactions: Transaction[] = classifiedTransactions;
    if (newAccounts.length > 0) {
      finalisedTransactions = await this.transactionService.createAccountsFromTransaction(
        classifiedTransactions,
        newAccounts,
      );
    }

    /**
     * change source transactions' ids, so they do not have the same ids as transactions.
     * set transactions' sourceIds respectfully
     * */
    rawTransactions.forEach((row, index) => {
      row._id = crypto.randomUUID();
      finalisedTransactions[index].sourceId = row._id;
    });
    await this.sourceImportService.processSourceImport(
      DataTransformer.castToSourceTransactionArray(rawTransactions),
    );

    await this.marketDataService.refreshMarketData(finalisedTransactions);

    await this.transactionService.importGeneratedMarketData(finalisedTransactions);
    await this.transactionService.import(finalisedTransactions);
  }

  getImporter(format: TransactionImportType): TransactionImporter {
    const importer = this.getImporterInstance(format);
    if (importer == null) {
      return null;
    }
    return importer;
  }

  private getImporterInstance(format: TransactionImportType) {
    if (format == null) {
      return null;
    }

    switch (format) {
      case TransactionImportType.transactioncsv:
        return new TransactionCsvImporter();
      case TransactionImportType.plaid:
        return new TransactionPlaidImporter();
      default:
        return new TransactionCsvImporter();
    }
  }
}

import { inject, Injector } from "@angular/core";

import { _getAppliedInterest } from "@bitwarden/web-vault/app/components/best-scenario-dialog/best-scenario.utils";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { IBestScenarioStep } from "@bitwarden/web-vault/app/models/interfaces/stepper.interface";
import {
  ScenarioAccountInfo,
  ScenarioData,
  ScenarioHelpInfo,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { ExistingAccountInfo } from "@bitwarden/web-vault/app/models/types/stepper.types";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

import { BaseStep } from "../stepper/base-step";
import { InstitutionInterestView } from "@bitwarden/web-vault/app/models/view/institution/institution-interest.view";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { DataPluginStoreService } from "@bitwarden/web-vault/app/services/store/data-plugin/data-plugin.store.service";
import { ScenarioDisplayView } from "@bitwarden/web-vault/app/models/view/scenario/scenario-display.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";

const BEST_SCENARIO_INDEX = 2;
const EXISTING_BEST_SCENARIO_INDEX = 1;
export abstract class BestScenarioStepper extends BaseStep implements IBestScenarioStep {
  private dataPluginStoreService: DataPluginStoreService = inject(DataPluginStoreService);
  private userStore: UserStoreService = inject(UserStoreService);

  nextBtnOptions = new ActionButton({
    text: this.i18n.t("continueToNextStep"),
    type: "primary",
    testId: "stepperNextButton",
  });

  prevBtnOptions: ActionButton = null;
  bestAccountInfo: ScenarioAccountInfo;
  earningAmount: number;
  helpInfo: ScenarioHelpInfo;
  rateInUse: InstitutionInterestView;
  // scenarioData: ScenarioData;
  scenarioDisplayView: ScenarioDisplayView[];
  // scenarioLastDay: string;
  scenarioSymbol: string;
  existingAccounts: ExistingAccountInfo[];
  bestExistingGroupedBalance: BalanceGrouping;
  bestGroupedBalance: BalanceGrouping;

  protected constructor(injector: Injector) {
    super(injector);
  }

  async setUpStep() {
    if (!this.scenarioDisplayView) {
      throw new Error("Scenario display view is not set");
    }

    this.helpInfo = this.scenarioDisplayView[BEST_SCENARIO_INDEX].helpInfo;
    // this.bestExistingGroupedBalance =
    //  this.scenarioData.scenario[EXISTING_BEST_SCENARIO_INDEX].groupedBalance;
    // this.bestGroupedBalance = this.scenarioData.scenario[BEST_SCENARIO_INDEX].groupedBalance;
    this.bestAccountInfo = this.helpInfo.transferToAccount;
    this.scenarioSymbol = this.helpInfo.symbol;
    // this.scenarioLastDay = this.getScenarioLastDay();
    this.earningAmount = this.getEarningAmount();
    this.rateInUse = this.getRateInUse();
    this.existingAccounts = await this.getExistingAccounts();
  }

  /*
  getScenarioLastDay(): string {
    return _getLastDayBalanceKey(this.scenarioData.scenario[BEST_SCENARIO_INDEX].finalBalanceAmount);
  }

   */

  getEarningAmount(): number {
    /*
    const path2LastDayGroupedBalance =
      this.bestExistingGroupedBalance.granularity["day"][this.scenarioLastDay].balance;
    const path2EndBalance =
      path2LastDayGroupedBalance.runningTotalValue[this.scenarioSymbol].symbolAmount.amount;

    const path3LastDayGroupedBalance =
      this.bestGroupedBalance.granularity["day"][this.scenarioLastDay].balance;
    const path3EndBalance =
      path3LastDayGroupedBalance.runningTotalValue[this.scenarioSymbol].symbolAmount.amount;
     */

    const path2EndBalance =
      this.scenarioDisplayView[EXISTING_BEST_SCENARIO_INDEX].finalBalanceAmount;
    const path3EndBalance = this.scenarioDisplayView[BEST_SCENARIO_INDEX].finalBalanceAmount;

    return path3EndBalance - path2EndBalance;
  }

  getRateInUse(): InstitutionInterestView {
    return _getAppliedInterest(this.bestAccountInfo.rate, this.helpInfo.transferAmount);
  }

  async getExistingAccounts(): Promise<ExistingAccountInfo[]> {
    const existingAccountInfos = [];
    const transactions = this.scenarioDisplayView[BEST_SCENARIO_INDEX].createdRecords.transactions;

    for (const transaction of transactions) {
      if (transaction.description === "Mock transfer : Out") {
        const accountInfo = await this.getExistingAccountInfo(transaction);
        existingAccountInfos.push(accountInfo);
      }
    }

    return existingAccountInfos;
  }

  private async getExistingAccountInfo(transaction: TransactionView): Promise<ExistingAccountInfo> {
    const amount = transaction.quantity.actualQuantity.amount;
    const account = this.getAccount(transaction);
    const interestRates = await this.getInterestRates(account);
    const appliedInterest = _getAppliedInterest(interestRates, amount);
    return {
      account,
      balance: amount,
      appliedInterest,
      displayName: `${account.name}(${appliedInterest.rate}% P.A.)`,
    };
  }

  private async getInterestRates(account: AccountView): Promise<InstitutionInterestView[]> {
    const institution = this.getInstitutionById(account.institutionLink.institutionId);
    return institution.availableAccounts.find(
      (acc) => acc.id === account.institutionLink.institutionAccountId,
    ).interestRates;
  }

  private getInstitutionById(institutionId: string) {
    const institutions = this.dataPluginStoreService.theInstitutionList.theInstitutionViews();
    return institutions?.find((i) => i.id === institutionId);
  }

  private getAccount(transaction: TransactionView): AccountView {
    return this.getAccountById(transaction.accountLink.id);
  }

  private getAccountById(id: string): AccountView {
    const accountViews = this.userStore.accounts.accountViews();

    for (const accountView of accountViews) {
      if (accountView.id === id) {
        return accountView;
      }
    }
    return;
  }
}

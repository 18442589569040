export type UserLocationType = "AU" | "HK" | "NZ" | "US" | "EU" | "JP" | "CN";
export type CurrencyType = "AUD" | "HKD" | "NZD" | "USD" | "EUR" | "JPY" | "CNY";

/** This control the full list in the system, or default to DefaultSystemCurrency if not found ( need to match with what the scope and claim forex return **/
export const AllowedCurrencies: CurrencyType[] = [
  "AUD",
  "HKD",
  "NZD",
  "USD",
  "EUR",
  "CNY",
  "JPY",
] as const;

/** This control the current list of available location to chose from. This should be a subset of UserLocationType **/
export const AllowedLocations: UserLocationType[] = ["AU", "HK"] as const;

/** System default **/
export const DefaultSystemCurrency: CurrencyType = "AUD" as const;
export const DefaultSystemLocation: UserLocationType = "AU" as const;

export const DefaultCurrencyPerLocation: Record<UserLocationType, CurrencyType> = {
  AU: "AUD",
  HK: "HKD",
  NZ: "NZD",
  US: "USD",
  EU: "EUR",
  JP: "JPY",
  CN: "CNY",
} as const;

export const DefaultLocationPerCurrency: Record<CurrencyType, UserLocationType> = {
  ...Object.entries(DefaultCurrencyPerLocation).reduce(
    (acc, pair) => {
      acc[pair[1]] = pair[0] as UserLocationType;
      return acc;
    },
    {} as Record<CurrencyType, UserLocationType>,
  ),
};

<div class="content tw-flex tw-flex-col">
  <div class="tw-flex tw-justify-end tw-items-center tw-px-3 tw-w-full sm:tw-px-0">
    <div class="tw-flex tw-items-center tw-justify-end tw-w-full tw-gap-2">
      <app-gloss-button
        class="tw-w-40 sm:tw-w-full tw-mr-2"
        [options]="{
          text: 'syncAll' | i18n,
          class: 'neutral',
          isEnabled: true,
          icon: 'sync-all',
          onClick: fullSync.bind(this),
        }"
        *appRoleScope="'DATA_AGGREGATOR'"
      ></app-gloss-button>
      <app-gloss-button
        class="tw-w-40 sm:tw-w-full"
        [options]="{
          text: 'addNew' | i18n,
          class: 'neutral',
          isEnabled: true,
          icon: 'add',
          onClick: openAccountCreateModal.bind(this),
        }"
      ></app-gloss-button>
    </div>
  </div>

  <div class="tw-flex tw-justify-between tw-w-full">
    <div
      id="accounts-sync-table"
      [ngClass]="{
        'details-section-desktop': !isMobile(),
        'details-section-mobile': isMobile(),
      }"
    >
      <div class="tw-m-3">
        <button
          class="tw-flex tw-items-center tw-gap-1 tw-px-2 tw-border tw-border-solid tw-border-neutral-100 tw-rounded-lg"
          (click)="onFilterClick()"
          (mouseenter)="onMouseEnter()"
          (mouseleave)="onMouseLeave()"
        >
          <span class="tw-text-sm tw-text-neutral-800 tw-font-medium">Add filter</span>
          <span class="tw-mr-1" *ngIf="filterCount > 0">({{ filterCount }})</span>
          <icon [name]="'filter'" [size]="4"></icon>
        </button>
        <div
          class="tw-inline-block tw-shadow-md tw-border tw-border-solid tw-border-neutral-100 tw-rounded-lg tw-absolute tw-z-10 tw-mt-1 tw-select-none"
          *ngIf="showFilterDropdown"
          (mouseenter)="onMouseEnter()"
          (mouseleave)="onMouseLeave()"
        >
          <div
            class="tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-3 tw-bg-white hover:tw-bg-tealgray-100 tw-rounded-lg"
            *ngFor="let type of accountTypes"
          >
            <input
              type="checkbox"
              [id]="type"
              [checked]="selectedFilter[type]"
              class="tw-h-5 tw-w-5 tw-border tw-rounded tw-shadow-inner tw-cursor-pointer accounts-checkbox"
              (change)="onAccountTypeFilterChange(type, $event)"
            />
            <label class="tw-text-sm tw-text-neutral-800 tw-capitalize" [for]="type"
              >{{ type }} Accounts</label
            >
          </div>
        </div>
      </div>

      <app-ag-grid-table
        [options]="table?.options"
        [data]="filteredData"
        [removeBottomBorder]="true"
        [removeTopBorder]="true"
      />
    </div>

    @if (table.accountOnDetail()) {
      <div
        [ngClass]="{
          'details-section-desktop': !isMobile(),
          'details-section-mobile': isMobile(),
        }"
      >
        <app-account-details
          [account]="table.accountOnDetail()"
          (cancel)="closeAccountDetails()"
        ></app-account-details>
      </div>
    }
  </div>
</div>

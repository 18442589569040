import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-symbol-country-renderer",
  templateUrl: "./symbol-country-renderer.component.html",
})
export class SymbolCountryRendererComponent implements ICellRendererAngularComp {
  country: string = "";

  agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  setData(params: ICellRendererParams): void {
    const countryCode = params.data?.country?.toLowerCase() || "";
    this.country = countryCode;
  }

  refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }
}

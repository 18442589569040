import * as d3 from "d3";

import { DateTimeFormatter } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/date-time-formatter";
import { ParsedDates, GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import { ScenarioData } from "@bitwarden/web-vault/app/models/types/scenario-group.types";

export class Scales {
  private graphContentGroup: any;
  private dateTimeFormatter: DateTimeFormatter;
  xScale: d3.ScaleBand<string>;
  yScale: d3.ScaleLinear<number, number, never>;

  constructor(graphContentGroup: any, dateTimeFormatter: DateTimeFormatter) {
    this.graphContentGroup = graphContentGroup;
    this.dateTimeFormatter = dateTimeFormatter;

    this.xScale = d3.scaleBand();
    this.yScale = d3.scaleLinear();
  }

  setScales(
    innerWidth: number,
    innerHeight: number,
    minYAxisValue: number,
    maxYAxisValue: number,
    parsedDates: ParsedDates,
    graphData: GraphDataSet[],
    scenarioData: ScenarioData,
  ) {
    this.xScale
      // adjust to the available screen area
      .rangeRound([0, innerWidth])
      // adjust to the range of our data
      // TODO:  will need to update this when we implement granularity properly
      //        for now default to month
      .domain(parsedDates.map((d) => d.mY));

    if (graphData.length === 1 && scenarioData.scenario.length === 0) {
      // Handling case with only one data point
      const balance = graphData[0].balance;
      this.yScale.rangeRound([innerHeight, 0]).domain([0, balance + 0.1 * balance]);
    } else {
      this.setYScales(innerHeight, minYAxisValue, maxYAxisValue);
    }
  }

  setYScales(innerHeight: number, minYAxisValue: number, maxYAxisValue: number) {
    const yRange = maxYAxisValue - minYAxisValue;
    this.yScale
      // adjust to the available screen area
      .rangeRound([innerHeight, 0])
      // adjust to the available screen area
      // add a bit of percentage padding to either end
      .domain([minYAxisValue - yRange * 0.1, maxYAxisValue + yRange * 0.05]);
  }
}

import { Component, Input } from "@angular/core";
import { GridOptions } from "ag-grid-enterprise";

@Component({
  selector: "app-ag-grid-table",
  templateUrl: "./ag-grid-table.component.html",
})
export class AgGridTableComponent {
  @Input() options!: GridOptions;
  /** @Sinan implement a type for the data prop */
  @Input() data!: any;
  @Input() showHeader: boolean = false;
  @Input() removeBottomBorder: boolean = false;
  @Input() removeTopBorder: boolean = false;

  get gridOptions(): GridOptions {
    return {
      ...this.options,
      headerHeight: this.showHeader ? undefined : 0,
      getRowStyle: () => (this.removeBottomBorder ? { border: "none" } : {}),
    };
  }
}

import { Component, input, effect, inject, computed } from "@angular/core";
import { ScenarioStoreService } from "@bitwarden/web-vault/app/services/store/scenario/scenario.store.service";
import { ScenarioPaths } from "@bitwarden/web-vault/app/models/enum/scenario.types";
import { ViewsService } from "@bitwarden/web-vault/app/services/views/views.service";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { HelperCollapse } from "../../../../shared/utils/helper-collapse";
import { getName } from "../../../../shared/utils/helper-string";
import { HelperExpand } from "../../../../shared/utils/helper-expand";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";

@Component({
  selector: "app-scenario-hud",
  templateUrl: "./scenario-hud.component.html",
})
export class ScenarioHudComponent {
  scenarioStoreService = inject(ScenarioStoreService);
  viewService = inject(ViewsService);
  protected helperCollapse: HelperCollapse[];
  protected helperExpand: HelperExpand;
  private userStoreService = inject(UserStoreService);

  hudIndex = input<number>();
  selectedPath = this.viewService.currentScenarioType;
  selectedPath$ = toObservable(this.selectedPath);
  granularity = this.scenarioStoreService.grouping.granularity;

  timeStamp = computed(() => {
    const selectedPathIndex = ScenarioPaths[this.selectedPath()];

    if (this.scenarioStoreService.balanceByTime.balances()) {
      if (
        selectedPathIndex !== undefined &&
        this.scenarioStoreService.balanceByTime.balances()?.[selectedPathIndex]
      ) {
        const balanceByTime = this.scenarioStoreService.balanceByTime.balances();
        const selectedBalance = balanceByTime[selectedPathIndex];
        const keys = [...selectedBalance.keys()];
        return keys[this.hudIndex()];
      }
    }
  });

  balanceDate = computed(() => {
    if (this.timeStamp()) {
      return new Date(Number(this.timeStamp()) * 1000);
    }
  });

  balance = computed(() => {
    const selectedPathIndex = ScenarioPaths[this.selectedPath()];
    const balanceByTime = this.scenarioStoreService.balanceByTime.balances();
    const selectedBalance = balanceByTime[selectedPathIndex];
    return selectedBalance.get(this.timeStamp());
  });

  pathBalances = computed(() => {
    const pathBalances = [];
    for (const balance of this.scenarioStoreService.balanceByTime.balances()) {
      pathBalances.push(balance.get(this.timeStamp()));
    }
    return pathBalances;
  });

  pathBalanceAccounts = computed(() => {
    const pathBalanceAccounts: { account: string; balance: number; accountID: string }[][] = [];

    if (this.scenarioStoreService.balanceByTimeAndAccounts.balances()) {
      for (const balances of this.scenarioStoreService.balanceByTimeAndAccounts.balances()) {
        const accountBalances = balances.get(this.timeStamp());
        const accountBalancesDisplay: { account: string; balance: number; accountID: string }[] =
          [];
        accountBalances?.forEach((amount, accountID) => {
          accountBalancesDisplay.push(this.getContext(accountID, amount));
        });
        pathBalanceAccounts.push(accountBalancesDisplay);
      }
    }
    return pathBalanceAccounts;
  });

  constructor() {
    this.helperCollapse = Array.from({ length: 3 }, () => new HelperCollapse());
    this.helperExpand = new HelperExpand();

    this.selectedPath$.pipe(takeUntilDestroyed()).subscribe(() => {
      const selectedPathIndex = ScenarioPaths[this.selectedPath()];
      this.resetAllCollapse();
      this.helperCollapse[selectedPathIndex].isCollapsed =
        !this.helperCollapse[selectedPathIndex].isCollapsed;
    });
  }

  getContext(accountID: string, amount: number) {
    const accountName =
      getName(this.userStoreService.accounts.accountViews(), accountID) ??
      getName(
        this.scenarioStoreService.interestScenario.bestScenario().createdRecords.accounts,
        accountID,
      );

    return {
      accountID: accountID,
      account: accountName,
      balance: amount,
    };
  }

  handleExpand(event: any) {
    this.helperExpand.toggleContent(event);
    this.resetAllCollapse();
  }

  resetExpanded() {
    const isAllUncollapsed = this.helperCollapse.every(
      (helper: HelperCollapse) => !helper.isCollapsed,
    );

    if (isAllUncollapsed) {
      this.helperExpand.setExpanded(true);
    }
  }

  handleCollapse(event: any, index: number) {
    this.helperCollapse[index].toggleContent(event);
    this.resetExpanded();
  }

  resetAllCollapse() {
    this.helperCollapse.forEach(
      (helper: HelperCollapse) => (helper.isCollapsed = !this.helperExpand.isExpanded),
    );
  }
}

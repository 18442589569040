<div class="content">
  <div class="tw-mb-5 tw-flex tw-justify-end">
    <app-gloss-button [options]="uploadNewFileOptions" class="sm:tw-w-full"></app-gloss-button>
  </div>

  <div *ngIf="vaultFiles" class="table-container tw-mb-[100px] tw-flex tw-h-[500px] tw-flex-col">
    <app-spinner [showSpinner]="isDeleting" class="tw-m-auto"></app-spinner>
    <ag-grid-angular
      class="ag-theme-quartz"
      style="height: 100%"
      [columnDefs]="colDefs"
      [rowData]="vaultFileGrid()"
      [autoSizeStrategy]="autoSizeStrategy"
      [getContextMenuItems]="getContextMenuItems.bind(this)"
      (gridReady)="onGridReady($event)"
      (cellClicked)="onCellClicked($event)"
    ></ag-grid-angular>
  </div>
</div>

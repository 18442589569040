import {
  dayGranularity,
  GranularityProperty,
  monthGranularity,
  quarterGranularity,
  weekGranularity,
  yearGranularity,
} from "../../../../models/types/balanceGroupingTypes";
import { GranularityBucketKeyFormat } from "../../../../models/enum/granularityBucketKeysDateFormatEnum";
import {
  addDays,
  eachDayOfInterval,
  format as dateFnsFormat,
  getDate,
  getMonth,
  setMonth,
} from "date-fns";
import { UserStoreService } from "../../user/user.store.service";

export class BalanceDatesUtils {
  /**
   * Return a list of dates that we would require balances for given a start and end date
   * and the required granularity
   *  - start date - start of day
   *  - end date - end of day
   *  - end of each granularity period
   *  - today's date - end of day (selected in the today view or current date)
   *
   * @param startDate
   * @param endDate
   * @param granularity
   * @param userStore
   * @param otherDates - optional list of other dates to include in the list
   */
  static getBalanceDates(
    startDate: Date,
    endDate: Date,
    granularity: GranularityProperty,
    userStore: UserStoreService,
    otherDates?: Array<number>,
  ): Array<number> {
    let balanceDates: Array<number> = [];

    const fullDateInterval = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    balanceDates.push(BalanceDatesUtils.getEndOfDayTimeStamp(startDate));
    let previousGrouping = BalanceDatesUtils.getDateGroupingKey(startDate, granularity, userStore);
    let previousDate = startDate;

    for (const candidateDate of fullDateInterval) {
      const candidateGrouping = BalanceDatesUtils.getDateGroupingKey(
        candidateDate,
        granularity,
        userStore,
      );
      if (candidateGrouping !== previousGrouping) {
        balanceDates.push(BalanceDatesUtils.getEndOfDayTimeStamp(previousDate));
        previousGrouping = candidateGrouping;
      }
      previousDate = candidateDate;
    }
    balanceDates.push(BalanceDatesUtils.getEndOfDayTimeStamp(previousDate));

    if (otherDates) {
      balanceDates = balanceDates.concat(otherDates);
    }

    // remove any duplicates from the array
    balanceDates = Array.from(new Set(balanceDates));

    // sort the array by dates
    balanceDates.sort();

    return balanceDates;
  }

  static getDateGroupingKey(date: Date, granularity: string, userStore: UserStoreService): string {
    let weekStartDay = 0;
    let monthStartDay = 1;
    if (userStore?.preferences?.preferenceView()?.weekDayStart !== undefined) {
      const weekDayPref = userStore.preferences.preferenceView().weekDayStart;
      for (const day of Object.keys(weekDayPref)) {
        weekStartDay = weekDayPref[day];
      }
    }
    if (userStore?.preferences?.preferenceView()?.monthDayStart !== undefined) {
      monthStartDay = userStore.preferences.preferenceView().monthDayStart;
    }

    let result: string;
    if (granularity === dayGranularity) {
      result = dateFnsFormat(date, GranularityBucketKeyFormat.day);
    } else if (granularity === weekGranularity) {
      result = dateFnsFormat(date, GranularityBucketKeyFormat.week, {
        weekStartsOn: weekStartDay as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      });
    } else if (granularity === monthGranularity) {
      let periodDateStart = date;
      // todo test this logic
      if (getDate(date) < monthStartDay) {
        periodDateStart = setMonth(date, getMonth(date) - 1);
      }
      result = dateFnsFormat(periodDateStart, GranularityBucketKeyFormat.month);
    } else if (granularity === quarterGranularity) {
      result = dateFnsFormat(date, GranularityBucketKeyFormat.quarter);
    } else if (granularity === yearGranularity) {
      // todo manage this._YearMonthStart
      result = dateFnsFormat(date, GranularityBucketKeyFormat.year);
    } else {
      throw new Error(`Granularity ${granularity} not implemented`);
    }

    return result;
  }

  static getStartOfDayTimeStamp(date: Date) {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    return BalanceDatesUtils.dateToTimestamp(startOfDay);
  }

  static getEndOfDayTimeStamp(date: Date) {
    let endOfDay = new Date(date);
    endOfDay = addDays(endOfDay, 1);
    endOfDay.setHours(0, 0, 0, 0);
    return BalanceDatesUtils.dateToTimestamp(endOfDay) - 1;
  }

  static getEndOfDayDate(date: Date): Date {
    const endOfDate = new Date(BalanceDatesUtils.getEndOfDayTimeStamp(date) * 1000);
    return endOfDate;
  }

  static getStartOfDayDate(date: Date): Date {
    const startOfDate = new Date(BalanceDatesUtils.getStartOfDayTimeStamp(date) * 1000);
    return startOfDate;
  }

  static dateToTimestamp(date: Date) {
    return Math.round(date.valueOf() / 1000);
  }
}

import { Component, inject } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";

import { SymbolTable } from "@bitwarden/web-vault/app/gloss/tables/symbol-table/symbol.table";
import { TablesFactory } from "@bitwarden/web-vault/app/gloss/tables/tables.factory";
import { TablesType } from "@bitwarden/web-vault/app/models/enum/tables.enum";
import { DialogFactory } from "@bitwarden/web-vault/app/components/dialog/dialogFactory";

@Component({
  selector: "app-symbol",
  templateUrl: "symbol.component.html",
})
export class SymbolComponent {
  private dialogFactory: DialogFactory;
  private deviceService = inject(DeviceDetectorService);
  tableSymbol: SymbolTable = new TablesFactory().get(TablesType.Symbol);

  constructor() {
    this.dialogFactory = new DialogFactory();
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  handleAddSymbolClick() {
    this.dialogFactory.addSymbol();
  }

  handleSaveClick() {
    // eslint-disable-next-line no-console
    console.log("Save button clicked!");
  }
}

<!--<app-autocomplete-->
<!--  [items]="transactionDirection"-->
<!--  [placeholder]="'searchForATransactionDirection' | i18n"-->
<!--  [label]="label"-->
<!--  (selectedItem)="transactionDirectionSelected($event)"-->
<!--  (clearSelection)="selectionCleared()"-->
<!--  [preselectedValue]="direction"-->
<!--&gt;</app-autocomplete>-->

<app-dropdown-select
  [options]="transactionDirection"
  [label]="'transactionDirection' | i18n"
  [isRequired]="true"
  (optionSelected)="transactionDirectionSelected($event)"
>
</app-dropdown-select>

import { Injectable, Injector } from "@angular/core";

import { BasiqAuth } from "../../../importers/importer.auth.basiq";
import { Origin, SyncStatusEnum } from "../../../models/types/general-types";

import { InstitutionGroup } from "../../../models/types/institution.type";
import { AccountView } from "../../../models/view/account/account.view";

import { BasiqInstitutionConnection } from "./basiq-institution-connection";
import { SyncStatusView } from "../../../models/view/sync-status/sync-status.view";
import { UserStoreService } from "../../store/user/user.store.service";
import { ImportStoreService } from "../../store/import/import.store.service";

@Injectable({
  providedIn: "root",
})
export class BasiqConnector {
  private authService: BasiqAuth;
  private userStoreService: UserStoreService;
  private importStoreService: ImportStoreService;

  id: string;
  accountViews: AccountView[];
  institutionConnections: BasiqInstitutionConnection[] = [];

  constructor(
    accountViews: AccountView[],
    private injector: Injector,
  ) {
    this.userStoreService = this.injector.get(UserStoreService);
    this.importStoreService = this.injector.get(ImportStoreService);
    this.id = crypto.randomUUID();
    this.authService = this.injector.get(BasiqAuth);
    this.accountViews = accountViews;
  }

  async syncAccounts() {
    const isBasiqUser = await this.authService.isBasiqUser();
    if (!isBasiqUser) {
      await this.terminateSync(SyncStatusEnum.noBasiqUser);
      return;
    }

    const isConsent = await this.authService.isUserConsent();
    if (!isConsent) {
      await this.terminateSync(SyncStatusEnum.noBasiqConsent);
      return;
    }

    this.setInstitutionConnections();
    this.runInstoLevelSync();
  }

  async terminateSync(statusEnum: SyncStatusEnum) {
    const accountViews = this.userStoreService.accounts.accountViews().map((acc) => acc.clone());
    const newSyncStatuses: SyncStatusView[] = [];
    const syncingAccounts = accountViews.map((account) => {
      if (account.origin !== Origin.basiq) {
        return account;
      }
      const newSyncStatus = new SyncStatusView({
        id: crypto.randomUUID(),
        key: statusEnum,
        vid: null,
        dc: new Date().toISOString(),
        dm: new Date().toISOString(),
        v: 1,
        acId: account.id,
      });
      newSyncStatuses.push(newSyncStatus);
      account.syncStatusLink.push({ id: newSyncStatus.id });
      return account;
    });

    await this.importStoreService.syncStatus.saveToVault(newSyncStatuses, true);
    await this.userStoreService.accounts.saveToVault(syncingAccounts, true);
  }

  runInstoLevelSync() {
    this.institutionConnections.forEach((instoConnection) => {
      instoConnection.syncAccounts().then(() => null);
    });
  }

  private getGroupOfInstitutions(): InstitutionGroup[] {
    const sortedAccountViews = this.accountViews.sort((a: AccountView, b: AccountView) =>
      a.name.localeCompare(b.name),
    );
    return sortedAccountViews.reduce((grouped: InstitutionGroup[], accountView) => {
      const key = accountView.institution.basiqId;
      const group = grouped.find((g) => g.institutionId === key);
      if (!group) {
        grouped.push({ institutionId: key, accountViews: [accountView] });
      } else {
        group.accountViews.push(accountView);
      }
      return grouped;
    }, []);
  }

  setInstitutionConnections() {
    const groupOfInstitutions = this.getGroupOfInstitutions();

    for (const institutionGroup of groupOfInstitutions) {
      const institutionConnector = new BasiqInstitutionConnection(institutionGroup, this.injector);
      this.institutionConnections.push(institutionConnector);
    }
  }
}

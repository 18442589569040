import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import {
  GlossNumber,
  GlossNumberUtils,
} from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { GlossQuantity } from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";
import {
  NormalizedPair,
  NormalizedPairUtils,
} from "@bitwarden/web-vault/app/models/data/shared/normalized-pair";
import { Valuation } from "@bitwarden/web-vault/app/models/data/valuation.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

export enum BalanceAlignmentDescription {
  Opening = "Opening Balance Alignment Transaction",
  Closing = "Closing Balance Alignment Transaction",
  Generated = "Balance reset to ",
}

export abstract class GlossBalanceUtils {
  /**
   * addNormalizedPairRecords
   * Returns a new Record containing the summation of the
   * firstRecord to the secondRecord matching on symbols
   * @param firstRecord
   * @param secondRecord
   */
  static addNormalizedPairRecords(
    firstRecord: Record<string, NormalizedPair>,
    secondRecord: Record<string, NormalizedPair>,
  ): Record<string, NormalizedPair> {
    const summationRecord: Record<string, NormalizedPair> = {};
    for (const newSymbol in firstRecord) {
      summationRecord[newSymbol] = firstRecord[newSymbol];
    }
    for (const newSymbol in secondRecord) {
      if (summationRecord[newSymbol]) {
        NormalizedPairUtils.add(summationRecord[newSymbol], secondRecord[newSymbol]);
      } else {
        summationRecord[newSymbol] = secondRecord[newSymbol];
      }
    }
    return summationRecord;
  }

  /**
   * addToNormalizedPairRecords
   * Adds the second parameter normalizedPair Record to the first one.
   * If the symbol exists in the first Record it will add the values.
   * If the symbol doesn't exist then it will add it to the first Record and
   * copy the values across.
   * @param currentRecord
   * @param additionalRecord
   */
  static addToNormalizedPairRecords(
    currentRecord: Record<string, NormalizedPair>,
    additionalRecord: Record<string, NormalizedPair>,
  ) {
    for (const newSymbol in additionalRecord) {
      if (currentRecord[newSymbol]) {
        NormalizedPairUtils.add(currentRecord[newSymbol], additionalRecord[newSymbol]);
      } else {
        currentRecord[newSymbol] = NormalizedPairUtils.copy(additionalRecord[newSymbol]);
      }
    }
  }

  /**
   * addNormalizedFromPair
   * Adds just the normalized components from a set of NormalizedPairs to the
   * GlossNumber in the first parameter.
   * @param currentNormalizedAmount
   * @param additionalRecord
   */
  static addNormalizedFromPair(
    currentNormalizedAmount: GlossNumber,
    additionalRecords: Record<string, NormalizedPair>,
  ) {
    for (const additionalSymbol in additionalRecords) {
      const additionalSymbolPair = additionalRecords[additionalSymbol];
      if (!currentNormalizedAmount.symbol) {
        currentNormalizedAmount.symbol = additionalSymbolPair.normalizedValue.symbol;
      }
      GlossNumberUtils.add(currentNormalizedAmount, additionalSymbolPair.normalizedValue);
    }
  }

  /**
   * Given a GlossQuantity and a Valuation, assuming they have been set
   * correctly, extract the pieces to build a full GlossBalance for a
   * transaction
   * @param quantity
   * @param valuation
   */
  static createGlossBalanceFromQuantityValuation(
    direction: TransactionDirection,
    quantity: GlossQuantity,
    valuation: Valuation,
    isTransfer = false,
  ): GlossBalance {
    const transactionBalance = new GlossBalance();
    const symbolGlossNumber = GlossNumberUtils.copy(quantity.actualQuantity);
    const normalizedGlossNumber = GlossNumberUtils.copy(valuation.normalizedValue);

    const transactionNormalizedPair = new NormalizedPair();
    if (direction === TransactionDirection.Out) {
      symbolGlossNumber.amount = -1 * symbolGlossNumber.amount;
    }
    transactionNormalizedPair.symbolAmount = symbolGlossNumber;
    transactionNormalizedPair.normalizedValue = normalizedGlossNumber;

    const symbol = symbolGlossNumber.symbol;

    if (!isTransfer) {
      if (direction === TransactionDirection.In) {
        transactionBalance.in[symbol] = NormalizedPairUtils.copy(transactionNormalizedPair);
        transactionBalance.runningTotalIn[symbol] =
          NormalizedPairUtils.copy(transactionNormalizedPair);
        GlossNumberUtils.copyFrom(
          transactionBalance.runningTotalNormalizedIn,
          normalizedGlossNumber,
        );
      } else {
        transactionBalance.out[symbol] = NormalizedPairUtils.copy(transactionNormalizedPair);
        transactionBalance.runningTotalOut[symbol] =
          NormalizedPairUtils.copy(transactionNormalizedPair);
        GlossNumberUtils.copyFrom(
          transactionBalance.runningTotalNormalizedOut,
          normalizedGlossNumber,
        );
      }
    }
    transactionBalance.value[symbol] = NormalizedPairUtils.copy(transactionNormalizedPair);
    transactionBalance.runningTotalValue[symbol] =
      NormalizedPairUtils.copy(transactionNormalizedPair);
    GlossNumberUtils.copyFrom(
      transactionBalance.runningTotalNormalizedValue,
      normalizedGlossNumber,
    );

    return transactionBalance;
  }

  static createGlossBalanceNormalizedPair(normalizedPair: NormalizedPair, isTransfer = false) {
    const { normalizedValue, symbolAmount } = normalizedPair;
    let direction = TransactionDirection.In;
    const transactionBalance = new GlossBalance();

    if (normalizedValue.amount < 0) {
      direction = TransactionDirection.Out;
    }

    const symbol = symbolAmount.symbol;

    if (!isTransfer) {
      if (direction === TransactionDirection.In) {
        transactionBalance.in[symbol] = NormalizedPairUtils.copy(normalizedPair);
        transactionBalance.runningTotalIn[symbol] = NormalizedPairUtils.copy(normalizedPair);
        transactionBalance.runningTotalNormalizedIn.copyFrom(normalizedValue);
      } else {
        transactionBalance.out[symbol] = NormalizedPairUtils.copy(normalizedPair);
        transactionBalance.runningTotalOut[symbol] = NormalizedPairUtils.copy(normalizedPair);
        GlossNumberUtils.copyFrom(transactionBalance.runningTotalNormalizedOut, normalizedValue);
      }
    }
    transactionBalance.value[symbol] = NormalizedPairUtils.copy(normalizedPair);
    transactionBalance.runningTotalValue[symbol] = NormalizedPairUtils.copy(normalizedPair);
    GlossNumberUtils.copyFrom(transactionBalance.runningTotalNormalizedValue, normalizedValue);

    return transactionBalance;
  }
}

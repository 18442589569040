import { inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";

import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { ConfirmationAttachment } from "@bitwarden/web-vault/app/models/types/general-types";

import { ConfirmationDialogComponent } from "../../components/confirmation-dialog/confirmation-dialog.component";

import { confirmationMapper } from "./confirmation.constant";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { NotificationType } from "@bitwarden/web-vault/app/models/types/confirmation.types";

@Injectable({
  providedIn: "root",
})
export class ConfirmationDialogService {
  private platformUtilsService = inject(PlatformUtilsService);
  private i18nService = inject(I18nService);
  private dialog = inject(MatDialog);

  /**
   * Opens a confirmation dialog based on the provided key.
   *
   * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating the user's confirmation.
   * @param confirmationKey - The key of the confirmation dialog to show.
   * @param attachment - An optional attachment to pass to the confirmation dialog.
   */
  async confirmFor(
    confirmationKey: ConfirmationEnum,
    attachment?: ConfirmationAttachment,
  ): Promise<boolean> {
    const confirmationData = confirmationMapper[confirmationKey];
    const { title, message, type } = confirmationData;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: "confirmation-dialog",
      disableClose: true,
      data: {
        title: title || "info",
        type,
        message: attachment && message === "$GENERATED$" ? attachment.messageReplacement : message,
      },
    });

    return await firstValueFrom(dialogRef.afterClosed());
  }

  notify(type: NotificationType, titleKey: string, textKey: string) {
    this.platformUtilsService.showToast(
      type,
      this.i18nService.t(titleKey),
      this.i18nService.t(textKey),
    );
  }
}

import { GridOptions } from "ag-grid-enterprise";
import { SymbolCodeRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/symbol-code-renderer/symbol-code-renderer.component";
import { SymbolAmountRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/symbol-amount-renderer/symbol-amount-renderer.component";
import { SymbolConvertedAmountRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/symbol-convertedAmount-renderer/symbol-convertedAmount-renderer.component";
import { SymbolCountryRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/symbol-country-renderer/symbol-country-renderer.component";
import { SymbolTableSubscription, SymbolData } from "./symbol-table.subscription";

export class AccountSymbolTable extends SymbolTableSubscription {
  options: GridOptions<SymbolData>;

  constructor() {
    super();
    const commonProperties = {
      sortable: false,
      suppressMenu: true,
    };
    const commonheaderClass = "tw-text-neutral-300 tw-text-sm";
    this.options = {
      columnDefs: [
        {
          headerName: "Code",
          headerClass: commonheaderClass,
          field: "code",
          cellRenderer: SymbolCodeRendererComponent,
          ...commonProperties,
        },
        {
          headerName: "Amount",
          headerClass: commonheaderClass,
          field: "amount",
          cellRenderer: SymbolAmountRendererComponent,
          ...commonProperties,
        },
        {
          headerName: "Converted to Main Currency (AUD)",
          headerClass: commonheaderClass,
          field: "convertedAmount",
          cellRenderer: SymbolConvertedAmountRendererComponent,
          ...commonProperties,
        },
        {
          headerName: "",
          headerClass: commonheaderClass,
          field: "country",
          cellRenderer: SymbolCountryRendererComponent,
          ...commonProperties,
        },
      ],
      onGridReady: (params) => params.api.sizeColumnsToFit(),
      suppressContextMenu: true,
    };
  }
}

<!-- this component is temporary used to get a date range to test the views-->
<!-- will be replaced by the actual date picker pr-->

<div class="tw-w-full tw-h-10 tw-flex tw-gap-1 tw-items-center">
  @if (viewsService.view !== ViewEnum.TODAY) {
    <div class="tw-relative tw-w-full tw-h-full">
      <div
        class="tw-w-full tw-h-full tw-bg-white left-round-border tw-flex tw-gap-2 tw-whitespace-nowrap tw-items-center tw-px-3"
        (click)="toggleStartPickerVisibility()"
      >
        <span>From</span>
        <span>{{ displayDateText().startDate }}</span>
      </div>
      <app-calendar
        appClickOutside
        (clickOutside)="handleClickOutsideStart()"
        *ngIf="showStartPicker"
        [pickerType]="'start'"
        [dateRange]="initialDateRange()"
        (dateChangeEvent)="onDateChange($event, 'startDate')"
        (confirmEvent)="onStartPickerConfirm()"
        (cancelEvent)="onPickerCancel()"
      ></app-calendar>
    </div>
  }

  <div class="tw-relative tw-w-full tw-h-full">
    <div
      class="tw-w-full tw-h-full tw-bg-white right-round-border tw-flex tw-gap-2 tw-whitespace-nowrap tw-items-center tw-px-3"
      (click)="toggleEndPickerVisibility()"
    >
      <span>To</span>
      <span>{{ displayDateText().endDate }}</span>
    </div>
    <app-calendar
      appClickOutside
      (clickOutside)="handleClickOutsideEnd()"
      *ngIf="showEndPicker"
      [pickerType]="'end'"
      [dateRange]="initialDateRange()"
      (dateChangeEvent)="onDateChange($event, 'endDate')"
      (confirmEvent)="onEndPickerConfirm()"
      (cancelEvent)="onPickerCancel()"
    ></app-calendar>
  </div>

  <icon
    name="calendar"
    [size]="6"
    [currentColor]="'neutral-800'"
    class="tw-px-3 xs:tw-hidden"
  ></icon>
</div>

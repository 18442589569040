import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DropdownBaseComponent } from "@bitwarden/web-vault/app/components/dropdown/dropdown-base.component";

@Component({
  selector: "app-dropdown-select",
  templateUrl: "./dropdown-select.component.html",
})
export class DropdownSelectComponent extends DropdownBaseComponent {
  // todo: add these 2 to the other components when needed
  @Input() label: string;
  @Input() isRequired: boolean = false;
  //
  @Input() options: string[] = [];
  @Input() selectedOption: string;
  @Output() optionSelected = new EventEmitter<string>();
}

import {
  CalculatedBalances,
  CalculatedBalancesGroupedTwice,
  CalculatedBalanceCollection,
  CalculatedBalanceParams,
} from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";
import { inject, signal } from "@angular/core";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { toObservable } from "@angular/core/rxjs-interop";

export class BalanceByAccountsAndSymbolsCalculatedCollection extends CalculatedBalanceCollection {
  protected log: LogService = inject(LogService);

  protected type: "BalanceByAccountsAndSymbols";
  protected calculations: CalculatedBalancesGroupedTwice = new Map<string, CalculatedBalances>();

  protected _balances = signal<Map<string, Map<string, number>>>(new Map());
  readonly balances = this._balances.asReadonly();
  balances$ = toObservable(this.balances);

  async updateCalculations(parameters: CalculatedBalanceParams): Promise<void> {
    const todayDate = parameters?.period?.endDate ? parameters.period.endDate : new Date();
    const balancesByAccountSymbols: Record<
      number,
      Record<string, number>
    > = await this.getBalanceByAccountsSymbols(
      parameters.webWorkerQueue,
      parameters.calculatedTransactions,
      todayDate,
    );

    this.setBalances(balancesByAccountSymbols);
  }

  getCalculations(): CalculatedBalancesGroupedTwice {
    return this.calculations;
  }

  clear() {
    this.calculations.clear();
    this._balances.set(null);
  }

  triggerChanges(): void {}

  private setBalances(balancesByAccountSymbols: Record<number, Record<string, number>>): void {
    this.calculations = new Map();

    for (const [account, balancesBySymbol] of Object.entries(balancesByAccountSymbols)) {
      const balancesBySymbolMap = new Map(Object.entries(balancesBySymbol));
      this.calculations.set(account, balancesBySymbolMap);
    }
    this._balances.set(this.calculations);
  }
}

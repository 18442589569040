import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { Component, Inject } from "@angular/core";
import { Router } from "@angular/router";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { ToasterType } from "@bitwarden/web-vault/app/components/old-tables/transaction-table/transaction-table.component";
import { SourceImportService } from "@bitwarden/web-vault/app/services/DataService/source-import/source-import.service";

import { TransactionService } from "../../../../app/services/DataService/transaction/transaction.service";
import { Transaction } from "../../../models/data/blobby/transaction.data";

import "./modal.component.scss";

type DeleteResponseTypes = {
  status: ToasterType;
  messageId: string;
  description: string;
};

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styles: ["modal.component.scss"],
})
export class ModalComponent {
  private DELETE_RESPONSE: DeleteResponseTypes = {
    status: "success",
    messageId: "transactionDeletedSuccess",
    description: "",
  };

  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) private data: Record<string, Transaction>,
    private transactionService: TransactionService,
    private sourceTransactionService: SourceImportService,
    private utilsService: PlatformUtilsService,
    private i18nService: I18nService,
    private router: Router,
  ) {}

  handleCancel() {
    this.dialogRef.close();
  }

  async handleDelete() {
    const { transaction } = this.data;
    const isTransDeleted = await this.transactionService.delete(transaction);
    const isSourceDeleted = await this.deleteSourceTransactions(transaction.id);

    if (isTransDeleted && isSourceDeleted) {
      this.showResponseMessage(isTransDeleted);
      this.dialogRef.close();
      this.router.navigate(["/primary-dashboard"]);
    }
  }

  private async deleteSourceTransactions(id: string) {
    const sourceTransaction = await this.sourceTransactionService.get(id);
    return this.sourceTransactionService.delete(sourceTransaction);
  }

  private showResponseMessage(isDeleted: boolean) {
    if (!isDeleted) {
      this.DELETE_RESPONSE = {
        status: "error",
        messageId: "transactionDeletedFailed",
        description: "",
      };
    }

    const { status, messageId, description } = this.DELETE_RESPONSE;
    this.utilsService.showToast(
      status,
      this.i18nService.t(messageId),
      this.i18nService.t(description),
    );
  }
}

import { Component, EventEmitter, inject, Injector, Output } from "@angular/core";
import { filter, Subject } from "rxjs";

import { BestScenarioStepper } from "@bitwarden/web-vault/app/components/best-scenario-dialog/best-scenario-stepper";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { StepperActionsEnum } from "@bitwarden/web-vault/app/models/enum/stepper.enum";
import { EarningDataCard } from "@bitwarden/web-vault/app/models/types/component.types";
import { StepperAction } from "@bitwarden/web-vault/app/models/types/stepper.types";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { ScenarioDisplayView } from "@bitwarden/web-vault/app/models/view/scenario/scenario-display.view";
import { ScenarioStoreService } from "@bitwarden/web-vault/app/services/store/scenario/scenario.store.service";

@Component({
  selector: "app-fulfill-conditions",
  templateUrl: "./fulfill-conditions.component.html",
})
export class FulfillConditionsComponent extends BestScenarioStepper {
  @Output() onAction = new EventEmitter<StepperAction>();
  private destroy$ = new Subject<void>();
  private dashboardService: DashboardService;

  private scenarioStore: ScenarioStoreService = inject(ScenarioStoreService);

  private scenarioObservable$ = toObservable(this.scenarioStore.interestScenario.collection);

  earningsCard: EarningDataCard;
  action = StepperActionsEnum.viewOtherAccounts;

  viewOtherAccounts = new ActionButton({
    text: this.i18n.t("viewOtherAccounts"),
    type: "secondary",
    class: "tw-bg-neutral-50",
    testId: "viewOtherAccountsInPath3Stepper",
    onClick: () => this.onViewOtherAccounts(),
  });
  nextBtnOptions = new ActionButton({
    text: this.i18n.t("complete"),
    class: "primary",
    testId: "stepperNextButton",
  });

  prevBtnOptions = new ActionButton({
    text: this.i18n.t("continueToNextStep"),
    class: "secondary",
    testId: "stepperPrevButton",
  });

  constructor(injector: Injector) {
    super(injector);
    // this.dashboardService = injector.get(DashboardService);
    this.scenarioObservable$
      .pipe(
        takeUntilDestroyed(),
        filter(
          (scenarioObservableValue) => scenarioObservableValue[0].fullTransactionList.length > 0,
        ),
      )
      .subscribe((scenarioDisplayViews: ScenarioDisplayView[]) => {
        this.scenarioDisplayView = scenarioDisplayViews;
        if (this.scenarioDisplayView) {
          this.setUpStep().then(() => {
            this.updateData();
          });
        }
      });
  }

  updateData() {
    this.earningsCard = {
      header: this.i18n.t("toEarn"),
      earningAmount: this.getEarningAmount(),
      headerDescription: `${this.rateInUse.rate}% P.A. interest`,
      currency: this.helpInfo.symbol,
      footerDescription: this.getDescription(),
    };
  }

  getDescription(): string {
    /** @Sinan check out for how to get this message: from message.json?  */
    return "In the next 12 months";
  }

  onViewOtherAccounts() {
    this.onAction.emit(StepperActionsEnum.viewOtherAccounts);
  }
}

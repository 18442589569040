<div class="progress-bar-container">
  <div
    class="tw-inline-flex tw-h-[250px] tw-w-[320px] tw-flex-col tw-items-center tw-gap-4 tw-rounded-xl tw-bg-white tw-px-12 tw-py-10"
  >
    <div class="tw-flex tw-gap-2">
      <div class="tw-text-xl tw-font-medium">{{ pendingState().message }}</div>
    </div>

    @if (pendingState().progress > 0) {
      <div
        class="tw-flex tw-h-28 tw-w-28 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-solid tw-border-neutral-50"
      >
        <div
          class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-full tw-to-neutral-50 tw-p-2"
          [ngStyle]="{ background: background() }"
        >
          <div
            class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-full tw-bg-white"
          >
            <div>...</div>
          </div>
        </div>
      </div>

      @if (pendingState().step) {
        <div class="tw-text-center">
          <span class="tw-font-semibold tw-text-primary">step</span>
          <span class="tw-font-medium">{{ pendingState().step }}</span>
        </div>
      }
    } @else {
      <div class="no-progress-loader"></div>
    }
  </div>
</div>

import { EstimateActionData } from "../../../../models/data/blobby/estimate.action.data";
import { EstimateActionStoreModel } from "../../../../models/store/estimate.action.store.model";

export const estimateAction_v0_to_v1 = (
  estimateActionItem: EstimateActionData,
): EstimateActionStoreModel => {
  return {
    dc: new Date().toString(),
    dm: new Date().toString(),
    id: estimateActionItem.id,
    v: 1,
    vid: "",
    atype: estimateActionItem.actionType,
    n: estimateActionItem.name,
    acc: estimateActionItem.account,
    etype: estimateActionItem.estimateType,
    sdate: estimateActionItem.startDate,
    dir: estimateActionItem.direction,
    des: estimateActionItem.description,
    cat: estimateActionItem.categories,
    cl: estimateActionItem.classifications,
    gid: estimateActionItem.groupId,
    r: estimateActionItem.recurring,
    ttemp: estimateActionItem.transactionTemplate,
    imatch: estimateActionItem.interestMatch,
  };
};

<form *ngIf="transactionFrom.formGroup" [formGroup]="transactionFrom.formGroup" name="accountForm">
  <div class="tw-mb-6 tw-flex tw-flex-col tw-gap-6 tw-w-full">
    <!--    <app-gloss-input-->
    <!--      #descriptionInput-->
    <!--      [options]="transactionFrom.descriptionInputOptions"-->
    <!--      class="tw-min-h-12 tw-w-full tw-bg-white"-->
    <!--      (isClearInput)="transactionFrom.clearInput('description')"-->
    <!--    ></app-gloss-input>-->
    @if (
      selectedAccount.type === accountManualType.bank ||
      selectedAccount.type === accountManualType.credit
    ) {
      <app-gloss-input-new
        class="tw-w-full"
        [label]="'description' | i18n"
        [isRequired]="true"
        (outputValue)="inputDescription($event)"
      >
      </app-gloss-input-new>
    }

    <div class="tw-flex tw-justify-between tw-gap-4 sm:tw-gap-6 tb:tw-flex-col">
      <app-date-picker
        [label]="'date' | i18n"
        [isRequired]="true"
        [hasError]="
          transactionFrom.formGroup.get('date').invalid &&
          transactionFrom.formGroup.get('date').touched
        "
        (changeDateEvent)="transactionFrom.onDateChange($event)"
        class="tw-w-full"
      >
      </app-date-picker>
      <app-gloss-input
        #timeInput
        [options]="transactionFrom.timeInputOptions"
        class="tw-w-full"
        (isClearInput)="transactionFrom.clearInput('time')"
      ></app-gloss-input>
    </div>

    <app-auto-transaction-direction
      [label]="'transactionDirection' | i18n"
      (selectTransactionDirection)="transactionFrom.selectDirection($event)"
      class="tw-w-full"
    ></app-auto-transaction-direction>

    @if (selectedAccount.type === accountManualType.stock) {
      <div class="tw-flex tw-gap-4 tw-w-full">
        <!--        symbol-->
        <div class="tw-flex tw-w-1/2 xs:tw-w-full">
          <div class="tw-w-full tw-flex tw-flex-col">
            <app-dropdown-select
              [label]="'symbol'"
              class="tw-w-full"
              [options]="mockStockSymbols"
              (optionSelected)="onStockSymbolSelected($event)"
            >
            </app-dropdown-select>
            <div class="tw-inline-block tw-relative tw-pointer-events-none">
              <a
                routerLink="/symbol"
                class="tw-text-sm tw-text-neutral-600 hover:tw-text-neutral-600 tw-underline tw-pointer-events-auto"
              >
                Symbol not found? Add it manually.
              </a>
            </div>
          </div>
        </div>
        <!--        amount-->
        <app-gloss-input-new
          class="tw-w-1/2"
          [label]="'amount' | i18n"
          [isRequired]="true"
          [type]="'number'"
          (outputValue)="onNumberOfPurchaseEntered($event)"
        >
        </app-gloss-input-new>
      </div>
    }

    <div class="tw-flex tw-justify-between tw-gap-4 tb:tw-flex-col">
      <!--      price-->
      <div class="tw-w-1/2 xs:tw-w-full">
        <app-gloss-input
          #amountInput
          [options]="transactionFrom.amountInputOptions"
          class="tw-w-full"
          (isClearInput)="transactionFrom.clearInput('amount')"
        ></app-gloss-input>
      </div>
      <!--      currency-->
      <app-currencies
        class="tw-w-1/2"
        *ngIf="transactionFrom"
        (selectedCurrency)="transactionFrom.selectCurrency($event)"
        [label]="'currency' | i18n"
        [accountCurrency]="transactionFrom.currency"
      ></app-currencies>
    </div>

    @if (
      selectedAccount.type === accountManualType.stock ||
      selectedAccount.type === accountManualType.other
    ) {
      <div
        class="tw-flex tw-flex-col tw-gap-3 tw-bg-white tw-w-full tw-rounded-xl tw-p-3 tw-shadow-tips"
      >
        <div class="tw-flex tw-justify-between tw-items-center tw-gap-6 tw-h-[52px]">
          <div class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-w-full">
            <div class="tw-flex tw-gap-2 tw-whitespace-nowrap">
              <span class="tw-font-medium">Update symbol value</span>
              <span>(optional)</span>
            </div>
            <div>! Changes will be applied to all of this symbol's value.</div>
          </div>
          <div
            class="tw-flex tw-justify-center tw-rounded-xl tw-h-full tw-aspect-square tw-bg-neutral-50"
            (click)="helperExpand.toggleContent($event)"
          >
            <icon [name]="helperExpand.icon" [size]="8"></icon>
          </div>
        </div>
        <div class="tw-flex tw-gap-3 tw-items-center">
          <span class="tw-whitespace-nowrap tw-font-medium">Selected Symbol</span>
          <div
            class="tw-h-12 tw-w-full tw-rounded-xl tw-px-6 tw-flex tw-items-center tw-bg-neutral-50 tw-text-neutral-100"
          >
            Please select the symbol from above.
          </div>
        </div>

        @if (helperExpand.isExpanded) {
          <app-symbol-form [isEditMode]="true"> </app-symbol-form>
        }
      </div>
    }
  </div>
</form>

import { ModelView } from "../model.view";
import {
  CategoryModelVersion,
  CategoryStoreModel,
  DefaultCategoryStoreModel,
} from "@bitwarden/web-vault/app/models/store/category.store.model";

export class CategoryView extends ModelView<CategoryStoreModel> {
  /** Readonly and private Field are located in the Base Class **/
  protected version: CategoryModelVersion;

  name: string;

  /** category is generate default */
  generalDefault?: boolean;

  weight: number;

  constructor(model?: CategoryStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.name = model.n;
      this.generalDefault = model.gendef;
      this.weight = model.w;
    } else {
      this.version = DefaultCategoryStoreModel.v;
    }
  }

  toStoreModel(): CategoryStoreModel {
    return {
      dc: this.dateCreated.toDateString(),
      dm: this.dateModified.toDateString(),
      id: this.id,
      vid: this.vaultId,
      v: this.version,
      n: this.name,
      gendef: this.generalDefault,
      w: this.weight,
    };
  }

  clone(): CategoryView {
    return new CategoryView(this.toStoreModel());
  }
}

import { Component, ViewChild, OnDestroy, computed, inject, OnInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Subject } from "rxjs";

import { I18nService } from "../../../../../../../libs/common/src/abstractions/i18n.service";
import { GlobalService } from "../../../../../../../libs/common/src/services/global/global.service";

import { Origin } from "../../../models/types/general-types";
import { AccountView } from "../../../models/view/account/account.view";

import "./stepper.component.scss";
import { PreferenceView } from "../../../models/view/preference/preference.view";
import { UserStoreService } from "../../../services/store/user/user.store.service";
import { AppStateStoreService } from "../../../services/store/app-state/app-state.store.service";
import { DialogFactory } from "../../../components/dialog/dialogFactory";

@Component({
  selector: "app-account-wizard-stepper",
  templateUrl: "./stepper.component.html",
  styles: ["account-wizard-stepper.component.scss"],
})
export class StepperComponent implements OnInit, OnDestroy {
  protected userStoreService: UserStoreService = inject(UserStoreService);
  protected appState = inject(AppStateStoreService);

  booksView: AccountView[];
  actionPromise: Promise<any>;
  addAccountButton = "addAccount";
  setBalanceButton = "enterAccountData";
  viewForecastButton = "viewForecast";
  setBalanceTitle = "addAccountBalanceTitle";
  setBalanceDescription = "addAccountBalanceDescription";
  setBalanceProgressTitle = "setBalance";
  dataSource: MatTableDataSource<AccountView>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["name", "institution", "type", "source", "status", "actions"];
  protected preferenceView: PreferenceView;

  dialogFactory: DialogFactory;

  protected readonly Origin = Origin;
  private destroy$ = new Subject<void>();

  constructor(protected i18nService: I18nService) {
    this.appState.wizard.set({ isVisible: true });
    this.dialogFactory = new DialogFactory(this.setCurStepAsComplete.bind(this));
  }

  async ngOnInit() {
    const wizard = this.userStoreService.wizards.wizardViews();
    if (wizard && wizard.findIndex((w) => w.type == "account") < 0) {
      const defaultWizard = this.userStoreService.wizards.getDefaultAccountCreationWizard();
      await this.userStoreService.wizards.save(defaultWizard);
    }
  }

  protected hasLocation = computed(() => {
    const location = this.userStoreService.preferences.preferenceView()?.userLocation;
    return location !== undefined;
  });

  protected wizard = computed(() => {
    const wizard = this.userStoreService.wizards.wizardViews();
    if (Array.isArray(wizard)) {
      const currentWizard = wizard.find((w) => w.type == "account");
      if (currentWizard) {
        return currentWizard.clone();
      }
    } else {
      return null;
    }
  });

  getIconClassStep1 = computed(() => {
    const currentStep = this.wizard().step;
    return currentStep.stepIndex > 1 ? "step-check-icon-1-finish" : "step-check-icon";
  });

  getIconClassStep2 = computed(() => {
    const currentStep = this.wizard().step;
    return currentStep.stepIndex < 2
      ? "step-check-icon-empty"
      : currentStep.stepIndex === 2
        ? "step-check-icon-in-progress"
        : "step-check-icon-complete-2";
  });

  getIconClassStep3 = computed(() => {
    const currentStep = this.wizard().step;
    return currentStep.stepIndex < 3
      ? "step-check-icon-empty"
      : currentStep.stepIndex === 3
        ? "step-check-icon-in-progress"
        : "step-check-icon-complete-latest";
  });

  getButtonState = computed(() => {
    const currentStep = this.wizard();
    return currentStep.step.stepIndex === 1 ? "done" : this.addAccountButton;
  });

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async setCurStepAsComplete() {
    const current = this.wizard();

    current.step.isComplete = false;
    current.step.stepIndex++;

    /** Clicking on view forecast, reset the counter, remove overlay. save wizard **/
    if (current.step.stepIndex >= 4) {
      current.step.stepIndex = 3;
      current.step.isComplete = true;
      await this.userStoreService.wizards.save(current); // save first then, remove the overlay
      this.appState.wizard.set({ isVisible: false });
    } else {
      this.appState.wizard.set({ isVisible: true }); // add the overlay, then save
      await this.userStoreService.wizards.save(current);
    }
  }

  isButtonEnabled(number: number) {
    return this.wizard().step.stepIndex !== number;
  }

  openCreationOptionsModal() {
    this.dialogFactory.openCreationOptionsModal();
  }

  async addBalanceSelected() {
    this.booksView = this.userStoreService.accounts.accountViews();
    const firstManuelAccount = this.booksView[0].clone();
    await this.dialogFactory.addBalanceSelected(firstManuelAccount);
  }

  async wizardExit() {
    await this.setCurStepAsComplete();
  }
}

<div class="tw-w-full tw-flex tw-justify-center balance-hud">
  <div
    class="tw-w-full tw-border tw-border-solid tw-rounded-lg tw-flex tw-flex-col tw-p-2 tw-bg-opacity-[12%] tw-border-secondary tw-bg-secondary"
  >
    <div class="tw-flex tw-justify-between tw-px-2 tw-py-1">
      <span>{{ balanceDate() | date: "YYYY-MM-dd" }}</span>
      <icon
        [name]="helperExpand.icon"
        [size]="5"
        [currentColor]="'tw-text-black'"
        (click)="handleExpand($event)"
      ></icon>
    </div>
    <div class="tw-flex tw-flex-col tw-gap-1">
      @for (pathBalance of pathBalances(); track idx; let idx = $index) {
        <ng-container
          class="tw-w-full"
          *ngTemplateOutlet="
            path;
            context: { pathName: 'Path ' + (idx + 1), index: idx, pathBalance: pathBalance }
          "
        ></ng-container>
      }
    </div>
  </div>
</div>

<ng-template #accountBalance let-account="account" let-balance="balance">
  <div class="tw-w-full tw-flex tw-justify-between tw-px-2 tw-py-1">
    <span>{{ account }}</span>
    <span class="tw-font-medium">{{ balance | currency }}</span>
  </div>
</ng-template>

<ng-template #path let-pathName="pathName" let-pathBalance="pathBalance" let-index="index">
  <div
    class="tw-flex tw-gap-1 tw-bg-opacity-[12%] tw-border-secondary tw-bg-secondary"
    [ngClass]="{ '!tw-bg-white': !helperCollapse[index].isCollapsed }"
    (click)="handleCollapse($event, index)"
  >
    <ng-container
      class="tw-w-full"
      *ngTemplateOutlet="accountBalance; context: { account: pathName, balance: pathBalance }"
    >
    </ng-container>
    <icon [name]="helperCollapse[index].icon" [size]="7"></icon>
  </div>
  @if (!helperCollapse[index].isCollapsed) {
    <div class="tw-bg-white">
      @for (account of pathBalanceAccounts()[index]; track account.accountID) {
        <ng-container *ngTemplateOutlet="accountBalance; context: account"></ng-container>
      }
    </div>
  }
</ng-template>

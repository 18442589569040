<ng-container>
  @if (isVisible) {
    <app-help-box
      (changeHelpOpen)="onHelpBoxClose($event)"
      [prefilledMessage]="prefilledMessage"
      class="tw-absolute tw-bottom-0 tw-top-0 tw-z-[2] tw-flex tw-w-full"
    >
    </app-help-box>
  }
  <app-spinner [showSpinner]="loading"></app-spinner>
  <div
    (mouseenter)="showTooltipBox(true)"
    *ngIf="showTooltipBoolean && hoveredTransaction.id === selectedTransactionId"
  >
    <app-info-tooltip
      (onMouseEnterTooltip)="onMouseEnterTooltip($event)"
      (onMouseLeaveTooltip)="onMouseLeaveTooltip($event)"
      [transaction]="hoveredTransaction"
      [selectedTransactionId]="selectedTransactionId"
    ></app-info-tooltip>
  </div>
  <div class="tw-mb-[100px] tw-flex tw-h-[500px] tw-w-full tw-flex-col">
    <app-transaction-table-tabs
      [isLinkButtonEnabled]="linkButtonEnabled"
      (linkConversion)="linkConversion()"
      (linkTransfer)="linkTransfer()"
      (unlinkTransactions)="unlinkTransactions()"
      (deleteTransaction)="deleteTransaction()"
      (resetColumns)="resetColumns()"
    ></app-transaction-table-tabs>
    <ag-grid-angular
      class="ag-theme-quartz"
      style="height: 100%"
      [noRowsOverlayComponent]="NoRowsOverlayComponentOfDashTable"
      [columnDefs]="colDefs"
      [getGroupRowAgg]="getGroupRowAgg"
      [rowModelType]="rowModelType"
      [context]="context"
      [autoSizeStrategy]="autoSizeStrategy"
      (gridReady)="onGridReady($event)"
      [columnMenu]="columnMenu"
      [getContextMenuItems]="getContextMenuItems"
      [rowSelection]="rowSelection"
      [rowClassRules]="rowClassRules"
      (cellClicked)="onCellClicked($event)"
      (cellContextMenu)="onCellRightClicked($event)"
      [gridOptions]="gridOptions"
      [autoGroupColumnDef]="autoGroupColumnDef"
    ></ag-grid-angular>
  </div>
</ng-container>

import { ParserFunctionType } from "../parser.type";

/**
 * Interface representing the structure and methods of a Book parser.
 */
export abstract class ParserAbstraction {
  abstract getSupportedVersion(): number[];
  abstract getParser(version: number): ParserFunctionType;
  isSupportedVersion(version: number): boolean {
    return this.getSupportedVersion().indexOf(version) !== -1;
  }
}

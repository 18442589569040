import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-symbol-convertedAmount-renderer",
  templateUrl: "./symbol-convertedAmount-renderer.component.html",
  providers: [DecimalPipe],
})
export class SymbolConvertedAmountRendererComponent implements ICellRendererAngularComp {
  convertedAmount!: string;

  constructor(private decimalPipe: DecimalPipe) {}

  agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  setData(params: ICellRendererParams) {
    const initialAmount = params.data.convertedAmount || 0;
    this.convertedAmount =
      this.decimalPipe.transform(initialAmount, "1.2-2") || "Unavailable convertedAmount";
  }

  refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }
}

import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SyncIcon } from "@bitwarden/web-vault/app/models/enum/sync.enum";
import { IButton } from "../../../models/interfaces/button.interface";

/**
 * GlossButtonComponent is a reusable button component for the application.
 * It accepts an options object of type GlossButtonOptions as an @Input.
 * The options object can contain iconName, buttonText, and an onClick method.
 *
 * @example
 * <app-gloss-button [options]="{ iconName: 'arrow_back', buttonText: 'Previous', onClick: previous }"></app-gloss-button>
 */

@Component({
  selector: "app-gloss-button",
  templateUrl: "./gloss-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlossButtonComponent {
  @Input() options: IButton;
  protected readonly SyncIcon = SyncIcon;
}

import { AccountFilter } from "@bitwarden/web-vault/app/models/types/filter.types";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { inject, Injectable } from "@angular/core";
import { FilterControlType } from "@bitwarden/web-vault/app/services/store/calculation/controls/filter.control";

@Injectable({
  providedIn: "root",
})
export class ViewsFilterAccountHelper {
  private userStore = inject(UserStoreService);
  private accountViews = this.userStore.accounts.accountViews();

  getFilter(
    selected: FilterControlType,
    institutionView: InstitutionView,
  ): Map<string, AccountFilter> {
    return this.accountFilterOfInstitution(selected, institutionView);
  }

  private accountFilterOfInstitution(
    selected: FilterControlType,
    institutionView: InstitutionView,
  ) {
    const isSelectedCountry = selected.countries.includes(institutionView.swift.countryCode);
    const isSelectedInsto = selected.institutions.includes(institutionView.id);
    const accountsFilterMap: Map<string, AccountFilter> = new Map();
    const institutionAccounts = this.accountViews.filter(
      (account) => institutionView.id === account.institutionLink.institutionId,
    );
    for (const accountView of institutionAccounts) {
      const accountFilter: AccountFilter = {
        accountView,
        isSelected:
          isSelectedCountry && isSelectedInsto && selected.accounts.includes(accountView.id),
        isDisabled: !isSelectedCountry || !isSelectedInsto,
      };

      accountsFilterMap.set(accountView.id, accountFilter);
    }

    return accountsFilterMap;
  }
}

import { inject, Injectable } from "@angular/core";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { SourceCategory } from "@bitwarden/web-vault/app/models/data/blobby/source-category";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";

import { Category, getDefaultCategory } from "../../../models/data/blobby/category.data";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { DataServiceAbstraction } from "../data.service.abstraction";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { CategoryView } from "@bitwarden/web-vault/app/models/view/category/category.view";
import { CategoryStoreModel } from "@bitwarden/web-vault/app/models/store/category.store.model";
import { SourceCategoryView } from "@bitwarden/web-vault/app/models/view/source-category/source-category.view";
import { ImportStoreService } from "@bitwarden/web-vault/app/services/store/import/import.store.service";

@Injectable({
  providedIn: "root",
})
export class CategoryService implements DataServiceAbstraction {
  private userStoreService: UserStoreService;
  private importStoreService: ImportStoreService;
  constructor(
    private logService: LogService,
    private dataRepositoryService: DataRepositoryService,
    private i18n: I18nService,
  ) {
    this.userStoreService = inject(UserStoreService);
    this.importStoreService = inject(ImportStoreService);
  }

  /**
   * @deprecated
   */
  async create(newCat: Category): Promise<Category> {
    try {
      return await this.dataRepositoryService.createCategory(newCat);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async createSourceCategory(sourceCategory: SourceCategory): Promise<SourceCategory> {
    try {
      return await this.dataRepositoryService.createSourceCategory(sourceCategory);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async update(catToBeUpdated: Category, triggerObservable: boolean): Promise<Category> {
    try {
      const updatedCategory = await this.dataRepositoryService.updateCategory(catToBeUpdated);
      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return updatedCategory;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async delete(category: Category, triggerObservable: boolean): Promise<boolean> {
    try {
      const success = await this.dataRepositoryService.deleteCategory(category);

      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return success;
    } catch (e) {
      this.logService.error(e.message);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async getAll(): Promise<Category[]> {
    return await this.dataRepositoryService.getAllCategories();
  }

  /**
   * @deprecated
   */
  async getAllSourceCategories(): Promise<SourceCategory[]> {
    return await this.dataRepositoryService.getAllSourceCategories();
  }

  /**
   * @deprecated
   */
  async get(categoryId: string): Promise<Category> {
    // TODO handle edge cases
    const allCats = await this.dataRepositoryService.getAllCategories();
    try {
      return allCats.filter((cat) => cat.id === categoryId)[0];
    } catch (e) {
      throw new Error("Method not implemented.");
    }
  }

  async createDefaultSplitCategory() {
    try {
      const defaultCategory = await this.getGeneralDefaultCategory();
      const defaultSplitCategory: SplitCategoryType[] = [];
      defaultSplitCategory.push({
        categoryId: defaultCategory.id,
        weight: 1,
        roundingDefault: true,
        name: defaultCategory.name,
      });
      return defaultSplitCategory;
    } catch (e) {
      this.logService.error(e);
    }
  }

  /**
   * @deprecated
   */
  async createGeneralDefaultCategory(): Promise<Category> {
    try {
      const defaultCategory = getDefaultCategory();
      defaultCategory.name = this.i18n.t("defaultCategory");
      return await this.dataRepositoryService.createCategory(defaultCategory);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async generateDefaultCategory(): Promise<CategoryView> {
    try {
      const defaultCategoryView = this.getDefaultCategoryView();
      this.userStoreService.categories.save(defaultCategoryView);
      return defaultCategoryView;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  sourceCategoryViewByName(sourceCatName: string): SourceCategoryView {
    const categories = this.importStoreService.sourceCategory.sourceCategoryViews();
    return categories.find(
      (sourceCat) =>
        sourceCat.name.toLowerCase().replace(/\s/g, "") ===
        sourceCatName.toLowerCase().replace(/\s/g, ""),
    );
  }

  getDefaultCategoryView(): CategoryView {
    const categoryStoreModel: CategoryStoreModel = {
      dc: new Date().toISOString(),
      dm: new Date().toISOString(),
      gendef: true,
      id: crypto.randomUUID(),
      n: "No Category",
      v: 1,
      vid: null,
      w: 1,
    };

    return new CategoryView(categoryStoreModel);
  }

  /**
   * @deprecated
   * */
  async getGeneralDefaultCategory() {
    try {
      const categories = await this.getAll();
      const arrayOfCategory = categories.filter((c) => c.generalDefault === true);
      if (arrayOfCategory.length === 0) {
        return await this.createGeneralDefaultCategory();
      } else {
        return arrayOfCategory[0];
      }
    } catch (e) {
      this.logService.error(e);
    }
  }

  async getDefaultCategory() {
    try {
      const categoryViews = this.userStoreService.categories.categoryViews();
      const arrayOfCategory = categoryViews.filter((c) => c.generalDefault === true);
      if (arrayOfCategory.length === 0) {
        return await this.generateDefaultCategory();
      } else {
        return arrayOfCategory[0];
      }
    } catch (e) {
      this.logService.error(e);
    }
  }

  async getByName(catName: string) {
    try {
      const categories = await this.getAll();
      return categories.find((cat) => cat.name === catName);
    } catch (e) {
      this.logService.error(e);
    }
  }

  categoryViewByName(catName: string) {
    try {
      const categories = this.userStoreService.categories.categoryViews();
      return categories.find((cat) => cat.name === catName);
    } catch (e) {
      this.logService.error(e);
    }
  }
}

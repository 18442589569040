import { GeneratedCollection } from "../generated.collection.abstraction";
import { DependencySubscriber } from "@bitwarden/web-vault/app/services/store/dependency.subscriber.abstraction";
import { TransactionSubscriber } from "@bitwarden/web-vault/app/services/store/calculation/transaction.subscriber";
import { signal } from "@angular/core";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { toObservable } from "@angular/core/rxjs-interop";
import { sortTransactionViews } from "@bitwarden/web-vault/app/shared/utils/helper.transactionView/sort";

export class CalculateTransactionGeneratedCollection
  extends GeneratedCollection<TransactionView>
  implements DependencySubscriber<TransactionView>
{
  dependentStore = new TransactionSubscriber();

  protected _collection = signal<TransactionView[]>(null);

  collection = this._collection.asReadonly();
  collection$ = toObservable(this.collection);

  update(transactions: TransactionView[]) {
    this._collection.set([...transactions.sort(sortTransactionViews)]);
  }

  clear(): void {
    this._collection.set(null);
  }
}

import { StoreData } from "./store.data";
import { EstimateActionsEnum } from "../enum/estimate-actions.enum";
import { SplitCategoryType } from "../types/split-category-type";
import { SplitClassificationType } from "../types/split-classification-type";
import { TransactionDirection } from "../enum/transactionDirection";
import { EstimatePeriod, MonthlyOption, WeeklyOption } from "../enum/estimateType";
import { DateAsISOStringType } from "./types/store.types";
import { WizardStoreModel } from "./wizard.store.model";
import { EstimateActionData } from "../data/blobby/estimate.action.data";

export type EstimateModelVersion = 1;
export interface EstimateStoreModel extends StoreData {
  /**  **Version Number** **/
  v: EstimateModelVersion;

  /** name */
  n: string;

  /** estimateActionKeys */
  eak: EstimateActionsEnum[] /** instead of saving estimate actions in memory. we just save the keys for them and create them on the fly */;

  /** estimateActions */
  ea: EstimateActionData[];

  /** categories */
  c: SplitCategoryType[];

  /** classifications */
  cl: SplitClassificationType[];

  /** accountId */
  aid: string;

  /** direction */
  d: TransactionDirection;

  /** period */
  p: EstimatePeriod;

  /** initialValue */
  iv: number /** The value that estimate will generate transactions of off. */;

  /** symbol */
  s: string /** Transaction symbol */;

  /** startDate */
  sd: DateAsISOStringType;

  /** endDate */
  ed: DateAsISOStringType;

  /** periodicTransactionDatesFormula */
  ptdf: WeeklyOption | MonthlyOption;

  /** isRecurring */
  ir: boolean;

  /** isFixedValue */
  ifv: boolean;

  /** frequency */
  f: number;

  /** transactionGenerator */
  tg: EstimateActionsEnum /** Used to detect the right estimateAction to run to generate transactions */;
}

export const DefaultEstimateStoreModel: EstimateStoreModel = {
  dc: "",
  dm: "",
  id: "",
  v: 1,
  vid: "",
  n: "",
  eak: [],
  ea: [],
  c: [],
  cl: [],
  aid: "",
  d: undefined,
  p: undefined,
  iv: 0,
  s: "",
  sd: "",
  ed: "",
  ptdf: undefined,
  ir: false,
  ifv: false,
  f: 0,
  tg: undefined,
};

import { VaultFile } from "../../../../models/data/blobby/vault-file.data";
import { VaultFileStoreModel } from "../../../../models/store/vault-file.store.model";

export const vaultFile_v0_to_v1 = (vaultItem: VaultFile): VaultFileStoreModel => {
  return {
    stat: vaultItem.status,
    dm: new Date().toString(),
    dc: new Date().toString(),
    encK: vaultItem.encryptedKey,
    id: vaultItem.id,
    mt: vaultItem.mimeType,
    nm: vaultItem.name,
    sa: vaultItem.statementAccounts,
    sfd: vaultItem.statementFromDate.date,
    std: vaultItem.statementToDate.date,
    v: 1,
    vid: "",
  };
};

/** Define the Subscriber fields and logic **/
import { Observable } from "rxjs";
import { ModelStoreAbstraction } from "@bitwarden/web-vault/app/services/store/model.store.abstraction";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import { StoreModel } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser.type";
import { ExternalView } from "@bitwarden/web-vault/app/models/view/external.view";

export abstract class StoreSubscriber<View extends ModelView<StoreModel>> {
  protected abstract dependentStore: ModelStoreAbstraction;
  abstract getObservable(): Observable<View[] | View>;
  abstract getCollection(): View[] | View;
}

export abstract class StoreCombineSubscriber<
  View1 extends ModelView<StoreModel> | ExternalView,
  View2 extends ModelView<StoreModel> | ExternalView,
> {
  // abstract getObservable(): Observable<[View1,View2]>;
  // abstract getCollection(): [View1,View2];
}

export interface DependencySubscriber<View extends ModelView<StoreModel>> {
  dependentStore: StoreSubscriber<View>;
}

import {
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { InputTypes } from "@bitwarden/web-vault/app/models/enum/inputTypes.enum";
import { SyncIcon } from "@bitwarden/web-vault/app/models/enum/sync.enum";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { ThirdPartyTransaction } from "@bitwarden/web-vault/app/models/types/transaction.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { BasiqConnector } from "@bitwarden/web-vault/app/services/syncing/basiq/basiq-connector.service";
import { BasiqInstitutionConnection } from "@bitwarden/web-vault/app/services/syncing/basiq/basiq-institution-connection";

export type DateString = string;
export type DateFormatIndex = {
  year: number;
  month: number;
  day: number;
  separator: string;
  format: string;
};
export type TimeFormatIndex = {
  hours: number;
  minutes: number;
  seconds?: number;
  is12HourFormat: boolean;
  amPm?: string;
  format: string;
};
export type TimezoneFormatIndex = {
  timezoneName: string;
  timezoneOffset: string; // Store the timezone offset in the format "-05:00"
  timezoneOffsetMinutes?: number; // Store the timezone offset (e.g., -300 for UTC-05:00)
};
export type PossibleDateIndices = {
  forYear: number[];
  forMonth: number[];
  forDay: number[];
};

export type ImportSteps = "upload" | "arrange" | "import" | "institution";
export type MoneySeparatorType = MoneySeparatorValues.decimal | MoneySeparatorValues.thousands;
export enum MoneySeparatorValues {
  decimal = "decimal",
  thousands = "thousands",
}
export type TransactionStatusType = TransactionStatusEnum;
export type Country = { name: string; code: string };

export enum Origin {
  manual = "manual",
  basiq = "basiq",
  plaid = "plaid",
}

type GlossInputType = InputTypes;

export interface GlossInputOptions {
  value?: string;
  suffix?: string;
  label: string;
  hint?: string;
  placeholder: string;
  type?: GlossInputType;
  isRequired: boolean;
  isDisabled?: boolean;
  inputBlurred: (value: string) => void;
  inputCleared: () => void;
  inputError?: (message: string) => void;
  onInput: (event: Event) => void;
  hideClearButton?: boolean;
  testId?: string;
  customisedInputOption?: boolean;
  isSameRow?: boolean;
}

export type SyncStatusAction = "retry" | "consent" | "connect" | "merge";

export type StatusPointResult = {
  type: "success" | "failure";
  message: string;
  messageI18nKey: string;
  actions: SyncStatusAction[];
};
export type StatusPoint = {
  key: "started-syncing" | "syncing" | "ready" | "synced" | "failed" | "working-on-sync";
  icon: SyncIcon.xCircle | SyncIcon.syncAll | SyncIcon.checkCircle | SyncIcon.alertTriangle;
  data: StatusPointResult;
  lastUpdated?: string;
};

export type SyncStatusKey =
  | SyncStatusEnum.failed
  | SyncStatusEnum.synced
  | SyncStatusEnum.syncing
  | SyncStatusEnum.noBasiqUser
  | SyncStatusEnum.noBasiqConsent
  | SyncStatusEnum.noConnection
  | SyncStatusEnum.noConnectionRefresh
  | SyncStatusEnum.basiqJobFetchFail
  | SyncStatusEnum.dataFetchFail
  | SyncStatusEnum.noConnector;

export type SyncStatusType = {
  key: SyncStatusKey;
  icon: SyncIcon.xCircle | SyncIcon.syncAll | SyncIcon.checkCircle | SyncIcon.alertTriangle;
  messageKey: string;
  actions: SyncStatusAction[];
};

export enum SyncStatusEnum {
  syncing = "syncing",
  synced = "synced",
  failed = "failed",
  noConnector = "noConnector",
  noBasiqUser = "noBasiqUser",
  noBasiqConsent = "noBasiqConsent",
  noConnection = "noConnection",
  noConnectionRefresh = "noConnectionRefresh",
  basiqJobFetchFail = "basiqJobFetchFail",
  dataFetchFail = "dataFetchFail",
}

export type AccountState = {
  accountId: string;
  accountView: AccountView;
  rawTransactions: ThirdPartyTransaction[];
  isStarted: boolean;
  isCompleted: boolean;
  isMerged: boolean;
  point: StatusPoint;
  lastSyncedAt: GlossDate;
};

export type AccountOriginGroup = {
  [key in Origin]: AccountView[];
};

export type SyncState = {
  isInitialState: boolean;
  isStarted: boolean;
  isCompleted: boolean;
  isSyncableAccounts: boolean;
  accountStatus: AccountState[];
  basiqConnector: BasiqConnector;
  basiqInstitutionConnections: BasiqInstitutionConnection[];
};

export type ScenarioMessageActionType = {
  pre: string;
  text: string;
  post: string;
};

export type ScenarioMessageType = {
  info: string;
  action: ScenarioMessageActionType;
};

export type ScenarioMessagesType = {
  noPositiveBalanceAccounts: ScenarioMessageType;
  noInterestRate: ScenarioMessageType;
  singleAndPositive: ScenarioMessageType;
  singleAndNonPositive: ScenarioMessageType;
  multipleWithOnePositive: ScenarioMessageType;
  notGettingInterest: ScenarioMessageType;
  congratulations: ScenarioMessageType;
};

export type PathMessagesType = {
  [key: number]: ScenarioMessagesType;
};

export type AgGridSizeStrategy =
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy;

export type ConfirmationType = {
  title: string;
  message: string;
  type: "info" | "warning" | "error";
};

// Generate type from enum
export type ConfirmationData = Record<ConfirmationEnum, ConfirmationType>;

export type ConfirmationAttachment = {
  messageReplacement: string;
};

export type GridStrategy =
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy;

export type MultiSelectableItem = Book | Category | Classification | string;

export function isOfType<T extends string>(value: string, allowedValues: readonly T[]): value is T {
  return allowedValues.includes(value as T);
}

import { DateFormatIndex } from "@bitwarden/web-vault/app/models/types/general-types";

import { TransactionResponse } from "../../models/data/response/transaction-response";
import { TransactionDirection } from "../../models/enum/transactionDirection";

export class PlaidMapper {
  private _transaction_id: string;
  private _category_id: string;
  private _account_id: string;
  private _name: string;
  private _category: { categoryId: string; weight: number }[];
  private _currency: string;
  private _amount: string;
  private _iso_currency_code: string;
  private _transactionDate: string;

  constructor(data: any) {
    if (data === null) {
      return;
    }

    this._transaction_id = data.transaction_id;
    this._category_id = data.category_id;
    this._account_id = data.account_id;
    this._name = data.name;
    this._category = data.category !== null ? data.category : [];
    this._currency = data.iso_currency_code;
    this._amount = data.amount;
    this._iso_currency_code = data.iso_currency_code;
    this._transactionDate = data.date;
  }

  get transaction_id(): string {
    return this._transaction_id;
  }

  set transaction_id(value: string) {
    this._transaction_id = value;
  }

  get category_id(): string {
    return this._category_id;
  }

  set category_id(value: string) {
    this._category_id = value;
  }

  get account_id(): string {
    return this._account_id;
  }

  set account_id(value: string) {
    this._account_id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get category(): { categoryId: string; weight: number }[] {
    return this._category;
  }

  set category(value: { categoryId: string; weight: number }[]) {
    this._category = value;
  }

  get currency(): string {
    return this._currency;
  }

  set currency(value: string) {
    this._currency = value;
  }

  get amount(): string {
    return this._amount;
  }

  set amount(value: string) {
    this._amount = value;
  }

  get iso_currency_code(): string {
    return this._iso_currency_code;
  }

  set iso_currency_code(value: string) {
    this._iso_currency_code = value;
  }

  get transactionDate(): string {
    return this._transactionDate;
  }

  set transactionDate(value: string) {
    this._transactionDate = value;
  }

  // todo @ronald this need love,     response.direction = TransactionDirection.Out; will not work
  static castToTransactionResponse(
    data: PlaidMapper,
    dateFormatIndex: DateFormatIndex,
  ): TransactionResponse | null {
    if (data === null) {
      return null;
    }

    const response = new TransactionResponse({ ...data, dateFormatIndex });

    response.accountId = data.account_id;
    response.description = data.name;
    response.categories = data.category;
    response.direction = +data.amount < 0 ? TransactionDirection.Out : TransactionDirection.In;
    response.transactionDate = data.transactionDate;

    /** Set the GlossNumber object */
    response.quantity.setQuantityAmountSymbol(+data.amount, data.iso_currency_code);
    response.quantity.currency = data.iso_currency_code;

    return response;
  }
}

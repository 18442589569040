export const ChevronCellRenderer = () => {
  return `<div class="tw-flex tw-flex-col tw-h-full tw-justify-center">
      <svg class="tw-h-5 tw-w-5">
        <use xlink:href="images/sprite.svg#right-arrow" />
      </svg>
    </div>`;
};

export const MoreCellRenderer = () => {
  return `<div class="tw-flex tw-flex-col tw-h-full tw-justify-center tw-cursor-pointer">
    <svg class="tw-h-5 tw-w-5 tw-rotate-90">
      <use xlink:href="images/sprite.svg#more" />
    </svg>
  </div>`;
};

import { signal, inject } from "@angular/core";
import { LogService } from "@bitwarden/common/abstractions/log.service";

import {
  CalculatedBalances,
  CalculatedBalanceCollection,
  CalculatedBalanceParams,
} from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";

export class BalanceByAccountsCalculatedCollection extends CalculatedBalanceCollection {
  protected log: LogService = inject(LogService);

  protected type: "BalanceByAccounts";
  protected calculations: CalculatedBalances = new Map<string, number>();

  protected _balances = signal<Map<string, number>>(null);
  readonly balances = this._balances.asReadonly();

  /**
   * Uses the filteredTransactions stored on the calculation store to update
   * the calculations for balance by account
   */
  async updateCalculations(parameters: CalculatedBalanceParams): Promise<void> {
    const filteredDate = parameters?.period?.endDate ? parameters.period.endDate : new Date();
    const balancesByAccounts: Record<string, number> = await this.getBalanceByAccount(
      parameters.webWorkerQueue,
      parameters.calculatedTransactions,
      filteredDate,
    );

    this.setBalances(balancesByAccounts);
  }

  getCalculations(): CalculatedBalances {
    return this.calculations;
  }

  clear() {
    this.calculations.clear();
    this._balances.set(null);
  }

  triggerChanges(): void {
    // this.updateCalculations();
  }

  private setBalances(balancesByAccounts: Record<string, number>): void {
    this.calculations = new Map(Object.entries(balancesByAccounts));
    this._balances.set(this.calculations);
  }
}

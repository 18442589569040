import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { EstimateStoreModel } from "../../../../models/store/estimate.store.model";
import { EstimateResponse } from "../../../../models/data/response/estimate.response";
import { Estimate } from "../../../../models/data/blobby/estimate.data";
import { estimate_v0_to_v1 } from "../model-dto/estimate";

export class EstimateParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Wizard parser version ${version}`);
    }

    if (version === 0) {
      return EstimateParser_v0;
    }
    return EstimateParser_v1;
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const EstimateParser_v1 = (estimateItem: any): any => estimateItem as EstimateStoreModel;
const EstimateParser_v0 = (estimateItem: any): any => {
  const response = new EstimateResponse(estimateItem);
  const estimate = new Estimate(response);
  const upgraded = estimate_v0_to_v1(estimate);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = estimateItem.vid ?? "";
  return EstimateParser_v1(upgraded);
};

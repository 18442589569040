import { signal, WritableSignal } from "@angular/core";
import { GridApi, GridOptions } from "ag-grid-enterprise";

import { SymbolRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/symbol-renderer/symbol-renderer.component";
import { SymbolRateRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/symbol-rate-renderer/symbol-rate-renderer.component";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";
import { SymbolTableSubscription, TableSource } from "./symbol-table.subscription";
import { getRowClass, getRowHeight } from "@bitwarden/web-vault/app/gloss/tables/tables.utils.js";
import { ChevronCellRenderer } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/end-icon-renderer/end-icon-renderer.component";

export class SymbolTable extends SymbolTableSubscription {
  gridApi: GridApi;
  options: GridOptions<TableSource>;
  selectedSymbol: WritableSignal<SymbolView> = signal<SymbolView>(null);
  private selectedRowIndex: number;

  constructor() {
    super();

    this.options = {
      columnDefs: [
        { field: "symbol", cellRenderer: SymbolRendererComponent },
        { field: "rate", flex: 1, cellRenderer: SymbolRateRendererComponent },
        {
          field: "rightChevron",
          width: 60,
          cellRenderer: ChevronCellRenderer,
          suppressHeaderMenuButton: true,
        },
      ],
      groupDisplayType: "groupRows",
      rowDragManaged: false,
      groupDefaultExpanded: -1,
      onRowClicked: (params) => this.onRowClicked(params),
      onGridReady: (params) => {
        this.gridApi = params.api;
      },
      getRowHeight: getRowHeight,
      getRowClass: (params) => getRowClass(params, this.selectedRowIndex),
    };
  }

  private onRowClicked(params: any): void {
    this.selectedRowIndex = params.node.rowIndex;
    this.gridApi.redrawRows();
    // todo @alex this is not done. I Want to pass the proped View type tyo the symbol form. which will be a symbol with the c_asOf ReferenceView populated
    const symbol = params?.data?.symbol;
    if (symbol instanceof SymbolView && params?.data?.rightChevron) {
      this.selectedSymbol.set(symbol);
    } else {
      this.selectedSymbol.set(null);
    }
  }
}

import { Injectable } from "@angular/core";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

import {
  Classification,
  getDefaultClassification,
} from "../../../models/data/blobby/classification.data";

@Injectable({
  providedIn: "root",
})
export class ClassificationService {
  constructor(
    private logService: LogService,
    private dataRepositoryService: DataRepositoryService,
    private i18n: I18nService,
  ) {}

  /**
   * @deprecated
   */
  async create(newCls: Classification): Promise<Classification> {
    try {
      return await this.dataRepositoryService.createClassification(newCls);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async createGeneralDefaultClassification(): Promise<Classification> {
    try {
      const defaultClassification = getDefaultClassification();
      defaultClassification.name = this.i18n.t("defaultClassification");
      return await this.dataRepositoryService.createClassification(defaultClassification);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async getGeneralDefaultClassification() {
    try {
      const classifications = await this.getAll();
      const arrayOfClassification = classifications.filter((c) => c.generalDefault === true);
      if (arrayOfClassification.length === 0) {
        return await this.createGeneralDefaultClassification();
      } else {
        return arrayOfClassification[0];
      }
    } catch (e) {
      this.logService.error(e);
    }
  }

  async createDefaultSplitClassification() {
    try {
      const defaultClassification = await this.getGeneralDefaultClassification();
      const defaultSplitClassification: SplitClassificationType[] = [];
      defaultSplitClassification.push({
        classificationId: defaultClassification.id,
        weight: 1,
        roundingDefault: true,
        name: defaultClassification.name,
      });
      return defaultSplitClassification;
    } catch (e) {
      this.logService.error(e);
    }
  }

  /**
   * @deprecated
   */
  async update(
    classification: Classification,
    triggerObservable: boolean,
  ): Promise<Classification> {
    try {
      const updatedCls = await this.dataRepositoryService.updateClassification(classification);
      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return updatedCls;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async delete(classification: Classification, triggerObservable: boolean): Promise<boolean> {
    try {
      const success = await this.dataRepositoryService.deleteClassification(classification);

      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return success;
    } catch (e) {
      this.logService.error(e.message);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async getAll(): Promise<Classification[]> {
    return await this.dataRepositoryService.getAllClassifications();
  }

  async get(id: string) {
    const allClasses = await this.getAll();
    const classArr = allClasses.filter((c) => c.id === id);

    return classArr[0];
  }
}

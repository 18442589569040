import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { InstitutionStoreModel } from "../../../../models/store/institution.store.model";
import { InstitutionResponse } from "../../../../models/data/response/institution.response";
import { Institution } from "../../../../models/data/blobby/institution.data";
import { institution_v0_to_v1 } from "../model-dto/institutions";

export class InstitutionParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw Error(`Unsupported Institution parser version ${version}`);
    }

    if (version === 0) {
      return InstitutionParser_v0;
    } else {
      return InstitutionParser_v1;
    }
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const InstitutionParser_v1 = (vaultItem: any): InstitutionStoreModel =>
  vaultItem as InstitutionStoreModel;
const InstitutionParser_v0 = (vaultItem: any): InstitutionStoreModel => {
  const response = new InstitutionResponse(vaultItem);
  const institution = new Institution(response);
  const upgraded = institution_v0_to_v1(institution);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = vaultItem.vid ?? "";
  return InstitutionParser_v1(upgraded);
};

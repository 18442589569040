import { StoreModelNames } from "../../dali/type/dali.type";
import { computed, inject, signal } from "@angular/core";
import { ReferenceDataStoreModel } from "../../../models/store/reference-data.store.model";
import { StoreModelCollection } from "../model.collection.abstraction";
import { ReferenceDataView } from "../../../models/view/reference-data/reference-data.view";

import { ModelView } from "../../../models/view/model.view";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ForexRateView } from "@bitwarden/web-vault/app/models/view/reference-data/forex-rate.view";

export class ReferenceDataStoreCollection extends StoreModelCollection<
  ReferenceDataStoreModel,
  ModelView<ReferenceDataStoreModel>
> {
  protected log: LogService = inject(LogService);

  /** 3 - Store Model and View **/
  readonly type: StoreModelNames = "ReferenceDataStoreModel";
  protected models: Map<string, ReferenceDataStoreModel> = new Map<
    string,
    ReferenceDataStoreModel
  >();

  private _referenceDataViews = signal<ReferenceDataView[]>(null);
  readonly referenceDataViews = this._referenceDataViews.asReadonly();

  triggerChanges(): void {
    const views: ReferenceDataView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new ReferenceDataView(model)) as ReferenceDataView);
    });
    this._referenceDataViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._referenceDataViews.set(null);
  }

  async processNewForexRates(forexDataViews: ForexRateView[]) {
    this.log.info("New forex rates fetched");
    const referenceData: ReferenceDataView[] = [];

    /** map on existing rates id **/
    const refDataIdMap = this.referenceDataViews().reduce((acc, i) => {
      acc.set(i.id, true);
      return acc;
    }, new Map<string, boolean>());

    forexDataViews.map((forexDataView) => {
      for (const symbol in forexDataView.symbols) {
        const id = `${forexDataView.glossDate.date}|${forexDataView.base}|${symbol}`;

        /** Append any new rates **/
        if (!refDataIdMap.has(id)) {
          const referenceStoreModel: ReferenceDataStoreModel = {
            dc: new Date().toISOString(),
            dm: new Date().toISOString(),
            id: `${forexDataView.glossDate.date}|${forexDataView.base}|${symbol}`,
            rate: forexDataView.symbols[symbol],
            v: 1,
            vid: "",
          };
          referenceData.push(new ReferenceDataView(referenceStoreModel));
        }
      }
    });

    if (referenceData.length > 0) {
      await this.saveToVault(referenceData, false);
    }
    // todo @alex work arround to retrigger the calculation on base currency change but no new rates imported.
    this.triggerChanges();
  }
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { NavigationEnd, Router } from "@angular/router";
import { map, Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { I18nService } from "../../../../../../libs/common/src/abstractions/i18n.service";
import {
  canAccessAdmin,
  isNotProviderUser,
  OrganizationService,
} from "../../../../../../libs/common/src/abstractions/organization/organization.service.abstraction";
import { PlatformUtilsService } from "../../../../../../libs/common/src/abstractions/platformUtils.service";
import { ProviderService } from "../../../../../../libs/common/src/abstractions/provider.service";
import { SyncService } from "../../../../../../libs/common/src/abstractions/sync/sync.service.abstraction";
import { TokenService } from "../../../../../../libs/common/src/abstractions/token.service";
import { devFlagEnabled } from "../../../../../../libs/common/src/misc/flags";
import { Organization } from "../../../../../../libs/common/src/models/domain/organization";
import { Provider } from "../../../../../../libs/common/src/models/domain/provider";
import { Book } from "../../models/data/blobby/book.data";
import { Category } from "../../models/data/blobby/category.data";
import { Classification } from "../../models/data/blobby/classification.data";
import { GlossRouteEnum } from "../../models/enum/routes.enum";
import { TransactionDirection } from "../../models/enum/transactionDirection";
import { GranularityProperty } from "../../models/types/balanceGroupingTypes";
import { SyncState } from "../../models/types/general-types";
import { GlossRoute } from "../../models/types/navigation.types";
import { ColumnStateService } from "../../services/dashboard/columnState-service";
import { DashboardService } from "../../services/dashboard/dashboard-service";
import { SideMenuService } from "../../services/menu/side-menu.service";
import { HelperCommon } from "../../shared/utils/helper-common";
import { HelperTooltip } from "../../shared/utils/helper-tooltip";
import { glossRouteInfo } from "../sections-info-texts";

import { glossRoutes } from "./navbar.routes";

@Component({
  selector: "app-navbar",
  templateUrl: "navbar.component.html",
})
export class NavbarComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  protected readonly GlossRouteEnum = GlossRouteEnum;
  protected readonly HelperCommon = HelperCommon;

  tooltipInfo: string;
  glossRoute: GlossRoute = null;
  selfHosted = false;
  name: string;
  email: string;
  providers: Provider[] = [];
  userId: string;
  organizations$: Observable<Organization[]>;
  isGlossMenuOpen = true;
  isInfoOpen = false;
  isSyncOpen = false;
  isSyncing = false;
  isAccountsToSync = false;
  syncState: SyncState;
  selectedFilterItem: string;
  granularityOptions: Array<GranularityProperty> = [];
  selectedGranularityOption: string;

  accounts: Book[];
  selectedAccounts: Book[] = [];
  accountsLabel = "accounts";
  accountsSelectElementId = "accountsSelect";
  displayKey = "name";
  /** Holds the options of direction user has chosen  */
  selectedDirections: TransactionDirection[] = [TransactionDirection.In, TransactionDirection.Out];
  selectedCategories: Category[] = [];
  selectedClassifications: Classification[] = [];

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild("menuContainer") menuContainer: ElementRef;
  tooltip: HelperTooltip;

  constructor(
    private platformUtilsService: PlatformUtilsService,
    private tokenService: TokenService,
    private providerService: ProviderService,
    private syncService: SyncService,
    private organizationService: OrganizationService,
    private i18nService: I18nService,
    private glossMenuService: SideMenuService,
    private router: Router,
    private dashboardService: DashboardService,
    private columnStateService: ColumnStateService,
  ) {
    this.listenForGlossRouteChange();
    this.tooltip = new HelperTooltip();
    this.selfHosted = this.platformUtilsService.isSelfHost();
  }

  async ngOnInit() {
    this.dashboardService.granularityOptions$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((options) => {
        this.granularityOptions = options;
        this.selectedGranularityOption = this.dashboardService.granularity;
      });

    this.dashboardService.dashboardFilter$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((filter) => {
        if (filter) {
          this.accounts = this.dashboardService.allAccounts;
          this.selectedAccounts = filter.accounts;
          this.selectedDirections = filter.directions;
          this.selectedCategories = filter.categories;
          this.selectedClassifications = filter.classifications;
        }
      });

    this.name = await this.tokenService.getName();
    this.email = await this.tokenService.getEmail();
    this.userId = await this.tokenService.getUserId();
    this.columnStateService.setUserId(this.userId);
    if (this.name == null || this.name.trim() === "") {
      this.name = this.email;
    }

    // Ensure providers and organizations are loaded
    if ((await this.syncService.getLastSync()) == null) {
      await this.syncService.fullSync(false);
    }
    //this.isAccountsToSync = await this.bookService.hasAutoBooks();
    this.providers = await this.providerService.getAll();

    this.organizations$ = this.organizationService.organizations$.pipe(
      map((orgs) => orgs.filter(isNotProviderUser)),
      canAccessAdmin(this.i18nService),
    );

    this.glossMenuService.isGlossMenuOpen$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isOpen) => (this.isGlossMenuOpen = isOpen));
  }

  /** filters the transactions based on selected accounts */
  async applySelectedAccounts(selectedAccounts: Book[]) {
    try {
      this.dashboardService.setIsSpinner(true);
      this.selectedAccounts = selectedAccounts;
      await this.dashboardService.filterDashboardTransactions(
        selectedAccounts,
        this.selectedDirections,
        this.selectedCategories,
        this.selectedClassifications,
      );
    } catch (e) {
      this.dashboardService.setIsSpinner(false);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Listens for changes in the router events and updates the current gloss route accordingly.
   *
   * This method subscribes to the router events and filters out events that are not instances of NavigationEnd.
   * When a NavigationEnd event occurs, it updates the current gloss route based on the URL of the event.
   * And finally based on the glossRoute it updates the header in the body of the page.
   */
  listenForGlossRouteChange() {
    this.router.events
      .pipe(
        // Filter out events that are not NavigationEnd events
        filter((event) => event instanceof NavigationEnd),
        // Automatically unsubscribe when the component is destroyed
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((event: NavigationEnd) => {
        // Cast the URL of the event to a GlossRouteEnum
        const url: GlossRouteEnum = event.url as GlossRouteEnum;
        // Update the current gloss route based on the URL
        this.glossRoute = glossRoutes[url];
        this.tooltipInfo = <string>glossRouteInfo[url];
      });
  }

  onFilterMenuClick(item: string) {
    this.selectedFilterItem = item;
  }

  async updateSelectedGranularityOption(value: GranularityProperty) {
    this.selectedGranularityOption = value;
    await this.dashboardService.updateGranularity(value);
  }
}

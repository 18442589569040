import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DropdownBaseComponent } from "@bitwarden/web-vault/app/components/dropdown/dropdown-base.component";

@Component({
  selector: "app-dropdown-multiselect",
  templateUrl: "./dropdown-multiselect.component.html",
})
export class DropdownMultiselectComponent extends DropdownBaseComponent {
  @Input() options: string[] = [];
  @Input() selectedOptions: string[] = [];
  @Input() disabledOptions: string[] = [];
  @Input() title: string = "";
  @Input() testId: string = "";
  @Output() selectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  toggleOption(option: string) {
    if (this.isDisabled(option)) {
      return;
    }
    const index = this.selectedOptions.indexOf(option);
    if (index === -1) {
      this.selectedOptions.push(option);
    } else {
      this.selectedOptions.splice(index, 1);
    }
    this.selectionChange.emit(this.selectedOptions);
  }

  isSelected(option: string): boolean {
    return this.selectedOptions.includes(option);
  }

  isDisabled(option: string): boolean {
    return this.disabledOptions.includes(option);
  }

  isAllDisabled(): boolean {
    return this.options.every((option: string) => this.disabledOptions.includes(option));
  }

  selectAllOptions() {
    if (this.isAllDisabled()) {
      return;
    }
    if (this.areAllOptionsSelected()) {
      this.selectedOptions = [];
    } else {
      this.selectedOptions = [
        ...this.options.filter((option) => !this.disabledOptions.includes(option)),
      ];
    }
    this.selectionChange.emit(this.selectedOptions);
  }

  areAllOptionsSelected(): boolean {
    return this.selectedOptions.length === this.options.length;
  }

  getOptionsText(): string {
    return this.areAllOptionsSelected()
      ? "All"
      : this.selectedOptions.length > 0
        ? this.selectedOptions.join(", ")
        : "Select options";
  }
}

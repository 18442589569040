import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import {
  BasiqAccountResponse,
  BasiqConnection,
  GlossConsentResponse,
  BasiqResponse,
  BasiqTransactionParams,
  GlossInstitutionResponse,
  GlossBasiqUserResponse,
  GlossConnectionsResponse,
  GlossConnectionResponse,
  GlossConnectionRefreshResponse,
  GlossGetBasiqJobResponse,
  GlossBasiqTokenResponse,
} from "@bitwarden/web-vault/app/models/types/basiq.types";
import { BasiqConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { GlossApiProxyRequest } from "@bitwarden/web-vault/app/shared/utils/helper.glos-api-call/gloss-api-proxy-request";

import { DataRepositoryService } from "../DataRepository/data-repository.service";

const basiqConfig = process.env.BASIQ as unknown as BasiqConfigType;

@Injectable({
  providedIn: "root",
})
export class BasiqIoConfigService {
  readonly product: Array<string> = ["transactions"];
  private readonly glossApiUrl: string;

  constructor(
    private http: HttpClient,
    private dataRepositoryService: DataRepositoryService,
    private apiService: ApiService,
    private globalService: GlobalService,
    private logger: LogService,
  ) {
    this.glossApiUrl = `${basiqConfig.url}/${basiqConfig.apiStage}`;
  }

  /**
   * @async
   * @returns {Promise<BasiqResponse>} - A promise that resolves to the Basiq response containing account information.
   * @throws {Error} - Throws an error if the request fails, logging the error and showing a warning message.
   */
  async getAccounts(): Promise<BasiqAccountResponse> {
    try {
      const data = {
        action: "GET",
        endpoint: "accounts",
      };
      return await this.getProxyGlossApi(data);
    } catch (error) {
      this.logger.error(`Failed to retrieve Consent: ${error}`);
      const d = error.response.data[0];
      this.globalService.showMessageAsIs("warning", d.title, d.detail);
    }
  }

  /**
   * @async
   * @param {BasiqTransactionParams} transactionParams - The endpoint from which to fetch transaction information.
   * @returns {Promise<any>} - A promise that resolves to the transaction data.
   * @throws {Error} - Throws an error if the request fails, logging the error.
   */
  async getTransactions(transactionParams: BasiqTransactionParams): Promise<BasiqResponse> {
    try {
      return await this.getProxyGlossApi(transactionParams);
    } catch (error) {
      this.logger.error(`Failed to fetch Plaid token: ${error}`);
      throw error;
    }
  }

  /**
   * @async
   * @returns {Promise<GlossConnectionsResponse>} - A promise that resolves to the Basiq response containing the connections.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.
   */
  async getConnections(): Promise<GlossConnectionsResponse> {
    try {
      const data = { provider: "basiq", endpoint: "connections" };
      return await this.getProxyGlossApi(data);
    } catch (error) {
      this.logger.error(`Failed to fetch Plaid token: ${error}`);
      throw error;
    }
  }

  async getAccountConnections(accountView: AccountView): Promise<GlossConnectionsResponse> {
    try {
      const data = {
        provider: "BASIQ",
        institutionId: accountView.institution.basiqId,
        endpoint: "accountConnections",
      };
      return await this.getProxyGlossApi(data);
    } catch (error) {
      this.logger.error(`Failed to fetch Plaid token: ${error}`);
      throw error;
    }
  }

  /**
   * @async
   * @param {string} consentId - The ID of the consent to remove.
   * */
  async removeConsent(consentId: string): Promise<boolean> {
    try {
      const data = {
        provider: "BASIQ",
        endpoint: "removeConsent",
        consentId,
      };
      return await this.getProxyGlossApi(data);
    } catch (error) {
      this.logger.error(`Failed to fetch Plaid token: ${error}`);
      throw error;
    }
  }

  /**
   * @async
   * @param {string} connectionId - The ID of the connection to fetch.
   * @returns {Promise<GlossConnectionResponse>} - A promise that resolves to the Basiq response containing the connection.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.
   */
  async getConnectionById(connectionId: string): Promise<GlossConnectionResponse> {
    const data = { connectionId, endpoint: "connection" };
    return await this.getProxyGlossApi(data);
  }

  /**
   * @async
   * @param {string} institutionId - The ID of the institution to fetch.
   * @returns {Promise<GlossInstitutionResponse>} - A promise that resolves to the Basiq response containing the institution.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.
   */
  async getInstitutionById(institutionId: string): Promise<GlossInstitutionResponse> {
    const data = { endpoint: `institution`, institutionId };
    return await this.getProxyGlossApi(data);
  }

  /**
   * @async
   * @param {string} jobId - The ID of the job to fetch.
   * @returns {Promise<GlossGetBasiqJobResponse>} - A promise that resolves to the Basiq response containing the job.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.

   */
  async getJob(jobId: string): Promise<GlossGetBasiqJobResponse> {
    const data = { endpoint: `jobs`, jobId };
    return await this.getProxyGlossApi(data);
  }

  /**
   * @async
   * @returns {Promise<GlossConsentResponse>} - A promise that resolves to the Basiq response containing the consent.
   *
   * @throws {Error} - Throws an error if the request fails, logging the error.

   */
  async getConsent(): Promise<GlossConsentResponse> {
    const data = { provider: "BASIQ", action: "GET", endpoint: "consents" };
    return await this.getProxyGlossApi(data);
  }

  /**
   * @async
   * @returns {Promise<GlossBasiqUserResponse>} - A promise that resolves to the newly created Basiq user.
   *
   * @throws {Error} - Throws an error if the request to the Basiq API fails, logging the error.
   */
  async createBasiqIoUser(): Promise<GlossBasiqUserResponse> {
    try {
      const path = basiqConfig.endpoint.user;
      return await this.dataRepositoryService.send("PUT", path, null, true, true, this.glossApiUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Plaid token:", error);
      throw error;
    }
  }

  async getBasiqUser(): Promise<GlossBasiqUserResponse> {
    try {
      const path = basiqConfig.endpoint.user;
      return await this.dataRepositoryService.send("GET", path, null, true, true, this.glossApiUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Plaid token:", error);
      throw error;
    }
  }

  /**
   * @async
   * @param {BasiqConnection} connection - The connection to be refreshed.
   * @returns {Promise<GlossConnectionRefreshResponse>} - A promise that resolves to the Basiq job associated with the refresh operation.
   *
   * @throws {Error} - Throws an error if the request to the Basiq API fails, logging the error.
   */
  async refreshConnection(connection: BasiqConnection): Promise<GlossConnectionRefreshResponse> {
    const data = {
      provider: "BASIQ",
      connectionId: connection.id,
      action: "POST",
      endpoint: "refreshConnection",
    };
    return await this.getProxyGlossApi(data);
  }

  async createClientAccessToken(): Promise<GlossBasiqTokenResponse> {
    try {
      const path = basiqConfig.endpoint.token;

      return await this.dataRepositoryService.send("GET", path, null, true, true, this.glossApiUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Plaid token:", error);
      throw error;
    }
  }

  /**
   * @deprecated
   * */
  async retrieveTransactions(
    lastTransactionDate: string,
    account: Book,
    nextPath?: string,
  ): Promise<BasiqResponse> {
    try {
      const transactionsParams: BasiqTransactionParams = {
        lastTransactionDate,
        accountId: account.basiqAccountId,
        nextPath,
        endpoint: "transactions",
      };
      return await this.getTransactions(transactionsParams);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Basiq token:", error);
    }
  }

  async getTransactionsFromBasiq(
    lastTransactionDate: string,
    account: AccountView,
    nextPath?: string,
  ): Promise<BasiqResponse> {
    try {
      const transactionsParams: BasiqTransactionParams = {
        lastTransactionDate,
        accountId: account.basiqAccountId,
        nextPath,
        endpoint: "transactions",
      };
      return await this.getTransactions(transactionsParams);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch Basiq token:", error);
    }
  }

  getBasiqPathToTransactions(
    nextPath: string,
    limit: number,
    lastTransactionDate: string,
    account: Book,
  ) {
    return {
      limit,
      lastTransactionDate,
      accountId: account.id,
      nextPath,
    };
  }

  async getProxyGlossApi(data: any) {
    try {
      const glossRequestObject = new GlossApiProxyRequest(data);
      return await this.dataRepositoryService.send(
        glossRequestObject.method,
        glossRequestObject.path,
        glossRequestObject.data,
        glossRequestObject.authed,
        glossRequestObject.hasResponse,
        glossRequestObject.url,
        glossRequestObject.alterHeaders,
      );
    } catch (error) {
      this.logger.error(`Failed to fetch Plaid token: ${error}`);
      throw error;
    }
  }
}

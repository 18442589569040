import { CipherView } from "@bitwarden/common/models/view/cipher.view";
import { inject } from "@angular/core";
import { CipherService } from "@bitwarden/common/abstractions/cipher.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { VaultStatistic } from "@bitwarden/web-vault/app/services/dali/vault.statistics";
import {
  StoreModelNames,
  StoreVaultCodeByModelName,
  VaultCodeEnum,
} from "@bitwarden/web-vault/app/services/dali/type/dali.type";
import { StoreModel } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser.type";
import { Queue } from "@bitwarden/web-vault/app/services/dali/vault.writer.queue";
import {
  BatchData,
  BatchingStrategy,
} from "@bitwarden/web-vault/app/services/dali/vault.writer.strategy";
import { StoreData } from "@bitwarden/web-vault/app/models/store/store.data";
import { CipherType } from "@bitwarden/common/enums/cipherType";
import { Cipher } from "@bitwarden/common/models/domain/cipher";

import { VaultModelUpgrader } from "@bitwarden/web-vault/app/services/dali/vault.model.upgrader";

interface VaultModelItem {
  type: StoreModelNames;
  model: StoreModel;
}

type VaultIdPipeVaultCode = string;

enum CRUD {
  update = "update",
  create = "create",
  delete = "delete",
}

export interface VaultWriterResult {
  updated: Map<VaultCodeEnum, StoreData[]>;
  deleted: Map<VaultCodeEnum, StoreData[]>;
  created: Map<VaultCodeEnum, StoreData[]>;
}

export class VaultWriter {
  protected cipherService: CipherService = inject(CipherService);
  protected log: LogService = inject(LogService);
  protected vaultStatistics: VaultStatistic = VaultStatistic.getInstance();
  protected vaultModelUpgrader: VaultModelUpgrader = VaultModelUpgrader.getInstance();

  private toDelete: Queue<VaultModelItem> = new Queue();
  private toUpdate: Queue<VaultModelItem> = new Queue();
  private toCreate: Queue<VaultModelItem> = new Queue();

  async syncToVault(
    type: StoreModelNames,
    models: StoreModel[],
  ): Promise<Map<VaultCodeEnum, StoreModel[]>> {
    // Separate new Model with Update
    for (const model of models) {
      /** If Flag for DELETE **/
      if (model.vid && model.del) {
        /** Replace by toDelete when batching support is correctly implemented **/
        this.toDelete.enqueue({ type, model });
      }

      /** If Flag as UPDATE **/
      if (model.vid && !model.del) {
        this.toUpdate.enqueue({ type, model });
      }

      /** If Flag as CREATE **/
      if (!model.vid && !model.del) {
        this.toCreate.enqueue({ type, model });
      }

      /** NOT IN Vault and DELETE **/
      if (!model.vid && model.del) {
        this.log.warning(`Try to delete ${model} but was never created.`);
      }
    }

    /** Maybe there is edge case to thing of like what if a delete and an update are in the same batch? **/
    const writerResult: VaultWriterResult = {
      updated: await this.commitUpdate(),
      deleted: await this.commitDelete(),
      created: await this.commitCreate(),
    };

    /** Generate the Map containing the new updated system properties on the model. **/
    /** This only apply for created model and updated model **/
    return new Map<VaultCodeEnum, StoreModel[]>([
      ...writerResult.created,
      ...writerResult.updated,
      ...writerResult.deleted,
    ]);
  }

  private async commitDelete(): Promise<Map<VaultCodeEnum, StoreModel[]>> {
    const results: VaultModelItem[] = [];

    const allItemsToDelete = this.toDelete.takeAll();
    const modelType = allItemsToDelete[0]?.type;
    const vaultItemMap: Map<string, StoreModel[]> = this.groupItemsByVid(allItemsToDelete);

    for (const [vid, items] of vaultItemMap) {
      const isDeleteAll = this.vaultStatistics.statistics.get(vid).itemCount === items.length;
      if (isDeleteAll) {
        await this.cipherService.deleteWithServer(vid);
      } else {
        const remainingItems = await this.partiallyDelete(vid, items);
        remainingItems.forEach((remItm) => {
          results.push({ type: modelType, model: remItm });
        });
      }
    }

    /*  for (const queueItem of this.toDelete.takeAll()) {
      await this.cipherService.deleteWithServer(queueItem.model.vid);
      results.push(queueItem);
    }*/
    return this.itemsToMap(results);
  }

  private async partiallyDelete(vid: string, itemsToDelete: StoreData[]) {
    const cipherView = await this.cipherService.getCipherView(vid);
    const original: StoreData[] = JSON.parse(cipherView.notes);
    const remainingItems = original.filter(
      (org) => !itemsToDelete.some((item) => item.id === org.id),
    );
    await this.updateCipherAndSend(cipherView, remainingItems);

    return remainingItems;
  }

  private groupItemsByVid(allItemsToDelete: VaultModelItem[]) {
    const itemsVidMap: Map<string, StoreModel[]> = new Map();
    allItemsToDelete.forEach((item) => {
      if (itemsVidMap.has(item.model.vid)) {
        itemsVidMap.get(item.model.vid).push(item.model);
      } else {
        itemsVidMap.set(item.model.vid, [item.model]);
      }
    });

    return itemsVidMap;
  }

  private async commitCreate(): Promise<Map<VaultCodeEnum, StoreModel[]>> {
    const results: Map<VaultCodeEnum, StoreModel[]> = new Map<VaultCodeEnum, StoreModel[]>();

    /** Unqueue and process the batches **/
    const takeItemFromQueue: VaultModelItem[] = this.toCreate.takeAll();

    /** Group by Vault cipher Code **/
    const createMap = this.itemsToMap(takeItemFromQueue);

    for (const [vaultCode, models] of createMap.entries()) {
      results.set(vaultCode, []);
      const batches: Array<Array<StoreData>> = BatchingStrategy.getBatches(models);
      for (const batch of batches) {
        try {
          /** Send the batch to the server **/
          const cipherView = new CipherView();
          cipherView.name = `${vaultCode}-${crypto.randomUUID()}`;

          const cipher = await this.encryptAndSend(cipherView, batch);

          /** Set system properties on the newly created batch **/
          results.get(vaultCode).push(...this.setSystemProperties(batch, cipher, CRUD.create));
          this.vaultStatistics.statistics.set(cipher.id, { itemCount: batch.length });
        } catch (e) {
          this.log.error("Something went wrong when sabing the batch");
          this.log.error(e);
          this.log.error(JSON.stringify(batch));
        }
      }
    }

    return results;
  }

  private async commitUpdate(): Promise<Map<VaultCodeEnum, StoreModel[]>> {
    const results: Map<VaultCodeEnum, StoreModel[]> = new Map<VaultCodeEnum, StoreModel[]>();

    /** Unqueue all and restructure for processing **/
    const takeItemFromQueue: VaultModelItem[] = this.toUpdate.takeAll();
    const groupPerVault: Map<VaultIdPipeVaultCode, StoreModel[]> =
      this.groupPerVault(takeItemFromQueue);

    // LOOP All vault id
    for (const [vaultId, models] of groupPerVault.entries()) {
      /** Get the vault id and the type back from the key**/
      const vid = vaultId.split("|")[0] as string;
      const vaultCode = StoreVaultCodeByModelName[
        vaultId.split("|")[1] as StoreModelNames
      ] as VaultCodeEnum;

      if (!results.has(vaultCode)) {
        results.set(vaultCode, []);
      }

      /** Load original server data **/
      const cipherView = await this.cipherService.getCipherView(vid);
      const original = JSON.parse(cipherView.notes);

      /** Need to parse the original array and upgrade it if the version is wrong. **/
      /** Get the target version from elements that are being saved. models[0].v will be = to the latest **/
      const upgradedCollection = this.vaultModelUpgrader.upgradeCollection(
        original,
        vaultCode,
        models[0].v,
      );

      /** Update Ciphers **/
      this.updateOriginalCipherArray(upgradedCollection, models);

      /** Recalculate the batching if this need to changes **/
      const batches: BatchData = BatchingStrategy.getBatches(upgradedCollection);

      if (!results.has(vaultCode)) {
        results.set(vaultCode, []);
      }

      /** Send the first batch as an update, **/
      await this.updateCipherAndSend(cipherView, batches.shift());
      results.get(vaultCode).push(...this.setSystemProperties(models, cipherView, CRUD.update));
      this.vaultStatistics.statistics.set(cipherView.id, { itemCount: original.length });

      /** Send the next batches as new ciphers **/
      if (batches.length > 0) {
        for (const batch of batches) {
          const cipherView = new CipherView();
          cipherView.name = `${vaultCode}-${crypto.randomUUID()}`;

          const cipher = await this.encryptAndSend(cipherView, batch);
          results.get(vaultCode).push(...this.setSystemProperties(batch, cipher, CRUD.update));
          this.vaultStatistics.statistics.set(cipherView.id, { itemCount: batch.length });
        }
      }
    }

    return results;
  }

  private updateOriginalCipherArray(original: any[], toUpdate: StoreModel[]) {
    for (const model of toUpdate) {
      const replaceIndex = original.findIndex((m) => {
        return m.id ? m.id === model.id : m._id === model.id;
      });
      if (replaceIndex > -1) {
        original[replaceIndex] = model;
      } else {
        this.log.error(`commitUpdate model ${model.id} not in original array.`);
      }
    }
  }

  private groupPerVault(
    takeItemFromQueue: VaultModelItem[],
  ): Map<VaultIdPipeVaultCode, StoreModel[]> {
    const groupPerVaultId = new Map<VaultIdPipeVaultCode, StoreModel[]>();
    for (const item of takeItemFromQueue) {
      const key = `${item.model.vid}|${item.type}`;
      if (groupPerVaultId.has(key)) {
        groupPerVaultId.get(key).push(item.model);
      } else {
        groupPerVaultId.set(key, [item.model]);
      }
    }
    return groupPerVaultId;
  }

  private async encryptCipher(cipherView: CipherView, batchItems: StoreData[]): Promise<Cipher> {
    /**  todo Add a replacer that blacklist vid, del and dc for updated CRUD **/
    cipherView.notes = JSON.stringify(batchItems);
    cipherView.type = CipherType.SecureNote;
    cipherView.favorite = false;

    return await this.cipherService.encrypt(cipherView);
  }
  private async encryptAndSend(
    cipherView: CipherView,
    batchItems: StoreData[],
  ): Promise<CipherView> {
    const encCipher = await this.encryptCipher(cipherView, batchItems);
    await this.cipherService.createWithServer(encCipher);
    cipherView.id = encCipher.id;
    return cipherView;
  }

  private async updateCipherAndSend(
    cipherView: CipherView,
    batchItems: StoreData[],
  ): Promise<CipherView> {
    const encCipher = await this.encryptCipher(cipherView, batchItems);
    await this.cipherService.updateWithServer(encCipher);
    return cipherView;
  }

  private setSystemProperties(models: StoreData[], cipher: CipherView, action: CRUD): StoreData[] {
    return models.map((model) => {
      return {
        ...model,
        vid: cipher.id,
        dm: new Date().toISOString(),
        dc: action === CRUD.create ? new Date().toISOString() : model.dc,
      };
    });
  }

  private itemsToMap(items: VaultModelItem[]): Map<VaultCodeEnum, StoreModel[]> {
    const map: Map<VaultCodeEnum, StoreModel[]> = new Map<VaultCodeEnum, StoreModel[]>();
    for (const item of items) {
      if (map.has(StoreVaultCodeByModelName[item.type])) {
        map.get(StoreVaultCodeByModelName[item.type]).push(item.model);
      } else {
        map.set(StoreVaultCodeByModelName[item.type], [item.model]);
      }
    }
    return map;
  }

  /**
   * @deprecated
   */
  private checkBeforeDelete(queueItem: VaultModelItem) {
    if (this.vaultStatistics.statistics.get(queueItem.model.vid).itemCount === 1) {
      this.toDelete.enqueue(queueItem);
      this.vaultStatistics.statistics.delete(queueItem.model.vid);
    } else {
      throw new Error("Delete single item in batch cipher is not yet supported. to be implemented");
    }
  }
}

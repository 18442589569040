import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";

import { InstitutionInterest } from "../../models/types/institution.type";
import { InstitutionInterestStoreModel } from "@bitwarden/web-vault/app/models/store/institution.store.model";
import { InstitutionInterestView } from "@bitwarden/web-vault/app/models/view/institution/institution-interest.view";

export function _getAppliedInterest(
  rates: InstitutionInterestView[],
  amount: number,
): InstitutionInterestView {
  const sortedRates = rates.sort((a, b) => a.range - b.range);

  for (const rate of sortedRates) {
    if (amount <= rate.range) {
      return rate;
    }
  }
  return sortedRates.find((rate) => rate.range === -1);
}

export function _getLastDayBalanceKey(groupedBalance: BalanceGrouping): string {
  const groupedBalanceByDay = groupedBalance.granularity["day"];
  const keys = Object.keys(groupedBalanceByDay).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime(),
  );

  return keys[keys.length - 1];
}

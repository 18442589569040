import { ModelView } from "../model.view";
import { StoreModel } from "../../../services/dali/vault-parser/parser.type";
import {
  EstimatePeriod,
  MonthlyOption,
  WeeklyOption,
} from "@bitwarden/web-vault/app/models/enum/estimateType";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import {
  DefaultEstimateStoreModel,
  EstimateModelVersion,
  EstimateStoreModel,
} from "../../store/estimate.store.model";
import { EstimateActionsEnum } from "../../enum/estimate-actions.enum";
import { EstimateActionData } from "../../data/blobby/estimate.action.data";
import { SplitCategoryType } from "../../types/split-category-type";
import { SplitClassificationType } from "../../types/split-classification-type";

export class EstimateView extends ModelView<EstimateStoreModel> {
  protected version: EstimateModelVersion;

  name: string;
  estimateActionKeys: EstimateActionsEnum[]; /** instead of saving estimate actions in memory. we just save the keys for them and create them on the fly */
  estimateActions: EstimateActionData[]; // todo use LinkedView
  categories: SplitCategoryType[];
  classifications: SplitClassificationType[];
  accountId: string;
  direction: TransactionDirection;
  period: EstimatePeriod;
  initialValue: number; /** The value that estimate will generate transactions of off. */
  symbol: string; /** Transaction symbol */
  dateCreated: Date;
  dateUpdated: Date;
  startDate: Date;
  endDate: Date;
  periodicTransactionDatesFormula: WeeklyOption | MonthlyOption;
  isRecurring: boolean;
  isFixedValue: boolean;
  frequency: number;
  transactionGenerator: EstimateActionsEnum; /** Used to detect the right estimateAction to run to generate transactions */

  constructor(model?: EstimateStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.name = model.n;
      this.estimateActionKeys = model.eak;
      this.estimateActions = model.ea;
      this.categories = model.c;
      this.classifications = model.cl;
      this.accountId = model.aid;
      this.direction = model.d;
      this.period = model.p;
      this.initialValue = model.iv;
      this.symbol = model.s;
      this.startDate = new Date(model.sd);
      this.endDate = new Date(model.ed);
      this.periodicTransactionDatesFormula = model.ptdf;
      this.isRecurring = model.ir;
      this.isFixedValue = model.ifv;
      this.frequency = model.f;
      this.transactionGenerator = model.tg;
    } else {
      this.version = DefaultEstimateStoreModel.v;
    }
  }

  toStoreModel(): EstimateStoreModel {
    return undefined;
  }

  clone(): EstimateView {
    return new EstimateView(this.toStoreModel());
  }
}

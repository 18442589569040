import { inject, Injectable } from "@angular/core";
import { saveAs } from "file-saver";

import { Utils } from "@bitwarden/common/misc/utils";
import { EncArrayBuffer } from "@bitwarden/common/models/domain/enc-array-buffer";
import { SymmetricCryptoKey } from "@bitwarden/common/models/domain/symmetric-crypto-key";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { VaultFileView } from "@bitwarden/web-vault/app/models/view/vault-file/vault-file.view";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { FileEncryptionService } from "@bitwarden/web-vault/app/services/DataService/file-upload/file-encryption.service";
import { BalanceActionEnum } from "@bitwarden/web-vault/app/shared/utils/helper.account/balance";
import { GlossApiProxyRequest } from "@bitwarden/web-vault/app/shared/utils/helper.glos-api-call/gloss-api-proxy-request";

import {
  CabinetFileUploadedData,
  VaultFileValidationType,
} from "../../../models/types/cabinet.types";

import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";

@Injectable({
  providedIn: "root",
})
export class CabinetService {
  constructor(
    private globalService: GlobalService,
    private dataRepositoryService: DataRepositoryService,
    private fileEncryption: FileEncryptionService,
  ) {}

  private userStore: UserStoreService = inject(UserStoreService);

  displayCatchError(error: any) {
    this.globalService.showErrorMessage("errorOccurred", "fileUploadFailed");
    // eslint-disable-next-line no-console
    console.error(error);
  }

  async saveFile(data: CabinetFileUploadedData) {
    const fileValidation = this.validateFile(data);
    if (!fileValidation.isValid) {
      this.globalService.showWarningMessage("warning", fileValidation.messageKey);
      return false;
    }

    let isSaved = false;
    await this.fileEncryption.encrypt(data.file);

    const newlySavedVaultFile = await this.saveFileToDali(data);
    if (newlySavedVaultFile) {
      const serverResponse = await this.saveContentToServer(newlySavedVaultFile, data.file);
      isSaved = !!serverResponse;
    }

    if (isSaved) {
      // here is where we add the balances
      await this.addBalances(data);
      this.globalService.showSuccessMessage("success", "fileUploadedSuccessfully");
    } else {
      this.globalService.showErrorMessage("errorOccurred", "fileUploadFailed");
    }

    return isSaved;
  }

  /**
   * @deprecated
   */
  private async addBalances(data: CabinetFileUploadedData) {
    for (const accountView of data.statementAccounts) {
      const openingBalance = data.openingBalances.get(accountView.legacyId);
      const closingBalance = data.closingBalances.get(accountView.legacyId);

      if (openingBalance) {
        if (openingBalance.action === BalanceActionEnum.add) {
          await openingBalance.addOpeningBalance();
        }

        if (openingBalance.action === BalanceActionEnum.edit) {
          await openingBalance.updateConfirmedBalance();
        }
      }

      if (closingBalance) {
        if (closingBalance.action === BalanceActionEnum.add) {
          await closingBalance.addClosingBalance();
        }

        if (closingBalance.action === BalanceActionEnum.edit) {
          await closingBalance.updateConfirmedBalance();
        }
      }
    }
  }

  private async saveFileToDali(data: CabinetFileUploadedData) {
    try {
      const newVaultFile = this.generateVaultFile(data);
      const saveFileResult = this.userStore.vaultFiles.save(newVaultFile);
      return saveFileResult ? newVaultFile : null;
      // return await this.vaultFileService.create(newVaultFile);
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "fileUploadFailed");
    }
  }

  validateFile(data: CabinetFileUploadedData): VaultFileValidationType {
    if (!data.file) {
      return { isValid: false, messageKey: "emptyFileErrorMessage" };
    }

    if (!data.name) {
      return { isValid: false, messageKey: "fileUploadNameRequired" };
    }
    if (!data.mimeType) {
      return { isValid: false, messageKey: "fileTypeNotSupported" };
    }

    if (!data.statementToDate) {
      return { isValid: false, messageKey: "fileUploadStatementToDateRequired" };
    }
    if (!data.statementFromDate) {
      return { isValid: false, messageKey: "fileUploadStatementFromDateRequired" };
    }
    if (!data.statementAccounts || data.statementAccounts.length === 0) {
      return { isValid: false, messageKey: "fileUploadStatementAccountsRequired" };
    }

    return { isValid: true, messageKey: "" };
  }

  private generateVaultFile(data: CabinetFileUploadedData): VaultFileView {
    const newVaultFileView = new VaultFileView();

    newVaultFileView.name = data.name;
    newVaultFileView.mimeType = data.mimeType;
    newVaultFileView.statementToDate = data.statementToDate;
    newVaultFileView.statementFromDate = data.statementFromDate;
    newVaultFileView.encryptedKey = this.fileEncryption.getEncryptedKey();
    newVaultFileView.statementAccounts = data.statementAccounts;
    newVaultFileView.accountIds = data.statementAccounts.map((sAccount) => sAccount.id);

    return newVaultFileView;
  }

  private async saveContentToServer(vaultFile: VaultFileView, file: File) {
    if (file) {
      const encArrayBuffer = this.fileEncryption.getEncryptedFile();

      const bufferText = Utils.fromBufferToB64(encArrayBuffer.buffer);
      const encryptedBlob = new Blob([bufferText], { type: "text/plain" });
      const encryptedFile = new File([encryptedBlob], vaultFile.id, { type: "text/plain" });

      const formData = new FormData();
      formData.append("file", encryptedFile);

      const glossRequestObject = new GlossApiProxyRequest({ endpoint: "uploadFile", formData });
      return await this.dataRepositoryService.send(
        glossRequestObject.method,
        glossRequestObject.path,
        glossRequestObject.data,
        glossRequestObject.authed,
        glossRequestObject.hasResponse,
        glossRequestObject.url,
        glossRequestObject.alterHeaders,
      );
    }
    return true;
  }

  async downloadFile(fileId: string) {
    const vaultFile = this.userStore.vaultFiles
      .vaultFileViews()
      .find((vaultFile) => vaultFile.id == fileId);
    const encryptedKey = vaultFile.encryptedKey;
    const symCryptkey = SymmetricCryptoKey.fromJSON(encryptedKey);
    const symmetricCryptoKey = new SymmetricCryptoKey(symCryptkey.key);

    /** Endpoint to request a encrypted file */
    const glossRequestObject = new GlossApiProxyRequest({ endpoint: "downloadFile", fileId });

    const response = await this.dataRepositoryService.send(
      glossRequestObject.method,
      glossRequestObject.path,
      glossRequestObject.data,
      glossRequestObject.authed,
      glossRequestObject.hasResponse,
      glossRequestObject.url,
      glossRequestObject.alterHeaders,
      true,
    );

    const encB64Text = await response.text();
    const encText = Utils.fromB64ToArray(encB64Text).buffer;
    const encArrayBuffer = new EncArrayBuffer(encText);
    const decryptedFile = await this.fileEncryption.decrypt(encArrayBuffer, symmetricCryptoKey);

    saveAs(decryptedFile, vaultFile.name);
  }

  async deleteFile(vaultFile: VaultFileView): Promise<void> {
    const data = { endpoint: "deleteFile", fileId: vaultFile.id };
    const glossRequestObject = new GlossApiProxyRequest(data);
    const response = await this.dataRepositoryService.send(
      glossRequestObject.method,
      glossRequestObject.path,
      glossRequestObject.data,
      glossRequestObject.authed,
      glossRequestObject.hasResponse,
      glossRequestObject.url,
      glossRequestObject.alterHeaders,
    );
    if (response) {
      await this.userStore.vaultFiles.delete(vaultFile);
      this.globalService.showSuccessMessage("success", "fileDeletedSuccessfully");
    }
  }
}

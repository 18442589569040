import { Injectable } from "@angular/core";
import _ from "lodash";
import { defer, Observable } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { DataServiceAbstraction } from "@bitwarden/web-vault/app/services/DataService/data.service.abstraction";

import { ReferenceData } from "../../../models/data/blobby/reference-data.data";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";

@Injectable({
  providedIn: "root",
})
export class ReferenceDataService implements DataServiceAbstraction {
  constructor(
    private logService: LogService,
    private dataRepositoryService: DataRepositoryService,
  ) {}

  /**
   * @deprecated
   */
  /**
   * Import a set of reference data
   * @param referenceData
   */
  async import(referenceData: ReferenceData[]): Promise<boolean> {
    return await this.dataRepositoryService.importReferenceData(referenceData);
  }

  /**
   * @deprecated
   */
  async create(item: ReferenceData): Promise<ReferenceData> {
    return await this.dataRepositoryService.createReferenceData(item);
  }

  /**
   * @deprecated
   */
  async delete(item: ReferenceData, triggerObservable: boolean): Promise<boolean> {
    // todo
    this.logService.error("Method not implemented");
    return Promise.resolve(false);
  }

  loadReferenceData$(): Observable<ReferenceData[]> {
    return defer(async () => {
      return this.getAll().then((referenceData) => {
        // todo state-management. when moving  100% functionality remove the deepclone. this is because the state management also seal the object.
        return _.cloneDeep(referenceData);
      });
    });
  }

  async get(itemId: string): Promise<ReferenceData> {
    // todo
    this.logService.error("Method not implemented");
    return Promise.resolve(new ReferenceData(null));
  }

  async getAll(): Promise<ReferenceData[]> {
    const allRefData = await this.dataRepositoryService.getAllReferenceData();
    allRefData.sort((a, b) => new Date(a.date.date).getTime() - new Date(b.date.date).getTime());

    return allRefData;
  }

  async update(item: ReferenceData, triggerObservable: boolean): Promise<ReferenceData> {
    // todo
    this.logService.error("Method not implemented");
    return Promise.resolve(new ReferenceData(null));
  }
}

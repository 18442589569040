import { TransactionView } from "../../models/view/transaction/transaction.view";

import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import { StoreModel } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser.type";
import { Signal, WritableSignal } from "@angular/core";
import { Observable } from "rxjs";
import { ExternalView } from "@bitwarden/web-vault/app/models/view/external.view";

export type GeneratedTransactionCollectionNames =
  | "BalanceAlignment"
  | "FilteredTransactionView"
  | "Revaluations"
  | "ScenarioTransactions";

export type GeneratedTransactionParams = {
  webWorkerQueue?: WebWorkerQueue;
  transactions?: TransactionView[];
};

export type GeneratedTransactions = Map<string, TransactionView>;

export type GeneratedTransactionTypes = GeneratedTransactions;

export abstract class GeneratedCollection<View extends ModelView<StoreModel> | ExternalView> {
  // protected abstract generate: View[];
  protected abstract _collection: WritableSignal<View[]>;
  abstract collection: Signal<View[]>;
  abstract collection$: Observable<View[]>;
  abstract update(collection: View[]): void;
  abstract clear(): void;
}

export type GeneratedAdapterInterface<T extends GeneratedTransactionCollectionType> = {
  [n in keyof T]: GeneratedCollection<ModelView<StoreModel>>;
};

type GeneratedTransactionCollectionType =
  | BalanceAlignmentAdapterType
  | RevaluationAdapterType
  | FilteredTransactionsAdapterType
  | ScenarioTransactionsAdapterType
  | DisplayedTransactionsAdapterType;

export type BalanceAlignmentAdapterType = {
  balanceAlignmentTransactions: GeneratedCollection<ModelView<StoreModel>>;
};

export type RevaluationAdapterType = {
  revaluationTransactions: GeneratedCollection<ModelView<StoreModel>>;
};

export type FilteredTransactionsAdapterType = {
  filteredTransactions: GeneratedCollection<ModelView<StoreModel>>;
};

export type DisplayedTransactionsAdapterType = {
  displayedTransactions: GeneratedCollection<ModelView<StoreModel>>;
};

export type ScenarioTransactionsAdapterType = {
  filteredTransactions: GeneratedCollection<ModelView<StoreModel>>;
};

import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { PreferenceStoreModel } from "../../../models/store/preference.store.model";
import { signal } from "@angular/core";
import { PreferenceView } from "@bitwarden/web-vault/app/models/view/preference/preference.view";
import { DependencyPublisher } from "@bitwarden/web-vault/app/services/store/dependency.publisher.abstraction";
import { toObservable } from "@angular/core/rxjs-interop";
import { Observable } from "rxjs";
//import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { dateFormats } from "@bitwarden/web-vault/app/gloss/manage/manage-preferences/dateformats";

export class PreferencesStoreCollection
  extends StoreModelCollection<PreferenceStoreModel, PreferenceView>
  implements DependencyPublisher<PreferenceView>
{
  readonly type: StoreModelNames = "PreferenceStoreModel";
  protected models: Map<string, PreferenceStoreModel> = new Map<string, PreferenceStoreModel>();

  private _preferenceView = signal<PreferenceView>(null);
  readonly preferenceView = this._preferenceView.asReadonly();

  publisher$: Observable<PreferenceView> = toObservable(this._preferenceView);

  triggerChanges(): void {
    const views: PreferenceView[] = [];
    this.models.forEach((model) => {
      const view = new PreferenceView(model);
      Object.freeze(view);
      views.push(view);
    });

    if (views.length === 0) {
      views.push(this.getDefaultPreference());
    }

    this._preferenceView.set(views.shift());
  }

  clear(): void {
    this.models.clear();
    this._preferenceView.set(null);
  }

  getDefaultPreference(): PreferenceView {
    const DefaultPreferenceStoreModel: PreferenceStoreModel = {
      bc: undefined,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      df: dateFormats[0],
      md: "Basic",
      wds: { Monday: 1 },
      yms: { January: 1 },
      mds: 1,
      ss: {
        fixer: { startDate: null, endDate: null, symbols: [] },
        instrumentStore: { startDate: null, endDate: null, symbols: [] },
        basiq: { startDate: null, endDate: null, symbols: [] },
        plaid: { startDate: null, endDate: null, symbols: [] },
      },
      ul: undefined,
      v: 1,
      vid: null,
      dc: new Date().toISOString(),
      dm: new Date().toISOString(),
      id: crypto.randomUUID(),
    };

    return new PreferenceView(DefaultPreferenceStoreModel);
  }
}

import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-rate-renderer",
  templateUrl: "./symbol-rate-renderer.component.html",
})
export class SymbolRateRendererComponent implements ICellRendererAngularComp {
  rate!: number;
  symbol!: string;

  agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }

  setData(params: ICellRendererParams) {
    this.rate = params.data.rate.rate || "Unavailable rate data";
    this.symbol = params.data.rate.cross || 0;
  }
}

import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { PreferenceStoreModel } from "../../../../models/store/preference.store.model";
import { Preference } from "../../../../models/data/blobby/preference.data";
import { preference_v0_to_v1 } from "../model-dto/preference";
import { PreferenceResponse } from "../../../../models/data/response/preference.response";

export class PreferenceParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Preference parser version ${version}`);
    }

    if (version === 0) {
      return PreferenceParser_v0;
    } else {
      return PreferenceParser_v1;
    }
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const PreferenceParser_v1 = (preferenceObj: any): any => preferenceObj as PreferenceStoreModel;

const PreferenceParser_v0 = (preferenceObj: any): any => {
  const response = new PreferenceResponse(preferenceObj);
  const preference = new Preference(response);
  const upgraded = preference_v0_to_v1(preference);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = preferenceObj.vid ?? "";
  return PreferenceParser_v1(upgraded);
};

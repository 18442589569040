import { Category } from "../../../../models/data/blobby/category.data";
import { CategoryStoreModel } from "../../../../models/store/category.store.model";

export const category_v0_to_v1 = (categoryItem: Category): CategoryStoreModel => {
  return {
    dc: new Date().toString(),
    dm: new Date().toString(),
    gendef: categoryItem.generalDefault,
    id: categoryItem.id,
    n: categoryItem.name,
    v: 1,
    vid: "",
    w: categoryItem.weight,
  };
};

import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountType } from "../types/account.types";
import { InstitutionAccountLink } from "../types/institution.type";
import { SplitCategoryType } from "../types/split-category-type";
import { SplitClassificationType } from "../types/split-classification-type";
import { StoreData } from "./store.data";
import {
  CurrencyType,
  DefaultSystemCurrency,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";
export type AccountModelVersion = 2;
export interface AccountStoreModel extends StoreData {
  v: AccountModelVersion;
  /* Link to an institution account. */
  instLnk: InstitutionAccountLink;

  /* Account currency */
  curr: CurrencyType;

  /* Account Name */
  name: string;

  /*balance calculation */
  bal: number;

  /*creditLimit */
  crLm: number;

  /* Account TimeZone if known */
  tz?: string | undefined;

  /* Default classifications for transaction splits */
  dfltCla: SplitClassificationType[];

  /* Default categories for transaction splits */
  dfltCat: SplitCategoryType[];

  /* Origin of the account */
  ori: Origin;

  /* Basic Account id */
  bsqId: string;

  /* Date associated with the model */
  date: string;

  /* The type of account */
  type: AccountType;

  /* status property */
  st: string[];
}

export const DefaultAccountStoreModel: AccountStoreModel = {
  bsqId: "",
  curr: DefaultSystemCurrency,
  date: "",
  crLm: 0,
  dc: "",
  dfltCat: [],
  dfltCla: [],
  dm: "",
  id: "",
  instLnk: undefined,
  name: "",
  bal: 0,
  ori: undefined,
  type: undefined,
  st: [],
  tz: "",
  v: 2,
  vid: "",
};

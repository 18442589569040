import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { NavItems, GlossRoutes } from "../../../models/enum/routes.enum";
import { HelperCommon } from "../../../shared/utils/helper-common";
import { Menu } from "@bitwarden/web-vault/app/layouts/menu/menu";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent extends Menu {
  @Input() selectedKey = GlossRoutes.Views.toString();
  @Output() openHelpBoxEvent = new EventEmitter<void>();

  protected readonly HelperCommon = HelperCommon;
  protected readonly process = process;
  navItems = NavItems;

  openHelpBox() {
    this.openHelpBoxEvent.emit();
  }
}

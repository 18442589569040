import { StoreModelCollection } from "../model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { signal } from "@angular/core";
import { ScenarioStoreModel } from "../../../models/store/scenario.store.model";
import { ScenarioView } from "@bitwarden/web-vault/app/models/view/scenario/scenario.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import { toObservable } from "@angular/core/rxjs-interop";

export class ScenarioStoreCollection extends StoreModelCollection<
  ScenarioStoreModel,
  ModelView<ScenarioStoreModel>
> {
  readonly type: StoreModelNames = "ScenarioStoreModel";
  protected models: Map<string, ScenarioStoreModel> = new Map<string, ScenarioStoreModel>();

  private _scenarioViews = signal<ScenarioView[]>([]);
  readonly scenarioView = this._scenarioViews.asReadonly();
  scenarioView$ = toObservable(this.scenarioView);

  triggerChanges(): void {
    const views: ScenarioView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new ScenarioView(model)) as ScenarioView);
    });
    this._scenarioViews.set(views);
  }
  clear(): void {
    this.models.clear();
    this._scenarioViews.set(null);
  }

  populateWithSystemDefaults(defaults: ScenarioView[]) {
    let views: ScenarioView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new ScenarioView(model)) as ScenarioView);
    });

    views = [...views, ...defaults];
    this._scenarioViews.set(views);
  }
}

import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { signal } from "@angular/core";
import { ConnectorView } from "@bitwarden/web-vault/app/models/view/connector/connector.view";
import { ConnectorStoreModel } from "@bitwarden/web-vault/app/models/store/connector.store.model";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class ConnectorsStoreCollection extends StoreModelCollection<
  ConnectorStoreModel,
  ModelView<ConnectorStoreModel>
> {
  readonly type: StoreModelNames = "ConnectorStoreModel";

  protected models: Map<string, ConnectorStoreModel> = new Map<string, ConnectorStoreModel>();

  private _connectorViews = signal<ConnectorView[]>(null);
  readonly connectorViews = this._connectorViews.asReadonly();

  triggerChanges(): void {
    const views: ConnectorView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new ConnectorView(model)) as ConnectorView);
    });
    this._connectorViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._connectorViews.set(null);
  }
}

import { StoreData } from "./store.data";

export type SourceCategoryModelVersion = 1;
export interface SourceCategoryStoreModel extends StoreData {
  /**  **Version Number** **/
  v: SourceCategoryModelVersion;

  /** name property from source-category **/
  nm: string;

  /** categoryId property from source-category **/
  ctId: string;
}

export const DefaultSourceCategoryStoreModel: SourceCategoryStoreModel = {
  nm: "",
  ctId: "",
  v: 1,
  vid: "",
  dc: "",
  dm: "",
  id: "",
};

import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { InstitutionStoreModel } from "../../../models/store/institution.store.model";
import { signal } from "@angular/core";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class InstitutionsStoreCollection extends StoreModelCollection<
  InstitutionStoreModel,
  ModelView<InstitutionStoreModel>
> {
  readonly type: StoreModelNames = "InstitutionStoreModel";
  protected models: Map<string, InstitutionStoreModel> = new Map<string, InstitutionStoreModel>();

  private _institutionViews = signal<InstitutionView[]>(null);
  readonly institutionViews = this._institutionViews.asReadonly();

  triggerChanges(): void {
    const views: InstitutionView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new InstitutionView(model)) as InstitutionView);
    });
    this._institutionViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._institutionViews.set(null);
  }
}

import { Injectable, Injector } from "@angular/core";

import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import {
  ScenarioOptionsClasses,
  ScenarioOptionsType,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { ScenarioOption } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/scenario-option";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";
import { ScenarioGroupView } from "@bitwarden/web-vault/app/models/view/scenario-group/scenario-group.view";
import { BalanceByAccountsSymbols } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";

@Injectable({
  providedIn: "root",
})
export class ScenarioOptionsFactory {
  static createScenarioOption(
    type: ScenarioOptionsType,
    scenarioGroup: ScenarioGroupView,
    injector: Injector,
    webWorkerQueue: WebWorkerQueue,
    startingBalances: BalanceByAccountsSymbols,
  ): ScenarioOption {
    return new ScenarioOptionsClasses[type](
      scenarioGroup,
      webWorkerQueue,
      startingBalances,
      injector,
    );
  }
}

import { Component, inject, input, computed } from "@angular/core";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { HelperCollapse } from "@bitwarden/web-vault/app/shared/utils/helper-collapse";
import { getName } from "@bitwarden/web-vault/app/shared/utils/helper-string";
import { HelperExpand } from "@bitwarden/web-vault/app/shared/utils/helper-expand";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";

@Component({
  selector: "app-balance-hud",
  templateUrl: "./balance-hud.component.html",
})
export class BalanceHudComponent {
  protected helperCollapse: HelperCollapse;
  protected helperExpand: HelperExpand;
  protected readonly getName = getName;
  private userStoreService = inject(UserStoreService);
  private calculationStoreService = inject(CalculationStoreService);

  hudIndex = input<number>();
  timeStamp = computed(() => {
    if (this.calculationStoreService.balanceByTime.balances()) {
      const balancesByTime = this.calculationStoreService.balanceByTime.balances();
      const keys = [...balancesByTime.keys()];

      return keys[this.hudIndex()];
    }
  });

  balance = computed(() => {
    if (this.calculationStoreService.balanceByTime.balances()) {
      return this.calculationStoreService.balanceByTime.balances().get(this.timeStamp());
    }
  });

  balanceDate = computed(() => {
    if (this.timeStamp()) {
      return new Date(Number(this.timeStamp()) * 1000);
    }
  });

  balanceAccounts = computed(() => {
    if (this.calculationStoreService.balanceByTimeAndAccounts.balances() && this.timeStamp()) {
      const balanceByAccounts = this.calculationStoreService.balanceByTimeAndAccounts
        .balances()
        .get(this.timeStamp());
      const accountBalancesDisplay: { account: string; balance: number; accountID: string }[] = [];
      balanceByAccounts.forEach((amount, accountID) => {
        accountBalancesDisplay.push(this.getContext(accountID, amount));
      });
      return accountBalancesDisplay;
    }
  });

  constructor() {
    this.helperCollapse = new HelperCollapse();
    this.helperExpand = new HelperExpand();
    this.helperExpand.isExpanded = false;
  }

  getContext(accountID: string, amount: number) {
    return {
      accountID: accountID,
      account: getName(this.userStoreService.accounts.accountViews(), accountID),
      balance: amount,
    };
  }
}

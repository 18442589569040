import { WorkerMessage } from "@bitwarden/web-vault/web-worker/worker.message";

export class TransactionBalancesWorkerMessage implements WorkerMessage {
  id: string;
  workerType = "TransactionBalancesWorker";
  workerIndex?: number;
  type?: string;
  transactionDates?: ArrayBuffer;
  transactionAmounts?: ArrayBuffer;
  startingBalance?: number;
  balanceDates?: ArrayBuffer;

  constructor(
    id: string,
    type: string,
    transactionDates?: ArrayBuffer,
    transactionAmounts?: ArrayBuffer,
    startingBalance?: number,
    balanceDates?: ArrayBuffer,
  ) {
    this.id = id;
    this.type = type;
    this.transactionDates = transactionDates;
    this.transactionAmounts = transactionAmounts;
    this.startingBalance = startingBalance;
    this.balanceDates = balanceDates;
  }
}

import { CalculationStoreService } from "../../../../services/store/calculation/calculation.store.service";
import { computed, inject, Injectable, Signal } from "@angular/core";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";

@Injectable({
  providedIn: "root",
})
export class GranularityFilter {
  protected calculationStore: CalculationStoreService = inject(CalculationStoreService);

  options: Signal<GranularityProperty[]> = computed(
    () => this.calculationStore.groupings.options().granularity,
  );
  selectedGranularity: Signal<string> = computed(
    () => this.calculationStore.groupings.selected().granularity,
  );

  onGranularitySelection(granularity: GranularityProperty) {
    this.calculationStore.groupings.updateSelection({
      ...this.calculationStore.groupings.selected(),
      granularity: granularity,
    });
  }
}

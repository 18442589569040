<div class="step-holder">
  <div>
    <span [innerHTML]="'moveBalanceDescription' | i18n" class="step-description"></span>
    <ng-container *ngIf="earningsCard">
      <best-scenario-stepper-earnings-card
        [earningsData]="earningsCard"
      ></best-scenario-stepper-earnings-card>
    </ng-container>

    <div *ngIf="existingAccounts" class="section-gap">
      <span class="section-header">{{ "from" | i18n }}</span>

      <app-accordion
        collapseIcon="minimize"
        expandIcon="maximize"
        customClass="tw-bg-neutral-50"
        [contentTemplate]="existingAccountsComponent"
      >
        <app-best-scenario-dialog-title title>
          {{ "yourCurrentAccounts" | i18n }}
        </app-best-scenario-dialog-title>
      </app-accordion>

      <ng-template #existingAccountsComponent>
        <div class="accounts-container tw-m-3 tw-rounded-lg tw-bg-tealgray-50 tw-p-3">
          <span *ngFor="let accountInfo of existingAccounts" class="accordion-list-item">
            <span class="accordion-list-item-bullet"></span>
            {{ accountInfo.displayName }}
          </span>
        </div>
      </ng-template>

      <span class="accordion-list-description">
        {{ existingAccounts.length }} existing accounts
      </span>
    </div>

    <div class="section-gap">
      <span class="section-header">{{ "to" | i18n }}</span>
      @if (rateInUse) {
        <app-best-account-tooltip [lastViewDate]="rateInUse.last_updated">
          {{ bestAccountInfo.accountName }}
        </app-best-account-tooltip>
      }
    </div>
  </div>
</div>

import { PreferenceTypeKey } from "@bitwarden/web-vault/app/models/view/preference/preference.view";

export const PreferenceType = {
  baseCurrency: "baseCurrency" as PreferenceTypeKey,
  timeZone: "timeZone" as PreferenceTypeKey,
  dateFormat: "dateFormat" as PreferenceTypeKey,
  mode: "mode" as PreferenceTypeKey,
  weekDayStart: "weekDayStart" as PreferenceTypeKey,
  YearMonthStart: "YearMonthStart" as PreferenceTypeKey,
  monthDayStart: "monthDayStart" as PreferenceTypeKey,
  userLocation: "userLocation" as PreferenceTypeKey,
};

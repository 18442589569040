import { Component, DoCheck, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { HelpBoxComponent } from "@bitwarden/web-vault/app/gloss/help-box/help-box.component";
import { RoleAccessData, ScopeValue } from "@bitwarden/web-vault/app/models/data/role-access.data";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";

@Component({
  selector: "app-creation-options",
  templateUrl: "./creation-options.component.html",
  styles: ["creation-options.component.scss"],
})
export class CreationOptionsComponent implements DoCheck {
  private destroy$ = new Subject<void>();
  private roleAccess: RoleAccessData;
  protected readonly RoleScope: ScopeValue;

  earlyRequestDialogRef: MatDialogRef<HelpBoxComponent>;
  hasAccess: boolean;
  hasScope: boolean;

  @Output() backClicked = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      createManually: CallableFunction;
      createByLinking: CallableFunction;
      closeDialogue: CallableFunction;
      backClicked: CallableFunction;
      isPremiumAccount: boolean;
    },
    private roleAccessService: RoleAccessService,
    public dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
  ) {
    this.hasAccess = data.isPremiumAccount;
  }

  ngDoCheck() {
    if (!this.roleAccess) {
      this.roleAccess = this.roleAccessService.getRoleAccess();
      this.hasScope = this.hasBasiqAccess();
    }
  }

  hasBasiqAccess() {
    if (this.roleAccess) {
      const scope = this.roleAccess.getScope();
      const claim = this.roleAccess.getClaim();
      const dataAggregator = claim.data_aggregator_connector_name;

      return (
        (scope.includes("BETA_ACCESS") || scope.includes("DATA_AGGREGATOR")) &&
        dataAggregator.includes("BASIQ")
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createManually() {
    this.data.createManually();
  }

  backToAccountCreateMain() {
    this.data.backClicked();
  }

  async createByLinking() {
    if (!this.hasScope) {
      const confirm = await this.confirmationDialogService.confirmFor(
        ConfirmationEnum.askingForEarlyAccess,
      );

      if (confirm) {
        this.openHelpMenu();
      }

      return;
    }

    if (this.data.isPremiumAccount) {
      this.data.createByLinking();
    }
  }

  openHelpMenu() {
    this.requestEarlyAccess();
  }

  requestEarlyAccess() {
    this.earlyRequestDialogRef = this.dialog.open(HelpBoxComponent, {
      panelClass: "no-background-dialog",
      data: {
        supportBoxHeader: "Request An Early Access",
        textFieldMessage: "",
        placeholderMessage: "message",
        dialogOpen: true,
        closeDialogue: this.closeEarlyRequestDialog.bind(this),
      },
      disableClose: true,
    });

    this.earlyRequestDialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeEarlyRequestDialog() {
    this.earlyRequestDialogRef.close();
  }
}

import { StoreData } from "./store.data";

export type ClassificationModelVersion = 1;
export interface ClassificationStoreModel extends StoreData {
  /**  **Version Number** **/
  v: ClassificationModelVersion;

  /** Classification Name */
  n: string;

  /** Classification is Generate Default */
  gendef?: boolean; // when we import data and create accounts if there are no classification-renderer related to transaction

  /** Classification Weight */
  w: number;
}

export const DefaultClassificationStoreModel: ClassificationStoreModel = {
  dc: "",
  dm: "",
  gendef: false,
  id: "",
  n: "",
  v: 1,
  vid: "",
  w: 0,
};

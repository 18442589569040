import {
  CalculatedBalances,
  CalculatedBalancesGroupedTwice,
  CalculatedBalanceCollection,
  CalculatedBalanceParams,
} from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";
import { inject, signal } from "@angular/core";
import { LogService } from "@bitwarden/common/abstractions/log.service";

export class BalanceByTimeAndAccountsCalculatedCollection extends CalculatedBalanceCollection {
  protected log: LogService = inject(LogService);

  protected type: "BalanceByTimeAndAccounts";
  protected calculations: CalculatedBalancesGroupedTwice = new Map<string, CalculatedBalances>();

  protected _balances = signal<Map<string, Map<string, number>>>(new Map());
  readonly balances = this._balances.asReadonly();

  async updateCalculations(parameters: CalculatedBalanceParams): Promise<void> {
    const balancesByTimeAccount: Record<
      number,
      Record<string, number>
    > = await this.getBalanceByTimeAccounts(
      parameters.webWorkerQueue,
      parameters.calculatedTransactions,
      parameters.period.startDate,
      parameters.period.endDate,
      parameters.group.granularity,
    );

    this.setBalances(balancesByTimeAccount);
  }

  getCalculations(): CalculatedBalancesGroupedTwice {
    return this.calculations;
  }

  clear() {
    this.calculations.clear();
    this._balances.set(null);
  }

  triggerChanges(): void {}

  private setBalances(balancesByTimeAccounts: Record<number, Record<string, number>>): void {
    this.calculations = new Map();

    for (const [time, balancesByAccount] of Object.entries(balancesByTimeAccounts)) {
      const balancesByAccountMap = new Map(Object.entries(balancesByAccount));
      this.calculations.set(time, balancesByAccountMap);
    }

    this._balances.set(this.calculations);
  }
}

import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { GlossInputOptions } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionAccount } from "@bitwarden/web-vault/app/models/types/institution.type";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { DeviceDetectorService } from "ngx-device-detector";
import {
  AccountManualType,
  accountManualType,
} from "@bitwarden/web-vault/app/models/types/account.types";

@Component({
  selector: "app-account-form",
  templateUrl: "./account-form.component.html",
})
export class AccountFormComponent {
  @Input() accountInputOptions: GlossInputOptions;
  @Input() institutionInputOptions: GlossInputOptions;
  @Input() selectedInstitution: InstitutionView;
  @Input() selectedAccountType: InstitutionAccount;
  @Input() saveButtonOptions: ActionButton;
  @Input() setBalanceButtonOptions: ActionButton;
  @Input() addTransactionButtonOptions: ActionButton;
  @Input() cancelButtonOptions?: ActionButton;
  @Input() accountManualType: AccountManualType;
  @Input() isEditMode: boolean;
  @Input() currency: string;
  @Input() inWizard: boolean = false;

  @Output() selectCurrency = new EventEmitter<string>();
  @Output() onSelectAccountType = new EventEmitter<InstitutionAccount>();

  protected deviceService: DeviceDetectorService = inject(DeviceDetectorService);
  accountManualTypeRef = accountManualType;

  emitSelectCurrency(currency: string) {
    this.selectCurrency.emit(currency);
  }

  emitOnSelectAccountType(institutionAccount: InstitutionAccount) {
    this.onSelectAccountType.emit(institutionAccount);
  }
}

import { Injectable } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { RevalMetaData } from "@bitwarden/web-vault/app/models/types/revaluation.types";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { TransactionStoreModel } from "@bitwarden/web-vault/app/models/store/transaction.store.model";
import {
  getTransactionViewOf,
  TransactionViewProperties,
} from "@bitwarden/web-vault/app/models/view/transaction/transaction.utils";

@Injectable({
  providedIn: "root",
})
export class RevaluationWorkerService {
  private worker: Worker;

  getRevaluations(revalMetaData: RevalMetaData): Promise<TransactionView[]> {
    return new Promise((resolve, reject) => {
      this.worker = new Worker(new URL("./revaluation.worker", import.meta.url), {
        type: "module",
      });

      this.worker.postMessage(revalMetaData);

      this.worker.onmessage = (event) => {
        this.worker.terminate();
        if (event?.data?.revaluations) {
          resolve(
            event.data.revaluations.map((transaction: TransactionViewProperties) =>
              getTransactionViewOf(transaction),
            ),
          );
        } else {
          reject("event.data");
        }
      };

      this.worker.onmessageerror = (event) => {
        this.worker.terminate();
        reject(event);
      };
    });
  }
}

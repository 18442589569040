import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";

import { Transaction } from "../../models/data/blobby/transaction.data";

import { LinkValidator, ValidatorResponseTypes } from "./link-validator";

export class LinkTransactionsValidator extends LinkValidator {
  /**
   * Validate if the transactions to link is a transfer
   *
   * @param transactions
   */
  isTransfer(transactions: Transaction[]) {
    return this.validateTransfer(transactions);
  }

  /**
   * Validate if the transactions to link is a conversion
   *
   * @param transactions
   */
  isConversion(transactions: Transaction[]) {
    return this.validateConversion(transactions);
  }

  /**
   * validate if a transaction is a revaluation
   *
   * @param transactionView
   * */
  isRevaluation(transactionView: TransactionView): ValidatorResponseTypes {
    return this.validateReval(transactionView);
  }
}

import { ComponentType } from "@angular/cdk/portal";
import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { ModalRef } from "@bitwarden/angular/components/modal/modal.ref";
import { ModalService } from "@bitwarden/angular/services/modal.service";
import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { MessagingService } from "@bitwarden/common/abstractions/messaging.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { PolicyService } from "@bitwarden/common/abstractions/policy/policy.service.abstraction";
import { StateService } from "@bitwarden/common/abstractions/state.service";
import { PolicyType } from "@bitwarden/common/enums/policyType";
import { TwoFactorProviderType } from "@bitwarden/common/enums/twoFactorProviderType";
import { TwoFactorProviders } from "@bitwarden/common/services/twoFactor.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";

import { TwoFactorWebAuthnComponent } from "./two-factor-webauthn.component";

@Component({
  selector: "app-two-factor-setup",
  templateUrl: "two-factor-setup.component.html",
})
export class TwoFactorSetupComponent implements OnInit, OnDestroy {
  protected apiService: ApiService;
  private confirmationDialogService: ConfirmationDialogService;
  private dialog: MatDialog;
  private i18nService: I18nService;
  protected messagingService: MessagingService;
  protected modalService: ModalService;
  private platformUtilsService: PlatformUtilsService;
  protected policyService: PolicyService;
  private stateService: StateService;

  @ViewChild("webAuthnTemplate", { read: ViewContainerRef, static: true })
  webAuthnModalRef: ViewContainerRef;

  organizationId: string;
  providers: any[] = [];
  canAccessPremium: boolean;
  showPolicyWarning = false;
  loading = true;
  modal: ModalRef;
  formPromise: Promise<any>;
  enableBiometricsOptions: ActionButton;
  cancelButtonOptions: ActionButton;
  isMobileFirstSetup = false;
  enableToggle = false;
  deviceType: string;

  private destroy$ = new Subject<void>();
  private twoFactorAuthPolicyAppliesToActiveUser: boolean;

  constructor(injector: Injector) {
    this.apiService = injector.get(ApiService);
    this.confirmationDialogService = injector.get(ConfirmationDialogService);
    this.dialog = injector.get(MatDialog);
    this.i18nService = injector.get(I18nService);
    this.modalService = injector.get(ModalService);
    this.messagingService = injector.get(MessagingService);
    this.platformUtilsService = injector.get(PlatformUtilsService);
    this.policyService = injector.get(PolicyService);
    this.stateService = injector.get(StateService);

    this.enableBiometricsOptions = new ActionButton({
      text: this.i18nService.t("enable"),
      onClick: () => this.manage(),
    });
    this.cancelButtonOptions = new ActionButton({
      class: "neutral",
      text: this.i18nService.t("laterInSettings"),
    });
    this.deviceType = this.platformUtilsService.getDeviceString();
  }

  async ngOnInit() {
    this.canAccessPremium = await this.stateService.getCanAccessPremium();
    this.enableToggle = await this.stateService.getBiometricFingerprintValidated();

    for (const key in TwoFactorProviders) {
      // eslint-disable-next-line
      if (!TwoFactorProviders.hasOwnProperty(key)) {
        continue;
      }

      const p = (TwoFactorProviders as any)[key];
      if (this.filterProvider(p.type)) {
        continue;
      }

      this.providers.push({
        type: p.type,
        name: p.name,
        description: p.description,
        enabled: false,
        premium: p.premium,
        sort: p.sort,
      });
    }

    this.providers.sort((a: any, b: any) => a.sort - b.sort);

    this.policyService
      .policyAppliesToActiveUser$(PolicyType.TwoFactorAuthentication)
      .pipe(takeUntil(this.destroy$))
      .subscribe((policyAppliesToActiveUser) => {
        this.twoFactorAuthPolicyAppliesToActiveUser = policyAppliesToActiveUser;
      });

    await this.load();
  }

  async load() {
    this.loading = true;
    const providerList = await this.getTwoFactorProviders();
    providerList.data.forEach((p) => {
      this.providers.forEach((p2) => {
        if (p.type === p2.type) {
          p2.enabled = p.enabled;
        }
      });
    });
    this.evaluatePolicies();
    this.loading = false;
  }

  async manage() {
    await this.openDialog(this.webAuthnModalRef, TwoFactorWebAuthnComponent);
  }

  async premiumRequired() {
    if (!this.canAccessPremium) {
      this.messagingService.send("premiumRequired");
      return;
    }
  }

  protected getTwoFactorProviders() {
    return this.apiService.getTwoFactorProviders();
  }

  protected filterProvider(type: TwoFactorProviderType) {
    return type === TwoFactorProviderType.OrganizationDuo;
  }

  protected async openDialog(ref: ViewContainerRef, component: ComponentType<any>) {
    this.dialog.open(component, {
      panelClass: "no-background-dialog",
      viewContainerRef: ref,
      disableClose: true,
    });
  }

  protected async openModal<T>(ref: ViewContainerRef, type: Type<T>): Promise<T> {
    const [modal, childComponent] = await this.modalService.openViewRef(type, ref);
    this.modal = modal;

    return childComponent;
  }

  protected updateStatus(enabled: boolean, type: TwoFactorProviderType) {
    if (!enabled && this.modal != null) {
      this.modal.close();
    }
    this.providers.forEach((p) => {
      if (p.type === type) {
        p.enabled = enabled;
      }
    });
    this.evaluatePolicies();
  }

  private async evaluatePolicies() {
    if (this.organizationId == null && this.providers.filter((p) => p.enabled).length === 1) {
      this.showPolicyWarning = this.twoFactorAuthPolicyAppliesToActiveUser;
    } else {
      this.showPolicyWarning = false;
    }
  }

  async onEnableBiometrics(isEnabled: boolean) {
    if (!isEnabled) {
      this.enableToggle = await this.confirmationDialogService.confirmFor(
        ConfirmationEnum.turnOffBiometrics,
      );
      return;
    }
    this.manage();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

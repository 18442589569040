import { inject } from "@angular/core";
import { UserStoreService } from "../../../services/store/user/user.store.service";
import { ScenarioStoreService } from "@bitwarden/web-vault/app/services/store/scenario/scenario.store.service";
import { ScenarioTypes } from "@bitwarden/web-vault/app/models/enum/scenario.types";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { SignalSubscriptionImpl } from "@bitwarden/web-vault/app/gloss/tables/data-subscription";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";
import { getName } from "@bitwarden/web-vault/app/shared/utils/helper-string";
import { ViewsService } from "@bitwarden/web-vault/app/services/views/views.service";
import type { TableSource } from "./transaction-table";

export class ScenarioTransactionTableSubscription extends SignalSubscriptionImpl<TableSource[]> {
  userStoreService = inject(UserStoreService);
  calculationStoreService = inject(CalculationStoreService);
  scenarioStoreService = inject(ScenarioStoreService);
  viewsService = inject(ViewsService);
  finalBalanceAmountList: number[] = [];

  get source() {
    const accountViews = this.calculationStoreService.filteredAccounts.collection();
    const institutionViews = this.userStoreService.institutions.institutionViews();
    const scenarioDisplayViews = this.scenarioStoreService.interestScenario.collection();
    const scenarioType = this.viewsService.currentScenarioType();

    const currentScenarioDisplayData = scenarioDisplayViews.find(
      (data) => scenarioType == data.scenarioType,
    );

    let transactions = currentScenarioDisplayData?.fullTransactionList;
    if (scenarioType === ScenarioTypes.BestNewInterestRate) {
      transactions = currentScenarioDisplayData?.createdRecords.transactions;
    }

    this.finalBalanceAmountList = scenarioDisplayViews.map(
      (scenarioDisplay) => scenarioDisplay.finalBalanceAmount,
    );

    return transactions?.map((transaction: TransactionView) => {
      const multiplier = transaction.direction === TransactionDirection.In ? 1 : -1;
      return {
        description: transaction.description,
        transactionDate: transaction.transactionDate,
        balance: multiplier * transaction.quantity.actualQuantity.amount,
        symbol: transaction.quantity.actualQuantity.symbol,
        institution: getName(institutionViews, transaction.c_institutionLink.id),
        account: getName(accountViews, transaction.accountLink.id),
      };
    });
  }
}

import { GeneratedCollection } from "../generated.collection.abstraction";
import { Signal, signal } from "@angular/core";
import { TransactionView } from "../../../models/view/transaction/transaction.view";
import { Observable } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";

export class FilteredTransactionGeneratedCollection extends GeneratedCollection<TransactionView> {
  protected generatedTransactions: TransactionView[] = [];

  protected _collection = signal<TransactionView[]>(null);
  collection = this._collection.asReadonly();
  collection$: Observable<TransactionView[]> = toObservable(this.collection);

  update(transactions: TransactionView[]) {
    this._collection.set([...transactions]);
  }

  clear(): void {
    this._collection.set(null);
  }
}

import { StateControlAbstraction } from "../../state-control.abstraction";
import { toObservable } from "@angular/core/rxjs-interop";
import { signal } from "@angular/core";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { BalanceDatesUtils } from "@bitwarden/web-vault/app/services/store/calculation/balances/balanceDates.utils";

/** @Note **_isDefault** is managed by the store **/
export type PeriodType = {
  startDate: Date;
  endDate: Date;
  _isDefault: boolean;
};

// CollectionControl
export class PeriodController extends StateControlAbstraction {
  private readonly today = BalanceDatesUtils.getEndOfDayDate(new Date());

  /** Public signal for component to listen too **/
  private _options = signal<PeriodType>(this.getInitialValue());

  /**
   * This contain the startDate to EndDate = today ) in the system.
   * **/
  options = this._options.asReadonly();

  private _selected = signal<PeriodType>(this.getInitialValue());
  selected = this._selected.asReadonly();

  /** **<span style="color: red;">This is for store use only</span>** **/
  readonly selected$ = toObservable(this.selected);

  /**
   * This contain the startDate to EndDate = today ) in the system.
   * **<span style="color: red;">This is for store use only</span>**
   * **/
  readonly options$ = toObservable(this.options);

  clear() {
    this._options.set(null);
    this._selected.set(null);
  }

  getInitialValue() {
    return {
      startDate: this.today,
      endDate: this.today,
      _isDefault: true,
    };
  }

  /**
   * Update range selection, set _isDefault to false automatically to flag human action
   */
  updateSelection(selection: PeriodType) {
    const adjustedTime = {
      startDate: BalanceDatesUtils.getStartOfDayDate(selection.startDate),
      endDate: BalanceDatesUtils.getEndOfDayDate(selection.endDate),
      _isDefault: selection._isDefault,
    };

    this._selected.set(adjustedTime);
  }

  /**
   * Reset the selected value to the default options range
   */
  resetSelection() {
    this._selected.set({ ...this.options(), _isDefault: true });
  }

  /**
   * Update value of the Universe of transaction Range options.
   */
  updateOption(option: PeriodType) {
    const adjustedTime = {
      startDate: BalanceDatesUtils.getStartOfDayDate(option.startDate),
      endDate: BalanceDatesUtils.getEndOfDayDate(option.endDate),
      _isDefault: option._isDefault,
    };
    this._options.set(adjustedTime);
  }

  /**
   * Set the new date range options, and also set the selected range if the user never touched it.
   */
  updateOptionAndSelectionFromTransactions(transactions: readonly TransactionView[]) {
    const period = this.getInitialValue();

    for (const transaction of transactions) {
      if (transaction.transactionDate.getTime() <= period.startDate.getTime()) {
        period.startDate = transaction.transactionDate;
      }

      if (transaction.transactionDate.getTime() >= period.endDate.getTime()) {
        period.endDate = transaction.transactionDate;
      }
    }

    this.updateOption({ ...period });
    if (this.selected()._isDefault) {
      this.updateSelection({ ...period });
    }
  }

  filterOnPeriod(transactions: readonly TransactionView[]) {
    return transactions.filter((transactionView) => {
      return (
        transactionView?.transactionDate.getTime() >= this.selected().startDate.getTime() &&
        transactionView?.transactionDate.getTime() <= this.selected().endDate.getTime()
      );
    });
  }
}

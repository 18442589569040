import { Injectable, inject } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";
import { ReferenceDataCalculationClass } from "@bitwarden/web-vault/app/services/DataCalculationService/reference-data/reference-data-calculation.class";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

import { ReferenceData } from "../../../models/data/blobby/reference-data.data";
import { ReferenceDataService } from "../../DataService/reference-data/reference-data.service";
import { CalculationServiceAbstraction } from "../calculation.service.abstraction";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";

@Injectable({
  providedIn: "root",
})
/**
 * @deprecated
 * this service needs to be deprecated and replaced with the ReferenceDataCalculationClass
 * and always called with the data from the ReferenceDataStoreService with up to date signal data
 */
export class ReferenceDataCalculationService implements CalculationServiceAbstraction {
  private referenceDataCalculation: ReferenceDataCalculationClass;
  private transactionStoreService = inject(TransactionStoreService);

  constructor(
    private referenceDataService: ReferenceDataService,
    private logger: LogService,
    private dataRepositoryService: DataRepositoryService,
    private globalService: GlobalService,
  ) {
    const referenceData = this.transactionStoreService.referenceData.referenceDataViews();
    this.referenceDataCalculation = new ReferenceDataCalculationClass(this.logger, referenceData);
  }

  /**
   * @deprecated
   */
  setAllReferenceData(data: ReferenceData[]) {
    // TODO: note that this function has been deprecated.
    // We should only call the
    // this.referenceDataCalculation.setAllReferenceData(data);
  }

  /** Todo : Do not delete this method. This is a placeholder to retrieve currency data from Instrument API of Ironfly */
  /**
   * @deprecated
   */
  async getCurrencyReferenceDataFromInstruments(
    symbols: string[],
    base: string,
    startDate: number,
    endDate: number,
  ): Promise<Array<ReferenceData>> {
    const url = "instrumentCallApiUrl";
    const path = `/?base=${base}&symbols=${symbols.join(
      ",",
    )}&startDate=${startDate}&endDate=${endDate}`;
    const instrumentMarketData = await this.dataRepositoryService.send(
      "GET",
      path,
      null,
      true,
      true,
      url,
    );
    if (!instrumentMarketData.error) {
      if (instrumentMarketData.currencyData.length) {
        return instrumentMarketData.currencyData.map(
          (currency: object) => new ReferenceData(new ReferenceDataResponse(currency)),
        );
      }
      return instrumentMarketData.currencyData;
    } else {
      this.globalService.showErrorMessage("errorOccurred", instrumentMarketData.error.message);
    }
  }
}

/**todo: currently amount is price, need to change amount to price and then change numberOfPurchase to amount
 *       also, current Symbol is currency, change that and so symbol is symbol(stockSymbol), currency is currency **/

import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { AutoCompleteTransactionDirectionComponent } from "@bitwarden/web-vault/app/components/auto-transaction-direction/auto-complete-transaction-direction.component";
import { DatePickerComponent } from "@bitwarden/web-vault/app/components/form/elements/date-picker/date-picker.component";
import { GlossInputComponent } from "@bitwarden/web-vault/app/components/inputs/gloss-input/gloss-input.component";
import { TransactionForm } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/transaction/transaction-form";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { transactionDataType } from "@bitwarden/web-vault/app/models/types/transactionData.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { SelectAccountService } from "@bitwarden/web-vault/app/services/cabinet/selectAccount.service";
import { accountManualType } from "@bitwarden/web-vault/app/models/types/account.types";
import { HelperExpand } from "@bitwarden/web-vault/app/shared/utils/helper-expand";

@Component({
  selector: "app-add-transaction",
  templateUrl: "./add-transaction.component.html",
})
export class AddTransactionComponent implements OnInit, OnDestroy {
  loading = false;
  transactionFrom: TransactionForm;
  selectAccountService: SelectAccountService;
  selectedAccount: AccountView;
  private destroy$ = new Subject<void>();

  @Output() transactionData = new EventEmitter<transactionDataType | null | "touched">();

  @ViewChild("descriptionInput") descriptionInput!: GlossInputComponent;
  @ViewChild("timeInput") timeInput!: GlossInputComponent;
  @ViewChild("amountInput") amountInput!: GlossInputComponent;
  @ViewChild(DatePickerComponent) datePickerComponent: DatePickerComponent;
  @ViewChild(AutoCompleteTransactionDirectionComponent)
  autoTransactionDirectionComponent: AutoCompleteTransactionDirectionComponent;

  // manualType = accountManualType;
  numberOfPurchase: number = 0;
  // get this from actual stock symbols
  selectedStockSymbol: string = "";
  mockStockSymbols: string[] = ["AAPL", "GOOGL", "MSFT", "AMZN", "TSLA"];
  helperExpand: HelperExpand;

  constructor(private injector: Injector) {
    this.helperExpand = new HelperExpand();
    this.selectAccountService = injector.get(SelectAccountService);
  }

  ngOnInit(): void {
    this.selectAccountService
      .getSelectedAccount()
      .pipe(takeUntil(this.destroy$))
      .subscribe((account) => {
        this.selectedAccount = account;
        this.transactionFrom = new TransactionForm(this.injector, this.selectedAccount);
      });
  }

  completeForm() {
    this.descriptionInput.inputValueControl.markAsTouched();
    this.amountInput.inputValueControl.markAsTouched();
    this.descriptionInput.onInputBlur();
    this.amountInput.onInputBlur();
    const result = this.transactionFrom.submit();
    this.transactionData.emit(result);
    if (result && result !== "touched") {
      this.resetValue();
    }
  }

  resetValue() {
    this.descriptionInput.clearInput();
    this.timeInput.clearInput();
    this.amountInput.clearInput();
    this.datePickerComponent.resetDate();
    this.autoTransactionDirectionComponent.setDirectionValue(TransactionDirection.In);
    this.transactionFrom.formGroup.reset({
      date: null,
      currency: this.transactionFrom.currency,
      transactionStatus: TransactionStatusEnum.transaction,
      type: TransactionDirection.In,
    });
  }

  editTransaction(inActionTransaction: transactionDataType) {
    this.transactionFrom.currentAccount = this.selectedAccount;
    this.transactionFrom.selectedAccount = inActionTransaction.account;
    this.transactionFrom.isEditTransaction = true;
    if (inActionTransaction) {
      const editData = inActionTransaction.formData;
      //reset the form view
      this.descriptionInput.setInputValue(editData.description);
      this.timeInput.setInputValue(editData.time);
      this.amountInput.setInputValue(editData.amount.toString());
      this.datePickerComponent.setDateText(editData.date);
      this.autoTransactionDirectionComponent.transactionDirectionSelected(editData.type);
      //reset the form value
      this.transactionFrom.formGroup.reset({
        description: editData.description,
        time: editData.time,
        date: editData.date,
        amount: editData.amount,
        currency: editData.currency,
        transactionStatus: TransactionStatusEnum.transaction,
        type: editData.type,
      });
    }
  }

  inputDescription(description: string) {
    this.descriptionInput.setInputValue(description);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onStockSymbolSelected(stockSymbol: string) {
    this.selectedStockSymbol = stockSymbol;
  }

  onNumberOfPurchaseEntered(numberOfPurchase: number) {
    this.numberOfPurchase = numberOfPurchase;
  }

  protected readonly accountManualType = accountManualType;
}

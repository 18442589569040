import { DatePipe } from "@angular/common";
import { Component, computed, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatRangeDateSelectionModel,
} from "@angular/material/datepicker";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { CalendarService } from "@bitwarden/web-vault/app/components/form/elements/date-picker/date-picker-calendar-overwrite/calendar.service";
import { DatePickerType } from "@bitwarden/web-vault/app/models/types/date-picker.types";
import { GranularityFilter } from "@bitwarden/web-vault/app/gloss/views/views-control/views-customize/granularity.filter";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
  ],
})
export class CalendarComponent implements OnInit {
  protected granularityFilter = inject(GranularityFilter);
  relativeDateValue: number;
  displayDate: string;
  topLabel: string;
  pickerLabel: string;
  waysToSetText: string;
  // dateRange: DateRange<Date>;
  selectedDateUnit: string;
  selectedTimeDirection: string;

  isPickDateMode = true;
  dateFilter: (date: Date) => boolean;
  private readonly today: Date = new Date();

  @Input() pickerType: DatePickerType;
  @Input() dateUnitOptions: string[] = ["Weeks", "Days", "Months"];
  @Input() timeDirectionOptions: string[] = ["In the Future", "In the Past"];

  @Input() dateRange: DateRange<Date>;

  @Output() dateChangeEvent = new EventEmitter<DateRange<Date>>();
  @Output() confirmEvent = new EventEmitter<void>();
  @Output() cancelEvent = new EventEmitter<void>();

  constructor(
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private i18nService: I18nService,
    private calendarService: CalendarService,
    private datePipe: DatePipe,
  ) {
    this.today.setHours(0, 0, 0, 0); // or use date-fns startOfDay, for the filter to get the correct date
  }

  cancelButtonOptions = new ActionButton({
    class: "neutral",
    fullClass: "round-corner-button",
    text: this.i18nService.t("cancel"),
    // onClick: this.onCancel.bind(this),
    isEnabled: true,
    testId: "calendarCancelButton",
  });

  confirmButtonOptions = new ActionButton({
    class: "primary",
    fullClass: "round-corner-button",
    text: this.i18nService.t("confirm"),
    onClick: this.onConfirm.bind(this),
    isEnabled: true,
    testId: "calendarConfirmButton",
  });

  private onConfirm() {
    this.confirmEvent.emit();
  }

  protected selectGranularityOption(granularity: string) {
    this.granularityFilter.onGranularitySelection(granularity as GranularityProperty);
  }

  currentDateRange = computed(() => this.calendarService.dateRange());
  currentFilter = computed(() => this.calendarService.dateFilterComputed());

  ngOnInit() {
    this.calendarService.updateDateRange(this.dateRange, this.pickerType);

    this.selectedDateUnit = this.dateUnitOptions[0];
    this.selectedTimeDirection = this.timeDirectionOptions[0];

    this.updateDisplayDate();
    this.updateDisplayText();
  }

  // reverse pick range
  // rangeChanged(date: Date){
  //   if(!this.dateRange?.start || this.dateRange.end){
  //     this.dateRange = new DateRange(date, null);
  //     return;
  //   }
  //   if(date > this.dateRange?.start){
  //     this.dateRange = new DateRange(
  //       this.dateRange.start,
  //       date
  //     );
  //   }else{
  //     this.dateRange = new DateRange(
  //       date,
  //       this.dateRange.start
  //     );
  //   }
  // }

  rangeChanged(date: Date) {
    if (this.pickerType === "start") {
      this.dateRange = new DateRange(date, this.dateRange.end);
    } else if (this.pickerType === "end") {
      this.dateRange = new DateRange(this.dateRange.start, date);
    }
    this.calendarService.updateDateRange(this.dateRange, this.pickerType);

    this.updateDisplayDate();
    this.dateChangeEvent.emit(this.currentDateRange());
  }

  // todo: change the today
  updateDisplayDate() {
    if (this.pickerType === "start") {
      this.displayDate = this.dateRange?.start
        ? this.formatDate(this.dateRange.start)
        : this.formatDate(this.today);
    } else if (this.pickerType === "end") {
      this.displayDate = this.dateRange?.end
        ? this.formatDate(this.dateRange.end)
        : this.formatDate(this.today);
    }
  }

  updateDisplayText() {
    const isStart = this.pickerType === "start";
    this.topLabel = isStart ? "start from" : "end on";
    this.pickerLabel = isStart ? "BEGINNING OF" : "END OF";
    this.waysToSetText = isStart ? "WAYS TO SET THE START DATE:" : "WAYS TO SET THE END DATE:";
  }

  // resetToToday() {
  //   if (this.pickerType === "start") {
  //     this.dateRange = new DateRange(this.today, this.dateRange?.endDate);
  //   } else if (this.pickerType === "end") {
  //     this.dateRange = new DateRange(this.dateRange?.start, this.today);
  //   }
  //   this.calendarService.updateDateRange(this.dateRange);
  //   this.updateDisplayDate();
  // }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, "yyyy-MM-dd")!;
  }

  selectDateUnit(option: string) {
    this.selectedDateUnit = option;
  }

  selectTimeDirection(option: string) {
    this.selectedTimeDirection = option;
  }

  getPlaceholder(): string {
    return `Number of ${this.selectedDateUnit.toLowerCase()}`;
  }

  // onCancel(): void {
  //   if (this.pickerType === "start") {
  //     this.dateRange = new DateRange(this.initialStartDate, this.dateRange?.end);
  //   } else if (this.pickerType === "end") {
  //     this.dateRange = new DateRange(this.dateRange?.start, this.initialEndDate);
  //   }
  //   this.calendarService.updateDateRange(this.dateRange);
  //   this.updateDisplayDate();
  //   this.cancelEvent.emit();
  // }

  // onConfirm(): void {
  //   if (this.isPickDateMode) {
  //     // Handle the confirmation when in pick date mode
  //     if (this.pickerType === "start") {
  //       this.dateRange = new DateRange(this.dateRange?.start, this.dateRange?.end);
  //     } else if (this.pickerType === "end") {
  //       this.dateRange = new DateRange(this.dateRange?.start, this.dateRange?.end);
  //     }
  //   } else {
  //     // Handle the confirmation when in relative date mode
  //     let relativeDate: Date;
  //     if (this.pickerType === "start") {
  //       relativeDate = this.calculateRelativeDate(
  //         this.today,
  //         this.relativeDateValue,
  //         this.selectedDateUnit,
  //         this.selectedTimeDirection
  //       );
  //       this.dateRange = new DateRange(relativeDate, this.dateRange?.end);
  //     } else if (this.pickerType === "end") {
  //       const startDate = this.dateRange?.start;
  //       relativeDate = this.calculateRelativeDate(
  //         startDate,
  //         this.relativeDateValue,
  //         this.selectedDateUnit,
  //         this.selectedTimeDirection
  //       );
  //       this.dateRange = new DateRange(this.dateRange?.start, relativeDate);
  //       //todo: @Horace a bug causing the selected value become incorrect e.g. picking 1 day in the past will become 1 week in the future, so like next item in the list was picked,
  //       // still trying to consistently replicate the bug, continue to investigate in the v2.
  //       // console.log(`Number of ${this.relativeDateValue} ${this.selectedDateUnit.toLowerCase()} ${this.selectedTimeDirection.toLowerCase()}`);
  //     }
  //   }
  //   this.calendarService.updateDateRange(this.dateRange);
  //   this.confirmEvent.emit();
  // }

  calculateRelativeDate(baseDate: Date, value: number, unit: string, direction: string): Date {
    const multiplier = direction === "In the Future" ? 1 : -1;

    switch (unit) {
      case "Days":
        baseDate.setDate(baseDate.getDate() + value * multiplier);
        break;
      case "Weeks":
        baseDate.setDate(baseDate.getDate() + value * 7 * multiplier);
        break;
      case "Months":
        baseDate.setMonth(baseDate.getMonth() + value * multiplier);
        break;
    }

    return baseDate;
  }

  toggleDateMode(mode: string): void {
    this.isPickDateMode = mode === "pick";
  }
}

import { inject, Injectable } from "@angular/core";
import { ModelStoreAbstraction } from "@bitwarden/web-vault/app/services/store/model.store.abstraction";
import { StoreModelNames } from "@bitwarden/web-vault/app/services/dali/type/dali.type";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { AccountsStoreCollection } from "@bitwarden/web-vault/app/services/store/user/accounts.store.collection";
import { VaultFilesStoreCollection } from "@bitwarden/web-vault/app/services/store/user/vault-files.store.collection";
import { WizardStoreCollection } from "@bitwarden/web-vault/app/services/store/user/wizards.store.collection";
import { CategoriesStoreCollection } from "@bitwarden/web-vault/app/services/store/user/categories.store.collection";
import { ClassificationsStoreCollection } from "@bitwarden/web-vault/app/services/store/user/classifications.store.collection";
import { PreferencesStoreCollection } from "@bitwarden/web-vault/app/services/store/user/preferences.store.collection";
import { InstitutionsStoreCollection } from "@bitwarden/web-vault/app/services/store/user/institutions.store.collection";
import { ConnectorsStoreCollection } from "@bitwarden/web-vault/app/services/store/user/connectors.store.collection";
import {
  ModelCollectionInterface,
  StoreModelCollection,
  UserStoreModelCollectionType,
} from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import { StoreData } from "@bitwarden/web-vault/app/models/store/store.data";
import { ExternalSubscriptionStoreCollection } from "@bitwarden/web-vault/app/services/store/user/external.subscription.store.collection";

@Injectable({
  providedIn: "root",
})
export class UserStoreService
  extends ModelStoreAbstraction
  implements ModelCollectionInterface<UserStoreModelCollectionType>
{
  readonly preferences: PreferencesStoreCollection = new PreferencesStoreCollection();
  readonly wizards: WizardStoreCollection = new WizardStoreCollection();
  readonly accounts: AccountsStoreCollection = new AccountsStoreCollection();
  readonly vaultFiles: VaultFilesStoreCollection = new VaultFilesStoreCollection();
  readonly categories: CategoriesStoreCollection = new CategoriesStoreCollection();
  readonly classifications: ClassificationsStoreCollection = new ClassificationsStoreCollection();
  readonly institutions: InstitutionsStoreCollection = new InstitutionsStoreCollection();
  readonly connectors: ConnectorsStoreCollection = new ConnectorsStoreCollection();
  readonly subscription: ExternalSubscriptionStoreCollection =
    new ExternalSubscriptionStoreCollection();

  protected log: LogService = inject(LogService);

  clearStore(): void {
    this.preferences.clear();
    this.wizards.clear();
    this.accounts.clear();
    this.vaultFiles.clear();
    this.categories.clear();
    this.classifications.clear();
    this.institutions.clear();
    this.connectors.clear();
    this.subscription.clear();
    this.log.info("UserStoreService cleared");
  }

  initialize(): void {
    this.log.info("Initializing UserStoreService");
    this.preferences.triggerChanges();
    this.wizards.triggerChanges();
    this.institutions.triggerChanges();
    this.accounts.triggerChanges();
    this.subscription.triggerChanges();

    /** **/

    this.categories.triggerChanges();
    this.classifications.triggerChanges();
    this.connectors.triggerChanges();
    this.vaultFiles.triggerChanges();
  }

  protected getStorageCollection(
    modelName: StoreModelNames,
  ): StoreModelCollection<StoreData, ModelView<StoreData>> {
    switch (modelName) {
      case "AccountStoreModel":
        return this.accounts;
      case "InstitutionStoreModel":
        return this.institutions;
      case "PreferenceStoreModel":
        return this.preferences;
      case "VaultFileStoreModel":
        return this.vaultFiles;
      case "WizardStoreModel":
        return this.wizards;
      case "CategoryStoreModel":
        return this.categories;
      case "ClassificationStoreModel":
        return this.classifications;
      case "ConnectorStoreModel":
        return this.connectors;
      default: {
        throw new Error(`UserStoreService doesnt implement ${modelName}`);
      }
    }
  }
}

import { Injectable } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";

import { CipherService } from "../../../../../../libs/common/src/abstractions/cipher.service";
import { CipherView } from "../../../../../../libs/common/src/models/view/cipher.view";
import {
  BlobbyDataTypeEnum,
  BlobbyDataTypeEnum as BType,
} from "../../models/enum/blobbyDataTypeEnum";

@Injectable({
  providedIn: "root",
})
export class CipherItemMap {
  private itemToCipherMap = new Map<string, string>();
  private cipherToItemMap = new Map<string, string[]>();

  constructor(
    private cipherService: CipherService,
    private logService: LogService,
  ) {}

  setMap(cipherViews: CipherView[]) {
    for (let x = 0; x < cipherViews.length; x++) {
      try {
        const notes: string = cipherViews[x].notes;
        const objTransactions = JSON.parse(notes);
        objTransactions.forEach((item: any) => {
          if (Array.isArray(item)) {
            for (const obj of item) {
              //Set cipher=>item
              if (this.cipherToItemMap.has(cipherViews[x].id)) {
                const existingValues: string[] = this.cipherToItemMap.get(cipherViews[x].id);
                if (cipherViews[x].id !== undefined && obj._id !== undefined) {
                  this.cipherToItemMap.set(cipherViews[x].id, [...existingValues, obj._id]);
                }
              } else {
                if (cipherViews[x].id !== undefined && obj._id !== undefined) {
                  this.cipherToItemMap.set(cipherViews[x].id, [obj._id]);
                }
              }

              //set item=>cipher
              if (cipherViews[x].id !== undefined && obj._id !== undefined) {
                this.itemToCipherMap.set(obj._id, cipherViews[x].id);
              }
            }
          } else if (typeof item === "object") {
            //Set cipher => item
            if (this.cipherToItemMap.has(cipherViews[x].id)) {
              const existingValues: string[] = this.cipherToItemMap.get(cipherViews[x].id);
              if (cipherViews[x].id !== undefined && item._id !== undefined) {
                this.cipherToItemMap.set(cipherViews[x].id, [...existingValues, item._id]);
              }
            } else {
              if (cipherViews[x].id !== undefined && item._id !== undefined) {
                this.cipherToItemMap.set(cipherViews[x].id, [item._id]);
              }
            }

            // set item=> cipher
            if (cipherViews[x].id !== undefined && item._id !== undefined) {
              this.itemToCipherMap.set(item._id, cipherViews[x].id);
            }
          }
        });
      } catch (e) {
        this.logService.error(e);
      }
    }
  }

  async setTransactionsMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Transaction),
    );
    this.setMap(cipherViews);
  }

  async setReferenceMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.ReferenceData),
    );
    this.setMap(cipherViews);
  }

  async setBooksMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Book),
    );
    this.setMap(cipherViews);
  }
  async setClassificationsMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Classification),
    );
    this.setMap(cipherViews);
  }
  async setInstitutionsMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Institution),
    );
    this.setMap(cipherViews);
  }
  async setCategoriesMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Category),
    );
    this.setMap(cipherViews);
  }
  async setEstimatesMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Estimate),
    );
    this.setMap(cipherViews);
  }
  async setEstimateGroupsMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.EstimateGroup),
    );
    this.setMap(cipherViews);
  }
  async setPreferencesMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Preference),
    );
    this.setMap(cipherViews);
  }
  async setSourceTransactionsMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.SourceTransaction),
    );
    this.setMap(cipherViews);
  }

  async setSourceBooksMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.SourceBook),
    );
    this.setMap(cipherViews);
  }
  async setSourceCategoriesMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.SourceCategory),
    );
    this.setMap(cipherViews);
  }
  async setSymbolsMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Symbol),
    );
    this.setMap(cipherViews);
  }
  async setConnectorMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Connector),
    );
    this.setMap(cipherViews);
  }

  async setVaultFileMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.VaultFile),
    );
    this.setMap(cipherViews);
  }

  async setWizardMap() {
    const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
      this.blobbyTypeNotePrefix(BlobbyDataTypeEnum.Wizard),
    );
    this.setMap(cipherViews);
  }

  async mapToEachOther() {
    await this.setTransactionsMap();
    await this.setReferenceMap();
    await this.setBooksMap();
    await this.setClassificationsMap();
    await this.setInstitutionsMap();
    await this.setCategoriesMap();
    await this.setEstimatesMap();
    await this.setEstimateGroupsMap();
    await this.setPreferencesMap();
    await this.setSourceTransactionsMap();
    await this.setSourceBooksMap();
    await this.setSourceCategoriesMap();
    await this.setSymbolsMap();
    await this.setConnectorMap();
    await this.setVaultFileMap();
    await this.setWizardMap();
  }

  async resetMapByType(type: BlobbyDataTypeEnum) {
    if (type === BlobbyDataTypeEnum.Transaction) {
      await this.setTransactionsMap();
    }

    if (type === BlobbyDataTypeEnum.ReferenceData) {
      await this.setReferenceMap();
    }

    if (type === BlobbyDataTypeEnum.SourceTransaction) {
      await this.setSourceTransactionsMap();
    }

    if (type === BlobbyDataTypeEnum.SourceBook) {
      await this.setSourceBooksMap();
    }

    if (type === BlobbyDataTypeEnum.Book) {
      await this.setBooksMap();
    }

    if (type === BlobbyDataTypeEnum.Category) {
      await this.setCategoriesMap();
    }

    if (type === BlobbyDataTypeEnum.Classification) {
      await this.setClassificationsMap();
    }

    if (type === BlobbyDataTypeEnum.Institution) {
      await this.setInstitutionsMap();
    }

    if (type === BlobbyDataTypeEnum.Estimate) {
      await this.setEstimatesMap();
    }

    if (type === BlobbyDataTypeEnum.Symbol) {
      await this.setSymbolsMap();
    }

    if (type === BlobbyDataTypeEnum.Preference) {
      await this.setPreferencesMap();
    }

    if (type === BlobbyDataTypeEnum.EstimateGroup) {
      await this.setEstimateGroupsMap();
    }

    if (type === BlobbyDataTypeEnum.Connector) {
      await this.setConnectorMap();
    }

    if (type === BlobbyDataTypeEnum.VaultFile) {
      await this.setVaultFileMap();
    }

    if (type === BlobbyDataTypeEnum.Wizard) {
      await this.setWizardMap();
    }
  }

  blobbyTypeNotePrefix(type: BType): string {
    if (type === BType.Book) {
      return "a";
    } else if (type === BType.Category) {
      return "ca";
    } else if (type === BType.Classification) {
      return "cl";
    } else if (type === BType.Institution) {
      return "in";
    } else if (type === BType.ReferenceData) {
      return "rd";
    } else if (type === BType.Estimate) {
      return "es";
    } else if (type === BType.Symbol) {
      return "s";
    } else if (type === BType.Transaction) {
      return "t";
    } else if (type === BType.SourceTransaction) {
      return "u";
    } else if (type === BType.SourceBook) {
      return "bs";
    } else if (type === BType.Preference) {
      return "p";
    } else if (type === BType.EstimateGroup) {
      return "eg";
    } else if (type === BType.Scenario) {
      return "sc";
    } else if (type === BType.ScenarioGroup) {
      return "sg";
    } else if (type === BType.SourceCategory) {
      return "soc";
    } else if (type === BType.Connector) {
      return "con";
    } else if (type === BType.VaultFile) {
      return "vf";
    } else if (type === BType.Wizard) {
      return "wz";
    } else {
      return "";
    }
  }

  getItemToCipherMap() {
    return this.itemToCipherMap;
  }

  getCipherToItemMap() {
    return this.cipherToItemMap;
  }
}

<app-widget
  [title]="'analysis' | i18n"
  [iconName]="'file-analysis'"
  [menu]="currentView === ViewEnum.TODAY ? chartWidgetMenu : getGranularityMenuOptions()"
  (onClick)="handleChartWidget($event)"
>
  @switch (currentView) { @case (ViewEnum.PAST) {
  <app-balance-chart></app-balance-chart>
  } @case (ViewEnum.TODAY) {
  <app-bubble-chart [dataSet]="computedData().dataSet"></app-bubble-chart>
  } @case (ViewEnum.FUTURE) {
  <app-scenario-chart></app-scenario-chart>
  } }
</app-widget>

<ng-container>
  <form
    #form
    class="tw-flex tw-w-fit tw-min-w-[270px] tw-max-w-[400px] sm:tw-w-full tw-flex-col tw-gap-6 md:tw-h-full md:tw-items-center md:tw-justify-between"
    id="preference"
  >
    <div class="tw-flex tw-flex-col tw-w-full tw-gap-6">
      <div>
        <label>{{ "selectLocation" | i18n }} *</label>
        <app-dropdown-select
          [title]="'selectLocation' | i18n"
          [options]="Object.values(userLocationOptions)"
          [selectedOption]="selectedLocation"
          (optionSelected)="onUserLocationSelected($event)"
        >
        </app-dropdown-select>
      </div>
      <div>
        <label>{{ "baseCurrency" | i18n }} *</label>
        <app-dropdown-select
          [title]="'selectCurrency' | i18n"
          [options]="currencyOptions"
          [selectedOption]="this.currentPreference.baseCurrency"
          (optionSelected)="onBaseCurrencySelected($event)"
        >
        </app-dropdown-select>
      </div>
      <ng-container>
        <div>
          <label>{{ "dateFormats" | i18n }} *</label>
          <app-dropdown-select
            [title]="'selectDateFormat' | i18n"
            [options]="this.dateFormatOptions"
            [selectedOption]="this.currentPreference.dateFormat"
            (optionSelected)="onDateFormatSelected($event)"
          >
          </app-dropdown-select>
        </div>
      </ng-container>
    </div>
    <app-gloss-button
      class="tw-w-full"
      [options]="{
        text: 'save' | i18n,
        type: 'submit',
        class: 'primary',
        onClick: onSubmitForm.bind(this),
        isEnabled: !formState().isSaving,
      }"
    ></app-gloss-button>
  </form>
</ng-container>

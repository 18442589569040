import { BalanceByAccountsSymbols } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { EstimateActionsType } from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import { BestExistingInterestRate } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/best-existing-interest-rate";
import { BestNewInterestRate } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/best-new-interest-rate";
import { CurrentInterestRate } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/current-interest-rate";
import { InstitutionInterestView } from "@bitwarden/web-vault/app/models/view/institution/institution-interest.view";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";

// Type used to call a scenario and to return its outcome
/**
 * @deprecated
 * Can remove when we delete old models and services
 */
export type GroupScenarioBalance = {
  ScenarioGroupId: string;
  scenarioID: string;
  scenarioType: ScenarioOptionsType;
  scenarioName: string;
  createdRecords: CreatedRecords;
  fullTransactionList: Array<Transaction>;
  finalBalanceAmount: number;
  transactionBalances?: Record<string, number>;
  graphData: Array<GraphDataSet>;
  graphDates?: Array<number>;
  graphBalances?: Array<number>;
  helpInfo?: ScenarioHelpInfo;
};

/**
 * This is the new type we are using for scenario data results with the new stores
 */
export type ScenarioResults = {
  ScenarioGroupId: string;
  scenarioID: string;
  scenarioType: ScenarioOptionsType;
  scenarioName: string;
  createdRecords: CreatedRecords;
  fullTransactionList: Array<TransactionView>;
  finalBalanceAmount: number;
  transactionBalances?: Record<string, number>;
  graphDates?: Array<number>;
  graphBalances?: Array<number>;
  helpInfo?: ScenarioHelpInfo;
};

// Type used to store all the information relating to a scenario group
export type ScenarioData = {
  scenario: Array<GroupScenarioBalance>;
  balance: Array<GraphDataSet>;
  anchorPoint?: AnchorPointData;
};

export type AnchorPointData = {
  anchorDate: string;
  anchorBalance: number;
  balanceDates?: Uint32Array;
  balanceArray?: Float64Array;
};

// Types relating to Scenario Options:
export const ScenarioOptionsClasses = {
  currentInterestRate: CurrentInterestRate,
  bestExistingInterestRate: BestExistingInterestRate,
  bestNewInterestRate: BestNewInterestRate,
};

export type ScenarioOptionsClassesProperties = typeof ScenarioOptionsClasses;
export type ScenarioOptionsType = keyof ScenarioOptionsClassesProperties;

export const currentInterestRate = "currentInterestRate" as ScenarioOptionsType;
export const bestExistingInterestRate = "bestExistingInterestRate" as ScenarioOptionsType;
export const bestNewInterestRate = "bestNewInterestRate" as ScenarioOptionsType;

export type InterestScenarioHelpInfo = {
  transferAmount?: number;
  transferToAccount?: ScenarioAccountInfo;
  transferFromAccount?: Array<ScenarioAccountInfo>;
  existingAccounts?: Array<ScenarioAccountInfo>;
  totalBalance?: number;
  symbol?: string;
  bestTenAccountPermutations?: Array<PermutationBalancePair>;
};

export type ScenarioHelpInfo = InterestScenarioHelpInfo | null;

export type ScenarioAccountInfo = {
  accountBank?: string;
  accountName: string;
  rate?: Array<InstitutionInterestView>;
  accountUrl?: string;
};

// Used to define the list of estimate actions to run per scenario
export type EstimateActionProperties = {
  parameters: object;
  estimateActionType: EstimateActionsType;
  createdRecords?: CreatedRecords;
  runningAccountBalances?: BalanceByAccountsSymbols;
};

export type ScenarioPermutation = {
  estimateActions: Array<EstimateActionProperties>;
  scenarioHelpInfo?: ScenarioHelpInfo;
};
export type PermutationBalancePair = {
  permutation: ScenarioPermutation;
  balance: number;
};

export type ScenarioOptionWinner = {
  createdRecords: CreatedRecords;
  fullTransactionList?: Array<TransactionView>;
  // groupedBalance?: BalanceGrouping;
  finalBalanceAmount?: number;
  hasInterestEarnings?: boolean;
  helpInfo?: ScenarioHelpInfo;
};

export type CreatedRecords = {
  accounts?: Array<AccountView>;
  transactions?: Array<TransactionView>;
  runningAccountBalances?: BalanceByAccountsSymbols;
};

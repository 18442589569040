<div
  class="tw-absolute tw-left-1/2 tw-top-full tw-z-10 tw-mt-1 tw--translate-x-1/2 tw-transform xs:tw-w-auto tw-w-[350px] tw-rounded-lg tw-bg-neutral-50 tw-p-6 tw-shadow-form"
>
  <div class="tw-flex tw-flex-col tw-gap-4">
    <!--  top-->
    <div class="tw-flex tw-flex-col tw-gap-2">
      <div class="tw-flex tw-justify-between">
        <div>{{ topLabel }}</div>
        <div>
          <span>Around</span>
          <span class="tw-underline">1 yr ago</span>
        </div>
      </div>

      <div class="tw-flex tw-items-center tw-gap-2">
        <div
          class="left-round-border tw-flex tw-h-12 tw-w-full tw-items-center tw-justify-center tw-border tw-border-solid tw-border-orange-500 tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-inner"
        >
          {{ displayDate }}
        </div>
        <!--        (click)="resetToToday()"-->
        <div
          class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-px-3 tw-py-1 tw-shadow-button"
        >
          <span class="tw-whitespace-nowrap">reset to</span>
          <span class="tw-font-medium tw-text-primary">today</span>
        </div>
      </div>
    </div>
    <!--  top-->

    <div class="tw-flex tw-flex-col tw-gap-2">
      <div>{{ waysToSetText }}</div>
      <div class="tw-flex tw-items-center tw-justify-between">
        <div
          class="tw-flex tw-h-12 tw-w-32 tw-items-center tw-justify-center tw-rounded-full tw-cursor-pointer"
          [ngClass]="{
            'tw-border tw-border-solid tw-border-neutral-100 tw-bg-white': isPickDateMode,
          }"
          (click)="toggleDateMode('pick')"
        >
          Pick Date
        </div>

        <icon [name]="'switch'" [size]="5"></icon>

        <div
          class="tw-flex tw-h-12 tw-w-32 tw-flex-col tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-solid tw-border-neutral-100 tw-cursor-pointer"
          [ngClass]="{
            'tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-shadow-calendar':
              !isPickDateMode,
          }"
          (click)="toggleDateMode('relative')"
        >
          <span>Relative Date</span>
          <span class="tw-text-[10px] tw-opacity-60"
            >Ref. <span class="tw-underline">2025-01-03</span></span
          >
        </div>
      </div>
    </div>
    <mat-calendar
      *ngIf="isPickDateMode"
      class="tw-w-full tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-p-2 tw-shadow-calendar"
      [selected]="dateRange"
      (selectedChange)="rangeChanged($event)"
      [dateFilter]="currentFilter()"
    >
    </mat-calendar>
    <div
      *ngIf="!isPickDateMode"
      class="tw-h-full tw-w-full tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-bg-white tw-p-2 tw-shadow-calendar"
    >
      <div CLASS="tw-flex tw-flex-col tw-gap-2">
        <div>{{ pickerLabel }}</div>

        <div class="tw-flex tw-h-12 tw-justify-between tw-gap-2">
          <input
            type="number"
            inputmode="numeric"
            class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between tw-rounded-lg tw-border-none tw-bg-neutral-50 tw-px-3 tw-shadow-inner tw-outline-none"
            [placeholder]="getPlaceholder()"
            [(ngModel)]="relativeDateValue"
          />
          <app-dropdown-select
            [options]="dateUnitOptions"
            (optionSelected)="selectDateUnit($event)"
            class="tw-w-full"
          >
          </app-dropdown-select>
        </div>
        <app-dropdown-select
          [options]="timeDirectionOptions"
          (optionSelected)="selectTimeDirection($event)"
          class="tw-w-full"
        >
        </app-dropdown-select>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-gap-2">
      <span class="tw-font-medium tw-text-sm tw-text-neutral-600">GRANULARITY</span>
      <app-dropdown-expand
        [options]="granularityFilter.options()"
        [selectedOption]="granularityFilter.selectedGranularity()"
        (optionSelected)="selectGranularityOption($event)"
        [testId]="'granularity-dropdown'"
      >
      </app-dropdown-expand>
    </div>
  </div>
</div>

import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  OnDestroy,
  EventEmitter,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";

import { InputTypes, InputTypesArray } from "@bitwarden/web-vault/app/models/enum/inputTypes.enum";
import { addAsterisk } from "@bitwarden/web-vault/app/shared/utils/helper-string";

import { GlossInputOptions } from "../../../models/types/general-types";

@Component({
  selector: "app-gloss-input",
  templateUrl: "./gloss-input.component.html",
})
export class GlossInputComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly validTypes = InputTypesArray;
  @Input() options: GlossInputOptions;
  @Output() isClearInput: EventEmitter<boolean> = new EventEmitter<boolean>();
  shouldDisplayHint = true;
  isFocused = false;
  label: string;
  inputValueControl: FormControl;

  @ViewChild("inputElement", { read: ElementRef }) inputElement: ElementRef;
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.initializeFormControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.initializeFormControl();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //the validation logic is not working when only the touch is set
  private updateSmallContentDisplay(): void {
    this.shouldDisplayHint =
      !this.inputValueControl.touched || !this.inputValueControl.hasError("required");
  }

  setLabel(): void {
    const { label, isRequired } = this.options;
    this.label = addAsterisk(label, isRequired);
  }

  private validateType(): boolean {
    return !this.options.type || GlossInputComponent.validTypes.includes(this.options.type);
  }

  private initializeFormControl(): void {
    const { isRequired, isDisabled, inputBlurred, inputCleared, inputError, value, onInput } =
      this.options;
    this.inputValueControl = new FormControl(
      { value: value || "", disabled: isDisabled },
      isRequired ? Validators.required : null,
    );
    this.inputBlurred = inputBlurred;
    this.inputCleared = inputCleared;
    this.inputError = inputError;
    this.onInput = onInput;
    if (value) {
      this.inputValueControl.setValue(value);
    }
    this.setLabel();
    // todo: change to signal once angular upgrade pr in
    this.inputValueControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateSmallContentDisplay();
    });
    this.options.type = this.validateType() ? this.options.type : InputTypes.text;
  }

  get inputValue(): string {
    return this.inputValueControl.value;
  }

  setInputValue(value: string): void {
    this.inputValueControl.setValue(value);
  }

  onInputBlur(): void {
    this.isFocused = false;
    if (this.options.isRequired && !this.inputValue && this.inputError) {
      this.inputError("Input is required.");
    } else {
      this.inputBlurred(this.inputValue);
    }
    this.updateSmallContentDisplay();
  }

  onInputFocus(): void {
    this.isFocused = true;
  }

  onInput(value: any): void {
    // This function will be replaced by the one from options
  }

  clearInput(): void {
    this.inputValueControl.markAsUntouched();
    this.inputValueControl.setValue("");
    this.inputCleared();
    this.isClearInput.emit(true);
  }

  inputBlurred(value: string): void {
    // This function will be replaced by the one from options
  }

  inputCleared(): void {
    // This function will be replaced by the one from options
  }

  inputError(message: string): void {
    // This function will be replaced by the one from options
  }
}

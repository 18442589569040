import { Component, computed, inject } from "@angular/core";
import { DatePipe } from "@angular/common";
import { PeriodType } from "@bitwarden/web-vault/app/services/store/calculation/controls/period.control";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";
import { DateRange } from "@angular/material/datepicker";
import { DeviceDetectorService } from "ngx-device-detector";
import { ViewsService } from "@bitwarden/web-vault/app/services/views/views.service";
import { View } from "@bitwarden/web-vault/app/models/enum/view.enum";
import { Observable } from "rxjs";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";

@Component({
  selector: "app-views-datepicker",
  templateUrl: "./views-datepicker.component.html",
})
export class ViewsDatepickerComponent {
  showStartPicker = false;
  showEndPicker = false;
  enableClickOutsideStart = false;
  enableClickOutsideEnd = false;
  calculationStoreService = inject(CalculationStoreService);
  private selectedPeriod: PeriodType;
  protected displayPeriod: { startDate: string; endDate: string };
  private selectedPeriodObservable: Observable<PeriodType>;
  protected viewsService: ViewsService = inject(ViewsService);
  deviceService = inject(DeviceDetectorService);

  constructor(private datePipe: DatePipe) {
    this.selectedPeriodObservable = toObservable(this.calculationStoreService.period.selected);
    this.selectedPeriodObservable.pipe(takeUntilDestroyed()).subscribe((period) => {
      this.selectedPeriod = period;
      this.displayPeriod = {
        startDate: this.datePipe.transform(period.startDate, "yyyy-MM-dd"),
        endDate: this.datePipe.transform(period.endDate, "yyyy-MM-dd"),
      };
    });
  }

  // period type from store
  currentDateRange = computed(() => this.calculationStoreService.period.selected());

  // date range type for mat-calendar
  initialDateRange = computed(() => {
    return new DateRange(this.currentDateRange().startDate, this.currentDateRange().endDate);
  });

  // display date text todo: format use preference
  displayDateText = computed(() => {
    return {
      startDate: this.datePipe.transform(this.currentDateRange().startDate, "yyyy-MM-dd"),
      endDate: this.datePipe.transform(this.currentDateRange().endDate, "yyyy-MM-dd"),
    };
  });

  onDateChange(event: DateRange<Date>, dateType: string): void {
    this.selectedPeriod = {
      startDate: event.start,
      endDate: event.end,
      _isDefault: false,
    };

    //this.updateDate(datePeriod);

    if (dateType === "startDate") {
      this.showStartPicker = false;
    }
    if (dateType === "endDate") {
      this.showEndPicker = false;
    }

    this.updateDate(this.selectedPeriod);
  }

  updateDate(dateRange: PeriodType) {
    this.calculationStoreService.period.updateSelection(dateRange);
  }

  onEndPickerConfirm() {
    this.showEndPicker = false;
    this.updateDate(this.selectedPeriod);
  }

  onPickerCancel() {
    this.showStartPicker = false;
    this.showEndPicker = false;
  }

  handleClickOutsideStart() {
    if (this.enableClickOutsideStart) {
      this.showStartPicker = false;
    }
    this.enableClickOutsideStart = true;
  }

  onStartPickerConfirm() {
    this.showStartPicker = false;
    this.showEndPicker = true;
    //this.updateDate(this.selectedPeriod);
  }

  toggleStartPickerVisibility() {
    this.showStartPicker = !this.showStartPicker;
    this.enableClickOutsideStart = false;
  }

  toggleEndPickerVisibility() {
    this.showEndPicker = !this.showEndPicker;
    this.enableClickOutsideEnd = false;
  }

  handleClickOutsideEnd() {
    if (this.enableClickOutsideEnd) {
      this.showEndPicker = false;
    }
    this.enableClickOutsideEnd = true;
  }

  protected readonly ViewEnum = View;
}

<div
  class="tw-flex tw-overflow-visible"
  role="dialog"
  aria-modal="true"
  aria-labelledby="deleteAccountTitle"
>
  <div class="tw-relative tw-mx-auto tw-w-full tw-rounded-lg tw-bg-white tw-p-6" role="document">
    <form
      class="tw-flex tw-flex-col tw-gap-6"
      #form
      [appApiAction]="formPromise"
      [formGroup]="deleteForm"
    >
      <div class="tw-flex md:tw-justify-between">
        <h1 class="modal-title" id="deleteAccountTitle">{{ "deleteAccount" | i18n }}</h1>
        <close-button [closeButtonType]="'plain'" class="tw-hidden md:tw-flex"></close-button>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-6">
        <p class="tw-mb-0 tw-text-justify">{{ "deleteAccountDesc" | i18n }}</p>
        <app-gloss-callout title="warning" body="deleteAccountWarning"></app-gloss-callout>
        <app-user-verification ngDefaultControl formControlName="verification">
        </app-user-verification>
      </div>
      <div class="tw-flex tw-justify-around tw-gap-6 md:tw-flex-col md:tw-gap-4">
        <app-gloss-button
          class="tw-w-full"
          [options]="{
            text: 'deleteAccount' | i18n,
            type: 'submit',
            class: 'danger-invert',
            onClick: submit.bind(this),
            isEnabled: !form.loading,
          }"
        ></app-gloss-button>

        <app-gloss-button
          mat-dialog-close
          aria-label="Close dialog"
          class="tw-w-full"
          [options]="{
            text: 'close' | i18n,
            class: 'neutral',
            isEnabled: true,
          }"
        ></app-gloss-button>
      </div>
    </form>
  </div>
</div>

import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { ConnectorResponse } from "../../../../models/data/response/connector.response";
import { Connector } from "../../../../models/data/blobby/connector.data";
import { ConnectorStoreModel } from "../../../../models/store/connector.store.model";
import { connector_v0_to_v1 } from "../model-dto/connector";

export class ConnectorParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Connector parser version ${version}`);
    }

    if (version === 0) {
      return ConnectorParser_v0;
    } else {
      return ConnectorParser_v1;
    }
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const ConnectorParser_v1 = (connectorItem: any): any => connectorItem as ConnectorStoreModel;
const ConnectorParser_v0 = (connectorItem: any): any => {
  const response = new ConnectorResponse(connectorItem);
  const connector = new Connector(response);
  const upgraded = connector_v0_to_v1(connector);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = connectorItem.vid ?? "";
  return ConnectorParser_v1(upgraded);
};

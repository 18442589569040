import { StoreModel } from "../../services/dali/vault-parser/parser.type";
import { GlossDateAsObjectType } from "./types/store.types";

export type ScenarioGroupModelVersion = 1;
export interface ScenarioGroupStoreModel extends StoreModel {
  /**  **Version Number** **/
  v: ScenarioGroupModelVersion;

  /** name **/
  nm: string;
  /** symbol **/
  sym: string;
  /** anchorPoint **/
  anP: GlossDateAsObjectType;
  /** endDate **/
  eD: GlossDateAsObjectType;
  /** scenarios **/
  scsId: string[];
  /** userDefinedEstimatesId **/
  estId: string[];
  /** source **/
  source: string;
}

export const DefaultScenarioGroupStoreModel: ScenarioGroupStoreModel = {
  nm: "",
  sym: "",
  anP: {
    date: "",
    time: null,
    timeZone: null,
  },
  eD: {
    date: "",
    time: null,
    timeZone: null,
  },
  scsId: [],
  estId: [],
  source: "",
  v: 1,
  vid: "",
  dc: "",
  dm: "",
  id: "",
};

import { BalanceAlignmentWorkerMessage } from "@bitwarden/web-vault/app/services/web-worker/balance-alignment/balance-alignment.worker.message";
import { WebWorkerImplementation } from "@bitwarden/web-vault/web-worker/web.worker.implementation";

export class BalanceAlignmentWorker extends WebWorkerImplementation<BalanceAlignmentWorkerMessage> {
  _resolve: (value?: any) => void;
  _reject: (reason?: any) => void;
  _workerType = "BalanceAlignmentWorker";

  protected constructor() {
    // also this can be thread_worker in Node.js environment
    const worker = new Worker(new URL("./balance-alignment.job.ts", import.meta.url), {
      type: "module",
    });
    super(worker);

    this.addEventListener("message", this.onMessageFromWorker.bind(this));
  }

  static getInstance(): BalanceAlignmentWorker {
    return new BalanceAlignmentWorker();
  }

  async realignTransactions(message: BalanceAlignmentWorkerMessage) {
    try {
      this.postMessage(message);
    } catch (e) {
      this.reject(e);
    }
  }

  /**
   * function to handle the message back from the worker service
   * @param message
   */
  onMessageFromWorker(message: MessageEvent): void {
    if (message.data.complete) {
      if (this.resolve) {
        this.resolve(message.data);
      } else if (this.reject) {
        this.reject(message.data);
      }
    }
  }

  get resolve(): (value?: any) => void {
    return this._resolve;
  }
  set resolve(resolve: (value?: any) => void) {
    this._resolve = resolve;
  }

  get reject(): (value?: any) => void {
    return this._reject;
  }
  set reject(reject: (value?: any) => void) {
    this._reject = reject;
  }
}

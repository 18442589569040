<div class="transaction-table-tabs">
  <div class="tabs-container sm:tw-justify-between">
    <ng-container *ngIf="tabs">
      <div class="tab-container" *ngFor="let tab of tabs">
        <div class="{{ tabContainerByDevice(tab) }}" (click)="updateTab(tab.type, tab.key)">
          {{ tabName(tab) }}
          <div *ngIf="tab.type === 'scenario' && tab.class.includes('selected')">
            <!--            todo: double click event here for quick fix, 1 is for using button id to trigger mimic click event for end of wizard, second is for changing icon color on tooltip open-->
            <button
              tabindex="-1"
              title="{{ 'pathHelpTitle' | i18n }}"
              id="scenario-{{ tab.key + 1 }}-help-button"
              (click)="openTabHelp($event, tab.key)"
            >
              <tooltip-icon
                [isClicked]="tooltip.isClicked"
                [size]="3"
                [class]="'tw-mb-[1px]'"
              ></tooltip-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <app-help-tooltip
      [showHelpTooltip]="tooltip.showHelpTooltip"
      [tooltipInfo]="'content'"
      [pointer]="'bottom'"
      [tabs]="tabs"
      [shouldRenderContent]="true"
      (onTooltipClose)="tooltip.close()"
      (toggleDeselected)="toggleSelected(null)"
    >
      <ng-container>
        <div class="help-heading">{{ scenarioPathName }}</div>
        <div>{{ scenarioPathDesc }}</div>
        <div
          class="tw-flex tw-flex-col tw-pt-3 tw-text-xs tw-leading-4"
          *ngIf="scenarioDetails?.helpInfo"
        >
          <ng-container
            *ngTemplateOutlet="
              scenarioInfo;
              context: {
                title: 'totalCurrentBalance' | i18n,
                info: getFormattedBalance(scenarioDetails.helpInfo?.totalBalance),
                condition: scenarioDetails.helpInfo?.totalBalance,
              }
            "
          >
          </ng-container>
          <ng-container
            *ngTemplateOutlet="
              scenarioInfo;
              context: {
                title: 'totalTransferAmount' | i18n,
                info: getFormattedBalance(scenarioDetails.helpInfo?.transferAmount),
                condition: scenarioDetails.helpInfo?.transferAmount,
              }
            "
          >
          </ng-container>
          <div
            class="tw-mb-4 tw-flex sm:tw-flex-col"
            *ngIf="scenarioDetails.helpInfo?.transferToAccount"
          >
            <div class="tooltip-label tw-w-[140px]">
              {{ "transferTo" | i18n }}
            </div>
            <ng-container
              *ngTemplateOutlet="
                accordionTooltip;
                context: { accounts: [scenarioDetails.helpInfo?.transferToAccount] }
              "
            >
            </ng-container>
          </div>
          <div
            class="tw-flex tw-flex-col"
            *ngIf="
              scenarioDetails.helpInfo?.transferFromAccount &&
              scenarioDetails.helpInfo.transferFromAccount.length > 0
            "
          >
            <div class="tw-mb-1 tw-flex">
              <div class="tooltip-label tw-w-[140px]">
                {{ "transferFrom" | i18n }}
              </div>
              <ng-container
                *ngTemplateOutlet="
                  existingAccount;
                  context: {
                    number: scenarioDetails.helpInfo.transferFromAccount.length,
                  }
                "
              >
              </ng-container>
            </div>
            <div class="tw-ml-[105px] md:tw-ml-0">
              <ng-container
                *ngTemplateOutlet="
                  accordionTooltip;
                  context: { accounts: scenarioDetails.helpInfo.transferFromAccount }
                "
              >
              </ng-container>
            </div>
          </div>
          <div class="tw-flex tw-flex-col" *ngIf="scenarioDetails.helpInfo?.existingAccounts">
            <div class="tw-mb-1 tw-flex tw-gap-6">
              <div class="tooltip-label">{{ "currentAccount" | i18n }}</div>
              <ng-container
                *ngTemplateOutlet="
                  existingAccount;
                  context: {
                    number: scenarioDetails.helpInfo.existingAccounts?.length,
                  }
                "
              >
              </ng-container>
            </div>
            <div class="tw-ml-[105px] md:tw-ml-0">
              <ng-container
                *ngTemplateOutlet="
                  accordionTooltip;
                  context: { accounts: scenarioDetails.helpInfo.existingAccounts }
                "
              >
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </app-help-tooltip>
  </div>

  <ng-template #accordionTooltip let-accounts="accounts">
    <ng-container *ngFor="let account of accounts">
      <app-accordion
        [contentTemplate]="accountDetails"
        collapseIcon="up-arrow"
        expandIcon="down-arrow"
        class="tw-w-full"
        customClass="tw-w-full tw-bg-tealgray-50"
      >
        <span title class="tw-w-[90%] tw-text-left">{{ getScenarioAccountInfo(account) }}</span>
      </app-accordion>

      <ng-template #accountDetails>
        <div class="tw-flex tw-items-baseline tw-gap-4 sm:tw-flex-col sm:tw-gap-0">
          <span class="tw-font-medium"> {{ "conditions" | i18n }}</span>
          <ul>
            <li
              class="tw-font-normal"
              *ngFor="let condition of getScenarioAccountConditions(account)"
            >
              {{ condition }}
            </li>
          </ul>
        </div>
        <div class="tw-mt-2 tw-flex tw-justify-between sm:tw-flex-col">
          <div class="tw-flex tw-gap-4 sm:tw-flex-col sm:tw-gap-0">
            <span class="tw-w-[60px] tw-font-medium sm:tw-w-full">{{ "lastReviewed" | i18n }}</span>
            <span class="tw-text-sm tw-font-normal" [innerHTML]="getLastCheckedDateMarkup(account)">
            </span>
          </div>
          <a
            href="{{ getUrl(account) }}"
            target="_blank"
            class="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-gap-2 tw-whitespace-nowrap tw-rounded-lg tw-bg-neutral-50 tw-px-6 tw-py-3 tw-font-medium tw-text-primary hover:tw-no-underline sm:tw-w-full"
          >
            {{ "linkToSite" | i18n }}
            <svg class="tw-h-4 tw-w-4 tw-text-primary">
              <use xlink:href="images/sprite.svg#link" />
            </svg>
          </a>
        </div>
      </ng-template>
    </ng-container>
  </ng-template>

  <ng-template #scenarioInfo let-title="title" let-info="info" let-condition="condition">
    <div class="tw-mb-4 tw-flex tw-gap-6" *ngIf="condition">
      <div class="tooltip-label tw-w-[150px]">
        {{ title }}
      </div>
      <div class="tw-w-full tw-bg-light tw-p-1 tw-px-6">
        {{ info }}
      </div>
    </div>
  </ng-template>

  <ng-template #existingAccount let-number="number">
    <div class="tw-flex tw-w-full tw-flex-col sm:tw-text-right">
      {{ number }}
      {{ "existingAccount" | i18n }}{{ number > 1 ? "s" : "" }}
    </div>
  </ng-template>

  <div class="buttons-container tw-flex" *ngIf="false">
    <button class="table-action-buttons right-space" disabled>
      <mat-icon class="transaction-action-button-icon" aria-hidden="true">edit</mat-icon>
      {{ "edit" | i18n }}
    </button>

    <button class="table-action-buttons right-space" (click)="resetColumns.emit()">
      <mat-icon class="transaction-action-button-icon" aria-hidden="true">history</mat-icon>
      {{ "reset" | i18n }}
    </button>

    <button class="table-action-buttons right-space" (click)="callDeleteTransaction()">
      <mat-icon class="transaction-action-button-icon" aria-hidden="true">delete</mat-icon>
      {{ "delete" | i18n }}
    </button>

    <div *ngIf="inDevMode">
      <button
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="table-action-buttons right-space"
        id="transaction-more-actions"
      >
        <div class="more-actions-dot"></div>
        <div class="more-actions-dot"></div>
        <div class="more-actions-dot"></div>
      </button>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button
          class="dropdown-item"
          href="#"
          (click)="callLinkConversion()"
          [disabled]="!isLinkButtonEnabled"
        >
          {{ "linkAsConversion" | i18n }}
        </button>
        <button
          class="dropdown-item"
          href="#"
          (click)="callLinkTransfer()"
          [disabled]="!isLinkButtonEnabled"
        >
          {{ "linkAsTransfer" | i18n }}
        </button>
        <button
          class="dropdown-item"
          href="#"
          (click)="callUnlinkTransactions()"
          [disabled]="!isLinkButtonEnabled"
        >
          {{ "removeLinkage" | i18n }}
        </button>
      </div>
    </div>
  </div>
</div>

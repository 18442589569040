import { Injectable } from "@angular/core";

import { CryptoService } from "@bitwarden/common/abstractions/crypto.service";
import { CryptoFunctionService } from "@bitwarden/common/abstractions/cryptoFunction.service";
import { EncryptService } from "@bitwarden/common/abstractions/encrypt.service";
import { EncArrayBuffer } from "@bitwarden/common/models/domain/enc-array-buffer";
import { SymmetricCryptoKey } from "@bitwarden/common/models/domain/symmetric-crypto-key";

@Injectable({
  providedIn: "root",
})
export class FileEncryptionService {
  private encryptedKey: SymmetricCryptoKey;
  private encryptedFile: EncArrayBuffer;
  private decryptedFile: File;

  constructor(
    private cryptoService: CryptoService,
    private cryptoFunctionService: CryptoFunctionService,
    private encryptService: EncryptService,
  ) {}

  async encrypt(file: File) {
    await this.generateKey();
    const fileBuffer = await file.arrayBuffer();
    this.encryptedFile = await this.encryptService.encryptToBytes(fileBuffer, this.encryptedKey);
  }

  async decrypt(encArrayBuffer: EncArrayBuffer, encryptedKey: SymmetricCryptoKey) {
    const decryptedFileData = await this.encryptService.decryptToBytes(
      encArrayBuffer,
      encryptedKey,
    );
    const blob = new Blob([decryptedFileData]);
    this.decryptedFile = new File([blob], "decryptedFile");

    return this.decryptedFile;
  }

  private async generateKey() {
    const key = await this.cryptoFunctionService.randomBytes(16);
    this.encryptedKey = await this.cryptoService.makeSendKey(key);
  }

  getEncryptedKey() {
    return this.encryptedKey;
  }

  getEncryptedFile() {
    return this.encryptedFile;
  }
}

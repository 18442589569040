import {
  OnInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnInit {
  @Input() options: { label: string; desc?: string; value: string }[] = [];
  @Input() defaultTabSelected: string;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  selectedTab: string;

  ngOnInit() {
    const defaultValue = this.defaultTabSelected || this.options[0]?.value;
    this.setSelectedTab(defaultValue);
  }

  onTabChange(value: string) {
    this.setSelectedTab(value);
    this.onChange.emit(value);
  }

  setSelectedTab(value: string) {
    this.selectedTab = value;
  }

  isSelectedTab(value: string) {
    return this.selectedTab === value;
  }
}

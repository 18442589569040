import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { AccountView } from "../../../models/view/account/account.view";
import { AccountStoreModel } from "../../../models/store/account.store.model";
import { signal } from "@angular/core";

export class AccountsStoreCollection extends StoreModelCollection<AccountStoreModel, AccountView> {
  readonly type: StoreModelNames = "AccountStoreModel";
  protected models: Map<string, AccountStoreModel> = new Map<string, AccountStoreModel>();

  private _accountViews = signal<AccountView[]>(null);
  readonly accountViews = this._accountViews.asReadonly();

  triggerChanges(): void {
    const views: AccountView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new AccountView(model)) as AccountView);
    });
    this._accountViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._accountViews.set(null);
  }
}

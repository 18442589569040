import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DropdownBaseComponent } from "@bitwarden/web-vault/app/components/dropdown/dropdown-base.component";

@Component({
  selector: "app-dropdown-expand",
  templateUrl: "./dropdown-expand.component.html",
})
export class DropdownExpandComponent extends DropdownBaseComponent {
  @Input() options: string[] = [];
  @Input() selectedOption: string;
  @Input() title: string = "";
  @Input() testId: string = "";
  @Output() optionSelected = new EventEmitter<string>();
}

import * as d3 from "d3";

export class Scales {
  xScale: d3.ScaleTime<number, number, never>;
  yScale: d3.ScaleLinear<number, number, never>;

  constructor() {
    this.xScale = d3.scaleTime();
    this.yScale = d3.scaleLinear();
  }

  setScales(
    innerWidth: number,
    innerHeight: number,
    minYAxisValue: number,
    maxYAxisValue: number,
    startDate: Date,
    endDate: Date,
  ) {
    this.setXScales(innerWidth, startDate, endDate);
    this.setYScales(innerHeight, minYAxisValue, maxYAxisValue);
  }

  setXScales(innerWidth: number, startDate: Date, endDate: Date) {
    this.xScale
      // adjust to the available screen area
      .range([0, innerWidth])
      .domain([startDate, endDate]);
  }

  setYScales(innerHeight: number, minYAxisValue: number, maxYAxisValue: number) {
    const yRange = maxYAxisValue - minYAxisValue;
    this.yScale
      // adjust to the available screen area
      .rangeRound([innerHeight, 0])
      // adjust to the available screen area
      // add a bit of percentage padding to either end
      .domain([minYAxisValue - yRange * 0.1, maxYAxisValue + yRange * 0.05]);
  }

  static calculateMaxMinYAxisValues(data: Map<string, number>) {
    // using the data from the balance graph and the scenario
    // work out the upper and lower boundaries of the y-axis
    const maxYAxisValue = Math.max(...data.values());
    const minYAxisValue = Math.min(...data.values());

    return [minYAxisValue, maxYAxisValue];
  }

  static calculateMaxMinYAxisValuesList(data: Map<string, number>[]) {
    // using the data from the balance graph and the scenario
    // work out the upper and lower boundaries of the y-axis
    let maxYAxisValue: number = null;
    let minYAxisValue: number = null;

    for (const dataSet of data) {
      const maxY = Math.max(...dataSet.values());
      const minY = Math.min(...dataSet.values());

      if (!isNaN(maxY) && maxY !== Infinity) {
        if (maxYAxisValue === null || maxY > maxYAxisValue) {
          maxYAxisValue = maxY;
        }
      }
      if (!isNaN(minY) && minY !== Infinity) {
        if (minYAxisValue === null || minY < minYAxisValue) {
          minYAxisValue = minY;
        }
      }
    }

    return [minYAxisValue, maxYAxisValue];
  }
}

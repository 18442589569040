import { filter, distinctUntilChanged, Observable } from "rxjs";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";

export const getFirstTransactionDateSubscriber = (observable: Observable<Date>) => {
  return observable.pipe(
    filter((x) => {
      return !!x;
    }),
    distinctUntilChanged((prev, curr) => {
      return prev.toISOString() === curr.toISOString();
    }),
  );
};

export const getSymbolsSubscriber = (observable: Observable<SymbolView[]>) => {
  return observable.pipe(
    filter((x) => {
      return x !== null && x?.length > 0;
    }),
    distinctUntilChanged((prev, curr) => {
      const prevCodes = prev.map((s) => s.code);
      const currCodes = curr.map((s) => s.code);
      return (
        prevCodes.length === currCodes.length && prevCodes.every((code) => currCodes.includes(code))
      );
    }),
  );
};

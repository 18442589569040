import * as d3 from "d3";

import { DateTimeFormatter } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/date-time-formatter";
import { Slider } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/slider";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";

export class InOutBoxes {
  private graphContentGroup: any;
  private dateTimeFormatter: DateTimeFormatter;
  private xScale: d3.ScaleBand<string>;
  private yScale: d3.ScaleLinear<number, number, never>;
  private slider: Slider;
  private incomeColour = "#00C4C4";
  private expenseColour = "#2A6CE2";

  constructor(
    graphContentGroup: any,
    dateTimeFormatter: DateTimeFormatter,
    xScale: d3.ScaleBand<string>,
    yScale: d3.ScaleLinear<number, number, never>,
    slider: Slider,
  ) {
    this.graphContentGroup = graphContentGroup;
    this.dateTimeFormatter = dateTimeFormatter;
    this.xScale = xScale;
    this.yScale = yScale;
    this.slider = slider;
  }

  private updateBoxes(
    graphData: GraphDataSet[],
    typeName: string,
    color: string,
    opacity: number,
    valueAccessor: (d: GraphDataSet) => number,
  ) {
    const calculateY = (d: GraphDataSet) => {
      if (typeName === "Income" || typeName === "EstIncome") {
        return this.yScale(d.prevBalance) - (this.yScale(0) - this.yScale(valueAccessor(d)));
      } else if (typeName === "Expenses" || typeName === "EstExpenses") {
        return this.yScale(d.prevBalance);
      }
      // Handle other types or provide a default value
      return 0;
    };

    this.graphContentGroup
      .selectAll(`#rect${typeName}`)
      .data(graphData)
      .join("rect")
      .attr("fill", color)
      .attr("fill-opacity", opacity)
      .attr("x", (d: GraphDataSet) => {
        if (!d?.midDate) {
          return (
            this.xScale(
              this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(d.date)),
            ) +
            this.xScale.bandwidth() / 2 -
            1
          );
        } else {
          const bandData = this.slider.findBand(d.midDate);
          return this.slider.calculateMiddleX(bandData, d.midDate);
        }
      })
      .attr("width", 2)
      .attr("id", `rect${typeName}`)
      .attr("y", (d: GraphDataSet) => calculateY(d))
      .attr("height", (d: GraphDataSet, i: number) =>
        i !== 0 ? this.yScale(0) - this.yScale(valueAccessor(d)) : 0,
      );
  }

  updateIncomeExpensesBoxes(graphData: GraphDataSet[]) {
    this.updateBoxes(graphData, "Income", this.incomeColour, 0.8, (d) => d.in);
    this.updateBoxes(graphData, "Expenses", this.expenseColour, 0.8, (d) => d.out);
  }

  updateEstimateIncomeExpensesBoxes(graphData: GraphDataSet[]) {
    this.updateBoxes(graphData, "EstIncome", "green", 0.2, (d) => d.estimateIn);
    this.updateBoxes(graphData, "EstExpenses", "red", 0.2, (d) => d.estimateOut);
  }
}

<div class="tw-rounded-xl tw-bg-white tw-p-4">
  <div class="tw-mb-4 tw-flex tw-items-center tw-justify-between">
    <div class="tw-flex tw-items-center" *ngIf="!hideIcon">
      <icon [size]="6" [name]="iconName" class="bordered-icon"> </icon>
      <span class="tw-ml-4 tw-text-xl tw-font-medium">{{ title }}</span>
    </div>
    <div *ngIf="menu" class="tw-rounded-xl tw-bg-neutral-50 tw-p-1">
      <div class="tw-flex tw-cursor-pointer tw-items-center tw-gap-1">
        @if (menu.type === "text") {
          <div
            *ngFor="let option of menu.options"
            class="tw-p-2"
            [ngClass]="{ 'widget-menu-selected': option.isSelected }"
            (click)="onOptionClick(option.value)"
          >
            {{ option.displayValue | capitalise }}
          </div>
        }

        @if (menu.type === "icon") {
          <icon
            *ngFor="let option of menu.options"
            class="tw-p-1"
            [ngClass]="{ 'widget-menu-selected': option.isSelected }"
            [size]="5"
            (click)="onOptionClick(option.value)"
            [name]="option.displayValue"
          >
          </icon>
        }
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>

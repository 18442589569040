import { StoreModel } from "../dali/vault-parser/parser.type";
import { StoreModelNames } from "@bitwarden/web-vault/app/services/dali/type/dali.type";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export abstract class ModelStoreAbstraction {
  /** Store need implement Log **/
  protected abstract log: LogService;

  /** Store required method **/
  abstract clearStore(): void;
  abstract initialize(): void;

  protected abstract getStorageCollection(
    modelName: StoreModelNames,
  ): StoreModelCollection<StoreModel, ModelView<StoreModel>>;

  /** Store shared method **/
  addStoreModels(modelName: StoreModelNames, data: StoreModel[]): void {
    const storeCollection = this.getStorageCollection(modelName);
    storeCollection.loadModels(data);
  }
}

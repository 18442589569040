import { Allocation } from "../../../models/data/allocation.data";
import {
  ItemType,
  AllocClassificationsType,
  CategoriesType,
} from "../../../models/types/allocations-type";
import { SplitClassificationType } from "../../../models/types/split-classification-type";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";

export abstract class AllocationUtils {
  static isValidToProcess(classifications: SplitClassificationType[]) {
    return classifications.length > 0;
  }

  static getSplitAmount(
    item: ItemType,
    overFlow: number,
    totalWeight: number,
    quantityAmount: number,
    precision = 2,
  ) {
    if (!item) {
      return quantityAmount;
    }

    let amount = quantityAmount;
    if (totalWeight !== 0) {
      amount = Number.parseFloat((quantityAmount / totalWeight).toFixed(precision));
    }

    if (item.roundingDefault && !isNaN(overFlow)) {
      amount += overFlow;
    }
    return amount;
  }

  static isDivisible(divide: number, divider: number) {
    return divide % divider === 0;
  }

  /**
   * example to understand : divide=100 , divider = 3
   * parts = 33.33
   * return = 100 - (33.33 * 3) = 0.01
   */
  static getRemainder(divide: number, divider: number, precision = 2) {
    const parts = Number.parseFloat((divide / divider).toFixed(precision));
    return divide - divider * parts;
  }

  static getTotalWeight(items: Array<ItemType>) {
    return items.reduce((total: number, item: ItemType) => total + item.weight, 0);
  }

  static getClassOverFlow(
    quantity: number,
    totalClassWeight: number,
    isBaseDivisible: boolean,
    precision = 2,
  ) {
    return !isBaseDivisible ? Allocation.getRemainder(quantity, totalClassWeight, precision) : 0;
  }

  static processAllocations(
    allocClassifications: AllocClassificationsType,
    allocCategories: CategoriesType,
    direction: string,
  ) {
    const allocations: Allocation[] = [];
    const allocation = new Allocation();
    const { classifications, classOverFlow, totalClassWeight, actualQuantity, totalWeight } =
      allocClassifications;
    const { categories, totalCatWeight } = allocCategories;

    classifications.forEach((cls) => {
      const classificationAmount = Allocation.getSplitAmount(
        cls,
        classOverFlow,
        totalClassWeight,
        actualQuantity.amount,
      );
      const categoryBaseDivisible = Allocation.isDivisible(classificationAmount, totalWeight);
      let catOverFlow = 0;
      if (!categoryBaseDivisible) {
        catOverFlow = Allocation.getRemainder(classificationAmount, totalCatWeight);
      }

      if (categories.length === 0) {
        allocation.classification = cls.classificationId;
        allocation.setValueSymbol(actualQuantity.symbol);
        if (direction === "OUT") {
          allocation.setValueAmount(classificationAmount * -1);
        } else {
          allocation.setValueAmount(classificationAmount);
        }
        allocations.push(allocation);
      }

      categories.forEach((cat) => {
        const categoryAmount = Allocation.getSplitAmount(
          cat,
          catOverFlow,
          totalCatWeight,
          classificationAmount,
        );
        allocation.classification = cls.classificationId;
        allocation.category = cat.categoryId;
        allocation.setValueSymbol(actualQuantity.symbol);
        if (direction === "OUT") {
          allocation.setValueAmount(categoryAmount * -1);
        } else {
          allocation.setValueAmount(categoryAmount);
        }
        allocations.push(allocation);
      });
    });

    return allocations;
  }

  static setAllocation(transaction: TransactionView) {
    if (!Allocation.isValidToProcess(transaction.classifications)) {
      return;
    }
    const glossQuantity = transaction.quantity;
    // const glossQuantity = new GlossQuantity().setToQuantityObj(this.quantity);
    const totalClassWeight = Allocation.getTotalWeight(transaction.classifications);
    const totalCatWeight = Allocation.getTotalWeight(transaction.categories);
    const totalWeight = totalCatWeight * totalClassWeight;
    const isBaseDivisible = Allocation.isDivisible(
      glossQuantity.actualQuantity.amount,
      totalClassWeight,
    );
    const classOverFlow = Allocation.getClassOverFlow(
      glossQuantity.actualQuantity.amount,
      totalClassWeight,
      isBaseDivisible,
    );
    const allocClassifications = {
      classifications: transaction.classifications,
      classOverFlow,
      totalClassWeight,
      actualQuantity: glossQuantity.actualQuantity,
      totalWeight,
    };
    const allocCategories = { categories: transaction.categories, totalCatWeight };

    transaction.c_allocations = Allocation.processAllocations(
      allocClassifications,
      allocCategories,
      transaction.direction,
    );
  }
}

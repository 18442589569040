import { ParserFunctionType } from "../parser.type";
import { ParserAbstraction } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/parser.abstraction";
import { SyncStatusStoreModel } from "../../../../models/store/sync-status.store.model";

export class SyncStatusParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Book parser version ${version}`);
    }

    return SyncStatusParser_v1;
  }

  override getSupportedVersion(): number[] {
    return [1];
  }
}

const SyncStatusParser_v1 = (vaultItem: any): SyncStatusStoreModel =>
  vaultItem as SyncStatusStoreModel;

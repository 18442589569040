<div class="tw-flex tw-h-full tw-items-center tw-justify-end">
  <div class="tw-flex tw-h-full tw-flex-col tw-items-end tw-justify-center">
    <span
      [ngClass]="{ 'tw-text-success': balance > 0, 'tw-text-danger': balance <= 0 }"
      class="tw-block tw-text-sm tw-font-semibold tw-text-success"
    >
      {{ balance | number: "1.2-2" }}
    </span>
    <span class="tw-text-xs tw-font-normal tw-text-neutral-600">{{ symbol }}</span>
  </div>
</div>

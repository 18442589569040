import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { ValidatorResponseTypes } from "@bitwarden/web-vault/app/validators/link.transactions/link-validator";

import { Transaction } from "../../../models/data/blobby/transaction.data";
import { LinkTransactionsValidator } from "../../../validators/link.transactions/link-transactions.validator";

export class LinkTransaction extends LinkTransactionsValidator {
  protected loading: boolean;
  protected unselectAll: boolean;
  protected linkIds: string[] = [];
  protected check: Record<string, boolean> = {};
  protected linkedTransactions: Transaction[] = [];
  protected linkedTo: string[] = [];
  protected UNLINK_RESPONSE: ValidatorResponseTypes = {
    status: "success",
    messageId: "linkedTransactionSuccess",
    description: "",
  };

  constructor() {
    super();
  }

  private linkRemovedView(
    transView: TransactionView,
    unlinkTransactions: Transaction[],
  ): TransactionView {
    const linkedTransaction = this.linkedTransactions.find(
      (linkedTrans) => linkedTrans.id === transView.id,
    );

    if (linkedTransaction) {
      linkedTransaction.linkedTo = [];
      unlinkTransactions.push(linkedTransaction);

      //return transView.update(linkedTransaction);
    } else {
      return transView;
    }
  }
  async removeLinkage(transactionsView: TransactionView[]) {
    this.loading = true;
    const unlinkTransactions: Transaction[] = [];

    const newTransactionsView = transactionsView.map((transView) => {
      const isRevalAndLinked =
        transView.revalTransaction &&
        transView.linkedTo.some((link) => this.linkIds.includes(link));

      if (isRevalAndLinked) {
        const baseTransaction = transView.baseTransaction;
        baseTransaction.linkedTo = [];
        return transView.update(baseTransaction);
      }

      if (this.linkIds.includes(transView.id)) {
        return this.linkRemovedView(transView, unlinkTransactions);
      } else {
        return transView;
      }
    });
    return { unlinkTransactions, newTransactionsView };
  }

  mapLikedTransactionsToEachOther() {
    this.linkedTransactions = this.linkedTransactions.map((lt) => {
      lt.linkedTo = this.linkIds.filter((linkId) => linkId !== lt.id);
      return lt;
    });
  }
  selectToLink(transaction: Transaction) {
    this.linkedTransactions.push(transaction);
    this.linkIds.push(transaction.id);
    this.mapLikedTransactionsToEachOther();
  }

  unselectToLink(transaction: Transaction) {
    this.linkedTransactions = this.linkedTransactions.filter(
      (linkedTransaction) => linkedTransaction.id !== transaction.id,
    );
    this.linkIds = this.linkIds.filter((linkId) => linkId !== transaction.id);
    this.mapLikedTransactionsToEachOther();
  }

  protected isValidToLink(validatorResponse: ValidatorResponseTypes) {
    return validatorResponse.status === "success";
  }

  protected async showToaster(
    response: ValidatorResponseTypes,
    platformUtilsService: PlatformUtilsService,
    i18nService: I18nService,
  ) {
    const { status, messageId, description } = response;
    platformUtilsService.showToast(status, i18nService.t(messageId), i18nService.t(description));
    this.loading = false;
  }

  protected resetProps() {
    for (const id of this.linkIds) {
      this.check[id] = false;
    }
    this.linkIds = [];
    this.linkedTransactions = [];
    this.unselectAll = false;
  }

  protected isLinkButtonEnabled() {
    return this.linkedTransactions?.length > 1;
  }
  protected isActionButtonEnabled() {
    return this.linkedTransactions?.length > 0;
  }

  protected showLinkIcon(id: string) {
    return !this.linkIds.includes(id);
  }
}

<div
  class="tw-flex tw-h-20 tw-w-full tw-overflow-auto tw-bg-blue-100 tw-rounded-t-lg tw-pt-2 tw-px-2 sm:hide-scrollbars"
>
  @for (option of options; track option.value) {
    <div
      class="tw-flex tw-flex-col tw-cursor-pointer tw-items-center tw-justify-center tw-p-4 tw-rounded-t-lg"
      [ngClass]="{ 'tw-bg-white': isSelectedTab(option.value) }"
      (click)="onTabChange(option.value)"
    >
      <div class="tw-text-lg tw-font-normal tw-whitespace-nowrap">{{ option.label }}</div>
      @if (option.desc) {
        <div
          class="tw-rounded-full tw-flex tw-bg-orange-100 tw-whitespace-nowrap tw-py-2 tw-px-4 tw-text-center"
          [ngClass]="{ '!tw-bg-blue-50': isSelectedTab(option.value) }"
        >
          {{ option.desc }}
        </div>
      }
    </div>
  }
</div>

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { AccountView } from "../../models/view/account/account.view";

@Injectable({
  providedIn: "root",
})
export class SelectAccountService {
  private selectedAccount$: BehaviorSubject<AccountView> = new BehaviorSubject<AccountView>(null);

  setSelectedAccount(account: AccountView) {
    this.selectedAccount$.next(account);
  }

  getSelectedAccount() {
    return this.selectedAccount$;
  }
}

import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { AccountManualType } from "@bitwarden/web-vault/app/models/types/account.types";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

const ACCOUNT_MANUAL_TYPES: { key: AccountManualType; title: string }[] = [
  { key: "bank", title: "addBankAccount" },
  { key: "credit", title: "addCreditAccount" },
  { key: "stock", title: "addStockTradingAccount" },
  { key: "other", title: "addOtherAccount" },
];

@Component({
  selector: "app-account-create-main",
  templateUrl: "./account-create-main.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountCreateMainComponent {
  protected readonly ACCOUNT_TYPES = ACCOUNT_MANUAL_TYPES;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      onSelectAccountType: (type: AccountManualType) => void;
    },
  ) {}

  tileButtonClicked(type: AccountManualType) {
    this.data.onSelectAccountType(type);
  }
}

import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { CategoriesAddEditComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-categories/categories-add-edit/categories-add-edit.component";
import { ClassificationAddEditComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-classifications/classification-add-edit/classification-add-edit.component";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

type Option = Category | Classification;
@Component({
  selector: "app-split-options",
  templateUrl: "./split-options.component.html",
})
export class SplitOptionsComponent {
  /** The form group that this component is a part of */
  @Input() formGroup: FormGroup;

  /** The name of what is being split over. category-renderer?, classification? */
  @Input() title: string;

  /** The condition to open or close the options */
  @Input() isPanelOpen: boolean;

  /** The control name of the main input*/
  @Input() mainFormControlName: string;

  /** Prefix to put at the beginning of every option's form control name*/
  @Input() optionControlNamePrefix: string;

  /** Button to add new Option */
  @Input() addNewButtonText: string;

  /** The options */
  @Input() options: Option[];

  /** The option to edit */
  @Input() selectedOptions: (SplitCategoryType | SplitClassificationType)[];

  /** The method that will open the options panel*/
  @Output() toggleOptionsPanel: EventEmitter<any> = new EventEmitter<any>();

  appliedOptions: Option[] = [];
  totalOptionSplit = 0;
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  resetMap = new Map<string, number>();
  constructor(
    private dataRepositoryService: DataRepositoryService,
    public dialog: MatDialog,
    private optionDialogRef: MatDialogRef<
      ClassificationAddEditComponent | CategoriesAddEditComponent
    >,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.options && changes.options.currentValue) ||
      (changes.selectedOptions && changes.selectedOptions.currentValue)
    ) {
      const optionsValue = this.getSelectedOptions();
      this.formGroup.controls[this.mainFormControlName].setValue(optionsValue);
    }
  }

  getTotalOptionSplit() {
    return this.selectedOptions.reduce((acc, option) => acc + option.weight, 0);
  }

  setSelectedOption() {
    const selectedOptionsString = this.getSelectedOptions();

    this.formGroup.controls[this.mainFormControlName].setValue(selectedOptionsString);
  }
  getSelectedOptions(): string {
    let selectedOptionsValue = "";
    let option: Option;

    for (const selectedOption of this.selectedOptions) {
      if (this.options && this.options.length) {
        if (this.mainFormControlName === "categories") {
          const splitOption = <SplitCategoryType>selectedOption;
          option = this.options.find((c) => c.id === splitOption.categoryId);
          this.formGroup.controls[`${this.optionControlNamePrefix}_${option.id}`].setValue(
            splitOption.weight,
          );
          this.addAppliedOption(option);
          this.totalOptionSplit = this.getTotalOptionSplit();
          selectedOptionsValue = `${selectedOptionsValue}, ${option.name}(${this.getProperNumber(
            splitOption.weight,
            this.totalOptionSplit,
          )}%)`;
          this.resetMap.set(`${this.optionControlNamePrefix}_${option.id}`, splitOption.weight);
        }

        if (this.mainFormControlName === "classifications") {
          const splitOption = <SplitClassificationType>selectedOption;
          option = this.options.find((c) => c.id === splitOption.classificationId);
          this.formGroup.controls[`${this.optionControlNamePrefix}_${option.id}`].setValue(
            splitOption.weight,
          );
          this.addAppliedOption(option);
          this.totalOptionSplit = this.getTotalOptionSplit();
          selectedOptionsValue = `${selectedOptionsValue}, ${option.name}(${this.getProperNumber(
            splitOption.weight,
            this.totalOptionSplit,
          )}%)`;

          this.resetMap.set(`${this.optionControlNamePrefix}_${option.id}`, splitOption.weight);
        }
      }
    }
    return selectedOptionsValue.substring(1);
  }
  emitToggleOptionsPanel() {
    for (const key in this.formGroup.controls) {
      if (key.startsWith(this.optionControlNamePrefix)) {
        this.resetMap.set(key, this.formGroup.controls[key].value);
      }
    }

    this.toggleOptionsPanel.emit();
  }

  discardChanges() {
    for (const key of this.resetMap.keys()) {
      this.formGroup.controls[key].setValue(this.resetMap.get(key));
    }
    this.setSelectedOption();
    this.toggleOptionsPanel.emit();
  }

  onOptionSplitChange(option: Option, event: any) {
    this.setOptionsSplits(option, event.target.value);
  }

  isOptionApplied(option: Option) {
    return this.appliedOptions.some((o) => o.id === option.id);
  }

  addAppliedOption(option: Option) {
    if (!this.isOptionApplied(option)) {
      this.appliedOptions.push(option);
      this.setTotalSplitOptions();
    }
  }

  setTotalSplitOptions() {
    this.totalOptionSplit = this.appliedOptions.reduce(
      (acc, option) =>
        acc + this.formGroup.controls[`${this.optionControlNamePrefix}_${option.id}`].value,
      0,
    );
  }

  removeAppliedOption(option: Option) {
    this.appliedOptions = this.appliedOptions.filter((c) => c.id !== option.id);
    this.setTotalSplitOptions();
  }

  setOptionsSplits(option: Option, value: number) {
    let optionsValue = "";

    if (this.isOptionApplied(option)) {
      if (value <= 0) {
        this.removeAppliedOption(option);
      }
    } else {
      if (value > 0) {
        this.addAppliedOption(option);
      }
    }
    this.setTotalSplitOptions();
    for (const key in this.formGroup.controls) {
      if (key.startsWith(this.optionControlNamePrefix + "_")) {
        const optionId = key.split("_")[1];
        const option = this.options.find((option) => option.id === optionId);
        if (this.isOptionApplied(option)) {
          const splitValue = this.formGroup.controls[key].value;
          optionsValue = `${optionsValue}, ${option.name}(${this.getProperNumber(
            splitValue,
            this.totalOptionSplit,
          )}%)`;
        }
      }
    }
    this.formGroup.controls[this.mainFormControlName].setValue(optionsValue.substring(1));
  }

  getProperNumber(splitValue: number, total: number) {
    return ((splitValue / total) * 100).toFixed(1);
  }

  async openOptionCreationModal(event: Event) {
    event.preventDefault();
    const dialogConfig = {
      width: "800px",
      disableClose: true,
      data: {
        actionSucceeded: this.optionCreated.bind(this),
      },
    };
    if (this.mainFormControlName === "categories") {
      this.optionDialogRef = await this.dialog.open(CategoriesAddEditComponent, dialogConfig);
    }

    if (this.mainFormControlName === "classifications") {
      this.optionDialogRef = await this.dialog.open(ClassificationAddEditComponent, dialogConfig);
    }

    this.optionDialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  async optionCreated() {
    let options: Option[] = [];
    if (this.mainFormControlName === "categories") {
      options = await this.dataRepositoryService.getAllCategories();
    }
    if (this.mainFormControlName === "classifications") {
      options = await this.dataRepositoryService.getAllClassifications();
    }

    this.options = options;
    const lastOption = options[options.length - 1];
    this.formGroup.addControl(
      `${this.optionControlNamePrefix}_${lastOption.id}`,
      new FormControl(1),
    );
    this.setOptionsSplits(lastOption, 1);
    this.optionDialogRef.close(options);
  }
}

import { Component, Inject, Type } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-wrapper",
  templateUrl: "./dialog-wrapper.component.html",
})
export class DialogWrapperComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      showBackButton: boolean;
      component: Type<any>;
    },
  ) {}

  getComponent() {
    return this.data.component;
  }
}

export enum MacroNumbersSymbol {
  TRILLION = "T",
  BILLION = "B",
  MILLION = "M",
  THOUSAND = "k",
}

export enum MacroNumbers {
  TRILLION = 1_000_000_000_000,
  BILLION = 1_000_000_000,
  MILLION = 1_000_000,
  THOUSAND = 1_000,
}

export const MacroNumbersDetails = [
  { symbol: MacroNumbersSymbol.TRILLION, amount: MacroNumbers.TRILLION },
  { symbol: MacroNumbersSymbol.BILLION, amount: MacroNumbers.BILLION },
  { symbol: MacroNumbersSymbol.MILLION, amount: MacroNumbers.MILLION },
  { symbol: MacroNumbersSymbol.THOUSAND, amount: MacroNumbers.THOUSAND },
];

import { Injectable, computed, signal } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import { DatePickerType } from "@bitwarden/web-vault/app/models/types/date-picker.types";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  private _dateRangeSignal = signal<DateRange<Date>>(new DateRange<Date>(null, null));
  dateRange = this._dateRangeSignal.asReadonly();

  private _dateFilterSignal = signal<(date: Date) => boolean>(() => true);

  updateDateRange(dateRange: DateRange<Date>, pickerType?: DatePickerType) {
    this._dateRangeSignal.set(dateRange);
    this.updateDateFilter(pickerType);
  }

  get currentDateRange(): DateRange<Date> {
    return this._dateRangeSignal();
  }

  get dateRangeComputed() {
    return this._dateRangeSignal;
  }

  get dateFilterComputed() {
    return this._dateFilterSignal;
  }

  updateDateFilter(pickerType: DatePickerType) {
    const filter = (date: Date) => {
      if (!date) {
        return false;
      }
      if (this.currentDateRange.start) {
        if (pickerType === "end") {
          return date >= this.currentDateRange.start;
        }
        return date <= this.currentDateRange.end;
      }
      return true;
    };
    this._dateFilterSignal.set(filter);
  }

  getDateFilter(pickerType: "start" | "end"): (date: Date | null) => boolean {
    return (date: Date | null) => {
      if (!date) {
        return false;
      }
      if (pickerType === "end" && this.dateRangeComputed().start) {
        return date >= this.dateRangeComputed().start;
      }
      return true;
    };
  }
}

import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { WizardStoreModel } from "../../../models/store/wizard.store.model";
import { model, signal } from "@angular/core";
import { WizardView } from "@bitwarden/web-vault/app/models/view/wizard/wizard.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class WizardStoreCollection extends StoreModelCollection<
  WizardStoreModel,
  ModelView<WizardStoreModel>
> {
  readonly type: StoreModelNames = "WizardStoreModel";

  protected models: Map<string, WizardStoreModel> = new Map<string, WizardStoreModel>();

  private _wizardViews = signal<WizardView[]>(null);
  readonly wizardViews = this._wizardViews.asReadonly();

  triggerChanges(): void {
    const views: WizardView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new WizardView(model)) as WizardView);
    });

    this._wizardViews.set(views);
  }

  getDefaultAccountCreationWizard(): WizardView {
    const AccountWizard = new WizardView();
    AccountWizard.step = { isComplete: false, stepIndex: 1 };
    AccountWizard.type = "account";
    return AccountWizard;
  }

  clear(): void {
    this.models.clear();
    this._wizardViews.set(null);
  }
}

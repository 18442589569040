import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

export function getTransactionAmount(transaction: TransactionView, useNormalized = true): number {
  let transactionAmount = 0;
  if (useNormalized) {
    transactionAmount = transaction.c_valuation.normalizedValue.amount;
  } else {
    transactionAmount = transaction.quantity.actualQuantity.amount;
    if (transaction.direction === TransactionDirection.Out && transactionAmount > 0) {
      transactionAmount = -1 * transactionAmount;
    }
  }
  return transactionAmount;
}

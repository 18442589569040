<form #form [appApiAction]="formPromise" ngNativeValidate>
  <div
    class="tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-items-center tw-justify-center tw-px-8 tw-py-8 xs:tw-px-2"
  >
    <div class="mb-4 tw-text-center">
      <i class="bwi bwi-lock bwi-4x text-muted" aria-hidden="true"></i>
    </div>
    <p class="tw-mb-4 tw-text-center tw-text-xl">
      {{ "yourVaultIsLocked" | i18n }} <br />
      {{ "verifyPassword" | i18n }}
    </p>
    <div
      class="tw-border-secondary-300 tw-mt-3 tw-flex tw-flex-col tw-rounded-3xl tw-bg-background tw-p-6 tw-shadow-xl xs:tw-flex-none"
    >
      <bit-form-field>
        <bit-label>{{ "masterPass" | i18n }} *</bit-label>
        <input
          id="masterPassword"
          [type]="showPassword ? 'text' : 'password'"
          bitInput
          name="MasterPassword"
          class="tw-w-full"
          [(ngModel)]="masterPassword"
          appAutofocus
          appInputVerbatim
        />
        <button
          type="button"
          bitSuffix
          bitPasswordInputToggle
          [(toggled)]="showPassword"
          class="tw-h-full tw-bg-transparent"
        >
          <svg class="tw-h-6 tw-w-6 tw-opacity-60">
            <use
              [attr.xlink:href]="
                showPassword ? 'images/sprite.svg#eye' : 'images/sprite.svg#eye-off'
              "
            ></use>
          </svg>
        </button>
      </bit-form-field>

      <small class="tw-mt-2">{{ "loggedInAsEmailOn" | i18n: email : webVaultHostname }}</small>
      <hr />
      <div class="tw-flex tw-gap-2 xs:tw-flex-col">
        <app-gloss-button class="button-resize" [options]="unlockButtonOptions"></app-gloss-button>
        <app-gloss-button class="button-resize" [options]="logOutButtonOptions"></app-gloss-button>
      </div>
    </div>
  </div>
</form>

import { Classification } from "../../../../models/data/blobby/classification.data";
import { ClassificationStoreModel } from "../../../../models/store/classification.store.model";

export const classification_v0_to_v1 = (
  classificationItem: Classification,
): ClassificationStoreModel => {
  return {
    dc: new Date().toString(),
    dm: new Date().toString(),
    gendef: classificationItem.generalDefault,
    id: classificationItem.id,
    n: classificationItem.name,
    v: 1,
    vid: "",
    w: classificationItem.weight,
  };
};

import { Injector } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { HelperNumericInput } from "@bitwarden/web-vault/app/shared/utils/helper-numeric-input";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import {
  AllowedLocations,
  CurrencyType,
  DefaultCurrencyPerLocation,
  UserLocationType,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";
import { isOfType } from "@bitwarden/web-vault/app/models/types/general-types";

export abstract class BaseForm {
  private userStore: UserStoreService;
  protected log: LogService;
  protected globalService: GlobalService;

  formGroup: FormGroup;
  formBuilder: FormBuilder;

  i18nService: I18nService;

  title: string;
  loading = false;
  currency: CurrencyType;
  dateFormat = new DateFormat();

  protected constructor(injector: Injector) {
    this.userStore = injector.get(UserStoreService);
    this.globalService = injector.get(GlobalService);
    this.i18nService = injector.get(I18nService);
    this.formBuilder = injector.get(FormBuilder);
    this.log = injector.get(LogService);
  }

  selectCurrency(selectedCurrency: string) {
    this.formGroup.controls.currency.setValue(selectedCurrency);
  }

  onInputNumeric(event: Event, controlName: string) {
    HelperNumericInput.onInput(event, this.formGroup.controls[controlName]);
    const target = event.target as HTMLInputElement;
    this.formGroup.get(controlName)?.setValue(target.value);
  }

  onInput(event: Event, controlName: string) {
    const target = event.target as HTMLInputElement;
    this.formGroup.get(controlName)?.setValue(target.value);
  }

  clearDefaultValue(controlName: string) {
    HelperNumericInput.clearDefaultValue(this.formGroup.controls[controlName]);
  }

  onDateChange(date: string) {
    this.formGroup.controls.date.setValue(date);
    this.formGroup.controls.date.markAsTouched();
  }

  protected getCurrencyFromLocation() {
    const userLocation = this.userStore.preferences.preferenceView().userLocation;

    if (isOfType<UserLocationType>(userLocation, AllowedLocations)) {
      return DefaultCurrencyPerLocation[userLocation];
    } else {
      return this.userStore.preferences.preferenceView().baseCurrency;
    }
  }
}

import { Injectable, Injector } from "@angular/core";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { devFlagEnabled } from "@bitwarden/common/misc/flags";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";

@Injectable({
  providedIn: "root",
})
export class HelperCommon {
  static RUN_VALUATION = false;

  protected transactionService: TransactionService;
  protected apiService: ApiService;
  protected log: LogService;
  constructor(injector: Injector) {
    this.transactionService = injector.get(TransactionService);
    this.apiService = injector.get(ApiService);
    this.log = injector.get(LogService);
  }

  /**
   * @deprecated - this is a legacy method that will be replace by RBAC
   */
  static isDevEnv() {
    return devFlagEnabled("development");
  }

  static isLocal(): boolean {
    return process.env.IS_LOCAL === "true";
  }
}

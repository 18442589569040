<div class="content">
  <div class="tw-flex tw-justify-end tw-mb-5">
    <app-gloss-button
      class="tw-w-44 sm:tw-w-full"
      [options]="{
        text: 'addSymbol' | i18n,
        class: 'neutral',
        isEnabled: true,
        icon: 'add',
        onClick: handleAddSymbolClick.bind(this),
      }"
    ></app-gloss-button>
  </div>

  <div class="tw-flex tw-justify-between tw-w-full">
    <div
      [ngClass]="{
        'tw-w-[49.5%] tw-overflow-hidden tw-rounded-lg tw-border tw-border-solid tw-border-neutral-100 tw-h-screen tw-bg-white tw-shadow-lg':
          !isMobile(),
        'tw-w-full tw-overflow-hidden tw-rounded-2xl': isMobile(),
      }"
    >
      <app-ag-grid-table
        [options]="tableSymbol?.options"
        [data]="tableSymbol?.data"
        [removeBottomBorder]="true"
        [removeTopBorder]="true"
      ></app-ag-grid-table>
    </div>

    @let selectedSymbol = this.tableSymbol.selectedSymbol();
    @if (selectedSymbol) {
      <div
        [ngClass]="{
          'details-section-desktop': !isMobile(),
          'details-section-mobile': isMobile(),
        }"
        class="tw-flex tw-flex-col tw-h-screen"
      >
        <app-symbol-details [symbol]="selectedSymbol"></app-symbol-details>
        <!--        <ng-container *ngTemplateOutlet="symbolHistory"></ng-container>-->
        <app-gloss-button
          class="tw-flex tw-w-1/2 tw-mt-auto"
          [options]="{
            text: 'deleteSymbol' | i18n,
            class: 'danger',
            isEnabled: true,
            onClick: handleSaveClick.bind(this),
          }"
        ></app-gloss-button>
      </div>
    }
  </div>
</div>

<!--<ng-template #symbolHistory>-->
<!--  <div class="tw-flex tw-items-center tw-bg-white tw-rounded-lg tw-gap-2">-->
<!--    <div class="bordered-icon">-->
<!--      <icon [name]="'clock'" [size]="8"></icon>-->
<!--    </div>-->
<!--    <span class="tw-uppercase tw-text-lg tw-text-neutral-600 tw-uppercase">{{-->
<!--        "symbolHistory" | i18n-->
<!--      }}</span>-->
<!--  </div>-->
<!--</ng-template>-->

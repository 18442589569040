import { ModelView } from "../model.view";
import { GlossDateAsObjectType } from "../../store/types/store.types";
import {
  DefaultScenarioGroupStoreModel,
  ScenarioGroupModelVersion,
  ScenarioGroupStoreModel,
} from "../../store/scenario-group.store.model";

export class ScenarioGroupView extends ModelView<ScenarioGroupStoreModel> {
  protected version: ScenarioGroupModelVersion;

  name: string;
  symbol: string;
  anchorPoint: GlossDateAsObjectType;
  endDate: GlossDateAsObjectType;
  scenariosId: string[];
  userDefinedEstimatesId: string[];
  source: string;

  constructor(model?: ScenarioGroupStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.name = model.nm;
      this.symbol = model.sym;
      this.anchorPoint = model.anP;
      this.endDate = model.eD;
      this.scenariosId = model.scsId;
      this.userDefinedEstimatesId = model.estId;
      this.source = model.source;
    } else {
      this.version = DefaultScenarioGroupStoreModel.v;
    }
  }

  toStoreModel(): ScenarioGroupStoreModel {
    return {
      dc: this.dateCreated.toDateString(),
      dm: this.dateModified.toDateString(),
      id: this.id,
      v: this.version,
      vid: this.vaultId,
      nm: this.name,
      sym: this.symbol,
      anP: this.anchorPoint,
      eD: this.endDate,
      scsId: this.scenariosId,
      estId: this.userDefinedEstimatesId,
      source: this.source,
    };
  }

  clone(): ScenarioGroupView {
    return new ScenarioGroupView(this.toStoreModel());
  }
}

import { Pipe, PipeTransform } from "@angular/core";

import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { MonthlyOption, YearlyOption } from "@bitwarden/web-vault/app/models/enum/estimateType";

@Pipe({
  name: "estimatePipe",
})
export class EstimatePipe implements PipeTransform {
  async transform(value: any, method: string, args?: any[]) {
    if (method === "getEstimateFreqAndPeriod") {
      return this.getEstimateFreqAndPeriod(value);
    }

    if (method === "getOptionForMonth") {
      return this.getOptionForMonth(value);
    }
    if (method === "getOptionForYear") {
      return this.getOptionForYear(value);
    }
    return value;
  }

  async getEstimateFreqAndPeriod(estimate: Estimate): Promise<string> {
    if (estimate.frequency && estimate.period) {
      return `${estimate.frequency} / ${estimate.period}`;
    } else {
      return "non-recurring";
    }
  }

  getSuffix(value: number) {
    const lastDigit = value % 10;
    return lastDigit === 1 && value !== 11
      ? "st"
      : lastDigit === 2 && value !== 12
        ? "nd"
        : lastDigit === 3 && value !== 13
          ? "rd"
          : "th";
  }
  getMonthlyDateSpecificOption(option: MonthlyOption) {
    const value = option.dateSpecific.date;
    const suffix = this.getSuffix(value);
    return `${value + suffix} of the month`;
  }

  getMonthlyDegreeSpecificDays(option: MonthlyOption) {
    const degree = option.degreeSpecific.degree;
    const days = {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
    };

    const dayDegree = typeof degree === "number" ? degree + this.getSuffix(degree) : "Last";

    return `${dayDegree} ${days[option.degreeSpecific.day]} of the month`;
  }

  getMonthlyLastDayDegree() {
    return "Last day";
  }
  async getOptionForMonth(option: MonthlyOption): Promise<string> {
    if (option === undefined || option === null) {
      return "";
    }

    if (option.dateSpecific?.date) {
      return this.getMonthlyDateSpecificOption(option);
    } else if (option.degreeSpecific?.lastDayOfMonth) {
      return this.getMonthlyLastDayDegree();
    } else if (
      (option.degreeSpecific?.day || option.degreeSpecific?.day === 0) &&
      option.degreeSpecific?.degree
    ) {
      return this.getMonthlyDegreeSpecificDays(option);
    }
  }

  async getOptionForYear(option: YearlyOption): Promise<string> {
    if (option === undefined || option === null) {
      return "";
    }

    const months = {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "Jun",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December",
    };

    const monthlyOption = await this.getOptionForMonth(option);

    return `${monthlyOption} of ${months[option.month]}`.replace(" of the month", "");
  }
}

import { WorkerMessage } from "@bitwarden/web-vault/web-worker/worker.message";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";

export class BalanceAlignmentWorkerMessage implements WorkerMessage {
  id: string;
  workerType = "BalanceAlignmentWorker";
  workerIndex: number;
  transactions: TransactionView[];

  constructor(id: string, transactions?: TransactionView[]) {
    this.id = id;
    this.transactions = transactions;
  }
}

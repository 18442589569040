import { ExternalView } from "@bitwarden/web-vault/app/models/view/external.view";

export class ForexRateView extends ExternalView {
  __v: number;
  glossDate: {
    date: string; //yyyy-mm-dd
    time: string | null;
    tz: string | null;
  };
  base: string;
  symbols: Record<string, number>;

  constructor(apiResponse: any) {
    super();

    if (typeof apiResponse === "object") {
      this.__v = apiResponse["__v"] ?? -1;
      this.glossDate = apiResponse["glossDate"];
      this.base = apiResponse["base"];
      this.symbols = apiResponse["symbols"];
    }
  }
}

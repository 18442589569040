import * as d3 from "d3";

import { Arrows } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/arrows";
import { DataPoints } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/data-points";
import { DateTimeFormatter } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/date-time-formatter";
import { Slider } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/slider";
import {
  GraphDataSet,
  GraphColourOptions,
} from "@bitwarden/web-vault/app/models/types/graph.types";
import {
  AnchorPointData,
  GroupScenarioBalance,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";

export class DataLine {
  private graphContentGroup: any;
  private dateTimeFormatter: DateTimeFormatter;
  private xScale: d3.ScaleBand<string>;
  private yScale: d3.ScaleLinear<number, number, never>;
  private slider: Slider;
  private colourOptions = GraphColourOptions;

  constructor(
    graphContentGroup: any,
    dateTimeFormatter: DateTimeFormatter,
    xScale: d3.ScaleBand<string>,
    yScale: d3.ScaleLinear<number, number, never>,
    slider: Slider,
  ) {
    this.graphContentGroup = graphContentGroup;
    this.dateTimeFormatter = dateTimeFormatter;
    this.xScale = xScale;
    this.yScale = yScale;
    this.slider = slider;
  }

  /**
   * updateBalanceLineGraph - Given a set of graph data points, it will create a set of lines between the
   * data points that are a shortened and have a black arrow on the end. Currently used to plot the balance line
   * on the graph.
   * @param graphData
   */
  updateBalanceLineGraph(graphData: Array<GraphDataSet>) {
    const graphLine: GraphDataSet[] = [...graphData];
    const lineData = this.generateLineData(graphLine, (d) => d.balance);
    this.updateLine("balanceLine", lineData, 3, "black", true, 1, "blackArrow");
    return lineData;
  }

  /**
   * updateScenarioLines - Given an array of graph data points, it will create a line for each set using an index
   * to determine colour
   * on the graph.
   * @param graphData
   */
  updateScenarioLines(
    graphData: Array<GraphDataSet>,
    index: number,
    arrowsClass: Arrows,
    dataPointClass: DataPoints,
  ) {
    index = index % (this.colourOptions.length - 1);
    const colour = this.colourOptions[index];
    // create the arrow needed for this scenario
    arrowsClass.createArrowMarker(colour + "Arrow", colour);

    // add in the data markers
    const dataPoints = dataPointClass.generatePointData(graphData);
    dataPointClass.updatePoints("scenarioPoints_" + index, colour, dataPoints, 1);

    const graphLine: GraphDataSet[] = [...graphData];
    const lineData = this.generateLineData(graphLine, (d) => d.balance);
    this.updateLine("scenarioLine_" + index, lineData, 3, colour, true, 1, colour + "Arrow", "3,3");
    return lineData;
  }

  removeScenarioLines(scenarioCount: number) {
    for (let i = 0; i <= scenarioCount; i++) {
      this.graphContentGroup.selectAll("#scenarioPoints_" + i).remove();
      this.graphContentGroup.selectAll("#scenarioLine_" + i).remove();
    }
    this.removeAnchorPoint();
  }

  removeAnchorPoint() {
    this.graphContentGroup.selectAll("#anchorPoint").remove();
    this.graphContentGroup.selectAll("#prevBalancePoint").remove();
    this.graphContentGroup.selectAll("#prevBalanceLine").remove();
  }

  plotAnchorPoint(anchorPoint: AnchorPointData, scenarioData: GroupScenarioBalance[]) {
    this.removeAnchorPoint();

    // get the band that the anchor point is in
    const anchorDate = new Date(anchorPoint.anchorDate);

    const anchorBand = this.slider.findBand(anchorDate);
    const x = this.slider.calculateMiddleX(anchorBand, anchorDate);

    if (x) {
      const bandStartDate = anchorBand.date;
      const y = this.yScale(anchorPoint.anchorBalance);
      this.graphContentGroup
        .selectAll("#anchorPoint")
        .data([[x, y]])
        .join("circle")
        .attr("id", "anchorPoint")
        .attr("cx", (d: number[]) => d[0])
        .attr("cy", (d: number[]) => d[1])
        .attr("r", 3)
        .attr("fill", "#FF792E");

      // Draw the scenario lines from anchor point to first scenario points
      for (let i = 0; i < scenarioData.length; i++) {
        const scenario = scenarioData[i];
        const balance = scenario.graphData.find((data) => {
          return data.date == bandStartDate;
        });

        if (balance) {
          const index = i % (this.colourOptions.length - 1);
          const colour = this.colourOptions[index];
          const x2 =
            this.xScale.bandwidth() / 2 +
            this.xScale(
              this.dateTimeFormatter.formatYearDate(
                this.dateTimeFormatter.timeParse(bandStartDate),
              ),
            );
          const y2 = this.yScale(balance.balance);
          const lineData = this.shortenLine([x, y, x2, y2]);

          this.addLine("scenarioLine_" + i, lineData, colour, colour + "Arrow", "3,3");
        }
      }
      return [x, y]; // return the anchor point to be used in plotPrevBandBalance
    }
  }

  plotPrevBandBalance(prevBalance: number, date: Date, anchorPoint: number[]) {
    const [x, y] = anchorPoint;
    const x1 =
      this.xScale.bandwidth() / 2 + this.xScale(this.dateTimeFormatter.formatYearDate(date));
    const y1 = this.yScale(prevBalance);
    this.graphContentGroup
      .selectAll("#prevBalancePoint")
      .data([[x1, y1]])
      .join("circle")
      .attr("id", "prevBalancePoint")
      .attr("cx", (d: number[]) => d[0])
      .attr("cy", (d: number[]) => d[1])
      .attr("r", 3)
      .attr("stroke", "black")
      .style("stroke-width", "0.5px")
      .attr("fill", "white");

    // Draw the line from the previous balance point to the red anchor point
    const lineData = this.shortenLine([x1, y1, x, y]);

    this.addLine("prevBalanceLine", lineData, "black", "blackArrow");
  }

  createMarkerLine(innerHeight: number) {
    return this.updateLine(
      "markerLine",
      [[0, 0, 0, innerHeight]],
      3,
      "#777",
      false,
      0.4,
      null,
      "6, 6",
      2,
    );
  }

  moveMarkerLine(xPosition: number) {
    this.graphContentGroup.selectAll("#markerLine").attr("x1", xPosition).attr("x2", xPosition);
  }

  private generateLineData(
    data: GraphDataSet[],
    yAccessor: (d: GraphDataSet) => number,
  ): number[][] {
    const lineData = [];
    for (let i = 1; i < data.length; i++) {
      const prevData = data[i - 1];
      const d = data[i];
      let x1;
      if (!prevData?.midDate) {
        x1 =
          this.xScale.bandwidth() / 2 +
          this.xScale(
            this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(prevData.date)),
          );
      } else {
        const bandData = this.slider.findBand(prevData.midDate);
        x1 = this.slider.calculateMiddleX(bandData, prevData.midDate);
      }
      const yBalance1 = yAccessor(prevData);
      const yBalance2 = yAccessor(d);
      if (yBalance1 === null || yBalance2 === null) {
        continue;
      }
      const y1 = this.yScale(yBalance1);
      let x2;
      if (!d?.midDate) {
        x2 =
          this.xScale.bandwidth() / 2 +
          this.xScale(
            this.dateTimeFormatter.formatYearDate(this.dateTimeFormatter.timeParse(d.date)),
          );
      } else {
        const bandData = this.slider.findBand(d.midDate);
        x2 = this.slider.calculateMiddleX(bandData, d.midDate);
      }
      const y2 = this.yScale(yBalance2);

      const shortenedLine = this.shortenLine([x1, y1, x2, y2]);

      if (shortenedLine) {
        lineData.push(shortenedLine);
      }
    }
    return lineData;
  }

  updateLine(
    name: string,
    data: number[][],
    y2Index: number,
    stroke: string,
    isAlwaysVisible: boolean,
    opacity: number,
    markerId?: string,
    dashArray?: string,
    strokeWidth?: number,
  ) {
    const line = this.graphContentGroup.selectAll(`#${name}`).data(data);

    return line
      .join("line")
      .attr("class", name)
      .attr("id", name)
      .attr("x1", (d: number[]) => d[0])
      .attr("y1", (d: number[]) => d[1])
      .attr("x2", (d: number[]) => d[2])
      .attr("y2", (d: number[]) => d[y2Index])
      .attr("stroke", stroke)
      .style("stroke-width", strokeWidth ? strokeWidth : "0.5px")
      .attr("opacity", (d: number[]) =>
        isAlwaysVisible ? opacity : d[y2Index] === d[1] ? 0 : opacity,
      )
      .attr("marker-end", markerId ? `url(#${markerId})` : null)
      .style("stroke-dasharray", dashArray || null);
  }

  addLine(
    name: string,
    points: number[],
    stroke: string,
    markerId?: string,
    dashArray?: string,
    strokeWidth?: number,
  ) {
    return this.graphContentGroup
      .append("line")
      .attr("class", name)
      .attr("id", name)
      .attr("x1", points[0])
      .attr("y1", points[1])
      .attr("x2", points[2])
      .attr("y2", points[3])
      .attr("stroke", stroke)
      .style("stroke-width", strokeWidth ? strokeWidth : "0.5px")
      .attr("marker-end", markerId ? `url(#${markerId})` : null)
      .style("stroke-dasharray", dashArray || null);
  }

  /**
   * Given two points, shorten the line by a number of co-ordinates so that the new line does not
   * touch the two edge points. A gap should be left at either enf of the points unless the line is too
   * short for a gap to be left
   * @param points
   * @private
   */
  private shortenLine(points: Array<number>): Array<number> {
    // how many co-ordinates to reduce the ends of the original line by
    const pixelReduction = 6;

    // work out the length of the original line
    const lineLength = Math.sqrt(
      Math.pow(points[2] - points[0], 2) + Math.pow(points[3] - points[1], 2),
    );

    if (lineLength === 0) {
      return;
    }
    // this is the mathematical formula to work out the new starting point of the line based on the pixel reduction we want
    const startPointX = points[0] + (pixelReduction / lineLength) * (points[2] - points[0]);
    const startPointY = points[1] + (pixelReduction / lineLength) * (points[3] - points[1]);

    // this is the mathematical formula to work out the new end point of the line based on the pixel reduction we want
    const endPointX =
      points[0] + ((lineLength - pixelReduction) / lineLength) * (points[2] - points[0]);
    const endPointY =
      points[1] + ((lineLength - pixelReduction) / lineLength) * (points[3] - points[1]);

    // return the new points to draw the line between to produce a gap at either end
    const newPoints = [startPointX, startPointY, endPointX, endPointY];

    return newPoints;
  }

  getColourOptions(): Array<string> {
    return this.colourOptions;
  }
}

import { Injectable, Injector } from "@angular/core";

import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { EstimateActionResponse } from "@bitwarden/web-vault/app/models/data/response/estimate-action.response";
import { Splitcategory } from "@bitwarden/web-vault/app/models/data/splitcategory";
import { Splitclassification } from "@bitwarden/web-vault/app/models/data/splitclassification";

import { EstimateActionData } from "../../../models/data/blobby/estimate.action.data";
import { Estimate } from "../../../models/data/blobby/estimate.data";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";

@Injectable({
  providedIn: "root",
})
export class EstimateService {
  constructor(
    private dataRepositoryService: DataRepositoryService,
    private injector: Injector,
  ) {}

  /**
   * @deprecated
   */
  async getMergeEstimateGroups() {
    const estimateGroups = await this.dataRepositoryService.getAllEstimateGroups();
    return this.mergeEstimateGroupsData(estimateGroups);
  }

  /**
   * @deprecated
   */
  async getAllEstimates() {
    return await this.dataRepositoryService.getAllEstimates();
  }

  /**
   * @deprecated
   */
  async create(estimate: Estimate): Promise<Estimate> {
    return this.dataRepositoryService.createEstimate(estimate);
  }

  /**
   * @deprecated
   */
  async update(estimate: Estimate): Promise<Estimate> {
    return await this.dataRepositoryService.updateEstimate(estimate);
  }

  /**
   * @deprecated
   */
  async delete(estimate: Estimate): Promise<boolean> {
    return await this.dataRepositoryService.deleteEstimate(estimate);
  }

  /**
   * @deprecated
   */
  async bulkDelete(estimates: Estimate[]): Promise<boolean> {
    const noDeletedEstimates: Estimate[] = [];
    for (const estimate of estimates) {
      const isDeleted = await this.dataRepositoryService.deleteEstimate(estimate);
      if (!isDeleted) {
        noDeletedEstimates.push(estimate);
      }
    }

    return noDeletedEstimates.length === 0;
  }

  private mergeEstimateGroupsData(estimateGroups: Array<Estimate>): any {
    if (estimateGroups.length === 0) {
      return [];
    }

    const estimateData: EstimateActionData[] = [];
    /** Merge the nested estimate groups */
    for (const groups of estimateGroups) {
      for (const estimate of groups.estimateActions) {
        const actionResponse = new EstimateActionResponse(estimate);
        const estimateModel = new EstimateActionData(actionResponse);
        estimateData.push(estimateModel);
      }
    }
    return estimateData;
  }

  getCategoriesFromEstimateGroup(groupCategories: Splitcategory[]) {
    const categories: Category[] = [];
    for (const group of groupCategories) {
      categories.push(group.category);
    }
    return categories;
  }

  getClassificationsFromEstimateGroup(groupClassifications: Splitclassification[]) {
    const classifications: Classification[] = [];
    for (const group of groupClassifications) {
      classifications.push(group.classification);
    }
    return classifications;
  }
}

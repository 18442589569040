import { StoreSubscriber } from "../dependency.subscriber.abstraction";
import { UserStoreService } from "../user/user.store.service";
import { inject } from "@angular/core";
import { PreferenceView } from "../../../models/view/preference/preference.view";

export class PreferenceSubscriber extends StoreSubscriber<PreferenceView> {
  protected dependentStore: UserStoreService = inject(UserStoreService);

  getCollection() {
    return this.dependentStore.preferences.preferenceView();
  }

  getObservable() {
    return this.dependentStore.preferences.publisher$;
  }
}

import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-symbol-code-renderer",
  templateUrl: "./symbol-code-renderer.component.html",
})
export class SymbolCodeRendererComponent implements ICellRendererAngularComp {
  code!: string;

  agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  setData(params: ICellRendererParams) {
    this.code = params.data.code || "Unavailable code";
  }

  refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }
}

import { StateControlAbstraction } from "../../state-control.abstraction";
import { signal } from "@angular/core";
import { TransactionView } from "../../../../models/view/transaction/transaction.view";
import { toObservable } from "@angular/core/rxjs-interop";
import { filter } from "rxjs";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";

export interface FilterControlType {
  countries: string[];
  accounts: string[];
  institutions: string[];
  symbols: string[];
}

const initialFilter: FilterControlType = {
  countries: [],
  accounts: [],
  institutions: [],
  symbols: [],
};

export class FilterControl extends StateControlAbstraction {
  protected initialOptions: FilterControlType = initialFilter;
  protected selectionToApply: FilterControlType = initialFilter;

  /** Public signal for component to listen too **/
  private _options = signal<FilterControlType>(null);
  options = this._options.asReadonly();

  private _selected = signal<FilterControlType>(null);
  selected = this._selected.asReadonly();

  clear() {
    this._options.set(null);
    this._selected.set(null);
    this.initialOptions = null;
  }

  /** **<span style="color: red;">This is for store use only</span>** **/
  readonly selected$ = toObservable(this.selected).pipe(
    filter((x) => x !== null || typeof x !== "undefined"),
  );

  /** On the new transaction load initialise the options for all the transactions. This is the unfiltered list from the transactionStore **/
  initialiseOptions(transactions: TransactionView[]) {
    this._options.set(this.generateOptions(transactions));
    this.initialOptions = { ...this.options() }; // keep a copy for when we reset
  }

  /** Updating control selection **/
  updateSelection(selection: FilterControlType) {
    if (this.selected() === null) {
      this._selected.set({ ...this.options() });
    } else {
      this._selected.set({ ...selection });
    }
  }

  clearFilter() {
    this.updateSelection(initialFilter);
    this._options.set({ ...this.initialOptions });
    this._selected.set({ ...this.options() });
  }

  private generateOptions(transactions: TransactionView[]) {
    const accounts = transactions.reduce((acc: string[], t) => {
      if (!acc.includes(t.accountLink.id)) {
        acc.push(t.accountLink.id);
      }
      return acc;
    }, []);

    const institutions = transactions.reduce((acc: string[], t) => {
      if (!acc.includes(t.c_institutionLink.id)) {
        acc.push(t.c_institutionLink.id);
      }
      return acc;
    }, []);

    const countries = transactions.reduce((acc: string[], t) => {
      if (!acc.includes(t.c_country.id)) {
        acc.push(t.c_country.id);
      }
      return acc;
    }, []);

    const symbols = transactions.reduce((syms: string[], t) => {
      if (!syms.includes(t.quantity.actualQuantity.symbol)) {
        syms.push(t.quantity.actualQuantity.symbol);
      }
      return syms;
    }, []);

    // Leave the already selected
    return {
      accounts,
      institutions,
      countries,
      symbols,
    };
  }

  applyFiltersOnTransaction(collection: readonly TransactionView[]) {
    return collection.filter((transactionView) => {
      return (
        this.selected()?.accounts.includes(transactionView.accountLink.id ?? "") &&
        this.selected()?.institutions.includes(transactionView.c_institutionLink.id ?? "") &&
        this.selected()?.countries.includes(transactionView.c_country.id ?? "") &&
        this.selected()?.symbols.includes(transactionView.quantity.actualQuantity.symbol || "")
      );
    });
  }

  // @alex todo implement for country too
  applyFiltersOnAccounts(collection: readonly AccountView[]) {
    return collection.filter((accountView) => {
      return (
        this.selected()?.accounts.includes(accountView.id ?? "") &&
        this.selected()?.institutions.includes(accountView.institutionLink.institutionId ?? "")
      );
    });
  }
}

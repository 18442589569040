import { StoreModelNames } from "../../dali/type/dali.type";
import { signal } from "@angular/core";
import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { SourceCategoryStoreModel } from "../../../models/store/source-category.store.model";
import { SourceCategoryView } from "@bitwarden/web-vault/app/models/view/source-category/source-category.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class SourceCategoryStoreCollection extends StoreModelCollection<
  SourceCategoryStoreModel,
  ModelView<SourceCategoryStoreModel>
> {
  readonly type: StoreModelNames = "SourceCategoryStoreModel";
  protected models: Map<string, SourceCategoryStoreModel> = new Map<
    string,
    SourceCategoryStoreModel
  >();

  private _sourceCategoryViews = signal<SourceCategoryView[]>(null);
  readonly sourceCategoryViews = this._sourceCategoryViews.asReadonly();

  triggerChanges(): void {
    const views: SourceCategoryView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new SourceCategoryView(model)) as SourceCategoryView);
    });
    this._sourceCategoryViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._sourceCategoryViews.set(null);
  }
}

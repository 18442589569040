<div
  #menuDiv
  class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-10 tw-grid tw-h-auto tw-w-full tw-grid-cols-4 tw-items-center tw-gap-2 tw-bg-white tw-px-2 tw-pb-[4%] tw-pt-2"
>
  <ng-container *ngFor="let item of menuItems; let i = index">
    <nav
      *ngIf="has5Items && i === 3"
      class="tw-flex tw-h-full tw-min-h-[60px] tw-flex-col tw-items-center tw-justify-center tw-rounded-xl tw-p-1
        {{ isMobileExpanded ? 'tw-shadow-inner' : 'tw-shadow-button tw-bg-neutral-50' }}"
      (click)="expandMenu()"
    >
      <svg class="tw-h-7 tw-w-7" [ngClass]="{ 'tw-stroke-primary': selectedKey !== item.key }">
        <use
          [attr.xlink:href]="
            'images/sprite.svg#more-' + (isMobileExpanded ? 'expanded' : 'not-expanded')
          "
        />
      </svg>
      <span class="tw-font-medium">{{ "more" | i18n }}</span>
    </nav>

    <nav
      *ngIf="!isMobileExpanded ? i < 3 : true"
      class="tw-flex tw-h-full tw-min-h-[60px] tw-flex-col tw-items-center tw-justify-center tw-rounded-xl tw-p-1 hover:tw-bg-neutral-55"
      [routerLink]="item.routerLink"
      (click)="selectKey(item.key)"
      [routerLinkActive]="'tw-no-underline'"
    >
      <svg
        class="tw-h-7 tw-w-7 tw-text-primary"
        [ngClass]="{ 'tw-stroke-primary': selectedKey !== item.key }"
      >
        <use [attr.xlink:href]="'images/sprite.svg#' + getSelectedIcon(item)" />
      </svg>
      <span class="tw-font-medium">{{ item.label | i18n }}</span>
    </nav>
  </ng-container>
</div>

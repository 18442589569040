<div class="tw-w-full tw-min-w-[300px]">
  <div
    class="tw-flex tw-rounded-full tw-w-full tw-h-full tw-bg-neutral-50 tw-shadow-button-new tw-justify-between tw-items-center tw-p-2 tw-gap-4 tb:tw-flex-col tb:tw-gap-2 tb:tw-rounded-3xl"
  >
    <!--    datepicker-->
    @if (currentView !== ViewEnum.TODAY) {
      <div class="tw-w-full tw-h-10">
        <app-views-datepicker></app-views-datepicker>
      </div>
    } @else {
      <!--    input field -->
      <div class="tw-flex tw-w-full tw-h-9">
        <div
          class="tw-w-full tw-h-full tw-bg-white tw-rounded-full tw-flex tw-gap-2 tw-whitespace-nowrap tw-items-center tw-px-3"
        >
          <span>{{ currentDate }}</span>
        </div>
        <icon name="calendar" [size]="6" [currentColor]="'neutral-300'" class="tw-px-3"></icon>
      </div>
    }
    <!--    filter-->
    <div class="tw-w-full tw-h-10">
      <app-views-customize></app-views-customize>
    </div>
  </div>
</div>

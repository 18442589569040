import { Component, Inject, Injector, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { GlossInputComponent } from "@bitwarden/web-vault/app/components/inputs/gloss-input/gloss-input.component";
import { BalanceForm } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/account-balance/balance-form";
import { TransactionData } from "@bitwarden/web-vault/app/models/types/transaction.types";

@Component({
  selector: "app-account-balance",
  templateUrl: "./account-balance.component.html",
})
export class AccountBalanceComponent {
  balanceForm: BalanceForm;
  data: TransactionData;
  constructor(
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    data: TransactionData,
  ) {
    this.data = data;
    this.balanceForm = new BalanceForm(this.injector, this.data, this.submit.bind(this));
  }

  // TODO: before gloss-input component inline validation fixed, we need to manipulate each gloss-input component instance
  @ViewChild("balanceInput") balanceInput!: GlossInputComponent;

  submit() {
    this.balanceInput.inputValueControl.markAsTouched();
    this.balanceInput.onInputBlur();
    this.balanceForm.submit().then();
  }
}

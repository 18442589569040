import {
  ExternalCollection,
  FetchParams,
} from "@bitwarden/web-vault/app/services/store/external.collection.abstraction";
import { ForexRateView } from "@bitwarden/web-vault/app/models/view/reference-data/forex-rate.view";
import { inject, signal } from "@angular/core";
import { format } from "date-fns";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { MarketDataConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

interface FetchCurrencyParams extends FetchParams {
  baseCurrency: SymbolView;
  currencies: SymbolView[];
  startDate: Date;
  endDate: Date;
}

const MARKET_DATA_CONFIG = process.env.MARKETDATA as MarketDataConfigType;
const API_DATE_FORMAT = "yyyy-MM-dd";

export class ExternalForexRatesStoreCollection extends ExternalCollection {
  protected log: LogService = inject(LogService);
  protected appState = inject(AppStateStoreService);
  protected dataRepositoryService: DataRepositoryService = inject(DataRepositoryService);

  /** When pulling currency we also need to know the transaction period interval **/

  private _forexRates = signal<ForexRateView[]>(null);
  readonly forexRates = this._forexRates.asReadonly();

  clear() {
    this._forexRates.set(null);
  }

  private validateParams(params: FetchCurrencyParams) {
    /** Check params validity **/
    if (
      typeof params?.baseCurrency?.code === "undefined" ||
      typeof params?.currencies === "undefined" ||
      typeof params?.startDate === "undefined" ||
      typeof params?.endDate === "undefined"
    ) {
      return false;
    } else {
      return true;
    }
  }

  async fetch(params: FetchCurrencyParams): Promise<ForexRateView[]> {
    const forexRates: ForexRateView[] = [];

    if (!this.validateParams(params)) {
      return forexRates;
    }

    /** Remove self cross rate e.g AUD to AUD, and validate that there are some rates to fetch  **/
    const symbols = params.currencies
      .filter((s) => s.code !== params.baseCurrency.code)
      .map((s) => s.code)
      .join(",");
    if (symbols === "") {
      return forexRates;
    }

    const path = `?base=${params.baseCurrency.code}&startDate=${format(params.startDate, API_DATE_FORMAT)}&endDate=${format(params.endDate, API_DATE_FORMAT)}&symbols=${symbols}`;

    this.appState.startProcess("fetching-forex", "Fetching Currency Rates...");
    const instrumentMarketData = await this.dataRepositoryService.send(
      "GET",
      path,
      null,
      true,
      true,
      `${MARKET_DATA_CONFIG.url}/${MARKET_DATA_CONFIG.apiStage}${MARKET_DATA_CONFIG.endpoint?.currency}`,
    );

    if (Array.isArray(instrumentMarketData?.referenceData)) {
      instrumentMarketData.referenceData.forEach((item: ForexRateView) => {
        forexRates.push(this.mapToView(item));
      });
    }

    this.appState.endProcess("fetching-forex");

    this._forexRates.set(forexRates);
    return forexRates;
  }

  protected mapToView(data: any): ForexRateView {
    return new ForexRateView(data);
  }
  triggerChanges(): void {}
}

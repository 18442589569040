import { StoreData } from "./store.data";

export type WizardModelVersion = 1;
export interface WizardStoreModel extends StoreData {
  /**  **Version Number** **/
  v: WizardModelVersion;

  /** Step Information */
  st: wizardStep;

  /** Type of the wizard **/
  ty: string;
}

export interface wizardStep {
  stepidx: number;
  iscomp: boolean;
}

export const DefaultWizardStoreModel: WizardStoreModel = {
  dc: new Date().toISOString(),
  dm: new Date().toISOString(),
  id: "",
  st: { stepidx: 1, iscomp: false },
  ty: "account",
  v: 1,
  vid: "",
};

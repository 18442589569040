import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";

import { NavItemsMobile, GlossRoutes } from "../../../models/enum/routes.enum";
import { HelperCommon } from "../../../shared/utils/helper-common";
import { Menu } from "../menu";

@Component({
  selector: "app-menu",
  templateUrl: "./app-menu.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMenuComponent extends Menu implements AfterViewInit {
  @Output() openHelpBoxEvent = new EventEmitter<void>();
  @Output() menuHeightEvent = new EventEmitter<number>();

  @ViewChild("menuDiv") menuDiv!: ElementRef<HTMLDivElement>;

  protected readonly HelperCommon = HelperCommon;
  protected readonly process = process;
  protected readonly EventEmitter = EventEmitter;

  menuItems = NavItemsMobile;
  has5Items = this.menuItems.length > 4;
  selectedKey = GlossRoutes.Views.toString();
  isMobileExpanded = false;

  ngAfterViewInit() {
    if (this.menuDiv) {
      setTimeout(() => {
        const menuHeight = this.menuDiv.nativeElement.offsetHeight;
        this.menuHeightEvent.emit(menuHeight);
      });
    }
  }

  selectKey(key: string) {
    if (key === "help") {
      this.openHelpBoxEvent.emit();
    } else {
      this.selectedKey = key;
    }
  }

  expandMenu() {
    this.isMobileExpanded = !this.isMobileExpanded;
  }
}

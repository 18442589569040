import { StoreData } from "../../models/store/store.data";
import { BlobbyConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";
import { VaultStat } from "@bitwarden/web-vault/app/services/dali/vault.statistics";

export type BatchData = Array<Array<StoreData>>;

export class BatchingStrategy {
  private static instance: BatchingStrategy;

  // Private constructor to prevent direct instantiation
  private constructor() {}

  /**
   * Get the shared singleton instance of VaultAdapter.
   */
  static getInstance(): BatchingStrategy {
    if (!this.instance) {
      this.instance = new BatchingStrategy();
    }
    return this.instance;
  }

  static getBatches(models: StoreData[]): BatchData {
    let batches: BatchData;

    /** Prepare the batching **/
    if (models.length < 4) {
      batches = useLoopingStrategy(models);
    } else {
      batches = useBatchingStrategy(models);
    }

    return batches;
  }
}

export const useLoopingStrategy = (items: StoreData[]): BatchData => {
  return items.reduce((acc, item) => {
    acc.push([item]);
    return acc;
  }, []);
};

export const useBatchingStrategy = (items: StoreData[]): BatchData => {
  /* Bitwarden backend as a fix size of 10 000 for the notes chiffer. This can be defined server side. */
  const blobbyConfig = process.env.BLOBBY as unknown as BlobbyConfigType;
  const SIZE_LIMIT = blobbyConfig.max_char ?? 10000;

  /* SHA-256 Enc has a total string overhead of 305 ( from google search ). The whole notes size will be at a minimum of 305 char */
  const ENCRYPTION_OVERHEAD_RATIO = blobbyConfig.enc_ratio ?? 1.4;
  /* Each iem in the array will produce extra JSON string charater to join them "," and multiply by the total number of item. */
  const JSON_BYTE_OVERHEAD_PER_ITEM = 1;
  const JSON_BYTE_OVERHEAD_ARRAY = 5;

  const batches: BatchData = [];
  let currentTotalSize = 0;
  let currentBatch: Array<StoreData> = [];

  /* For all item  loop and create batches that are under the maximum db size */
  for (const item of items) {
    const itemSize = JSON.stringify(item).length;
    if (
      SIZE_LIMIT <
      (currentTotalSize +
        itemSize +
        JSON_BYTE_OVERHEAD_PER_ITEM * currentBatch.length +
        JSON_BYTE_OVERHEAD_ARRAY) *
        ENCRYPTION_OVERHEAD_RATIO
    ) {
      batches.push(currentBatch);
      currentBatch = [];
      currentTotalSize = 0;
    }
    currentTotalSize += itemSize;
    currentBatch.push(item);
  }
  batches.push(currentBatch);
  return batches;
};

import { ModelView } from "../model.view";
import {
  DefaultPreferenceStoreModel,
  PreferenceModelVersion,
  PreferenceStoreModel,
} from "@bitwarden/web-vault/app/models/store/preference.store.model";
import {
  CurrencyType,
  UserLocationType,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";
import { SyncStatusCollectionType } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { StringToNumberPreference } from "@bitwarden/web-vault/app/models/types/PrefereneceTypes";

export type PreferenceTypeKey = Exclude<
  keyof PreferenceView,
  keyof ModelView<PreferenceStoreModel>
>;

export class PreferenceView extends ModelView<PreferenceStoreModel> {
  protected version: PreferenceModelVersion;

  baseCurrency: CurrencyType;
  timeZone: string;
  dateFormat: string;
  mode: string;
  weekDayStart: StringToNumberPreference;
  YearMonthStart: StringToNumberPreference;
  monthDayStart: number;
  syncStatus: SyncStatusCollectionType;
  userLocation: UserLocationType;

  constructor(model?: PreferenceStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.baseCurrency = model.bc;
      this.timeZone = model.tz;
      this.dateFormat = model.df;
      this.mode = model.md;
      this.weekDayStart = model.wds;
      this.YearMonthStart = model.yms;
      this.monthDayStart = model.mds;
      this.syncStatus = model.ss;
      this.userLocation = model.ul;
    } else {
      this.version = DefaultPreferenceStoreModel.v;
    }
  }

  toStoreModel(): PreferenceStoreModel {
    return {
      bc: this.baseCurrency,
      tz: this.timeZone,
      df: this.dateFormat,
      md: this.mode,
      wds: this.weekDayStart,
      yms: this.YearMonthStart,
      mds: this.monthDayStart,
      ss: this.syncStatus,
      ul: this.userLocation,
      v: this.version,
      vid: this.vaultId,
      dc: this.dateCreated.toISOString(),
      dm: this.dateModified.toISOString(),
      id: this.id,
    };
  }

  clone(): PreferenceView {
    return new PreferenceView(this.toStoreModel());
  }
}

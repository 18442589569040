import { GeneratedCollection } from "../generated.collection.abstraction";
import { TransactionView } from "../../../models/view/transaction/transaction.view";
import { inject, signal, Signal, WritableSignal } from "@angular/core";
import { Observable } from "rxjs";
import { NormalizeTransaction } from "@bitwarden/web-vault/app/models/view/transaction/normalize/normalizeTransaction.utils";
import { Allocation } from "@bitwarden/web-vault/app/models/data/allocation.data";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PreferenceView } from "@bitwarden/web-vault/app/models/view/preference/preference.view";
import { ReferenceDataView } from "@bitwarden/web-vault/app/models/view/reference-data/reference-data.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { toObservable } from "@angular/core/rxjs-interop";

export class EnrichTransactionGeneratedCollection extends GeneratedCollection<TransactionView> {
  protected log: LogService = inject(LogService);
  protected userStore: UserStoreService = inject(UserStoreService);

  protected _collection: WritableSignal<TransactionView[]> = signal<TransactionView[]>(null);
  collection: Signal<TransactionView[]> = this._collection.asReadonly();
  collection$: Observable<TransactionView[]> = toObservable(this.collection);

  generate(
    preference: PreferenceView,
    referenceData: ReferenceDataView[],
    transactions: TransactionView[],
  ): void {
    this.log.info("---- Regenerating Enrich Transaction Collection ---- ");
    const enrichTransactions: TransactionView[] = [];
    const normalizeTransaction = new NormalizeTransaction(
      this.log,
      preference.baseCurrency,
      referenceData,
    );

    for (const transaction of transactions) {
      const enrich = transaction.clone();
      const institutionLink = this.getInstitutionLink(enrich.accountLink.id);
      enrich.c_institutionLink = { id: institutionLink.id };
      enrich.c_country.id = institutionLink.countryCode;
      Allocation.setAllocation(enrich);
      normalizeTransaction.normalizeImportedTransaction(enrich);
      enrichTransactions.push(Object.freeze(enrich) as TransactionView);
    }

    this._collection.set(enrichTransactions);
  }

  update(collection: TransactionView[]): void {
    //todo implement
  }

  clear(): void {
    this._collection.set(null);
  }

  protected getInstitutionLink(accountId: string) {
    const account = this.userStore.accounts.accountViews()?.find((a) => a.id === accountId);
    const institution = this.userStore.institutions
      .institutionViews()
      ?.find((i) => i.id === account?.institutionLink?.institutionId || "");
    const countryCode = institution?.swift.countryCode;
    return { id: institution?.id, countryCode: countryCode };
  }
}

import { Component, inject } from "@angular/core";

import { UserLocationService } from "@bitwarden/web-vault/app/services/location/user-location.service";

@Component({
  selector: "user-location",
  templateUrl: "./user-location.component.html",
})
export class UserLocationComponent {
  protected userLocationService = inject(UserLocationService);
}

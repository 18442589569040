import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { TransactionDirection } from "../../enum/transactionDirection";
import { TransactionSource } from "../../enum/transactionSource";
import { TransactionStatusType } from "../../types/general-types";
import { SplitCategoryType } from "../../types/split-category-type";
import { SplitClassificationType } from "../../types/split-classification-type";
import { Allocation } from "../allocation.data";
import { TransactionResponse } from "../response/transaction-response";
import { GlossBalance } from "../shared/gloss-balance";
import { GlossDate } from "../shared/gloss-date";
import { GlossQuantity } from "../shared/gloss-quantity";
import { Valuation } from "../valuation.data";

export class Transaction extends ModelValidator {
  private readonly __v = 1;

  accountId: string;
  private _sourceId: string;
  description: string;
  kind: string;
  valuationPrice: number;
  direction: TransactionDirection;
  categories: SplitCategoryType[] = [];
  classifications: SplitClassificationType[] = [];
  allocations: Array<Allocation>;
  bankImportedBalance: number; // stores the imported bank balance for an account

  private _id: string;
  private _transactionDate: GlossDate;
  private _dateModified: string;
  private _dateCreated: string;
  private status: boolean;
  private _balance: GlossBalance;
  private _quantity: GlossQuantity;
  private _valuation: Valuation;
  private _linkedTo: Array<string> = [];
  private _source: TransactionSource;
  private _sourceDetail: string; // todo change that to enum and rename

  // TODO: move this to the Transaction view in the future
  private _estimateTransaction: boolean; // is this a fake transaction created from an estimate
  private _revalTransaction: boolean; // is this a generated reval transaction created from symbol reference data
  private _isUsingValuationPrice: boolean;
  // TODO: will become one big transaction.type enum
  private _definition: TransactionStatusType;

  constructor(response: TransactionResponse) {
    super();
    if (response == null) {
      return;
    }

    this.sourceId = response.sourceId;
    this.checkVersion(this.__v, response.__v);
    this._id = response.id ? response.id : crypto.randomUUID();
    this.bankImportedBalance = response.bankImportedBalance;
    this.valuationPrice = response.valuationPrice;
    this.direction = response.direction;
    this.kind = response.kind;
    this.description = response.description;
    this.accountId = response.accountId ? response.accountId : "";
    this.allocations = response.allocations;
    this._dateCreated = new Date().toUTCString();
    this._dateModified = new Date().toUTCString();

    // TODO: move this to the Transaction view in the future
    this._estimateTransaction = false;
    this._revalTransaction = response.revalTransaction ? response.revalTransaction : false;
    this.quantity = new GlossQuantity().setToQuantityObj(response.quantity);
    this.balance = new GlossBalance().setToBalanceObj(response.balance);
    this.valuation = new Valuation().setToValuationObj(response.valuation);
    this.isUsingValuationPrice = response.isUsingValuationPrice;
    this.definition = response.definition;

    this.transactionDate =
      response.transactionDate instanceof GlossDate
        ? response.transactionDate
        : new GlossDate().setToDateObj(response.transactionDate);

    if (response.categories != null) {
      this.categories = response.categories;
    }

    if (response.classifications != null) {
      this.classifications = response.classifications;
    }

    if (response.linkedTo) {
      this.linkedTo = response.linkedTo;
    }
  }

  get id(): string {
    return this._id;
  }

  get sourceId(): string {
    return this._sourceId;
  }

  set sourceId(sourceId: string) {
    this._sourceId = sourceId;
  }

  get valuation(): Valuation {
    return this._valuation;
  }

  set valuation(valuation: Valuation) {
    this._valuation = valuation;
  }

  get balance(): GlossBalance {
    return this._balance;
  }

  set balance(value: GlossBalance) {
    this._balance = value;
  }

  get quantity(): GlossQuantity {
    return this._quantity;
  }
  set quantity(value: GlossQuantity) {
    this._quantity = value;
  }

  set definition(value: TransactionStatusType) {
    this._definition = value;
  }

  get definition(): TransactionStatusType {
    return this._definition;
  }

  setStatus(status: boolean) {
    this.status = status;
  }

  // TODO: move this to the Transaction view in the future
  set estimateTransaction(estimateTransaction: boolean) {
    this._estimateTransaction = estimateTransaction;
  }

  // TODO: move this to the Transaction view in the future
  get estimateTransaction() {
    return this._estimateTransaction;
  }

  // TODO: move this to the Transaction view in the future
  set revalTransaction(revalTransaction: boolean) {
    this._revalTransaction = revalTransaction;
  }

  // TODO: move this to the Transaction view in the future
  get revalTransaction() {
    return this._revalTransaction;
  }

  get transactionDate(): GlossDate {
    return this._transactionDate;
  }

  set transactionDate(value: GlossDate) {
    this._transactionDate = value;
  }

  get linkedTo(): Array<string> {
    return this._linkedTo;
  }

  set linkedTo(value: Array<string>) {
    this._linkedTo = value;
  }

  get isUsingValuationPrice(): boolean {
    return this._isUsingValuationPrice;
  }

  set isUsingValuationPrice(value: boolean) {
    this._isUsingValuationPrice = value;
  }

  get dateModified(): string {
    return this._dateModified;
  }

  get dateCreated(): string {
    return this._dateCreated;
  }

  /**
   * Creates a new GlossBalance on the transaction and sets it to the balance
   * parameter of the transaction. Assumes that the quantity and the valuation
   * piece of the transaction has been set correctly as it relies on these components
   * to be correct.
   */
  setGlossBalance() {
    if (this.direction && this.quantity && this.valuation) {
      let isTransfer = false;
      if (this.linkedTo.length > 0) {
        isTransfer = true;
      }
      this.balance = GlossBalance.createGlossBalanceFromQuantityValuation(
        this.direction,
        this.quantity,
        this.valuation,
        isTransfer,
      );
    }
  }

  getRawTransactionDate() {
    return this._transactionDate;
  }

  private parseGlossDate(transactionDate: GlossDate) {
    if (transactionDate instanceof GlossDate) {
      return transactionDate;
    }

    const glossDate = new GlossDate();
    glossDate.date = transactionDate;

    return glossDate;
  }
}

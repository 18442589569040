import { CurrencyType } from "@bitwarden/web-vault/app/models/types/location-currency.types";

export type ScopeValue =
  | "api" // legaci @deprecated
  | "offline_mode" // legaci @deprecated
  | "GLOSS"
  | "VAULT"
  | "VIEWS"
  | "DATA_AGGREGATOR"
  | "FOREX"
  | "EQUITY"
  | "BETA_ACCESS";

export type Scope = ScopeValue[];

// Define specific types for arrays
type DataAggregatorConnectorName = "BASIQ" | "PLAID";
type GlossView = "scenario" | "summary" | "combined";
type EquityInterval = "month" | "day" | "week" | "year";
type ForexInterval = "month" | "day" | "week" | "year";

// Define the claim interface
export interface RoleClaim {
  data_aggregator_connector_name: DataAggregatorConnectorName[];
  equity_interval: EquityInterval;
  forex_currency: CurrencyType[];
  forex_interval: ForexInterval;
  gloss_subscription_store: string;
  gloss_subscription_tier: "free" | "premium" | "enterprise";
  gloss_views: GlossView[];
  gloss_views_size: number;
  market_data_exchanges: string[];
  vault_size_limit: number;
}

// Now update your RoleAccessData class to use this type
export class RoleAccessData {
  private scope: Scope;
  private claim: RoleClaim;

  getScope() {
    return this.scope;
  }

  setScope(scopes: Scope) {
    this.scope = scopes;
  }

  getClaim(): RoleClaim {
    return this.claim;
  }

  setClaims(claims: RoleClaim) {
    this.claim = claims;
  }
}

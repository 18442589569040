import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from "@angular/core";

import { ModalService as BaseModalService } from "@bitwarden/angular/services/modal.service";
import { MessagingService } from "@bitwarden/common/abstractions/messaging.service";

@Injectable()
export class ModalService extends BaseModalService {
  el: any = null;
  modalOpen = false;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    applicationRef: ApplicationRef,
    injector: Injector,
    private messagingService: MessagingService,
  ) {
    super(componentFactoryResolver, applicationRef, injector);
  }
}

<div (keydown.enter)="$event.preventDefault()">
  <modal-header
    [title]="'locationSelection' | i18n"
    [showBackButton]="false"
    [showCloseButton]="false"
  ></modal-header>

  <div class="modal-body">
    <span class="tw-text-xl tw-font-normal">{{ "primaryLocationSelection" | i18n }}</span>
    <div class="tw-my-4 tw-flex tw-gap-12 md:tw-flex-col">
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        [title]="'australia' | i18n"
        (onClick)="userLocationService.processUserLocation('AU')"
      >
        <bank-icons [banks]="userLocationService.getBanks('AU')"></bank-icons>
      </tile-button>
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        [title]="'hongkong' | i18n"
        (onClick)="userLocationService.processUserLocation('HK')"
      >
        <bank-icons [banks]="userLocationService.getBanks('HK')"></bank-icons>
      </tile-button>
    </div>
    <span class="tw-text-base">{{ "locationSelectionDesc" | i18n }}</span>
  </div>
</div>

import { StoreData } from "./store.data";
import { Origin } from "../types/general-types";

export type SourceAccountModelVersion = 1;
export interface SourceAccountStoreModel extends StoreData {
  /**  **Version Number** **/
  v: SourceAccountModelVersion;

  /** source property on source-book */
  src: string;

  /** accountId property on source-book */
  acId: string;

  /** origin property  on the source book */
  org: Origin;
}

/** Default values for the source book store model */
export const DefaultSourceBookStoreModel: SourceAccountStoreModel = {
  src: "",
  acId: "",
  org: Origin.manual,
  v: 1,
  vid: "",
  dc: "",
  dm: "",
  id: "",
};

import { ScenarioGroupStoreModel } from "../../../../models/store/scenario-group.store.model";
import { ScenarioGroup } from "../../../../models/data/scenario-group.data";

export const scenarioGroup_v0_to_v1 = (scenarioGroup: ScenarioGroup): ScenarioGroupStoreModel => {
  return {
    dc: new Date().toString(),
    dm: new Date().toString(),
    v: 1,
    vid: "",

    nm: scenarioGroup.name,
    sym: scenarioGroup.symbol,
    anP: {
      date: scenarioGroup.anchorPoint.date,
      time: scenarioGroup.anchorPoint.time,
      timeZone: scenarioGroup.anchorPoint.tz,
    },
    eD: {
      date: scenarioGroup.endDate?.date,
      time: scenarioGroup.endDate?.time,
      timeZone: scenarioGroup.endDate?.tz,
    },
    scsId: scenarioGroup.scenarios.map((scenario) => scenario.id),
    estId: scenarioGroup.userDefinedEstimates.map((estimate) => estimate.id),
    source: scenarioGroup.source,
    id: scenarioGroup.id,
  };
};

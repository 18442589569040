import { ModelView } from "../model.view";
import {
  DefaultSourceTransactionStoreModel,
  SourceTransactionModelVersion,
  SourceTransactionStoreModel,
} from "../../store/source-transaction.store.model";
import { TransactionDirection } from "../../enum/transactionDirection";
import { TransactionStatusEnum } from "../../enum/transactionType";

export class SourceTransactionsView extends ModelView<SourceTransactionStoreModel> {
  protected version: SourceTransactionModelVersion;

  accountId: string;
  symbol: string;
  currency: string;
  description: string;
  kind: string;
  direction: TransactionDirection;
  bankImportedBalance: number;
  quantity: string;
  price: number;
  categories: Array<string>;
  classifications: Array<string>;
  sourceId: string;
  definition: TransactionStatusEnum;
  transactionDate: string;

  constructor(model?: SourceTransactionStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.accountId = model.acId;
      this.symbol = model.sym;
      this.currency = model.ccy;
      this.description = model.dsc;
      this.kind = model.knd;
      this.direction = model.dir;
      this.bankImportedBalance = model.bIB;
      this.quantity = model.qty;
      this.price = model.prc;
      this.categories = model.cts;
      this.classifications = model.cls;
      this.sourceId = model.srId;
      this.definition = model.dfn;
      this.transactionDate = model.tDt;
    } else {
      this.version = DefaultSourceTransactionStoreModel.v;
    }
  }

  toStoreModel(): SourceTransactionStoreModel {
    return {
      acId: this.accountId,
      bIB: this.bankImportedBalance,
      ccy: this.currency,
      cls: this.classifications,
      cts: this.categories,
      dc: this.dateCreated.toISOString(),
      dfn: this.definition,
      dir: this.direction,
      dm: this.dateModified.toISOString(),
      dsc: this.description,
      id: this.id,
      knd: this.kind,
      prc: this.price,
      qty: this.quantity,
      srId: this.sourceId,
      sym: this.symbol,
      tDt: this.transactionDate,
      v: 1,
      vid: this.vaultId,
    };
  }

  clone(): SourceTransactionsView {
    return new SourceTransactionsView(this.toStoreModel());
  }
}

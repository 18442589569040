import { Component, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { GranularityProperty } from "../../../models/types/balanceGroupingTypes";
import { ScenarioStoreService } from "../../../services/store/scenario/scenario.store.service";
import { filter, Observable } from "rxjs";
import { DeviceDetectorService } from "ngx-device-detector";
import "./scenario-chart.component.scss";
import * as d3 from "d3";

import { Scales } from "@bitwarden/web-vault/app/components/charts/shared/scales";
import { Axis } from "@bitwarden/web-vault/app/components/charts/shared/axis";
import { DataLine } from "@bitwarden/web-vault/app/components/charts/shared/data-line";
import { DataPoints } from "@bitwarden/web-vault/app/components/charts/shared/data-points";
import { debounce } from "lodash";
import { CalculatedBalancesArray } from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";

@Component({
  selector: "app-scenario-chart",
  templateUrl: "./scenario-chart.component.html",
  styles: ["scenario-chart.component.scss"],
})
export class ScenarioChartComponent {
  // subscribe to signal stores
  private scenarioStoreService = inject(ScenarioStoreService);
  private balanceByTime$: Observable<CalculatedBalancesArray> = toObservable(
    this.scenarioStoreService.balanceByTime.balances,
  );
  private deviceDetectorService = inject(DeviceDetectorService);

  // graph elements
  private svg: any;
  private graphContainer: any;
  private balanceGraph: any;

  private dimension: { width: number; height: number };
  private margin: { top: number; bottom: number; left: number; right: number };
  private innerHeight: number;
  private innerWidth: number;

  // graph dimensions
  private dimensions = {
    desktop: {
      width: 590,
      height: 300,
    },
    mobile: {
      width: 590,
      height: 300,
    },
  };

  private margins = {
    desktop: {
      top: 0,
      bottom: 30,
      left: 50,
      right: 20,
    },
    mobile: {
      top: 0,
      bottom: 10,
      left: 10,
      right: 10,
    },
  };

  private lineColours = ["#496A71", "#0081B8", "#2A6CE2"];

  private outerWidth = this.dimensions.desktop.width;
  private outerHeight = this.dimensions.desktop.height;

  private markerLine: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private xScale: d3.ScaleTime<number, number, never>;
  private yScale: d3.ScaleLinear<number, number, never>;

  // Graph Elements controlled by graph element classes
  private graphElementsInitialized = false;
  private scales: Scales;
  private axis: Axis;
  private dataLine: DataLine;
  private dataPoints: DataPoints;

  // Graph Data
  private balancePointData: number[][] = [];
  private balancesByTime: Map<string, number>[];

  /**
   * Data to be passed to the balance HUD component
   */
  hudIndex: number;
  granularity: GranularityProperty;

  constructor() {
    this.balanceByTime$
      .pipe(
        filter((x) => x !== null || typeof x !== "undefined"),
        takeUntilDestroyed(),
      )
      .subscribe(this.updateGraph.bind(this));
  }

  ngOnInit() {
    this.setGraphDimensions();
    this.initGraphContainers();
  }

  /**
   * Triggers when the balance numbers have updated on the store
   *
   */
  updateGraph() {
    // get the granularity from the groupings signal
    this.updateGranularity();

    // Create a local copy of the balance by time Map
    this.balancesByTime = this.scenarioStoreService.balanceByTime.clone();

    const startDate = this.scenarioStoreService.period?.startDate;
    const endDate = this.scenarioStoreService.period?.endDate;

    if (!this.balancesByTime || this.balancesByTime.length === 0) {
      return;
    }

    if (!this.graphElementsInitialized) {
      this.initGraphElements();
    }

    this.drawScales(startDate, endDate, this.balancesByTime);
    this.drawLines(this.balancesByTime);
    this.drawPoints(this.balancesByTime);

    if (this.balancePointData.length > 0) {
      this.setHudData(this.balancePointData.length - 1);
    }
  }

  updateGranularity() {
    this.granularity = this.scenarioStoreService.grouping.granularity;
  }

  initGraphContainers() {
    this.initSVG();
    this.initGroupings();
    this.addMouseMoveEvent();
  }

  // <editor-fold desc="Initialization Functions">
  private initSVG() {
    this.graphContainer = d3.select("div#scenarioLineChart");
    this.svg = this.graphContainer
      .append("svg")
      .attr("id", "scenarioGraphContainer")
      .attr("viewBox", `0 0 ${this.outerWidth} ${this.outerHeight}`);
  }

  private initGroupings() {
    // parent grouping for graph elements
    this.balanceGraph = this.svg
      .append("g")
      .attr("id", "scenarioLineChartGroup")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`);
  }

  private initGraphElements() {
    this.initScales();
    this.initAxis();
    this.initDataPoints();
    this.initDataLine();
    // this.initMarker();
  }

  private initMarker() {
    if (!this.markerLine && this.dataLine) {
      // only create the marker if it hasn't been created
      this.markerLine = this.dataLine.createMarkerLine(this.innerHeight);
    }
  }

  private initScales() {
    this.scales = new Scales();
    this.xScale = this.scales.xScale;
    this.yScale = this.scales.yScale;
  }

  private initAxis() {
    this.axis = new Axis(
      this.balanceGraph,
      this.xScale,
      this.yScale,
      this.deviceDetectorService.isMobile(),
    );
  }

  private initDataPoints() {
    this.dataPoints = new DataPoints(this.balanceGraph, this.xScale, this.yScale);
  }

  private initDataLine() {
    this.dataLine = new DataLine(this.balanceGraph, this.xScale, this.yScale);
  }

  private setGraphDimensions() {
    if (this.deviceDetectorService.isMobile()) {
      this.dimension = this.dimensions.mobile;
      this.margin = this.margins.mobile;
    } else {
      this.dimension = this.dimensions.desktop;
      this.margin = this.margins.desktop;
    }
    this.innerHeight = this.dimension.height - this.margin.top - this.margin.bottom;
    this.innerWidth = this.dimension.width - this.margin.left - this.margin.right;
  }

  // </editor-fold desc="Initialization Functions">

  // <editor-fold desc="Graph Drawing Functions">
  private drawScales(startDate: Date, endDate: Date, balancesByTime: Map<string, number>[]) {
    const [minYAxisValue, maxYAxisValue] = Scales.calculateMaxMinYAxisValuesList(balancesByTime);

    if (!this.graphElementsInitialized) {
      this.scales.setScales(
        this.innerWidth,
        this.innerHeight,
        minYAxisValue,
        maxYAxisValue,
        startDate,
        endDate,
      );

      this.axis.drawAxis(
        this.innerHeight,
        startDate,
        endDate,
        this.granularity,
        balancesByTime,
        "",
        "",
      );
      this.graphElementsInitialized = true;
    } else {
      this.scales.setScales(
        this.innerWidth,
        this.innerHeight,
        minYAxisValue,
        maxYAxisValue,
        startDate,
        endDate,
      );
      this.axis.redrawAxis(this.innerHeight, startDate, endDate, this.granularity, balancesByTime);
    }
  }

  private drawPoints(balancesByTime: Map<string, number>[]) {
    balancesByTime.forEach((balanceSet, index) => {
      if (balanceSet.size > 0) {
        const balancePoints = this.dataPoints.generatePointData(balanceSet);
        this.balancePointData = balancePoints;
        this.dataPoints.updatePoints(
          `balancePoints_${index}`,
          "#FFFFFF",
          balancePoints,
          1,
          this.lineColours[index],
        );
      }
    });
  }

  private drawLines(balancesByTime: Map<string, number>[]) {
    balancesByTime.forEach((balanceSet, index) => {
      this.dataLine.updateBalanceLineGraph(
        balanceSet,
        `balanceLine_${index}`,
        this.lineColours[index],
      );
    });
  }

  // </editor-fold desc="Graph Drawing Functions">

  // <editor-fold desc="Mouse Move Events">
  private addMouseMoveEvent() {
    this.graphContainer.on("mousemove", (e: any) =>
      this.debounceD3Event(this.handleMouseMoveEvent(e), 100),
    );
  }

  private handleMouseMoveEvent(e: any): any {
    const pointerCoords = d3.pointer(e);

    const mouseX = pointerCoords[0] - this.margin.left;

    let i = 0;
    let closestPointX = 0;
    let closestPointY = 0;

    for (const [balanceX, balanceY] of this.balancePointData) {
      if (i == this.balancePointData.length - 1) {
        closestPointX = balanceX;
        closestPointY = balanceY;
        break;
      }
      if (mouseX < balanceX) {
        closestPointX = balanceX;
        closestPointY = balanceY;
        break;
      } else {
        const [nextBalanceX, nextBalanceY] = this.balancePointData[i + 1];
        if (mouseX < nextBalanceX) {
          if (mouseX - balanceX < nextBalanceX - mouseX) {
            closestPointX = balanceX;
            closestPointY = balanceY;
            break;
          } else {
            closestPointX = nextBalanceX;
            closestPointY = nextBalanceY;
            i++;
            break;
          }
        }
      }
      i++;
    }

    // this.dataLine.moveMarkerLine(closestPointX, closestPointY);
    this.setHudData(i);
  }

  setHudData(i: number) {
    this.hudIndex = i;
  }

  private debounceD3Event(handleMouseEvent: any, wait: number) {
    return () => {
      debounce(handleMouseEvent, wait);
    };
  }

  // </editor-fold desc="Mouse Move Events">
}

<div class=""></div>
<div class="tw-flex tw-flex-col tw-gap-1">
  @if (label) {
    <div class="tw-flex tw-gap-1 tw-font-medium tw-text-neutral-800 tw-z-10">
      <span class="tw-whitespace-nowrap">{{ label }}</span>
      <span *ngIf="isRequired" class="tw-text-sm">*</span>
    </div>
  }
  <div
    [ngClass]="type === 'number' ? 'tw-h-12' : 'tw-h-14'"
    class="tw-px-3 tw-py-2 tw-flex tw-w-full tw-items-center tw-rounded-lg tw-bg-neutral-50 tw-shadow-inner focus-within:tw-border-2 focus-within:tw-border-primary focus-within:tw-border-solid"
  >
    <input
      class="tw-w-full tw-bg-transparent tw-border-none"
      type="{{ type }}"
      [formControl]="inputControl"
    />
  </div>
</div>

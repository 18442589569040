import { Component, Input } from "@angular/core";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";

@Component({
  selector: "app-symbol-details",
  templateUrl: "./symbol-details.component.html",
})
export class SymbolDetailsComponent {
  @Input() symbol: SymbolView;
}

import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { HintComponent as BaseHintComponent } from "@bitwarden/angular/components/hint.component";
import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { LoginService } from "@bitwarden/common/abstractions/login.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";

@Component({
  selector: "app-hint",
  templateUrl: "hint.component.html",
})
export class HintComponent extends BaseHintComponent {
  submitButtonOptions: ActionButton;
  cancelButtonOptions: ActionButton;
  constructor(
    router: Router,
    i18nService: I18nService,
    apiService: ApiService,
    platformUtilsService: PlatformUtilsService,
    logService: LogService,
    loginService: LoginService,
  ) {
    super(router, i18nService, apiService, platformUtilsService, logService, loginService);
    this.submitButtonOptions = new ActionButton({
      type: "submit",
      text: this.i18nService.t("submit"),
      onClick: this.submit.bind(this),
    });
    this.cancelButtonOptions = new ActionButton({
      type: "link",
      class: "neutral",
      text: this.i18nService.t("cancel"),
      link: "/login",
      isEnabled: true,
    });
  }
}

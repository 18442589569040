import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { computed, inject, signal } from "@angular/core";
import { TransactionStoreModel } from "../../../models/store/transaction.store.model";
import { TransactionView } from "../../../models/view/transaction/transaction.view";

import { DependencyPublisher } from "@bitwarden/web-vault/app/services/store/dependency.publisher.abstraction";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import { Observable } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";

// todo - implement CategoryView not here

export class TransactionStoreCollection
  extends StoreModelCollection<TransactionStoreModel, ModelView<TransactionStoreModel>>
  implements DependencyPublisher<TransactionView>
{
  protected log: LogService = inject(LogService);

  readonly type: StoreModelNames = "TransactionStoreModel";
  protected models: Map<string, TransactionStoreModel> = new Map<string, TransactionStoreModel>();

  private _transactionViews = signal<TransactionView[]>(null);
  readonly transactionViews = this._transactionViews.asReadonly();

  /** Store Subscriber/Publisher **/
  publisher$: Observable<TransactionView[]> = toObservable(this.transactionViews);

  /** First Transaction Date **/
  firstTransactionDate = computed(() => {
    return this.transactionViews()?.reduce(
      (acc, item) => (item.transactionDate.getTime() < acc.getTime() ? item.transactionDate : acc),
      new Date(),
    );
  });

  /** Last Transaction Date **/
  lastTransactionDate = computed(() => {
    return this.transactionViews()?.reduce(
      (acc, item) => (item.transactionDate.getTime() > acc.getTime() ? item.transactionDate : acc),
      new Date(0),
    );
  });

  triggerChanges(): void {
    const views: TransactionView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new TransactionView(model)) as TransactionView);
    });
    this._transactionViews.set(views);
  }

  loadModels(models: TransactionStoreModel[]): void {
    models.forEach((model) => {
      if (this.validator.assertModel(this.type, model)) {
        this.models.set(model.id, model);
      }
    });
  }

  clear(): void {
    this.models.clear();
    this._transactionViews.set(null);
  }
}

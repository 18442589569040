import { inject, Injector, DestroyRef } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import type { AccountTableView } from "@bitwarden/web-vault/app/models/types/account.types";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";
import { ImportStoreService } from "@bitwarden/web-vault/app/services/store/import/import.store.service";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { combineLatest, filter, map } from "rxjs";
import { ObservableSubscriptionImpl } from "../../data-subscription";
import { getAccountTableViews } from "../account.table";

export type TableSource = AccountTableView;

/**
 * This class only need to deal with angular dependency
 * and the core logic is in getAccountTableViews function which can be easily unit tested
 */
export class SyncTableSubscription extends ObservableSubscriptionImpl<TableSource[]> {
  userStoreService: UserStoreService;
  importStoreService: ImportStoreService;
  calculationStoreService: CalculationStoreService;

  constructor() {
    super();
    this.userStoreService = inject(UserStoreService);
    this.importStoreService = inject(ImportStoreService);
    this.calculationStoreService = inject(CalculationStoreService);
    this.start();
    // auto stop when destroyed
    inject(Injector)
      .get(DestroyRef)
      .onDestroy(() => {
        this.stop();
      });
  }

  get source() {
    const accountViews$ = toObservable(this.userStoreService.accounts.accountViews);
    const balances$ = toObservable(this.calculationStoreService.balanceByAccount.balances);
    const institutionViews$ = toObservable(this.userStoreService.institutions.institutionViews);
    const preference$ = toObservable(this.userStoreService.preferences.preferenceView);
    const syncStatusViews$ = toObservable(this.importStoreService.syncStatus.syncStatusViews);
    return combineLatest([
      accountViews$,
      syncStatusViews$,
      institutionViews$,
      balances$,
      preference$,
    ]).pipe(
      filter(([accountViews, syncStatusViews, institutionViews, balances]) => balances?.size > 0),
      map((d) => {
        return getAccountTableViews(...d);
      }),
    );
  }
}

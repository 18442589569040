<div class="tw-flex tw-h-full tw-flex-col">
  <span class="tw-mb-6 tw-flex tw-text-base tw-font-medium tw-leading-[125%] tw-text-neutral-800">
    {{ "topInterestRateAccounts" | i18n }}
  </span>

  <div
    class="tw-w-full tw-overflow-hidden"
    (touchstart)="onTouchStart($event)"
    (touchmove)="onTouchMove($event)"
    (touchend)="onTouchEnd()"
  >
    <div
      class="tw-flex tw-h-full tw-max-h-[575px] tw-pb-2 tw-transition-transform tw-duration-500"
      [ngStyle]="{ transform: 'translateX(-' + activeIndex * 100 + '%)' }"
    >
      <ng-content></ng-content>
    </div>
    <div
      class="tw-absolute tw-bottom-0 tw-left-1/2 tw-flex tw--translate-x-1/2 tw-transform tw-items-center tw-gap-2"
    >
      <span
        *ngFor="let item of items; let i = index"
        class="tw-cursor-pointer tw-rounded-full"
        [ngClass]="{
          'tw-h-4 tw-w-4 tw-bg-primary': isActive(i),
          'tw-h-2.5 tw-w-2.5 tw-bg-neutral-100': !isActive(i),
        }"
        (click)="setActiveIndex(i)"
      ></span>
    </div>
  </div>
</div>

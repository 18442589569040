import { StoreData } from "./store.data";
import { CabinetFileType, VaultFileStatus } from "../types/cabinet.types";
import { GlossDateAsObjectType } from "./types/store.types";
import { VaultFile } from "@bitwarden/web-vault/app/models/data/blobby/vault-file.data";

export type VaultFileModelVersion = 1;
export interface VaultFileStoreModel extends StoreData {
  /**  **Version Number** **/
  v: VaultFileModelVersion;

  /** @alias **Encrypted Key** **/
  encK: encryptedKeyJSON;

  /** @alias **Name of the file** **/
  nm: string;

  /** @alias **Status of the file** **/
  stat: VaultFileStatus;

  /** @alias **mimeType of the file** **/
  mt: CabinetFileType;

  /** @alias **Statement to date** **/
  std: Date;

  /** @alias **Statement from date** **/
  sfd: Date;

  /** @alias **Statement Accounts** **/
  sa: string[];
}

interface encryptedKeyJSON {
  keyB64: string;
}

export const DefaultVaultFileStoreModel: VaultFileStoreModel = {
  dc: "",
  dm: "",
  encK: undefined,
  id: "",
  mt: undefined,
  nm: "",
  sa: [],
  sfd: undefined,
  stat: undefined,
  std: undefined,
  v: 1,
  vid: "",
};

import { Component, Inject, Injector, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { AddTransactionComponent } from "@bitwarden/web-vault/app/components/add-transaction/add-transaction.component";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { actionButtonOptions } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/accounts-add-edit/component.options";
import { transactionDataType } from "@bitwarden/web-vault/app/models/types/transactionData.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { MarketDataService } from "@bitwarden/web-vault/app/services/DataService/market-data/market-data.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { SelectAccountService } from "@bitwarden/web-vault/app/services/cabinet/selectAccount.service";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";

@Component({
  selector: "account-transaction",
  templateUrl: "./transaction.component.html",
})
export class TransactionComponent {
  title = "";
  loading = false;
  i18nService: I18nService;
  globalService: GlobalService;
  transactionService: TransactionService;
  selectAccountService: SelectAccountService;
  dataRepositoryService: DataRepositoryService;
  marketDataService: MarketDataService;

  @ViewChild(AddTransactionComponent) addTransactionComponent!: AddTransactionComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accountView: AccountView;
      closeDialogue: CallableFunction;
    },
    private injector: Injector,
  ) {
    this.i18nService = injector.get(I18nService);
    this.globalService = injector.get(GlobalService);
    this.transactionService = injector.get(TransactionService);
    this.selectAccountService = injector.get(SelectAccountService);
    this.dataRepositoryService = injector.get(DataRepositoryService);
    this.marketDataService = injector.get(MarketDataService);
    this.selectAccountService.setSelectedAccount(data.accountView);
    this.title = this.i18nService.t("addTransaction");
  }

  saveButtonOptions = new ActionButton({
    ...actionButtonOptions,
    isEnabled: true,
    onClick: this.submit.bind(this),
  });

  async receivedTransactionData($event: transactionDataType | "touched") {
    // console.log("transaction -> receivedTransactionData -> $event", $event);
    if ($event && $event !== "touched") {
      let addedTransaction: TransactionView;
      try {
        this.loading = true;
        addedTransaction = await this.transactionService.createNormalTransaction(
          $event.account,
          $event.formData,
        );
        this.globalService.showSuccessMessage("success", "transactionAddedSuccessfully");
        this.data.closeDialogue();
        this.selectAccountService.setSelectedAccount(null);
      } catch (e) {
        this.globalService.showErrorMessage("error", "transactionFailed");
        this.saveButtonOptions.enableButton(true);
      } finally {
        // const transactions = await this.dataRepositoryService.getAllTransactions(false);
        // await this.marketDataService.refreshMarketData(transactions.concat(addedTransaction));
        this.loading = false;
      }
    } else {
      this.saveButtonOptions.enableButton(true);
    }
  }

  submit() {
    this.addTransactionComponent.completeForm();
  }
}

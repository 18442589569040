@if (viewsDropdown) {
  <div
    class="tw-fixed tw-inset-0 tw-bg-black tw-opacity-50 tw-z-10"
    (click)="toggleDropdown()"
  ></div>
}
<div
  class="tw-w-full tw-h-full tw-relative"
  [ngClass]="{ 'tw-rounded-full tw-bg-white tw-z-20': viewsDropdown }"
>
  @if (calculationStoreService.filters.selected()) {
    <div class="tw-w-full tw-h-full tw-flex tw-gap-1 tw-items-center" (click)="toggleDropdown()">
      <div class="tw-w-full tw-h-full tw-rounded-full tw-bg-white tw-px-6 tw-flex tw-items-center">
        <div class="tw-flex tw-w-full tw-h-full tw-items-center tw-cursor-pointer tw-gap-2">
          <!--        todo: handle the width-->
          <div class="tw-font-medium tw-text-neutral-600">Filter</div>
          <!--        todo: display these 3 ?-->
          <!--        <div class="tw-rounded-full tw-py-2 tw-px-3 tw-bg-neutral-50">{{ selectedOptions.selectedGranularityOption || 'Granularity' }}</div>-->
          <!--        <div class="tw-rounded-full tw-py-2 tw-px-3 tw-bg-neutral-50">{{ selectedOptions.selectedCountries?.join(', ') || 'Countries' }}</div>-->
          <!--        <div class="tw-rounded-full tw-py-2 tw-px-3 tw-bg-neutral-50">{{ selectedOptions.selectedCategories?.join(', ') || 'Categories' }}</div>-->
          <div class="tw-rounded-full tw-py-2 tw-px-3 tw-bg-neutral-50">
            {{ "institutions" | i18n }}({{
              calculationStoreService.filters.selected().institutions.length
            }})
          </div>
          <div class="tw-rounded-full tw-py-2 tw-px-3 tw-bg-neutral-50">
            {{ "accounts" | i18n }}({{
              calculationStoreService.filters.selected().accounts.length
            }})
          </div>
        </div>
      </div>
      <icon
        name="view-toggle"
        [size]="6"
        [currentColor]="'tw-text-black'"
        class="tw-px-3 xs:tw-hidden"
      ></icon>
    </div>
  }

  @if (viewsDropdown) {
    <app-views-dropdown
      class="tw-absolute tw-mt-1 tw-top-full tw-left-0 tw-w-full tw-rounded-xl tw-bg-neutral-50 tw-p-3 tw-shadow-lg tw-max-h-[500px] tw-overflow-y-auto"
      (selectedOptionsChange)="toggleDropdown()"
    ></app-views-dropdown>
  }
</div>

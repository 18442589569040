import { StoreData } from "./store.data";

export type CategoryModelVersion = 1;
export interface CategoryStoreModel extends StoreData {
  /** **Date structure Version Number** **/
  v: CategoryModelVersion;

  /** category name */
  n: string;

  /** category is generate default */
  gendef?: boolean; // when we import data and create accounts if there are no category-renderer related to transaction

  /** category weight */
  w: number;
}

export const DefaultCategoryStoreModel: CategoryStoreModel = {
  dc: "",
  dm: "",
  gendef: false,
  id: "",
  n: "",
  v: 1,
  vid: "",
  w: 0,
};

import { inject, Injectable, signal } from "@angular/core";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { StateProcess } from "@bitwarden/web-vault/app/services/store/app-state/state-process";
import {
  ProcessType,
  StateProcessFactory,
} from "@bitwarden/web-vault/app/services/store/app-state/state-process.factory";
import { interval, BehaviorSubject, combineLatest } from "rxjs";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { LoadingStateLogic } from "@bitwarden/web-vault/app/services/store/app-state/loading-state.logic";
import { AppStateType } from "@bitwarden/web-vault/app/services/store/app-state/loading-state.type";

/**
 * Possible context of the app. This can be improved.
 *
 * @tag with Calculation : A page or route that required any type of calculation to be loaded to be displayed correctly.
 * @tag with Data : A page that display data from the vault.
 * @tag system : Context to the app, from user perspective this is always blocking
 * @tag background : App background context, from user perspective this is never blocking
 */
export type AppContext = "calculation" | "data" | "system" | "waiting-for-user" | "background";

export const InitialAppState: AppStateType = {
  status: "idle",
  isStoreInitialised: false,
};

@Injectable({
  providedIn: "root",
})
export class AppStateStoreService {
  private log: LogService = inject(LogService);
  private readonly initialContext: AppContext = "data";
  private loadingLogic = new LoadingStateLogic();

  /** Current context of the app **/
  context = signal(this.initialContext);

  private _state = signal<AppStateType>(InitialAppState);

  state = this._state.asReadonly();

  /** App global Overlay **/
  helpBox = signal({ isVisible: false });
  wizard = signal({ isVisible: false });

  /** Loading and progress bar overlay **/
  private activeProcesses = new Map<ProcessType, StateProcess>();
  private activeProcess$ = new BehaviorSubject<StateProcess>(null);

  private listenerRef = combineLatest([
    this.activeProcess$.asObservable(),
    toObservable(this.context),
  ]).subscribe(this.onProcessUpdate.bind(this));

  constructor() {
    /** Cronjob for managing rogue processes **/
    interval(3000).pipe(takeUntilDestroyed()).subscribe(this.onProcessCheck.bind(this));
  }

  clearStore() {
    this._state.set(InitialAppState);
  }

  private onProcessUpdate() {
    const newState = this.loadingLogic.calculateNewState(
      this.context(),
      this._state(),
      this.activeProcesses,
    );
    this._state.set(newState);
  }

  private onProcessCheck() {
    this.activeProcesses.forEach((process) => {
      const runtime = (performance.now() - process.startTime) / 1000;
      if (runtime > 60) {
        this.log.error(
          "Process " +
            process.type +
            " has been running for " +
            runtime +
            " seconds, killing process",
        );
        this.endProcess(process.type);
      } else if (runtime > 15) {
        this.log.warning(
          "Process " + process.type + " has been running for " + runtime + " seconds",
        );
        this.log.warning(JSON.stringify(process));
      }
    });
  }

  startProcess(type: ProcessType, msg: string, progressMsg?: string) {
    this.log.info("Starting process: " + type);
    const process = StateProcessFactory.create(type);
    process.message = msg;
    process.progressMessage = progressMsg ?? "";

    if (this.activeProcesses.has(type)) {
      this.log.warning("Duplicate Running process detected:");
      this.log.warning("Old: \n" + JSON.stringify(this.activeProcesses.get(type)));
      this.log.warning("New: \n" + JSON.stringify(process));
      this.activeProcesses.get(type).merge(process);
    } else {
      this.activeProcesses.set(type, process);
    }

    this.activeProcess$.next(process);
  }

  /**
   * Updates the progress and messages for an active process of a given type.
   *
   * @param {ProcessType} type - The type of the process to update.
   * @param {number} progress - The progress value to set for the process, should be within the range of ***0 to 100***.
   * @param {string} [msg] - An optional message to associate with the process.
   * @param {string} [progressMsg] - An optional progress message to associate with the process.
   * @return {void} This method does not return a value.
   */
  processTick(type: ProcessType, progress: number, msg?: string, progressMsg?: string) {
    if (progress > 100) {
      this.log.warning("update:: Process cannot be greater than 100 %");
      progress = 100;
    }

    if (this.activeProcesses.has(type)) {
      /** Update map object ref **/
      const processRef = this.activeProcesses.get(type);
      processRef.tick(progress);
      processRef.message = msg ?? processRef.message;
      processRef.progressMessage = progressMsg ?? processRef.progressMessage;

      this.activeProcess$.next(processRef);
    } else {
      this.log.error(
        "Trying to update progress of a process that does not exist please use startProcess: " +
          type,
      );
    }
  }

  errorProcess(type: ProcessType, msg: string) {
    if (this.activeProcesses.has(type)) {
      const processRef = this.activeProcesses.get(type);
      processRef.error(msg);
      this.activeProcess$.next(processRef);
    } else {
      this.log.error(
        "Trying to update progress of a process that does not exist please use startProcess: " +
          type,
      );
    }
  }

  endProcess(type: ProcessType) {
    if (this.activeProcesses.has(type)) {
      const processRef = this.activeProcesses.get(type);

      this.log.info(
        "Ending process: " +
          type +
          " Runtime : " +
          (performance.now() - processRef.startTime) / 1000,
      );
      processRef.terminate();
      this.activeProcess$.next(null);

      if (processRef.completed()) {
        this.activeProcesses.delete(type);
      }
    } else {
      this.log.error(
        "Trying to end progress of a process that does not exist please use startProcess: " + type,
      );
    }
  }
}

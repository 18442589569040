<div class="tw-w-full tw-items-center tw-rounded-lg tw-bg-white">
  <div
    class="tw-flex tw-h-12 tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3"
    [attr.data-testid]="testId"
    (click)="toggleExpand()"
  >
    <div class="tw-flex tw-gap-2 tw-items-center tw-text-neutral-800">
      <span>{{ title }}</span>
      <span class="tw-flex tw-py-2 tw-px-3 tw-rounded-full tw-bg-neutral-50">{{
        getOptionsText()
      }}</span>
    </div>
    <icon [name]="'down-arrow'" [size]="5" [currentColor]="'neutral-800'" class="tw-right-3"></icon>
  </div>

  <div *ngIf="isExpanded" class="tw-w-full tw-bg-white tw-p-2 tw-rounded-lg">
    <div
      class="tw-flex tw-h-12 tw-w-full  tw-items-center tw-px-3 tw-rounded-lg  tw-ml-1 tw-mb-1
      {{
        isAllDisabled()
          ? 'tw-opacity-50 tw-cursor-not-allowed tw-bg-neutral-100 hover:tw-bg-neutral-100'
          : 'tw-cursor-pointer hover:tw-bg-neutral-50'
      }}"
      (click)="selectAllOptions()"
    >
      <app-checkbox
        [disabled]="isAllDisabled()"
        [checked]="areAllOptionsSelected()"
        [title]="'Select All'"
      ></app-checkbox>
    </div>
    <div
      *ngFor="let option of options"
      class="tw-flex tw-h-12 tw-w-full  tw-items-center tw-px-3 tw-rounded-lg  tw-ml-1 tw-mb-1
      {{
        isDisabled(option)
          ? 'tw-opacity-50 tw-cursor-not-allowed tw-bg-neutral-100 hover:tw-bg-neutral-100'
          : 'tw-cursor-pointer hover:tw-bg-neutral-50'
      }}"
      [ngClass]="{ 'tw-bg-neutral-50': isSelected(option) }"
      (click)="toggleOption(option)"
    >
      <!--      <input type="checkbox" [checked]="isSelected(option)" class="tw-mr-2" />-->
      <!--      {{ option }}-->
      <app-checkbox [disabled]="isDisabled(option)" [checked]="isSelected(option)" [title]="option">
      </app-checkbox>
    </div>
  </div>
</div>

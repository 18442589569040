<ng-container>
  <div
    class="tw-flex tw-h-14 tw-w-full tw-cursor-pointer tw-items-center tw-rounded-xl"
    [ngClass]="{
      'tw-bg-neutral-50': this.option.selected && this.option.value === selectedValue,
      'tw-cursor-pointer': !option.disabled,
      'tw-opacity-30': option.disabled,
    }"
    (click)="option.disabled ? null : onButtonClick(option.value)"
  >
    <div class="tw-relative tw-ml-1.5 tw-flex tw-items-center tw-justify-center">
      <div class="tw-h-6 tw-w-6 tw-rounded-full tw-bg-white tw-shadow-radio-outer"></div>
      <div
        class="tw-absolute tw-rounded-full tw-w-3 tw-h-3 tw-shadow-radio-inner
      {{
          this.option.selected && this.option.value === selectedValue
            ? 'tw-bg-primary'
            : 'tw-bg-white'
        }} "
      ></div>
    </div>
    <p *ngIf="option.labelType === 'text'" class="tw-ml-3 tw-text-lg tw-font-medium">
      {{ option.label }}
    </p>
    <ng-container *ngIf="option.labelType === 'template'">
      <ng-content></ng-content>
    </ng-container>
  </div>
</ng-container>

import { Injector } from "@angular/core";

import { Origin } from "../../models/types/general-types";
import { AccountView } from "../../models/view/account/account.view";

import { BasiqConnector } from "./basiq/basiq-connector.service";

export class GlossConnectorService {
  private readonly origin: Origin;
  private readonly accountViews: AccountView[];

  constructor(
    origin: Origin,
    accountViews: AccountView[],
    private injector: Injector,
  ) {
    this.origin = origin;
    this.accountViews = accountViews;
  }

  getConnector() {
    switch (this.origin) {
      case Origin.basiq:
        return new BasiqConnector(this.accountViews, this.injector);
      /*      case Origin.plaid:
        return new PlaidSyncStatus(this.accountViews, this.injector);*/
      default:
        return null;
    }
  }
}

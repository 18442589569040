import { ModelView } from "../model.view";

import {
  DefaultReferenceDataStoreModel,
  ReferenceDataModelVersion,
  ReferenceDataStoreModel,
} from "../../store/reference-data.store.model";
import { GlossDateAsObjectType } from "../../store/types/store.types";

export type ReferenceDate = string;
export type ReferenceBase = string;
export type ReferenceCross = string;
export type ReferenceIdParts = [ReferenceDate, ReferenceBase, ReferenceCross];
export type ReferenceId = `${ReferenceDate}|${ReferenceBase}|${ReferenceCross}`;

export interface IReferenceData {
  date: GlossDateAsObjectType;
  base: string;
  rate: number;
  symbol: string;
}

export class ReferenceDataView
  extends ModelView<ReferenceDataStoreModel>
  implements IReferenceData
{
  protected version: ReferenceDataModelVersion;

  date: GlossDateAsObjectType;
  base: string;
  id: ReferenceId;
  rate: number;
  symbol: string;

  constructor(model?: ReferenceDataStoreModel) {
    super(model);
    if (typeof model === "object") {
      const { date, base, symbol, rate } = this.castToReferenceDataView(model);
      this.date = date;
      this.base = base;
      this.symbol = symbol;
      this.rate = rate;
    } else {
      this.version = DefaultReferenceDataStoreModel.v;
    }
  }

  nBase(): string {
    return this.base;
  }
  nCross(): string {
    return this.symbol;
  }
  nDate(): string {
    return this.id.split("|")[0];
  }
  nRate(): number {
    return this.rate;
  }

  isMoreRecent(other: ReferenceDataView): boolean {
    return new Date(this.date.date).getTime() > new Date(other.date.date).getTime();
  }

  toStoreModel(): ReferenceDataStoreModel {
    return {
      rate: this.rate,
      v: this.version,
      vid: this.vaultId,
      dc: this.dateCreated.toISOString(),
      dm: this.dateModified.toISOString(),
      id: this.id,
    };
  }

  clone(): ReferenceDataView {
    return new ReferenceDataView(this.toStoreModel());
  }

  private castToReferenceDataView(model: ReferenceDataStoreModel): IReferenceData {
    /** @Sinan add a validator for the id to fit in the structure*/

    const idParts = model.id.split("|") as ReferenceIdParts;
    return {
      date: {
        date: idParts[0],
        time: null,
        timeZone: null,
      },
      base: idParts[1],
      symbol: idParts[2],
      rate: Number(model.rate),
    };
  }
}

import { inject, Injectable } from "@angular/core";

import { RoleAccessData, RoleClaim, ScopeValue } from "../../models/data/role-access.data";
import { StateService } from "@bitwarden/web-vault/app/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { LogService } from "@bitwarden/common/abstractions/log.service";

@Injectable({
  providedIn: "root",
})
export class RoleAccessService {
  protected log: LogService = inject(LogService);
  private stateService = inject(StateService);
  private roleAccess: RoleAccessData = new RoleAccessData();

  constructor() {
    this.stateService.activeAccount$
      .pipe(takeUntilDestroyed())
      .subscribe(this.initialise.bind(this));
  }

  async initialise(userId: string) {
    this.log.info(`Session Open for user ${userId}`);
    const roleScope = await this.stateService.getRoleScope();
    const roleClaim = (await this.stateService.getRoleClaim()) as RoleClaim;
    this.setRoleScope(roleScope);
    this.setRoleClaim(roleClaim);
  }

  setRoleScope(roleScope: string) {
    const scope = roleScope.split(" ") as ScopeValue[];

    this.roleAccess.setScope(scope);
  }

  setRoleClaim(roleClaims: RoleClaim) {
    this.roleAccess.setClaims(roleClaims);
  }

  getRoleAccess() {
    return this.roleAccess;
  }

  isBetaUser() {
    this.roleAccess = this.getRoleAccess();
    const scope = this.roleAccess.getScope();
    return scope.includes("BETA_ACCESS");
  }
}

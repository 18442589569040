import { SubscriptionView } from "../../../models/view/subscription/subscription.view";
import { computed, inject, signal } from "@angular/core";
import {
  ExternalCollection,
  FetchParams,
} from "@bitwarden/web-vault/app/services/store/external.collection.abstraction";
import { StateService } from "@bitwarden/web-vault/app/core";

interface SubscriptionFetchParams extends FetchParams {
  userId: string;
}

export class ExternalSubscriptionStoreCollection extends ExternalCollection {
  private legacyAppState = inject(StateService);

  private _subscriptions = signal<SubscriptionView[]>(null);
  readonly subscriptions = this._subscriptions.asReadonly();

  private _activeSubscription = signal<SubscriptionView>(null);
  readonly activeSubscription = this._activeSubscription.asReadonly();

  clear(): void {
    this._subscriptions.set(null);
    this._activeSubscription.set(null);
  }

  fetch(params: SubscriptionFetchParams): Promise<SubscriptionView> {
    return Promise.resolve(undefined);
  }

  protected mapToView(data: any): SubscriptionView {
    const view = new SubscriptionView();
    view.active = data?.active ?? false;
    view.expiration = data?.expiration ?? "";
    view.nextPayment = data?.nextPayment ?? "";
    view.subscription = data?.subscription ?? "Free";
    return Object.freeze(view);
  }

  triggerChanges(): void {
    /** Load legacy token active subscription **/
    this.legacyAppState.getSubscription().then((tokenSubscription) => {
      this._activeSubscription.set(this.mapToView(tokenSubscription));
    });

    /** Fetch user historical subscriptions **/
    this.legacyAppState.getUserId().then((userId) => {
      this.fetch({ userId: userId }).then((subscriptions) => {
        // todo @alex implement
      });
    });
  }
}

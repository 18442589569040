<div class="tw-grid tw-grid-cols-2 tw-gap-12 sm:tw-flex sm:tw-flex-col">
  @for (type of ACCOUNT_TYPES; track type) {
    <tile-button
      class="tw-w-full"
      [title]="type.title | i18n"
      (click)="tileButtonClicked(type.key)"
    >
      <div class="tw-w-full">
        <div class="tw-relative tw-h-10">
          <div
            class="tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-rounded-full tw-shadow-icon tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-overflow-hidden sm:tw-left-auto sm:tw-right-8"
          >
            <icon [name]="type.key + '-account'" [size]="12"></icon>
          </div>
          <div
            class="tw-absolute tw-top-0 tw-left-8 tw-bg-white tw-rounded-full tw-shadow-icon tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center sm:tw-left-auto sm:tw-right-0"
          >
            <icon [name]="'plus-account'" [size]="6"></icon>
          </div>
        </div>
      </div>
    </tile-button>
  }
</div>

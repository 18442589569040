import { Component, EventEmitter, Input, Output } from "@angular/core";

import { InstitutionAccount } from "@bitwarden/web-vault/app/models/types/institution.type";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";

@Component({
  selector: "app-institution",
  templateUrl: "./institution.component.html",
})
export class InstitutionComponent {
  @Input() institution: InstitutionView;
  @Input() preselectedInstitution: InstitutionView;
  @Output() selectedInstitution: EventEmitter<InstitutionView> =
    new EventEmitter<InstitutionView>();

  // institutionAccountSelected(institutionView: InstitutionView) {
  //   if (this.selectedInstitution) {
  //     this.selectedInstitution.emit(institutionView);
  //   }
  // }

  // selectionCleared() {
  // this.selectedInstitution.emit(null);
  // }
}

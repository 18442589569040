import {
  actionButtonOptions,
  amountInputOptions,
  defaultInputOptions,
} from "../../manage-accounts/accounts-add-edit/component.options";
import { FormInterface } from "../../../../models/interfaces/form.interface";
import { BaseForm } from "../../../../shared/form/BaseForm";
import { Injector } from "@angular/core";
import { GlossInputOptions, isOfType } from "../../../../models/types/general-types";
import { HelperNumericInput } from "../../../../shared/utils/helper-numeric-input";
import { Validators } from "@angular/forms";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { ReferenceDataView } from "@bitwarden/web-vault/app/models/view/reference-data/reference-data.view";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import {
  AllowedCurrencies,
  DefaultSystemCurrency,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";
import {
  DefaultReferenceDataStoreModel,
  ReferenceDataStoreModel,
} from "@bitwarden/web-vault/app/models/store/reference-data.store.model";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";

export class SymbolForm extends BaseForm implements FormInterface {
  private transactionStore: TransactionStoreService;
  private isEditMode: boolean = false;
  protected symbol: SymbolView;

  priceInputOptions: GlossInputOptions;
  nameInputOptions: GlossInputOptions;
  saveButtonOptions: ActionButton;

  constructor(
    private injector: Injector,
    isEditMode: boolean,
    symbol: SymbolView,
  ) {
    super(injector);
    this.isEditMode = isEditMode;
    this.transactionStore = this.injector.get(TransactionStoreService);

    this.symbol = symbol;
    this.initialize();
  }

  formToReferenceDate(): ReferenceDataView {
    return new ReferenceDataView();
  }

  initialize() {
    this.formGroup = this.formBuilder.group({
      name: [this.symbol?.code, Validators.required],
      currency: [this.symbol?.c_asOf?.symbol, Validators.required],
      price: [
        this.symbol?.c_asOf?.rate,
        [HelperNumericInput.isNumberValidator, Validators.required],
      ],
      date: [this.symbol?.c_asOf?.date?.date, Validators.required],
    });

    this.nameInputOptions = {
      ...defaultInputOptions,
      label: this.i18nService.t(this.isEditMode ? "name" : "valueOf"),
      placeholder: this.i18nService.t("Enter symbol name"),
      isRequired: true,
      isSameRow: !this.isEditMode,
      isDisabled: this.isEditMode,
      onInput: (event: Event) => this.onInput(event, "name"),
      value: this.formGroup.get("name").value,
    };

    this.priceInputOptions = {
      ...amountInputOptions,
      isRequired: true,
      label: this.i18nService.t("price"),
      onInput: (event: Event) => this.onInputNumeric(event, "price"),
      inputBlurred: () => this.clearDefaultValue("price"),
      value: this.formGroup.get("price").value,
    };

    this.selectCurrency(this.formGroup.get("currency").value);

    this.saveButtonOptions = new ActionButton({
      ...actionButtonOptions,
      onClick: this.submit.bind(this),
    });
  }

  onDateChange(event: string) {
    if (event) {
      this.formGroup.get("date").setValue(event);
    }
  }

  selectCurrency(selectedCurrency: string) {
    if (isOfType(selectedCurrency, AllowedCurrencies)) {
      this.formGroup.get("currency").setValue(selectedCurrency);
    } else {
      this.formGroup.get("currency").setValue(DefaultSystemCurrency);
    }
  }

  private formGroupToView() {
    // todo @jun remove when this is not needed anymore. it should use the view and not the model
    const rdModel: ReferenceDataStoreModel = {
      id: `${this.formGroup.get("date").value}|${this.formGroup.get("name").value}|${this.formGroup.get("currency").value}`,
      rate: this.formGroup.get("price").value,
      v: DefaultReferenceDataStoreModel.v,
      vid: null,
      dc: new Date().toISOString(),
      dm: new Date().toISOString(),
    };
    return new ReferenceDataView(rdModel);
  }

  resetForm() {
    this.saveButtonOptions.enableButton(true);
  }

  async submit() {
    if (!this.formGroup.valid) {
      this.resetForm();
      return;
    }

    const referenceData = this.formGroupToView();
    // todo @marnie - Manage the try catch from the ui perspective
    try {
      await this.transactionStore.referenceData.save(referenceData);
    } catch (e) {
      this.log.error(e);
      this.globalService.showErrorMessage("error", "somethingWentWrong");
    } finally {
      this.resetForm();
    }
  }
}

import { StoreData } from "./store.data";
import { EstimateType } from "../enum/estimateType";
import { TransactionDirection } from "../enum/transactionDirection";
import { Splitcategory } from "../data/splitcategory";
import { Splitclassification } from "../data/splitclassification";
import { RecurringData } from "../data/recurring.data";
import { TransactionTemplateData } from "../data/transaction-template.data";
import { InterestMatchData } from "../data/interest-match.data";
import { BalanceGrouping } from "../../services/DataCalculationService/balanceGrouping/balanceGrouping";
import { BalanceByAccounts } from "../types/balanceGroupingTypes";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";

export type EstimateActionModelVersion = 1;
export interface EstimateActionStoreModel extends StoreData {
  /**  **Version Number** **/
  v: EstimateActionModelVersion;

  /** Estimate Action Type */
  atype: string;

  /** Estimate Action Name */
  n: string;

  /** Estimate Account */
  acc: Book;

  /** Estimate Type */
  etype: EstimateType; // transaction, interest

  /** Estimate Start Date */
  sdate: string;

  /** Estimate Transaction Direction */
  dir: TransactionDirection; //<'in','out'> // the direction of the new transaction

  /** Estimate Description */
  des: string; // the description that the new transaction will have

  /** Estimate Categories */
  cat: Splitcategory[];

  /** Estimate Classifications */
  cl: Splitclassification[];

  /** Estimate Group Id */
  gid: string;

  /** Estimate Recurring Data */
  r: RecurringData; // todo @michelle simplify the object. RecurringIdReference - set to Null if is one off transaction

  /** Estimate Transaction Template Data */
  ttemp: TransactionTemplateData; // todo @michelle simplify the object. TransactionTemplateIDReference - set if transaction type is transaction

  /** Estimate Interest Match Data */
  imatch: InterestMatchData; // todo @michelle simplify the object. InterestMatchIdReference - set if estimateType is interest
}

export const DefaultEstimateActionStoreModel: EstimateActionStoreModel = {
  dc: "",
  dm: "",
  id: "",
  v: 1,
  vid: "",
  atype: "",
  n: "",
  acc: undefined,
  etype: undefined,
  sdate: "",
  dir: undefined,
  des: "",
  cat: [],
  cl: [],
  gid: "",
  r: undefined,
  ttemp: undefined,
  imatch: undefined,
};

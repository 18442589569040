import { inject, Injectable } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";

import { Book } from "../../../models/data/blobby/book.data";
import { Transaction } from "../../../models/data/blobby/transaction.data";
import DateFormat from "../../../shared/utils/helper.date/date-format";
import { BasiqValidator } from "../../../validators/basiq-validator";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { TransactionImportService } from "../../transaction-import.service";
import { BasiqIoConfigService } from "../basiqio-config.service";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { BasiqIoTransactionType } from "@bitwarden/web-vault/app/models/types/basiq-io-transaction.types";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import { ImportStoreService } from "@bitwarden/web-vault/app/services/store/import/import.store.service";

@Injectable({
  providedIn: "root",
})
export class BasiqTransactionProcessingService {
  private transactionsStoreService: TransactionStoreService;
  private importStoreService: ImportStoreService;
  private sortedExistingTransactions: TransactionView[] = [];
  constructor(
    private dataRepositoryService: DataRepositoryService,
    private dateFormat: DateFormat,

    private basiqValidator: BasiqValidator,
    private transactionImportService: TransactionImportService,
    private log: LogService,
    private basiqIoConfigService: BasiqIoConfigService,
  ) {
    this.transactionsStoreService = inject(TransactionStoreService);
    this.importStoreService = inject(ImportStoreService);
  }

  async rawBasiqTransactions(account: AccountView, hasTransactions = false) {
    let lastTransactionDate = null;
    if ((await this.hasTransactions()) || hasTransactions) {
      lastTransactionDate = this.lastTransactionDate(account) || null;
    }

    await this.removePendingTransactions(account);
    return await this.allTransactions(lastTransactionDate, account);
  }

  async validateTransactions(transactions: TransactionView[]): Promise<TransactionView[]> {
    try {
      return transactions.map((transaction) => {
        return this.basiqValidator.validateTransaction(transaction);
      });
    } catch (e) {
      this.log.error(e);
    }
  }

  async addTransaction(transaction: Transaction) {
    await this.transactionImportService.import([transaction], []);
  }

  async removePending(account: AccountView) {
    const transactions = this.transactionsStoreService.enrichTransaction.collection();
    const sourceTransactions = this.importStoreService.sourceTransaction.sourceTransactionViews();
    const pendingTransactions = transactions.filter(
      (transaction: TransactionView) =>
        transaction.definition === "pending" && transaction.accountLink.id === account.id,
    );
    for (const pendingTransaction of pendingTransactions) {
      const sourceTransaction = sourceTransactions.find(
        (sourceTransaction) =>
          sourceTransaction.id === pendingTransaction.sourceLink.id &&
          sourceTransaction.accountId === account.id,
      );
      const isDeleted = await this.transactionsStoreService.transaction.delete(pendingTransaction);
      if (isDeleted) {
        await this.importStoreService.sourceTransaction.delete(sourceTransaction);
      }
    }
  }

  async removePendingTransactions(account: AccountView) {
    return await this.removePending(account);
  }

  async hasTransactions(): Promise<boolean> {
    this.sortedExistingTransactions = this.transactionsStoreService.transaction
      .transactionViews()
      .sort((a, b) => a.transactionDate.getTime() - b.transactionDate.getTime());
    return !!this.sortedExistingTransactions.length;
  }

  /**
   * @deprecated
   * */
  getLastTransactionDate(basiqAccountInGloss: Book) {
    return this.getLastDate(basiqAccountInGloss);
  }

  getLastDate(basiqAccountInGloss: Book | AccountView) {
    const pendingTransactions = this.sortedExistingTransactions.filter(
      (transaction) =>
        transaction.accountId === basiqAccountInGloss.id &&
        transaction.definition === TransactionStatusEnum.pending,
    );
    const postedTransactions = this.sortedExistingTransactions.filter(
      (transaction) =>
        transaction.accountId === basiqAccountInGloss.id &&
        transaction.definition == TransactionStatusEnum.transaction,
    );

    const latestPendingAccountTransaction = pendingTransactions.length
      ? pendingTransactions[pendingTransactions.length - 1]
      : null;

    const latestPostedAccountTransaction = postedTransactions.length
      ? postedTransactions[postedTransactions.length - 1]
      : null;
    const lastPendingDate = latestPendingAccountTransaction
      ? latestPendingAccountTransaction.transactionDate.getTime()
      : 0;

    const lastPostedDate = latestPostedAccountTransaction
      ? latestPostedAccountTransaction.transactionDate.getTime()
      : 0;

    let latestAccountTransaction;

    if (lastPendingDate && lastPostedDate) {
      latestAccountTransaction = Math.min(lastPendingDate, lastPostedDate);
    } else if (lastPendingDate) {
      latestAccountTransaction = lastPendingDate;
    } else if (lastPostedDate) {
      latestAccountTransaction = lastPostedDate;
    }

    return latestAccountTransaction
      ? this.dateFormat.getDateStringFromStamp(latestAccountTransaction)
      : null;
  }

  lastTransactionDate(basiqAccountInGloss: AccountView) {
    return this.getLastDate(basiqAccountInGloss);
  }

  private async allTransactions(lastTransactionsDate: string, account: AccountView) {
    const result: { data: BasiqIoTransactionType[] } = { data: [] };
    let nextLoop = true;
    let nextUrlCall = null;

    while (nextLoop) {
      try {
        const transactionsResponse: any = await this.basiqIoConfigService.getTransactionsFromBasiq(
          lastTransactionsDate,
          account,
          nextUrlCall,
        );

        if (!transactionsResponse) {
          throw new Error("Transactions not retrieved.");
        }

        nextUrlCall = transactionsResponse?.nextPath;
        result.data = result.data.concat(transactionsResponse.transactions);

        nextLoop = nextUrlCall;
      } catch (error) {
        nextLoop = false; // Exit the loop
      }
    }

    return result;
  }
}

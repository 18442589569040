import { inject, Injectable, signal } from "@angular/core";
import { Observable } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";
import { CountryFilter, MainFilter } from "@bitwarden/web-vault/app/models/types/filter.types";
import { FilterControlType } from "@bitwarden/web-vault/app/services/store/calculation/controls/filter.control";
import { ViewsFilterInstitutionHelper } from "@bitwarden/web-vault/app/gloss/views/views-control/views-customize/viewa.filter.helper.institution";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";
import { DataPluginStoreService } from "@bitwarden/web-vault/app/services/store/data-plugin/data-plugin.store.service";

@Injectable({
  providedIn: "root",
})
export class ViewsFilter {
  private userStore = inject(UserStoreService);
  private calculationStore = inject(CalculationStoreService);
  private dataPluginStore = inject(DataPluginStoreService);
  private institutionFilterHelper = inject(ViewsFilterInstitutionHelper);

  accountViews = this.userStore.accounts.accountViews();
  institutionViews = this.userStore.institutions.institutionViews();
  countryViews = this.dataPluginStore.theCountryList.theCountryViews();

  options = this.calculationStore.filters.options();
  selected: FilterControlType = { ...this.calculationStore.filters.selected() };

  filter = signal<MainFilter>(null);
  filterObservable$: Observable<MainFilter> = toObservable(this.filter);

  resetFilter() {
    this.selected.countries = this.options.countries;
    this.selected.institutions = this.options.institutions;
    this.selected.accounts = this.options.accounts;
    this.setFilter();
  }

  setFilter() {
    const filter: MainFilter = new Map();
    for (const countryCode of this.options.countries) {
      const countryView = this.countryViews.find((cv) => cv.code === countryCode);
      const isSelectedCountry = this.selected.countries.includes(countryCode);
      const countryFilter: CountryFilter = {
        countryView,
        institutionFilter: this.institutionFilterHelper.getFilter(this.selected, countryView),
        isDisabled: false,
        isSelected: isSelectedCountry,
      };
      filter.set(countryCode, countryFilter);
    }

    this.filter.set(filter);
  }

  onCountrySelect(selectedCountryCodes: string[]) {
    this.selected.countries = selectedCountryCodes;
    this.setFilter();
  }

  onInstitutionSelect(selectedInstitutionIds: string[]) {
    this.selected.institutions = selectedInstitutionIds;
    this.setFilter();
  }

  onAccountSelect(selectedAccountIds: string[]) {
    this.selected.accounts = selectedAccountIds;
    this.setFilter();
  }
}

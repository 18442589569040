export class HelperCollapse {
  private _isCollapsed = true;
  icon = "up-arrow";

  private setIcon() {
    this.icon = !this.isCollapsed ? "down-arrow" : "up-arrow";
  }

  toggleContent(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.isCollapsed = !this.isCollapsed;
    this.setIcon();
  }

  set isCollapsed(value: boolean) {
    this._isCollapsed = value;
    this.setIcon();
  }

  get isCollapsed(): boolean {
    return this._isCollapsed;
  }
}

import {
  CalculatedBalancesGroupedTwiceArray,
  CalculatedBalanceCollection,
  ScenarioBalanceParams,
} from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";
import { inject, signal } from "@angular/core";
import { LogService } from "@bitwarden/common/abstractions/log.service";

export class ScenarioBalanceByTimeAndAccountsCalculatedCollection extends CalculatedBalanceCollection {
  protected log: LogService = inject(LogService);

  protected type: "ScenarioBalanceByTimeAndAccounts";
  protected calculations: CalculatedBalancesGroupedTwiceArray = [];

  protected _balances = signal<CalculatedBalancesGroupedTwiceArray>([]);
  readonly balances = this._balances.asReadonly();

  async updateCalculations(parameters: ScenarioBalanceParams): Promise<void> {
    const promises: Promise<Record<string, Record<string, number>>>[] = [];
    for (const transactions of parameters.scenarioTransactions) {
      promises.push(
        this.getBalanceByTimeAccounts(
          parameters.webWorkerQueue,
          transactions,
          parameters.period.startDate,
          parameters.period.endDate,
          parameters.group.granularity,
        ),
      );
    }
    await Promise.all(promises).then((results: Record<number, Record<string, number>>[]) => {
      // console.log(results);
      this.setBalances(results);
    });
  }

  getCalculations(): CalculatedBalancesGroupedTwiceArray {
    return this.calculations;
  }

  clear() {
    this.calculations = [];
    this._balances.set(null);
  }

  triggerChanges(): void {}

  private setBalances(
    balancesByTimeAccountsArray: Array<Record<number, Record<string, number>>>,
  ): void {
    this.calculations = [];

    for (const balancesByTimeAccounts of balancesByTimeAccountsArray) {
      const balanceMap = new Map();
      for (const [time, balancesByAccount] of Object.entries(balancesByTimeAccounts)) {
        const balancesByAccountMap = new Map(Object.entries(balancesByAccount));
        balanceMap.set(time, balancesByAccountMap);
      }
      this.calculations.push(balanceMap);
    }

    this._balances.set(this.calculations);
  }
}

import { SETTINGS_PATH } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { ScopeValue } from "@bitwarden/web-vault/app/models/data/role-access.data";

// todo remove this as this shouldn't have "/"
export enum GlossRouteEnum {
  dashboard = "/primary-dashboard",
  cabinet = "/cabinet",
  account = "/manage/account",
  symbol = "/symbol",
  categories = "/manage/categories",
  classifications = "/manage/classifications",
  institutions = "/manage/institutions",
  import = "/import-management",
  reference = "/reference-management",
  estimates = "/estimates",
  preferences = "/preferences",
  subscription = "/settings/subscription",
  sync = "/sync",
}

export enum GlossRoutes {
  Dashboard = "primary-dashboard",
  Cabinet = "cabinet",
  Account = "account",
  Symbol = "symbol",
  Categories = "categories",
  Classifications = "classifications",
  Institutions = "institutions",
  Import = "import-management",
  Reference = "reference-management",
  Estimates = "estimates",
  Preferences = "preferences",
  Subscription = "subscription",
  Sync = "sync",
  Settings = "settings",
  Views = "views",
}

interface NavItem {
  routerLink: string[] | string;
  key: string;
  icon: string;
  label: string;
  currentUrl?: string;
  selectedIcon?: string;
  roleScope?: ScopeValue;
}

export const NavItemsMobile: NavItem[] = [
  {
    key: GlossRoutes.Views,
    routerLink: ["/", GlossRoutes.Views],
    icon: "views",
    label: "sideMenuDashboard",
  },
  {
    key: "account",
    routerLink: ["/manage", "account"],
    icon: "accounts",
    label: "sideMenuAccount",
  },
  {
    key: "cabinet",
    routerLink: ["/", "cabinet"],
    icon: "cabinet",
    label: "sideMenuCabinet",
    roleScope: "GLOSS",
  },
  {
    key: "symbol",
    routerLink: ["/", "symbol"],
    icon: "symbol",
    label: "symbol",
    roleScope: "GLOSS",
  },
  {
    key: "settings",
    routerLink: [SETTINGS_PATH],
    icon: "settings",
    label: "settings",
  },
  {
    key: "help",
    routerLink: "",
    icon: "help",
    selectedIcon: "help-selected",
    label: "help",
  },
];

export const NavItems: NavItem[] = [
  {
    routerLink: ["/", GlossRoutes.Views],
    key: "views",
    icon: "views",
    label: "sideMenuDashboard",
    roleScope: "VIEWS",
  },
  {
    routerLink: ["/manage", "account"],
    key: "accounts",
    icon: "accounts",
    label: "sideMenuAccount",
    roleScope: "GLOSS",
  },
  {
    routerLink: ["/", "symbol"],
    key: "symbol",
    icon: "symbol",
    label: "symbol",
    roleScope: "GLOSS",
  },
  {
    routerLink: ["/", "cabinet"],
    key: "cabinet",
    icon: "cabinet",
    label: "sideMenuCabinet",
    roleScope: "GLOSS",
  },
  {
    routerLink: ["/manage", "categories"],
    key: "categories",
    icon: "categories",
    label: "categories",
    roleScope: "BETA_ACCESS",
  },
  {
    routerLink: ["/manage", "classifications"],
    key: "classifications",
    icon: "classifications",
    label: "classifications",
    roleScope: "BETA_ACCESS",
  },
  {
    routerLink: ["/manage", "institutions"],
    key: "institutions",
    icon: "institutions",
    label: "institutions",
    roleScope: "BETA_ACCESS",
  },
  {
    routerLink: ["/", "import-management"],
    key: "importData",
    icon: "import-data",
    label: "importData",
    roleScope: "BETA_ACCESS",
  },
  {
    routerLink: ["/", "reference-management"],
    key: "reference",
    icon: "reference",
    label: "sideMenuReferenceData",
    roleScope: "EQUITY",
  },
  {
    routerLink: ["/", "estimates"],
    key: "estimate",
    icon: "estimate",
    label: "sideMenuEstimates",
    roleScope: "BETA_ACCESS",
  },
  {
    routerLink: [SETTINGS_PATH],
    currentUrl: SETTINGS_PATH,
    key: "settings",
    icon: "settings",
    label: "settings",
    roleScope: "GLOSS",
  },
];

export enum MainSettingsRoute {
  Profile = "profile",
  Security = "security",
  AdvancedFeatures = "advanced-features",
  Preferences = "preferences",
  Access = "access",
}

export enum SecuritySettingsRoute {
  ChangePassword = "change-password",
  LoginDevices = "login-devices",
}

@if (appState.state().status === "pending") {
  <app-loading-overlay></app-loading-overlay>
}

@if (showWizard()) {
  <div class="wizard">
    <app-account-wizard-stepper></app-account-wizard-stepper>
  </div>
}

<div class="tw-flex tw-w-full tw-h-full tw-bg-tealgray-50">
  @if (deviceDetector.isDesktop()) {
    <div class="tw-h-full tw-overflow-y-auto tw-fixed tw-left-0 tw-top-0 tw-bottom-0 tw-w-48">
      <app-side-menu (openHelpBoxEvent)="openHelpBox()"></app-side-menu>
    </div>
  }
  <main
    class="tw-z-1 tw-flex tw-flex-col tw-justify-between xs:tw-fixed xs:tw-left-0 xs:tw-right-0 tw-w-full tw-min-h-full tw-h-full tw-overflow-y-auto tw-ml-48 sm:tw-ml-0"
  >
    <div
      class="tw-flex tw-flex-col tw-h-auto tw-w-full"
      [ngStyle]="{ 'margin-bottom.px': menuHeight }"
    >
      <app-navbar></app-navbar>
      <router-outlet></router-outlet>
    </div>
    @if (deviceDetector.isDesktop()) {
      <app-footer></app-footer>
    } @else {
      <div>
        <app-menu
          (openHelpBoxEvent)="openHelpBox()"
          (menuHeightEvent)="updateMenuHeight($event)"
        ></app-menu>
      </div>
    }
  </main>

  @if (appState.helpBox().isVisible) {
    <app-help-box
      (changeHelpOpen)="closeHelpBox()"
      class="tw-absolute tw-bottom-0 tw-top-0 tw-z-[2] tw-flex tw-w-full"
    >
    </app-help-box>
  }
</div>

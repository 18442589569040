import { TransactionDescriptionCellRenderer } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/transaction-desc-renderer/transaction-desc-renderer.component";
import { AmountRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/amount-renderer/amount-renderer.component";
import { TransactionTableRowGroupRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/row-group/transaction-table/transaction-table-row-group-renderer.component";
import type { GridOptions, ColDef } from "ag-grid-enterprise";

export const getOptions = (): GridOptions<TableRow> => {
  return {
    rowDragManaged: false,
    columnDefs: getTransactionTableColumns(),
    groupRowRenderer: TransactionTableRowGroupRendererComponent,
    groupDisplayType: "groupRows",
    groupRowRendererParams: {
      suppressCount: true,
      suppressPadding: true,
    },
    onRowClicked: (event: any) => {
      const rowNode = event.node;
      if (rowNode.group) {
        TransactionTableRowGroupRendererComponent.prototype.onExpanded.call(
          { params: { node: rowNode } },
          event.event,
        );
      }
    },
    getRowClass: (params: any) => {
      switch (params.node?.rowGroupColumn?.getColId()) {
        case "institution":
          return "tw-bg-tealgray-50 tw-rounded-xl";
        case "account":
          return "tw-bg-neutral-50";
      }
      return "tw-bg-white";
    },
  };
};

export const getTransactionTableColumns = (): ColDef<TableRow>[] => {
  return [
    { field: "institution", enableRowGroup: true, rowGroup: true, hide: true, flex: 2 },
    { field: "account", rowGroup: true, hide: true, flex: 2 },
    { field: "transactionDate", hide: true, flex: 2 },
    { field: "description", flex: 2, cellRenderer: TransactionDescriptionCellRenderer }, // transactionDate is also rendered here
    { field: "symbol", hide: true, flex: 2 },
    { field: "balance", cellRenderer: AmountRendererComponent }, // balance and symbol are rendered here
  ];
};

export type TableSource = { balance: number; symbol: string; institution: string; account: string };
export type TableRow = TableSource & { transactionDate: string; description: string };

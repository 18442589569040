<div class="tw-w-full tw-flex tw-justify-center balance-hud">
  <div
    class="tw-w-full tw-border tw-border-solid tw-rounded-lg tw-flex tw-flex-col tw-p-2 tw-bg-opacity-[12%] tw-border-accent tw-bg-accent"
  >
    <div class="tw-flex tw-justify-between tw-px-2 tw-py-1">
      <span>{{ balanceDate() | date: "YYYY-MM-dd" }}</span>
    </div>
    <div class="tw-flex tw-flex-col tw-gap-2 tw-rounded-lg">
      <div class="tw-flex tw-gap-1 tw-bg-white" (click)="helperCollapse.toggleContent($event)">
        <ng-container
          class="tw-w-full"
          *ngTemplateOutlet="
            accountBalance;
            context: { account: 'Total Balance', balance: balance() }
          "
        >
        </ng-container>
        <icon [name]="helperCollapse.icon" [size]="7"></icon>
      </div>
      @if (!helperCollapse.isCollapsed) {
        <div class="tw-bg-white">
          @for (account of balanceAccounts(); track account.accountID) {
            <ng-container *ngTemplateOutlet="accountBalance; context: account"></ng-container>
          }
        </div>
      }
    </div>
  </div>
</div>

<ng-template #accountBalance let-account="account" let-balance="balance">
  <div class="tw-w-full tw-flex tw-justify-between tw-px-2 tw-py-1">
    <span>{{ account }}</span>
    <span class="tw-font-medium">{{ balance | currency }}</span>
  </div>
</ng-template>

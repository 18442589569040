import { StoreModelNames } from "../../dali/type/dali.type";
import { SourceTransactionStoreModel } from "../../../models/store/source-transaction.store.model";
import { signal } from "@angular/core";
import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { SourceTransactionsView } from "@bitwarden/web-vault/app/models/view/source-transactions/source-transactions.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class SourceTransactionsStoreCollection extends StoreModelCollection<
  SourceTransactionStoreModel,
  ModelView<SourceTransactionStoreModel>
> {
  readonly type: StoreModelNames = "SourceTransactionStoreModel";
  protected models: Map<string, SourceTransactionStoreModel> = new Map<
    string,
    SourceTransactionStoreModel
  >();

  private _sourceTransactionViews = signal<SourceTransactionsView[]>(null);
  readonly sourceTransactionViews = this._sourceTransactionViews.asReadonly();

  triggerChanges(): void {
    const views: SourceTransactionsView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new SourceTransactionsView(model)) as SourceTransactionsView);
    });
    this._sourceTransactionViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._sourceTransactionViews.set(null);
  }
}

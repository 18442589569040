import { SymbolView } from "./symbol.view";

export function isCurrency(symbols: SymbolView) {
  switch (symbols.code.toUpperCase()) {
    case "DZD": // Algerian Dinar
    case "AOA": // Angolan Kwanza
    case "BIF": // Burundian Franc
    case "XAF": // Central African CFA Franc
    case "KMF": // Comoro Franc
    case "CDF": // Congolese Franc
    case "DJF": // Djiboutian Franc
    case "EGP": // Egyptian Pound
    case "ERN": // Eritrean Nakfa
    case "ETB": // Ethiopian Birr
    case "GHS": // Ghanaian Cedi
    case "KES": // Kenyan Shilling
    case "LSL": // Lesotho Loti
    case "LRD": // Liberian Dollar
    case "LYD": // Libyan Dinar
    case "MGA": // Malagasy Ariary
    case "MWK": // Malawian Kwacha
    case "MAD": // Moroccan Dirham
    case "MZN": // Mozambican Metical
    case "NAD": // Namibian Dollar
    case "NGN": // Nigerian Naira
    case "RWF": // Rwandan Franc
    case "STN": // São Tomé and Príncipe Dobra
    case "SCR": // Seychellois Rupee
    case "SLE": // Sierra Leonean Leone
    case "SOS": // Somali Shilling
    case "ZAR": // South African Rand
    case "SSP": // South Sudanese Pound
    case "SDG": // Sudanese Pound
    case "SZL": // Swazi Lilangeni
    case "TZS": // Tanzanian Shilling
    case "TND": // Tunisian Dinar
    case "UGX": // Ugandan Shilling
    case "ZMW": // Zambian Kwacha
    case "ZWL": // Zimbabwean Dollar /** America **/
    case "ARS": // Argentine Peso
    case "BSD": // Bahamian Dollar
    case "BBD": // Barbados Dollar
    case "BZD": // Belize Dollar
    case "BMD": // Bermudian Dollar
    case "BOB": // Bolivian Boliviano
    case "BRL": // Brazilian Real
    case "CAD": // Canadian Dollar
    case "CLP": // Chilean Peso
    case "COP": // Colombian Peso
    case "CRC": // Costa Rican Colón
    case "CUP": // Cuban Peso
    case "DOP": // Dominican Peso
    case "USD": // United States Dollar
    case "GTQ": // Guatemalan Quetzal
    case "GYD": // Guyanese Dollar
    case "HTG": // Haitian Gourde
    case "HNL": // Honduran Lempira
    case "JMD": // Jamaican Dollar
    case "MXN": // Mexican Peso
    case "NIO": // Nicaraguan Córdoba
    case "PAB": // Panamanian Balboa
    case "PYG": // Paraguayan Guaraní
    case "PEN": // Peruvian Sol
    case "TTD": // Trinidad and Tobago Dollar
    case "UYU": // Uruguayan Peso /** Asia **/
    case "AFN": // Afghan Afghani
    case "AMD": // Armenian Dram
    case "AZN": // Azerbaijani Manat
    case "BHD": // Bahraini Dinar
    case "BDT": // Bangladeshi Taka
    case "BTN": // Bhutanese Ngultrum
    case "BND": // Brunei Dollar
    case "KHR": // Cambodian Riel
    case "CNY": // Chinese Yuan
    case "GEL": // Georgian Lari
    case "HKD": // Hong Kong Dollar
    case "INR": // Indian Rupee
    case "IDR": // Indonesian Rupiah
    case "IRR": // Iranian Rial
    case "IQD": // Iraqi Dinar
    case "ILS": // Israeli New Shekel
    case "JPY": // Japanese Yen
    case "JOD": // Jordanian Dinar
    case "KZT": // Kazakhstani Tenge
    case "KPW": // North Korean Won
    case "KRW": // South Korean Won
    case "KWD": // Kuwaiti Dinar
    case "KGS": // Kyrgyzstani Som
    case "LAK": // Lao Kip
    case "LBP": // Lebanese Pound
    case "MOP": // Macanese Pataca
    case "MYR": // Malaysian Ringgit
    case "MVR": // Maldivian Rufiyaa
    case "MNT": // Mongolian Tögrög
    case "MMK": // Myanmar Kyat
    case "NPR": // Nepalese Rupee
    case "OMR": // Omani Rial
    case "PKR": // Pakistani Rupee
    case "PHP": // Philippine Peso
    case "QAR": // Qatari Riyal
    case "SAR": // Saudi Riyal
    case "SGD": // Singapore Dollar
    case "LKR": // Sri Lankan Rupee
    case "SYP": // Syrian Pound
    case "TWD": // New Taiwan Dollar
    case "TJS": // Tajikistani Somoni
    case "THB": // Thai Baht
    case "TRY": // Turkish Lira
    case "AED": // United Arab Emirates Dirham
    case "UZS": // Uzbekistan Som
    case "VND": // Vietnamese
    case "YER": // Yemeni Rial
    case "ALL": // Albanian Lek /** Europe**/
    case "BYN": // Belarusian Ruble
    case "BAM": // Bosnian Convertible Mark
    case "BGN": // Bulgarian Lev
    case "HRK": // Croatian Kuna
    case "CZK": // Czech Koruna
    case "DKK": // Danish Krone
    case "EUR": // Euro
    case "GBP": // British Pound Sterling
    case "HUF": // Hungarian Forint
    case "ISK": // Icelandic Króna
    case "MDL": // Moldovan Leu
    case "MKD": // Macedonian Denar
    case "NOK": // Norwegian Krone
    case "PLN": // Polish Złoty
    case "RON": // Romanian Leu
    case "RUB": // Russian Ruble
    case "RSD": // Serbian Dinar
    case "SEK": // Swedish Krona
    case "CHF": // Swiss Franc
    case "UAH": // Ukrainian Hryvnia
    case "AUD": // Australian Dollar /** Oceania **/
    case "FJD": // Fijian Dollar
    case "NZD": // New Zealand Dollar
    case "PGK": // Papua New Guinean Kina
    case "TOP": // Tongan Paʻanga
    case "WST": // Samoan
      return true;

    default:
      return false;
  }
}

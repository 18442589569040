import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import {
  BalanceByAccounts,
  BalanceByAccountsSymbols,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";

import { AccountCreationAction } from "../data/possible-estimate-actions/account-creation.action";
import { InterestAction } from "../data/possible-estimate-actions/interest-action";
import { TransactionTransferAction } from "../data/possible-estimate-actions/transaction-transfer.action";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { InstitutionAccountView } from "@bitwarden/web-vault/app/models/view/institution/institution-account.view";
import { InstitutionInterestView } from "@bitwarden/web-vault/app/models/view/institution/institution-interest.view";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";

export const EstimateActionsTypeClasses = {
  accountCreation: AccountCreationAction,
  transactionTransfer: TransactionTransferAction,
  interestCreation: InterestAction,

  // interest = "interest-action" /** Interest calculation*/,
  // transactionTemplate = "transaction-template" /** For a specific template: When there is one we should change name accordingly*/,
  // recurringFixed = "recurring-fixed" /** Calculating the same transaction for the given period */,
  // nonRecurring = "non-recurring" /** Generating only one transaction. Can be like recurringFixed with both startDate and endDate being the same */,
};

export type EstimateActionsTypeProperties = typeof EstimateActionsTypeClasses;
export type EstimateActionsType = keyof EstimateActionsTypeProperties; // accountCreation | transactionTransfer

export const accountCreation = "accountCreation" as EstimateActionsType;
export const transactionTransfer = "transactionTransfer" as EstimateActionsType;
export const interestCreation = "interestCreation" as EstimateActionsType;

// Types relating to input and output of particular estimate actions:
export type AccountCreationParameters = {
  accountName: string; // account we are creating
  currency: string; // default account currency
  institution: InstitutionView; // list of institutions
  newInstitutionalAccount: InstitutionAccountView; // list of new institutional accounts
};

export type AccountCreationOutput = {
  accounts: Array<AccountView>;
};

export type InterestParameters = {
  account: AccountView; // account we are creating interest from
  accountBalances?: BalanceByAccounts; // grouping balance up until the startDate of interest calculations
  currency: string; // currency we are calculating interest on
  startDate: Date; // starting date of interest calculations
  endDate: Date; // ending date of interest calculations
  interestRates: InstitutionInterestView[]; // set of interest rates that we are applying
  userGeneratedEstimateTransactions: Array<TransactionView>; // set of transactions created from other user generated estimates
  baseCurrency: string;
  referenceData: ReferenceData[];
  startingTransactions?: Array<TransactionView>;
  defaultSplitClassification?: SplitClassificationType[];
  defaultSplitCategory?: SplitCategoryType[];
};

export type InterestOutput = {
  transactions: Array<TransactionView>;
  runningAccountBalances: BalanceByAccountsSymbols;
};

export type TransferParameters = {
  accountFrom: AccountView; // account we are creating the transfer from
  accountTo: AccountView; // account we are creating the transfer to
  transactionDate: Date; // transaction date
  amount: number;
  symbol: string;
  baseCurrency: string;
  referenceData: ReferenceData[];
  defaultSplitClassification?: SplitClassificationType[];
  defaultSplitCategory?: SplitCategoryType[];
};

export type TransferOutput = {
  transactions: Array<TransactionView>;
  runningAccountBalances: BalanceByAccountsSymbols;
};

export type InstitutionAccountGroup = {
  institutionsMap: Map<string, InstitutionView>;
  newInstitutionalAccounts: InstitutionAccountView[];
};

import { ChangeDetectionStrategy, Component, computed, inject } from "@angular/core";
import { WidgetMenuType } from "../../../models/types/widget.types";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";
import { BalanceDatesUtils } from "@bitwarden/web-vault/app/services/store/calculation/balances/balanceDates.utils";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-current-balance",
  templateUrl: "./current-balance.html",
  providers: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentBalanceComponent {
  protected readonly Number = Number;
  protected userStore = inject(UserStoreService);
  protected calculatedStore = inject(CalculationStoreService);
  protected isEyeOpen = true;
  protected balanceWidgetMenu = {
    type: "text" as WidgetMenuType,
    options: [
      {
        displayValue: "Balance",
        value: "balance",
        isSelected: false,
      },
    ],
  };

  getCurrencySymbol = computed(
    () => this.userStore.preferences.preferenceView()?.baseCurrency || "",
  );

  balance = computed(() => {
    const currentTime = BalanceDatesUtils.dateToTimestamp(
      this.calculatedStore.period.selected()?.endDate,
    );
    return this.calculatedStore.balanceByTime.balances()?.get(currentTime.toString()) || 0;
  });

  protected toggleEye() {
    this.isEyeOpen = !this.isEyeOpen;
  }

  handleBalanceWidget(value: string) {
    // console.log(value);
  }
}

import { StoreData } from "./store.data";
import { ScenarioOptionsType } from "../types/scenario-group.types";
import { EstimateActionsEnum } from "../enum/estimate-actions.enum";

export type ScenarioModelVersion = 1;
/** Represents the store model for a scenario */
export interface ScenarioStoreModel extends StoreData {
  /**  **Version Number** **/
  v: ScenarioModelVersion;

  /** name */
  nm: string;
  /** symbol */
  sym: string;
  /** scenarioType */
  scnT: ScenarioOptionsType;
  /** source */
  src: string;
  /** estimateActionsId */
  eAId: string[];
  /** estimateActionTypes */
  eATp: EstimateActionsEnum[];
}

/** Default values for the scenario store model */
export const DefaultScenarioStoreModel: ScenarioStoreModel = {
  nm: "",
  sym: "",
  scnT: null,
  src: "",
  eAId: [],
  eATp: [],
  v: 1,
  vid: "",
  dc: "",
  dm: "",
  id: "",
};

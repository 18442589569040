import { VaultFile } from "../../data/blobby/vault-file.data";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import {
  DefaultVaultFileStoreModel,
  VaultFileModelVersion,
  VaultFileStoreModel,
} from "@bitwarden/web-vault/app/models/store/vault-file.store.model";
import {
  CabinetFileType,
  VaultFileStatus,
} from "@bitwarden/web-vault/app/models/types/cabinet.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";

interface encryptedKeyJSON {
  keyB64: string;
}

export class VaultFileView extends ModelView<VaultFileStoreModel> {
  protected version: VaultFileModelVersion;

  encryptedKey: encryptedKeyJSON;
  name: string;
  status: VaultFileStatus;
  mimeType: CabinetFileType;
  statementToDate: string;
  statementFromDate: string;
  statementAccounts: AccountView[]; // todo use LinkedViewProperty
  banks: InstitutionView[]; // todo use LinkedViewProperty
  accountIds: string[];
  fileContent: string;
  /** @deprecated **/
  origin: VaultFile; // todo use LinkedViewProperty or remove

  constructor(model?: VaultFileStoreModel) {
    super(model);
    if (model) {
      this.name = model.nm;
      this.statementFromDate = new Date(model.sfd).toISOString();
      this.statementToDate = new Date(model.std).toISOString();
      this.encryptedKey = model.encK;
      this.accountIds = model.sa;
    } else {
      this.version = DefaultVaultFileStoreModel.v;
    }
  }

  toStoreModel(): VaultFileStoreModel {
    return {
      mt: this.mimeType,
      stat: this.status,
      id: this.id,
      vid: this.vaultId,
      v: this.version,
      dc: this.dateCreated.toISOString(),
      dm: this.dateModified.toISOString(),
      nm: this.name,
      sfd: new Date(this.statementFromDate),
      std: new Date(this.statementToDate),
      sa: this.accountIds,
      encK: this.encryptedKey,
    };
  }

  clone(): VaultFileView {
    return new VaultFileView(this.toStoreModel());
  }
}

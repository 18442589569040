<div class="tw-flex tw-h-full tw-items-center tw-justify-end">
  <div class="tw-flex tw-h-full tw-flex-col tw-items-end tw-justify-center">
    <span
      [ngClass]="{ 'tw-text-success': rate > 0, 'tw-text-danger': rate&lt;= 0 }"
      class="tw-block tw-text-sm tw-text-neutral-800 tw-text-success"
    >
      {{ rate }}
    </span>
    <span class="tw-text-xs tw-font-normal tw-text-neutral-600">{{ symbol }}</span>
  </div>
</div>

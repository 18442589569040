<div class="step-holder">
  <div>
    <span [innerHTML]="'fulFillConditionsDescription' | i18n" class="step-description"></span>
    <div *ngIf="rateInUse" class="section-gap">
      <span class="section-header">{{ "fulFillTheseConditions" | i18n }}</span>
      <app-accordion
        collapseIcon="minimize"
        expandIcon="maximize"
        customClass="tw-bg-neutral-50"
        [contentTemplate]="fulFillConditions"
      >
        <app-best-account-tooltip title [lastViewDate]="rateInUse.last_updated">
          <app-best-scenario-dialog-title [isPrimary]="false">
            {{ "highestInterestBankAccount" | i18n }}
          </app-best-scenario-dialog-title>

          <app-best-scenario-dialog-title class="tw-mr-6 xs:tw-mr-3">
            {{ bestAccountInfo.accountName }}
          </app-best-scenario-dialog-title>
        </app-best-account-tooltip>
      </app-accordion>

      <ng-template #fulFillConditions>
        <div class="accounts-container tw-m-3 tw-rounded-lg tw-bg-tealgray-50 tw-p-3">
          <span class="accordion-list-item">
            <span class="accordion-list-item-bullet"></span>
            {{ rateInUse.condition_intrinsic_desc }}
          </span>
          <span class="accordion-list-item">
            <span class="accordion-list-item-bullet"></span>
            {{ rateInUse.condition_action_desc }}
          </span>
        </div>
      </ng-template>
    </div>

    <div *ngIf="earningsCard" class="section-gap">
      <best-scenario-stepper-earnings-card
        [earningsData]="earningsCard"
      ></best-scenario-stepper-earnings-card>
    </div>
  </div>
  <div class="secondary-buttons-holder">
    <app-gloss-button class="tw-w-full" [options]="viewOtherAccounts"></app-gloss-button>
  </div>
</div>

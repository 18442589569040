import { StoreModelCollection } from "../model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { signal } from "@angular/core";
import { EstimateStoreModel } from "../../../models/store/estimate.store.model";
import { EstimateView } from "@bitwarden/web-vault/app/models/view/estimate/estimate.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class EstimateStoreCollection extends StoreModelCollection<
  EstimateStoreModel,
  ModelView<EstimateStoreModel>
> {
  readonly type: StoreModelNames = "EstimateStoreModel";
  protected models: Map<string, EstimateStoreModel> = new Map<string, EstimateStoreModel>();

  private _estimateViews = signal<EstimateView[]>([]);
  readonly estimateView = this._estimateViews.asReadonly();

  triggerChanges(): void {
    const views: EstimateView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new EstimateView(model)) as EstimateView);
    });
    this._estimateViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._estimateViews.set(null);
  }
}

import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { CategoryStoreModel } from "../../../../models/store/category.store.model";
import { CategoryResponse } from "../../../../models/data/response/category.response";
import { Category } from "../../../../models/data/blobby/category.data";
import { category_v0_to_v1 } from "../model-dto/category";
import { SourceAccountStoreModel } from "../../../../models/store/source-account-store.model";
import { SourceBook } from "../../../../models/data/blobby/source-book";
import { sourceBook_v0_to_v1 } from "../model-dto/source-book";

export class SourceBookParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Category parser version ${version}`);
    }

    if (version === 0) {
      return SourceBookParser_v0;
    } else {
      return SourceBookParser_v1;
    }
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const SourceBookParser_v1 = (sourceBookObj: any): any => sourceBookObj as SourceAccountStoreModel;
const SourceBookParser_v0 = (sourceBookObj: any): any => {
  const sourceBook = new SourceBook(sourceBookObj);
  const upgraded = sourceBook_v0_to_v1(sourceBook);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = sourceBookObj.vid ?? "";
  return SourceBookParser_v1(upgraded);
};

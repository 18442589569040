import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { vaultFile_v0_to_v1 } from "../model-dto/vault-file";
import { VaultFileResponse } from "../../../../models/data/response/vault-file.response";
import { VaultFile } from "../../../../models/data/blobby/vault-file.data";
import { TransactionStoreModel } from "@bitwarden/web-vault/app/models/store/transaction.store.model";

export class VaultFileParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported VaultFile parser version ${version}`);
    }

    if (version === 0) {
      return VaultFileParser_v0;
    }
    return VaultFileParser_v1;
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}
const VaultFileParser_v1 = (vaultItem: any): TransactionStoreModel =>
  vaultItem as TransactionStoreModel;
const VaultFileParser_v0 = (vaultItem: any): TransactionStoreModel => {
  const response = new VaultFileResponse(vaultItem);
  const vaultFile = new VaultFile(response);
  const upgraded = vaultFile_v0_to_v1(vaultFile);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = vaultItem.vid ?? "";
  return VaultFileParser_v1(upgraded);
};

import { Component, inject, Inject, Injector, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import _ from "lodash";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { AccountBalanceComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/account-balance/account-balance.component";
import {
  accountInputOptions,
  actionButtonOptions,
  institutionInputOptions,
} from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/accounts-add-edit/component.options";
import {
  GlossInputOptions,
  isOfType,
  Origin,
} from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionAccount } from "@bitwarden/web-vault/app/models/types/institution.type";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { AccountManualType } from "@bitwarden/web-vault/app/models/types/account.types";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";
import { EstimateActionsService } from "@bitwarden/web-vault/app/services/DataService/estimates/estimate-actions-service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";

import { Book } from "../../../../models/data/blobby/book.data";

import { BookService } from "../../../../services/DataService/book/book.service";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { InstitutionAccountStoreModel } from "@bitwarden/web-vault/app/models/store/institution.store.model";
import { InstitutionAccountView } from "@bitwarden/web-vault/app/models/view/institution/institution-account.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { CategoryView } from "@bitwarden/web-vault/app/models/view/category/category.view";
import { ClassificationView } from "@bitwarden/web-vault/app/models/view/classification/classification.view";
import {
  DefaultCurrencyPerLocation,
  UserLocationType,
  AllowedLocations,
  DefaultSystemCurrency,
  CurrencyType,
  AllowedCurrencies,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";
import { removeSpace } from "@bitwarden/web-vault/app/shared/utils/helper-string";

@Component({
  selector: "account-add-edit",
  templateUrl: "account-add-edit.component.html",
})
export class AccountAddEditComponent implements OnInit {
  readonly INVALID_FORM = "INVALID";
  baseCurrency: CurrencyType;
  form: FormGroup;
  accountName: string;
  accountId: string;
  cat: string;
  categories: Array<CategoryView>;
  accountBalance: number;
  currency: CurrencyType;
  classifications: Array<ClassificationView>;
  institutions: Array<InstitutionView>;
  accountTypes: Array<InstitutionAccountView>;
  accountManualType: AccountManualType;

  isEditMode = false;
  isUserInput = true;
  isInstitutionDisabled = false;
  isCategoryOpen = false;
  isClassificationOpen = false;
  title: string;
  selectedClassifications: SplitClassificationType[] = [];
  selectedCategories: SplitCategoryType[] = [];
  selectedInstitution: InstitutionView;
  selectedAccountType: InstitutionAccountView;
  accountBalanceRef: MatDialogRef<AccountBalanceComponent>;

  private userStore = inject(UserStoreService);

  private classificationService: ClassificationService;
  private categoryService: CategoryService;

  saveButtonOptions: ActionButton;
  accountInputOptions: GlossInputOptions = {
    ...accountInputOptions,
    inputBlurred: (value: string) => this.accountNameSet(value),
    inputCleared: () => this.nameCleared(),
    onInput: (event: Event) => {
      if (this.isUserInput) {
        this.isUserInput = false;
      }
      if (this.form.status === this.INVALID_FORM) {
        this.saveButtonOptions.enableButton(true);
      }
    },
  };
  setBalanceButtonOptions: ActionButton;
  addTransactionButtonOptions: ActionButton;

  institutionInputOptions: GlossInputOptions = institutionInputOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      account: AccountView;
      actionSucceeded: CallableFunction;
      delete: CallableFunction;
      institution: InstitutionView;
      // accountType: InstitutionAccountStoreModel;
      closeDialogue: CallableFunction;
      accountCurrency: string;
      openInstitutionSelectionModal: CallableFunction;
      isWizard: boolean;
      accountType: AccountManualType;
    },
    private bookService: BookService,
    private estimateService: EstimateActionsService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
    private institutionService: InstitutionService,
    private injector: Injector,
    private dialog: MatDialog,
    private preferenceService: PreferenceService,
    private i18nService: I18nService,
  ) {
    this.isEditMode = !!this.data.account;
    this.selectedInstitution = this.data?.institution;
    this.institutionInputOptions.value = this.selectedInstitution?.name;
    this.accountManualType = this.data.accountType;

    const buttonText = this.data.isWizard || this.isEditMode ? "save" : "next";
    this.saveButtonOptions = new ActionButton({
      ...actionButtonOptions,
      text: this.i18nService.t(buttonText),
      onClick: this.submit.bind(this),
    });
    this.setBalanceButtonOptions = new ActionButton({
      type: "submit",
      text: this.i18nService.t("setBalance"),
      icon: "add",
      class: "neutral",
    });
    this.addTransactionButtonOptions = new ActionButton({
      type: "submit",
      text: this.i18nService.t("addTransaction"),
      icon: "add",
      class: "neutral",
    });
  }

  async ngOnInit() {
    const preference = this.userStore.preferences.preferenceView();
    this.baseCurrency = preference.baseCurrency;

    await this.setForm();
    this.setInstitution();
    this.initializeForm();
  }

  async setForm() {
    if (this.isEditMode) {
      await this.setFormToUpdate();
    } else {
      await this.setFormToCreate();
    }
  }

  async setFormToUpdate() {
    this.title = "Edit account";
    await this.getData();
    this.accountInputOptions = { ...this.accountInputOptions, value: this.data.account.name };
    this.institutionInputOptions = {
      ...this.institutionInputOptions,
      value: this.data.account.institution.name,
    };
    this.selectedInstitution = this.data.account.institution;
    this.selectedAccountType = this.data.account.institutionAccountType;
    this.accountName = this.data.account.name;
    this.accountTypes = this.selectedInstitution.availableAccounts;
    this.selectedCategories = this.data.account.defaultCategories;
    this.selectedClassifications = this.data.account.defaultClassifications;
    this.currency = this.data.account.currency;
  }

  async setFormToCreate() {
    this.title = "Add Account";
    this.selectedInstitution = this.data.institution;
    this.accountTypes = this.selectedInstitution.availableAccounts;
    this.accountInputOptions.value = "";
    this.accountInputOptions.placeholder = `My ${this.selectedInstitution.name} Account`;
    this.institutionInputOptions.value = this.selectedInstitution.name;

    const countryCode = this.data.institution.swift.countryCode;

    if (isOfType<UserLocationType>(countryCode, AllowedLocations)) {
      this.currency = DefaultCurrencyPerLocation[countryCode];
    } else {
      this.currency = DefaultSystemCurrency;
    }

    this.isInstitutionDisabled = true;
    await this.getData();
  }

  async getData() {
    // this.categories = await this.dataRepositoryService.getAllCategories();
    // this.classifications = await this.dataRepositoryService.getAllClassifications();
    this.categories = this.userStore.categories.categoryViews();
    this.classifications = this.userStore.classifications.classificationViews();
    /* this.institutions = await this.dataRepositoryService.getAllInstitutions();*/
    this.institutions = this.userStore.institutions.institutionViews();
  }

  setInstitution() {
    if (this.isEditMode) {
      this.selectedInstitution = this.data.account.institution;
      this.accountTypes = this.selectedInstitution.availableAccounts;
      this.selectedAccountType = this.data.account.institutionAccountType;
    } else {
      this.selectedInstitution = this.data.institution;
      this.accountTypes = this.selectedInstitution.availableAccounts;
    }
  }

  onBackClicked() {
    if (this.data.closeDialogue && this.data.openInstitutionSelectionModal) {
      this.data.closeDialogue(true);
      this.data.openInstitutionSelectionModal();
    } else {
      /*TODO tell user to start over instead of saying something went wrong!!*/
      this.globalService.showWarningMessage("warning", "somethingWentWrong");
    }
  }

  private getEmptyFormControls(): any {
    const { origin } = this.data.account ?? { origin: "manual" };
    const isDisabled = origin !== Origin.manual;

    return {
      accountName: [{ value: "", disabled: isDisabled }, Validators.required],
      selectedCategory: [{ value: null }],
      selectedClassification: [{ value: null }],
      selectedInstitution: [{ value: this.selectedInstitution, disabled: isDisabled }],
      selectedAccountType: null,
      categories: null,
      classifications: null,
    };
  }

  private filledFormControls(): any {
    const {
      origin,
      name,
      defaultClassifications,
      defaultCategories,
      institution,
      institutionAccountType,
    } = this.data.account;
    const isDisabled = origin !== Origin.manual;
    return {
      accountName: [{ value: name, disabled: isDisabled }, Validators.required],
      selectedCategory: defaultCategories,
      selectedClassification: defaultClassifications,
      selectedInstitution: [{ value: institution, disabled: isDisabled }],
      selectedAccountType: institutionAccountType,
      categories: null,
      classifications: null,
    };
  }

  private getFormControls() {
    if (this.isEditMode) {
      return this.filledFormControls();
    } else {
      return this.getEmptyFormControls();
    }
  }

  initializeForm() {
    const formControls = this.getFormControls();
    this.form = this.formBuilder.group(formControls);

    this.categories.forEach((cat) => {
      this.form.addControl(`category_${cat.id}`, new FormControl(0));
    });

    this.classifications.forEach((cat) => {
      this.form.addControl(`classification_${cat.id}`, new FormControl(0));
    });
  }

  accountNameSet(name: string) {
    this.accountName = name;
  }

  nameCleared() {
    this.accountName = null;
  }

  async submit() {
    if (!this.accountName || this.accountName === "") {
      this.globalService.showErrorMessage("errorOccurred", "accountNameEmpty");
      this.saveButtonOptions.enableButton(true);
      return;
    }

    if (!this.selectedAccountType) {
      this.globalService.showErrorMessage("errorOccurred", "accountTypeEmpty");
      this.saveButtonOptions.enableButton(true);
      return;
    }

    /// todo add app-state
    if (this.isEditMode) {
      await this.updateAccount();
    } else {
      await this.createAccount();
    }

    this.saveButtonOptions.enableButton(true);
    /// todo remove add app-state
  }

  private async createAccount() {
    if (this.isBookExisted(this.accountName)) {
      return;
    }
    try {
      const isInstitutionAccount = await this.institutionService.isInstitutionExist(
        this.selectedInstitution,
      );

      if (!isInstitutionAccount) {
        this.selectedInstitution.availableAccounts.forEach((acc) => {
          if (acc.id === this.selectedAccountType.id) {
            acc.interestRates.forEach((ir) => {
              ir.symbol = this.currency;
            });
          }
        });
        await this.institutionService.create(this.selectedInstitution);
      } else {
        this.selectedInstitution.availableAccounts.forEach((acc) => {
          if (acc.id === this.selectedAccountType.id) {
            acc.interestRates.forEach((ir) => {
              ir.symbol = this.currency;
            });
          }
        });

        await this.institutionService.updateInstitutionView(this.selectedInstitution);
      }

      const accountViewModel = new AccountView();
      accountViewModel.name = this.accountName;
      accountViewModel.currency = this.currency;
      accountViewModel.type = this.accountManualType;

      /** todo : if the book-renderer, aka newBook,  has a preferred institution account then link it as well */
      accountViewModel.institutionLink = {
        institutionId: this.selectedInstitution.id,
        institutionAccountId: this.selectedAccountType ? this.selectedAccountType.id : null,
      };

      if (!accountViewModel?.currency) {
        accountViewModel.currency = this.baseCurrency;
      }

      const newAccountView = new AccountView(
        this.bookService.toAccountStoreModel(accountViewModel, this.selectedInstitution),
      );

      const createdAccount = await this.userStore.accounts.save(newAccountView);

      if (createdAccount) {
        await this.estimateService.generateAutoBookEstimates(newAccountView);
        this.data.actionSucceeded("createdSuccessfully");
        this.data.closeDialogue();
        if (!this.data.isWizard) {
          this.openAccountBalanceDialog(newAccountView);
        }
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  isBookExisted(accountName: string): boolean {
    const existingBooks = this.userStore.accounts.accountViews();
    const elementExists = existingBooks.some(
      (b) => removeSpace(b.name) === removeSpace(accountName),
    );

    if (elementExists) {
      this.globalService.throwBookExistsError();
      return true;
    }
    return false;
  }

  private openAccountBalanceDialog(accountView: AccountView) {
    this.accountBalanceRef = this.dialog.open(AccountBalanceComponent, {
      panelClass: "no-background-dialog",
      data: {
        accountView: accountView,
        actionSucceeded: this.data.actionSucceeded.bind(this),
        closeDialogue: this.closeAccountBalanceDialog.bind(this),
      },
      disableClose: true,
    });
  }

  closeAccountBalanceDialog() {
    this.accountBalanceRef.close();
  }

  private async updateAccount() {
    try {
      const clonedAccount = _.cloneDeep(this.data.account.originalBook);
      clonedAccount.name = this.accountName;
      clonedAccount.institutionLink = {
        institutionId: this.selectedInstitution.id,
        institutionAccountId: this.selectedAccountType ? this.selectedAccountType.id : null,
      };

      const updatedAccount = await this.bookService.update(clonedAccount, false);

      /** Account validation failed */
      if (!updatedAccount) {
        this.form.setErrors({ INVALID: true });
      }

      if (updatedAccount instanceof Book) {
        this.data.actionSucceeded("updatedSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async deleteAccount() {
    // TODO check if it is a callable
    this.data.delete(this.data.account.originalBook);
  }

  selectCurrency(currency: string) {
    if (isOfType<CurrencyType>(currency, AllowedCurrencies)) {
      this.currency = currency;
    } else {
      this.globalService.showErrorMessage("errorOccurred", "invalidCurrency");
      return;
    }
  }

  onInstitutionSelect(selected: InstitutionView) {
    this.selectedInstitution = selected;
    this.selectedAccountType = null;
    this.accountTypes = this.selectedInstitution.availableAccounts;
  }

  onSelectAccountType(selected: InstitutionAccount) {
    this.selectedAccountType = selected;
    if (this.isUserInput) {
      this.accountInputOptions = {
        ...this.accountInputOptions,
        placeholder: `My ${selected.name}`,
      };
    }
  }

  openCategoriesPanel() {
    this.isCategoryOpen = !this.isCategoryOpen;
    this.isClassificationOpen = false;
    if (!this.categories.length) {
      this.globalService.showMessage("info", "categoriesEmpty", "pleaseAddSomeCategories");
    }
  }

  openClassificationsPanel() {
    this.isClassificationOpen = !this.isClassificationOpen;
    this.isCategoryOpen = false;
    if (!this.classifications.length) {
      this.globalService.showMessage(
        "info",
        "classificationsEmpty",
        "pleaseAddSomeClassifications",
      );
    }
  }

  /**
   * Get or create a general default classification to assign to as the default classification
   * for this account that is being created
   */
  async setGeneralDefaultClassification(): Promise<SplitClassificationType[]> {
    this.classificationService = this.injector.get(ClassificationService);
    const defaultClassification =
      await this.classificationService.getGeneralDefaultClassification();
    const defaultSplitClassifications: SplitClassificationType[] = [];
    defaultSplitClassifications.push({
      classificationId: defaultClassification.id,
      weight: 1,
      roundingDefault: true,
      name: defaultClassification.name,
    });
    return defaultSplitClassifications;
  }

  /**
   * Get or create a general default classification to assign to as the default classification
   * for this account that is being created
   */
  async setGeneralDefaultCategories(): Promise<SplitCategoryType[]> {
    this.categoryService = this.injector.get(CategoryService);
    const defaultCategory = await this.categoryService.getGeneralDefaultCategory();
    const defaultSplitCategories: SplitCategoryType[] = [];
    defaultSplitCategories.push({
      categoryId: defaultCategory.id,
      weight: 1,
      roundingDefault: true,
      name: defaultCategory.name,
    });
    return defaultSplitCategories;
  }

  protected readonly Origin = Origin;
  protected readonly process = process;
}

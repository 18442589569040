import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";
import { InterestRateScenarioOptionUtils } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/interest-rate-scenario-option.utils";
import { ScenarioOption } from "./scenario-option";
import { Injector } from "@angular/core";
import { ScenarioGroupView } from "@bitwarden/web-vault/app/models/view/scenario-group/scenario-group.view";
import { BalanceByAccountsSymbols } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";

export class InterestRateScenarioOption extends ScenarioOption {
  protected userSymbols: Array<string>;
  // this flag is used to escape running the permutations if there are no positive accounts
  protected hasPositiveAccounts = false;
  protected balanceByAccountAtAnchorDate: Map<string, number>;
  util: InterestRateScenarioOptionUtils;

  constructor(
    scenarioGroup: ScenarioGroupView,
    webWorkerQueue: WebWorkerQueue,
    startingBalances: BalanceByAccountsSymbols,
    injector: Injector,
  ) {
    super(scenarioGroup, webWorkerQueue, startingBalances, injector);
    this.util = new InterestRateScenarioOptionUtils(this.injector);
  }

  async beforeRunningPermutationsHook() {
    this.hasPositiveAccounts = this.util.hasPositiveAccounts(this);
    if (!this.startingTransactions) {
      this.startingTransactions = await this.util.createStartingTransactionsFromBalance(
        this.startDate,
        this.startingAccountBalances,
      );
    }
    await super.beforeRunningPermutationsHook();
    return;
  }

  async afterRunningPermutationsHook() {
    const winner = await this.util.calculateWinner(this);
    this.callback(winner);
    return;
  }

  /** seems to be deprecated
  async getUserSymbols() {
    if (this.userSymbols) {
      return this.userSymbols;
    }

    this.userSymbols = await this.util.getUserSymbols();
    return this.userSymbols;
  }

  async setDefaultSplitClassification() {
    if (!this.defaultSplitClassification) {
      this.defaultSplitClassification = await this.util.createDefaultSplitClassification();
    }
  }

  async setDefaultSplitCategory() {
    if (!this.defaultSplitCategory) {
      this.defaultSplitCategory = await this.util.createDefaultSplitCategory();
    }
  }

  async setBaseCurrency() {
    const prefBaseCurrency = await this.util.getBaseCurrency();
    if (prefBaseCurrency) {
      if (typeof prefBaseCurrency === "string" && this.baseCurrency !== prefBaseCurrency) {
        this.baseCurrency = prefBaseCurrency;
      }
    }
  }

  async setReferenceData() {
    const referenceData = await this.util.getAllReferenceData();
    if (referenceData) {
      this.referenceData = referenceData;
    }
  }

  async setDatePreference() {
    this.datePreferences = await this.util.getDatePreferences();
  }
   */
}

import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { InstitutionsAddEditComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-institutions/institutions-add-edit/institutions-add-edit.component";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { InstitutionResponse } from "@bitwarden/web-vault/app/models/data/response/institution.response";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import {
  InstitutionAccountStoreModel,
  InstitutionInterestStoreModel,
  InstitutionStoreModel,
} from "@bitwarden/web-vault/app/models/store/institution.store.model";

@Component({
  selector: "app-create-institution-form",
  templateUrl: "./create-institution-form.component.html",
})
export class CreateInstitutionFormComponent implements OnInit {
  private userStoreService: UserStoreService;
  @Input() data: any;

  /** form controller of creating an institution */
  createForm: FormGroup;
  loading = false;
  /** if an institution is being edited */
  editMode = false;
  /** in creation holds the selected country from the list */
  selectedCreateCountry: Country;
  /** holds the list of filtered countries based on user input in create section */
  filteredCreateCountries: Country[] = [];

  /** helps listening to change in addForm controls. */
  private destroy$ = new Subject<void>();
  /** hardcoded countries with name and code */
  protected countries: Country[] = [];
  constructor(
    private institutionService: InstitutionService,
    private dialogRef: MatDialogRef<InstitutionsAddEditComponent>,
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
  ) {
    this.userStoreService = inject(UserStoreService);
    /** define the create institution form to handle changes*/
    this.createForm = this.formBuilder.group({
      createdInstitutionName: ["", Validators.required],
      createdInstitutionCountry: ["", Validators.required],
    });
  }

  async ngOnInit() {
    this.filteredCreateCountries = await this.institutionService.getCountryMasterList();
    this.countries = this.filteredCreateCountries;
  }

  /** filters the list of country based on the entered input in creation section */
  searchForCreateCountry() {
    const typed = this.createForm.value.createdInstitutionCountry;
    this.filteredCreateCountries = this.countries.filter((country) =>
      country.name.toLowerCase().includes(typed.toString().toLowerCase()),
    );
  }

  /** updates state when user selects a country from the list in creation section*/
  createCountrySelected(country: Country) {
    this.selectedCreateCountry = country;
  }

  /** close the dialogue */
  closeDialogue() {
    this.dialogRef.close();
  }

  /** process a manually-created institution */
  async submitCreateForm() {
    this.loading = true;

    try {
      if (this.createForm.valid) {
        await this.createInstitution();
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
    this.loading = false;
  }

  /** create an institution manually */
  async createInstitution() {
    const instoStoreModel: InstitutionStoreModel = {
      name: this.createForm.value.createdInstitutionName,
      fk: null,
      vid: "",
      dc: "",
      dm: "",
      id: crypto.randomUUID(),
      v: 1,
      swt: {
        brc: "",
        bac: "",
        cc: this.selectedCreateCountry.code,
        lc: "",
      },
      bic: {
        bc: "",
        cc: this.selectedCreateCountry.code,
        lc: "",
      },
      avaAcc: [],
    };
    /*const newInstitution = new Institution(new InstitutionResponse(instoStoreModel));*/
    const newInstitution = new InstitutionView(instoStoreModel);
    /** */
    const countryInstitutions = await this.institutionService.getCountryInstitutions(
      this.selectedCreateCountry,
    );
    /** check if the user would be able to choose the institution from the add section */
    const institutionExists = countryInstitutions.some(
      (inst) =>
        inst.name.toLowerCase().replace(/\s/g, "") ===
        newInstitution.name.toLowerCase().replace(/\s/g, ""),
    );
    if (institutionExists) {
      this.globalService.showErrorMessage("errorOccurred", "canSelectInstitutionFromList");
      return;
    }

    try {
      /*const createdInstitution = await this.institutionService.create(newInstitution);*/
      const createdInstitution = this.userStoreService.institutions.save(newInstitution);
      if (createdInstitution instanceof InstitutionView) {
        await this.data.actionSucceeded("createdSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async deleteInstitution(institution: Institution) {
    this.data.delete(institution);
  }
}

import { StoreModelNames } from "../../dali/type/dali.type";
import { signal } from "@angular/core";
import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { SourceAccountStoreModel } from "../../../models/store/source-account-store.model";
import { SourceAccountView } from "@bitwarden/web-vault/app/models/view/source-book/source-account.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class SourceAccountStoreCollection extends StoreModelCollection<
  SourceAccountStoreModel,
  ModelView<SourceAccountStoreModel>
> {
  readonly type: StoreModelNames = "SourceBookStoreModel";
  protected models: Map<string, SourceAccountStoreModel> = new Map<
    string,
    SourceAccountStoreModel
  >();

  private _sourceBookViews = signal<SourceAccountView[]>(null);
  readonly sourceBookViews = this._sourceBookViews.asReadonly();

  triggerChanges(): void {
    const views: SourceAccountView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new SourceAccountView(model)) as SourceAccountView);
    });
    this._sourceBookViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._sourceBookViews.set(null);
  }
}

<div>
  <modal-header
    [title]="'createNewAccount' | i18n"
    [showBackButton]="true"
    [enableTooltip]="true"
    [tooltipInfo]="'creationOptionsTooltip' | i18n"
    (onBackClicked)="backToAccountCreateMain()"
  >
  </modal-header>

  <div class="modal-body">
    <div class="tw-flex tw-gap-12 md:tw-flex-col">
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        [title]="'linkedAccounts' | i18n"
        [description]="'linkedAccountsExplanation' | i18n"
        (onClick)="createByLinking()"
        [isEnabled]="hasBasiqAccess()"
      >
        <svg
          class="tw-h-12 tw-w-12 tw-rounded-lg tw-text-primary tw-shadow-radio-icon md:tw-shadow-none"
        >
          <use xlink:href="images/sprite.svg#add-account" />
        </svg>
      </tile-button>
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        *appRoleScope="'GLOSS'"
        [title]="'unlinkedAccounts' | i18n"
        [description]="'unlinkedAccountsExplanation' | i18n"
        (onClick)="createManually()"
      >
        <svg
          class="tw-h-12 tw-w-12 tw-rounded-lg tw-text-secondary tw-shadow-icon md:tw-shadow-none"
        >
          <use xlink:href="images/sprite.svg#add-account" />
        </svg>
      </tile-button>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-gloss-input-new",
  templateUrl: "./gloss-input-new.component.html",
})
export class GlossInputNewComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() isRequired: boolean = false;
  @Input() type: string = "text";

  @Output() outputValue = new EventEmitter<any>();

  value: string;
  inputControl: FormControl;
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.inputControl = new FormControl(
      { value: this.value, disabled: false },
      this.isRequired ? [Validators.required] : null,
    );

    this.inputControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((newValue) => {
      this.outputValue.emit(newValue);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { StoreData } from "@bitwarden/web-vault/app/models/store/store.data";

export abstract class ModelView<Type extends StoreData> {
  /** Readonly and private Field **/
  readonly id: string;
  readonly dateCreated: Date;

  /** System Fields **/
  protected vaultId: string | null;
  protected version: number;

  /** Public properties **/
  dateModified: Date;

  protected constructor(model?: Type) {
    if (model) {
      this.id = model.id;
      this.vaultId = model.vid;
      this.dateCreated = new Date(model.dc);
      this.dateModified = new Date(model.dm);
      this.version = model.v;
    } else {
      this.id = crypto.randomUUID();
      this.vaultId = null; // View object outside of fromStoreModel do not have vaultId properties.
      this.dateCreated = new Date();
      this.dateModified = new Date();
      this.version = -1; // Unkown Version?
    }
  }

  abstract clone(): ModelView<Type>;
  abstract toStoreModel(): Type;
}

import { CreationOptionsComponent } from "../../gloss/manage/manage-accounts/creation-options/creation-options.component";
import { AccountRegionsComponent } from "../../gloss/manage/manage-accounts/account-regions/account-regions.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { InstitutionSelectionComponent } from "../../gloss/manage/manage-accounts/institution-selection/institution-selection.component";
import { Institution } from "../../models/data/blobby/institution.data";
import { AccountView } from "../../models/view/account/account.view";
import { AccountAddEditComponent } from "../../gloss/manage/manage-accounts/accounts-add-edit/account-add-edit.component";
import { AccountBalanceComponent } from "../../gloss/manage/manage-accounts/account-balance/account-balance.component";
import { Origin } from "../../models/types/general-types";
import { TransactionComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/transaction/transaction.component";
import { effect, inject } from "@angular/core";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { SymbolFormComponent } from "@bitwarden/web-vault/app/gloss/manage/symbol/symbol-form/symbol-form.component";
import { DialogWrapperComponent } from "@bitwarden/web-vault/app/components/dialog/dialog-wrapper/dialog-wrapper.component";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";
import {
  accountManualType,
  AccountManualType,
} from "@bitwarden/web-vault/app/models/types/account.types";
import { AccountCreateMainComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/account-create-main/account-create-main.component";
import { DefaultSystemCurrency } from "@bitwarden/web-vault/app/models/types/location-currency.types";

export class DialogFactory {
  protected appState = inject(AppStateStoreService);
  private userStore: UserStoreService = inject(UserStoreService);
  private dialog: MatDialog = inject(MatDialog);
  private globalService: GlobalService = inject(GlobalService);
  isLoading = false;
  private readonly setCurStepAsComplete: () => Promise<void>;

  dialogRef: MatDialogRef<
    | CreationOptionsComponent
    | InstitutionSelectionComponent
    | AccountRegionsComponent
    | AccountAddEditComponent
    | AccountBalanceComponent
    | TransactionComponent
    | DialogWrapperComponent
    | AccountCreateMainComponent
  >;
  inWizard: boolean = false;
  baseCurrency: string;

  constructor(setCurStepAsComplete?: () => Promise<void>) {
    effect(() => {
      const preference = this.userStore.preferences.preferenceView();
      if (preference) {
        this.baseCurrency = preference.baseCurrency;
      } else {
        this.baseCurrency = DefaultSystemCurrency;
      }
    });

    effect(() => {
      this.inWizard = this.appState.wizard()?.isVisible ?? false;
    });

    this.setCurStepAsComplete = setCurStepAsComplete;
  }

  // -------------------------------------------------------------------------------------------------------------------
  // create Dialogs for "add new account"
  // -------------------------------------------------------------------------------------------------------------------

  closeCreationOptionsDialogue() {
    this.globalService.showMessage("info", "createAccount", "pleaseCreateAnAccountFirst");
  }

  createManually(accountType?: AccountManualType) {
    this.dialogRef?.close();
    this.openInstitutionSelectionModal(accountType);
  }

  openCreationOptionsModal(accountType: AccountManualType = accountManualType.bank) {
    this.dialogRef?.close();
    this.dialogRef = this.dialog.open(CreationOptionsComponent, {
      panelClass: "no-background-dialog",
      data: {
        // createManually: this.createManually.bind(this),
        createManually: () => this.createManually(accountType),
        createByLinking: this.createByLinking.bind(this),
        closeDialogue: this.closeCreationOptionsDialogue.bind(this),
        backClicked: this.openAccountCreateMain.bind(this),
        isPremiumAccount: true,
        /*this.hasAccess,*/
      },
      disableClose: true,
    });
    // dialogRef.afterClosed().pipe(takeUntilDestroyed());
  }

  createByLinking() {
    this.dialogRef.close();
    this.dialogRef = this.dialog.open(AccountRegionsComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeRegionOptionDialogue.bind(this),
        openCreationOptionModal: this.openCreationOptionsModal.bind(this),
        setLoading: this.setLoading.bind(this),
      },
      disableClose: true,
    });
    // dialogRef.afterClosed().pipe(takeUntilDestroyed());
  }

  closeRegionOptionDialogue() {
    this.dialogRef.close();
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  // -------------------------------------------------------------------------------------------------------------------
  // create Dialogs for "Find Institution / Bank" => create Dialogs for creating account
  // -------------------------------------------------------------------------------------------------------------------
  openInstitutionSelectionModal(accountType?: AccountManualType) {
    this.dialogRef?.close();
    this.dialogRef = this.dialog.open(InstitutionSelectionComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeInstitutionSelectionDialogue.bind(this),
        openInstitutionDialogue: () => this.openInstitutionSelectionModal(accountType),
        openCreationOptionsDialogue: () => {
          if (accountType === accountManualType.stock || accountType === accountManualType.other) {
            this.openAccountCreateMain();
          } else {
            this.openCreationOptionsModal(accountType);
          }
        },
        openAccountAddModal: (institution: Institution, country: string) =>
          this.openAddAccountModal(institution, country, accountType),
      },
      disableClose: true,
    });
    // dialogRef.afterClosed().pipe(takeUntilDestroyed());
  }

  closeInstitutionSelectionDialogue() {
    this.dialogRef.close();
  }

  async openAddAccountModal(institution: Institution, country: string, type: AccountManualType) {
    await this.addAccount(null, institution, type);
  }

  async addAccount(
    account: AccountView,
    selectedInstitution?: Institution,
    accountType?: AccountManualType,
  ) {
    this.dialogRef = this.dialog.open(AccountAddEditComponent, {
      panelClass: "no-background-dialog",
      data: {
        actionSucceeded: this.openTransactionImportModal.bind(this),
        institution: selectedInstitution,
        accountCurrency: this.baseCurrency,
        closeDialogue: this.closeAccountAddModal.bind(this),
        openInstitutionSelectionModal: this.openInstitutionSelectionModal.bind(this),
        isWizard: this.inWizard,
        accountType: accountType,
      },
      disableClose: true,
    });
    // dialogRef.afterClosed().pipe(takeUntilDestroyed());
  }

  openTransactionImportModal() {
    this.actionSucceeded("createdSuccessfully").then();
    this.dialogRef.close();
  }

  async closeAccountAddModal(isBack: boolean) {
    if (!isBack && this.inWizard && this.setCurStepAsComplete) {
      await this.setCurStepAsComplete();
    }
    this.dialogRef.close();
  }

  // -------------------------------------------------------------------------------------------------------------------
  // create Dialogs for "add balance at selected account"
  // -------------------------------------------------------------------------------------------------------------------

  async addBalanceSelected(account: AccountView) {
    const firstManuelAccount = account;
    if (firstManuelAccount) {
      this.addBalance(firstManuelAccount);
      return true;
    } else {
      this.globalService.showMessage(
        "info",
        "addAccount",
        "pleaseCreateAnAccountManuallyToAddBalance",
      );
      return false;
    }
  }

  addBalance(account: AccountView) {
    if (account.origin !== Origin.manual) {
      this.globalService.showWarningMessage("warning", "balanceForManualAccount");
      return;
    }
    this.dialogRef = this.dialog.open(AccountBalanceComponent, {
      panelClass: "no-background-dialog",
      data: {
        accountView: account,
        actionSucceeded: this.actionSucceeded.bind(this),
        closeDialogue: this.closeBalanceModal.bind(this),
      },
      disableClose: true,
    });
  }

  async actionSucceeded(actionKey: string) {
    this.globalService.showSuccessMessage("succeeded", actionKey);
  }

  async closeBalanceModal() {
    this.dialogRef.close();
    if (this.inWizard && this.setCurStepAsComplete) {
      await this.setCurStepAsComplete();
    }
  }

  addTransaction(account: AccountView) {
    this.dialogRef = this.dialog.open(TransactionComponent, {
      panelClass: "no-background-dialog",
      data: {
        accountView: account,
        actionSucceeded: this.actionSucceeded.bind(this),
        closeDialogue: () => this.dialogRef.close(),
      },
      disableClose: true,
    });
  }

  openAccountCreateMain() {
    this.dialogRef?.close();
    this.dialogRef = this.dialog.open(DialogWrapperComponent, {
      panelClass: "no-background-dialog",
      data: {
        title: "Add New Account",
        component: AccountCreateMainComponent,
        onSelectAccountType: (type: AccountManualType) => this.onSelectAccountType(type),
      },
      disableClose: true,
    });
  }

  onSelectAccountType(type: AccountManualType) {
    switch (type) {
      case accountManualType.stock:
      case accountManualType.other:
        this.createManually(type);
        break;
      default:
        this.openCreationOptionsModal(type);
        break;
    }
  }

  addSymbol() {
    this.dialogRef = this.dialog.open(DialogWrapperComponent, {
      panelClass: "no-background-dialog",
      data: {
        title: "Add Symbol",
        component: SymbolFormComponent,
      },
    });
  }
}

import { inject, Injectable, signal } from "@angular/core";
import { ModelStoreAbstraction } from "@bitwarden/web-vault/app/services/store/model.store.abstraction";
import { StoreModelNames } from "@bitwarden/web-vault/app/services/dali/type/dali.type";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { EstimateStoreCollection } from "@bitwarden/web-vault/app/services/store/scenario/estimate.store.collection";
import { EstimateActionStoreCollection } from "@bitwarden/web-vault/app/services/store/scenario/estimate.action.store.collection";
import {
  ModelCollectionInterface,
  ScenarioStoreCollectionType,
  StoreModelCollection,
} from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { ScenarioStoreCollection } from "@bitwarden/web-vault/app/services/store/scenario/scenario.store.collection";
import { ScenarioGroupStoreCollection } from "@bitwarden/web-vault/app/services/store/scenario/scenario-group.store.collection";
import { StoreData } from "@bitwarden/web-vault/app/models/store/store.data";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import { ScenarioDataGeneratedCollection } from "@bitwarden/web-vault/app/services/store/scenario/scenario-data.generated.collection";
import { ScenarioResults } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { ScenarioService } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/scenario.service";
import { CalculationStoreService } from "../calculation/calculation.store.service";
import { ScenarioGroupView } from "@bitwarden/web-vault/app/models/view/scenario-group/scenario-group.view";
import { ScenarioView } from "@bitwarden/web-vault/app/models/view/scenario/scenario.view";
import {
  setAnchorDate,
  setEndDate,
} from "@bitwarden/web-vault/app/shared/utils/helper.scenarioGroupView/scenarioDates";
import { DefaultScenarios } from "@bitwarden/web-vault/app/services/store/scenario/defaultScenarios.util";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { combineLatest, distinctUntilChanged, filter, Subscription } from "rxjs";
import { PreferenceSubscriber } from "@bitwarden/web-vault/app/services/store/scenario/preference.subscriber";
import { toObservable } from "@angular/core/rxjs-interop";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { ScenarioBalanceParams } from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";
import { GroupingSelection } from "@bitwarden/web-vault/app/services/store/calculation/controls/group.control";
import { PeriodType } from "@bitwarden/web-vault/app/services/store/calculation/controls/period.control";
import { addYears } from "date-fns";
import { ScenarioBalanceByTimeCalculatedCollection } from "@bitwarden/web-vault/app/services/store/scenario/balances/scenario-balance-by-time.calculated.collection";
import { ScenarioBalanceByTimeAndAccountsCalculatedCollection } from "@bitwarden/web-vault/app/services/store/scenario/balances/scenario-balance-by-time-and-accounts.calculated.collection";
import { DefaultCurrencyPerLocation } from "@bitwarden/web-vault/app/models/types/location-currency.types";

@Injectable({
  providedIn: "root",
})
export class ScenarioStoreService
  extends ModelStoreAbstraction
  implements ModelCollectionInterface<ScenarioStoreCollectionType>
{
  readonly scenario: ScenarioStoreCollection = new ScenarioStoreCollection();
  readonly scenarioGroup: ScenarioGroupStoreCollection = new ScenarioGroupStoreCollection();
  readonly estimateAction: EstimateActionStoreCollection = new EstimateActionStoreCollection();
  readonly estimate: EstimateStoreCollection = new EstimateStoreCollection();
  readonly balanceByTime: ScenarioBalanceByTimeCalculatedCollection =
    new ScenarioBalanceByTimeCalculatedCollection();
  readonly balanceByTimeAndAccounts: ScenarioBalanceByTimeAndAccountsCalculatedCollection =
    new ScenarioBalanceByTimeAndAccountsCalculatedCollection();

  // todo @jun @michelle this is just a place holder so I can hookup the component with the store and display some mock data. I'm assuming this will change
  readonly interestScenario: ScenarioDataGeneratedCollection =
    new ScenarioDataGeneratedCollection();

  protected log: LogService = inject(LogService);
  private scenarioService = inject(ScenarioService);
  private calculationStoreService = inject(CalculationStoreService);
  private userStoreService = inject(UserStoreService);
  private queue = this.calculationStoreService.webWorkerQueue;
  private running = false;

  private _refresh = signal<boolean>(true);
  readonly refresh = this._refresh.asReadonly();
  refresh$ = toObservable(this.refresh);

  private preferenceSubscriber: PreferenceSubscriber = new PreferenceSubscriber();
  private balanceSubscription: Subscription;
  private locationSubscription: Subscription;

  // Default parameters to be fixed correctly to use the period and granularity selectors
  // for the future graph once they have been built correctly
  private startDate = new Date();
  private endDate = addYears(new Date(), 1);

  grouping: GroupingSelection = {
    countries: true,
    institutions: true,
    granularity: "month",
  };

  period: PeriodType = {
    startDate: this.startDate,
    endDate: this.endDate,
    _isDefault: true,
  };

  private initialiseCollectionListener(): void {
    this.balanceSubscription = combineLatest([
      this.calculationStoreService.balanceByAccountSymbols.balances$.pipe(
        filter((x) => !!x),
        distinctUntilChanged((previous, current) => previous.size === 0 && current.size === 0),
      ),
      this.scenario.scenarioView$.pipe(
        filter((x) => !!x),
        distinctUntilChanged((previous, current) => previous.length === 0 && current.length === 0),
      ),
    ]).subscribe(this.triggerRefresh.bind(this));

    this.locationSubscription = this.preferenceSubscriber
      .getObservable()
      .pipe(
        filter((x) => !!x),
        distinctUntilChanged((previous, current) => previous.userLocation === current.userLocation),
      )
      .subscribe(this.updateDefaultScenario.bind(this));
  }

  clearStore(): void {
    this.scenario.clear();
    this.scenarioGroup.clear();
    this.estimate.clear();
    this.estimateAction.clear();
    this.balanceByTime.clear();
    this.balanceByTimeAndAccounts.clear();
    this.balanceSubscription.unsubscribe();
    this.locationSubscription.unsubscribe();

    this.log.info("ScenarioStoreService cleared");
  }

  initialize(): void {
    this.log.info("Initializing ScenarioStoreService");
    this.initialiseCollectionListener();
    this.scenario.triggerChanges();
    this.scenarioGroup.triggerChanges();
  }

  protected getStorageCollection(
    modelName: StoreModelNames,
  ): StoreModelCollection<StoreData, ModelView<StoreData>> {
    switch (modelName) {
      case "ScenarioGroupStoreModel":
        return this.scenarioGroup;
      case "ScenarioStoreModel":
        return this.scenario;
      case "EstimateActionStoreModel":
        return this.estimateAction;
      case "EstimateStoreModel":
        return this.estimate;
      default: {
        this.log.warning(`Need to implement ScenarioStoreService ${modelName}`);
        return this.scenario;
      }
    }
  }

  protected triggerRefresh() {
    // console.log('Marking scenarios to refresh again');
    this._refresh.set(true);
  }

  protected updateDefaultScenario() {
    // console.log('updateDefaultScenario called');
    const defaultScenarioUtil = new DefaultScenarios();

    const symbol =
      DefaultCurrencyPerLocation[this.userStoreService.preferences.preferenceView().userLocation];
    const defaultScenarios = defaultScenarioUtil.createDefaultScenarios(symbol);
    // save the default scenarios to the store
    this.scenario.populateWithSystemDefaults(defaultScenarios);

    // create the default scenario grouping
    const defaultScenarioGroups = defaultScenarioUtil.createDefaultScenarioGroup(
      symbol,
      defaultScenarios,
    );

    // save the grouping to the store
    this.scenarioGroup.populateWithSystemDefaults(defaultScenarioGroups);
  }

  async refreshScenarioData() {
    // console.log('called refreshScenarioData to run scenario');
    if (this.running) {
      // console.log('not running scenario because it is already running');
      this._refresh.set(true);
      return;
    }
    if (this.refresh()) {
      // console.log('refresh is set to true - calling runScenarioGroup');
      this._refresh.set(false);
      this.running = true;

      if (this.scenarioGroup.scenarioGroupView().length > 0) {
        await this.runScenarioGroup(
          this.scenarioGroup.scenarioGroupView()[0],
          async (scenarioWinners) => {
            // console.log('scenario finished running with winner');
            // console.log(scenarioWinners);
            this.interestScenario.update(scenarioWinners);
            this.running = false;
            if (this.refresh()) {
              // console.log('refresh is true so need to rerun after completed before');
              this.refreshScenarioData();
            } else {
              this.interestScenario.enrichTransactions();
              await this.updateBalanceCalculations();
            }
          },
        ).then(() => {
          if (this.refresh()) {
            // console.log('refresh is true so need to rerun');
            this.refreshScenarioData();
          }
        });
      }
    }
  }

  private async runScenarioGroup(
    scenarioGroup: ScenarioGroupView,
    callback: (scenarioWinners: ScenarioResults[]) => Promise<void>,
  ) {
    if (!scenarioGroup) {
      this.running = false;
      return;
    }
    const scenarioGroupCopy = scenarioGroup.clone();

    //TODO: @mmeshel - when we have a place in the UI to set anchor date and end date of the scenario graph we need to
    // set them here as parameters
    setAnchorDate(scenarioGroupCopy);
    setEndDate(scenarioGroupCopy);

    const scenarioViews = this.scenario.scenarioView();
    const scenarios: ScenarioView[] = scenarioGroup.scenariosId.reduce((acc, s) => {
      const sv = scenarioViews.find((sv) => sv.id === s);
      if (sv) {
        acc.push(sv);
      }
      return acc;
    }, []);

    if (scenarios.length === 0) {
      this.running = false;
      return;
    }
    await this.scenarioService.runScenarioGroup(scenarioGroupCopy, scenarios, this.queue, callback);
  }

  /**
   * This function is used to recalculate the balances for each balance collection in
   * the calculated store.
   */
  private async updateBalanceCalculations(): Promise<void> {
    const scenarioTransactions: TransactionView[][] = [];

    for (const scenario of this.interestScenario.collection()) {
      scenarioTransactions.push(scenario.fullTransactionList);
    }

    // TODO: use the granularity selection and period selection for the scenario graph when it has been built
    const parameters: ScenarioBalanceParams = {
      webWorkerQueue: this.calculationStoreService.webWorkerQueue,
      scenarioTransactions: scenarioTransactions,
      group: this.grouping,
      period: this.period,
    };

    const promises: Promise<void>[] = [];
    promises.push(this.balanceByTime.updateCalculations(parameters));
    promises.push(this.balanceByTimeAndAccounts.updateCalculations(parameters));

    await Promise.all(promises);
    return;
  }
}

import { BaseResponse } from "@bitwarden/common/models/response/base.response";
import { AccountTypes } from "@bitwarden/web-vault/app/models/enum/account.enum";
import { AccountType } from "@bitwarden/web-vault/app/models/types/account.types";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionAccountLink } from "@bitwarden/web-vault/app/models/types/institution.type";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { CurrencyType } from "@bitwarden/web-vault/app/models/types/location-currency.types";

export class BookResponse extends BaseResponse {
  __v: number;
  id: string;
  currency: CurrencyType;
  name: string;
  timezone: string;
  defaultClassifications: SplitClassificationType[];
  defaultCategories: SplitCategoryType[];
  balance: number;
  institutionLink: InstitutionAccountLink;
  origin: Origin;
  basiqAccountId: string;
  date: string;
  type: AccountType;

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    this.name = this.getResponseProperty("name");
    this.timezone = this.getResponseProperty("timezone");
    this.currency = this.getResponseProperty("currency");
    this.defaultClassifications = this.getResponseProperty("defaultClassifications");
    this.defaultCategories = this.getResponseProperty("defaultCategories");
    this.balance = this.getResponseProperty("balance");
    this.institutionLink = this.getResponseProperty("institutionLink");
    this.origin = this.getResponseProperty("origin") || Origin.manual;
    this.basiqAccountId = this.getResponseProperty("basiqAccountId");
    this.type = this.getResponseProperty("type") || AccountTypes.regular;
    this.date = this.getResponseProperty("date") || new Date().toISOString().split("T")[0];

    if (!this.timezone) {
      this.setDefaultTimeZone();
    }
  }

  setDefaultTimeZone() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}

import * as d3 from "d3";
import {
  dayGranularity,
  GranularityProperty,
  monthGranularity,
  quarterGranularity,
  weekGranularity,
  yearGranularity,
} from "../../../models/types/balanceGroupingTypes";
import {
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  getMonth,
} from "date-fns";
import { Scales } from "./scales";

const Y_TICK_COUNT = 4;

export class Axis {
  private balanceGraph: any;
  private xScale: d3.ScaleTime<number, number, never>;
  private yScale: d3.ScaleLinear<number, number, never>;
  private maxYAxisValue: number;
  private minYAxisValue: number;
  private granularity: GranularityProperty;
  private readonly isMobile: boolean;

  constructor(
    balanceGraph: any,
    xScale: d3.ScaleTime<number, number, never>,
    yScale: d3.ScaleLinear<number, number, never>,
    isMobile: boolean,
  ) {
    this.balanceGraph = balanceGraph;
    this.xScale = xScale;
    this.yScale = yScale;
    this.isMobile = isMobile;
  }

  drawAxis(
    innerHeight: number,
    startDate: Date,
    endDate: Date,
    granularity: GranularityProperty,
    balanceByTime: Map<string, number>[],
    xLabel?: string,
    yLabel?: string,
  ) {
    this.granularity = granularity;
    [this.minYAxisValue, this.maxYAxisValue] = Scales.calculateMaxMinYAxisValuesList(balanceByTime);

    this.balanceGraph
      .append("g")
      .attr("id", "x-axis")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${innerHeight})`)
      .style("stroke-width", "0.5px")
      .call(this.createXAxis(startDate, endDate))
      .selectAll("text")
      .style("text-anchor", "middle");

    this.balanceGraph
      .append("g")
      .attr("id", "y-axis")
      .attr("class", "y-axis")
      .style("stroke-width", "0.5px")
      .call(this.createYAxis()); // left: text will be shown left of the line\

    if (xLabel) {
      this.addXAxisLabel(xLabel);
    }

    if (yLabel) {
      this.addYAxisLabel(yLabel);
    }
  }

  redrawAxis(
    innerHeight: number,
    startDate: Date,
    endDate: Date,
    granularity: GranularityProperty,
    balanceByTime: Map<string, number>[],
  ) {
    this.granularity = granularity;
    [this.minYAxisValue, this.maxYAxisValue] = Scales.calculateMaxMinYAxisValuesList(balanceByTime);

    this.balanceGraph
      .select("#x-axis")
      .attr("transform", `translate(0,${innerHeight})`)
      .style("stroke-width", "0.5px")
      .call(this.createXAxis(startDate, endDate))
      .selectAll("text")
      .style("text-anchor", "middle")
      .transition()
      .ease(d3.easePolyInOut)
      .duration(500); // Adjust text-anchor for proper alignment

    this.redrawYAxis(innerHeight);
  }

  redrawYAxis(innerHeight: number) {
    this.balanceGraph
      .select("#y-axis")
      .transition()
      .ease(d3.easePolyInOut)
      .duration(500)
      .style("stroke-width", "0.5px")
      .call(this.createYAxis());
  }

  private createXAxis(startDate: Date, endDate: Date) {
    return d3
      .axisBottom(this.xScale)
      .ticks(this.getTickType().every(this.getTickFrequency(startDate, endDate)))
      .tickFormat(this.getTickFormat());
  }

  private getTickFrequency(startDate: Date, endDate: Date): number {
    const tickType = this.getTickType();
    const intervals = tickType.count(startDate, endDate);
    const ticks = this.getTickCount();

    const step = Math.ceil(intervals / ticks);

    if (this.granularity === quarterGranularity) {
      if (step > 4) {
        return 6;
      }
      return 3;
    }

    return step;
  }

  private getTickCount(): number {
    const ticks = {
      mobile: {
        day: 7,
        week: 7,
        month: 12,
        quarter: 6,
        year: 6,
      },
      desktop: {
        day: 14,
        week: 10,
        month: 10,
        quarter: 10,
        year: 10,
      },
    };

    return ticks[this.isMobile ? "mobile" : "desktop"][this.granularity];
  }

  private getTickFormat(): (d: Date, i: number) => string {
    switch (this.granularity) {
      case dayGranularity:
        return d3.timeFormat("%b %d");
      case weekGranularity:
        return d3.timeFormat("%Y-W%V");
      case monthGranularity:
        return d3.timeFormat("%b %Y");
      case quarterGranularity:
        return (date: Date): string => {
          if (date.getMonth() % 3 === 0) {
            const quarter = Math.floor(date.getMonth() / 3 + 1);
            const year = date.getFullYear();
            return `Q${quarter} ${year}`;
          }
          return "";
        };
      case yearGranularity:
        return d3.timeFormat("%Y");
      default:
        return d3.timeFormat("%b %Y");
    }
  }

  private getTickType(): d3.CountableTimeInterval {
    switch (this.granularity) {
      case dayGranularity:
        return d3.timeDay;
      case weekGranularity:
        return d3.timeWeek;
      case monthGranularity:
        return d3.timeMonth;
      case quarterGranularity:
        return d3.timeMonth;
      case yearGranularity:
        return d3.timeYear;
      default:
        return d3.timeMonth;
    }
  }

  private getNumberOfIntervals(startDate: Date, endDate: Date): number {
    switch (this.granularity) {
      case dayGranularity:
        return differenceInDays(endDate, startDate);
      case weekGranularity:
        return differenceInWeeks(endDate, startDate);
      case monthGranularity:
        return differenceInMonths(endDate, startDate);
      case quarterGranularity:
        return differenceInMonths(endDate, startDate);
      case yearGranularity:
        return differenceInYears(endDate, startDate);
      default:
        return differenceInMonths(endDate, startDate);
    }
  }

  private createYAxis() {
    let tickCount = Y_TICK_COUNT;
    if (this.maxYAxisValue === this.minYAxisValue) {
      tickCount = 1;
    }

    return d3.axisLeft(this.yScale).ticks(tickCount).tickFormat(d3.format(".3s"));
  }

  addXAxisLabel(label: string) {
    this.balanceGraph
      .append("text")
      .attr("class", "x-axis-title")
      .attr("dy", "1px")
      .attr("dx", "1px")
      .style("text-anchor", "middle")
      .style("font-size", "11px")
      .attr("fill", "#c6c6c6")
      .text(label);
  }

  addYAxisLabel(label: string) {
    this.balanceGraph
      .append("text")
      .attr("class", "y-axis-title")
      .attr("dy", "-10px")
      .attr("dx", "-20px")
      .style("text-anchor", "middle")
      .style("font-size", "11px")
      .attr("fill", "#c6c6c6")
      .text(label);
  }
}

import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { ClassificationStoreModel } from "../../../models/store/classification.store.model";
import { signal } from "@angular/core";
import { ClassificationView } from "@bitwarden/web-vault/app/models/view/classification/classification.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class ClassificationsStoreCollection extends StoreModelCollection<
  ClassificationStoreModel,
  ModelView<ClassificationStoreModel>
> {
  readonly type: StoreModelNames = "ClassificationStoreModel";

  protected models: Map<string, ClassificationStoreModel> = new Map<
    string,
    ClassificationStoreModel
  >();

  private _classificationViews = signal<ClassificationView[]>(null);
  readonly classificationViews = this._classificationViews.asReadonly();

  triggerChanges(): void {
    const views: ClassificationView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new ClassificationView(model)) as ClassificationView);
    });
    this._classificationViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._classificationViews.set(null);
  }
}

import {
  eachDayOfInterval,
  format as dateFnsFormat,
  getDate,
  getMonth,
  isBefore,
  setMonth,
  subDays,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
  addDays,
  addWeeks,
  addMonths,
  addQuarters,
  addYears,
} from "date-fns";

import { DateStartPreferences } from "@bitwarden/web-vault/app/models/types/balance.types";

import { GranularityBucketKeyFormat } from "../../../models/enum/granularityBucketKeysDateFormatEnum";
import {
  dayGranularity,
  GranularityProperty,
  monthGranularity,
  quarterGranularity,
  weekGranularity,
  yearGranularity,
} from "../../../models/types/balanceGroupingTypes";

export class GranularityDates {
  private _weekStartDay = 0;
  private _monthStartDay = 1;
  private _YearMonthStart = 1;

  constructor(datePreferences?: DateStartPreferences) {
    if (datePreferences) {
      this.applyManualPreferences(datePreferences);
    }
  }

  applyManualPreferences(datePreferences: DateStartPreferences) {
    if (!datePreferences) {
      if (datePreferences?.weekStartDay) {
        this._weekStartDay = datePreferences.weekStartDay;
      }
      if (datePreferences?.monthStartDay) {
        this._monthStartDay = datePreferences.monthStartDay;
      }
      if (datePreferences?.yearMonthStart) {
        this._YearMonthStart = datePreferences.yearMonthStart;
      }
    }
  }

  getGranularityGroupingKeys(
    start: Date,
    end: Date,
    granularity: GranularityProperty,
  ): Record<string, Record<string, Date>> {
    const fullDateInterval = eachDayOfInterval({
      start: start,
      end: end,
    });

    let checkStartDate = false;

    const groupings: Record<string, Record<string, Date>> = {};
    for (const candidateDate of fullDateInterval) {
      const groupingKeys = Object.keys(groupings);
      const groupingKey = this.getDateGroupingKey(candidateDate, granularity);
      if (!groupingKeys.includes(groupingKey)) {
        checkStartDate = false;
        let endDateGroupingKey;
        let endDate = candidateDate;
        if (groupingKeys.length > 0) {
          endDate = subDays(this.getNextGranularityDate(candidateDate, granularity), 1);
          endDateGroupingKey = this.getDateGroupingKey(endDate, granularity);
          if (endDateGroupingKey !== groupingKey) {
            endDate = candidateDate;
            checkStartDate = true;
          }
        }
        groupings[groupingKey] = { startDate: candidateDate, endDate: endDate };
      } else {
        if (groupingKeys.length == 1) {
          groupings[groupingKey].endDate = candidateDate;
        } else if (candidateDate > groupings[groupingKey].endDate) {
          groupings[groupingKey].endDate = candidateDate;
          if (checkStartDate) {
            const startDate = addDays(
              this.getPreviousGranularityDate(candidateDate, granularity),
              1,
            );
            const startDateGroupingKey = this.getDateGroupingKey(startDate, granularity);

            if (
              startDateGroupingKey === groupingKey &&
              startDate < groupings[groupingKey].startDate
            ) {
              groupings[groupingKey].startDate = startDate;
            }
          }
        }
      }
    }
    return groupings;
  }

  getDateGroupingKey(date: Date, granularity: string): string {
    let result: string;
    if (granularity === dayGranularity) {
      result = dateFnsFormat(date, GranularityBucketKeyFormat.day);
    } else if (granularity === weekGranularity) {
      result = dateFnsFormat(date, GranularityBucketKeyFormat.week, {
        weekStartsOn: this._weekStartDay as 0 | 1 | 2 | 3 | 4 | 5 | 6,
        // useAdditionalWeekYearTokens: true
      });
    } else if (granularity === monthGranularity) {
      let periodDateStart = date;
      // todo test this logic
      if (getDate(date) < this._monthStartDay) {
        periodDateStart = setMonth(date, getMonth(date) - 1);
      }
      result = dateFnsFormat(periodDateStart, GranularityBucketKeyFormat.month);
    } else if (granularity === quarterGranularity) {
      result = dateFnsFormat(date, GranularityBucketKeyFormat.quarter);
    } else if (granularity === yearGranularity) {
      // todo manage this._YearMonthStart
      result = dateFnsFormat(date, GranularityBucketKeyFormat.year);
    } else {
      throw new Error(`Granularity ${granularity} not implemented`);
    }

    return result;
  }

  getSetGranularityDates(startDate: Date, endDate: Date, granularity: GranularityProperty): Date[] {
    if (isBefore(startDate, endDate)) {
      return eachDayOfInterval({ start: startDate, end: endDate });
    } else {
      return [];
    }
  }

  getPreviousGranularityDate(date: Date, granularity: GranularityProperty): Date {
    if (granularity === dayGranularity) {
      return subDays(date, 1);
    } else if (granularity === weekGranularity) {
      return subWeeks(date, 1);
    } else if (granularity === monthGranularity) {
      return subMonths(date, 1);
    } else if (granularity === quarterGranularity) {
      return subQuarters(date, 1);
    } else if (granularity === yearGranularity) {
      // todo manage this._YearMonthStart
      return subYears(date, 1);
    } else {
      throw new Error(`Granularity ${granularity} not implemented`);
    }
  }

  getNextGranularityDate(date: Date, granularity: GranularityProperty): Date {
    switch (granularity) {
      case dayGranularity:
        return addDays(date, 1);
      case weekGranularity:
        return addWeeks(date, 1);
      case monthGranularity:
        return addMonths(date, 1);
      case quarterGranularity:
        return addQuarters(date, 1);
      case yearGranularity:
        return addYears(date, 1);
      default:
        throw new Error(`Granularity ${granularity} not implemented`);
    }
  }
}

import { AccountTableView } from "@bitwarden/web-vault/app/models/types/account.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { SyncStatusView } from "@bitwarden/web-vault/app/models/view/sync-status/sync-status.view";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { Country, SyncStatusEnum } from "@bitwarden/web-vault/app/models/types/general-types";
import { SyncStatusStoreModel } from "@bitwarden/web-vault/app/models/store/sync-status.store.model";
import { PreferenceView } from "@bitwarden/web-vault/app/models/view/preference/preference.view";

export function getAccountTableViews(
  accountViews: AccountView[],
  syncStatusViews: SyncStatusView[],
  institutionViews: InstitutionView[],
  balances: Map<string, number>,
  preference: PreferenceView,
): AccountTableView[] {
  // todo @alex replace for the new Allowed Country list
  const countries: Country[] = [
    { code: "AU", name: "Australia" },
    { code: "HK", name: "Hong Kong" },
  ];
  return accountViews?.map((accountView, index) => {
    const accountClone = accountView.clone();
    const institutionView = institutionViews?.find(
      (view) => view.id === accountView.institutionLink.institutionId,
    );
    const country = institutionView
      ? countries.find((country) => country.code === institutionView?.swift.countryCode).name
      : "";

    accountClone.institution = institutionView;
    accountClone.institutionAccountType = institutionView.availableAccounts.find(
      (accType) => (accType.id = accountClone.institutionLink.institutionAccountId),
    );

    return {
      original: accountClone,
      country,
      institution: institutionView?.name,
      id: accountView.id,
      // institutionCode: institutionView?.bic?.bankCode?.toLowerCase(),
      name: accountView.name,
      interestRate: accountView.c_maxInterestRate,
      balance: balances.get(accountView.id),
      symbol: preference.baseCurrency,
      statusPoint: getAccountStatusView(accountView, syncStatusViews),
      type: accountView.type,
    } as AccountTableView;
  });
}

/** @Sinan once all is ready remove the mockSyncStatus and return from the service */
function getAccountStatusView(
  accountView: AccountView,
  syncStatusViews: SyncStatusView[],
): SyncStatusView {
  if (syncStatusViews.length) {
    const ordered = syncStatusViews.sort(
      (a, b) => b.dateCreated.getTime() - a.dateCreated.getTime(),
    );

    return ordered.find((view) => view.accountId === accountView.id);
  } else {
    const mockSyncStatusModel = {
      key: SyncStatusEnum.synced,
      acId: accountView.id,
      dc: new Date().toISOString(),
    } as SyncStatusStoreModel;

    return new SyncStatusView(mockSyncStatusModel);
  }
}

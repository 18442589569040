<!-- @if (account) { -->
<!-- Account Name -->
<div class="tw-flex tw-justify-between tw-items-center tw-pr-3 tw-my-2">
  <div class="tw-flex tw-items-center">
    <icon [name]="'right-arrow'" [size]="5"></icon>
    <span class="tw-ml-2 tw-font-medium">{{ account?.name }}</span>
  </div>
  <!-- <div
    class="tw-flex tw-items-center tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-p-1.5 tw-bg-white"
  >
    <icon
      [name]="'maximize'"
      [size]="4"
      class="tw-max-w-full tw-max-h-full tw-w-auto tw-h-auto"
    ></icon>
  </div> -->
</div>

<!-- Sync -->
<div class="tw-bg-white tw-rounded-lg tw-p-3 tw-mb-4 tw-mx-auto">
  <div class="tw-flex tw-justify-between tw-items-center">
    <div class="tw-flex tw-items-center">
      <div
        class="tw-flex tw-items-center tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-mr-2"
      >
        <icon [name]="'sync-black'" [size]="8"></icon>
      </div>
      <span class="tw-text-neutral-600 tw-uppercase">{{ "sync" | i18n }}</span>
    </div>
    <app-gloss-button
      [options]="{
        text: 'syncNow' | i18n,
        isEnabled: true,
        onClick: fullSync,
        fullClass:
          'tw-h-8 tw-w-full tw-px-3.5 tw-border tw-border-solid tw-border-neutral-100 tw-rounded-md tw-text-neutral-800 tw-text-sm tw-font-medium',
      }"
    ></app-gloss-button>
  </div>

  <div class="tw-flex tw-justify-between tw-items-center tw-mt-3">
    <span class="tw-text-neutral-800">{{ "syncProgress" | i18n }}</span>
    <span class="tw-text-neutral-300 tw-text-sm tw-uppercase">{{ "endTime" | i18n }}</span>
  </div>
  <div class="tw-flex tw-justify-between tw-items-center tw-mt-1">
    <span
      class="tw-text-success tw-text-xs tw-uppercase tw-py-1 tw-px-2.5 tw-bg-success-200 tw-rounded-md"
      >{{ "successful" | i18n }}</span
    >
    <span class="tw-text-neutral-800 tw-text-sm">2025-01-15 12:00 am</span>
  </div>

  <div class="tw-border-2 tw-border-solid tw-border-blue-500 tw-mt-12"></div>
</div>

<!-- TODO: reintroduce in the future -->
<!-- Upload Files -->
<!-- <div class="tw-bg-white tw-rounded-lg tw-p-3 tw-mb-4 tw-mx-auto">
  <div class="tw-flex tw-justify-between tw-items-center">
    <div class="tw-flex tw-items-center">
      <div
        class="tw-flex tw-items-center tw-justify-center tw-bg-white tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-mr-2"
      >
        <icon [name]="'paperclip'" [size]="5"></icon>
      </div>
      <span class="tw-text-neutral-600 tw-uppercase">{{ "uploadFiles" | i18n }}</span>
    </div>
    <app-gloss-button
      [options]="{
        link: '/cabinet',
        text: 'editInCabinet' | i18n,
        isEnabled: true,
        onClick: fullSync,
        fullClass:
          'tw-h-8 tw-w-full tw-px-3.5 tw-border tw-border-solid tw-border-neutral-100 tw-rounded-md tw-text-neutral-800 tw-text-sm tw-font-medium',
      }"
    ></app-gloss-button>
  </div>

  <div class="tw-flex tw-justify-between tw-items-center tw-mt-3">
    <div class="tw-flex">
      <div
        class="tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-100 tw-rounded-lg tw-p-2 tw-mr-2"
      >
        <div
          class="tw-flex tw-items-center tw-justify-center tw-bg-white tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-mr-2"
        >
          <icon [name]="'file-icon'" [size]="5"></icon>
        </div>
        <div class="tw-flex tw-flex-col tw-text-xs">
          <span class="tw-text-neutral-800 tw-max-w-48 tw-break-words"
            >Transaction Jan 2024.pdf</span
          >
          <span class="tw-text-neutral-600 tw-max-w-48 tw-break-words">15MB</span>
        </div>
      </div>
      <div
        class="tw-flex tw-items-start tw-border tw-border-solid tw-border-neutral-100 tw-rounded-lg tw-p-2 tw-mr-2"
      >
        <div
          class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-mr-2"
        >
          <icon [name]="'file-icon'" [size]="5"></icon>
        </div>
        <div class="tw-flex tw-flex-col tw-text-xs">
          <span class="tw-text-neutral-800 tw-max-w-48 tw-break-words"
            >Transaction Jan 2024.pdf</span
          >
          <span class="tw-text-neutral-600 tw-max-w-48 tw-break-words">15MB</span>
        </div>
      </div>
    </div>

    <div
      class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100"
    >
      <span>...</span>
    </div>
  </div>
</div> -->

<!-- My Symbol -->
<div class="tw-bg-white tw-rounded-lg tw-p-3 tw-mb-4 tw-mx-auto">
  <div class="tw-flex tw-justify-between tw-items-center tw-mb-3">
    <div class="tw-flex tw-items-center">
      <div
        class="tw-flex tw-items-center tw-justify-center tw-bg-white tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-mr-2"
      >
        <icon [name]="'form'" [size]="5"></icon>
      </div>
      <span class="tw-text-neutral-600 tw-uppercase">{{ "mySymbol" | i18n }}</span>
    </div>
    <app-gloss-button
      [options]="{
        link: '/symbol',
        text: 'symbolList' | i18n,
        isEnabled: true,
        onClick: fullSync,
        fullClass:
          'tw-h-8 tw-w-full tw-px-3.5 tw-border  that tw-border-solid tw-border-neutral-100 tw-rounded-md tw-text-neutral-800 tw-text-sm tw-font-medium',
      }"
    ></app-gloss-button>
  </div>

  <app-ag-grid-table [showHeader]="true" [options]="table?.options" [data]="table?.data" />
</div>

<!-- My Transactions -->
<div
  class="tw-flex tw-justify-between tw-items-center tw-bg-white tw-rounded-lg tw-p-3 tw-mb-4 tw-mx-auto"
>
  <div class="tw-flex tw-items-center">
    <div
      class="tw-flex tw-items-center tw-justify-center tw-bg-white tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-mr-2"
    >
      <icon [name]="'form'" [size]="5"></icon>
    </div>
    <span class="tw-text-neutral-600 tw-uppercase">{{ "myTransactions" | i18n }}</span>
  </div>
  <app-gloss-button
    [options]="{
      link: '/views',
      text: 'pastView' | i18n,
      isEnabled: true,
      onClick: this.goToPastView.bind(this),
      fullClass:
        'tw-h-8 tw-w-full tw-px-3.5 tw-border tw-border-solid tw-border-neutral-100 tw-rounded-md tw-text-neutral-800 tw-text-sm tw-font-medium',
    }"
  ></app-gloss-button>
</div>

<!-- Account Detail -->
<div class="tw-bg-white tw-rounded-lg tw-p-3 tw-mb-4 tw-mx-auto">
  <div class="tw-flex tw-items-center tw-mb-4">
    <div
      class="tw-flex tw-items-center tw-justify-center tw-bg-white tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-mr-2"
    >
      <icon [name]="'wallet'" [size]="5"></icon>
    </div>
    <span class="tw-text-neutral-600 tw-uppercase">{{ "accountDetail" | i18n }}</span>
  </div>
  <app-account-form
    (onSelectAccountType)="onSelectAccountType($event)"
    (selectCurrency)="selectCurrency($event)"
    [accountInputOptions]="accountInputOptions"
    [currency]="currency"
    [institutionInputOptions]="institutionInputOptions"
    [selectedInstitution]="selectedInstitution"
    [selectedAccountType]="selectedAccountType"
    [isEditMode]="false"
    [saveButtonOptions]="saveButtonOptions"
    [cancelButtonOptions]="cancelButtonOptions"
  ></app-account-form>
</div>
<!-- } -->

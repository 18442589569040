import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";

@Component({
  selector: "app-estimates-add-edit",
  templateUrl: "./estimates-add-edit.component.html",
})
export class EstimatesAddEditComponent implements OnInit {
  loading = false;
  editMode = false;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isEditMode: boolean;
      delete: CallableFunction;
      actionSucceeded: CallableFunction;
      estimate: Estimate;
      estimateGroup: {
        isNew: boolean;
        estimateGroupId: null | string;
      };
    },
    public dialog: MatDialog,
    private globalService: GlobalService,
  ) {}
  async ngOnInit(): Promise<void> {
    if (this.data.isEditMode) {
      this.title = this.globalService.getLocaleValue("editEstimate");
    } else {
      this.title = this.globalService.getLocaleValue("createNewEstimate");
    }
  }
}

import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
  selector: "[appClickOutside]",
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private eRef: ElementRef) {}

  @HostListener("document:click", ["$event"])
  onClick(event: MouseEvent | TouchEvent) {
    // console.log('detect click outside');
    // console.log('event', event.target);
    // console.log('this.eRef.nativeElement', this.eRef.nativeElement);
    // console.log('next');

    //todo: @Horace this is more accurate, but contains is not working in the mat-calendar for some reason,
    // the element is inside the calendar, but it's detecting it as outside
    // continue to investigate in the v2,
    // for now, use the rect position detect method, it works but feels hacky

    // if (!this.eRef.nativeElement.contains(event.target)) {
    //   console.log('clickOutside');
    //   this.clickOutside.emit();
    // }

    const rect = this.eRef.nativeElement.getBoundingClientRect();
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
    const isInElement =
      clientX >= rect.left &&
      clientX <= rect.right &&
      clientY >= rect.top &&
      clientY <= rect.bottom;

    if (!isInElement) {
      this.clickOutside.emit();
    }
  }
}

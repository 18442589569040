import { Component, inject, signal } from "@angular/core";
import { Subject, throttleTime } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";

import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { PreferenceView } from "@bitwarden/web-vault/app/models/view/preference/preference.view";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  AllowedLocations,
  CurrencyType,
  DefaultSystemLocation,
  UserLocationType,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";
import { isOfType } from "@bitwarden/web-vault/app/models/types/general-types";

export type AvailableLocations = Exclude<UserLocationType, "NZ" | "US" | "EU" | "JP" | "CN">;

@Component({
  selector: "app-manage-preferences",
  templateUrl: "./manage-preferences.component.html",
})
export class ManagePreferencesComponent {
  private userStoreService: UserStoreService = inject(UserStoreService);
  private roleAccessService: RoleAccessService = inject(RoleAccessService);
  private globalService: GlobalService = inject(GlobalService);
  private buttonSubmitSubject = new Subject<void>();

  protected formState = signal({
    isSaving: false,
  });

  /** Lock down the location dropdown for now **/
  protected userLocationOptions: Record<AvailableLocations, string> = {
    AU: "Australia",
    HK: "Hong Kong",
  } as const;

  protected readonly dateFormatOptions = ["dd/MM/yyyy"];
  protected currentPreference: PreferenceView;
  protected currencyOptions;
  protected selectedLocation: AvailableLocations;

  constructor() {
    this.currentPreference = this.userStoreService.preferences.preferenceView().clone();
    this.buttonSubmitSubject
      .asObservable()
      .pipe(throttleTime(200), takeUntilDestroyed())
      .subscribe(this.submit.bind(this));

    this.currencyOptions = this.roleAccessService.getRoleAccess().getClaim().forex_currency;

    this.selectedLocation = this.currentPreference.userLocation as AvailableLocations;
  }

  onUserLocationSelected(event: string) {
    const option = Object.entries(this.userLocationOptions).find(([key, value]) => value === event);

    if (isOfType<UserLocationType>(option[0], AllowedLocations)) {
      this.currentPreference.userLocation = option[0];
    } else {
      this.currentPreference.userLocation = DefaultSystemLocation;
    }
  }

  onBaseCurrencySelected(event: string) {
    if (isOfType<CurrencyType>(event, this.currencyOptions)) {
      this.currentPreference.baseCurrency = event;
    } else {
      this.currentPreference.baseCurrency = "AUD";
    }
  }

  onDateFormatSelected(event: string) {
    this.currentPreference.dateFormat = event;
  }

  onSubmitForm() {
    this.formState.set({ isSaving: true });
    this.buttonSubmitSubject.next();
  }

  async submit(): Promise<void> {
    try {
      await this.userStoreService.preferences.save(this.currentPreference);
      this.currentPreference = this.userStoreService.preferences.preferenceView().clone();
    } catch (error) {
      this.globalService.showErrorMessage("error", "saveToVaultError");
    }
    this.formState.set({ isSaving: false });
  }

  protected readonly Object = Object;
}

import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

@Component({
  selector: "app-table-description-renderer",
  templateUrl: "../desc-renderer.component.html",
})
export class TransactionDescriptionCellRenderer implements ICellRendererAngularComp {
  title!: string;
  subtitle!: string;
  dateFormat = new DateFormat();

  agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  setData(params: ICellRendererParams) {
    this.title = params.data.description || "";
    this.subtitle = this.dateFormat.getDateStringFromStamp(params.data.transactionDate) || "";
  }

  refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }
}

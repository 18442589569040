import { Component, inject, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { _getAppliedInterest } from "@bitwarden/web-vault/app/components/best-scenario-dialog/best-scenario.utils";
import { FulfillConditionsComponent } from "@bitwarden/web-vault/app/components/best-scenario-dialog/steps/fulfill-conditions/fulfill-conditions.component";
import { MoveAssetComponent } from "@bitwarden/web-vault/app/components/best-scenario-dialog/steps/move-asset/move-asset.component";
import { OpenAccountComponent } from "@bitwarden/web-vault/app/components/best-scenario-dialog/steps/open-account/open-account.component";
import { StepperActionsEnum } from "@bitwarden/web-vault/app/models/enum/stepper.enum";
import { BestScenarioCarouselItem } from "@bitwarden/web-vault/app/models/types/carousel.types";
import { EarningDataCard } from "@bitwarden/web-vault/app/models/types/component.types";
import { InstitutionInterest } from "@bitwarden/web-vault/app/models/types/institution.type";
import {
  PermutationBalancePair,
  ScenarioData,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { Step, StepActionData } from "@bitwarden/web-vault/app/models/types/stepper.types";
import { InstitutionInterestStoreModel } from "@bitwarden/web-vault/app/models/store/institution.store.model";
import { InstitutionInterestView } from "@bitwarden/web-vault/app/models/view/institution/institution-interest.view";
import { ScenarioStoreService } from "@bitwarden/web-vault/app/services/store/scenario/scenario.store.service";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";

const BEST_SCENARIO_MONTHS = 12; /** Number of months scenario calculated for */
@Component({
  selector: "app-best-scenario-dialog",
  templateUrl: "./best-scenario-dialog.component.html",
})
export class BestScenarioDialogComponent {
  protected scenarioStore: ScenarioStoreService = inject(ScenarioStoreService);
  protected userStore: UserStoreService = inject(UserStoreService);

  isStepper = true;
  isCarousel = false;
  steps: Step[] = [
    { component: { type: OpenAccountComponent, instance: null }, isVisible: true },
    { component: { type: MoveAssetComponent, instance: null }, isVisible: false },
    { component: { type: FulfillConditionsComponent, instance: null }, isVisible: false },
  ];

  bestTenAccounts: BestScenarioCarouselItem[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      closeDialogue: CallableFunction;
    },
    private i18n: I18nService,
  ) {}

  move(stepAction: StepActionData) {
    if (stepAction.action === StepperActionsEnum.viewOtherAccounts) {
      this.setCarouselData();
      this.isStepper = false;
      this.isCarousel = true;
    }

    if (stepAction.action === StepperActionsEnum.completeSteps) {
      this.data.closeDialogue();
    }
  }

  // todo move that in computed signal
  private setCarouselData() {
    const carouselItems: BestScenarioCarouselItem[] = [];
    const bestPermutations =
      this.scenarioStore.interestScenario.bestScenario().helpInfo.bestTenAccountPermutations;
    for (const permutationPair of bestPermutations) {
      const scenarioItem: BestScenarioCarouselItem = {
        accountName: this.getAccountName(permutationPair),
        earningsCard: this.getEarningsCard(permutationPair),
        rateInUse: this.getRateInUse(permutationPair),
      };

      carouselItems.push(scenarioItem);
    }

    this.bestTenAccounts = carouselItems;
  }

  private getAccountName(permutationPair: PermutationBalancePair): string {
    return permutationPair.permutation.scenarioHelpInfo.transferToAccount.accountName;
  }

  // todo move that in computed signal
  private getEarningsCard(permutationPair: PermutationBalancePair): EarningDataCard {
    // const scenarioTwoBalanceGroup = this.data.scenarioData.scenario[1].groupedBalance;
    // const symbol = permutationPair.permutation.scenarioHelpInfo.symbol;
    // const lastDayKey = _getLastDayBalanceKey(scenarioTwoBalanceGroup);
    // const scenarioTwoEndBalance =
    //  scenarioTwoBalanceGroup.granularity["day"][lastDayKey].balance.runningTotalValue[symbol]
    //    .symbolAmount.amount;
    const scenarioTwoEndBalance =
      this.scenarioStore.interestScenario.existingBestScenario().finalBalanceAmount;
    return {
      header: this.i18n.t("toEarn"),
      earningAmount: permutationPair.balance - scenarioTwoEndBalance,
      currency: permutationPair.permutation.scenarioHelpInfo.symbol,
      months: BEST_SCENARIO_MONTHS,
    };
  }

  private getRateInUse(permutationPair: PermutationBalancePair): InstitutionInterestView {
    const rates = permutationPair.permutation.scenarioHelpInfo.transferToAccount.rate;
    const amount = permutationPair.permutation.scenarioHelpInfo.transferAmount;
    return _getAppliedInterest(rates, amount);
  }
}

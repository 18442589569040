<div>
  <div class="modal-body">
    <div class="tw-flex tw-flex-col tw-gap-10">
      <div>
        <p>
          {{ "cabinetSelectAccount1" | i18n }}
          <span class="tw-font-medium">{{ "cabinetSelectAccount2" | i18n }}</span>
          {{ "cabinetSelectAccount3" | i18n }}
        </p>
        <div
          class="tw-flex tw-max-h-48 tw-flex-col tw-gap-2.5 tw-overflow-y-auto"
          [ngClass]="{ 'tw-border-solid tw-border-danger ': noAccountErrorText }"
        >
          <radio-button
            *ngFor="let account of accountOptions"
            [option]="account"
            (onClick)="selectAccount($event)"
            [selectedValue]="selectedAccountId"
            class="tw-gap-2"
          >
            <app-account-selector-options
              [accountData]="account.templateData"
            ></app-account-selector-options>
          </radio-button>
        </div>
        <span *ngIf="noAccountErrorText" class="tw-text-danger">{{
          noAccountErrorText | i18n
        }}</span>
      </div>
      <div class="tw-flex tw-gap-12 md:tw-flex-col">
        <app-gloss-button
          [options]="skipAddTransaction"
          class="tw-w-1/2 md:tw-w-full"
        ></app-gloss-button>
        <app-gloss-button
          [options]="addTransaction"
          class="tw-w-1/2 md:tw-w-full"
        ></app-gloss-button>
      </div>
    </div>
  </div>
</div>

import { Directive, EventEmitter, OnInit } from "@angular/core";

@Directive()
export class DropdownBaseComponent implements OnInit {
  options: string[] = [];
  selectedOption: string;
  optionSelected = new EventEmitter<string>();
  isExpanded = false;

  ngOnInit() {
    if (!this.options.includes(this.selectedOption)) {
      this.selectedOption = this.options[0];
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.optionSelected.emit(option);
    this.isExpanded = false;
  }
}

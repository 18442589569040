import { Component, inject, Input, OnInit, Renderer2 } from "@angular/core";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { View } from "@bitwarden/web-vault/app/models/enum/view.enum";
import { ViewsService } from "@bitwarden/web-vault/app/services/views/views.service";
import { AccountViewTable } from "@bitwarden/web-vault/app/gloss/tables/account-tables/accounts-view-table/account-view.table";
import { TablesFactory } from "@bitwarden/web-vault/app/gloss/tables/tables.factory";
import { BestScenarioDialogComponent } from "@bitwarden/web-vault/app/components/best-scenario-dialog/best-scenario-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { TablesType } from "@bitwarden/web-vault/app/models/enum/tables.enum";
import { ScenarioStoreService } from "@bitwarden/web-vault/app/services/store/scenario/scenario.store.service";
import { ScenarioPaths, ScenarioTypes } from "@bitwarden/web-vault/app/models/enum/scenario.types";
import { PastTransactionTable } from "@bitwarden/web-vault/app/gloss/tables/transaction-table/past-transaction.table";
import { ScenarioTransactionTable } from "@bitwarden/web-vault/app/gloss/tables/transaction-table/scenario-transaction.table";
import { ScenarioDifferenceMessageComponent } from "@bitwarden/web-vault/app/components/scenario-difference-message/scenario-difference-message.component";
import { takeUntil } from "rxjs/operators";
import { DeviceDetectorService } from "ngx-device-detector";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { Subject } from "rxjs";
import { EditBalanceComponent } from "@bitwarden/web-vault/app/gloss/wizard/edit-balance/edit-balance.component";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { ScenarioDisplayView } from "@bitwarden/web-vault/app/models/view/scenario/scenario-display.view";
import { MoneyPipe } from "@bitwarden/web-vault/app/pipes/money.pipe";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-views",
  templateUrl: "views.component.html",
  providers: [MoneyPipe, DecimalPipe],
})
export class ViewsComponent implements OnInit {
  protected viewsService: ViewsService = inject(ViewsService);
  protected dialog = inject(MatDialog);
  private scenarioStore: ScenarioStoreService = inject(ScenarioStoreService);
  private deviceService: DeviceDetectorService = inject(DeviceDetectorService);
  private renderer: Renderer2 = inject(Renderer2);
  private userStoreService: UserStoreService = inject(UserStoreService);
  private moneyPipe: MoneyPipe = inject(MoneyPipe);

  protected readonly ViewEnum = View;
  private defaultView = View.TODAY;
  private destroy$ = new Subject<void>();
  private scenarioCollection: ScenarioDisplayView[];
  private openDifferenceMessage = false;

  private scenarioStoreCollection$ = toObservable(this.scenarioStore.interestScenario.collection);
  // @deprecated
  account: Book; /** @Sinan move this to pastViewService and serve it from there*/

  @Input() iconName: string;
  @Input() title: string;
  @Input() widgetIcons: string[];

  viewColors = {
    [View.PAST]: "accent",
    [View.TODAY]: "primary",
    [View.FUTURE]: "blue-400",
  };

  scenarioDialogRef: MatDialogRef<BestScenarioDialogComponent>;
  differenceScenarioRef: MatDialogRef<ScenarioDifferenceMessageComponent>;
  openReBalanceFormDialog: MatDialogRef<EditBalanceComponent>;

  timeButtons = Object.values(View).map((value) => ({
    value: value,
    color: "tw-bg-" + this.viewColors[value],
  }));

  accountsViewTable: AccountViewTable = new TablesFactory().get(TablesType.AccountView);
  pastTransactionTable: PastTransactionTable = new TablesFactory().get(TablesType.PastTransactions);
  scenarioTransactionTable: ScenarioTransactionTable = new TablesFactory().get(
    TablesType.ScenarioTransactions,
  );

  getTabOptions = () => [
    {
      label: "Path 1",
      value: ScenarioTypes.CurrentInterestRate,
      desc: this.getScenarioPathDesc(ScenarioPaths.currentInterestRate),
    },
    {
      label: "Path 2",
      value: ScenarioTypes.BestExistingInterestRate,
      desc: this.getScenarioPathDesc(ScenarioPaths.bestExistingInterestRate),
    },
    {
      label: "Path 3",
      value: ScenarioTypes.BestNewInterestRate,
      desc: this.getScenarioPathDesc(ScenarioPaths.bestNewInterestRate),
    },
  ];

  constructor() {
    this.scenarioStoreCollection$.pipe(takeUntilDestroyed()).subscribe((collection) => {
      this.scenarioCollection = collection;
      if (this.openDifferenceMessage) {
        this.openScenarioDifferenceMessage();
        this.openDifferenceMessage = false;
      }
    });
  }

  ngOnInit(): void {
    this.viewsService.view = this.viewsService.view || this.defaultView;
    this.viewsService.scenarioType = ScenarioTypes.BestNewInterestRate;
  }

  toggleTimeButtons(value: string) {
    this.viewsService.view = value as View;
  }

  openBestScenarioDialog() {
    this.scenarioDialogRef = this.dialog.open(BestScenarioDialogComponent, {
      maxWidth: "800px",
      data: {
        closeDialogue: () => this.closeScenarioDialog(),
      },
      disableClose: true,
      panelClass: ["tw-py-4", "xs:tw-w-[90%]", "xs:tw-h-[90%]"],
      autoFocus: false,
    });
  }

  submitButtonOptions = new ActionButton({
    type: "none",
    text: "Open Scenario",
    onClick: this.openScenarioDifferenceMessage.bind(this),
  });

  closeScenarioDialog() {
    this.scenarioDialogRef.close();
  }

  setScenarioPath(value: string) {
    this.viewsService.scenarioType = value as ScenarioTypes;
  }

  getScenarioPathDesc(index: number) {
    const amount = this.scenarioTransactionTable?.finalBalanceAmountList[index];
    return this.moneyPipe.transform(
      amount,
      this.userStoreService.preferences.preferenceView()?.baseCurrency,
    );
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  // scenario difference message (based on wizard balance)
  openScenarioDifferenceMessage() {
    const dialogRef = this.dialog.open(ScenarioDifferenceMessageComponent, {
      maxWidth: "800px",
      data: {
        scenarioData: this.scenarioCollection,
        baseCurrency: this.userStoreService.preferences.preferenceView().baseCurrency,
      },
      disableClose: true,
      panelClass: ["scenario-difference-dialog", "xs:tw-h-full"],
      autoFocus: false,
    });
    this.differenceScenarioRef = dialogRef;
    // Use Renderer2 to add the class to the mat-dialog-container element
    dialogRef
      .afterOpened()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const matDialogContainer = document.querySelector("mat-dialog-container");
        if (matDialogContainer && this.isMobile()) {
          this.renderer.addClass(matDialogContainer, "mobile-mat-dialog-container");
        } else {
          this.renderer.addClass(matDialogContainer, "desktop-mat-dialog-container");
        }
      });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result !== "skip") {
          this.openBestScenarioDialog();
        } else if (result === "skip") {
          this.openReBalanceForm();
        }
      });
  }

  // redo balance form
  private openReBalanceForm() {
    const dialogRef = this.dialog.open(EditBalanceComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialog: () => dialogRef.close(),
      },
      disableClose: true,
    });
    this.openReBalanceFormDialog = dialogRef;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.openReBalanceFormDialog.close();
        this.openDifferenceMessage = true;
      });
  }
}

import { Injectable } from "@angular/core";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { InstitutionAccount } from "@bitwarden/web-vault/app/models/types/institution.type";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";

/**
 * @name ScenarioMessagesService
 * @description This service is responsible for setting the messages for the scenario when there are no rows to display.
 * @class ScenarioMessagesService
 * */
@Injectable({
  providedIn: "root",
})
export class ScenarioMessagesService {
  activeScenarioGroup: ScenarioGroup;
  private _accounts: Book[] = [];
  private _institutions: InstitutionView[] = [];
  private _institutionAccounts: InstitutionAccount[] = [];
  private _isInitialized: boolean;
  private baseCurrency: string;
  private finalAccountBalances: Array<Record<string, number>>;
  private pendingAccountBalance: Array<
    (accountsCount: number, positiveAccountsCount: number) => void
  > = [];
  constructor(
    private bookService: BookService,
    private institutionService: InstitutionService,
    private dashboardService: DashboardService,
    private preferencesService: PreferenceService,
    private dataRepositoryService: DataRepositoryService,
  ) {}

  set isInitialized(isInitialized: boolean) {
    this._isInitialized = isInitialized;
  }

  get isInitialized() {
    return this._isInitialized;
  }

  set accounts(accounts: Book[]) {
    this._accounts = accounts;
  }

  get accounts() {
    return this._accounts;
  }

  set institutions(institutions: InstitutionView[]) {
    this._institutions = institutions;
  }

  get institutions() {
    return this._institutions;
  }

  private addInstitution(institution: InstitutionView) {
    const exist = this.institutions.find((inst) => inst.id === institution.id);
    if (!exist) {
      this._institutions.push(institution);
    }
  }

  set institutionAccounts(institutionAccounts: InstitutionAccount[]) {
    this._institutionAccounts = institutionAccounts;
  }

  get institutionAccounts() {
    return this._institutionAccounts;
  }

  private addInstitutionAccount(institutionAccount: InstitutionAccount) {
    const exist = this.institutionAccounts.find((inst) => inst.id === institutionAccount.id);
    if (!exist) {
      this._institutionAccounts.push(institutionAccount);
    }
  }

  private async initialize() {
    const preference = this.preferencesService.getPreferenceView();
    this.baseCurrency = preference.baseCurrency;
    this.accounts = await this.bookService.getAll();
    this.subscribeToDashboardService();
    this.activeScenarioGroup = await this.dataRepositoryService.getActiveScenarioGroup();
    this.isInitialized = true;
  }

  subscribeToDashboardService() {
    this.dashboardService.finalAccountBalances$.subscribe((finalAccountBalances) => {
      this.finalAccountBalances = finalAccountBalances;
      if (this.pendingAccountBalance) {
        this.pendingAccountBalance.forEach((callback) => {
          const accountDetails = this.getAccountDetailsFromBalances();
          callback(accountDetails.accountsCount, accountDetails.positiveAccountsCount);
        });
      }
    });
  }

  /**
   * @description This method is responsible for resetting the institutions, institution accounts and accounts after message is set.
   * */
  reset() {
    this.institutions = [];
    this.institutionAccounts = [];
    this.accounts = [];
    this.isInitialized = false;
  }

  /**
   * @description Checks if there are any Interest Rates in the system, so we can calculate teh earnings on.
   * */
  async hasInterestRate() {
    if (!this.isInitialized) {
      await this.initialize();
    }

    for (const account of this.accounts) {
      const { institutionId, institutionAccountId } = account.institutionLink || {};
      const institution = await this.institutionService.getInstitutionById(institutionId);
      this.addInstitution(institution);
      const institutionAccount = await this.institutionService.filterInstitutionAccountById(
        institutionAccountId,
        institution,
      );
      this.addInstitutionAccount(institutionAccount);
      if (institutionAccount?.interestRates.length > 0) {
        return true;
      }
    }

    return false;
  }

  /**
   * @description This method is responsible for getting the predefined message to fill the message box when user clicks 'Notify us' button.
   * @returns {string} Returns the predefined message.
   * @memberof ScenarioMessagesService
   * */
  getPredefinedMessage(): string {
    let accountsAndInstitutionsPart = "";
    for (const institutionAccount of this.institutionAccounts) {
      const institution = this.institutions.find((inst) =>
        inst.availableAccounts.find((acc) => acc.id === institutionAccount.id),
      );

      accountsAndInstitutionsPart += `Account : ${institutionAccount?.name} & Institution : ${institution?.name},\n`;
    }

    return `I have an issue of interest rates for the following accounts & institutions:\n${accountsAndInstitutionsPart} Please help me to add the interest rates.`;
  }

  /**
   *  @description Check if there are any transactions for the scenario that are monthly interest earnings
   *  @returns {boolean} Returns true there are any matching transactions
   * */
  hasInterestEarnings() {
    return (
      this.dashboardService.tableTransactions.find((transaction) => {
        return (
          transaction.description === "Monthly Interest Amount" &&
          transaction.quantity?.actualQuantity?.amount > 0
        );
      }) !== undefined
    );
  }

  async getAccountsDetails(): Promise<{ accountsCount: number; positiveAccountsCount: number }> {
    if (!this.finalAccountBalances) {
      return new Promise((resolve) => {
        this.pendingAccountBalance.push((accountsCount: number, positiveAccountsCount: number) => {
          resolve({ accountsCount, positiveAccountsCount });
        });
      });
    } else {
      return this.getAccountDetailsFromBalances();
    }
  }

  getAccountDetailsFromBalances() {
    let positiveAccountsCount = 0;
    let accountsCount = 0;
    for (const account in this.finalAccountBalances[this.dashboardService.scenarioKey]) {
      accountsCount++;
      if (
        this.dashboardService.newAnchorPointBalance?.[account]?.[this.activeScenarioGroup.symbol]
      ) {
        const accountCurrentBalance: number =
          this.dashboardService.newAnchorPointBalance[account][this.activeScenarioGroup.symbol];
        if (accountCurrentBalance > 0) {
          positiveAccountsCount++;
        }
      }
    }
    return { accountsCount: accountsCount, positiveAccountsCount: positiveAccountsCount };
  }
}

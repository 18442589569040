import { EstimateStoreModel } from "../../../../models/store/estimate.store.model";
import { Estimate } from "../../../../models/data/blobby/estimate.data";

export const estimate_v0_to_v1 = (estimateItem: Estimate): EstimateStoreModel => {
  return {
    dc: new Date().toString(),
    dm: new Date().toString(),
    id: estimateItem.id,
    v: 1,
    vid: "",
    n: estimateItem.name,
    eak: estimateItem.estimateActionKeys,
    ea: estimateItem.estimateActions,
    c: estimateItem.categories,
    cl: estimateItem.classifications,
    aid: estimateItem.accountId,
    d: estimateItem.direction,
    p: estimateItem.period,
    iv: estimateItem.initialValue,
    s: estimateItem.symbol,
    sd: estimateItem.startDate.toString(),
    ed: estimateItem.endDate.toString(),
    ptdf: estimateItem.periodicTransactionDatesFormula,
    ir: estimateItem.isRecurring,
    ifv: estimateItem.isFixedValue,
    f: estimateItem.frequency,
    tg: estimateItem.transactionGenerator,
  };
};

import { StoreModelNames } from "../../dali/type/dali.type";
import { signal } from "@angular/core";
import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { SyncStatusStoreModel } from "../../../models/store/sync-status.store.model";
import { SyncStatusView } from "@bitwarden/web-vault/app/models/view/sync-status/sync-status.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class SyncStatusStoreCollection extends StoreModelCollection<
  SyncStatusStoreModel,
  ModelView<SyncStatusStoreModel>
> {
  readonly type: StoreModelNames = "SyncStatusStoreModel";
  protected models: Map<string, SyncStatusStoreModel> = new Map<string, SyncStatusStoreModel>();

  private _syncStatusViews = signal<SyncStatusView[]>(null);
  readonly syncStatusViews = this._syncStatusViews.asReadonly();

  triggerChanges(): void {
    const views: SyncStatusView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new SyncStatusView(model)) as SyncStatusView);
    });
    this._syncStatusViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._syncStatusViews.set(null);
  }
}

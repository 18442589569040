import { inject } from "@angular/core";

import { ViewsService } from "../../../../services/views/views.service";
import { View } from "@bitwarden/web-vault/app/models/enum/view.enum";
import { Observable } from "rxjs";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { AccountIconRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/account-icon-renderer/account-icon-renderer.component";
import { AccountNameRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/account-name-renderer/account-name-renderer.component";
import { AmountRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/amount-renderer/amount-renderer.component";

import { GridOptions, RowClickedEvent } from "ag-grid-enterprise";
import { ViewTableSubscription, TableSource } from "./view-table.subscription";
import { FilterControlType } from "@bitwarden/web-vault/app/services/store/calculation/controls/filter.control";
import { ChevronCellRenderer } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/end-icon-renderer/end-icon-renderer.component";

type AccountViewTableUIData = { icon: string };
type AccountViewTableDataSource = TableSource & AccountViewTableUIData;

export class AccountViewTable extends ViewTableSubscription {
  private viewsService: ViewsService;
  options: GridOptions<AccountViewTableDataSource>;

  constructor() {
    super();
    this.viewsService = inject(ViewsService);
    this.options = {
      columnDefs: [
        {
          field: "dragHandle",
          width: 20,
          rowDrag: true,
          rowDragText: (params: any) => `${params.rowNode?.data?.name}`,
        },
        { field: "icon", flex: 1, cellRenderer: AccountIconRendererComponent },
        { field: "name", flex: 2, cellRenderer: AccountNameRendererComponent },
        { field: "balance", flex: 1, cellRenderer: AmountRendererComponent },
        {
          field: "rightChevron",
          width: 60,
          cellRenderer: ChevronCellRenderer,
          suppressHeaderMenuButton: true,
        },
      ],
      onRowClicked: (params) => this.onAccountClick(params),
    };
  }

  async onAccountClick(event: RowClickedEvent) {
    const accountData = event.data as AccountView;
    this.filterOnAccount(accountData.id);
    this.viewsService.view = View.PAST;
  }

  private filterOnAccount(accountId: string) {
    const newFilter: FilterControlType = {
      ...this.calculationStoreService.filters.selected(),
      accounts: [accountId],
    };

    this.calculationStoreService.filters.updateSelection(newFilter);
  }
}

import { Injector } from "@angular/core";
import { BasiqAuth } from "../../../importers/importer.auth.basiq";
import {
  BasiqConnection,
  BasiqJob,
  BasiqJobStep,
  GlossGetBasiqJobResponse,
} from "../../../models/types/basiq.types";
import { Origin, SyncStatusEnum } from "../../../models/types/general-types";
import { InstitutionGroup } from "../../../models/types/institution.type";

import { BasiqAccountSync } from "./basiq.account.sync";
import { SyncStatusView } from "../../../models/view/sync-status/sync-status.view";
import { UserStoreService } from "../../store/user/user.store.service";
import { ImportStoreService } from "../../store/import/import.store.service";

const DELAY_FOR_JOB_CHECKS = 5000;

export class BasiqInstitutionConnection {
  private basiqAuth: BasiqAuth;
  private userStoreService: UserStoreService;
  private importStoreService: ImportStoreService;

  id: string;
  institutionGroup: InstitutionGroup;
  instoConnection: BasiqConnection;

  constructor(
    institutionGroup: InstitutionGroup,
    private injector: Injector,
  ) {
    this.userStoreService = this.injector.get(UserStoreService);
    this.importStoreService = this.injector.get(ImportStoreService);

    this.institutionGroup = institutionGroup;
    this.basiqAuth = this.injector.get(BasiqAuth);
    this.id = institutionGroup.institutionId;
  }

  async syncAccounts() {
    const isValidConnection = await this.isValidInstoConnection();
    if (!isValidConnection) {
      await this.terminateSync(SyncStatusEnum.noConnection);
    } else {
      try {
        const refreshConnection = await this.basiqAuth.refreshConnection(this.instoConnection);
        if (!refreshConnection?.job) {
          await this.terminateSync(SyncStatusEnum.noConnectionRefresh);
          return;
        }

        await this.runBasiqJob(refreshConnection.job);
      } catch (e) {
        await this.terminateSync(SyncStatusEnum.noConnectionRefresh);
      }
    }
  }

  async terminateSync(statusEnum: SyncStatusEnum) {
    const accountViews = this.userStoreService.accounts.accountViews().map((acc) => acc.clone());
    const institutionViews = this.userStoreService.institutions
      .institutionViews()
      .map((i) => i.clone());
    const newSyncStatuses: SyncStatusView[] = [];
    const syncingAccounts = accountViews.map((account) => {
      account.institution = institutionViews.find(
        (institution) => institution.id === account.institutionLink.institutionId,
      );
      if (account.origin !== Origin.basiq || account.institution.basiqId !== this.id) {
        return account;
      }

      const newSyncStatus = new SyncStatusView({
        id: crypto.randomUUID(),
        key: statusEnum,
        vid: null,
        dc: new Date().toISOString(),
        dm: new Date().toISOString(),
        v: 1,
        acId: account.id,
      });
      newSyncStatuses.push(newSyncStatus);
      account.syncStatusLink.push({ id: newSyncStatus.id });
      return account;
    });

    await this.importStoreService.syncStatus.saveToVault(newSyncStatuses, true);
    await this.userStoreService.accounts.saveToVault(syncingAccounts, true);
  }

  async isValidInstoConnection(): Promise<boolean> {
    try {
      const basiqConnection = await this.basiqAuth.getConnection(
        this.institutionGroup.accountViews[0],
      );
      if (basiqConnection) {
        this.instoConnection = basiqConnection;
      }

      return !!basiqConnection;
    } catch (e) {
      return false;
    }
  }

  /** Job: given by Basiq to keep track. So we keep checking until we get the success, then we start retrieving data */
  async runBasiqJob(job: BasiqJob) {
    const basiqJobResponse: GlossGetBasiqJobResponse = await this.basiqAuth.getBasiqJob(job.id);
    if (this.isJobFailed(basiqJobResponse.job)) {
      await this.terminateSync(SyncStatusEnum.basiqJobFetchFail);
      return;
    }

    if (this.isJobSucceeded(basiqJobResponse.job)) {
      await this.runAccountLevelSync();
      return;
    }

    this.basiqAuth.delay(DELAY_FOR_JOB_CHECKS).then(() => {
      this.runBasiqJob(basiqJobResponse.job);
    });
  }

  async runAccountLevelSync() {
    for (const accountSyncStatus of this.institutionGroup.accountViews) {
      const accountStatus = new BasiqAccountSync(accountSyncStatus, this.injector);
      accountStatus.syncAccount().then(() => null);
    }
  }

  private isJobFailed(job: BasiqJob): boolean {
    return job.steps.some((step: BasiqJobStep) => step.status === "failed");
  }

  private isJobSucceeded(job: BasiqJob): boolean {
    return job.steps.every((step: BasiqJobStep) => step.status === "success");
  }
}

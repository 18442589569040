import { GeneratedCollection } from "../generated.collection.abstraction";
import { SymbolView } from "../../../models/view/symbol/symbol.view";
import { signal, Signal, WritableSignal } from "@angular/core";
import { Observable } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";
import { ReferenceDataView } from "../../../models/view/reference-data/reference-data.view";
import { TransactionView } from "../../../models/view/transaction/transaction.view";

export class SymbolsGeneratedCollection extends GeneratedCollection<SymbolView> {
  protected _collection: WritableSignal<SymbolView[]> = signal<SymbolView[]>(null);

  clear(): void {
    this._collection.set(null);
  }

  collection: Signal<SymbolView[]> = this._collection.asReadonly();
  collection$: Observable<SymbolView[]> = toObservable(this.collection);

  update(collection: SymbolView[]): void {}

  generate(referenceDataList: ReferenceDataView[], transacions: TransactionView[]): void {
    const symbolsMap = new Map<string, SymbolView>();

    for (const transaction of transacions) {
      const code = transaction?.quantity?.actualQuantity?.symbol;
      if (code && !symbolsMap.has(code)) {
        const symbol = new SymbolView(code);
        symbolsMap.set(code, symbol);
      }
    }

    // todo @jun need to update the code with the new ReferenceDataView methods
    for (const referenceData of referenceDataList) {
      const base = referenceData.base;

      if (!symbolsMap.has(base)) {
        const symbol = new SymbolView(base);
        symbol.c_asOf = referenceData.clone();
        symbolsMap.set(base, symbol);
      }

      if (!symbolsMap.get(base)?.c_asOf?.isMoreRecent(referenceData)) {
        symbolsMap.get(base).c_asOf = referenceData.clone();
      }
    }

    this._collection.set([...symbolsMap.values()]);
  }
}

import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { AccountManualType } from "@bitwarden/web-vault/app/models/types/account.types";

@Component({
  selector: "app-account-icon-renderer",
  templateUrl: "./account-icon-renderer.component.html",
})
export class AccountIconRendererComponent implements ICellRendererAngularComp {
  defaultIcon: AccountManualType = "bank";
  icon: string;

  agInit(params: ICellRendererParams): void {
    this.icon = `${params.data.type}-account` || this.defaultIcon; // Check if the 'icon' property exists in the data
  }

  refresh(params: ICellRendererParams): boolean {
    this.icon = `${params.data.type}-account` || this.defaultIcon; // Update the icon on refresh
    return true;
  }
}

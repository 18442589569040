import { TransactionView } from "../../../../models/view/transaction/transaction.view";
import { GeneratedCollection } from "../../generated.collection.abstraction";
import { signal, Signal, WritableSignal } from "@angular/core";
import { Observable } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";
import { RevalMetaData } from "@bitwarden/web-vault/app/models/types/revaluation.types";
import { RevaluationWorkerService } from "@bitwarden/web-vault/app/services/web-worker/revaluation/revaluation.worker.service";

export class RevaluationTransactionsGeneratedCollection extends GeneratedCollection<TransactionView> {
  protected generatedTransactions: TransactionView[] = [];
  protected _collection: WritableSignal<TransactionView[]> = signal<TransactionView[]>(null);
  collection: Signal<TransactionView[]> = this._collection.asReadonly();
  collection$: Observable<TransactionView[]> = toObservable(this.collection);

  update(transactions: TransactionView[]): void {
    this.generatedTransactions = transactions;
    this._collection.set(this.generatedTransactions);
  }

  generate(transactions: TransactionView[]): void {
    // run the balance alignnment webworker on these transactions
  }

  clear(): void {
    this._collection.set(null);
  }

  async setRevals(revalMetaData: RevalMetaData) {
    /** @Sinan do we need to cast back to TransactionView? OR is TransactionViewObject is fine? */
    const revals = await new RevaluationWorkerService().getRevaluations(revalMetaData);
    this._collection.set(revals);
  }
}

import { inject, Injectable } from "@angular/core";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ExternalInstitutionsStoreCollection } from "@bitwarden/web-vault/app/services/store/data-plugin/external.institutions.store.collection";
import { ExternalCountriesStoreCollection } from "@bitwarden/web-vault/app/services/store/data-plugin/external.countries.store.collection";

import { ExternalForexRatesStoreCollection } from "@bitwarden/web-vault/app/services/store/data-plugin/external.forex-rates.store.collection";
import { CalculatedStoreAbstraction } from "@bitwarden/web-vault/app/services/store/calculated.store.abstraction";

@Injectable({
  providedIn: "root",
})
export class DataPluginStoreService extends CalculatedStoreAbstraction {
  protected log: LogService = inject(LogService);

  theInstitutionList = new ExternalInstitutionsStoreCollection();
  theCountryList = new ExternalCountriesStoreCollection();
  forexRates = new ExternalForexRatesStoreCollection();

  clearStore(): void {
    this.theInstitutionList.clear();
    this.theCountryList.clear();
    this.forexRates.clear();
    this.log.info("DataPluginStoreService cleared");
  }

  initialize(): void {
    this.log.info("Initializing DataPluginStoreService");
    this.theCountryList.triggerChanges();
    this.forexRates.triggerChanges();
    /** theInstitutionList is triggered via store dependencies with Preference.userLocationOptions **/
  }
}

import { RowClassParams } from "ag-grid-enterprise";

export const getRowClass = <T>(params: RowClassParams<T>, selectedRowIndex: number) => {
  if (params.node.group && params.node.level === 0) {
    return "tw-flex tw-items-end";
  }
  if (params.node.rowIndex === selectedRowIndex) {
    return "tw-bg-neutral-50";
  }
  return "";
};

export const getRowHeight = (params: any) =>
  params.node.group ? (params.node.level === 0 ? 42 : 20) : 42;

<div class="tw-h-full">
  @if (statusPoint.key === "synced") {
    <div class="tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-center">
      <span class="tw-text-xs tw-text-neutral-600">{{ "lastUpdated" | i18n }}</span>
      <span class="tw-text-sm tw-font-normal tw-text-neutral-800">
        {{ statusPoint.dateCreated | date: "yyyy-MM-dd" }}
      </span>
    </div>
  }

  @if (statusPoint.key === "syncing") {
    <div class="tw-flex tw-h-full tw-items-center tw-justify-start">
      <svg
        class="tw-h-5 tw-w-5 {{ statusPoint.icon === SyncIcon.syncAll && 'tw-animate-spin-slow' }}"
      >
        <use [attr.xlink:href]="'images/sprite.svg#' + statusPoint.icon"></use>
      </svg>

      <span class="tw-ml-2">{{ statusPoint.messageKey | i18n }}</span>
    </div>
  }

  @if (statusPoint.key !== "synced" && statusPoint.key !== "syncing") {
    <div class="tw-flex tw-h-full tw-items-center tw-justify-start">
      <svg class="tw-h-5 tw-w-5 {{ statusPoint.icon === SyncIcon.xCircle }}">
        <use [attr.xlink:href]="'images/sprite.svg#' + statusPoint.icon"></use>
      </svg>

      <span class="tw-ml-2">{{ statusPoint.messageKey | i18n }}</span>
    </div>
  }
</div>

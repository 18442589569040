//TODO make this on ea proper class that can be extended and used in other components
import { IButton } from "@bitwarden/web-vault/app/models/interfaces/button.interface";

import { GlossInputOptions } from "../../../../models/types/general-types";

export const actionButtonOptions: IButton = {
  text: "Save",
  type: "submit",
};

export const defaultInputOptions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  inputBlurred: (value: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInput: (event: Event) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  inputCleared: () => {},
};

export const accountInputOptions: GlossInputOptions = {
  placeholder: "Enter account name...",
  label: "Account Name",
  inputBlurred: null,
  inputCleared: null,
  isRequired: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInput: (event: Event) => {},
};

export const currencyInputOptions: GlossInputOptions = {
  ...defaultInputOptions,
  placeholder: "Enter currency...",
  label: "Currency",
  isRequired: true,
  isDisabled: false,
  value: null,
};

export const institutionInputOptions: GlossInputOptions = {
  ...defaultInputOptions,
  placeholder: "Enter institution name...",
  label: "Institution / Bank",
  isRequired: false,
  isDisabled: true,
};

export const amountInputOptions: GlossInputOptions = {
  ...defaultInputOptions,
  label: "Amount",
  placeholder: "0",
  isRequired: true,
};

// todo start these shouldn't be here
// change password component
export const defaultPasswordInputOptions = {
  ...defaultInputOptions,
  value: "",
  isRequired: true,
  hideClearButton: true,
};

export const currentPasswordOptions: GlossInputOptions = {
  ...defaultPasswordInputOptions,
  label: "currentMasterPass",
  placeholder: "enterCurrentPassword",
};

export const newPasswordInputOptions: GlossInputOptions = {
  ...defaultPasswordInputOptions,
  label: "newMasterPass",
  placeholder: "enterNewPassword",
};

export const confirmPasswordInputOptions: GlossInputOptions = {
  ...defaultPasswordInputOptions,
  label: "confirmNewMasterPass",
  placeholder: "confirmNewPassword",
};

export const userVerificationInputOptions: GlossInputOptions = {
  ...defaultPasswordInputOptions,
  label: "password",
  placeholder: "password",
  hint: "confirmIdentity",
};
// todo end these shouldn't be here

import { Component, EventEmitter, inject, Injector, OnDestroy, Output } from "@angular/core";
import { filter, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { BestScenarioStepper } from "@bitwarden/web-vault/app/components/best-scenario-dialog/best-scenario-stepper";
import { EarningDataCard } from "@bitwarden/web-vault/app/models/types/component.types";
import { StepperAction } from "@bitwarden/web-vault/app/models/types/stepper.types";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { ScenarioDisplayView } from "@bitwarden/web-vault/app/models/view/scenario/scenario-display.view";
import { ScenarioStoreService } from "@bitwarden/web-vault/app/services/store/scenario/scenario.store.service";

@Component({
  selector: "app-open-account",
  templateUrl: "./open-account.component.html",
})
export class OpenAccountComponent extends BestScenarioStepper implements OnDestroy {
  private destroy$ = new Subject<void>();
  @Output() onAction = new EventEmitter<StepperAction>();
  // private dashboardService: DashboardService;
  private scenarioStore: ScenarioStoreService = inject(ScenarioStoreService);

  private scenarioObservable$ = toObservable(this.scenarioStore.interestScenario.collection);

  earningsCard: EarningDataCard;

  constructor(injector: Injector) {
    super(injector);

    this.scenarioObservable$
      .pipe(
        takeUntilDestroyed(),
        filter(
          (scenarioObservableValue) => scenarioObservableValue[0].fullTransactionList.length > 0,
        ),
      )
      .subscribe((scenarioDisplayViews: ScenarioDisplayView[]) => {
        this.scenarioDisplayView = scenarioDisplayViews;
        if (this.scenarioDisplayView) {
          this.setUpStep().then(() => {
            this.updateData();
          });
        }
      });
  }

  updateData() {
    this.earningsCard = {
      header: this.i18n.t("toEarn"),
      headerDescription: this.i18n.t("percentInterestRate", this.rateInUse.rate),
      earningAmount: this.getEarningAmount(),
      currency: this.helpInfo.symbol,
      footerDescription: this.getDescription(),
    };
  }

  getDescription(): string {
    /** @Sinan check out for how to get this message: from message.json?  */
    return "In the next 12 months";
  }

  openLinkToSite() {
    const url = this.bestAccountInfo.accountUrl;
    window.open(url, "_blank");
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

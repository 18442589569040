<div (keydown.enter)="$event.preventDefault(); $event.stopPropagation()">
  <modal-header
    [title]="title"
    [showBackButton]="!isEditMode"
    (onBackClicked)="onBackClicked()"
  ></modal-header>

  <div class="modal-body">
    <app-account-form
      (onSelectAccountType)="onSelectAccountType($event)"
      (selectCurrency)="selectCurrency($event)"
      [accountInputOptions]="accountInputOptions"
      [currency]="currency"
      [institutionInputOptions]="institutionInputOptions"
      [setBalanceButtonOptions]="setBalanceButtonOptions"
      [addTransactionButtonOptions]="addTransactionButtonOptions"
      [selectedInstitution]="selectedInstitution"
      [selectedAccountType]="selectedAccountType"
      [isEditMode]="false"
      [saveButtonOptions]="saveButtonOptions"
      [accountManualType]="accountManualType"
      [inWizard]="this.data.isWizard"
    ></app-account-form>

    <!--    <form name="accountForm">
      <div class="tw-flex tw-flex-col">
        <app-gloss-input [options]="accountInputOptions"></app-gloss-input>
        <br />
        <app-currencies
          (selectedCurrency)="selectCurrency($event)"
          [label]="'Currency'"
          class="tw-w-full"
          [disabled]="isEditMode"
          [accountCurrency]="currency"
        ></app-currencies>
        <br />
        <app-gloss-input [options]="institutionInputOptions"></app-gloss-input>
        <br />

        <app-institutional-accounts
          [institution]="selectedInstitution"
          (selectedInstitutionAccount)="onSelectAccountType($event)"
          [preselectedInstitutionalAccount]="selectedAccountType"
        ></app-institutional-accounts>

        <modal-footer [isFullWidth]="isEditMode">
          <app-gloss-button [options]="saveButtonOptions" class="tw-w-full"></app-gloss-button>
        </modal-footer>
      </div>
    </form>-->
  </div>
</div>

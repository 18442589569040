import { StoreData } from "./store.data";
import { TransactionDirection } from "../enum/transactionDirection";
import { TransactionStatusEnum } from "../enum/transactionType";

export type SourceTransactionModelVersion = 1;
export interface SourceTransactionStoreModel extends StoreData {
  /**  **Version Number** **/
  v: SourceTransactionModelVersion;

  /** accountId property on source-transaction type **/
  acId: string;

  /** symbol property on source-transaction type **/
  sym: string;

  /** currency property on source-transaction type **/
  ccy: string;

  /** description property on source-transaction type **/
  dsc: string;

  /** kind property on source-transaction type **/
  knd: string;

  /** direction property on source-transaction type **/
  dir: TransactionDirection;

  /** bankImportedBalance property on source-transaction type **/
  bIB: number;

  /** quantity property on source-transaction type **/
  qty: string;

  /** price property on source-transaction type **/
  prc: number;

  /** categories property on source-transaction type **/
  cts: Array<string>;

  /** classifications property on source-transaction type **/
  cls: Array<string>;

  /** sourceId property on source-transaction type **/
  srId: string;

  /** definition property on source-transaction type **/
  dfn: TransactionStatusEnum;

  /** Date time of the source-transaction. if only date time will be set to 00:00:00 **/
  tDt: string;
}

export const DefaultSourceTransactionStoreModel: SourceTransactionStoreModel = {
  acId: "",
  sym: "",
  ccy: "",
  dsc: "",
  knd: "",
  dir: undefined,
  bIB: 0,
  qty: "",
  prc: 0,
  cts: [],
  cls: [],
  srId: "",
  dfn: undefined,
  tDt: "",
  v: 1,
  vid: "",
  dc: "",
  dm: "",
  id: "",
};

import { Component, OnChanges, ElementRef, Input, SimpleChanges, inject } from "@angular/core";
import * as d3 from "d3";
import { ScaleOrdinal } from "d3";
import { HierarchyCircularNode, HierarchyNode } from "d3-hierarchy";

import { MacroNumberPipe } from "@bitwarden/web-vault/app/pipes/macro-number.pipe";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { CurrencyPipe, DecimalPipe } from "@angular/common";

// todo move to chartDataInterface
interface BubbleData {
  text: string;
  amount: number;
  percent: string;
}

@Component({
  selector: "app-bubble-chart",
  templateUrl: "./bubble-chart.component.html",
  providers: [MacroNumberPipe, DecimalPipe, CurrencyPipe],
})
export class BubbleChartComponent implements OnChanges {
  @Input() dataSet: { text: string; amount: number; percent: string }[];
  protected userStore = inject(UserStoreService);
  protected symbol: string; // use base currency symbol
  protected openToolTips: boolean = false;
  protected tooltips: any;

  width = 450;
  height = 350;

  constructor(
    private el: ElementRef,
    private macroNumberPipe: MacroNumberPipe,
    private currencyPipe: CurrencyPipe,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSet) {
      this.symbol = this.userStore.preferences.preferenceView()?.baseCurrency ?? "USD";
      this.drawChart();
    }
  }

  drawChart() {
    const canvasSelection = d3.select(this.el.nativeElement).select(".canvas");
    canvasSelection.select("svg").remove();

    const canvas = canvasSelection
      .append("svg")
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .attr("width", "100%")
      .attr("height", "100%");

    const radiusScale = d3
      .scaleSqrt()
      .domain([0, d3.max(this.dataSet, (d: BubbleData) => Math.abs(d.amount))]) // Input domain based on the amount
      .range([60, 85]); // Output range for radius (adjust as needed)

    const fillColorScale = d3
      .scaleOrdinal<string>()
      .domain(this.dataSet.map((d) => d.text))
      .range(["#FFC9B2", "#A8C7FF", "#F8D1D1", "#FFEDBA"]);

    const strokeColorScale = d3
      .scaleOrdinal<string>()
      .domain(this.dataSet.map((d) => d.text))
      .range(["#FF5812", "#007EBE", "#FF2214", "#FFC423"]);

    const pack = d3
      .pack()
      .size([this.width - 10, this.height - 10])
      .padding(5)
      .radius((d: HierarchyCircularNode<BubbleData>) => radiusScale(d.data.amount));

    // Create the hierarchy from the data
    const hierarchy = d3
      .hierarchy<{ children: BubbleData[] }>({ children: this.dataSet })
      .sum((d: any) => d.amount)
      .sort((a: HierarchyNode<any>, b: HierarchyNode<any>) => b.data.amount - a.data.amount);
    // Compute the pack layout
    const root = pack(hierarchy);
    const defs = canvas.append("defs");

    this.dataSet.forEach((data, index) => {
      this.createGradient({ defs, index, data, colorScale: fillColorScale, name: "fill" });
      this.createGradient({ defs, index, data, colorScale: strokeColorScale, name: "stroke" });
    });

    this.createBoxShadow(defs);

    const bubbleChart = canvas
      .selectAll(".bubble")
      .data(root.descendants().slice(1))
      .enter()
      .append("g")
      .attr("class", "bubble")
      .attr("transform", (d: HierarchyCircularNode<BubbleData>) => `translate(${d.x}, ${d.y})`)
      .on("click", (event: MouseEvent, d: HierarchyCircularNode<BubbleData>) => {
        const [x, y] = d3.pointer(event);
        const mouseCoordinates = { x: x, y: y };
        this.onBubbleClick(canvas, d, mouseCoordinates);
      })
      .on("mouseout", (event: MouseEvent) => {
        this.closeToolTips();
      });

    bubbleChart
      .append("circle")
      .attr("r", (d: HierarchyCircularNode<BubbleData>) => d.r)
      .attr("fill", (d, i) => `url(#fill-gradient-${i})`)
      .attr("stroke-width", 2)
      .attr("stroke", (d, i) => `url(#stroke-gradient-${i})`);

    bubbleChart
      .append("text")
      .style("text-anchor", "middle")
      .each((d: HierarchyCircularNode<BubbleData>, i, nodes) => {
        const wrapedText = this.wrapText(d.data.text, d.r / 5);
        d3.select(nodes[i])
          .append("tspan")
          .text(wrapedText)
          .attr("dy", "-0.6em")
          .attr("x", 0)
          .attr("class", "tspan")
          .attr("fill", "gray")
          .style("font-size", `13px`);
      })
      .append("tspan")
      .attr("class", "value")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .style("font-size", (d: HierarchyCircularNode<BubbleData>) => `${Math.max(5, d.r / 3)}px`)
      .style("fill", (d: HierarchyCircularNode<BubbleData>) =>
        d.data.amount > 0 ? "black" : "red",
      )
      .text((d: HierarchyCircularNode<BubbleData>) =>
        this.macroNumberPipe.transform(d.data.amount, this.symbol),
      );
  }

  onBubbleClick(
    d3Comp: any,
    data: HierarchyCircularNode<BubbleData>,
    mouseCoord: { x: number; y: number },
  ) {
    this.openToolTips = true;
    mouseCoord = { x: mouseCoord.x + data.x, y: mouseCoord.y + data.y };
    this.createToolTips(d3Comp, data, mouseCoord);
  }

  createToolTips(
    d3Comp: any,
    data: HierarchyCircularNode<BubbleData>,
    mouseCoord: { x: number; y: number },
  ) {
    this.closeToolTips();
    const leftPadding = 12;
    const topPadding = 12;
    const rectHeight = 1.2 * 3 * 18 + topPadding * 2;
    const rectWidth = this.width / 3;
    this.tooltips = d3Comp
      .append("g")
      .attr("class", "tooltips")
      .attr("transform", this.tooltipsLocation(rectHeight, rectWidth, mouseCoord));

    this.tooltips
      .append("rect")
      .attr("height", rectHeight)
      .attr("width", rectWidth)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("fill", "white")
      .attr("stroke", "#DBDBDB")
      .attr("stoke-width", "0.5")
      .attr("filter", "url(#shadow)");

    const tooltipsT = this.tooltips
      .append("text")
      .attr("x", topPadding)
      .attr("y", leftPadding)
      .attr("text-anchor", "start");

    tooltipsT
      .append("tspan")
      .attr("x", leftPadding)
      .attr("dy", "1.2em")
      .style("font-size", `10px`)
      .attr("fill", "#5C5C5C")
      .text("Account Name:");

    tooltipsT
      .append("tspan")
      .attr("x", leftPadding)
      .attr("dy", "1.2em")
      .style("font-size", `12px`)
      .attr("fill", "#292929")
      .text(
        `${data.data.text.length > 12 ? data.data.text.substring(0, rectWidth / 12 + 6) + "..." : data.data.text}`,
      );

    tooltipsT
      .append("tspan")
      .attr("x", leftPadding)
      .attr("dy", "1.2em")
      .style("font-size", `10px`)
      .attr("fill", "#5C5C5C")
      .text("Balance:");

    tooltipsT
      .append("tspan")
      .attr("x", leftPadding)
      .attr("dy", "1.2em")
      .style("font-size", `12px`)
      .attr("fill", "#292929")
      .text(`${this.currencyPipe.transform(data.data.amount, this.symbol)}`);
  }

  closeToolTips() {
    // Remove the rectangle and text if they exist
    if (this.tooltips) {
      this.tooltips.remove();
      this.tooltips = null;
    }
  }

  tooltipsLocation(rectHeight: number, rectWidth: number, mouseCoord: { x: number; y: number }) {
    let x = mouseCoord.x;
    let y = mouseCoord.y;
    if (x + rectWidth > this.width || y + rectHeight > this.height) {
      x = x - rectWidth;
      y = y - rectHeight;
    }
    return `translate(${x}, ${y})`;
  }

  createBoxShadow(defs: any) {
    const filter = defs
      .append("filter")
      .attr("id", "shadow")
      .attr("x", "-50%")
      .attr("y", "-50%")
      .attr("width", "200%")
      .attr("height", "200%");

    // Append Gaussian Blur
    filter
      .append("feGaussianBlur")
      .attr("in", "SourceAlpha")
      .attr("stdDeviation", 4)
      .attr("result", "blur");

    // Append Offset
    filter
      .append("feOffset")
      .attr("in", "blur")
      .attr("dx", 0)
      .attr("dy", 4)
      .attr("result", "offsetBlur");

    // Append Flood
    filter.append("feFlood").attr("flood-color", "rgba(0, 0, 0, 0.1)");

    // Append Composite
    filter.append("feComposite").attr("in2", "offsetBlur").attr("operator", "in");

    const merge = filter.append("feMerge");
    merge.append("feMergeNode");
    merge.append("feMergeNode").attr("in", "SourceGraphic");
  }

  createGradient(params: {
    defs: any;
    index: number;
    data: { text: string };
    colorScale: ScaleOrdinal<string, string>;
    name: string;
  }) {
    const { defs, index, data, colorScale, name } = params;
    const gradient = defs
      .append("linearGradient")
      .attr("id", `${name}-gradient-${index}`)
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%");

    gradient.append("stop").attr("offset", "0%").attr("stop-color", colorScale(data.text));
    gradient.append("stop").attr("offset", "100%").attr("stop-color", "#FFFFFF");
  }

  wrapText(text: string, maxWidth: number): string {
    const wordList = text.split(" ");
    const lines: string[] = [];
    let curLine = "";

    wordList.forEach((word) => {
      if (curLine.length + word.length + (curLine.length > 0 ? 1 : 0) <= maxWidth) {
        if (curLine.length > 0) {
          curLine += " ";
        }
        curLine += word;
      } else {
        if (curLine.length > 0) {
          lines.push(curLine);
        }
        curLine = word;
      }
    });

    if (curLine.length > 0) {
      lines.push(curLine);
    }

    return lines.length > 1 ? lines[0] + "..." : lines[0];
  }
}

import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";

import { AccountType } from "../../types/account.types";
import { InstitutionAccountLink } from "../../types/institution.type";
import { SplitCategoryType } from "../../types/split-category-type";
import { SplitClassificationType } from "../../types/split-classification-type";
import { BookResponse } from "../response/book.response";
import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { CurrencyType } from "@bitwarden/web-vault/app/models/types/location-currency.types";

export class Book extends ModelValidator {
  private readonly __v = 1;
  private _id: string;

  get id(): string {
    return this._id;
  }

  set id(id: string) {
    this._id = id;
  }

  institutionLink: InstitutionAccountLink;

  currency: CurrencyType;
  name: string;
  timezone: string;
  defaultClassifications: SplitClassificationType[];
  defaultCategories: SplitCategoryType[];
  balance: number;
  origin: Origin;
  basiqAccountId: string;
  date: string;
  type: AccountType;

  constructor(response: BookResponse) {
    super();
    if (response == null) {
      return;
    }
    this.checkVersion(this.__v, response.__v);
    this._id = response.id ? response.id : crypto.randomUUID();
    this.name = response.name;
    this.timezone = response.timezone;
    this.currency = response.currency;
    this.defaultClassifications = response.defaultClassifications;
    this.defaultCategories = response.defaultCategories;
    this.balance = response.balance;
    this.institutionLink = response.institutionLink;
    this.origin = response.origin;
    this.basiqAccountId = response.basiqAccountId;
    this.date = response.date;
    this.type = response.type;
  }
}

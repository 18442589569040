import { StoreData } from "./store.data";
import { TransactionDirection } from "../enum/transactionDirection";
import { SplitCategoryType } from "../types/split-category-type";
import { SplitClassificationType } from "../types/split-classification-type";
import { GlossDateAsObjectType, GlossQuantityAsObjectType } from "./types/store.types";
import { Origin, TransactionStatusType } from "@bitwarden/web-vault/app/models/types/general-types";

export type TransactionModelVersion = 2;

export interface TransactionStoreModel extends StoreData {
  /**  **Version Number** **/
  v: TransactionModelVersion;

  /** accountId property on transaction type **/
  acId: string;

  /** bankImportedBalance property on transaction type **/
  bIB: number;

  /** bankImportedBalanceOrigin property on transaction type **/
  bIBO: Origin;

  /** classifications property on transaction type **/
  cls: SplitClassificationType[];

  /** categories property on transaction type **/
  cts: SplitCategoryType[];

  /** definition property on transaction type **/
  dfn: TransactionStatusType;

  /** direction property on transaction type **/
  dir: TransactionDirection;

  /** description property on transaction type **/
  dsc: string;

  /** kind property on transaction type **/
  knd: string;

  /** linkedToId property on transaction type **/
  lTid: string[];

  /** quantity property on transaction type **/
  qty: GlossQuantityAsObjectType;

  /** sourceId property on transaction type **/
  srId: string;

  /** transactionDate property on transaction type **/
  tDt: GlossDateAsObjectType;

  /** valuationPrice property on transaction type **/
  vlPc: number;
}

export const DefaultTransactionStoreModel: TransactionStoreModel = {
  acId: "",
  bIB: 0,
  bIBO: null,
  cls: [],
  cts: [],
  dc: "",
  dfn: undefined,
  dir: undefined,
  dm: "",
  dsc: "",
  id: "",
  knd: "",
  lTid: [],
  qty: {
    cvrt: 0,
    cvsym: "",
    ccy: "",
    qty: {
      amt: 0,
      prcs: 0,
      sym: "",
    },
  },
  srId: "",
  tDt: undefined,
  v: 2,
  vid: "",
  vlPc: 0,
};

import { PreferenceStoreModel } from "../../../../models/store/preference.store.model";
import { Preference } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { CurrencyType } from "@bitwarden/web-vault/app/models/types/location-currency.types";
export const preference_v0_to_v1 = (preference: Preference): PreferenceStoreModel => {
  return {
    dc: new Date().toString(),
    dm: new Date().toString(),
    v: 1,
    vid: "",
    bc: preference.baseCurrency as CurrencyType,
    tz: preference.timeZone,
    df: preference.dateFormat,
    md: preference.mode,
    wds: preference.weekDayStart,
    yms: preference.YearMonthStart,
    mds: preference.monthDayStart,
    ss: preference.syncStatus,
    ul: preference.userLocation,
    id: preference.id,
  };
};

import { inject } from "@angular/core";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { SignalSubscriptionImpl } from "../../data-subscription";

export type SymbolData = {
  code: string; // Currency code (e.g., "AUD", "USD")
  amount: number; // Amount in the currency
  convertedAmount: number; // Amount converted to main currency
  country: string; // Country code (e.g., "AU", "US")
};

/**
 * This class only need to deal with angular dependency
 * and the core logic is in getAccountTableViews function which can be easily unit tested
 */
export class SymbolTableSubscription extends SignalSubscriptionImpl<SymbolData[]> {
  private calculationStoreService = inject(CalculationStoreService);
  private transactionStore = inject(TransactionStoreService);
  private userStore = inject(UserStoreService);
  selectedAccount: AccountView;

  get source() {
    const symbolData: SymbolData[] = [];
    const balances = this.calculationStoreService.balanceByAccountSymbols.balances();
    const baseCurrency = this.userStore.preferences.preferenceView().baseCurrency;
    const AccountSymbolBalance = balances?.get(this.selectedAccount?.id);

    if (!AccountSymbolBalance) {
      return symbolData;
    }

    AccountSymbolBalance.forEach((value, key) => {
      // todo @alex change that when we have a proper symbols rates lookup function
      const symbol = new SymbolView(key);
      const rateToBase = this.transactionStore.symbols
        .collection()
        .find(
          (cross) => cross.code === baseCurrency && cross?.c_asOf?.nCross() === symbol.code,
        )?.c_asOf;

      const symData: SymbolData = {
        code: symbol.code,
        amount: value,
        convertedAmount: baseCurrency === symbol.code ? value : NaN,
        country: symbol.location,
      };

      // todo @alex - replace that for a generic method of normalising for a day X
      if (rateToBase) {
        symData.convertedAmount = value / rateToBase.nRate();
      }
      symbolData.push(symData);
    });

    return symbolData;
  }
}

import { DateAsISOStringType } from "./types/store.types";

export interface StoreData {
  /**  **Generic Version Number** **/
  v: number;
  /** **Vault Id ** **/
  /** This is populated during loading and after creation.**/
  vid: string;

  /** **Element internal Id** **/
  id: string;
  /** **last Date Modified ** **/
  dm: DateAsISOStringType;
  /** **Date Created** **/
  dc: DateAsISOStringType;

  /** **System Properties that flags model to delete** **/
  del?: boolean;
}

export const DefaultStoreData: StoreData = { dc: "", dm: "", id: "", v: 1, vid: "" };

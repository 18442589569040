import { StoreModelNames } from "../dali/type/dali.type";
import { StoreModel } from "../dali/vault-parser/parser.type";
import { ModelView } from "../../models/view/model.view";
import { ModelValidator } from "@bitwarden/web-vault/app/models/store/model.validator";
import { DaliService } from "@bitwarden/web-vault/app/services/dali/dali.service";
import { inject } from "@angular/core";
import { StoreData } from "@bitwarden/web-vault/app/models/store/store.data";

export abstract class StoreModelCollection<
  Model extends StoreModel,
  View extends ModelView<Model>,
> {
  protected abstract type: StoreModelNames;
  protected abstract models: Map<string, StoreData>;
  protected validator: ModelValidator = new ModelValidator();
  protected daliService: DaliService = inject(DaliService);

  abstract triggerChanges(): void;
  abstract clear(): void;

  loadModels(models: StoreData[]): void {
    models.forEach((model) => {
      if (this.validator.assertModel(this.type, model)) {
        Object.freeze(model);
        this.models.set(model.id, model);
      }
    });
  }

  /**
   * Save views to vault and trigger the signal.
   */
  async save(view: View): Promise<boolean> {
    const updatedModel = await this.daliService.save(this.type, [view.toStoreModel()]);
    this.loadModels(updatedModel);
    this.triggerChanges();
    return true;
  }

  /**
   * Delete the element from the vault and trigger the update signal
   */
  async delete(view: View): Promise<boolean> {
    const modelToDelete = this.models.get(view.id);
    this.models.delete(view.id);
    await this.daliService.save(this.type, [{ ...modelToDelete, del: true }]);
    this.triggerChanges();
    return true;
  }

  /**
   * Delete the element from the vault and trigger the update signal
   */
  async deleteFromVault(views: View[]): Promise<boolean> {
    const modelsToDelete = views.map((view) => {
      const model = view.toStoreModel();
      model.del = true;
      this.models.delete(view.id);
      return model;
    });

    await this.daliService.save(this.type, modelsToDelete);
    this.triggerChanges();
    return true;
  }

  /**
   * Save views to vault. Once the operation complete, the updated model will be loaded in the internal map.
   * **<span style="color: yellow;">Requires manual invocation of triggerChanges or automatic triggering through a dependency subscription </span>**
   */
  async saveToVault(views: View[], triggerChanges = false): Promise<boolean> {
    const modelToSave = views.map((view) => view.toStoreModel());
    const result = await this.pushStoreChangesToVault(modelToSave);
    if (triggerChanges) {
      this.triggerChanges();
    }
    return result;
  }

  protected async pushStoreChangesToVault(models: StoreData[]): Promise<boolean> {
    const updatedModel = await this.daliService.save(this.type, models);
    this.loadModels(updatedModel);
    return true;
  }

  get(id: string): View {
    return undefined;
  }
}

export type ModelCollectionInterface<T extends AllowedCollectionType> = {
  [n in keyof T]: StoreModelCollection<StoreData, ModelView<StoreData>>;
};

type AllowedCollectionType =
  | UserStoreModelCollectionType
  | ImportStoreCollectionType
  | ReferenceDataStoreCollectionType
  | ScenarioStoreCollectionType;

export type UserStoreModelCollectionType = {
  accounts: StoreModelCollection<StoreData, ModelView<StoreData>>;
  vaultFiles: StoreModelCollection<StoreData, ModelView<StoreData>>;
  wizards: StoreModelCollection<StoreData, ModelView<StoreData>>;
  categories: StoreModelCollection<StoreData, ModelView<StoreData>>;
  classifications: StoreModelCollection<StoreData, ModelView<StoreData>>;
  preferences: StoreModelCollection<StoreData, ModelView<StoreData>>;
  institutions: StoreModelCollection<StoreData, ModelView<StoreData>>;
  connectors: StoreModelCollection<StoreData, ModelView<StoreData>>;
};

export type ImportStoreCollectionType = {
  sourceTransaction: StoreModelCollection<StoreData, ModelView<StoreData>>;
  sourceCategory: StoreModelCollection<StoreData, ModelView<StoreData>>;
  sourceAccount: StoreModelCollection<StoreData, ModelView<StoreData>>;
  syncStatus: StoreModelCollection<StoreData, ModelView<StoreData>>;
};

export type ReferenceDataStoreCollectionType = {
  referenceData: StoreModelCollection<StoreData, ModelView<StoreData>>;
};

export type ScenarioStoreCollectionType = {
  scenario: StoreModelCollection<StoreData, ModelView<StoreData>>;
  scenarioGroup: StoreModelCollection<StoreData, ModelView<StoreData>>;
  estimate: StoreModelCollection<StoreData, ModelView<StoreData>>;
  estimateAction: StoreModelCollection<StoreData, ModelView<StoreData>>;
};

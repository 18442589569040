import { Component, effect, inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LicenseManager } from "ag-grid-enterprise";
import { DeviceDetectorService } from "ngx-device-detector";
import { filter, Observable, Subject, take } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { AccountSyncTable } from "@bitwarden/web-vault/app/gloss/tables/account-tables/accounts-sync-table/account-sync.table";
import { TablesFactory } from "@bitwarden/web-vault/app/gloss/tables/tables.factory";
import { TransactionBasiqImporter } from "@bitwarden/web-vault/app/importers/transaction-basiq-importer";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { ConnectorService } from "@bitwarden/web-vault/app/services/DataService/connector/connector.service";
import { SideMenuService } from "@bitwarden/web-vault/app/services/menu/side-menu.service";
import { BasiqProgressBar } from "@bitwarden/web-vault/app/services/progress-bar/basiq-progress-bar";

import { AccountAddEditComponent } from "./accounts-add-edit/account-add-edit.component";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { ImportStoreService } from "@bitwarden/web-vault/app/services/store/import/import.store.service";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import { SourceTransactionsView } from "@bitwarden/web-vault/app/models/view/source-transactions/source-transactions.view";
import { DialogFactory } from "@bitwarden/web-vault/app/components/dialog/dialogFactory";
import { ConnectorView } from "@bitwarden/web-vault/app/models/view/connector/connector.view";
import { TablesType } from "@bitwarden/web-vault/app/models/enum/tables.enum";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { DataPluginStoreService } from "@bitwarden/web-vault/app/services/store/data-plugin/data-plugin.store.service";
import { toObservable, takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { NotificationEnum } from "@bitwarden/web-vault/app/models/enum/notification.enum";
import { GlossSyncService } from "@bitwarden/web-vault/app/services/syncing/gloss-sync.service";
import {
  AccountManualType,
  accountManualType,
} from "@bitwarden/web-vault/app/models/types/account.types";

/* Set license */
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-063300}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Ironfly_Technologies_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GLOSS_Vault}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GLOSS_Vault}_need_to_be_licensed___{GLOSS_Vault}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_July_2025}____[v3]_[01]_MTc1MjE4ODQwMDAwMA==78426cc29fbc2c22bf97224bbd624392",
);

@Component({
  selector: "manage-account",
  templateUrl: "manage-account.component.html",
})
export class ManageAccountComponent implements OnInit, OnDestroy {
  private transactionBasiqImporter: TransactionBasiqImporter = null;
  private destroy$ = new Subject<void>();
  private institutionsSignal: Observable<InstitutionView[]>;
  private hideDropdownTimeout: any;
  protected readonly Origin = Origin;
  protected unsubscribe$ = new Subject<void>();

  localEnv = false;
  loading = false;
  isInfo = true;
  accountViews: AccountView[];
  accountAddRef: MatDialogRef<AccountAddEditComponent>;
  sourceTransactions: SourceTransactionsView[];
  progressBarPercent = 0;
  userStore: UserStoreService;
  importStore: ImportStoreService;
  transactionStore: TransactionStoreService;
  dataPluginStoreService: DataPluginStoreService;
  bookService: BookService;
  i18nService = inject(I18nService);
  confirmationDialogService: ConfirmationDialogService;
  dialogFactory: DialogFactory;
  table: AccountSyncTable = new TablesFactory().get(TablesType.AccountSync, this);
  glossSyncService = inject(GlossSyncService);
  isListToggled: boolean = false;
  filterCount: number = 0;
  showFilterDropdown: boolean = false;
  accountTypes: AccountManualType[] = Object.keys(accountManualType) as AccountManualType[];
  selectedFilter: Record<AccountManualType, boolean> = {
    bank: false,
    credit: false,
    other: false,
    stock: false,
  };
  filteredData: any[] = [];

  constructor(
    public dialog: MatDialog,
    private globalService: GlobalService,
    private logService: LogService,
    private connectorService: ConnectorService,
    private deviceService: DeviceDetectorService,
    private glossMenuService: SideMenuService,
    private basiqProgressBar: BasiqProgressBar,
  ) {
    this.dialogFactory = new DialogFactory();
    this.userStore = inject(UserStoreService);
    this.bookService = inject(BookService);
    this.importStore = inject(ImportStoreService);
    this.transactionBasiqImporter = inject(TransactionBasiqImporter);
    this.transactionStore = inject(TransactionStoreService);
    this.dataPluginStoreService = inject(DataPluginStoreService);

    this.institutionsSignal = toObservable(
      this.dataPluginStoreService.theInstitutionList.theInstitutionViews,
    );

    effect(() => {
      this.accountViews = this.userStore.accounts.accountViews();
      this.sourceTransactions = this.importStore.sourceTransaction.sourceTransactionViews();
    });

    // todo @alex we need to standardise that effect/signal/compute vs observable
    this.table.source.pipe(takeUntilDestroyed()).subscribe(this.updateFilteredData.bind(this));
  }

  async ngOnInit() {
    if (process.env.NODE_ENV === "development") {
      this.localEnv = true;
    }

    try {
      this.loading = true;
      await this.handleBasiqRedirect();
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.logService.error(e);
    }
  }

  toggleFilterDropdown() {
    this.showFilterDropdown = !this.showFilterDropdown;
  }

  onFilterClick() {
    if (this.isMobile()) {
      this.toggleFilterDropdown();
    }
  }

  onMouseEnter() {
    clearTimeout(this.hideDropdownTimeout);
    if (!this.isMobile()) {
      this.showFilterDropdown = true;
    }
  }

  onMouseLeave() {
    this.hideDropdownTimeout = setTimeout(() => {
      if (!this.isMobile()) {
        this.showFilterDropdown = false;
      }
    }, 100);
  }

  onAccountTypeFilterChange(type: AccountManualType, event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.selectedFilter[type] = checked;
    this.updateFilteredData();
  }

  updateFilteredData() {
    const noFilterSelected = Object.values(this.selectedFilter).every((value) => !value);
    if (noFilterSelected) {
      this.filterCount = 0;
      return (this.filteredData = this.table.data);
    }

    this.filteredData = this.table.data.filter(
      (row) => this.selectedFilter[row.type as AccountManualType],
    );
    this.filterCount = Object.values(this.selectedFilter).filter(Boolean).length;
  }

  async handleBasiqRedirect() {
    const shouldCallBasiq = await this.transactionBasiqImporter.isCallBasiqAfterRedirect();
    if (shouldCallBasiq) {
      this.initializeBasiqImport();
    }
  }

  resetProgressbar() {
    this.progressBarPercent = 0;
    this.loading = false;
  }

  async fullSync() {
    await this.glossSyncService.startSync();
  }

  async saveBasiqInstitutions() {
    try {
      this.isInfo = false;
      /** TODO move this method to transaction-basiq.importer.ts*/
      await this.transactionBasiqImporter.saveInstitutions();
    } catch (e) {
      this.logService.error("saveBasiqInstitutions :");
      this.resetProgressbar();
      this.showGlobalError();
    }
  }

  async saveBasiqAccounts(): Promise<AccountView[]> {
    try {
      const accountViews = await this.transactionBasiqImporter.saveAccounts();
      if (accountViews?.length > 0) {
        await this.userStore.accounts.saveToVault(accountViews, true);
      }
      return accountViews;
    } catch (e) {
      this.logService.error("saveBasiqAccounts :");
      this.resetProgressbar();
      this.showGlobalError();
      return [];
    }
  }

  async saveBasiqTransactions(newAccounts: AccountView[]) {
    try {
      for (const account of newAccounts) {
        const basiqConnector = await this.connectorService.getConnectorOf(Origin.basiq);

        this.basiqProgressBar.setAccountLabel(account.name);
        await this.transactionBasiqImporter.saveBasiqTransactionsOfAccount(account);

        if (!basiqConnector) {
          const connector = new ConnectorView();
          connector.name = Origin.basiq;
          connector.origin = Origin.basiq;
          connector.institutions = [];
          connector.accountStates = [];
          connector.connectionInfo = null;

          await this.userStore.connectors.save(connector);
        }
      }
      this.resetProgressbar();
    } catch (e) {
      this.logService.error("saveBasiqTransactions :");
      this.resetProgressbar();
      this.showGlobalError();
    }
  }

  showGlobalError() {
    this.globalService.showErrorMessage("error", "somethingWentWrong");
  }

  /** Initialize the import process for Basiq */
  private initializeBasiqImport() {
    try {
      this.importAfterInstosInitialized();
    } catch (e) {
      this.loading = false;
      this.transactionBasiqImporter.endFailureBasiqImport();
      this.showGlobalError();
      this.logService.error(e);
    }
  }

  /**
   * take(1) : Coming from basiq it might not have been initialized before the import call, so this
   * is to make sure they are initialized and there will bo no error down the road
   * */
  private importAfterInstosInitialized() {
    this.institutionsSignal
      .pipe(
        filter((ins) => ins !== null),
        take(1),
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((instos) => {
        if (instos.length > 0) {
          this.startBasiqImport();
        }
      });
  }

  /** Imports data from Basiq to Gloss*/
  private async startBasiqImport() {
    this.transactionBasiqImporter.startBasiqImport();
    this.loading = true;
    this.isInfo = true;
    await this.saveBasiqInstitutions();
    const newAccounts = await this.saveBasiqAccounts();

    await this.saveBasiqTransactions(newAccounts);
    this.loading = false;
    this.transactionBasiqImporter.endSuccessBasiqImport();
  }

  async delete(accountView: AccountView) {
    const accountTransactions = this.transactionStore.transaction
      .transactionViews()
      .filter((t) => t.accountLink.id === accountView.id);

    if (!(await this.isDeleteConfirmed(accountTransactions))) {
      return;
    }

    if (!(await this.deleteAccountTransactions(accountTransactions))) {
      return this.handleDeletionError("transactionsAreNotDeleted");
    }

    if (!(await this.deleteAccount(accountView))) {
      return this.handleDeletionError("accountIsNotDeleted");
    }

    this.confirmationDialogService.notify(
      NotificationEnum.success,
      "succeeded",
      "accountDeletedSuccessfully",
    );
  }

  private async handleDeletionError(errorMessage: string) {
    this.confirmationDialogService.notify(NotificationEnum.error, "errorOccurred", errorMessage);
    return false;
  }

  private async deleteAccountTransactions(accountTransactions: TransactionView[]) {
    try {
      /** If there are no transactions on the account we just assume they are deleted */
      if (!accountTransactions?.length) {
        return true;
      }

      if (!(await this.transactionStore.transaction.deleteFromVault(accountTransactions))) {
        return false;
      }

      const accountSourceTransactions = this.importStore.sourceTransaction
        .sourceTransactionViews()
        .filter((t) => t.accountId === accountTransactions[0].accountLink.id);
      if (accountSourceTransactions) {
        await this.importStore.sourceTransaction.deleteFromVault(accountSourceTransactions);
      }

      return true;
    } catch (error) {
      this.logService.info("Something went wrong while deleting transactions");
      return false;
    }
  }

  private async deleteAccount(accountView: AccountView) {
    try {
      if (!(await this.userStore.accounts.delete(accountView))) {
        return false;
      }

      const sourceAccount = this.importStore.sourceAccount
        .sourceBookViews()
        ?.find((sa) => sa.accountId === accountView.id);
      if (sourceAccount) {
        await this.importStore.sourceAccount.delete(sourceAccount);
      }

      return true;
    } catch (error) {
      this.logService.info("Something went wrong while deleting accounts");
      return false;
    }
  }

  private async isDeleteConfirmed(accountTransactions: TransactionView[]) {
    let isConfirmed = false;
    if (accountTransactions?.length) {
      return await this.confirmForTransactionsDeletion(accountTransactions);
    }

    isConfirmed = await this.confirmationDialogService.confirmFor(ConfirmationEnum.deletingAccount);

    return isConfirmed;
  }

  private async confirmForTransactionsDeletion(transactions: TransactionView[]) {
    const message = this.i18nService.t(
      "deleteAccountConfirmMessageAllTransactions",
      transactions.length.toString(),
    );
    return await this.confirmationDialogService.confirmFor(
      ConfirmationEnum.deletingAccountWithGeneratedMessage,
      { messageReplacement: message },
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async actionSucceeded(actionKey: string) {
    this.accountViews = this.userStore.accounts.accountViews();
    if (this.accountViews.length === 0) {
      this.glossMenuService.setShouldRunWizard(true, false);
    }

    this.accountAddRef?.close(this.accountViews);
    this.globalService.showSuccessMessage("succeeded", actionKey);
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  closeAccountDetails() {
    this.table.accountOnDetail.set(null);
  }

  openAccountCreateModal() {
    this.dialogFactory.openAccountCreateMain();
  }

  toggleView() {
    this.isListToggled = !this.isListToggled;
  }
}

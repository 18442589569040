import { ModelView } from "../model.view";
import {
  DefaultWizardStoreModel,
  WizardModelVersion,
  WizardStoreModel,
} from "@bitwarden/web-vault/app/models/store/wizard.store.model";
import { StepView } from "@bitwarden/web-vault/app/gloss/wizard/stepper/step.view";

export class WizardView extends ModelView<WizardStoreModel> {
  protected version: WizardModelVersion;

  /** Step Information */
  type: string;
  step: StepView;

  constructor(model?: WizardStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.type = model.ty;
      this.step = {
        stepIndex: model.st.stepidx,
        isComplete: model.st.iscomp,
      };
    } else {
      this.version = DefaultWizardStoreModel.v;
      this.type = "account";
      this.step = {
        stepIndex: 1,
        isComplete: false,
      };
    }
  }

  toStoreModel(): WizardStoreModel {
    return {
      id: this.id,
      vid: this.vaultId,
      v: this.version,
      dc: this.dateCreated.toISOString(),
      dm: this.dateModified.toISOString(),
      ty: this.type,
      st: {
        stepidx: this.step.stepIndex,
        iscomp: this.step.isComplete,
      },
    };
  }

  clone(): WizardView {
    return new WizardView(this.toStoreModel());
  }
}

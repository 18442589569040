import { Scenario } from "../../../../models/data/blobby/scenario.data";
import { ScenarioStoreModel } from "../../../../models/store/scenario.store.model";

export const scenario_v0_to_v1 = (scenario: Scenario): ScenarioStoreModel => {
  return {
    dc: new Date().toString(),
    dm: new Date().toString(),
    v: 1,
    vid: "",

    nm: scenario.name,
    sym: scenario.symbol,
    scnT: scenario.scenarioType,
    src: scenario.source,
    eAId: scenario.estimateActions.map((action) => action.id),
    eATp: scenario.estimateActionTypes,
    id: scenario.id,
  };
};

import { AppContext } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";
import { ProcessType } from "@bitwarden/web-vault/app/services/store/app-state/state-process.factory";

type AppStateActionVerb =
  | "fetching"
  | "syncing"
  | "encrypting"
  | "decrypting"
  | "calculating"
  | "generating"
  | "initialising";

type AppStateSubject =
  | "data"
  | "alignment"
  | "normalization"
  | "valuation"
  | "balance"
  | "scenario";
type AppStateStep = "start" | "end" | "error" | "in-progress";
type AppStateProgress = number; // 0 to 100?

const PROGRESS_SCALE = 100;

export class StateProcess {
  /** Internal process lifecycle and type props **/
  private readonly action: AppStateActionVerb;
  private readonly subject: AppStateSubject;
  private step: AppStateStep;
  private _errorMessage: string;
  private progress: AppStateProgress;
  private _processCount = 1;
  private _terminatedProcess = 0;
  private tickSumFactor = 1;
  private remainingProgressAtFork = 0;

  /** Which app context the process is related **/
  readonly context: AppContext;
  readonly type: ProcessType;

  /** started time **/
  readonly startTime;

  /** Display **/
  message: string;
  progressMessage: string;

  constructor(
    type: ProcessType,
    context: AppContext,
    action: AppStateActionVerb,
    subject: AppStateSubject,
    message?: string,
  ) {
    this.type = type;
    this.context = context;
    this.action = action;
    this.subject = subject;
    this.step = "start";
    this.progress = 0;
    this.startTime = performance.now();
    this.message = message;
  }

  merge(stateProcess: StateProcess): StateProcess {
    this.message = stateProcess.message;
    this.progressMessage = stateProcess.progressMessage;
    this.step = stateProcess.step;
    this._processCount++;

    /** The formula creates a distribution scheme where each successive process gets a smaller portion of the remaining progress, ensuring the total never exceeds 100%. **/
    this.remainingProgressAtFork = PROGRESS_SCALE - this.progress;
    this.tickSumFactor =
      this.remainingProgressAtFork /
      (this.remainingProgressAtFork / this._processCount + PROGRESS_SCALE / this._processCount);

    return this;
  }

  get processCount(): number {
    return this._processCount;
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  get id(): string {
    return `${this.type}:${this.context}:${this.action}:${this.subject}:${this.startTime}`;
  }

  started(): boolean {
    return this.step === "start";
  }

  inProgress(): boolean {
    return this.step === "in-progress";
  }

  completed(): boolean {
    return this.step === "end";
  }

  inError(): boolean {
    return this.step === "error";
  }

  /**
   * Get progress as percentage 0 to 100%
   */
  overallProgress(): number {
    return this.progress;
  }

  getMaxProgress(): number {
    return 100;
  }

  /**
   * update progress tick by a %
   * @param value
   */
  tick(value: number) {
    if (value > 0 && value < this.getMaxProgress()) {
      this.step = "in-progress";
    }

    this.progress += Number(
      Number((value * this.tickSumFactor) / this._processCount).toPrecision(6),
    );

    if (value >= this.getMaxProgress()) {
      this.terminate();
    }
  }

  terminate() {
    this._terminatedProcess++;
    if (this.processCount === this._terminatedProcess || this.progress >= this.getMaxProgress()) {
      this.progress = this.getMaxProgress();
      this.step = "end";
    }
  }

  error(msg: string) {
    this.step = "error";
    this._errorMessage = msg;
  }
}

<form name="accountForm">
  <div class="tw-flex tw-flex-col">
    <app-gloss-input
      class="tw-font-neutral-800 tw-mb-3 [&_label]:tw-mb-1"
      [options]="accountInputOptions"
    ></app-gloss-input>

    <app-currencies
      class="tw-w-full tw-font-neutral-800 tw-mb-3 [&_label]:tw-mb-1"
      (selectedCurrency)="emitSelectCurrency($event)"
      [label]="'Currency'"
      [disabled]="isEditMode"
      [accountCurrency]="currency"
    ></app-currencies>

    <app-institution
      class="tw-font-neutral-800 tw-mb-3 [&_label]:tw-mb-1"
      [preselectedInstitution]="selectedInstitution"
    >
    </app-institution>

    <app-institutional-accounts
      class="tw-font-neutral-800 tw-mb-3 [&_label]:tw-mb-1"
      [institution]="selectedInstitution"
      (selectedInstitutionAccount)="emitOnSelectAccountType($event)"
      [preselectedInstitutionalAccount]="selectedAccountType"
    ></app-institutional-accounts>

    @if (accountManualType === accountManualTypeRef.stock) {
      <div class="tw-flex tw-flex-col tw-gap-3">
        <span>Add Details</span>
        <app-gloss-button [options]="setBalanceButtonOptions" class="tw-w-full"></app-gloss-button>
        <app-gloss-button
          [options]="addTransactionButtonOptions"
          class="tw-w-full"
        ></app-gloss-button>
      </div>
    }

    <modal-footer [isFullWidth]="isEditMode">
      <app-gloss-button [options]="saveButtonOptions" class="tw-w-full"></app-gloss-button>
    </modal-footer>
  </div>
</form>

import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-table-name-renderer",
  templateUrl: "../desc-renderer.component.html",
})
export class AccountNameRendererComponent implements ICellRendererAngularComp {
  title!: string;
  subtitle!: string;

  agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  setData(params: ICellRendererParams) {
    this.title = params.data.name || "Unavailable account name";
    if (!params.data.interestRate && isNaN(params.data.interestRate)) {
      this.subtitle = "";
    } else {
      this.subtitle = params.data.interestRate + "% p.a.";
    }
  }

  refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }
}

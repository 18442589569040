import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { DialogService } from "@bitwarden/components";
import { ModalComponent } from "@bitwarden/web-vault/app/components/shared/modal/modal.component";
import { TransactionFormValidator } from "@bitwarden/web-vault/app/gloss/dash/transactions-form/transaction-form.validator";
import { currencies } from "@bitwarden/web-vault/app/gloss/manage/manage-preferences/currencies";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { GlossQuantity } from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";
import { Valuation } from "@bitwarden/web-vault/app/models/data/valuation.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";

// @deprecated
@Component({
  selector: "app-transaction-form",
  templateUrl: "./transaction-form.component.html",
})
export class TransactionFormComponent
  extends TransactionFormValidator
  implements OnInit, OnDestroy
{
  private unsubscribe$ = new Subject<void>();
  transaction: Transaction;
  form: FormGroup;
  formTitle = "Add Transaction";
  loading = true;
  transactionDirections: Array<string> = [TransactionDirection.In, TransactionDirection.Out];
  preferredCurrency = "";
  filteredCurrencies: string[] = currencies;
  currencies: string[] = currencies;
  filteredCategories: Array<Category>;
  filteredClassifications: Array<Classification>;
  accounts: Array<Book>;
  transactionId: string;
  _id: string;
  accountId: string;
  _transactionDate: Date;
  categories: string;
  classifications: string;
  description: string;
  currency: string;
  bankImportedBalance: number;
  balance: GlossBalance;
  direction: string;
  quantity: number;
  valuation: Valuation;

  isDisabled = true;

  constructor(
    private dataRepositoryService: DataRepositoryService,
    private globalService: GlobalService,
    private platformUtilsService: PlatformUtilsService,
    private dashboardService: DashboardService,
    private logService: LogService,
    private i18nService: I18nService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
  ) {
    super();
  }

  async ngOnInit() {
    this.loading = false;
    this.filteredCategories = await this.dataRepositoryService.getAllCategories();
    this.filteredClassifications = await this.dataRepositoryService.getAllClassifications();
    this.accounts = await this.dataRepositoryService.getAllBooks();

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: { [transactionId: string]: string }) => {
        this.transactionId = params["transactionId"];
      });

    this.initializeForm();

    this.transaction = await this.getTransactionById();
    if (this.transaction) {
      this.editForm();
      if (this.isDisabled) {
        this.form.disable();
      }
    }
  }

  private initializeForm() {
    this.form = new FormGroup({
      _id: new FormControl(null),
      accountId: new FormControl(null, Validators.required),
      _transactionDate: new FormControl(null, Validators.required),
      categories: new FormControl(null),
      classifications: new FormControl(null),
      description: new FormControl(null),
      currency: new FormControl(null, Validators.required),
      balance: new FormControl(null),
      direction: new FormControl(null, Validators.required),
      quantity: new FormControl(null),
      bankImportedBalance: new FormControl(null),
      valuation: new FormControl(null),
    });
  }

  private editForm() {
    this.formTitle = "Edit Transaction";
    const glossQuantity = new GlossQuantity().setToQuantityObj(this.transaction.quantity);

    this._id = this.transaction.id;
    this.accountId = this.transaction.accountId;
    this._transactionDate = this.transaction.transactionDate.date;
    this.description = this.transaction.description;
    this.currency = glossQuantity.currency;
    this.balance = this.transaction.balance;
    this.bankImportedBalance = this.transaction.bankImportedBalance;
    this.direction = this.transaction.direction;
    this.quantity = glossQuantity.actualQuantity.amount;
    this.valuation = this.transaction.valuation;

    this.selectedClassifications = this.transaction.classifications;
    this.selectedCategories = this.transaction.categories;

    this.form.setValue({
      _id: this._id,
      accountId: this.accountId,
      _transactionDate: this._transactionDate,
      categories: this.selectedCategories,
      classifications: this.selectedClassifications,
      description: this.description,
      currency: this.currency,
      balance: this.balance,
      direction: this.direction,
      quantity: this.quantity,
      bankImportedBalance: this.bankImportedBalance,
      valuation: this.valuation,
    });
  }

  async handleSubmit() {
    if (this.form.valid) {
      this.loading = true;
      try {
        const date = new DatePipe("en").transform(
          this.form.value._transactionDate,
          this.dashboardService.defaultDateFormat,
        );
        this.form.patchValue({
          _id: this._id,
          categories: this.selectedCategories,
          classifications: this.selectedClassifications,
          bankImportedBalance: this.bankImportedBalance,
          balance: this.balance,
          quantity: this.quantity,
          valuation: this.valuation,
          _transactionDate: date,
        });

        const transaction = DataTransformer.castToTransactionArray(this.form.value);
        await this.dataRepositoryService.updateTransaction(transaction[0]);
        this.platformUtilsService.showToast(
          "success",
          null,
          this.i18nService.t("transactionUpdated"),
        );
        await this.router.navigate(["/primary-dashboard"]);
      } catch (e) {
        this.logService.error(e.message);
      }
      this.loading = false;
    }
  }

  async getAllTransactions() {
    return await this.dataRepositoryService.getAllTransactions();
  }

  async getTransactionById() {
    if (!this.transactionId) {
      return;
    }
    const transactions = await this.getAllTransactions();
    return transactions.find((transaction) => transaction.id === this.transactionId);
  }

  selectCategory(selected: any) {
    const category = selected.value;

    if (this.isCategoryExists(category)) {
      this.globalService.showErrorMessage("errorOccurred", "categoryExists");
    } else {
      const newSplitCategory: SplitCategoryType = {
        categoryId: category.id,
        weight: 1,
        roundingDefault: false,
        name: category.name,
      };

      this.selectedCategories.push(newSplitCategory);
    }
  }

  selectClassification(selected: any) {
    const classification = selected.value;

    if (this.isClassificationExists(classification)) {
      this.globalService.showErrorMessage("errorOccurred", "classificationExists");
    } else {
      const newSplitClassification: SplitClassificationType = {
        classificationId: classification.id,
        weight: 1,
        roundingDefault: false,
        name: classification.name,
      };

      this.selectedClassifications.push(newSplitClassification);
    }
  }

  filter(filterable: string[], toFilterWith: string) {
    return filterable.filter((inArrayValue) =>
      inArrayValue.toLowerCase().includes(toFilterWith.toString().toLowerCase()),
    );
  }

  searchForCurrency(): void {
    this.filteredCurrencies = this.filter(this.currencies, this.preferredCurrency);
  }

  addSelectedCategory(value: number, index: number) {
    this.selectedCategories[index].weight = value;
  }

  removeSelectedCategory(index: number) {
    this.selectedCategories.splice(index, 1);
  }

  addSelectedClasification(value: number, index: number) {
    this.selectedClassifications[index].weight = value;
  }

  removeSelectedClassification(index: number) {
    this.selectedClassifications.splice(index, 1);
  }

  handleCancel() {
    this.router.navigate(["/primary-dashboard"]);
  }

  handleDelete($event: Event) {
    $event.stopPropagation();
    const transaction = this.transaction;
    this.dialogService.open(ModalComponent, { data: { transaction } });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

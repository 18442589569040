import { Wizard } from "../../../../models/data/blobby/wizard.data";
import { WizardStoreModel } from "../../../../models/store/wizard.store.model";

export const wizard_v0_to_v1 = (wizardItem: Wizard): WizardStoreModel => {
  return {
    dc: new Date().toString(),
    dm: new Date().toString(),
    id: wizardItem.id,
    st: {
      stepidx: wizardItem.getStep().stepIndex,
      iscomp: wizardItem.getStep().isComplete,
    },
    v: 1,
    ty: "account",
    vid: "",
  };
};

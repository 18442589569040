import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "icon",
  templateUrl: "./icon.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "tw-flex tw-justify-center tw-items-center",
  },
})
export class IconComponent {
  @Input() name: string;
  @Input() size: number;
  @Input() sizeArbitrary: string;
  @Input() currentColor: string;

  getSize(): string {
    if (this.size) {
      return `tw-w-${this.size} tw-h-${this.size}`;
    } else {
      return `tw-w-[${this.sizeArbitrary}] tw-h-[${this.sizeArbitrary}]`;
    }
  }
}

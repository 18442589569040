import { LogService } from "@bitwarden/common/abstractions/log.service";
import {
  NormalizationProperties,
  NormalizationRates,
} from "@bitwarden/web-vault/app/models/types/normalization-types";
import { ReferenceDataCalculationClass } from "@bitwarden/web-vault/app/services/DataCalculationService/reference-data/reference-data-calculation.class";
import { IReferenceData } from "@bitwarden/web-vault/app/models/view/reference-data/reference-data.view";
import { DateFormatPipe } from "@bitwarden/web-vault/app/pipes/date-format.pipe";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";

// TODO: fix this and update the unit test
// function checkForNonCurrencyRate(symbol: string, base: string, date: number, rate: number, referenceDataCalculation: ReferenceDataCalculationClass) {
//   const normalizedRates: NormalizationRates = {
//     normalizingRate: rate,
//   };
//   // for equitie references, we assume that only contains one symbol in the list
//   // look for the symbol as base rate
//   let symbolReferenceData = referenceDataCalculation.getReferenceForBaseDate(symbol, date);
//   if (symbolReferenceData) {
//     const possibleCurrencies = Object.keys(symbolReferenceData.symbols);
//     let symbolRate;
//     let valueCurrency;
//     if (possibleCurrencies.includes(base)) {
//       symbolRate = symbolReferenceData.symbols[base];
//       valueCurrency = base;
//     } else {
//       // take the first currency as the one we are using
//       symbolRate = symbolReferenceData.symbols[possibleCurrencies[0]];
//       valueCurrency = possibleCurrencies[0];
//     }
//     const updatedRate = rate / symbolRate;
//
//     normalizedRates.symbolValue = symbolRate;
//     normalizedRates.valueCurrency = valueCurrency;
//     normalizedRates.normalizingRate = updatedRate;
//     return normalizedRates;
//   }
//   // look for the symbol as a symbol in reference data
//   symbolReferenceData = referenceDataCalculation.getReferenceForSymbolDate(symbol, date);
//
//   // try to get the symbol rate in the currency of the transaction
//   let symbolRate;
//   let valueCurrency;
//   if (symbolReferenceData) {
//     symbolRate = symbolReferenceData.symbols[symbol];
//     valueCurrency = symbolReferenceData.base;
//     symbolRate = 1 / symbolRate;
//
//     const updatedRate = rate / symbolRate;
//
//     normalizedRates.symbolValue = symbolRate;
//     normalizedRates.valueCurrency = valueCurrency;
//     normalizedRates.normalizingRate = updatedRate;
//   }
//   return normalizedRates;
// }

export class SymbolConversion {
  private _baseCurrency: string;
  protected logService: LogService;
  private referenceDataCalculation: ReferenceDataCalculationClass;

  constructor(baseCurrency: string, logger: LogService, referenceData: IReferenceData[]) {
    this._baseCurrency = baseCurrency;
    this.logService = logger;
    this.referenceDataCalculation = new ReferenceDataCalculationClass(
      this.logService,
      referenceData,
    );
  }

  /**
   * Return the calculated normalizingRates data for symbols.
   * The rate supplied to the function is the rate to get from the symbol to the base, but if the symbol is an equity
   * and not a currency, then we need to split the rate into two parts, one for the symbol and the rest for the currency
   * normalization.
   * @param symbol
   * @param base
   * @param date
   * @param rate
   * @private
   */
  private checkForSymbolRate(
    symbol: string,
    base: string,
    date: number,
    rate: number,
  ): NormalizationRates {
    const normalizedRates: NormalizationRates = {
      normalizingRate: rate,
    };
    if (new SymbolView(symbol).isCurrency()) {
      // add in the valueCurrency if it's a currency
      normalizedRates.valueCurrency = symbol;
      return normalizedRates;
    } else {
      // The calculation is for an equity but cannot pass the unit test
      // comment it out first and fix it later
      // return this.convertSymbolRateToCurrency(symbol, base, date, rate, this.referenceDataCalculation);
      return normalizedRates;
    }
  }

  /**
   * Rate to convert 1 of symbol into the supplied base currency base
   * @param fromSymbol
   * @param toSymbol
   * @param date
   */
  private getConversionRate(fromSymbol: string, toSymbol: string, date: number): number {
    // if symbol is the same as the base, then the rate is 1
    if (fromSymbol === toSymbol) {
      return 1;
    }

    const baseSymbolData = this.referenceDataCalculation.getReferenceData(
      toSymbol,
      fromSymbol,
      date,
    );

    if (baseSymbolData && baseSymbolData.length > 0) {
      const rate = baseSymbolData[0].rate;
      return rate;
    }

    const reverseSymbolData = this.referenceDataCalculation.getReferenceData(
      fromSymbol,
      toSymbol,
      date,
    );

    if (reverseSymbolData && reverseSymbolData.length > 0) {
      const rate = 1 / reverseSymbolData[0].rate;
      return rate;
    }

    // if there was no match for the symbol to the base in the reference data, then try to create reference
    // data for the two using a global reserve currency
    const reserveRate = this.getReferenceDataFromReserveCurrency(toSymbol, fromSymbol, date);
    if (reserveRate) {
      return reserveRate;
    }

    // if can't get a match joined on reserve currency, try to find any currency for symbol and then convert to base
    // using global reserve
    const inferredRate = this.getAnyMatchToCurrency(fromSymbol, toSymbol, date);
    if (inferredRate) {
      return inferredRate;
    }

    return null;
  }

  private getReferenceDataFromReserveCurrency(symbol: string, base: string, date: number): number {
    const symbolData = this.referenceDataCalculation.getReferenceDataFromReserveCurrency(
      symbol,
      base,
      date,
    );

    if (symbolData.length > 0) {
      const rate = symbolData[0].rate;
      return rate;
    }
    return null;
  }

  private getAnyMatchToCurrency(symbol: string, base: string, date: number): number {
    let referenceData = this.referenceDataCalculation.getReferenceForBaseDate(symbol, date);
    let symbolData = referenceData ? [referenceData] : [];

    if (symbolData.length > 0) {
      for (const { rate: firstRate, symbol: possibleSymbol } of symbolData) {
        const finalRate = this.getSecondLevelAnyMatchToCurrency(
          base,
          date,
          firstRate,
          possibleSymbol,
        );
        if (finalRate) {
          return finalRate;
        }
      }
    }
    referenceData = this.referenceDataCalculation.getReferenceForSymbolDate(symbol, date);
    symbolData = referenceData ? [referenceData] : [];

    if (symbolData.length > 0) {
      for (const { rate: symbolRate, base: possibleSymbol } of symbolData) {
        const firstRate = 1 / symbolRate;
        const finalRate = this.getSecondLevelAnyMatchToCurrency(
          base,
          date,
          firstRate,
          possibleSymbol,
        );

        if (finalRate) {
          return finalRate;
        }
      }
    }
    return null;
  }

  private getSecondLevelAnyMatchToCurrency(
    base: string,
    date: number,
    firstRate: number,
    possibleSymbol: string,
  ) {
    // try to get there directly
    const directSymbolData = this.referenceDataCalculation.getReferenceData(
      possibleSymbol,
      base,
      date,
    );

    let currencyRate;
    // check for a direct match
    if (directSymbolData.length > 0) {
      currencyRate = directSymbolData[0].rate;
      const finalRate = firstRate / currencyRate;
      return finalRate;
    }
    // check for a direct reverse match
    const reverseSymbolData = this.referenceDataCalculation.getReferenceData(
      base,
      possibleSymbol,
      date,
    );
    if (reverseSymbolData.length > 0) {
      currencyRate = reverseSymbolData[0].rate;
      const finalRate = firstRate * currencyRate;
      return finalRate;
    }
    // check for a reserve currency match
    currencyRate = this.getReferenceDataFromReserveCurrency(base, possibleSymbol, date);
    if (currencyRate) {
      const finalRate = currencyRate * firstRate;
      return finalRate;
    }

    return null;
  }

  /**
   * Given a quantity and a symbol, work out the normalised value for a given date using market data.
   * Normalisation is calculated to the base currency unless a different currency is supplied as parameter.
   *
   * @param quantity
   * @param date
   * @param symbol
   * @param currency
   */
  normalizeQuantity(
    quantity: number,
    date: Date,
    symbol: string,
    currency?: string,
  ): NormalizationProperties {
    // normalize to the base currency if the to symbol is not supplied
    if (!currency) {
      currency = this._baseCurrency;
    }

    let normalizedValue = quantity;

    const normalizedProperties: NormalizationProperties = {
      value: quantity,
      normalizedValue: normalizedValue,
      valueCurrency: symbol,
      normalizedCurrency: currency,
    };

    const isoDate = new DateFormatPipe().transform(date);
    const unixDate = new Date(isoDate).getTime();

    //const unixDate = date.getTime();
    let rate = this.getConversionRate(symbol, currency, unixDate);

    if (rate === null) {
      const inverseRate = this.getConversionRate(currency, symbol, unixDate);
      if (inverseRate) {
        rate = 1 / inverseRate;
      }
    }

    if (rate) {
      normalizedValue = normalizedValue * rate;
      const normalizedProperties: NormalizationProperties = {
        value: quantity,
        normalizingRate: rate,
        normalizedValue: normalizedValue,
        normalizedCurrency: currency,
      };
      const normalizedRates = this.checkForSymbolRate(symbol, currency, unixDate, rate);
      Object.assign(normalizedProperties, normalizedRates);

      if (normalizedRates.symbolValue) {
        const newValue = quantity * normalizedRates.symbolValue;
        normalizedProperties.value = newValue;
      }

      return normalizedProperties;
    } else {
      this.logService.warning(
        "No normalisation on symbol " +
          symbol +
          " because symbol does not exist in the reference data",
      );
    }
    return normalizedProperties;
  }

  convertSymbolRateToCurrency() {
    //TODO: reimplement this functionality
  }
}

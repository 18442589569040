export class ModelValidator {
  checkVersion(modelVersion: number, documentVersion: number): boolean {
    if (modelVersion !== documentVersion) {
      // throw new Error("Invalid version");
      // eslint-disable-next-line no-console
      console.warn(
        `Data version has changed Model is ${modelVersion} Document is ${documentVersion} for ${this.getModelName()}`,
      );
      return false;
    } else {
      return true;
    }
  }

  // New method to get and print the class name
  getModelName(): string {
    return this.constructor.name;
  }
}

<app-widget
  [title]="'balance' | i18n"
  [iconName]="'dollar'"
  [menu]="balanceWidgetMenu"
  (onClick)="handleBalanceWidget($event)"
>
  <div class="tw-flex tw-items-center sm:tw-justify-center" (click)="toggleEye()">
    <span class="tw-font-roboto-condensed tw-text-4xl tw-flex-1 tw-text-center">
      {{ isEyeOpen ? (balance() | money: getCurrencySymbol() ) : "****" }}
    </span>
    <icon
      [name]="isEyeOpen ? 'eye' : 'eye-off'"
      [size]="6"
      class="tw-ml-2 tw-w-5 tw-mb-2.5 sm:tw-mb-0"
    ></icon>
  </div>
</app-widget>

import { Component, EventEmitter, Input, Output } from "@angular/core";
type CloseButtonType = "plain" | "square";

@Component({
  selector: "close-button",
  templateUrl: "./close-button.component.html",
})
export class CloseButtonComponent {
  @Input() closeButtonType: CloseButtonType;
  @Input() isMatDialog: boolean = true;
  @Output() closeClickedEvent = new EventEmitter<void>();

  handleClose() {
    if (!this.isMatDialog) {
      this.closeClickedEvent.emit();
    }
  }
}

import { Injector } from "@angular/core";
import { addMonths, addYears, subDays } from "date-fns";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { TransactionFilterResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-filter.response";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { TransactionFilter } from "@bitwarden/web-vault/app/models/data/scenario-transaction-filter.data";
import { DateStartPreferences } from "@bitwarden/web-vault/app/models/types/balance.types";
import {
  BalanceByAccounts,
  GranularityProperty,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GlossDateType } from "@bitwarden/web-vault/app/models/types/gloss-date-types";
import { GroupScenarioBalance } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { ScenarioService } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/scenario.service";
import { TransactionCalculationService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.calculation.service";
import { TransactionBalanceHelpers } from "@bitwarden/web-vault/app/services/DataCalculationService/transactionBalances/transactionBalanceHelpers";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { GraphTools } from "@bitwarden/web-vault/app/services/dashboard/graph/graphTools";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";
import { TransactionBalancesWorkerResult } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.result";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

const SCENARIO_LENGTH_IN_MONTHS = 12;
export class GraphScenarioElements {
  // services that we need to inject
  dataRepositoryService: DataRepositoryService;
  dateFormat: DateFormat;
  dateStartPreference: DateStartPreferences;
  scenarioService: ScenarioService;
  transactionCalculationService: TransactionCalculationService;
  logService: LogService;
  webWorkerQueue: WebWorkerQueue;

  // class variables
  activeScenarioGroup: ScenarioGroup;

  constructor(
    private injector: Injector,
    webWorkerQueue: WebWorkerQueue,
  ) {
    this.webWorkerQueue = webWorkerQueue;
  }

  /**
   * TODO: Finish this function once we have the selection process from the Product team
   *
   * @param scenarioGroupID
   */
  async updateScenarioGroup(scenarioGroupID?: string) {
    // set the default scenario to the first one if scenarioGroupID no supplied
    if (!scenarioGroupID) {
      this.dataRepositoryService = this.injector.get(DataRepositoryService);
      const scenarioGroup = await this.dataRepositoryService.getAllScenarioGroups();
      this.activeScenarioGroup = scenarioGroup[0];
      scenarioGroup[0].endDate = this.getScenarioGroupEndDate();
      scenarioGroup[0].anchorPoint = this.getDefaultAnchorPointDate();
      scenarioGroup[0].transactionFilters = await this.getScenarioGroupFilters(scenarioGroup[0]);
    } else {
      // TODO: retrieve the required scenario from the dataRepository by the ID
    }
  }

  /**
   * This function is used to set the starting balance at the anchor point so that
   * we don't need to recalculate it each time for each
   * @param startingBalances
   */
  setStartingBalance(startingBalances: BalanceByAccounts) {
    // set the starting balance at the anchor point
    // this needs to include the accounts children as we will need that for processing
    this.activeScenarioGroup.startingBalances = startingBalances;
  }

  setEndDate(endDate: string) {
    const endDateFormatted: GlossDateType = {
      date: endDate,
      time: null,
      tz: null,
    };
    this.activeScenarioGroup.endDate = endDateFormatted;
  }

  setDatePreferences(dateStartPreference: DateStartPreferences) {
    this.dateStartPreference = dateStartPreference;
  }

  async getScenarioGroupFilters(scenarioGroup: ScenarioGroup): Promise<TransactionFilter> {
    this.dataRepositoryService = this.injector.get(DataRepositoryService);
    const scenarioGroupAccounts: Book[] =
      await this.dataRepositoryService.getScenarioGroupAccounts(scenarioGroup);
    const scenarioGroupTransactions = await this.dataRepositoryService.getAllTransactionsBySymbol(
      scenarioGroup.symbol,
    );
    const filterObject = {
      symbol: scenarioGroup.symbol,
      accounts: scenarioGroupAccounts,
      transactions: scenarioGroupTransactions,
    };
    return new TransactionFilter(new TransactionFilterResponse(filterObject));
  }

  getScenarioGroupEndDate(): GlossDateType {
    this.dateFormat = this.injector.get(DateFormat);
    const todayOneYearString = this.dateFormat.getDateStringFromStamp(
      addYears(new Date(), 1).getTime(),
    );
    return {
      date: todayOneYearString,
      time: null,
      tz: null,
    };
  }

  getScenarioDefaultStartDate(): GlossDateType {
    this.dateFormat = this.injector.get(DateFormat);
    const lastDayPreviousMonth = subDays(new Date().setDate(1), 1);
    const todayString = this.dateFormat.getDateStringFromStamp(lastDayPreviousMonth.getTime());
    return {
      date: todayString,
      time: null,
      tz: null,
    };
  }

  getDefaultAnchorPointDate(): GlossDateType {
    // the default anchor point date is the current date
    this.dateFormat = this.injector.get(DateFormat);
    const todayString = this.dateFormat.getDateStringFromStamp(new Date().getTime());
    return {
      date: todayString,
      time: null,
      tz: null,
    };
  }

  getActiveAnchorPoint(): GlossDateType {
    return this.activeScenarioGroup.anchorPoint;
  }

  /**
   * return a date 6 months out from the anchor point to default to graph to
   */
  getDefaultEndDateAfterAnchorPoint(): GlossDateType {
    this.dateFormat = this.injector.get(DateFormat);
    const anchorPoint = this.getActiveAnchorPoint();
    const sixMonthsAfterAnchor = addMonths(new Date(anchorPoint.date), SCENARIO_LENGTH_IN_MONTHS);
    const endDateSixMonthsLater = subDays(
      new Date(sixMonthsAfterAnchor.getFullYear(), sixMonthsAfterAnchor.getMonth() + 1, 1),
      1,
    );

    const endDateAfterAnchorPoint = this.dateFormat.getDateStringFromStamp(
      endDateSixMonthsLater.getTime(),
    );
    return {
      date: endDateAfterAnchorPoint,
      time: null,
      tz: null,
    };
  }

  getFilterSymbol(): string {
    return this.activeScenarioGroup.symbol;
  }

  /**
   * @deprecated
   */
  async calculateGraphData(
    groupScenarioBalances: GroupScenarioBalance[],
    granularity: GranularityProperty,
    startDate: string,
    endDate: string,
  ): Promise<GroupScenarioBalance[]> {
    /*
    const graphTools = new GraphTools();
    const scenarioStartDate = new Date(startDate);
    const scenarioEndDate = new Date(endDate);

    const balanceGraphGroupings = graphTools.getGraphDates(
      scenarioStartDate,
      scenarioEndDate,
      granularity,
    );

    for (const groupScenarioBalance of groupScenarioBalances) {
      // push the fullTransactionList to the webworker to calculate the balances for the required dates
      const transactionBalanceHelper = new TransactionBalanceHelpers();
      const flattenedTransactions = transactionBalanceHelper.buildTransactionArrays(
        groupScenarioBalance.fullTransactionList,
      );
      const transactionDates = flattenedTransactions.transactionDates;
      const transactionsAmounts = flattenedTransactions.transactionAmounts;

      const graphTransactionDatesBuffer = new Uint32Array(transactionDates).buffer;
      const graphTransactionAmountsBuffer = new Float64Array(transactionsAmounts).buffer;

      await graphTools
        .buildBalanceForGraphing(
          this.webWorkerQueue,
          "scenarioWinnerBalancesForGraph",
          balanceGraphGroupings,
          scenarioStartDate,
          scenarioEndDate,
          graphTransactionDatesBuffer,
          graphTransactionAmountsBuffer,
        )
        .then(
          async (workerMessage: TransactionBalancesWorkerResult) => {
            if (workerMessage.workerType === "TransactionBalancesWorker") {
              if (workerMessage?.balanceAmounts) {
                // TODO: @mmeshel Update this to call the new graphing functions to get the dates and balances to graph
                groupScenarioBalance.graphData = await graphTools.formatDataSets(
                  workerMessage,
                  balanceGraphGroupings,
                  scenarioStartDate,
                  scenarioEndDate,
                  null,
                );
              }
            }
          },
          (error: Error) => {
            return Promise.reject(error);
          },
        );

      const tableTransactionDatesBuffer = new Uint32Array(transactionDates).buffer;
      const tableTransactionAmountsBuffer = new Float64Array(transactionsAmounts).buffer;

      await transactionBalanceHelper
        .buildTransactionTableBalances(
          this.webWorkerQueue,
          "getTableBalancesForScenarios",
          tableTransactionDatesBuffer,
          tableTransactionAmountsBuffer,
        )
        .then(async (workerMessage: TransactionBalancesWorkerResult) => {
          if (workerMessage.workerType === "TransactionBalancesWorker") {
            if (workerMessage?.balanceAmounts) {
              groupScenarioBalance.transactionBalances = transactionBalanceHelper.mapTransactions(
                workerMessage.balanceAmounts,
                groupScenarioBalance.fullTransactionList,
              );
            }
          }
        });
    }
    return groupScenarioBalances;

     */
    return;
  }

  /**
   * @deprecated
   */
  async generateScenarioBalances(
    callback: (scenarioWinners: GroupScenarioBalance[]) => Promise<void>,
  ) {
    this.scenarioService = this.injector.get(ScenarioService);
    // commenting this out as will be deprecated
    /*
    await this.scenarioService.runScenarioGroup(
      this.activeScenarioGroup,
      this.webWorkerQueue,
      callback,
    );
     */
  }
}

import { ModelView } from "../model.view";
import {
  ClassificationModelVersion,
  ClassificationStoreModel,
  DefaultClassificationStoreModel,
} from "@bitwarden/web-vault/app/models/store/classification.store.model";

export class ClassificationView extends ModelView<ClassificationStoreModel> {
  protected version: ClassificationModelVersion;

  name: string;
  generateDefault?: boolean; // when we import data and create accounts if there are no classification-renderer related to transaction
  weight: number;

  constructor(model?: ClassificationStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.name = model.n;
      this.generateDefault = model.gendef;
      this.weight = model.w;
    } else {
      this.version = DefaultClassificationStoreModel.v;
    }
  }

  toStoreModel(): ClassificationStoreModel {
    return undefined;
  }

  clone(): ClassificationView {
    return new ClassificationView(this.toStoreModel());
  }
}

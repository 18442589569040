import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, defer, Observable } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { SourceCategory } from "@bitwarden/web-vault/app/models/data/blobby/source-category";
import { SourceTransaction } from "@bitwarden/web-vault/app/models/data/blobby/source-transaction.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { BookResponse } from "@bitwarden/web-vault/app/models/data/response/book.response";
import { CategoryResponse } from "@bitwarden/web-vault/app/models/data/response/category.response";
import { ClassificationResponse } from "@bitwarden/web-vault/app/models/data/response/classification.response";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";
import { BalanceAlignmentDescription } from "@bitwarden/web-vault/app/models/data/utils/glossBalance.utils";
import {
  BalanceForm,
  TransactionForm,
  UnrecognizedAccount,
} from "@bitwarden/web-vault/app/models/types/account.types";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { TransactionFilter } from "@bitwarden/web-vault/app/models/types/transaction.types";
import { AllocationService } from "@bitwarden/web-vault/app/services/DataCalculationService/allocation/allocation.service";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";
import { ClassificationService } from "@bitwarden/web-vault/app/services/DataService/classification/classification.service";
import { MarketDataService } from "@bitwarden/web-vault/app/services/DataService/market-data/market-data.service";
import { ReferenceDataService } from "@bitwarden/web-vault/app/services/DataService/reference-data/reference-data.service";
import { SourceTransactionService } from "@bitwarden/web-vault/app/services/DataService/source-transactions/source-transaction.service";
import { BlobbyUtils } from "@bitwarden/web-vault/app/services/blobby/blobbyUtils";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper-preference";
import {
  getBalanceAlignmentTransaction,
  getNormalTransaction,
} from "@bitwarden/web-vault/app/shared/utils/helper.transactions/alignment-transactions";

import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { DataServiceAbstraction } from "../data.service.abstraction";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";

@Injectable({
  providedIn: "root",
})
export class TransactionService implements DataServiceAbstraction {
  private savingItemAccounts = new BehaviorSubject<number>(0);
  savingItemAccounts$ = this.savingItemAccounts.asObservable();

  private totalAccountsToProcess = new BehaviorSubject<number>(0);
  totalAccountsToProcess$ = this.totalAccountsToProcess.asObservable();

  private recalculateBalance = new BehaviorSubject<number>(0);
  recalculateBalance$ = this.recalculateBalance.asObservable();

  private transactionStore = inject(TransactionStoreService);

  constructor(
    private dataRepositoryService: DataRepositoryService,
    private allocationService: AllocationService,
    private classificationService: ClassificationService,
    private categoryService: CategoryService,
    private helperPreference: HelperPreference,
    private logService: LogService,
    private referenceDataService: ReferenceDataService,
    private sourceTransactionService: SourceTransactionService,
  ) {}

  /**
   * @deprecated
   */
  async getAll(runValuation = true) {
    return await this.dataRepositoryService.getAllTransactions(runValuation);
  }

  getAccountTransactions(account: AccountView) {
    const transactions = this.transactionStore.enrichTransaction.collection();
    return transactions.filter((transaction) => transaction.accountLink.id === account.id);
  }

  getAllTransactions() {
    return this.transactionStore.enrichTransaction.collection();
  }

  checkCategoryForFilter(
    transaction: Transaction,
    categories: Category[],
    includeEmptyCategories: boolean,
  ): boolean {
    const tCats = transaction.categories;
    return categories.some((category) => {
      if (includeEmptyCategories && tCats.length === 0) {
        return true;
      } else {
        return tCats.some((tCat) => tCat.categoryId === category.id);
      }
    });
  }

  checkClassificationForFilter(
    transaction: Transaction,
    classifications: Classification[],
    includeEmptyClassifications: boolean,
  ): boolean {
    const tClasses = transaction.classifications;
    return classifications.some((classification) => {
      if (includeEmptyClassifications && tClasses.length === 0) {
        return true;
      } else {
        return tClasses.some((tClss) => tClss.classificationId === classification.id);
      }
    });
  }

  checkSymbolForFilter(transaction: Transaction, symbol: string): boolean {
    if (symbol === null || symbol === undefined) {
      return true;
    }
    return transaction.quantity.actualQuantity.symbol === symbol;
  }

  isEmptyFilter(filter: TransactionFilter) {
    const { categories, classifications, accounts, directions } = filter;
    return !!(
      !categories.length ||
      !classifications.length ||
      !accounts.length ||
      !directions.length
    );
  }
  async getTransactionsOfFilter(
    filter: TransactionFilter,
    includeEmptyCategories: boolean,
    includeEmptyClassifications: boolean,
    allUserTransactions?: Transaction[],
  ): Promise<Transaction[]> {
    if (!allUserTransactions) {
      allUserTransactions = await this.getAll();
    }

    const filteredTransactionsByDate = await this.filterByDates(
      filter.startDate,
      filter.endDate,
      allUserTransactions,
    );
    const { categories, classifications, accounts, directions, symbol } = filter;
    if (this.isEmptyFilter(filter)) {
      return [];
    }
    return filteredTransactionsByDate.filter((transaction) => {
      const accountCheck = accounts.some((account) => account.id === transaction.accountId);
      const directionCheck = directions.some((direction) => transaction.direction === direction);
      const categoryCheck = this.checkCategoryForFilter(
        transaction,
        categories,
        includeEmptyCategories,
      );
      const classificationCheck = this.checkClassificationForFilter(
        transaction,
        classifications,
        includeEmptyClassifications,
      );
      const symbolCheck = this.checkSymbolForFilter(transaction, symbol);

      return accountCheck && directionCheck && categoryCheck && classificationCheck && symbolCheck;
    });
  }

  startAccountImport() {
    this.savingItemAccounts = new BehaviorSubject<number>(0);
    this.savingItemAccounts$ = this.savingItemAccounts.asObservable();
  }

  completeAccountProgress() {
    this.savingItemAccounts.complete();
  }

  /**
   * @deprecated
   */
  async getTransactionsEffectedByReferenceData(
    referenceDataStartDate: number,
  ): Promise<Transaction[]> {
    const allTransactions = await this.dataRepositoryService.getAllTransactions();
    return allTransactions.filter((transaction) => {
      const transactionDate = new Date(transaction.transactionDate.date);
      return transactionDate.getTime() >= referenceDataStartDate;
    });
  }

  /**
   * @deprecated
   */
  async getAllSourceTransactions() {
    return await this.dataRepositoryService.getAllSourceTransactions();
  }

  /**
   * @deprecated
   */
  //todo implement
  async create(transaction: Transaction): Promise<Transaction> {
    return Promise.resolve(undefined);
  }

  /**
   * @deprecated
   */
  async delete(transaction: Transaction): Promise<boolean> {
    return await this.dataRepositoryService.deleteTransaction(transaction);
  }

  async bulkDeleteTransactions(transactions: TransactionView[]): Promise<boolean> {
    return await this.transactionStore.transaction.deleteFromVault(transactions);
  }

  /**
   * @deprecated
   */
  async bulkDelete(transactions: Transaction[]): Promise<boolean> {
    const notDeletedTransactions: Transaction[] = [];
    for (const transaction of transactions) {
      const sourceTransaction = await this.sourceTransactionService.get(transaction.sourceId);
      const isDeleted = await this.dataRepositoryService.deleteTransaction(transaction);
      if (!isDeleted) {
        notDeletedTransactions.push(transaction);
      } else if (isDeleted && sourceTransaction) {
        await this.dataRepositoryService.deleteSourceTransaction(sourceTransaction);
      }
    }
    return notDeletedTransactions.length === 0;
  }

  async get(itemId: string): Promise<Transaction> {
    const transaction = await this.getAll(false);
    return transaction.find((t) => t.id === itemId);
  }

  /**
   * @deprecated
   */
  async update(transaction: Transaction, triggerObservable?: boolean): Promise<Transaction> {
    try {
      return await this.dataRepositoryService.updateTransaction(transaction);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async import(transactions: Transaction[]): Promise<boolean> {
    MarketDataService.refreshTransactions = true;
    return await this.dataRepositoryService.importTransactions(transactions);
  }

  /**
   * @deprecated
   */
  generateSymbolMarketData(t: Transaction) {
    const symMarketDataObject = {
      id: `${t.quantity.actualQuantity.symbol}@${t.transactionDate}`,
      date: {
        date: t.transactionDate,
      },
      base: t.quantity.actualQuantity.symbol,
      symbols: {
        [t.quantity.currency]: t.valuationPrice,
        [t.quantity.actualQuantity.symbol]: 1,
      },
    };
    return new ReferenceData(new ReferenceDataResponse(symMarketDataObject));
  }

  generateCurrencyMarketData(t: Transaction) {
    const ccyMarketDataObject = {
      id: `${t.quantity.currency}@${t.transactionDate}`,
      date: { date: t.transactionDate },
      base: t.quantity.convsym,
      symbols: {
        [t.quantity.convsym]: 1,
        [t.quantity.currency]: t.quantity.convrate,
      },
    };

    return new ReferenceData(new ReferenceDataResponse(ccyMarketDataObject));
  }
  async importGeneratedMarketData(finalisedTransactions: Transaction[]): Promise<void> {
    const marketDataFromTransactions: ReferenceData[] = [];
    for (const t of finalisedTransactions) {
      const isSymbolCurrency = t.quantity.actualQuantity.symbol !== t.quantity.currency;
      if (isSymbolCurrency) {
        if (t.valuationPrice) {
          const newMd = this.generateSymbolMarketData(t);
          marketDataFromTransactions.push(newMd);
        }

        if (t.quantity.convsym && t.quantity.convrate) {
          const newMd = this.generateCurrencyMarketData(t);
          marketDataFromTransactions.push(newMd);
        }
      }
    }
    if (marketDataFromTransactions.length) {
      await this.referenceDataService.import(marketDataFromTransactions);
    }
  }

  static isBalanceAlignmentTransaction(trans: Transaction): boolean {
    return !!(
      this.isBalanceAlignmentTransactionOpen(trans) ||
      this.isBalanceAlignmentTransactionClose(trans)
    );
  }

  static isBalanceAlignmentTransactionOpen(trans: Transaction): boolean {
    return !!(trans && trans.description === BalanceAlignmentDescription.Opening);
  }

  static isBalanceAlignmentTransactionClose(trans: Transaction): boolean {
    return !!(trans && trans.description === BalanceAlignmentDescription.Closing);
  }

  static isGeneratedBalanceAlignmentTransaction(trans: Transaction): boolean {
    return !!(trans && trans.description === BalanceAlignmentDescription.Generated);
  }

  static isBalanceAlignmentSourceTransactionOpen(st: SourceTransaction): boolean {
    return !!(st && st.description === BalanceAlignmentDescription.Opening);
  }

  static isBalanceAlignmentSourceTransactionClose(st: SourceTransaction): boolean {
    return !!(st && st.description === BalanceAlignmentDescription.Closing);
  }

  static isBalanceAlignmentSourceTransaction(st: SourceTransaction): boolean {
    return !!(
      TransactionService.isBalanceAlignmentSourceTransactionOpen(st) ||
      TransactionService.isBalanceAlignmentSourceTransactionClose(st)
    );
  }

  static isSourceTransactionExists(
    items: Array<any>,
    existingTransaction: Transaction | SourceTransaction,
    isBalAlignTrans: boolean,
  ): boolean {
    let matched = false;

    items.forEach((item) => {
      const matchAccount = item.account === existingTransaction.accountId;
      const matchTransactionDate = item.transactionDate === existingTransaction.transactionDate;
      const matchDescription = item.description === existingTransaction.description;
      const matchDirection = item.direction === existingTransaction.direction;
      const matchQuantity = item.quantity === existingTransaction.quantity;
      // todo match balance if isBalAlignTrans

      if (
        matchAccount &&
        matchTransactionDate &&
        matchDescription &&
        (matchDirection || isBalAlignTrans) &&
        (matchQuantity || isBalAlignTrans)
      ) {
        matched = true;
      }
    });

    return matched;
  }

  /**
   *
   * TODO: This will likely be replaced or updated once we have the query function for blobby working
   * Filter a list of transactions by start date and end date inclusively. Get all the transactions if a list of
   * transactions is not supplied
   *
   * @param {string} startDate - the start date
   * @param {string} endDate - the end date
   * @param {Array|null} transactions - supplied list of transactions
   */
  async filterByDates(
    startDate: string,
    endDate: string,
    transactions?: Array<Transaction> | null,
  ): Promise<Array<Transaction>> {
    const filteredTransactions: Array<Transaction> = [];
    /** Get all the transactions if transactions are not supplied */
    if (!transactions) {
      transactions = await this.getAll();
    }

    /** Filter the list of transactions by the start and end date */
    if (startDate === null && endDate === null) {
      return filteredTransactions;
    }

    const startJSDate = BlobbyUtils.setDateToYMD(new Date(startDate));
    const endJSDate = BlobbyUtils.setDateToYMD(new Date(endDate));

    for (const transaction of transactions) {
      const transactionDate = BlobbyUtils.setDateToYMD(new Date(transaction.transactionDate.date));

      if (!(transactionDate < startJSDate || transactionDate > endJSDate)) {
        filteredTransactions.push(transaction);
      }
    }
    return filteredTransactions;
  }

  /** generate SplitCategoryType for the category-renderer that comes with the transaction*/
  async getTransactionSplitCategories(
    identifiedCategories: SplitCategoryType[],
    newCategories: Category[],
    categoryInTransaction: SplitCategoryType,
  ): Promise<SplitCategoryType> {
    const existingCategories = await this.dataRepositoryService.getAllCategories();

    /** Set the SplitCategory as if it is a new one , and  not an existing one .Pre-generate its id for bulk creation*/
    const splitCategory: SplitCategoryType = {
      categoryId: crypto.randomUUID(),
      weight: 1,
      roundingDefault: false,
      name: categoryInTransaction.name,
    };
    /** Holds the category-renderer that is already in the system and is also in transaction */
    const categoryInExistingOnes = existingCategories.filter(
      (cat) => cat.name === categoryInTransaction.name,
    );

    /** holds the category-renderer that are new to the system but has been processed before . No to process more than once*/
    const categoryInNewCategories = newCategories.filter(
      (cat) => cat.name === categoryInTransaction.name,
    );

    /** category-renderer does not exist*/
    if (categoryInExistingOnes.length === 0 && categoryInNewCategories.length === 0) {
      /** set the new category-renderer's id to splitCategory.categoryId, defined at the beginning so the ids match after creating category-renderer*/
      categoryInTransaction._id = splitCategory.categoryId;
      const category = new Category(new CategoryResponse(categoryInTransaction));
      newCategories.push(category);
    } else {
      /** If category-renderer exists then change the splitCategory's id to the existing category-renderer id , so they match .*/
      splitCategory.categoryId =
        categoryInExistingOnes.length > 0
          ? categoryInExistingOnes[0].id
          : categoryInNewCategories[0].id;
    }

    return splitCategory;
  }

  /**
   * @deprecated
   */
  /** generate SplitClassificationType for the category-renderer that comes with the transaction */
  async getTransactionSplitClassifications(
    identifiedClassifications: SplitClassificationType[],
    newClassifications: Classification[],
    classificationInTransaction: SplitClassificationType,
  ): Promise<SplitClassificationType> {
    const existingClassifications = await this.dataRepositoryService.getAllClassifications();

    /** Set the SplitClassification as if it is a new one , and  not an existing one .Pre-generate its id for bulk creation*/
    const splitClassification: SplitClassificationType = {
      classificationId: crypto.randomUUID(),
      weight: 1,
      roundingDefault: false,
      name: classificationInTransaction.name,
    };
    /** Holds the classification that is already in the system and is also in transaction */
    const classificationInExistingOnes = existingClassifications.filter(
      (cls) => cls.name === classificationInTransaction.name,
    );

    /** holds the classification that are new to the system but has been processed before . No to process more than once*/
    const classificationInNewClassifications = newClassifications.filter(
      (cls) => cls.name === classificationInTransaction.name,
    );

    /** classification does not exist*/
    if (
      classificationInExistingOnes.length === 0 &&
      classificationInNewClassifications.length === 0
    ) {
      /** set the new classification's id to splitClassification.classificationId, defined at the beginning so the ids match after creating classification*/
      classificationInTransaction._id = splitClassification.classificationId;
      const classification = new Classification(
        new ClassificationResponse(classificationInTransaction),
      );
      newClassifications.push(classification);
    } else {
      /** If classification exists then change the splitClassification's id to the existing classification id , so they match .*/
      splitClassification.classificationId =
        classificationInExistingOnes.length > 0
          ? classificationInExistingOnes[0].id
          : classificationInNewClassifications[0].id;
    }

    return splitClassification;
  }
  async getTransactionIdentifiedCategories(
    transaction: Transaction,
    newCategories: Category[],
  ): Promise<SplitCategoryType[]> {
    /** holds the categories whose id is set. If it is a new one generate an id and set it to the category-renderer if it is an existing one then just use that id */
    const identifiedCategories: SplitCategoryType[] = [];
    /** loop over the categories that comes with the transaction when importing */
    for (const categoryInTransaction of transaction.categories) {
      if (categoryInTransaction) {
        /** holds the SplitCategoryType  of the category-renderer that comes with the transaction*/
        const splitCategory: SplitCategoryType = await this.getTransactionSplitCategories(
          identifiedCategories,
          newCategories,
          categoryInTransaction,
        );
        identifiedCategories.push(splitCategory);
      }
    }

    return identifiedCategories;
  }

  async getTransactionIdentifiedClassification(
    transaction: Transaction,
    newClassifications: Classification[],
  ): Promise<SplitClassificationType[]> {
    /** holds the classifications whose id is set. If it is a new one generate an id and set it to the classification, if it is an existing one then just use that id */
    const identifiedClassifications: SplitClassificationType[] = [];
    /** loop over the classifications that comes with the transaction when importing */
    for (const classificationInTransaction of transaction.classifications) {
      if (classificationInTransaction) {
        /** holds the SplitClassificationType  of the classification that comes with the transaction*/
        const splitClassification: SplitClassificationType =
          await this.getTransactionSplitClassifications(
            identifiedClassifications,
            newClassifications,
            classificationInTransaction,
          );
        identifiedClassifications.push(splitClassification);
      }
    }

    return identifiedClassifications;
  }

  /** Bulk creates categories from transactions' imports */
  async createCategoriesFromTransaction(transactions: Array<Transaction>) {
    /** Holds the transactions whose categories property is settled properly */
    const categorisedTransactions: Transaction[] = [];
    /** holds categories that are new to the system . To bulk save later */
    const newCategories: Category[] = [];
    for (const transaction of transactions) {
      /** set each transaction's categories property properly */
      if (transaction.categories) {
        transaction.categories = await this.getTransactionIdentifiedCategories(
          transaction,
          newCategories,
        );
      }
      categorisedTransactions.push(transaction);
    }

    /** If there are new categories save them all at once*/
    if (newCategories.length > 0) {
      await this.dataRepositoryService.bulkCreateCategories(newCategories);
      const newSourceCategories = newCategories.map(
        (cat) => new SourceCategory({ name: cat.name, categoryId: cat.id }),
      );
      await this.dataRepositoryService.bulkCreateSourceCategories(newSourceCategories);
    }

    return categorisedTransactions;
  }

  /** Bulk creates classifications from transactions' imports */
  async createClassificationsFromTransaction(transactions: Array<Transaction>) {
    /** Holds the transactions whose classifications property is settled properly */
    const classifiedTransactions: Transaction[] = [];
    /** holds classifications that are new to the system . To bulk save later */
    const newClassifications: Classification[] = [];
    for (const transaction of transactions) {
      /** set each transaction's classifications property properly */
      if (transaction.classifications) {
        transaction.classifications = await this.getTransactionIdentifiedClassification(
          transaction,
          newClassifications,
        );
      }
      classifiedTransactions.push(transaction);
    }

    /** If there are new classifications save them all at once */
    if (newClassifications.length > 0) {
      await this.dataRepositoryService.bulkCreateClassifications(newClassifications);
    }

    return classifiedTransactions;
  }

  async createAccountsFromTransaction(
    transactions: Array<any>,
    newAccounts: UnrecognizedAccount[],
  ) {
    const bookedTransactions: Transaction[] = [];
    let counter = 0;

    if (newAccounts.length > 0) {
      this.totalAccountsToProcess.next(newAccounts.length);
    }

    for (const transaction of transactions) {
      const existingAccounts: Book[] = await this.dataRepositoryService.getAllBooks();
      const accountInExistingOnes = existingAccounts.filter((account) => {
        return account.name === transaction.accountId;
      });
      if (accountInExistingOnes.length === 0) {
        let accountClassifications: SplitClassificationType[] = [];
        let accountCategories: SplitCategoryType[] = [];
        //Set classifications...
        if (Array.isArray(transaction.classifications) && transaction.classifications.length > 0) {
          //there are classifications in transaction
          transaction.classifications[0].roundingDefault = true;
          accountClassifications = transaction.classifications;
        } else {
          //there are no classifications in transaction
          const defaultClassification =
            await this.classificationService.getGeneralDefaultClassification();
          accountClassifications.push({
            classificationId: defaultClassification.id,
            weight: 1,
            roundingDefault: true,
            name: defaultClassification.name,
          });
        }

        //Set categories
        if (Array.isArray(transaction.categories) && transaction.categories.length > 0) {
          //there are categories in transaction
          transaction.categories[0].roundingDefault = true;
          accountCategories = transaction.categories;
        } else {
          //there are no categories in transaction
          const defaultCategory: Category = await this.categoryService.getGeneralDefaultCategory();
          accountCategories.push({
            categoryId: defaultCategory.id,
            weight: 1,
            roundingDefault: true,
            name: defaultCategory.name,
          });
        }
        const accountCurrency = await this.getAccountCurrencyFromTransaction(
          transaction,
          newAccounts,
        );
        const accountTimeZone = this.getAccountTimeZoneFromTransaction(transaction, newAccounts);
        const institutionAccount = newAccounts.find(
          (newAccount) => newAccount.accountId === transaction.accountId,
        );

        const bookObj: any = {
          name: transaction.accountId, // because when coming from excell ot plaid accountId refres to its name
          currency: accountCurrency,
          timezone: accountTimeZone,
          defaultClassifications: accountClassifications,
          defaultCategories: accountCategories,
          balance: transaction.balance.normalized ?? 0,
          institutionLink: institutionAccount.institutionAccountLink,
        };
        if (institutionAccount._id) {
          bookObj._id = institutionAccount._id;
        }

        const newAccount = new Book(new BookResponse(bookObj));
        await this.dataRepositoryService.createBook(newAccount);
        transaction.accountId = newAccount.id;
        bookedTransactions.push(transaction);
        counter++;
        if (this.savingItemAccounts) {
          this.savingItemAccounts.next(counter);
        }
        if (counter === newAccounts.length) {
          this.savingItemAccounts.complete();
          this.totalAccountsToProcess.next(0);
        }
      } else {
        // NOTE !! this means account exists and it has default classifications.
        /*
        TODO when a classification is being deleted check if it is any account's default Classification
        TODO ....cuz classification and category-renderer default values are necessary for account
   */
        transaction.accountId = accountInExistingOnes[0].id;
        // if a classification is not provided with the import, then use the default classification for the account
        if (transaction.classifications.length === 0) {
          transaction.classifications = accountInExistingOnes[0].defaultClassifications;
        }

        // if a category-renderer is not provided with the import, then use the default classification for the account
        if (transaction.categories.length === 0) {
          transaction.categories = accountInExistingOnes[0].defaultCategories;
        }
        bookedTransactions.push(transaction);
      }
    }

    return bookedTransactions;
  }

  async getAccountCurrencyFromTransaction(
    transaction: Transaction,
    newAccounts: UnrecognizedAccount[],
  ): Promise<string> {
    let currency = transaction.quantity.currency;
    if (!currency) {
      currency = await this.helperPreference.getBaseCurrency();
    }
    for (const newAccount of newAccounts) {
      if (
        newAccount.accountId === transaction.accountId &&
        newAccount.selectedCurrency &&
        newAccount.selectedCurrency.length > 0
      ) {
        currency = newAccount.selectedCurrency;
        break;
      }
    }

    return currency;
  }

  getAccountTimeZoneFromTransaction(
    transaction: Transaction,
    newAccounts: UnrecognizedAccount[],
  ): string {
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    for (const newAccount of newAccounts) {
      if (
        newAccount.accountId === transaction.accountId &&
        newAccount.selectedCurrency &&
        newAccount.selectedCurrency.length > 0
      ) {
        timeZone = newAccount.selectedTimeZone;
        break;
      }
    }

    return timeZone;
  }

  loadTransactions$(): Observable<Transaction[]> {
    return defer(() => {
      return this.getAll().then((transactions) => {
        return transactions;
      });
    });
  }

  /**
   * @deprecated
   */
  async createAccountAlignmentTransaction(
    account: AccountView,
    form: BalanceForm,
    isUseFormCurrency = false,
  ): Promise<TransactionView> {
    const transactionView = getBalanceAlignmentTransaction(account, form, isUseFormCurrency);
    //now only save one item
    const isSave = this.transactionStore.transaction.save(transactionView);
    if (isSave) {
      return transactionView;
    }
    // const isImport = await this.import([transaction]);
    // if (isImport) {
    //   return transaction;
    // }
  }

  // todo no dependency on alignments
  createNormalTransaction(account: AccountView, form: TransactionForm) {
    const transactionView = getNormalTransaction(account, form);
    //now only save one item
    const isSave = this.transactionStore.transaction.save(transactionView);
    if (isSave) {
      return transactionView;
    }
    // const isImport = await this.import([transaction]);
    // if (isImport) {
    //   return transaction;
    // }
  }
}

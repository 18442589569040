import * as d3 from "d3";

export class DataPoints {
  private balanceGraph: any;
  private xScale: d3.ScaleTime<number, number, never>;
  private yScale: d3.ScaleLinear<number, number, never>;

  constructor(
    balanceGraph: any,
    xScale: d3.ScaleTime<number, number, never>,
    yScale: d3.ScaleLinear<number, number, never>,
  ) {
    this.balanceGraph = balanceGraph;
    this.xScale = xScale;
    this.yScale = yScale;
  }

  generatePointData(balancesByTime: Map<string, number>): number[][] {
    const dataPoints: number[][] = [];

    for (const data of balancesByTime) {
      const xValue = this.xScale(new Date(Number(data[0]) * 1000));
      if (data[1] !== null) {
        const yValue = this.yScale(data[1]);
        dataPoints.push([xValue, yValue]);
      }
    }
    return dataPoints;
  }

  updatePoints(
    name: string,
    colour: string,
    pointData: number[][],
    opacity: number,
    fill = "white",
  ) {
    const points = this.balanceGraph.selectAll(`#${name}`).data(pointData);

    points
      .join("circle")
      .attr("id", name)
      .attr("cx", (d: number[]) => d[0])
      .attr("cy", (d: number[]) => d[1])
      .attr("r", 4)
      .attr("stroke", colour)
      .attr("stroke-opacity", opacity)
      .style("stroke-width", "3px")
      .attr("fill", fill);
  }
}

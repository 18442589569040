import { GeneratedCollection } from "../generated.collection.abstraction";
import { ScenarioDisplayView } from "../../../models/view/scenario/scenario-display.view";
import { computed, inject, signal, Signal, WritableSignal } from "@angular/core";
import { Observable } from "rxjs";
import { ScenarioView } from "@bitwarden/web-vault/app/models/view/scenario/scenario.view";
import { NormalizeTransaction } from "@bitwarden/web-vault/app/models/view/transaction/normalize/normalizeTransaction.utils";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import { Allocation } from "@bitwarden/web-vault/app/models/data/allocation.data";

const mockData: ScenarioDisplayView[] = [
  {
    ScenarioGroupId: "ScenarioGroupId 0",
    scenarioID: "scenarioID",
    scenarioType: "currentInterestRate",
    scenarioName: "currentAccountGrowth",
    createdRecords: {
      accounts: [],
      transactions: [],
      runningAccountBalances: {},
    },
    fullTransactionList: [],
    finalBalanceAmount: 0,
    graphDates: [],
    graphBalances: [],
  },
  {
    ScenarioGroupId: "ScenarioGroupId 1",
    scenarioID: "scenarioID",
    scenarioType: "currentInterestRate",
    scenarioName: "existingBestScenario",
    createdRecords: {
      accounts: [],
      transactions: [],
      runningAccountBalances: {},
    },
    fullTransactionList: [],
    finalBalanceAmount: 0,
    graphDates: [],
    graphBalances: [],
  },
  {
    ScenarioGroupId: "ScenarioGroupId 2",
    scenarioID: "scenarioID",
    scenarioType: "currentInterestRate",
    scenarioName: "bestScenario",
    createdRecords: {
      accounts: [],
      transactions: [],
      runningAccountBalances: {},
    },
    fullTransactionList: [],
    finalBalanceAmount: 0,
    graphDates: [],
    graphBalances: [],
  },
];

export class ScenarioDataGeneratedCollection extends GeneratedCollection<ScenarioDisplayView> {
  protected log: LogService = inject(LogService);
  protected userStoreService: UserStoreService = inject(UserStoreService);
  protected transactionStoreService: TransactionStoreService = inject(TransactionStoreService);

  protected _currentScenario: WritableSignal<ScenarioView> = signal<ScenarioView>(null);
  currentScenario: Signal<ScenarioView> = this._currentScenario.asReadonly();

  protected _collection: WritableSignal<ScenarioDisplayView[]> =
    signal<ScenarioDisplayView[]>(mockData);
  collection: Signal<ScenarioDisplayView[]> = this._collection.asReadonly();
  collection$: Observable<ScenarioDisplayView[]>;

  currentAccountGrowth = computed(() => {
    if (Array.isArray(this.collection()) && this.collection().length > 0) {
      return this.collection()[0];
    } else {
      return null;
    }
  });

  existingBestScenario = computed(() => {
    if (Array.isArray(this.collection()) && typeof this.collection()[1] !== "undefined") {
      return this.collection()[1];
    } else {
      return null;
    }
  });

  bestScenario = computed(() => {
    if (Array.isArray(this.collection()) && typeof this.collection()[2] !== "undefined") {
      return this.collection()[2];
    }
  });

  update(collection: ScenarioDisplayView[]): void {
    this._collection.set(collection);
  }

  clear(): void {
    this._collection.set(null);
  }

  setCurrentScenario(scenario: ScenarioView): void {
    this._currentScenario.set(scenario);
  }

  enrichTransactions() {
    const preference = this.userStoreService.preferences.preferenceView();
    const referenceData = this.transactionStoreService.referenceData.referenceDataViews();

    const normalizeTransaction = new NormalizeTransaction(
      this.log,
      preference.baseCurrency,
      referenceData,
    );

    for (const scenario of this._collection()) {
      for (const transaction of scenario.fullTransactionList) {
        Allocation.setAllocation(transaction);
        normalizeTransaction.normalizeImportedTransaction(transaction);
      }
    }
  }
}

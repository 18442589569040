import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { ConnectorService } from "@bitwarden/web-vault/app/services/DataService/connector/connector.service";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";

import { InstitutionsAddEditComponent } from "../manage-institutions/institutions-add-edit/institutions-add-edit.component";
import { manageButtonProperties } from "../manage.components";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";

@Component({
  selector: "app-manage-institutions",
  templateUrl: "./manage-institutions.component.html",
})
export class ManageInstitutionsComponent implements OnInit, OnDestroy {
  userStoreService: UserStoreService;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  loading = true;
  dialogueRef: MatDialogRef<InstitutionsAddEditComponent>;
  existingInstitutions: InstitutionView[];
  connector: Connector;

  @ViewChild("inputElement") inputElement: ElementRef;
  constructor(
    public dialog: MatDialog,
    private globalService: GlobalService,
    private connectorService: ConnectorService,
    private confirmationDialogService: ConfirmationDialogService,
    private i18nService: I18nService,
  ) {
    this.userStoreService = inject(UserStoreService);
  }

  addInstitutionButton = new ActionButton({
    ...manageButtonProperties,
    text: this.i18nService.t("addInstitution"),
    onClick: this.addInstitution.bind(this),
  });

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  async ngOnInit(): Promise<void> {
    try {
      this.existingInstitutions = this.userStoreService.institutions.institutionViews();
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  addInstitution() {
    this.edit(null);
  }

  async edit(institution: InstitutionView): Promise<void> {
    const dialogRef = this.dialog.open(InstitutionsAddEditComponent, {
      width: "800px", // for mat-dialog-container, built in max-width: 80vw; so this width works for smaller screens too
      data: {
        institution,
        actionSucceeded: this.actionSucceeded.bind(this),
        delete: this.delete.bind(this, institution),
      },
      autoFocus: ".auto-focus",
      backdropClass: "custom-backdrop-class",
      disableClose: true,
    });

    this.dialogueRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((newInstitutions) => {
        if (newInstitutions) {
          this.existingInstitutions = newInstitutions;
        }
      });
  }

  async delete(institution: InstitutionView) {
    const confirmed = await this.confirmationDialogService.confirmFor(
      ConfirmationEnum.deletingInstitution,
    );
    if (confirmed) {
      try {
        this.loading = true;
        /** @Sinan if the institution is linked to any Accounts do not let user delete it */
        const deleted = await this.userStoreService.institutions.delete(institution);

        if (deleted) {
          await this.actionSucceeded("institutionRemoved");
        }
      } catch (e) {
        this.globalService.showErrorMessage("errorOccurred", e);
      } finally {
        this.loading = false;
      }
    }
  }

  async actionSucceeded(actionMessage: string) {
    this.existingInstitutions = this.userStoreService.institutions.institutionViews();
    this.dialogueRef?.close(this.existingInstitutions);
    this.globalService.showSuccessMessage("succeeded", actionMessage);
  }

  async testConnector() {
    this.loading = true;
    try {
      await this.connectorService.testConnector();
      this.globalService.showSuccessMessage("succeeded", "connectorTested");
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    } finally {
      this.loading = false;
    }
  }
}

import { Component, EventEmitter, inject, Injector, OnDestroy, Output } from "@angular/core";
import { filter, Subject } from "rxjs";

import { BestScenarioStepper } from "@bitwarden/web-vault/app/components/best-scenario-dialog/best-scenario-stepper";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { EarningDataCard } from "@bitwarden/web-vault/app/models/types/component.types";
import { StepperAction } from "@bitwarden/web-vault/app/models/types/stepper.types";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { ScenarioDisplayView } from "@bitwarden/web-vault/app/models/view/scenario/scenario-display.view";
import { ScenarioStoreService } from "@bitwarden/web-vault/app/services/store/scenario/scenario.store.service";

@Component({
  selector: "app-move-asset",
  templateUrl: "./move-asset.component.html",
})
export class MoveAssetComponent extends BestScenarioStepper implements OnDestroy {
  @Output() onAction = new EventEmitter<StepperAction>();
  private destroy$ = new Subject<void>();
  // private dashboardService: DashboardService;

  private scenarioStore: ScenarioStoreService = inject(ScenarioStoreService);

  private scenarioObservable$ = toObservable(this.scenarioStore.interestScenario.collection);

  earningsCard: EarningDataCard;

  nextBtnOptions = new ActionButton({
    text: this.i18n.t("next"),
    type: "primary",
    testId: "stepperNextButton",
  });

  prevBtnOptions = new ActionButton({
    text: this.i18n.t("previous"),
    type: "secondary",
    testId: "stepperPrevButton",
  });

  constructor(injector: Injector) {
    super(injector);
    // this.dashboardService = injector.get(DashboardService);
    this.scenarioObservable$
      .pipe(
        takeUntilDestroyed(),
        filter(
          (scenarioObservableValue) => scenarioObservableValue[0].fullTransactionList.length > 0,
        ),
      )
      .subscribe((scenarioDisplayViews: ScenarioDisplayView[]) => {
        this.scenarioDisplayView = scenarioDisplayViews;
        if (this.scenarioDisplayView) {
          this.setUpStep().then(() => {
            this.updateData();
          });
        }
      });
  }

  updateData() {
    this.earningsCard = {
      header: this.i18n.t("move"),
      earningAmount: this.helpInfo.transferAmount,
      currency: this.helpInfo.symbol,
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

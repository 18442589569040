import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { ScenarioGroupStoreModel } from "../../../../models/store/scenario-group.store.model";
import { ScenarioGroupResponse } from "../../../../models/data/response/scenario-group.response";
import { ScenarioGroup } from "../../../../models/data/scenario-group.data";
import { scenarioGroup_v0_to_v1 } from "../model-dto/scenario-group";

export class ScenarioGroupParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Category parser version ${version}`);
    }

    if (version === 0) {
      return ScenarioGroupParser_v0;
    } else {
      return ScenarioGroupParser_v1;
    }
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const ScenarioGroupParser_v1 = (scenarioGroupObj: any): any =>
  scenarioGroupObj as ScenarioGroupStoreModel;
const ScenarioGroupParser_v0 = (scenarioGroupObj: any): any => {
  const response = new ScenarioGroupResponse(scenarioGroupObj);
  const scenarioGroup = new ScenarioGroup(response);
  const upgraded = scenarioGroup_v0_to_v1(scenarioGroup);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = scenarioGroupObj.vid ?? "";
  return ScenarioGroupParser_v1(upgraded);
};

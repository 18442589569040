import { Injectable, Injector, signal, WritableSignal, inject, Signal } from "@angular/core";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { View as ViewEnum } from "@bitwarden/web-vault/app/models/enum/view.enum";
import { ScenarioStoreService } from "@bitwarden/web-vault/app/services/store/scenario/scenario.store.service";
import { ScenarioTypes } from "@bitwarden/web-vault/app/models/enum/scenario.types";

@Injectable({
  providedIn: "root",
})

/**@Sinan divide this service into  todayViewService, pastViewService and futureViewService */
export class ViewsService {
  private _view: WritableSignal<ViewEnum> = signal<ViewEnum>(ViewEnum.TODAY);
  private _account: WritableSignal<Book> = signal<Book>(null);
  private _scenarioType: WritableSignal<ScenarioTypes> = signal<ScenarioTypes>(
    ScenarioTypes.BestNewInterestRate,
  );
  currentScenarioType: Signal<ScenarioTypes> = this._scenarioType.asReadonly();
  private scenarioStoreService = inject(ScenarioStoreService);

  constructor(private injector: Injector) {
    // listen on refresh changing to true from false on scenario and run the scenario if the view is set to future
    this.scenarioStoreService.refresh$.subscribe((refresh) => {
      if (refresh === true && this.view === ViewEnum.FUTURE) {
        this.rerunScenarioStoreCalculations();
      }
    });
  }

  set view(view: ViewEnum) {
    this._view.set(view);

    if (this.view === ViewEnum.FUTURE) {
      this.rerunScenarioStoreCalculations();
    }
  }

  get view(): ViewEnum {
    return this._view();
  }

  set account(account: Book) {
    this._account.set(account);
  }

  get account(): Book {
    return this._account();
  }

  rerunScenarioStoreCalculations() {
    this.scenarioStoreService.refreshScenarioData();
  }

  set scenarioType(value: ScenarioTypes) {
    this._scenarioType.set(value);
  }

  get scenarioType() {
    return this._scenarioType();
  }
}

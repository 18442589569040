export type StateControlGenericType = Record<string, string | string[]>;

export abstract class StateControlAbstraction {
  abstract clear(): void;
}

export type StateControlCollection<Type extends AllowedCollectionType> = {
  [n in keyof Type]: StateControlAbstraction;
};

type AllowedCollectionType =
  | FilterCollectionType
  | GroupingCollectionType
  | PeriodCollectionType
  | AppDomainType
  | AppState;

export interface FilterCollectionType {
  filters: StateControlAbstraction;
}

export interface GroupingCollectionType {
  groupings: StateControlAbstraction;
}
export interface PeriodCollectionType {
  period: StateControlAbstraction;
}

export interface AppDomainType {
  domain: StateControlAbstraction;
}

export interface AppState {
  state: StateControlAbstraction;
}

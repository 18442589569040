import { Component, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { dayGranularity, GranularityProperty } from "../../../models/types/balanceGroupingTypes";
import { CalculationStoreService } from "../../../services/store/calculation/calculation.store.service";
import { filter, Observable } from "rxjs";
import { DeviceDetectorService } from "ngx-device-detector";
import "./balance-chart.component.scss";
import * as d3 from "d3";

import { Scales } from "@bitwarden/web-vault/app/components/charts/shared/scales";
import { Axis } from "@bitwarden/web-vault/app/components/charts/shared/axis";
import { DataLine } from "@bitwarden/web-vault/app/components/charts/shared/data-line";
import { DataPoints } from "@bitwarden/web-vault/app/components/charts/shared/data-points";
import { debounce } from "lodash";

@Component({
  selector: "app-balance-chart",
  templateUrl: "./balance-chart.component.html",
  styles: ["balance-chart.component.scss"],
})
export class BalanceChartComponent {
  // subscribe to signal stores
  private calculationStoreService = inject(CalculationStoreService);
  private balanceByTime$: Observable<Map<string, number>> = toObservable(
    this.calculationStoreService.balanceByTime.balances,
  );
  private deviceDetectorService = inject(DeviceDetectorService);

  // graph elements
  private svg: any;
  private graphContainer: any;
  private balanceGraph: any;

  private dimension: { width: number; height: number };
  private margin: { top: number; bottom: number; left: number; right: number };
  private innerHeight: number;
  private innerWidth: number;

  // graph dimensions
  private dimensions = {
    desktop: {
      width: 590,
      height: 300,
    },
    mobile: {
      width: 590,
      height: 300,
    },
  };

  private margins = {
    desktop: {
      top: 0,
      bottom: 30,
      left: 50,
      right: 20,
    },
    mobile: {
      top: 0,
      bottom: 10,
      left: 10,
      right: 10,
    },
  };

  private outerWidth = this.dimensions.desktop.width;
  private outerHeight = this.dimensions.desktop.height;

  private markerLine: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private xScale: d3.ScaleTime<number, number, never>;
  private yScale: d3.ScaleLinear<number, number, never>;

  // Graph Elements controlled by graph element classes
  private graphElementsInitialized = false;
  private scales: Scales;
  private axis: Axis;
  private dataLine: DataLine;
  private dataPoints: DataPoints;

  // Graph Data
  private balancePointData: number[][] = [];
  private balancesByTime: Map<string, number>;

  /**
   * Data to be passed to the balance HUD component
   */
  hudIndex: number;
  granularity: GranularityProperty;

  constructor() {
    this.balanceByTime$
      .pipe(
        filter((x) => x !== null || typeof x !== "undefined"),
        takeUntilDestroyed(),
      )
      .subscribe(this.updateGraph.bind(this));
  }

  ngOnInit() {
    this.setGraphDimensions();
    this.initGraphContainers();
    this.updateGraph();
  }

  /**
   * Triggers when the balance numbers have updated on the store
   *
   */
  updateGraph() {
    // get the granularity from the groupings signal
    this.updateGranularity();

    // Create a local copy of the balance by time Map
    this.balancesByTime = this.calculationStoreService.balanceByTime.clone();

    // if the granularity is days, then we need to massage the times to be start of the day or the plotting will look
    // like the next day as they are closing balance timestamps for the end of the day
    if (this.granularity == dayGranularity) {
      const startOfDayTimes: Record<string, number> = {};
      const secondsToSubtract = 86400 - 1;
      this.balancesByTime.forEach((amount, time) => {
        const startOfDay = Number(time) - secondsToSubtract;
        startOfDayTimes[startOfDay] = amount;
      });

      this.balancesByTime = new Map(Object.entries(startOfDayTimes));
    }

    const startDate = this.calculationStoreService.period.selected()?.startDate;
    const endDate = this.calculationStoreService.period.selected()?.endDate;

    if (!this.balancesByTime) {
      return;
    }

    if (!this.graphElementsInitialized) {
      this.initGraphElements();
    }

    this.drawScales(startDate, endDate, this.balancesByTime);
    this.drawLines(this.balancesByTime);
    this.drawPoints(this.balancesByTime);

    if (this.balancePointData.length > 0) {
      this.setHudData(this.balancePointData.length - 1);
    }
  }

  updateGranularity() {
    this.granularity = this.calculationStoreService.groupings.selected().granularity;
  }

  initGraphContainers() {
    this.initSVG();
    this.initGroupings();
    this.addMouseMoveEvent();
  }

  // <editor-fold desc="Initialization Functions">
  private initSVG() {
    this.graphContainer = d3.select("div#balanceLineChart");
    this.svg = this.graphContainer
      .append("svg")
      .attr("id", "balanceGraphContainer")
      .attr("viewBox", `0 0 ${this.outerWidth} ${this.outerHeight}`);
  }

  private initGroupings() {
    // parent grouping for graph elements
    this.balanceGraph = this.svg
      .append("g")
      .attr("id", "balanceLineChartGroup")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`);
  }

  private initGraphElements() {
    this.initScales();
    this.initAxis();
    this.initDataPoints();
    this.initDataLine();
    this.initMarker();
  }

  private initMarker() {
    if (!this.markerLine && this.dataLine) {
      // only create the marker if it hasn't been created
      this.markerLine = this.dataLine.createMarkerLine(this.innerHeight);
    }
  }

  private initScales() {
    this.scales = new Scales();
    this.xScale = this.scales.xScale;
    this.yScale = this.scales.yScale;
  }

  private initAxis() {
    this.axis = new Axis(
      this.balanceGraph,
      this.xScale,
      this.yScale,
      this.deviceDetectorService.isMobile(),
    );
  }

  private initDataPoints() {
    this.dataPoints = new DataPoints(this.balanceGraph, this.xScale, this.yScale);
  }

  private initDataLine() {
    this.dataLine = new DataLine(this.balanceGraph, this.xScale, this.yScale);
  }

  private setGraphDimensions() {
    if (this.deviceDetectorService.isMobile()) {
      this.dimension = this.dimensions.mobile;
      this.margin = this.margins.mobile;
    } else {
      this.dimension = this.dimensions.desktop;
      this.margin = this.margins.desktop;
    }
    this.innerHeight = this.dimension.height - this.margin.top - this.margin.bottom;
    this.innerWidth = this.dimension.width - this.margin.left - this.margin.right;
  }

  // </editor-fold desc="Initialization Functions">

  // <editor-fold desc="Graph Drawing Functions">
  private drawScales(startDate: Date, endDate: Date, balancesByTime: Map<string, number>) {
    const [minYAxisValue, maxYAxisValue] = Scales.calculateMaxMinYAxisValues(balancesByTime);

    if (!this.graphElementsInitialized) {
      this.scales.setScales(
        this.innerWidth,
        this.innerHeight,
        minYAxisValue,
        maxYAxisValue,
        startDate,
        endDate,
      );

      this.axis.drawAxis(
        this.innerHeight,
        startDate,
        endDate,
        this.granularity,
        [balancesByTime],
        "",
        "",
      );
      this.graphElementsInitialized = true;
    } else {
      this.scales.setScales(
        this.innerWidth,
        this.innerHeight,
        minYAxisValue,
        maxYAxisValue,
        startDate,
        endDate,
      );
      this.axis.redrawAxis(this.innerHeight, startDate, endDate, this.granularity, [
        balancesByTime,
      ]);
    }
  }

  private drawPoints(balancesByTime: Map<string, number>) {
    this.balancePointData = this.dataPoints.generatePointData(balancesByTime);
    this.dataPoints.updatePoints("balancePoints", "#FFFFFF", this.balancePointData, 1, "#FF792E");
  }

  private drawLines(balancesByTime: Map<string, number>) {
    this.dataLine.updateBalanceLineGraph(balancesByTime);
  }

  // </editor-fold desc="Graph Drawing Functions">

  // <editor-fold desc="Mouse Move Events">
  private addMouseMoveEvent() {
    this.graphContainer.on("mousemove", (e: any) =>
      this.debounceD3Event(this.handleMouseMoveEvent(e), 50),
    );
  }

  private handleMouseMoveEvent(e: any): any {
    const pointerCoords = d3.pointer(e);

    const mouseX = pointerCoords[0] - this.margin.left;
    const graphX = (mouseX * this.innerWidth) / (this.outerWidth - this.margin.left);

    let i = 0;
    let closestPointX = 0;
    let closestPointY = 0;

    for (const [balanceX, balanceY] of this.balancePointData) {
      if (i == this.balancePointData.length - 1) {
        closestPointX = balanceX;
        closestPointY = balanceY;
        break;
      }
      if (graphX < balanceX) {
        closestPointX = balanceX;
        closestPointY = balanceY;
        break;
      } else {
        const [nextBalanceX, nextBalanceY] = this.balancePointData[i + 1];
        if (graphX < nextBalanceX) {
          if (graphX - balanceX < nextBalanceX - graphX) {
            closestPointX = balanceX;
            closestPointY = balanceY;
            break;
          } else {
            closestPointX = nextBalanceX;
            closestPointY = nextBalanceY;
            i++;
            break;
          }
        }
      }
      i++;
    }

    this.dataLine.moveMarkerLine(closestPointX, closestPointY);
    this.setHudData(i);
  }

  setHudData(i: number) {
    this.hudIndex = i;
  }

  private debounceD3Event(handleMouseEvent: any, wait: number) {
    return () => {
      debounce(handleMouseEvent, wait);
    };
  }

  // </editor-fold desc="Mouse Move Events">
}

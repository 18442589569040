<div class="tw-flex tw-flex-col tw-gap-4 tw-w-full">
  <!--  filter-->
  <div class="tw-flex tw-flex-col tw-gap-2">
    <span class="tw-font-medium tw-text-sm tw-text-neutral-600">FILTER</span>
    <app-dropdown-multiselect
      [title]="'Country'"
      [options]="countries"
      [selectedOptions]="selectedCountries"
      (selectionChange)="onCountrySelect($event)"
      [testId]="'country-dropdown'"
    ></app-dropdown-multiselect>

    <app-dropdown-multiselect
      [title]="'Institutions'"
      [options]="institutions"
      [selectedOptions]="selectedInstitutions"
      [disabledOptions]="disabledInstitutions"
      (selectionChange)="onInstitutionSelect($event)"
      [testId]="'institutions-dropdown'"
    ></app-dropdown-multiselect>

    <app-dropdown-multiselect
      [title]="'Accounts'"
      [options]="accounts"
      [selectedOptions]="selectedAccounts"
      [disabledOptions]="disabledAccounts"
      (selectionChange)="onAccountSelect($event)"
      [testId]="'accounts-dropdown'"
    ></app-dropdown-multiselect>
  </div>

  <!--  Group by-->
  <!--@Sinan TODO this block is commented out to be enabled later on. No need to delete it -->
  <!--  <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
    <span class="tw-font-medium tw-text-sm tw-text-neutral-600">GROUP BY</span>
    <app-dropdown-multiselect
      [title]="'Group By'"
      [options]="groupByOptions"
      [selectedOptions]="selectedGroupByOptions"
      (selectionChange)="onGroupBySelectionChange($event)"
    ></app-dropdown-multiselect>
  </div>-->
  <!--  Group by-->

  <div class="tw-flex tw-justify-end tw-gap-2">
    <app-gloss-button [options]="cancelButtonOptions"></app-gloss-button>
    <app-gloss-button [options]="confirmButtonOptions"></app-gloss-button>
  </div>
</div>

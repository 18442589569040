import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { VaultFileStoreModel } from "../../../models/store/vault-file.store.model";
import { VaultFileView } from "../../../models/view/vault-file/vault-file.view";
import { signal } from "@angular/core";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class VaultFilesStoreCollection extends StoreModelCollection<
  VaultFileStoreModel,
  ModelView<VaultFileStoreModel>
> {
  readonly type: StoreModelNames = "VaultFileStoreModel";

  protected models: Map<string, VaultFileStoreModel> = new Map<string, VaultFileStoreModel>();

  private _vaultFileViews = signal<VaultFileView[]>(null);
  readonly vaultFileViews = this._vaultFileViews.asReadonly();

  triggerChanges(): void {
    const views: VaultFileView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new VaultFileView(model)) as VaultFileView);
    });
    this._vaultFileViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._vaultFileViews.set(null);
  }
}

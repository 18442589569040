import { DragDropModule } from "@angular/cdk/drag-drop";
import { LayoutModule } from "@angular/cdk/layout";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApmModule } from "@elastic/apm-rum-angular";

import { AgGridAngular } from "ag-grid-angular";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { OssRoutingModule } from "./oss-routing.module";
import { OssModule } from "./oss.module";
import { WildcardRoutingModule } from "./wildcard-routing.module";

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    AgGridAngular,
    ApmModule,
    OssModule,
    BrowserAnimationsModule,
    FormsModule,
    CoreModule,
    InfiniteScrollModule,
    DragDropModule,
    LayoutModule,
    OssRoutingModule,
    ReactiveFormsModule,
    WildcardRoutingModule,
    MatChipsModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}

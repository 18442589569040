<div class="tw-flex tw-flex-col tw-gap-12">
  <!--        statement period-->
  <div class="tw-flex tw-w-full tw-flex-col tw-gap-4">
    <span class="tw-font-medium">{{ "statementPeriod" | i18n }} *</span>
    <div class="tw-flex tw-w-full tw-gap-4">
      <div class="tw-flex tw-w-full tw-gap-4">
        <!--From picker-->
        <app-date-picker
          [isRequired]="true"
          [hasError]="formGroup.get('fromDate').invalid && formGroup.get('fromDate').touched"
          [borderType]="'left'"
          [dateInputLabel]="'from' | i18n"
          (changeDateEvent)="onDateChange($event, 'fromDate')"
          class="tw-w-full xs:tw-pl-1"
        >
        </app-date-picker>
        <!--to Picker-->
        <app-date-picker
          [isRequired]="true"
          [hasError]="formGroup.get('toDate').invalid && formGroup.get('toDate').touched"
          [borderType]="'right'"
          [dateInputLabel]="'to' | i18n"
          (changeDateEvent)="onDateChange($event, 'toDate')"
          class="tw-w-full"
        >
        </app-date-picker>
      </div>
    </div>
  </div>

  <ng-template #accountDisplayTemplate let-data="data">
    <div class="sm:tw-flex sm:tw-flex-col">
      <span
        class="tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.004em] tw-text-neutral-600"
      >
        {{ "opening" | i18n }}:
        {{ _mOpeningBalances.get(data.id)?.balanceForm?.balance | currency: baseCurrency }}</span
      >
      <span
        *ngIf="_mClosingBalances.get(data.id)"
        class="tw-text-[12px] tw-font-medium tw-leading-[125%] tw-tracking-[0.004em] tw-text-neutral-600"
      >
        {{ "closing" | i18n }}:
        {{ _mClosingBalances.get(data.id)?.balanceForm?.balance | currency: baseCurrency }}</span
      >
    </div>
  </ng-template>

  <div class="linked-accounts-container" *ngIf="linkedAccounts.length > 0">
    <span class="tw-font-medium">{{ "linkedCabinetAccounts" | i18n }} *</span>
    <app-item-display
      *ngFor="let linkedAccount of linkedAccountsDisplay"
      [item]="linkedAccount"
      (editFunction)="receivedEditAccount($event)"
      (removeFunction)="receivedRemoveAccount($event)"
    >
      <ng-container
        *ngTemplateOutlet="accountDisplayTemplate; context: { data: linkedAccount.templateData }"
      ></ng-container>
    </app-item-display>
  </div>

  <div class="tw-flex tw-flex-col">
    <div
      class="tw-flex tw-flex-col tw-gap-2 tw-rounded-xl tw-bg-neutral-50 tw-p-3 tw-shadow-button"
      [ngClass]="{ 'tw-border-solid tw-border-danger ': noLinkedAccountErrorText }"
    >
      <span class="tw-font-medium">{{ "statementAccount" | i18n }} *</span>

      <mat-select
        #accountSelect
        (selectionChange)="accountSelected($event)"
        class="tw-h-12 tw-w-full tw-rounded-xl tw-bg-white tw-shadow-inner"
        placeholder="{{ 'searchToAddExistingAccounts' | i18n }}"
        panelClass="tw-bg-neutral-50"
      >
        <mat-option
          *ngFor="let account of accountViews"
          [value]="account"
          [disabled]="isAccountLinked(account)"
        >
          {{ account.name }}
        </mat-option>
      </mat-select>

      <!-- statement balance -->
      <div class="tw-flex tw-w-full tw-flex-col" [ngClass]="{ 'tw-hidden': !accountInAction }">
        <span class="tw-font-medium">{{ "statementBalance" | i18n }} *</span>
        <div class="tw-flex tw-w-full tw-justify-around tw-gap-4">
          <!-- Opening balance input -->
          <div class="tw-w-[50%]">
            <div
              (click)="openingBalanceInput.focus()"
              class="left-round-border tw-flex tw-items-center tw-justify-between tw-gap-3 tw-bg-white tw-px-6 tw-py-3 tw-shadow-inner sm:tw-mt-7 sm:tw-flex-auto"
              [ngClass]="{ 'tw-border-solid tw-border-danger ': openingBalanceErrorText }"
            >
              <span class="sm:tw--ml-[20px] sm:tw--mt-[65px] sm:tw-w-0">{{
                "opening" | i18n
              }}</span>
              <input
                #openingBalanceInput
                (input)="openingBalanceChange($event)"
                type="number"
                placeholder="0.00"
                class="tw-w-full tw-border-0 tw-text-left focus:tw-border-none focus:tw-outline-none"
              />
              <span class="tw-uppercase tw-text-neutral-300">{{ baseCurrency }}</span>
            </div>
            <span *ngIf="openingBalanceErrorText" class="tw-text-danger">{{
              openingBalanceErrorText | i18n
            }}</span>
          </div>

          <!-- Closing balance input -->
          <div class="tw-w-[50%]">
            <div
              (click)="closingBalanceInput.focus()"
              class="right-round-border tw-flex tw-items-center tw-justify-between tw-gap-3 tw-bg-white tw-px-6 tw-py-3 tw-shadow-inner sm:tw-mt-7 sm:tw-flex-auto"
              [ngClass]="{ 'tw-border-solid tw-border-danger ': closingBalanceErrorText }"
            >
              <span class="sm:tw--ml-[20px] sm:tw--mt-[65px] sm:tw-w-0">{{
                "closing" | i18n
              }}</span>
              <input
                #closingBalanceInput
                (input)="closingBalanceChange($event)"
                type="number"
                placeholder="0.00"
                class="tw-w-full tw-border-0 tw-text-left focus:tw-border-none focus:tw-outline-none"
              />

              <span class="tw-uppercase tw-text-neutral-300">{{ baseCurrency }}</span>
            </div>
            <span *ngIf="closingBalanceErrorText" class="tw-text-danger">{{
              closingBalanceErrorText | i18n
            }}</span>
          </div>
        </div>
      </div>

      <button
        (click)="linkSelectedAccount()"
        class="tw-mt-2 tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2 tw-rounded-xl tw-border-none tw-bg-neutral-50 tw-p-3 tw-shadow-button hover:tw-cursor-pointer"
      >
        <span
          class="tw-text-4 tw-pr-[10px] tw-font-medium tw-tracking-[0.0025em] tw-text-primary"
          >{{ "linkAnotherAccount" | i18n }}</span
        >
        <svg class="tw-h-4 tw-w-4">
          <use xlink:href="images/sprite.svg#add" />
        </svg>
      </button>
      <span *ngIf="noLinkedAccountErrorText" class="tw-text-danger">{{
        noLinkedAccountErrorText | i18n
      }}</span>
    </div>
    <div class="tw-flex tw-justify-end tw-py-1.5">
      <app-gloss-button
        class="tw-w-1/2 xs:tw-w-full"
        [options]="{
          type: 'submit',
          text: ('complete' | i18n),
          onClick: sendBalanceData.bind(this),
          isEnabled: true,
          class: 'primary',
        }"
      >
      </app-gloss-button>
    </div>
  </div>
</div>

<!--todo: refactor this 'modal' logic from bootstrap, its too complicated for a simple close button-->
<div
  #modal
  class="tw-flex tw-overflow-visible"
  role="dialog"
  aria-modal="true"
  aria-labelledby="deAuthTitle"
>
  <div class="tw-relative tw-mx-auto tw-w-full tw-rounded-lg tw-bg-white tw-p-6" role="document">
    <form class="tw-flex tw-flex-col tw-gap-6 tw-bg-white" #form [appApiAction]="formPromise">
      <div class="tw-flex md:tw-justify-between">
        <h1 class="tw-text-xl" id="deAuthTitle">{{ "deauthorizeSessions" | i18n }}</h1>
        <close-button [closeButtonType]="'plain'" class="tw-hidden md:tw-flex"></close-button>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-6">
        <p class="tw-my-0 tw-text-justify">{{ "deauthorizeSessionsDesc" | i18n }}</p>
        <app-gloss-callout title="warning" body="deauthorizeSessionsWarning"></app-gloss-callout>
        <app-user-verification [(ngModel)]="masterPassword" ngDefaultControl name="secret">
        </app-user-verification>
      </div>
      <div class="tw-flex tw-justify-around tw-gap-6 md:tw-flex-col md:tw-gap-4">
        <app-gloss-button
          class="tw-w-full"
          [options]="{
            text: 'deauthorizeSessions' | i18n,
            type: 'submit',
            class: 'danger-invert',
            onClick: submit.bind(this),
            isEnabled: !form.loading,
          }"
        ></app-gloss-button>

        <app-gloss-button
          mat-dialog-close
          aria-label="Close dialog"
          class="tw-w-full"
          [options]="{
            text: 'close' | i18n,
            class: 'neutral',
            isEnabled: true,
          }"
        ></app-gloss-button>
      </div>
    </form>
  </div>
</div>

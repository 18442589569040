import { MoneySeparator } from "@bitwarden/web-vault/app/shared/utils/helper-money-separator";

import { MoneySeparatorValues } from "../../models/types/general-types";

export class NumberFormatValidator {
  validMoneySplits = [" ", ",", ".", "`", "'"];
  isMoneySplitValid(split: string): boolean {
    return this.validMoneySplits.includes(split);
  }

  cleanNumber(value: string): string {
    /** Remove all spaces in case there is space here in there*/
    let newValue = value.replace(/\s/g, "");

    /** Remove all " from around the number*/
    newValue = newValue.replace(/"/g, "");

    /** Removes currency symbols such as $, £ from both ends of a number*/
    newValue = newValue.replace(/^[\p{Sc}\p{Cf}]+|[\p{Sc}\p{Cf}]+$/gu, "");

    /** Removes monetary keywords such as USD , EUR from a number*/
    newValue = newValue.replace(/^[A-Za-z]+|[A-Za-z]+$/g, "");

    /** updates the number depending on it having multiple or single separator. Eg : 1 000 000.00 or 1,000,000.00*/
    newValue = this.getNumberFormat(newValue, value);

    return newValue;
  }

  /**
   *
   * @param newValue :  value of number stripped from spaces and other non-numeric characters
   * @param originalValue : value of number as is
   *
   * */
  private getNumberFormat(newValue: string, originalValue: string): string {
    const valueSplit = newValue.split("");
    const separators: MoneySeparator[] = this.getNumberSeparators(newValue, originalValue);
    if (separators.length) {
      return this.valueOfNumberSeparator(separators, valueSplit, originalValue);
    }

    return newValue;
  }

  /**
   *
   * @param cleanValue  value that has been stripped from spaces and other non-numeric chars such as $ , USD etc
   * @param originalValue value as is
   *
   * */
  getNumberSeparators(cleanValue: string, originalValue: string) {
    /** Remove duplicates from characters array */
    const charSet = new Set(cleanValue.split(""));
    /** Remove numbers from characters array */
    const possibleSeparators = [...charSet].filter((char) => isNaN(Number(char)) && char !== "-");
    /** There should be only two separators for numbers . Thousands and Decimals*/
    if (possibleSeparators.length > 2) {
      throw { messageKey: "notValidNumberDetected", value: originalValue };
    }

    const separators: MoneySeparator[] = [];
    for (const separatorChar of possibleSeparators) {
      if (!this.isMoneySplitValid(separatorChar)) {
        throw { messageKey: "notValidNumberDetected", value: originalValue };
      }
      const newSeparator = new MoneySeparator(
        separatorChar,
        cleanValue,
        originalValue,
        possibleSeparators,
      );
      separators.push(newSeparator);
    }

    return separators;
  }

  valueOfNumberSeparator(
    separators: MoneySeparator[],
    valueSplit: string[],
    originalValue: string,
  ): string {
    const decimalSeparators: MoneySeparator[] = [];
    const thousandSeparators: MoneySeparator[] = [];

    separators.forEach((separator) => {
      if (separator.type === MoneySeparatorValues.decimal) {
        decimalSeparators.push(separator);
      }
      if (separator.type === MoneySeparatorValues.thousands) {
        thousandSeparators.push(separator);
      }
    });

    /** There can not be multiple decimal separator */
    if (decimalSeparators.length > 1 || thousandSeparators.length > 1) {
      throw { messageKey: "notValidNumberDetected", value: originalValue };
    }

    let value = "";
    for (const [index, char] of valueSplit.entries()) {
      if (index === 0 && char === "-") {
        value += char;
      }
      if (!isNaN(Number(char))) {
        value = value + char;
      } else if (char === decimalSeparators[0]?.character) {
        value = value + ".";
      }
    }
    return value;
  }
}

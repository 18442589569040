import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

import "./autocomplete.component.styles.scss";

// TODO : This component has some private implementations. It should be cleaned up and  refactored. Such as gatRate() method
/**
 * AutocompleteComponent is a reusable component that provides an autocomplete functionality.
 * It takes an array of items and a placeholder string as inputs, and emits an event when an item is selected.
 * TODO: Add a feature to select multiple items.
 * @component
 */
@Component({
  selector: "app-autocomplete",
  templateUrl: "./autocomplete.component.html",
  styles: ["autocomplete.component.styles.scss"],
})
export class AutocompleteComponent implements OnChanges, OnInit {
  @ViewChild("trigger") autocompleteTrigger: MatAutocompleteTrigger;
  /**
   * An array of items to be displayed in the autocomplete dropdown.
   */
  @Input() items: any[];

  /**
   * A placeholder string to be displayed in the input field.
   */
  @Input() placeholder: string;

  /**
   * A preselected value for the autocomplete input field.
   * This value, if provided, will be used to prepopulate the input field when the component is initialized.
   */
  @Input() preselectedValue: any;

  /**
   * The label for the autocomplete input field.
   */
  @Input() label: string;

  /**
   * The label for the autocomplete input field.
   */
  @Input() isRequired: boolean = true;

  /**
   * The label for the autocomplete input field.
   */
  @Input() disabled = false;

  @Input() isDownArrow: boolean = true;

  /**
   * An event that is emitted when an item is selected from the autocomplete dropdown.
   * The selected item is passed as the event payload.
   */
  @Output() selectedItem: EventEmitter<any> = new EventEmitter<any>();

  /**
   * An event that is emitted when an item is selected from the autocomplete dropdown.
   * The selected item is passed as the event payload.
   */
  @Output() clearSelection: EventEmitter<any> = new EventEmitter<any>();

  /**
   * A FormControl instance for the input field.
   */
  itemControl = new FormControl({ value: "", disabled: this.disabled });

  /**
   * An Observable that emits an array of items that match the current input value.
   */
  filteredItems: Observable<any[]>;

  isSelectionClear = true;
  isPanelOpen = false;
  isInvalid = false;

  constructor() {
    this.filteredItems = this.itemControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value)),
    );
  }

  /**
   * Angular lifecycle hook that is called after data-bound properties of a directive are initialized.
   * In this case, it checks if a preselected value exists and if so, sets the value of the itemControl form control to the preselected value.
   */
  ngOnInit() {
    if (this.preselectedValue) {
      this.itemControl.setValue(this.preselectedValue);
      this.filteredItems = this.itemControl.valueChanges.pipe(
        startWith(this.preselectedValue),
        map((value) => this._filter(value)),
      );
    }
    this.updateErrorState();
  }

  /**
   * Angular lifecycle hook that is called when a data-bound input property changes.
   * This method resets the value of the itemControl form control to an empty string if the items array changes.
   * If the preselectedValue changes, it sets the value of the itemControl form control to the new preselectedValue.
   *
   * @param {SimpleChanges} changes - An object of the current and previous property values provided by Angular.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && changes.disabled.currentValue) {
      this.itemControl.disable();
    }

    if (changes.items && changes.items.currentValue) {
      this.itemControl.setValue("");
    }

    if (changes.preselectedValue) {
      this.itemControl.setValue(changes.preselectedValue.currentValue);
    }
    this.updateErrorState();
  }

  /**
   * Filters the items array based on the provided value.
   * If the value is a string, it is used as the filter value.
   * If the value is an object with a name property, the name is used as the filter value.
   *
   * @param {string | any} value - The value to filter the items array with.
   * @returns {any[]} - An array of items that match the filter value.
   */
  private _filter(value: string | any): any[] {
    let filterValue = "";
    if (typeof value === "string") {
      filterValue = value.toLowerCase();
    } else if (value && typeof value === "object" && value.name) {
      filterValue = value.name.toLowerCase();
    }

    const filteredItems =
      this.items?.filter((item) => {
        if (item.name) {
          return item.name.toLowerCase().includes(filterValue);
        } else {
          return item.toLowerCase().includes(filterValue);
        }
      }) || [];

    return this._sortItems(filteredItems);
  }

  private _sortItems(items: any[]): any[] {
    return items.sort((a, b) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      } else {
        return a.localeCompare(b);
      }
    });
  }

  /**
   * Clears the input field and emits the clearSelection event.
   * This method is typically used to reset the autocomplete component.
   */
  clearInput() {
    this.itemControl.setValue("");
    this.clearSelection.emit();
  }

  onKey(event: Event) {
    const htmlInputElement = event.target as HTMLInputElement;
    const inputValue = htmlInputElement.value;
    if (inputValue.length === 0) {
      this.clearInput();
    } else {
      this.filteredItems = this.itemControl.valueChanges.pipe(
        startWith(inputValue),
        map((inputValue) => this._filter(inputValue)),
      );
    }
  }

  onBlur(event: FocusEvent) {
    setTimeout(() => {
      if (!this.isSelectionClear) {
        const inputElement = event.target as HTMLInputElement;
        const filteredItems = this._filter(inputElement.value);

        if (filteredItems.length === 1) {
          const firstElementOfFilteredItems = this._filter(inputElement.value)[0];
          this.itemControl.setValue(firstElementOfFilteredItems);
          this.itemSelected(this.itemControl.value);
          this.isSelectionClear = false;
          return;
        }

        this.isSelectionClear = false;
      } else {
        this.isSelectionClear = false;
      }
    }, 100);
    this.updateErrorState();
  }

  onPanelOpen() {
    this.isPanelOpen = true;
    this.updateErrorState();
    this.filteredItems = this.itemControl.valueChanges.pipe(
      startWith(this.itemControl.value),
      map((value) => this._filter(value)),
    );
  }

  updateErrorState() {
    this.isInvalid =
      this.itemControl.hasError("required") && !this.isPanelOpen && this.itemControl.touched;
  }

  onPanelClose() {
    this.isPanelOpen = false;
    this.updateErrorState();
  }

  /**
   * Returns the name of the provided item if it exists, otherwise returns an empty string.
   *
   * @param {any} item - The item to get the name of.
   * @returns {string} - The name of the item, or an empty string if it doesn't exist.
   */
  displayItem(item: any): string {
    return item && item.name ? item.name : item || "";
  }

  /**
   * Emits the selectedItem event with the provided item as the event payload.
   *
   * @param {any} item - The item to emit the selectedItem event with.
   */
  itemSelected(item: any) {
    this.isSelectionClear = true;
    this.selectedItem.emit(item);
  }
}

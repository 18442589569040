import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { ReferenceDataStoreModel } from "../../../../models/store/reference-data.store.model";
import { DefaultStoreData } from "@bitwarden/web-vault/app/models/store/store.data";

export class ReferenceDataParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported ReferenceData parser version ${version}`);
    }

    if (version === 0) {
      return (obj: any) => DefaultStoreData;
    } else {
      return ReferenceDataParser_v1;
    }
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const ReferenceDataParser_v1 = (referenceDataObj: any): any =>
  referenceDataObj as ReferenceDataStoreModel;

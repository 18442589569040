import { inject, Injectable } from "@angular/core";
import { VaultReader } from "@bitwarden/web-vault/app/services/dali/vault.reader";
import { StoreModel } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser.type";
import {
  StoreModelNames,
  StoreVaultCodeByModelName,
} from "@bitwarden/web-vault/app/services/dali/type/dali.type";
import { VaultWriter } from "@bitwarden/web-vault/app/services/dali/vault.writer";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";

@Injectable({
  providedIn: "root",
})
export class DaliService {
  protected appState = inject(AppStateStoreService);

  private vaultLoader: VaultReader = new VaultReader();
  private vaultWriter: VaultWriter = new VaultWriter();

  async initialise() {
    return await this.vaultLoader.loadVault();
  }

  async save(type: StoreModelNames, models: StoreModel[]) {
    this.appState.startProcess("syncing-data", "Syncing data to vault...");

    /** todo maybe we need a lock to process the queueing **/
    const result = await this.vaultWriter.syncToVault(type, models);

    if (result.size > 1) {
      throw new Error("More than one model type saved. Need to implement a batching mechanism.");
    }

    this.appState.endProcess("syncing-data");
    return result.get(StoreVaultCodeByModelName[type]);
  }

  async commit(type: StoreModelNames, models: StoreModel[]) {
    /** todo maybe we need a lock to process the queueing **/
    return await this.vaultWriter.syncToVault(type, models);
  }
}

import { AccountSyncTable } from "@bitwarden/web-vault/app/gloss/tables/account-tables/accounts-sync-table/account-sync.table";
import { AccountSymbolTable } from "@bitwarden/web-vault/app/gloss/tables/account-tables/accounts-symbol-table/accounts-symbol-table";
import { AccountViewTable } from "@bitwarden/web-vault/app/gloss/tables/account-tables/accounts-view-table/account-view.table";
import { SymbolTable } from "@bitwarden/web-vault/app/gloss/tables/symbol-table/symbol.table";
import { TablesType } from "@bitwarden/web-vault/app/models/enum/tables.enum";
import { TableParent } from "@bitwarden/web-vault/app/models/types/table.types";
import { PastTransactionTable } from "@bitwarden/web-vault/app/gloss/tables/transaction-table/past-transaction.table";
import { ScenarioTransactionTable } from "@bitwarden/web-vault/app/gloss/tables/transaction-table/scenario-transaction.table";

/** Factory type map **/
export type TableTypeMap = {
  [TablesType.AccountView]: AccountViewTable;
  [TablesType.AccountSync]: AccountSyncTable;
  [TablesType.AccountSymbol]: AccountSymbolTable;
  [TablesType.Symbol]: SymbolTable;
  [TablesType.PastTransactions]: PastTransactionTable;
  [TablesType.ScenarioTransactions]: ScenarioTransactionTable;
};

export class TablesFactory {
  get<K extends TablesType>(type: K, parent?: TableParent): TableTypeMap[K] {
    switch (type) {
      case TablesType.AccountView:
        return new AccountViewTable() as TableTypeMap[K];
      case TablesType.AccountSync:
        return new AccountSyncTable(parent) as TableTypeMap[K];
      case TablesType.AccountSymbol:
        return new AccountSymbolTable() as TableTypeMap[K];
      case TablesType.Symbol:
        return new SymbolTable() as TableTypeMap[K];
      case TablesType.PastTransactions:
        return new PastTransactionTable() as TableTypeMap[K];
      case TablesType.ScenarioTransactions:
        return new ScenarioTransactionTable() as TableTypeMap[K];
      default:
        throw new Error(`Unknown table type: ${type}`);
    }
  }
}

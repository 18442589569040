import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { RegisterComponent as BaseRegisterComponent } from "@bitwarden/angular/components/register.component";
import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { AuthService } from "@bitwarden/common/abstractions/auth.service";
import { CryptoService } from "@bitwarden/common/abstractions/crypto.service";
import { EnvironmentService } from "@bitwarden/common/abstractions/environment.service";
import { FormValidationErrorsService } from "@bitwarden/common/abstractions/formValidationErrors.service";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PasswordGenerationService } from "@bitwarden/common/abstractions/passwordGeneration.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { PolicyService } from "@bitwarden/common/abstractions/policy/policy.service.abstraction";
import { StateService } from "@bitwarden/common/abstractions/state.service";
import { MasterPasswordPolicyOptions } from "@bitwarden/common/models/domain/master-password-policy-options";
import { ReferenceEventRequest } from "@bitwarden/common/models/request/reference-event.request";
import { StripeConfigService } from "@bitwarden/web-vault/app/services/api/stripe-config.service";
import { HelperTooltip } from "@bitwarden/web-vault/app/shared/utils/helper-tooltip";

import { ConfirmationDialogService } from "../../services/confirmation/confirmation.service";
import { DeviceDetector, DeviceDetection } from "../../shared/utils/device-detector";

class templateRef {}

@Component({
  selector: "app-register-form",
  templateUrl: "./register-form.component.html",
  providers: [StripeConfigService],
})
export class RegisterFormComponent extends BaseRegisterComponent implements OnDestroy {
  @Input() queryParamEmail: string;
  @Input() enforcedPolicyOptions: MasterPasswordPolicyOptions;
  @Input() referenceDataValue: ReferenceEventRequest;

  @ViewChild("notificationPassword") notificationPassword: TemplateRef<any>;
  @Output() submitNotification: EventEmitter<templateRef> = new EventEmitter();

  private deviceDetector: DeviceDetection;
  private unsubscribe$ = new Subject<void>();
  private sessionId: string;
  showErrorSummary = false;
  isStripeLoaded = false;
  version: string;
  tooltip: HelperTooltip;
  year = "2015";

  constructor(
    private policyService: PolicyService,
    private route: ActivatedRoute,
    private stripeConfigService: StripeConfigService,
    formValidationErrorService: FormValidationErrorsService,
    formBuilder: UntypedFormBuilder,
    authService: AuthService,
    router: Router,
    i18nService: I18nService,
    cryptoService: CryptoService,
    apiService: ApiService,
    stateService: StateService,
    platformUtilsService: PlatformUtilsService,
    passwordGenerationService: PasswordGenerationService,
    environmentService: EnvironmentService,
    logService: LogService,
    dialog: MatDialog,
    confirmationDialogService: ConfirmationDialogService,
  ) {
    super(
      formValidationErrorService,
      formBuilder,
      authService,
      router,
      i18nService,
      cryptoService,
      apiService,
      stateService,
      platformUtilsService,
      passwordGenerationService,
      environmentService,
      logService,
      dialog,
      confirmationDialogService,
    );
    this.tooltip = new HelperTooltip();

    this.deviceDetector = new DeviceDetector();
  }

  async ngOnInit() {
    this.year = new Date().getFullYear().toString();
    this.version = await this.platformUtilsService.getApplicationVersion();
    await super.ngOnInit();
    await this.initializeForm();
    this.referenceData = this.referenceDataValue;
    this.submitNotification.emit(this.notificationPassword);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async submit() {
    if (!this.formGroup.invalid) {
      const userConfirmed = await this.notificationDialogOpen();
      if (!userConfirmed) {
        return;
      }
    }
    if (
      this.enforcedPolicyOptions != null &&
      !this.policyService.evaluateMasterPassword(
        this.passwordStrengthResult.score,
        this.formGroup.value.masterPassword,
        this.enforcedPolicyOptions,
      )
    ) {
      this.platformUtilsService.showToast(
        "error",
        this.i18nService.t("errorOccurred"),
        this.i18nService.t("masterPasswordPolicyRequirementsNotMet"),
      );
      return;
    }

    await super.submit(false);
  }
  notificationRef: MatDialogRef<any>;

  openDialog(templateRef: TemplateRef<any>) {
    this.notificationRef = this.dialog.open(templateRef, {
      panelClass: "no-background-dialog-v2",
      disableClose: true,
    });
  }

  notificationDialogOpen(): Promise<boolean> {
    return new Promise((resolve) => {
      this.openDialog(this.notificationPassword);
      this.notificationRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result === "userConfirmed") {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  submitStart() {
    this.notificationRef.close("userConfirmed");
  }

  resetPassword() {
    this.notificationRef.close("userCancelled");
  }

  private async initializeForm() {
    this.isStripeLoaded = true;
    return;
    // todo removed the following code because of apple request in-store app purchase.
    // We will reimpllement stripe and the paywall in the future.
    // try {
    //   this.getQueryParams();
    //
    //   if (!this.sessionId) {
    //     if (this.environmentService.isLocal() || this.deviceDetector.isIphoneDevice()) {
    //       this.isStripeLoaded = true;
    //     }
    //
    //   }
    //
    //
    //
    //   const checkoutDetail = await this.getCheckoutDetail();
    //   if (checkoutDetail) {
    //     this.setCheckoutFormDetail(checkoutDetail);
    //     this.isStripeLoaded = true;
    //   }
    // } catch (error) {
    //   // todo: display error on page
    // } finally {
    //   if (this.environmentService.isLocal() || this.deviceDetector.isIphoneDevice()) {
    //     this.isStripeLoaded = true;
    //   }
    // }
  }

  private getQueryParams() {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.sessionId = params["session"];
    });
  }

  private async getCheckoutDetail() {
    return await this.stripeConfigService.getCheckoutSessions(this.sessionId);
  }

  private setCheckoutFormDetail(checkoutDetail: Record<string, any>) {
    this.formGroup.get("email").setValue(checkoutDetail.email);
    this.formGroup.get("email").disable();

    this.formGroup.get("name").setValue(checkoutDetail.name);
    this.formGroup.get("name").disable();
  }
}

import { ParserFunctionType } from "../parser.type";
import { BookResponse } from "../../../../models/data/response/book.response";
import { Book } from "../../../../models/data/blobby/book.data";
import {
  book_v0_to_v1,
  book_v1_to_v2,
} from "@bitwarden/web-vault/app/services/dali/vault-parser/model-dto/books";
import { AccountStoreModel } from "../../../../models/store/account.store.model";
import { ParserAbstraction } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/parser.abstraction";

export class BookParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Book parser version ${version}`);
    }

    if (version === 0) {
      return BookParser_v0;
    }

    if (version === 1) {
      return BookParser_v1;
    }

    return BookParser_v2;
  }

  override getSupportedVersion(): number[] {
    return [0, 1, 2];
  }
}

const BookParser_v2 = (vaultItem: any): AccountStoreModel => vaultItem as AccountStoreModel;

const BookParser_v1 = (vaultItem: any): AccountStoreModel => {
  const upgraded = book_v1_to_v2(vaultItem);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = vaultItem.vid ?? "";
  return BookParser_v2(upgraded);
};

const BookParser_v0 = (vaultItem: any): AccountStoreModel => {
  const response = new BookResponse(vaultItem);
  const book = new Book(response);
  const upgraded = book_v0_to_v1(book);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = vaultItem.vid ?? "";
  return BookParser_v1(upgraded);
};

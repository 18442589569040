import { Component, EventEmitter, Input, Output, ViewChild, SimpleChanges } from "@angular/core";
import { MatSelect } from "@angular/material/select";

import { BalanceDirection } from "@bitwarden/common/enums/balanceDirection";
import { RecurringPeriod } from "@bitwarden/common/enums/recurringPeriod";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
})
export class SelectComponent {
  @Input() labelKey: string;
  @Input() selectedValue: any;
  @Input() selectElementId: string;
  @Input() itemList: Array<any>;
  @Input() valueKey: string;
  @Input() displayKey: string;

  @Output() selectEvent = new EventEmitter();
  @ViewChild("select") select: MatSelect;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemList) {
      this.getDisplayValue(this.selectedValue);
    }
  }

  getDisplayValue(selectedValue: any): string {
    const selectedItem = this.itemList?.find((item) => item[this.valueKey] === selectedValue);
    return selectedItem ? selectedItem[this.displayKey] : "";
  }

  onSelectionChange(
    selectedValue: string | Book | BalanceDirection | RecurringPeriod | TransactionDirection,
  ) {
    this.selectEvent.emit(selectedValue);
  }
}

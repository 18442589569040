import { Injectable } from "@angular/core";

import { DateFormatIndex } from "@bitwarden/web-vault/app/models/types/general-types";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { AccountValidator } from "@bitwarden/web-vault/app/validators/entity/account-validator";

import { PlaidMapper } from "../../app/importers/data-mapper/plaid-mapper";
import { TransactionService } from "../../app/services/DataService/transaction/transaction.service";
import { Transaction } from "../models/data/blobby/transaction.data";

import { BaseValidator } from "./base-validator";
import { Validator } from "./validator";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";

@Injectable({
  providedIn: "root",
})
export class PlaidValidator extends BaseValidator implements Validator {
  constructor(
    private transactionService: TransactionService,
    public accountValidator: AccountValidator,
    public bookService: BookService,
  ) {
    super(accountValidator, bookService);
  }

  /**
   * This will check if the record coming from plaid has duplicate
   * if no matches found on the record then it will be a new record
   *
   * @param items
   */

  /* grab the dateFormatIndex in the method and set the same format to all tha transactionResponses*/
  async matchTransactionRecord(items: Array<any>, dateFormatIndex: DateFormatIndex) {
    const vaultTrans = await this.transactionService.getAllSourceTransactions();
    const newTransactions: any = [];

    items.forEach((plaidTransaction: any) => {
      // Convert to Plaid Object
      plaidTransaction.classifications = plaidTransaction.classifications
        ? [{ name: plaidTransaction.classifications }]
        : [];
      plaidTransaction.categories = plaidTransaction.categories
        ? [{ name: plaidTransaction.categories }]
        : [];

      // todo @sinan need to use the new object.
      const plaid = new PlaidMapper(plaidTransaction);

      // Convert to Transaction so that the data
      const transactionResponse = PlaidMapper.castToTransactionResponse(plaid, dateFormatIndex);

      const transaction = new Transaction(transactionResponse);

      newTransactions.push(transaction);
    });

    return await this.validate(newTransactions, vaultTrans);
  }

  isDuplicate(item: TransactionView): boolean {
    return false;
  }

  protected validateTransaction(item: TransactionView): TransactionView {
    return undefined;
  }
}

import { StoreData } from "./store.data";
import { SyncStatusKey } from "../types/general-types";

export type SyncStatusModelVersion = 1;
export interface SyncStatusStoreModel extends StoreData {
  /**  **Version Number** **/
  v: SyncStatusModelVersion;

  key: SyncStatusKey;
  acId: string;
}

export const DefaultSyncStatusStoreModel: SyncStatusStoreModel = {
  key: undefined,
  acId: "",
  v: 1,
  dc: "",
  dm: "",
  id: "",
  vid: "",
};

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { UserLocation } from "@bitwarden/web-vault/app/models/data/user-location";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import {
  StringToNumberHelperOfMonth,
  StringToNumberHelperOfWeek,
  StringToNumberPreference,
  UserPreference,
} from "@bitwarden/web-vault/app/models/types/PrefereneceTypes";
import { BasiqUserType } from "@bitwarden/web-vault/app/models/types/basiq.types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

import { Preference, SyncStatusCollectionType } from "../../../models/data/blobby/preference.data";

import { PreferenceTypeKey, PreferenceView } from "../../../models/view/preference/preference.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { toObservable } from "@angular/core/rxjs-interop";
import { DefaultPreferenceStoreModel } from "@bitwarden/web-vault/app/models/store/preference.store.model";

@Injectable({
  providedIn: "root",
})
export class PreferenceService {
  private hasLocationSet$ = new BehaviorSubject<boolean>(false);

  private preferenceView$ = toObservable(this.userStore.preferences.preferenceView);
  private _preferenceView: PreferenceView;

  constructor(
    private userStore: UserStoreService,
    private globalService: GlobalService,
    private dataRepositoryService: DataRepositoryService,
  ) {
    this.preferenceView$.subscribe((preferenceView) => {
      this._preferenceView = preferenceView;
    });
  }

  getPreferenceView() {
    return this._preferenceView;
  }

  async createPreferenceView() {
    await this.userStore.preferences.save(new PreferenceView(DefaultPreferenceStoreModel));
  }

  async updatePreferenceView(preferenceView: PreferenceView) {
    return await this.userStore.preferences.save(preferenceView);
  }

  /** @deprecated */
  setUserLocation(hasLocation: boolean) {
    this.hasLocationSet$.next(hasLocation);
  }

  /** @deprecated */
  hasUserLocation() {
    return this.hasLocationSet$;
  }

  /**
   * @deprecated
   */
  async create(preference: Preference): Promise<Preference> {
    return await this.dataRepositoryService.createPreference(preference);
  }

  /**
   * @deprecated
   */
  async update(preference: Preference): Promise<Preference> {
    return this.dataRepositoryService.updatePreference(preference);
  }

  async updatePreference(preferenceView: PreferenceView) {
    return this.userStore.preferences.save(preferenceView);
  }

  /**
   * @deprecated
   */
  async getAll(): Promise<Preference | false> {
    const preference = await this.dataRepositoryService.getAllPreferences();
    if (preference instanceof Preference) {
      return preference;
    } else {
      return false;
    }
  }

  /** updated to preferenceView */
  async getUserPreferenceObject(): Promise<UserPreference | false> {
    const preference = this.getPreferenceView();
    if (preference) {
      return {
        baseCurrency: preference.baseCurrency,
        timeZone: preference.timeZone,
        dateFormat: preference.dateFormat,
        mode: preference.mode,
        weekDayStart: preference.weekDayStart
          ? this.getWeekDaysArray(preference.weekDayStart)[0].value
          : 1,
        weekDayStartName: preference.weekDayStart
          ? this.getWeekDaysArray(preference.weekDayStart)[0].day
          : "Monday",
        YearMonthStart: preference.YearMonthStart
          ? this.getYearMonthsArray(preference.YearMonthStart)[0].value
          : 1,
        YearMonthStartName: preference.YearMonthStart
          ? this.getYearMonthsArray(preference.YearMonthStart)[0].month
          : "January",
        monthDayStart: preference.monthDayStart ? preference.monthDayStart : 1,
      };
    } else {
      return false;
    }
  }

  /** updated to preferenceView */
  async get(
    key: PreferenceTypeKey,
  ): Promise<
    | string
    | StringToNumberPreference
    | number
    | BasiqUserType
    | SyncStatusCollectionType
    | UserLocation
  > {
    try {
      const preference = this.getPreferenceView();

      if (preference instanceof PreferenceView) {
        return preference[key];
      }
    } catch (e) {
      this.globalService.showErrorMessage(`Could not get preference ${key}`, e);
    }
  }

  async getWeekDayStart(): Promise<StringToNumberPreference> {
    return <StringToNumberPreference>await this.get(PreferenceType.weekDayStart);
  }

  async getYearMonthStart(): Promise<StringToNumberPreference> {
    return <StringToNumberPreference>await this.get(PreferenceType.YearMonthStart);
  }

  async getWeekDayStartAsNumber() {
    const weekDayStart = await this.getWeekDayStart();
    return Object.values(weekDayStart)[0];
  }

  async getYearMonthStartAsNumber() {
    const yearMonthStart = await this.getYearMonthStart();
    return Object.values(yearMonthStart)[0];
  }

  /** updated to preferenceView */
  async updateKey(
    key: PreferenceTypeKey,
    value: string | StringToNumberPreference | number | BasiqUserType | SyncStatusCollectionType,
  ): Promise<boolean> {
    const preference = this.getPreferenceView();
    if (preference instanceof PreferenceView) {
      // const updatedPreference = new Preference(
      //   new PreferenceResponse({ ...preference, ...{ [key]: value } }),
      // );
      // return await this.update(updatedPreference);

      const updatePreferenceView = new PreferenceView({
        ...preference.toStoreModel(),
        ...{ [key]: value },
      });
      return await this.updatePreferenceView(updatePreferenceView);
    }
    return false;
  }

  getWeekDaysArray(weekDaysTypes: StringToNumberPreference): StringToNumberHelperOfWeek[] {
    try {
      return Object.entries(weekDaysTypes).map(([day, value]) => ({ day, value }));
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  getYearMonthsArray(weekDaysTypes: StringToNumberPreference): StringToNumberHelperOfMonth[] {
    try {
      return Object.entries(weekDaysTypes).map(([month, value]) => ({ month, value }));
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }
}

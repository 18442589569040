import { SourceBook } from "../../../../models/data/blobby/source-book";
import { SourceAccountStoreModel } from "../../../../models/store/source-account-store.model";

export const sourceBook_v0_to_v1 = (sourceBook: SourceBook): SourceAccountStoreModel => {
  return {
    acId: sourceBook.accountId,
    src: sourceBook.source,
    org: sourceBook.origin,
    v: 1,
    vid: "",
    dc: new Date().toString(),
    dm: new Date().toString(),
    id: sourceBook.id,
  };
};

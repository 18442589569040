import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { ClassificationResponse } from "../response/classification.response";

export class Classification extends ModelValidator {
  private readonly __v = 1;
  private _id: string;
  weight: number;
  name: string;
  generalDefault?: boolean; // when we import data and create accounts if there are no classification related to transaction
  // we create a default classification as account has to have at least one default classification

  get id(): string {
    return this._id;
  }

  constructor(response: ClassificationResponse) {
    super();
    if (response == null) {
      return;
    }

    this.checkVersion(this.__v, response.__v);

    this._id = response.id ? response.id : crypto.randomUUID();
    this.name = response.name;
    this.weight = response.weight;
    this.generalDefault = response.generalDefault;
  }
}

export function getDefaultClassification(): Classification {
  return new Classification(
    new ClassificationResponse({
      __v: 1,
      name: "No Classification",
      id: "noClassification",
      weight: 1,
      generalDefault: true,
    }),
  );
}

export enum VaultCodeEnum {
  Book = "a",
  Category = "ca",
  Classification = "cl",
  Institution = "in",
  ReferenceData = "rd",
  Estimate = "es",
  Symbol = "s",
  Transaction = "t",
  SourceTransaction = "u",
  SourceBook = "bs",
  Preference = "p",
  EstimateAction = "ea",
  Scenario = "sc",
  ScenarioGroup = "sg",
  SourceCategory = "soc",
  Connector = "con",
  VaultFile = "vf",
  Wizard = "wz",
  SyncStatus = "ss",
  UNKNOWN = "unknown",
}

export type StoreModelNames =
  | "AccountStoreModel"
  | "CategoryStoreModel"
  | "ClassificationStoreModel"
  | "InstitutionStoreModel"
  | "ReferenceDataStoreModel"
  | "EstimateStoreModel"
  | "SymbolStoreModel"
  | "TransactionStoreModel"
  | "SourceTransactionStoreModel"
  | "SourceBookStoreModel"
  | "PreferenceStoreModel"
  | "EstimateActionStoreModel"
  | "ScenarioStoreModel"
  | "ScenarioGroupStoreModel"
  | "SourceCategoryStoreModel"
  | "ConnectorStoreModel"
  | "VaultFileStoreModel"
  | "WizardStoreModel"
  | "SyncStatusStoreModel"
  | "StoreModel";

export const StoreModelNameByVaultCode: Record<VaultCodeEnum, StoreModelNames> = {
  [VaultCodeEnum.Book]: "AccountStoreModel",
  [VaultCodeEnum.Category]: "CategoryStoreModel",
  [VaultCodeEnum.Classification]: "ClassificationStoreModel",
  [VaultCodeEnum.Institution]: "InstitutionStoreModel",
  [VaultCodeEnum.ReferenceData]: "ReferenceDataStoreModel",
  [VaultCodeEnum.Estimate]: "EstimateStoreModel",
  [VaultCodeEnum.Symbol]: "SymbolStoreModel",
  [VaultCodeEnum.Transaction]: "TransactionStoreModel",
  [VaultCodeEnum.SourceTransaction]: "SourceTransactionStoreModel",
  [VaultCodeEnum.SourceBook]: "SourceBookStoreModel",
  [VaultCodeEnum.Preference]: "PreferenceStoreModel",
  [VaultCodeEnum.EstimateAction]: "EstimateActionStoreModel",
  [VaultCodeEnum.Scenario]: "ScenarioStoreModel",
  [VaultCodeEnum.ScenarioGroup]: "ScenarioGroupStoreModel",
  [VaultCodeEnum.SourceCategory]: "SourceCategoryStoreModel",
  [VaultCodeEnum.Connector]: "ConnectorStoreModel",
  [VaultCodeEnum.VaultFile]: "VaultFileStoreModel",
  [VaultCodeEnum.Wizard]: "WizardStoreModel",
  [VaultCodeEnum.SyncStatus]: "SyncStatusStoreModel",
  [VaultCodeEnum.UNKNOWN]: "StoreModel",
};

/** Use AI to revert the key from StoreModelNameByVaultCode or show me how to do tghat in typescript **/
export const StoreVaultCodeByModelName: Record<StoreModelNames, VaultCodeEnum> = {
  AccountStoreModel: VaultCodeEnum.Book,
  CategoryStoreModel: VaultCodeEnum.Category,
  ClassificationStoreModel: VaultCodeEnum.Classification,
  InstitutionStoreModel: VaultCodeEnum.Institution,
  ReferenceDataStoreModel: VaultCodeEnum.ReferenceData,
  EstimateStoreModel: VaultCodeEnum.Estimate,
  SymbolStoreModel: VaultCodeEnum.Symbol,
  TransactionStoreModel: VaultCodeEnum.Transaction,
  SourceTransactionStoreModel: VaultCodeEnum.SourceTransaction,
  SourceBookStoreModel: VaultCodeEnum.SourceBook,
  PreferenceStoreModel: VaultCodeEnum.Preference,
  EstimateActionStoreModel: VaultCodeEnum.EstimateAction,
  ScenarioStoreModel: VaultCodeEnum.Scenario,
  ScenarioGroupStoreModel: VaultCodeEnum.ScenarioGroup,
  SourceCategoryStoreModel: VaultCodeEnum.SourceCategory,
  ConnectorStoreModel: VaultCodeEnum.Connector,
  VaultFileStoreModel: VaultCodeEnum.VaultFile,
  WizardStoreModel: VaultCodeEnum.Wizard,
  StoreModel: VaultCodeEnum.UNKNOWN,
  SyncStatusStoreModel: VaultCodeEnum.SyncStatus,
};

import * as d3 from "d3";

export class DataLine {
  private balanceGraph: any;
  private xScale: d3.ScaleTime<number, number, never>;
  private yScale: d3.ScaleLinear<number, number, never>;

  constructor(
    balanceGraph: any,
    xScale: d3.ScaleTime<number, number, never>,
    yScale: d3.ScaleLinear<number, number, never>,
  ) {
    this.balanceGraph = balanceGraph;
    this.xScale = xScale;
    this.yScale = yScale;
  }

  /**
   * updateBalanceLineGraph - Given a list of balances over timestamps, the function will create the points
   * required on the graph and draw the line on the graph assigning the given name and colour
   * @pa
   */
  updateBalanceLineGraph(
    balancesByTime: Map<string, number>,
    lineName = "balanceLine",
    colour = "#FF792E",
  ) {
    const lineData = this.generateLineData(balancesByTime);
    this.updateLine(lineName, lineData, 3, colour, true, 1, null, null, 2.2);
    return lineData;
  }

  createMarkerLine(innerHeight: number) {
    return this.updateLine(
      "markerLine",
      [[0, 0, 0, innerHeight]],
      3,
      "#FF792E",
      false,
      0,
      null,
      null,
      2,
    );
  }

  moveMarkerLine(xPosition: number, yPosition: number) {
    this.balanceGraph
      .selectAll("#markerLine")
      .attr("x1", xPosition)
      .attr("x2", xPosition)
      .attr("y2", yPosition)
      .attr("opacity", 1);
  }

  private generateLineData(balancesByTime: Map<string, number>): number[][] {
    const lineData = [];
    let i = 0;
    const balanceByTimeArray = Array.from(balancesByTime);

    for (const data of balanceByTimeArray) {
      if (i === balanceByTimeArray.length - 1) {
        break;
      }
      const nextData = balanceByTimeArray[i + 1];

      const x1 = this.xScale(new Date(Number(data[0]) * 1000));
      const y1 = this.yScale(data[1]);
      const x2 = this.xScale(new Date(Number(nextData[0]) * 1000));
      const y2 = this.yScale(nextData[1]);

      // const shortenedLine = this.shortenLine([x1, y1, x2, y2]);
      const shortenedLine = [x1, y1, x2, y2];

      if (shortenedLine) {
        lineData.push(shortenedLine);
      }
      i++;
    }
    return lineData;
  }

  private updateLine(
    name: string,
    data: number[][],
    y2Index: number,
    stroke: string,
    isAlwaysVisible: boolean,
    opacity: number,
    markerId?: string,
    dashArray?: string,
    strokeWidth?: number,
  ) {
    const line = this.balanceGraph.selectAll(`#${name}`).data(data);

    return line
      .join("line")
      .attr("class", name)
      .attr("id", name)
      .attr("x1", (d: number[]) => d[0])
      .attr("y1", (d: number[]) => d[1])
      .attr("x2", (d: number[]) => d[2])
      .attr("y2", (d: number[]) => d[y2Index])
      .attr("stroke", stroke)
      .style("stroke-width", strokeWidth ? strokeWidth : "0.5px")
      .attr("opacity", (d: number[]) =>
        isAlwaysVisible ? opacity : d[y2Index] === d[1] ? 0 : opacity,
      )
      .attr("marker-end", markerId ? `url(#${markerId})` : null)
      .style("stroke-dasharray", dashArray || null);
  }

  /**
   * Given two points, shorten the line by a number of co-ordinates so that the new line does not
   * touch the two edge points. A gap should be left at either enf of the points unless the line is too
   * short for a gap to be left
   * @param points
   * @private
   */
  private shortenLine(points: Array<number>): Array<number> {
    // how many co-ordinates to reduce the ends of the original line by
    const pixelReduction = 6;

    // work out the length of the original line
    const lineLength = Math.sqrt(
      Math.pow(points[2] - points[0], 2) + Math.pow(points[3] - points[1], 2),
    );

    if (lineLength === 0) {
      return;
    }
    // this is the mathematical formula to work out the new starting point of the line based on the pixel reduction we want
    const startPointX = points[0] + (pixelReduction / lineLength) * (points[2] - points[0]);
    const startPointY = points[1] + (pixelReduction / lineLength) * (points[3] - points[1]);

    // this is the mathematical formula to work out the new end point of the line based on the pixel reduction we want
    const endPointX =
      points[0] + ((lineLength - pixelReduction) / lineLength) * (points[2] - points[0]);
    const endPointY =
      points[1] + ((lineLength - pixelReduction) / lineLength) * (points[3] - points[1]);

    // return the new points to draw the line between to produce a gap at either end
    const newPoints = [startPointX, startPointY, endPointX, endPointY];

    return newPoints;
  }
}

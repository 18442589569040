@if (symbol) {
  <div class="tw-flex tw-justify-between tw-items-center tw-p-3">
    <div class="tw-flex tw-items-center">
      <icon [name]="'right-arrow'" [size]="5" class="tw-mr-1"></icon>
      <span>{{ "updateMySymbol" | i18n }}</span>
    </div>
    <!-- <div class="bordered-icon">
      <icon [name]="'expand'" [size]="8"></icon>
    </div> -->
  </div>

  <div class="tw-bg-white tw-rounded-lg tw-p-[12px]">
    <div class="tw-flex tw-items-center">
      <div class="bordered-icon">
        <icon [name]="'coins'" [size]="8"></icon>
      </div>
      <span class="tw-uppercase tw-text-neutral-600 tw-ml-2">{{ "symbolDetails" | i18n }}</span>
    </div>

    <app-widget
      [title]="'symbolName' | i18n"
      [hideIcon]="true"
      class="[&>div]:tw-p-0 [&_label]:tw-mb-1"
    >
      <app-symbol-form [symbol]="symbol" [isEditMode]="true"></app-symbol-form>
    </app-widget>
  </div>
}

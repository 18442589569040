import { ModelView } from "../model.view";
import { ScenarioOptionsType } from "../../types/scenario-group.types";
import { EstimateActionsEnum } from "../../enum/estimate-actions.enum";
import {
  DefaultScenarioStoreModel,
  ScenarioModelVersion,
  ScenarioStoreModel,
} from "../../store/scenario.store.model";

export class ScenarioView extends ModelView<ScenarioStoreModel> {
  protected version: ScenarioModelVersion;
  name: string;
  symbol: string;
  scenarioType: ScenarioOptionsType;
  source: string;
  estimateActionsId: string[];
  estimateActionTypes: EstimateActionsEnum[];

  constructor(model?: ScenarioStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.name = model.nm;
      this.symbol = model.sym;
      this.scenarioType = model.scnT;
      this.source = model.src;
      this.estimateActionsId = model.eAId;
      this.estimateActionTypes = model.eATp;
    } else {
      this.version = DefaultScenarioStoreModel.v;
    }
  }

  toStoreModel(): ScenarioStoreModel {
    return undefined;
  }
  clone(): ScenarioView {
    return new ScenarioView(this.toStoreModel());
  }
}

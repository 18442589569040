import { StoreModelCollection } from "../model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { signal } from "@angular/core";
import { EstimateActionStoreModel } from "../../../models/store/estimate.action.store.model";
import { EstimateActionView } from "@bitwarden/web-vault/app/models/view/estimateAction/estimateAction.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

export class EstimateActionStoreCollection extends StoreModelCollection<
  EstimateActionStoreModel,
  ModelView<EstimateActionStoreModel>
> {
  readonly type: StoreModelNames = "EstimateActionStoreModel";
  protected models: Map<string, EstimateActionStoreModel> = new Map<
    string,
    EstimateActionStoreModel
  >();

  private _estimateActionViews = signal<EstimateActionView[]>([]);
  readonly estimateActionView = this._estimateActionViews.asReadonly();

  triggerChanges(): void {
    const views: EstimateActionView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new EstimateActionView(model)) as EstimateActionView);
    });
    this._estimateActionViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._estimateActionViews.set(null);
  }
}

import { Component, computed, inject } from "@angular/core";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";
import { PendingState } from "@bitwarden/web-vault/app/services/store/app-state/loading-state.type";

@Component({
  selector: "app-loading-overlay",
  templateUrl: "./loading-overlay.component.html",
})
export class LoadingOverlayComponent {
  protected appState = inject(AppStateStoreService);

  pendingState = computed((): PendingState => {
    if (this.appState.state().status === "pending") {
      return this.appState.state() as PendingState;
    } else {
      return null;
    }
  });

  background = computed(() => {
    const progress = this.pendingState().progress ?? 0;
    return `conic-gradient(#00C4C4 ${progress * 3.6}deg, #F5F5F5 ${progress}deg )`;
  });
}

import { transactionDirectionType } from "@bitwarden/web-vault/app/components/auto-transaction-direction/auto-complete-transaction-direction.component";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionAccountLink } from "@bitwarden/web-vault/app/models/types/institution.type";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { SyncStatusView } from "@bitwarden/web-vault/app/models/view/sync-status/sync-status.view";

export type NewAccountActions = "link" | "rename" | "default";
export type UnrecognizedAccount = {
  _id?: string;
  currencies: string[];
  source: string;
  accountId: string;
  action: NewAccountActions;
  selectedCurrency: string;
  selectedTimeZone: string;
  institutionAccountLink: InstitutionAccountLink;
  origin: Origin;
};

export type AccountBalance = {
  account: Book;
  accountBalance: GlossNumber;
};

export type BasiqTypes =
  | "transaction"
  | "savings"
  | "credit-card"
  | "mortgage"
  | "loan"
  | "investment"
  | "term-deposit"
  | "insurance"
  | "foreign"
  | "unknown";
export type PlaidTypes =
  | "depository"
  | "credit"
  | "loan"
  | "mortgage"
  | "investment"
  | "insurance"
  | "unknown";
// export type AccountManualType = "regular";
export type AccountManualType = "bank" | "credit" | "stock" | "other";
// TODO: to be improved
export const accountManualType = {
  bank: "bank",
  credit: "credit",
  stock: "stock",
  other: "other",
} as const;

export type AccountType = BasiqTypes | PlaidTypes | AccountManualType;

export type BalanceForm = {
  balance: string;
  currency: string;
  date: string;
  transactionStatus: TransactionStatusEnum;
  description?: string;
  time?: string;
  type?: "Opening" | "Closing";
};

export type TransactionForm = {
  amount: number;
  currency: string;
  date: string;
  transactionStatus: TransactionStatusEnum;
  description?: string;
  time?: string;
  type: transactionDirectionType;
};

export type AccountTableView = {
  original: AccountView;
  country: string;
  institution: string;
  id: string;
  dragHandle: boolean;
  rightChevron: boolean;
  institutionCode: string;
  name: string;
  interestRate: number;
  balance: number;
  symbol: string;
  type: AccountType;
  statusPoint: SyncStatusView;
};

export type SymbolTableView = {
  code: string;
  amount: number;
  convertedAmount: number;
  flag: string;
};

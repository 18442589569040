import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { ClassificationResponse } from "../../../../models/data/response/classification.response";
import { Classification } from "../../../../models/data/blobby/classification.data";
import { ClassificationStoreModel } from "../../../../models/store/classification.store.model";
import { classification_v0_to_v1 } from "../model-dto/classification";

export class ClassificationParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Classification parser version ${version}`);
    }

    if (version === 0) {
      return ClassificationParser_v0;
    } else {
      return ClassificationParser_v1;
    }
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const ClassificationParser_v1 = (classificationParserItem: any): any =>
  classificationParserItem as ClassificationStoreModel;
const ClassificationParser_v0 = (classificationParserItem: any): any => {
  const response = new ClassificationResponse(classificationParserItem);
  const classification = new Classification(response);
  const upgraded = classification_v0_to_v1(classification);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = classificationParserItem.vid ?? "";
  return ClassificationParser_v1(upgraded);
};

import { inject } from "@angular/core";
import type { AccountTableView } from "@bitwarden/web-vault/app/models/types/account.types";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";
import { ImportStoreService } from "@bitwarden/web-vault/app/services/store/import/import.store.service";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { SignalSubscriptionImpl } from "../../data-subscription";
import { getAccountTableViews } from "../account.table";

export type TableSource = AccountTableView;
/**
 * This class only need to deal with angular dependency
 * and the core logic is in getAccountTableViews function which can be easily unit tested
 */
export class ViewTableSubscription extends SignalSubscriptionImpl<TableSource[]> {
  userStoreService: UserStoreService = inject(UserStoreService);
  importStoreService: ImportStoreService = inject(ImportStoreService);
  calculationStoreService: CalculationStoreService = inject(CalculationStoreService);

  get source() {
    const accountViews = this.calculationStoreService.filteredAccounts.collection();
    const balances = this.calculationStoreService.balanceByAccount.balances();
    const institutionViews = this.userStoreService.institutions.institutionViews();
    const preference = this.userStoreService.preferences.preferenceView();
    const syncStatusViews = this.importStoreService.syncStatus.syncStatusViews();

    if (!balances || balances.size === 0) {
      return [];
    }

    return getAccountTableViews(
      accountViews,
      syncStatusViews,
      institutionViews,
      balances,
      preference,
    );
  }
}

import { Injector } from "@angular/core";
import { BasiqTransactionProcessingService } from "../../api/basiq/transaction.processing.service";

import { Origin, SyncStatusEnum } from "../../../models/types/general-types";
import { AccountView } from "../../../models/view/account/account.view";
import { SyncStatusView } from "../../../models/view/sync-status/sync-status.view";
import { UserStoreService } from "../../store/user/user.store.service";
import { ImportStoreService } from "../../store/import/import.store.service";
import { BasiqIoMapper } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/basiq-io/basiq-io-mapper";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { sortTransactionViews } from "@bitwarden/web-vault/app/shared/utils/helper.transactionView/sort";
import { subDays } from "date-fns";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { GlossQuantityUtils } from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";
import { BasiqMapType } from "@bitwarden/web-vault/app/models/types/basiq.types";
import { BasiqPulledTransaction } from "@bitwarden/web-vault/app/models/types/transaction.types";

export class BasiqAccountSync {
  private userStoreService: UserStoreService;
  private importStoreService: ImportStoreService;
  private transactionStoreService: TransactionStoreService;
  private transactionProcessingService: BasiqTransactionProcessingService;
  private basiqMapper: BasiqIoMapper;

  id: string;
  accountView: AccountView;
  rawTransactions: BasiqPulledTransaction = { data: [] };
  mappedBasiqData: BasiqMapType;
  validatedTransactions: TransactionView[];

  constructor(
    accountView: AccountView,
    private injector: Injector,
  ) {
    this.basiqMapper = this.injector.get(BasiqIoMapper);
    this.userStoreService = this.injector.get(UserStoreService);
    this.importStoreService = this.injector.get(ImportStoreService);
    this.transactionStoreService = this.injector.get(TransactionStoreService);

    this.accountView = accountView;
    this.id = accountView.id;
    this.transactionProcessingService = this.injector.get(BasiqTransactionProcessingService);
  }

  async syncAccount() {
    try {
      await this.pullTransactions();
      this.mapToGlossTransactions();
      await this.saveCategories();
      await this.validateTransactions();
      await this.saveSourceTransactions();
      await this.saveTransactions();
      await this.terminateSync(SyncStatusEnum.synced);
    } catch (error) {
      await this.terminateSync(SyncStatusEnum.dataFetchFail);
    }
  }

  private async pullTransactions() {
    this.rawTransactions = await this.transactionProcessingService.rawBasiqTransactions(
      this.accountView,
      true,
    );
  }

  private mapToGlossTransactions() {
    this.mappedBasiqData = this.basiqMapper.mapBasiqData(
      this.accountView,
      this.rawTransactions.data,
    );
  }

  private async saveCategories() {
    await this.userStoreService.categories.saveToVault(this.mappedBasiqData.categories, true);
  }

  private async validateTransactions() {
    const validatedTransactions = await this.transactionProcessingService.validateTransactions(
      this.mappedBasiqData.transactions,
    );

    this.validatedTransactions = validatedTransactions.filter(
      (transaction) => !transaction.isDuplicate,
    );
  }

  private async saveSourceTransactions() {
    const sourceTransactionsToSave = this.validatedTransactions.map((t) =>
      this.basiqMapper.toSourceTransactionView(this.accountView, t),
    );

    if (sourceTransactionsToSave.length > 0) {
      await this.importStoreService.sourceTransaction.saveToVault(sourceTransactionsToSave, true);
    }
  }

  private async saveTransactions() {
    if (!this.hasInitialBasiqOBAT()) {
      const basiqOBAT: TransactionView = this.generateBasiqOBAT(this.validatedTransactions);
      this.validatedTransactions.push(basiqOBAT);
    }

    if (this.validatedTransactions.length > 0) {
      await this.transactionStoreService.transaction.saveToVault(this.validatedTransactions, true);
    }
  }

  private generateBasiqOBAT(transactionsToSave: TransactionView[]) {
    const theEarliestTransaction = transactionsToSave.sort(sortTransactionViews)[0];
    return this.newBasiqOBAT(theEarliestTransaction);
  }

  private hasInitialBasiqOBAT() {
    const transactions = this.sortedAccountTransactions();

    const hasNoTransactions = transactions?.length === 0;
    const hasNoInitialOBAT =
      transactions[0]?.bankImportedBalance &&
      transactions[0]?.bankImportedBalanceOrigin !== Origin.basiq;

    return !(hasNoTransactions || hasNoInitialOBAT);
  }

  private sortedAccountTransactions() {
    return this.transactionStoreService.transaction
      .transactionViews()
      .filter((t) => t.accountLink.id === this.accountView.id)
      .sort(sortTransactionViews);
  }

  private newBasiqOBAT(theEarliestTransaction: TransactionView) {
    const transaction = new TransactionView();

    transaction.transactionDate = subDays(theEarliestTransaction.transactionDate, 1);
    transaction.bankImportedBalance = this.accountView.c_balance - this.basiqMapper.totalAmount;
    transaction.bankImportedBalanceOrigin = Origin.basiq;
    transaction.quantity = GlossQuantityUtils.fromModel({
      cvrt: 1,
      ccy: theEarliestTransaction.quantity.currency,
      cvsym: "",
      qty: {
        amt: 0,
        sym: theEarliestTransaction.quantity.actualQuantity.symbol,
        prcs: 8,
      },
    });
    transaction.sourceLink = { id: null };
    transaction.accountLink = theEarliestTransaction.accountLink;
    transaction.categories = [];
    transaction.classifications = [];
    transaction.direction =
      transaction.bankImportedBalance > 0 ? TransactionDirection.In : TransactionDirection.Out;
    transaction.description = "Opening Alignment on Basiq import ";
    transaction.valuationPrice = null;
    transaction.linkedTo = [];
    transaction.definition = TransactionStatusEnum.opening;
    transaction.kind = "";

    return transaction;
  }

  async terminateSync(statusEnum: SyncStatusEnum) {
    const accountViews = this.userStoreService.accounts.accountViews().map((acc) => acc.clone());
    const institutionViews = this.userStoreService.institutions
      .institutionViews()
      .map((i) => i.clone());
    const newSyncStatuses: SyncStatusView[] = [];
    const syncingAccounts = accountViews.map((account) => {
      account.institution = institutionViews.find(
        (institution) => institution.id === account.institutionLink.institutionId,
      );
      if (account.id !== this.id) {
        return account;
      }

      const newSyncStatus = new SyncStatusView({
        id: crypto.randomUUID(),
        key: statusEnum,
        vid: null,
        dc: new Date().toISOString(),
        dm: new Date().toISOString(),
        v: 1,
        acId: account.id,
      });
      newSyncStatuses.push(newSyncStatus);
      account.syncStatusLink.push({ id: newSyncStatus.id });
      return account;
    });

    await this.importStoreService.syncStatus.saveToVault(newSyncStatuses, true);
    await this.userStoreService.accounts.saveToVault(syncingAccounts, true);
  }
}

import { ModelView } from "../model.view";
import { Origin } from "../../types/general-types";
import {
  DefaultSourceBookStoreModel,
  SourceAccountModelVersion,
  SourceAccountStoreModel,
} from "../../store/source-account-store.model";

export class SourceAccountView extends ModelView<SourceAccountStoreModel> {
  protected version: SourceAccountModelVersion;

  source: string;
  accountId: string;
  origin: Origin;

  constructor(model?: SourceAccountStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.source = model.src;
      this.accountId = model.acId;
      this.origin = model.org;
    } else {
      this.version = DefaultSourceBookStoreModel.v;
    }
  }

  toStoreModel(): SourceAccountStoreModel {
    return {
      acId: this.accountId,
      dc: this.dateCreated.toISOString(),
      dm: this.dateModified.toISOString(),
      id: this.id,
      org: this.origin,
      src: this.source,
      v: 1,
      vid: this.vaultId,
    };
  }

  clone(): SourceAccountView {
    return new SourceAccountView(this.toStoreModel());
  }
}

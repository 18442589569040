import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";

import { ToasterType } from "@bitwarden/web-vault/app/components/old-tables/transaction-table/transaction-table.component";
import { Transaction } from "../../models/data/blobby/transaction.data";

import { LinkValidatorRules } from "./link-validator.rules";
import { Validator } from "./validator";

export type ValidatorResponseTypes = {
  status: ToasterType;
  messageId: string;
  description: string;
};

export class LinkValidator extends LinkValidatorRules implements Validator {
  private VALIDATOR_RESPONSE: ValidatorResponseTypes = {
    status: "success",
    messageId: "linkedTransactionSuccess",
    description: "",
  };

  private DELETE_VALIDATOR_RESPONSE: ValidatorResponseTypes = {
    status: "success",
    messageId: "deleteTransactionSuccess",
    description: "",
  };

  private resetValidator() {
    this.VALIDATOR_RESPONSE = {
      status: "success",
      messageId: "linkedTransactionSuccess",
      description: "",
    };
  }

  validateReval(transaction: TransactionView): ValidatorResponseTypes {
    this.resetValidator();
    if (transaction.revalTransaction) {
      return (this.VALIDATOR_RESPONSE = {
        status: "error",
        messageId: "linkedTransferError",
        description: "transactionIsRevalAndCanNotBeLinked",
      });
    }

    return this.VALIDATOR_RESPONSE;
  }

  validateTransfer(transactions: Transaction[]): ValidatorResponseTypes {
    this.resetValidator();
    const notEqualSymbols = !this.isSameSymbols(transactions);
    if (notEqualSymbols) {
      return (this.VALIDATOR_RESPONSE = {
        status: "error",
        messageId: "linkedTransferError",
        description: "isSameSymbols",
      });
    }

    if (this.isNotZeroBalance(transactions)) {
      return (this.VALIDATOR_RESPONSE = {
        status: "error",
        messageId: "linkedTransferError",
        description: "isNotZeroBalance",
      });
    }

    return this.VALIDATOR_RESPONSE;
  }

  validateConversion(transactions: Transaction[]): ValidatorResponseTypes {
    this.resetValidator();

    if (this.getZeroBalanceForConversion(transactions)) {
      return (this.VALIDATOR_RESPONSE = {
        status: "error",
        messageId: "linkedTransferError",
        description: "isNotZeroBalance",
      });
    }

    return this.VALIDATOR_RESPONSE;
  }

  validateDelete(transactions: Transaction[]): ValidatorResponseTypes {
    this.resetValidator();

    if (!this.isRealTransactions(transactions)) {
      return (this.DELETE_VALIDATOR_RESPONSE = {
        status: "error",
        messageId: "deleteTransactionError",
        description: "thereAreRevalTransactions",
      });
    }

    return this.DELETE_VALIDATOR_RESPONSE;
  }
}

import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";

@Component({
  selector: "app-symbol-renderer",
  templateUrl: "../desc-renderer.component.html",
})
export class SymbolRendererComponent implements ICellRendererAngularComp {
  title!: string;
  subtitle!: string;

  agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }

  setData(params: ICellRendererParams) {
    const symbol = params.data.symbol as SymbolView; // change that when we type AGGrid
    this.title = symbol?.c_asOf?.base ?? symbol.code;
    this.subtitle = symbol?.c_asOf?.date?.date ?? "";
  }
}

import { inject, Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe, getCurrencySymbol } from "@angular/common";

@Pipe({
  name: "money",
})
export class MoneyPipe implements PipeTransform {
  private decimalPipe = inject(DecimalPipe);
  constructor() {}
  transform(
    value: number,
    currencyCode: string,
    display?: "code" | "symbol" | "symbol-narrow" | string,
    digitsInfo?: string,
    locale?: string,
  ): string | null {
    const formattedValue = this.decimalPipe.transform(value, "1.2-2");
    const currencySymbol = getCurrencySymbol(currencyCode, "wide");
    if (!formattedValue) {
      return null;
    }
    return `${currencySymbol} ${formattedValue}`;
  }
}

import { Injectable, Injector } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ConsoleLogService } from "@bitwarden/common/services/consoleLog.service";
import { ReferenceDataService } from "@bitwarden/web-vault/app/services/DataService/reference-data/reference-data.service";

import { Transaction } from "../../../models/data/blobby/transaction.data";
import { PreferenceService } from "../../DataService/preference/preference.service";
import { CalculationServiceAbstraction } from "../calculation.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class TransactionNormalizeService implements CalculationServiceAbstraction {
  private _baseCurrency: string;
  protected logService: LogService = new ConsoleLogService(false);
  // preferenceSetting$: Observable<Preference | boolean>;
  private preferenceService: PreferenceService;
  private injector: Injector;
  // TODO: @mmeshel - move this to the TransactionView when ready
  // private normalizeTransaction: NormalizeTransaction;
  referenceDataService: ReferenceDataService;

  constructor(injector: Injector) {
    this.injector = injector;
    this.preferenceService = this.injector.get(PreferenceService);
    this.referenceDataService = this.injector.get(ReferenceDataService);
  }

  private async getBaseCurrency(): Promise<void> {
    /*   const preferences  = <Preference | false>this.getState(this.preferenceSetting$);
    if(preferences){
      this._baseCurrency = preferences.baseCurrency;
    }*/
    // TODO: get the base currency from ngrx when preferences are stored correctly
    // don't cache the base currency as this might cause issues with a stale base currency
    // after setting it
    const prefBaseCurrency = await this.preferenceService.get("baseCurrency");
    if (prefBaseCurrency) {
      if (typeof prefBaseCurrency === "string" && this._baseCurrency !== prefBaseCurrency) {
        this._baseCurrency = prefBaseCurrency;
      }
    }
  }

  /**
   * @deprecated - we need to update this to TransactionView before we can use it again
   * @private
   */
  private async setupNormalizeTransactionClass() {
    if (!this._baseCurrency) {
      await this.getBaseCurrency();
    }

    // TODO - @mmeshel move this to TransactionView when ready
    /*
    if (!this.normalizeTransaction) {
      this.normalizeTransaction = new NormalizeTransaction(
        this._baseCurrency,
        this.logService,
        null,
        this.referenceDataService,
      );
    }
     */
  }

  /**
   * @deprecated - this method should no longer be used as we are moving to TransactionView and state management
   * model. We should no longer need to normalize transactions using a service and should use the normalizeTransaction
   * class instead. The reference data needs to come from the referenceServiceStore.
   * @param transaction
   */
  async normalizeImportedTransaction(transaction: Transaction): Promise<void> {
    // await this.setupNormalizeTransactionClass();
    // logic moved to NormalizeTransaction class when web workers were introduced
    // return await this.normalizeTransaction.normalizeImportedTransaction(transaction);
    return;
  }
}

<div class="tw-flex tw-flex-col tw-gap-1">
  @if (label) {
    <div class="tw-flex tw-gap-1 tw-font-medium tw-text-neutral-800 tw-z-10">
      <span class="tw-whitespace-nowrap">{{ label }}</span>
      <span *ngIf="isRequired" class="tw-text-sm">*</span>
    </div>
  }
  <div class="tw-relative tw-h-12 tw-w-full tw-items-center">
    <div
      class="tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg tw-bg-neutral-50 tw-px-3 tw-shadow-button"
      (click)="toggleExpand()"
    >
      <span>{{ selectedOption }}</span>
      <icon
        [name]="'down-arrow'"
        [size]="5"
        [currentColor]="'neutral-800'"
        class="tw-right-3"
      ></icon>
    </div>
    <div *ngIf="isExpanded" class="tw-absolute tw-z-10 tw-w-full tw-bg-white tw-shadow-lg">
      <ng-container *ngTemplateOutlet="dropdownPanel"></ng-container>
    </div>
  </div>
</div>

<ng-template #dropdownPanel>
  <div class="tw-absolute tw-mt-1 tw-w-full tw-rounded-lg tw-bg-white tw-p-2 tw-shadow-lg">
    <div
      *ngFor="let option of options"
      class="tw-flex tw-h-12 tw-w-full tw-cursor-pointer tw-items-center tw-rounded-lg tw-px-3 hover:tw-bg-neutral-50"
      [ngClass]="{ 'tw-bg-neutral-50': option === selectedOption }"
      (click)="selectOption(option)"
    >
      {{ option }}
    </div>
  </div>
</ng-template>

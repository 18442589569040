import { Component, effect, inject } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";

@Component({
  selector: "app-aggregate-amount-cell-renderer",
  templateUrl: "./aggregate-amount-cell-renderer.component.html",
})
export class AggregateAmountCellRenderer implements ICellRendererAngularComp {
  private userStore = inject(UserStoreService);
  amount: number;
  baseCurrency: string;

  constructor() {
    effect(() => {
      this.baseCurrency = this.userStore.preferences.preferenceView().baseCurrency;
    });
  }

  agInit(params: any): void {
    this.amount = params.data?.valuation?.normalizedValue?.amount || 0;
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}

import { Component, Input } from "@angular/core";
import { HelperCollapse } from "@bitwarden/web-vault/app/shared/utils/helper-collapse";

@Component({
  selector: "app-accordion",
  templateUrl: "./accordion.component.html",
})
export class AccordionComponent {
  protected _id: string;
  helperCollapse: HelperCollapse;

  @Input() isClickable: true;
  @Input() expandIcon: string;
  @Input() collapseIcon: string;
  @Input() customClass?: string;
  @Input() contentTemplate!: any;
  @Input() set id(value: string) {
    this._id = "#" + value;
  }

  constructor() {
    this.helperCollapse = new HelperCollapse();
  }

  onAccordionClicked() {
    if (this.isClickable) {
      this.helperCollapse.toggleContent();
    }
  }
}

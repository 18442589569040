import {
  accountCreation,
  InstitutionAccountGroup,
  interestCreation,
  transactionTransfer,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import {
  EstimateActionProperties,
  ScenarioHelpInfo,
  ScenarioPermutation,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { InterestRateScenarioOption } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/interest-rate-scenario-option";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";

export class BestNewInterestRate extends InterestRateScenarioOption {
  /**
   * createPermutations - Create the different possible sets of permutations to run
   *                      By default, this should only run the ones from blobby so that it will
   *                      work for user generated scenarios eventually
   */
  async createPermutations() {
    this.permutations = [];

    if (!this.hasPositiveAccounts) {
      return;
    }

    const symbol = this.scenarioGroup.symbol;
    if (this.startingAccountBalances) {
      const possibleAccounts = await this.util.getAllScenarioAccounts();

      // get all the existing institutionalAccountIDs
      const existingInstitutionalAccounts =
        await this.getExistingInstitutionalAccounts(possibleAccounts);
      const { newInstitutionalAccounts, institutionsMap } = await this.getNewInstitutionalAccounts(
        existingInstitutionalAccounts,
      );
      for (const newInstitutionalAccount of newInstitutionalAccounts) {
        const scenarioPermutation: ScenarioPermutation = { estimateActions: [] };
        const helpInfo: ScenarioHelpInfo = { symbol };

        // create a mock account of the new institutionalAccount
        const accountCreationParameters = this.util.createAccountCreationParameters(
          institutionsMap.get(newInstitutionalAccount.id),
          newInstitutionalAccount,
          symbol,
        );

        const permutation: EstimateActionProperties = {
          parameters: accountCreationParameters,
          estimateActionType: accountCreation,
        };
        scenarioPermutation.estimateActions.push(permutation);

        // check for the account type being one with interest rates
        const interestRates = newInstitutionalAccount.interestRates;
        const toAccountUrl: string = newInstitutionalAccount.link;
        const toInstitutionName: string = newInstitutionalAccount.institutionName;
        if (interestRates && interestRates.length > 0) {
          for (const oldAccountID in this.startingAccountBalances) {
            const oldAccount = await this.util.getBookById(oldAccountID);
            const oldAccountSymbolValue = this.util.getSymbolValue(oldAccountID, symbol, this);
            const creditAccount = this.util.isCredit(oldAccount);
            const fromInterestRate = await this.util.getInterestRates(oldAccount, symbol);
            const fromAccountUrl: string = await this.util.getAccountUrl(oldAccount);
            const fromInstitutionName: string = await this.util.getInstitutionName(oldAccount);

            // create a transfer estimate action to move the symbol to the new account
            if (oldAccount && !creditAccount && oldAccountSymbolValue > 0) {
              const transferParameters = this.util.createTransferParameters(
                oldAccount,
                null, // account hasn't been created it. Need to get it from createdRecords
                oldAccountSymbolValue,
                symbol,
                this,
              );
              await this.util.addToHelpFromTransferParameters(
                helpInfo,
                transferParameters,
                interestRates,
                fromInterestRate,
                toAccountUrl,
                fromAccountUrl,
                toInstitutionName,
                fromInstitutionName,
                newInstitutionalAccount.name,
              );
              const permutation: EstimateActionProperties = {
                parameters: transferParameters,
                estimateActionType: transactionTransfer,
              };
              scenarioPermutation.estimateActions.push(permutation);
            }
          }

          // create an interest estimate action to create interest transactions
          const interestParameters = this.util.createInterestParameters(null, interestRates, this);
          const permutation: EstimateActionProperties = {
            parameters: interestParameters,
            estimateActionType: interestCreation,
          };
          scenarioPermutation.estimateActions.push(permutation);
        }
        if (scenarioPermutation.estimateActions.length > 0) {
          scenarioPermutation.scenarioHelpInfo = helpInfo;
          this.permutations.push(scenarioPermutation);
        }
      }
    }
  }

  private async getExistingInstitutionalAccounts(
    existingAccounts: Array<AccountView>,
  ): Promise<Array<string>> {
    const institutionalAccountNames: Array<string> = [];
    for (const account of existingAccounts) {
      const institutionalAccount = await this.util.getAccountType(account);
      if (institutionalAccount) {
        institutionalAccountNames.push(institutionalAccount);
      }
    }
    return institutionalAccountNames;
  }

  private async getNewInstitutionalAccounts(existingAccounts: Array<string>) {
    const institutionAccountGroup: InstitutionAccountGroup = {
      institutionsMap: new Map(),
      newInstitutionalAccounts: [],
    };
    const institutions = await this.util.getInstitutionsMasterList();

    if (institutions !== null && Array.isArray(institutions)) {
      for (const institution of institutions) {
        const institutionName = institution.name;
        for (const institutionalAccount of institution.availableAccounts) {
          if (institutionalAccount.useForScenarioBestRate) {
            // TODO: not sure that this works yet because insitution API isn't working and all the ones returned are created
            // at run time. Double check logic and uncomment this line when institution API is working.
            // TODO : @Sinan@Michelle previously it was ruling out the existing accounts . But weather user have it or not we should get the best rate.
            const institutionalAccountAndName = { institutionName, ...institutionalAccount };
            institutionAccountGroup.newInstitutionalAccounts.push(institutionalAccountAndName);
            institutionAccountGroup.institutionsMap.set(institutionalAccount.id, institution);
          }
        }
      }
    }
    return institutionAccountGroup;
  }
}

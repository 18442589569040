import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

import { InstitutionAccount } from "@bitwarden/web-vault/app/models/types/institution.type";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";

@Component({
  selector: "app-institutional-accounts",
  templateUrl: "./institutional-accounts.component.html",
})
export class InstitutionalAccountsComponent implements OnChanges {
  institutionAccounts: InstitutionAccount[] = [];
  @Input() institution: InstitutionView;
  @Input() isAppendRatesRange = true;
  @Input() preselectedInstitutionalAccount: InstitutionAccount;
  @Output() selectedInstitutionAccount: EventEmitter<InstitutionAccount> =
    new EventEmitter<InstitutionAccount>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.institution) {
      if (changes.institution.currentValue) {
        this.institutionAccounts = !this.isAppendRatesRange
          ? this.institution.availableAccounts
          : this.getRateRangeAppendedAccounts(this.institution.availableAccounts);
      } else {
        this.institutionAccounts = [];
      }
    }

    if (changes.preselectedInstitutionalAccount) {
      this.preselectedInstitutionalAccount = changes.preselectedInstitutionalAccount.currentValue;
    }
  }

  getRateRangeAppendedAccounts(institutionAccounts: InstitutionAccount[]): InstitutionAccount[] {
    return institutionAccounts.map((institutionAccount) => {
      return {
        ...institutionAccount,
        name: `${institutionAccount.name} ${this.getRate(institutionAccount)}`,
      };
    });
  }

  /**
   * Calculates the formatted interest rate or range of rates for a given set of rates.
   * This method determines the maximum and minimum rates from the provided array of rate objects,
   * and formats the final rate as a string. If there is more than one rate, it formats the rate as a range (min - max).
   * Otherwise, it returns the single rate. The rate is appended with "P.A." to denote per annum.
   *
   * @returns {string} The formatted interest rate or range of rates per annum.
   * @param data
   */
  getRate(data: InstitutionAccount) {
    if (data.interestRates) {
      const rate = data.interestRates;
      if (rate.length === 0) {
        return "";
      }

      const maxRate = rate.reduce((max: number, current: { rate: number }) => {
        return current.rate > max ? current.rate : max;
      }, rate[0].rate);
      const minRate = rate.reduce((min: number, current: { rate: number }) => {
        return current.rate < min ? current.rate : min;
      }, rate[0].rate);

      const finalRate = rate.length > 1 ? `${minRate}% - ${maxRate}%` : `${maxRate}%`;
      return `(${finalRate} p.a.)`;
    } else {
      return "";
    }
  }

  institutionAccountSelected(institutionAccount: InstitutionAccount) {
    if (this.selectedInstitutionAccount) {
      this.selectedInstitutionAccount.emit(institutionAccount);
    }
  }

  selectionCleared() {
    this.selectedInstitutionAccount.emit(null);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { WidgetMenuType, WidgetType } from "@bitwarden/web-vault/app/models/types/widget.types";

@Component({
  selector: "app-widget",
  templateUrl: "widget.component.html",
})
export class WidgetComponent implements OnInit {
  @Input() title: string;
  @Input() iconName: string;
  @Input() hideIcon: boolean = false;
  @Input() type: WidgetType = "static";
  @Input() menu?: {
    type: WidgetMenuType;
    options: { displayValue?: string; value: string; isSelected?: boolean }[];
  };
  @Output() onClick: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    if (this.menu?.options.length > 0) {
      this.setSelectedOption(this.menu.options[0].value);
    }
  }

  setSelectedOption(value: string) {
    this.menu.options.forEach((option) => {
      option.isSelected = value == option.value;
    });
  }

  onOptionClick(value: string) {
    this.setSelectedOption(value);
    this.onClick.emit(value);
  }
}

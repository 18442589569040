import { Component, EventEmitter, inject, Input, OnChanges, Output } from "@angular/core";

import { AccountSymbolTable } from "../../../tables/account-tables/accounts-symbol-table/accounts-symbol-table";
import { AccountTableView } from "@bitwarden/web-vault/app/models/types/account.types";
import {
  accountInputOptions,
  actionButtonOptions,
  institutionInputOptions,
} from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/accounts-add-edit/component.options";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import _ from "lodash";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { FormGroup } from "@angular/forms";
import {
  GlossInputOptions,
  SyncStatusAction,
} from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { InstitutionAccountView } from "@bitwarden/web-vault/app/models/view/institution/institution-account.view";
import { GlossSyncService } from "@bitwarden/web-vault/app/services/syncing/gloss-sync.service";
import { TablesFactory } from "@bitwarden/web-vault/app/gloss/tables/tables.factory";
import { TablesType } from "@bitwarden/web-vault/app/models/enum/tables.enum";
import { View } from "@bitwarden/web-vault/app/models/enum/view.enum";
import { ViewsService } from "@bitwarden/web-vault/app/services/views/views.service";

@Component({
  selector: "app-account-details",
  templateUrl: "./account-details.component.html",
})
export class AccountDetailsComponent implements OnChanges {
  private viewsService: ViewsService;
  private glossSyncService: GlossSyncService;
  @Input() account: AccountTableView;
  protected institutionInputOptions = institutionInputOptions;
  protected accountInputOptions: GlossInputOptions = {
    ...accountInputOptions,
    inputBlurred: (value: string) => this.accountNameSet(value),
    inputCleared: () => this.nameCleared(),
  };

  form: FormGroup;
  accountName: string;
  currency: string;
  isUserInput = true;
  selectedInstitution: InstitutionView;
  selectedAccountType: InstitutionAccountView;
  saveButtonOptions = new ActionButton({
    ...actionButtonOptions,
    text: this.i18nService.t("save"),
    onClick: this.submit.bind(this),
  });
  table: AccountSymbolTable = new TablesFactory().get(TablesType.AccountSymbol);
  // temp
  @Output() cancel = new EventEmitter<void>();
  cancelButtonOptions = new ActionButton({
    class: "neutral",
    text: this.i18nService.t("cancel"),
    onClick: this.onCancel.bind(this),
  });

  constructor(
    private i18nService: I18nService,
    private bookService: BookService,
    private globalService: GlobalService,
  ) {
    this.glossSyncService = inject(GlossSyncService);
    this.viewsService = inject(ViewsService);
  }

  ngOnChanges() {
    this.accountName = this.account.name;
    this.selectedInstitution = this.account.original.institution;
    this.table.selectedAccount = this.account.original;
    this.selectedAccountType = this.account.original.institutionAccountType;

    this.institutionInputOptions = {
      ...this.institutionInputOptions,
      value: this.selectedInstitution?.name,
    };
    this.accountInputOptions = { ...this.accountInputOptions, value: this.accountName };
  }

  accountNameSet(name: string) {
    this.accountName = name;
  }

  nameCleared() {
    this.accountName = null;
  }

  selectCurrency(currency: string) {
    this.currency = currency;
  }

  onSelectAccountType(selected: InstitutionAccountView) {
    this.selectedAccountType = selected;
    if (this.isUserInput) {
      this.accountInputOptions = {
        ...this.accountInputOptions,
        placeholder: `My ${selected.name}`,
      };
    }
  }

  goToPastView(): void {
    this.viewsService.view = View.PAST;
  }

  async fullSync() {
    await this.glossSyncService.startSync();
  }

  async actionCall(actionKey: SyncStatusAction) {
    if (actionKey === "retry") {
      await this.fullSync();
    }

    if (actionKey === "consent") {
      await this.glossSyncService.basiqReConsent();
    }

    /*    if(actionKey === "connect"){
      await this.glossSyncService.connect();
    }

    if(actionKey === "merge") {
      await this.glossSyncService.merge();
    }*/
  }

  private async submit() {
    try {
      const originalBook = await this.bookService.get(this.account.id);
      originalBook.name = this.accountName;
      originalBook.institutionLink = {
        institutionId: this.selectedInstitution.id,
        institutionAccountId: this.selectedAccountType ? this.selectedAccountType.id : null,
      };

      const updatedAccount = await this.bookService.update(originalBook, false);

      /** Account validation failed */
      if (!updatedAccount) {
        this.form.setErrors({ INVALID: true });
      }

      if (updatedAccount instanceof Book) {
        this.globalService.showSuccessMessage("success", "updatedSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}

import { ExternalView } from "@bitwarden/web-vault/app/models/view/external.view";

export type FetchParams = Record<string, boolean | number | string | object>;

export abstract class ExternalCollection {
  abstract clear(): void;
  abstract fetch(params: FetchParams): Promise<ExternalView>;
  protected abstract mapToView(data: any): ExternalView;
  abstract triggerChanges(): void;
}

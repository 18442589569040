import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { StateService } from "@bitwarden/common/abstractions/state.service";
import { RoleAccessService } from "../../../../apps/ironfly-web/src/app/services/permission/role-access.service";
import {
  ScopeValue,
  RoleClaim,
} from "../../../../apps/ironfly-web/src/app/models/data/role-access.data";

@Directive({
  selector: "[appRoleScope]",
})
export class RoleScopeDirective implements OnInit {
  private roleScope: ScopeValue;

  @Input() set appRoleScope(scope: ScopeValue) {
    this.roleScope = scope;
  }

  constructor(
    private roleAccessService: RoleAccessService,
    private stateService: StateService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  async ngOnInit(): Promise<void> {
    const roleScope = await this.stateService.getRoleScope();
    const roleClaim = (await this.stateService.getRoleClaim()) as RoleClaim;
    this.roleAccessService.setRoleScope(roleScope);
    this.roleAccessService.setRoleClaim(roleClaim);

    if (this.hasScopeAccess() || this.hasBetaAccess()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private hasScopeAccess() {
    const scope = this.roleAccessService.getRoleAccess().getScope();
    return scope.includes(this.roleScope);
  }

  private hasBetaAccess() {
    const scope = this.roleAccessService.getRoleAccess().getScope();
    return scope.includes("BETA_ACCESS");
  }
}

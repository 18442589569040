import { ModelView } from "../model.view";
import { AccountState, Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { ConnectionInfoType } from "@bitwarden/web-vault/app/models/types/connector.type";
import {
  ConnectorModelVersion,
  ConnectorStoreModel,
  DefaultConnectorStoreModel,
} from "@bitwarden/web-vault/app/models/store/connector.store.model";

export class ConnectorView extends ModelView<ConnectorStoreModel> {
  protected version: ConnectorModelVersion;

  name: string;
  origin: Origin;
  institutions: Institution[]; // todo use LinkedView
  connectionInfo: ConnectionInfoType;
  accountStates: AccountState[];

  constructor(model?: ConnectorStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.name = model.n;
      this.origin = model.o;
      this.institutions = model.ins; // todo use LinkedView
      this.connectionInfo = model.cinfo;
      this.accountStates = model.astat;
    } else {
      this.version = DefaultConnectorStoreModel.v;
    }
  }

  toStoreModel(): ConnectorStoreModel {
    return {
      dc: this.dateCreated.toISOString(),
      dm: this.dateModified.toISOString(),
      id: this.id,
      v: this.version,
      vid: this.vaultId,
      n: this.name,
      o: this.origin,
      ins: this.institutions, // todo use LinkedView
      cinfo: this.connectionInfo,
      astat: this.accountStates,
    };
  }

  clone(): ConnectorView {
    return new ConnectorView(this.toStoreModel());
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { HelpBoxComponent } from "@bitwarden/web-vault/app/gloss/help-box/help-box.component";

import { I18nService } from "../../../../../../libs/common/src/abstractions/i18n.service";
import { ActionButton } from "../../components/buttons/gloss-button/actionButton";
import { RoleAccessData } from "../../models/data/role-access.data";

@Component({
  selector: "app-user-access-page",
  templateUrl: "user-access-page.component.html",
})
export class UserAccessPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private roleAccess: RoleAccessData;

  earlyRequestDialogRef: MatDialogRef<HelpBoxComponent>;
  isPremiumUser = false;
  requestAccessButtonOptions: ActionButton;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private i18nService: I18nService,
  ) {
    this.requestAccessButtonOptions = new ActionButton({
      text: this.i18nService.t("requestAccess"),
      class: "primary",
      icon: "link",
      onClick: this.requestEarlyAccess.bind(this),
    });
  }

  ngOnInit() {
    this.checkRoleAccess();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkRoleAccess() {
    if (this.roleAccess) {
      const claim = this.roleAccess.getClaim();
      this.isPremiumUser =
        claim.gloss_subscription_tier === "premium" ||
        claim.gloss_subscription_tier === "enterprise";
    }
  }

  requestEarlyAccess = async () => {
    this.earlyRequestDialogRef = this.dialog.open(HelpBoxComponent, {
      panelClass: "no-background-dialog",
      data: {
        supportBoxHeader: this.i18nService.t("requestEarlyAccess"),
        textFieldMessage: this.i18nService.t("earlyAccessDefaultMessage"),
        dialogOpen: true,
        closeDialogue: this.closeEarlyRequestDialog.bind(this),
      },
      disableClose: true,
    });

    this.earlyRequestDialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  };

  closeEarlyRequestDialog() {
    this.earlyRequestDialogRef.close();
  }
}

import { ScenarioGroupView } from "@bitwarden/web-vault/app/models/view/scenario-group/scenario-group.view";
import { addYears } from "date-fns";

export function setAnchorDate(scenarioGroup: ScenarioGroupView, anchorDate?: Date) {
  anchorDate = anchorDate ?? new Date();
  if (anchorDate) {
    scenarioGroup.anchorPoint = {
      date: anchorDate.toISOString(),
      time: anchorDate.toISOString().split("T")[0],
      timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }
}

export function setEndDate(scenarioGroup: ScenarioGroupView, endDate?: Date) {
  endDate = endDate ?? addYears(new Date(), 1);
  if (endDate) {
    scenarioGroup.endDate = {
      date: endDate.toISOString(),
      time: endDate.toISOString().split("T")[0],
      timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }
}

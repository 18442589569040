import { DOCUMENT } from "@angular/common";
import {
  Component,
  inject,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  SecurityContext,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ApmService } from "@elastic/apm-rum-angular";

import { IndividualConfig, ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { AuthService } from "@bitwarden/common/abstractions/auth.service";
import { BroadcasterService } from "@bitwarden/common/abstractions/broadcaster.service";
import { CipherService } from "@bitwarden/common/abstractions/cipher.service";
import { CollectionService } from "@bitwarden/common/abstractions/collection.service";
import { CryptoService } from "@bitwarden/common/abstractions/crypto.service";
import { EventUploadService } from "@bitwarden/common/abstractions/event/event-upload.service";
import { InternalFolderService } from "@bitwarden/common/abstractions/folder/folder.service.abstraction";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { KeyConnectorService } from "@bitwarden/common/abstractions/keyConnector.service";
import { NotificationsService } from "@bitwarden/common/abstractions/notifications.service";
import { PasswordGenerationService } from "@bitwarden/common/abstractions/passwordGeneration.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { InternalPolicyService } from "@bitwarden/common/abstractions/policy/policy.service.abstraction";
import { SearchService } from "@bitwarden/common/abstractions/search.service";
import { SettingsService } from "@bitwarden/common/abstractions/settings.service";
import { StateService } from "@bitwarden/common/abstractions/state.service";
import { SyncService } from "@bitwarden/common/abstractions/sync/sync.service.abstraction";
import { VaultTimeoutService } from "@bitwarden/common/abstractions/vaultTimeout/vaultTimeout.service";

import { ApmConfig } from "../../config/apm-config";

import { RouterService } from "./core";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";
import { StoreLoaderService } from "@bitwarden/web-vault/app/services/dali/store.loader.service";

const BroadcasterSubscriptionId = "AppComponent";
const IdleTimeout = 60000 * 10; // 10 minutes

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnDestroy, OnInit {
  private lastActivity: number = null;
  private idleTimer: number = null;
  private isIdle = false;

  private destroy$ = new Subject<void>();

  /** This is the new app state using signal **/
  private appState: AppStateStoreService = inject(AppStateStoreService);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private broadcasterService: BroadcasterService,
    private folderService: InternalFolderService,
    private settingsService: SettingsService,
    private syncService: SyncService,
    private passwordGenerationService: PasswordGenerationService,
    private cipherService: CipherService,
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
    private i18nService: I18nService,
    private platformUtilsService: PlatformUtilsService,
    private ngZone: NgZone,
    private vaultTimeoutService: VaultTimeoutService,
    private cryptoService: CryptoService,
    private collectionService: CollectionService,
    private sanitizer: DomSanitizer,
    private searchService: SearchService,
    private notificationsService: NotificationsService,
    private routerService: RouterService,
    private stateService: StateService,
    private eventUploadService: EventUploadService,
    private policyService: InternalPolicyService,
    private keyConnectorService: KeyConnectorService,
    private apmService: ApmService,
    private apiService: ApiService,
    private storeLoaderService: StoreLoaderService,
  ) {
    this.loadApmInstance();
  }

  ngOnInit() {
    this.i18nService.locale$.pipe(takeUntil(this.destroy$)).subscribe((locale) => {
      this.document.documentElement.lang = locale;
    });

    this.ngZone.runOutsideAngular(() => {
      window.onmousemove = () => this.recordActivity();
      window.onmousedown = () => this.recordActivity();
      window.ontouchstart = () => this.recordActivity();
      window.onclick = () => this.recordActivity();
      window.onscroll = () => this.recordActivity();
      window.onkeypress = () => this.recordActivity();
    });

    this.broadcasterService.subscribe(BroadcasterSubscriptionId, async (message: any) => {
      this.ngZone.run(async () => {
        switch (message.command) {
          case "loggedIn":
            this.notificationsService.updateConnection(false);
            break;
          case "loggedOut":
            this.storeLoaderService.clearStores();
            this.appState.clearStore();
            this.routerService.setPreviousUrl("/");
            this.notificationsService.updateConnection(false);
            break;
          case "unlocked":
            this.notificationsService.updateConnection(false);
            break;
          case "authBlocked":
            this.routerService.setPreviousUrl(message.url);
            this.router.navigate(["/"]);
            break;
          case "logout":
            this.logOut(!!message.expired);
            break;
          case "lockVault":
            await this.vaultTimeoutService.lock();
            break;
          case "locked":
            this.notificationsService.updateConnection(false);
            this.router.navigate(["lock"]);
            break;
          case "lockedUrl":
            this.routerService.setPreviousUrl(message.url);
            break;
          case "syncStarted":
            this.appState.startProcess("vault-loading", "Decrypting vault...");

            break;
          case "syncCompleted":
            this.appState.endProcess("vault-loading");

            if (!this.appState.state().isStoreInitialised) {
              await this.initAppService();
            }
            break;
          case "upgradeOrganization": {
            const upgradeConfirmed = await this.platformUtilsService.showDialog(
              this.i18nService.t("upgradeOrganizationDesc"),
              this.i18nService.t("upgradeOrganization"),
              this.i18nService.t("upgradeOrganization"),
              this.i18nService.t("cancel"),
            );
            if (upgradeConfirmed) {
              this.router.navigate([
                "organizations",
                message.organizationId,
                "settings",
                "billing",
              ]);
            }
            break;
          }
          case "premiumRequired": {
            const premiumConfirmed = await this.platformUtilsService.showDialog(
              this.i18nService.t("premiumRequiredDesc"),
              this.i18nService.t("premiumRequired"),
              this.i18nService.t("upgrade"),
              this.i18nService.t("cancel"),
            );
            if (premiumConfirmed) {
              this.router.navigate(["settings/subscription/premium"]);
            }
            break;
          }
          case "emailVerificationRequired": {
            const emailVerificationConfirmed = await this.platformUtilsService.showDialog(
              this.i18nService.t("emailVerificationRequiredDesc"),
              this.i18nService.t("emailVerificationRequired"),
              this.i18nService.t("learnMore"),
              this.i18nService.t("cancel"),
            );
            if (emailVerificationConfirmed) {
              this.platformUtilsService.launchUri("https://www.gloss-vault.com/faq");
            }
            break;
          }
          case "showToast":
            this.showToast(message);
            break;
          case "convertAccountToKeyConnector":
            this.router.navigate(["/remove-password"]);
            break;
          default:
            break;
        }
      });
    });
  }

  ngOnDestroy() {
    this.broadcasterService.unsubscribe(BroadcasterSubscriptionId);
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async initAppService() {
    await Promise.all([this.setApmUserContext(), this.storeLoaderService.initialise()]);
  }

  private async logOut(expired: boolean) {
    await this.eventUploadService.uploadEvents();
    const userId = await this.stateService.getUserId();
    await Promise.all([
      this.syncService.setLastSync(new Date(0)),
      this.cryptoService.clearKeys(),
      this.settingsService.clear(userId),
      this.cipherService.clear(userId),
      this.folderService.clear(userId),
      this.collectionService.clear(userId),
      this.policyService.clear(userId),
      this.passwordGenerationService.clear(),
      this.keyConnectorService.clear(),
    ]);

    this.searchService.clearIndex();
    this.authService.logOut(async () => {
      if (expired) {
        this.platformUtilsService.showToast(
          "warning",
          this.i18nService.t("loggedOut"),
          this.i18nService.t("loginExpired"),
        );
      }

      await this.stateService.clean({ userId: userId });
      this.router.navigate(["/"]);
    });
  }

  private async recordActivity() {
    const now = new Date().getTime();
    if (this.lastActivity != null && now - this.lastActivity < 250) {
      return;
    }

    this.lastActivity = now;
    this.stateService.setLastActive(now);
    // Idle states
    if (this.isIdle) {
      this.isIdle = false;
      this.idleStateChanged();
    }
    if (this.idleTimer != null) {
      window.clearTimeout(this.idleTimer);
      this.idleTimer = null;
    }
    this.idleTimer = window.setTimeout(() => {
      if (!this.isIdle) {
        this.isIdle = true;
        this.idleStateChanged();
      }
    }, IdleTimeout);
  }

  private showToast(msg: any) {
    let message = "";

    const options: Partial<IndividualConfig> = {};

    if (typeof msg.text === "string") {
      message = msg.text;
    } else if (msg.text.length === 1) {
      message = msg.text[0];
    } else {
      msg.text.forEach(
        (t: string) =>
          (message += "<p>" + this.sanitizer.sanitize(SecurityContext.HTML, t) + "</p>"),
      );
      options.enableHtml = true;
    }
    if (msg.options != null) {
      if (msg.options.trustedHtml === true) {
        options.enableHtml = true;
      }
      if (msg.options.timeout != null && msg.options.timeout > 0) {
        options.timeOut = msg.options.timeout;
      }
    }

    this.toastrService.show(message, msg.title, options, "toast-" + msg.type);
  }

  private idleStateChanged() {
    if (this.isIdle) {
      this.notificationsService.disconnectFromInactivity();
    } else {
      this.notificationsService.reconnectFromActivity();
    }
  }

  private loadApmInstance() {
    const apmConfig = new ApmConfig();

    this.apmService.init({
      serviceName: apmConfig.SERVICE_NAME,
      serverUrl: apmConfig.SERVER_URL,
      environment: apmConfig.environment,
      serviceVersion: apmConfig.serviceVersion,
    });
  }

  private async setApmUserContext() {
    this.apiService.getProfile().then((profile) => {
      this.apmService.apm.setUserContext({
        id: profile.id,
        username: profile.name,
        email: profile.email,
      });
    });
  }
}

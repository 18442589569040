import {
  AccountCreationParameters,
  InterestParameters,
  TransferParameters,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import { InstitutionAccountLink } from "@bitwarden/web-vault/app/models/types/institution.type";
import {
  PermutationBalancePair,
  ScenarioHelpInfo,
  ScenarioOptionWinner,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { ScenarioOption } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/scenario-option";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { InstitutionAccountView } from "@bitwarden/web-vault/app/models/view/institution/institution-account.view";
import { InstitutionInterestView } from "@bitwarden/web-vault/app/models/view/institution/institution-interest.view";
import { ScenarioDataAggregator } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/scenario-data-aggregrator";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";

export class InterestRateScenarioOptionUtils extends ScenarioDataAggregator {
  /**
   * getInterestRates - given an account ID, see if there exists any interest rates for that
   * account type in that institution
   * @param account
   * @param symbol
   */
  async getInterestRates(account: AccountView, symbol: string) {
    if (account && account?.institutionLink) {
      const { institutionId, institutionAccountId }: InstitutionAccountLink =
        account.institutionLink;
      const institutionAccount = await this.getFilterInstitutionAccountById(
        institutionId,
        institutionAccountId,
      );
      if (institutionAccount) {
        const interestRates = institutionAccount.interestRates;

        // filter the interestRates by the symbol that we are looking for
        return interestRates.filter((rate) => rate.symbol === symbol);
      }
    }
    return;
  }

  async getAccountUrl(account: AccountView) {
    if (account && account?.institutionLink) {
      const { institutionId, institutionAccountId }: InstitutionAccountLink =
        account.institutionLink;
      const institutionAccount = await this.getFilterInstitutionAccountById(
        institutionId,
        institutionAccountId,
      );
      if (institutionAccount) {
        return institutionAccount.link;
      }
    }
    return;
  }

  async getInstitutionName(account: AccountView) {
    if (account && account?.institutionLink) {
      const { institutionId }: InstitutionAccountLink = account.institutionLink;
      const institution = await this.getInstitutionById(institutionId);
      if (institution) {
        return institution.name;
      }
    }
    return;
  }

  /**
   * createParameters - helper function to create the parameters for an interest creation estimate action
   * @param account
   * @param interestRates
   * @param scenarioOption
   */
  createInterestParameters(
    account: AccountView,
    interestRates: InstitutionInterestView[],
    scenarioOption: ScenarioOption,
  ): InterestParameters {
    return {
      account: account,
      accountBalances: scenarioOption.startingAccountBalances,
      currency: scenarioOption.scenarioGroup.symbol,
      startDate: scenarioOption.startDate,
      endDate: scenarioOption.endDate,
      interestRates: interestRates,
      userGeneratedEstimateTransactions: scenarioOption.userEstimateTransactions,
      startingTransactions: scenarioOption.startingTransactions,
      defaultSplitClassification: scenarioOption.defaultSplitClassification,
      defaultSplitCategory: scenarioOption.defaultSplitCategory,
      baseCurrency: scenarioOption.baseCurrency,
      referenceData: scenarioOption.referenceData,
    };
  }

  createTransferParameters(
    accountFrom: AccountView,
    accountTo: AccountView,
    amount: number,
    symbol: string,
    scenarioOption: ScenarioOption,
  ): TransferParameters {
    return {
      accountFrom: accountFrom, // account we are creating the transfer from
      accountTo: accountTo, // account we are creating the transfer to
      transactionDate: new Date(scenarioOption.scenarioGroup.anchorPoint.date), // transaction date
      amount: amount,
      symbol: symbol,
      defaultSplitClassification: scenarioOption.defaultSplitClassification,
      defaultSplitCategory: scenarioOption.defaultSplitCategory,
      baseCurrency: scenarioOption.baseCurrency,
      referenceData: scenarioOption.referenceData,
    };
  }

  async addToHelpFromTransferParameters(
    helpInfo: ScenarioHelpInfo,
    transferParameters: TransferParameters,
    toRate?: Array<InstitutionInterestView>,
    fromRate?: Array<InstitutionInterestView>,
    toAccountUrl?: string,
    fromAccountUrl?: string,
    ToInstitutionName?: string,
    FromInstitutionName?: string,
    mockAccountName?: string,
  ) {
    if (transferParameters?.amount) {
      if (helpInfo?.transferAmount) {
        helpInfo.transferAmount += transferParameters.amount;
      } else {
        helpInfo.transferAmount = transferParameters.amount;
      }
    }
    if (!helpInfo?.transferToAccount && transferParameters?.accountTo) {
      const accountToName = await this.getAccountType(transferParameters.accountTo);
      helpInfo.transferToAccount = {
        accountBank: ToInstitutionName,
        accountUrl: toAccountUrl,
        accountName: accountToName,
        rate: toRate,
      };
    }
    if (!helpInfo?.transferToAccount && mockAccountName) {
      helpInfo.transferToAccount = {
        accountBank: ToInstitutionName,
        accountUrl: toAccountUrl,
        accountName: mockAccountName,
        rate: toRate,
      };
    }
    if (transferParameters?.accountFrom) {
      const accountFromName = await this.getAccountType(transferParameters.accountFrom);
      if (helpInfo?.transferFromAccount) {
        helpInfo.transferFromAccount.push({
          accountBank: FromInstitutionName,
          accountUrl: fromAccountUrl,
          accountName: accountFromName,
          rate: fromRate,
        });
      } else {
        helpInfo.transferFromAccount = [
          {
            accountBank: FromInstitutionName,
            accountUrl: fromAccountUrl,
            accountName: accountFromName,
            rate: fromRate,
          },
        ];
      }
    }
  }

  createAccountCreationParameters(
    institution: InstitutionView,
    institutionalAccount: InstitutionAccountView,
    currency: string,
  ): AccountCreationParameters {
    return {
      accountName: institutionalAccount.name, // account name we are creating
      currency: currency, // default account currency
      newInstitutionalAccount: institutionalAccount,
      institution: institution,
    };
  }

  /**
   * calculateWinner - choose the winning permutation from the set
   */
  async calculateWinner(scenarioOption: ScenarioOption): Promise<ScenarioOptionWinner> {
    const winner = await scenarioOption.calculateWinner();
    const permutationBalancePairs: PermutationBalancePair[] = [];
    if (winner) {
      return winner;
    }

    if (scenarioOption.permutations.length > 0) {
      // choose the permutation with the highest balance on the end date
      let winningPermutation = scenarioOption.permutations[0];
      let maxBalance = await scenarioOption.getBalanceForPermutation(winningPermutation);
      for (const permutation of scenarioOption.permutations) {
        const newBalance = await scenarioOption.getBalanceForPermutation(permutation);
        permutationBalancePairs.push({ permutation, balance: newBalance });
        if (maxBalance && newBalance) {
          if (newBalance > maxBalance) {
            winningPermutation = permutation;
            maxBalance = newBalance;
          }
        } else if (newBalance) {
          winningPermutation = permutation;
          maxBalance = newBalance;
        }
      }
      /** TODO @Sinan@Michelle fix for following:
       * TODO - have an account with int. rates  and import basiq => scenario 2 works
       * TODO - import basiq  then create an account with interest rates => scenario 2  does not work
       * TODO Keep in mind that accounts from Basiq does not have interest rates
       * */
      const createdRecords = scenarioOption.combineCreatedRecords(winningPermutation);
      const fullTransactionList = scenarioOption.getFullTransactionList(
        createdRecords.transactions,
      );
      const scenarioHelp = winningPermutation.scenarioHelpInfo;
      scenarioHelp.bestTenAccountPermutations =
        this.getBestTenPermutations(permutationBalancePairs);
      return {
        createdRecords: createdRecords,
        finalBalanceAmount: maxBalance,
        fullTransactionList: fullTransactionList,
        helpInfo: scenarioHelp,
      };
    } else {
      return {
        createdRecords: { accounts: [], transactions: [] },
        fullTransactionList: [],
      };
    }
  }

  getBestTenPermutations(
    permutationBalancePairs: PermutationBalancePair[],
  ): PermutationBalancePair[] {
    const sortedPermutationBalancePairs = permutationBalancePairs.sort(
      (a, b) => b.balance - a.balance,
    );
    if (sortedPermutationBalancePairs.length < 10) {
      return sortedPermutationBalancePairs;
    }

    return sortedPermutationBalancePairs.slice(0, 10);
  }

  getSymbolValue(accountID: string, symbol: string, scenarioOption: ScenarioOption): number {
    if (scenarioOption.startingAccountBalances[accountID]?.[symbol]) {
      return scenarioOption.startingAccountBalances[accountID][symbol];
    }
    return 0;
  }

  isCredit(account: AccountView): boolean {
    return ["credit", "credit-card"].includes(account.type);
  }

  hasPositiveAccounts(scenarioOption: ScenarioOption): boolean {
    // check if there are positive accounts in the starting balance
    // set an escape flag on creating permutations if there are no positive accounts
    let hasPositiveAccounts = false;

    for (const account in scenarioOption.startingAccountBalances) {
      const accountBalances = scenarioOption.startingAccountBalances[account];
      for (const symbol in accountBalances) {
        if (accountBalances[symbol] > 0) {
          hasPositiveAccounts = true;
        }
      }
    }
    return hasPositiveAccounts;
  }
}

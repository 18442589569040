<div class="tw-w-full tw-items-center tw-rounded-lg tw-bg-white">
  <div
    class="tw-flex tw-h-12 tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-px-3"
    (click)="toggleExpand()"
  >
    <span>{{ selectedOption || title }}</span>
    <icon [name]="'down-arrow'" [size]="5" [currentColor]="'neutral-800'" class="tw-right-3"></icon>
  </div>
  <div *ngIf="isExpanded" class="tw-w-full tw-bg-white tw-p-2 tw-rounded-lg">
    <div
      *ngFor="let option of options"
      class="tw-flex tw-h-12 tw-w-full tw-cursor-pointer tw-items-center tw-px-3 tw-rounded-lg hover:tw-bg-neutral-50"
      [ngClass]="{ 'tw-bg-neutral-50': option === selectedOption }"
      (click)="selectOption(option)"
    >
      {{ option }}
    </div>
  </div>
</div>

import { StateProcess } from "@bitwarden/web-vault/app/services/store/app-state/state-process";
import { ProcessType } from "@bitwarden/web-vault/app/services/store/app-state/state-process.factory";
import { AppStateType } from "@bitwarden/web-vault/app/services/store/app-state/loading-state.type";
import { AppContext } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";

export const ContextPriorities = {
  "waiting-for-user": 0,
  system: 1,
  calculation: 2,
  data: 3,
  background: 4,
};

export class LoadingStateLogic {
  /** Add global state flag here **/

  calculateNewState(
    currentContext: Readonly<AppContext>,
    currentState: Readonly<AppStateType>,
    activeProcesses: Readonly<Map<ProcessType, StateProcess>>,
  ): AppStateType {
    /** First carry over the store initialisation as this is only being change once **/
    let completedStoreInitialisation = currentState.isStoreInitialised;

    /** Process completed process, flag if initialisation was completed **/
    Array.from(activeProcesses.values())
      .filter((process) => process.completed())
      .forEach((process) => {
        if (process.type === "store-initialisation") {
          completedStoreInitialisation = true;
        }
      });

    const idleState: AppStateType = {
      status: "idle",
      isStoreInitialised: completedStoreInitialisation,
    };

    /** Checkin gon active process **/
    const contextProcesses = Array.from(activeProcesses.values())
      .filter(
        (process) =>
          ContextPriorities[process.context] <= ContextPriorities[currentContext] &&
          !process.completed(),
      )
      .sort((a, b) => {
        // First sort by context priority ascending
        const contextComparison = ContextPriorities[a.context] - ContextPriorities[b.context];

        // If contexts are equal, sort by startTime descending
        if (contextComparison === 0) {
          return b.startTime - a.startTime; // Note the reversed order (b - a) for descending sort
        }
        return contextComparison;
      });

    // todo put the lowest priority on top ( could also use the startTime )
    if (contextProcesses.length > 0) {
      const runningProcess = contextProcesses.shift();

      if (runningProcess.inError()) {
        return {
          isStoreInitialised: completedStoreInitialisation,
          status: "error",
          error: runningProcess.errorMessage,
        };
      } else if (runningProcess.inProgress() || runningProcess.started()) {
        return {
          isStoreInitialised: completedStoreInitialisation,
          status: "pending",
          progress: runningProcess.overallProgress(),
          message: runningProcess.message,
          step: runningProcess.progressMessage,
        };
      } else {
        // eslint-disable-next-line no-console
        console.log("Unknown state for process:");
        // eslint-disable-next-line no-console
        console.error(runningProcess);
        return idleState;
      }
    } else {
      return idleState;
    }
  }
}

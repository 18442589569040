<div (keydown.enter)="$event.preventDefault()">
  <modal-header [title]="'accountRegion' | i18n" (onBackClicked)="onBackClick()"></modal-header>

  <div class="modal-body">
    <div class="tw-flex tw-justify-between tw-gap-12 md:tw-flex-col">
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        [title]="'australia' | i18n"
        [description]="'auOptionExplanation' | i18n"
        (onClick)="goWithBasiq()"
      >
        <bank-icons [banks]="getBanks('AU')"></bank-icons>
      </tile-button>
      <tile-button
        class="tw-w-1/2 md:tw-w-full"
        [title]="'unitedStates' | i18n"
        [description]="'usOptionExplanation' | i18n"
        (onClick)="goWithPlaid()"
      >
        <bank-icons [banks]="getBanks('US')"></bank-icons>
      </tile-button>
    </div>
  </div>
</div>

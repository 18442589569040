import { GeneratedCollection } from "../generated.collection.abstraction";
import { AccountView } from "../../../models/view/account/account.view";
import { signal, Signal, WritableSignal } from "@angular/core";
import { Observable } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";

export class FilteredAccountGeneratedCollection extends GeneratedCollection<AccountView> {
  protected _collection: WritableSignal<AccountView[]> = signal<AccountView[]>(null);
  collection: Signal<AccountView[]> = this._collection.asReadonly();
  collection$: Observable<AccountView[]> = toObservable(this.collection);

  update(collection: AccountView[]): void {
    this._collection.set([...collection]);
  }

  clear(): void {
    this._collection.set(null);
  }
}

import { ModelView } from "../model.view";
import {
  DefaultSourceCategoryStoreModel,
  SourceCategoryModelVersion,
  SourceCategoryStoreModel,
} from "../../store/source-category.store.model";

export class SourceCategoryView extends ModelView<SourceCategoryStoreModel> {
  protected version: SourceCategoryModelVersion;
  name: string;
  categoryId: string;

  constructor(model?: SourceCategoryStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.name = model.nm;
      this.categoryId = model.id;
    } else {
      this.version = DefaultSourceCategoryStoreModel.v;
    }
  }

  toStoreModel(): SourceCategoryStoreModel {
    return undefined;
  }

  clone(): SourceCategoryView {
    return new SourceCategoryView(this.toStoreModel());
  }
}

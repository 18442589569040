import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";

import { TransactionView } from "../../../models/view/transaction/transaction.view";

export const sortTransactionViews = (a: TransactionView, b: TransactionView): number => {
  const DIRECTION = { OUT: -1, IN: 1 };

  if (a.transactionDate > b.transactionDate) {
    return 1;
  }
  if (a.transactionDate < b.transactionDate) {
    return -1;
  }

  // if the dates are the same, then sort any opening transactions first
  if (
    a.definition === TransactionStatusEnum.opening &&
    b.definition !== TransactionStatusEnum.opening
  ) {
    return -1;
  }
  if (
    b.definition === TransactionStatusEnum.opening &&
    a.definition !== TransactionStatusEnum.opening
  ) {
    return 1;
  }

  // sort any closing transactions last
  if (
    a.definition === TransactionStatusEnum.closing &&
    b.definition !== TransactionStatusEnum.closing
  ) {
    return 1;
  }
  if (
    b.definition === TransactionStatusEnum.closing &&
    a.definition !== TransactionStatusEnum.closing
  ) {
    return -1;
  }

  // sort any alignment transactions after a normal transaction
  if (
    a.definition === TransactionStatusEnum.alignment &&
    b.definition !== TransactionStatusEnum.alignment
  ) {
    return 1;
  }
  if (
    b.definition === TransactionStatusEnum.alignment &&
    a.definition !== TransactionStatusEnum.alignment
  ) {
    return -1;
  }

  if (a.bankImportedBalance && b.bankImportedBalance) {
    // if transactionOne's balance + amount is equal to transactionTwo's balance, transactionTwo should come after transactionOne
    if (
      a.bankImportedBalance + a.quantity.actualQuantity.amount * DIRECTION[a.direction] ===
      b.bankImportedBalance
    ) {
      return -1;
    }
    if (
      b.bankImportedBalance + b.quantity.actualQuantity.amount * DIRECTION[b.direction] ===
      a.bankImportedBalance
    ) {
      return 1;
    }
  }
  // if a transaction is pending, it should be sorted last
  if (a.definition === TransactionStatusEnum.pending) {
    return 1;
  }
  if (b.definition === TransactionStatusEnum.pending) {
    return -1;
  }

  return 0;
};

import { Component, inject, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { Button } from "@bitwarden/web-vault/app/components/buttons/gloss-button/button";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { DataPluginStoreService } from "@bitwarden/web-vault/app/services/store/data-plugin/data-plugin.store.service";

@Component({
  selector: "app-institution-selection",
  templateUrl: "./institution-selection.component.html",
})
export class InstitutionSelectionComponent implements OnInit, OnDestroy {
  protected dataPluginStoreService: DataPluginStoreService = inject(DataPluginStoreService);
  protected formBuilder: FormBuilder = inject(FormBuilder);
  protected i18nService: I18nService = inject(I18nService);
  protected globalService: GlobalService = inject(GlobalService);
  protected institutionsIsRefreshing = false;

  form: FormGroup;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  selectedCountry: Country = null;
  nextButton: Button = new ActionButton({
    text: this.i18nService.t("next"),
    type: "submit",
    onClick: this.next.bind(this),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      closeDialogue: CallableFunction;
      openInstitutionDialogue: CallableFunction;
      openCreationOptionsDialogue: CallableFunction;
      openAccountAddModal: CallableFunction;
    },
    public dialog: MatDialog,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      institution: [null, Validators.required],
      country: [null, Validators.required],
    });
  }

  onBackClicked() {
    this.data.closeDialogue();
    this.data.openCreationOptionsDialogue();
  }

  setInstitution(institution: InstitutionView) {
    this.form.get("institution")?.setValue(institution);
  }

  setCountry(country: Country) {
    if (country !== null) {
      this.selectedCountry = country;
      this.form.get("country")?.setValue(country);

      if (!this.dataPluginStoreService.theInstitutionList.countryCode().includes(country.code)) {
        this.institutionsIsRefreshing = true;
        this.dataPluginStoreService.theInstitutionList
          .fetch({ countryCode: country.code })
          .catch(() => this.globalService.showErrorMessage("error", "institutionSelectionFailed"))
          .finally(() => {
            this.institutionsIsRefreshing = false;
          });
      }
    }
  }

  next() {
    const selectedInstitution = this.form.value.institution;
    if (this.form.valid) {
      this.data.closeDialogue();
      // todo: country passed in but function only takes one param?
      this.data.openAccountAddModal(selectedInstitution, this.selectedCountry);
    } else {
      this.nextButton.enableButton(true);
      this.globalService.showWarningMessage("warning", "institutionSelectionInfo");
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}

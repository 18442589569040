<!--<div class="tw-flex tw-w-full tw-max-w-[380px] tw-gap-2 tw-rounded-lg" [formGroup]="dateForm">-->
<!--  &lt;!&ndash;  start&ndash;&gt;-->
<!--  <div-->
<!--    class="tw-relative tw-flex tw-w-[190px] tw-flex-col tw-rounded-full tw-pl-2 xs:!tw-w-[130px] md:tw-w-[175px] md:tw-pl-0"-->
<!--  >-->
<!--    <div-->
<!--      class="tw-z-10 tw-ml-4 tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-whitespace-nowrap tw-font-medium tw-text-neutral-600"-->
<!--    >-->
<!--      Start Date-->
<!--    </div>-->
<!--    <div-->
<!--      (click)="toggleStartPickerVisibility()"-->
<!--      class="left-round-border tw-flex tw-h-12 tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-gap-3 tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-px-2"-->
<!--    >-->
<!--      <div class="tw-flex tw-items-center tw-justify-center">-->
<!--        &lt;!&ndash;        reference for v2&ndash;&gt;-->
<!--        &lt;!&ndash;        <mat-datepicker #startPicker></mat-datepicker>&ndash;&gt;-->
<!--        &lt;!&ndash;        <input&ndash;&gt;-->
<!--        &lt;!&ndash;          matInput&ndash;&gt;-->
<!--        &lt;!&ndash;          [matDatepicker]="startPicker"&ndash;&gt;-->
<!--        &lt;!&ndash;          formControlName="startDate"&ndash;&gt;-->
<!--        &lt;!&ndash;          (dateChange)="onDateChange($event, 'startDate')"&ndash;&gt;-->
<!--        &lt;!&ndash;          class="tw-pointer-events-none tw-absolute tw-opacity-0"&ndash;&gt;-->
<!--        &lt;!&ndash;        />&ndash;&gt;-->
<!--        &lt;!&ndash;        <app-calendar [pickerType]="'start'" (dateChangeEvent)="onDateChange($event, 'startDate')"></app-calendar>&ndash;&gt;-->
<!--        <div-->
<!--          class="tw-flex tw-w-full tw-min-w-[6rem] tw-justify-center tw-whitespace-nowrap tw-text-neutral-800"-->
<!--        >-->
<!--          {{ startDateText }}-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <app-calendar-->
<!--      #startPicker-->
<!--      appClickOutside-->
<!--      (clickOutside)="handleClickOutsideStart()"-->
<!--      *ngIf="toggleStartPicker"-->
<!--      [pickerType]="'start'"-->
<!--      class="tw-absolute tw-left-1/2 tw-top-full tw-z-10 tw-mt-1 tw&#45;&#45;translate-x-1/2 tw-transform"-->
<!--      [initialStartDate]="initialStartDate"-->
<!--      (dateChangeEvent)="onDateChange($event, 'startDate')"-->
<!--      (confirmEvent)="onStartPickerConfirm()"-->
<!--      (cancelEvent)="onPickerCancel()"-->
<!--    ></app-calendar>-->
<!--  </div>-->
<!--  &lt;!&ndash; start&ndash;&gt;-->

<!--  &lt;!&ndash;  end&ndash;&gt;-->
<!--  <div-->
<!--    class="tw-relative tw-flex tw-w-[190px] tw-flex-col tw-rounded-full tw-pr-2 xs:!tw-w-[130px] md:tw-w-[175px]"-->
<!--  >-->
<!--    <div-->
<!--      class="tw-z-10 tw-ml-4 tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-whitespace-nowrap tw-font-medium tw-text-neutral-600"-->
<!--    >-->
<!--      End Date-->
<!--    </div>-->
<!--    <div-->
<!--      (click)="toggleEndPickerVisibility()"-->
<!--      class="right-round-border tw-flex tw-h-12 tw-w-full tw-cursor-pointer tw-items-center tw-justify-between tw-gap-3 tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-gap-0 xs:tw-px-2"-->
<!--    >-->
<!--      <div class="tw-flex tw-items-center tw-justify-center">-->
<!--        &lt;!&ndash;        reference for v2&ndash;&gt;-->
<!--        &lt;!&ndash;        <mat-datepicker #endPicker></mat-datepicker>&ndash;&gt;-->
<!--        &lt;!&ndash;        <input&ndash;&gt;-->
<!--        &lt;!&ndash;          matInput&ndash;&gt;-->
<!--        &lt;!&ndash;          [matDatepicker]="endPicker"&ndash;&gt;-->
<!--        &lt;!&ndash;          [min]="getMinEndDate()"&ndash;&gt;-->
<!--        &lt;!&ndash;          formControlName="endDate"&ndash;&gt;-->
<!--        &lt;!&ndash;          (dateChange)="onDateChange($event, 'endDate')"&ndash;&gt;-->
<!--        &lt;!&ndash;          class="tw-pointer-events-none tw-absolute tw-opacity-0"&ndash;&gt;-->
<!--        &lt;!&ndash;        />&ndash;&gt;-->
<!--        &lt;!&ndash;        <app-calendar #endPicker [pickerType]="'end'"></app-calendar>&ndash;&gt;-->
<!--        <div-->
<!--          class="tw-flex tw-w-full tw-min-w-[6rem] tw-justify-center tw-whitespace-nowrap tw-text-neutral-800"-->
<!--        >-->
<!--          {{ endDateText }}-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="tw-overflow-hidden tw-rounded">-->
<!--        <svg class="tw-h-6 tw-w-6 xs:tw-scale-75">-->
<!--          <use xlink:href="images/sprite.svg#calendar" />-->
<!--        </svg>-->
<!--      </div>-->
<!--    </div>-->
<!--    <app-calendar-->
<!--      #endPicker-->
<!--      appClickOutside-->
<!--      (clickOutside)="handleClickOutsideEnd()"-->
<!--      *ngIf="toggleEndPicker"-->
<!--      [pickerType]="'end'"-->
<!--      [initialEndDate]="initialEndDate"-->
<!--      class="tw-absolute tw-left-1/2 tw-top-full tw-z-10 tw-mt-1 tw&#45;&#45;translate-x-1/2 tw-transform"-->
<!--      (dateChangeEvent)="onDateChange($event, 'endDate')"-->
<!--      (confirmEvent)="onEndPickerConfirm()"-->
<!--      (cancelEvent)="onPickerCancel()"-->
<!--    ></app-calendar>-->
<!--  </div>-->
<!--  &lt;!&ndash;  end&ndash;&gt;-->
<!--</div>-->

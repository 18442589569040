import { inject, Injectable } from "@angular/core";
import { ModelStoreAbstraction } from "@bitwarden/web-vault/app/services/store/model.store.abstraction";
import { StoreModelNames } from "@bitwarden/web-vault/app/services/dali/type/dali.type";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { SourceTransactionsStoreCollection } from "@bitwarden/web-vault/app/services/store/import/source-transactions.store.collection";
import {
  ImportStoreCollectionType,
  ModelCollectionInterface,
  StoreModelCollection,
} from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { SourceCategoryStoreCollection } from "@bitwarden/web-vault/app/services/store/import/source-category.store.collection";
import { SourceAccountStoreCollection } from "@bitwarden/web-vault/app/services/store/import/source-account.store.collection";
import { SyncStatusStoreCollection } from "@bitwarden/web-vault/app/services/store/import/sync-status.store.collection";
import { StoreData } from "@bitwarden/web-vault/app/models/store/store.data";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

@Injectable({
  providedIn: "root",
})
export class ImportStoreService
  extends ModelStoreAbstraction
  implements ModelCollectionInterface<ImportStoreCollectionType>
{
  protected log: LogService = inject(LogService);
  readonly sourceTransaction: SourceTransactionsStoreCollection =
    new SourceTransactionsStoreCollection();
  readonly sourceCategory: SourceCategoryStoreCollection = new SourceCategoryStoreCollection();
  readonly sourceAccount: SourceAccountStoreCollection = new SourceAccountStoreCollection();
  readonly syncStatus: SyncStatusStoreCollection = new SyncStatusStoreCollection();

  clearStore(): void {
    this.sourceTransaction.clear();
    this.sourceCategory.clear();
    this.sourceAccount.clear();
    this.syncStatus.clear();
    this.log.info("ImportStoreService cleared");
  }

  initialize(): void {
    this.log.info("Initializing ImportStoreService");
    this.sourceTransaction.triggerChanges();
    this.sourceCategory.triggerChanges();
    this.sourceAccount.triggerChanges();
    this.syncStatus.triggerChanges();
  }

  protected getStorageCollection(
    modelName: StoreModelNames,
  ): StoreModelCollection<StoreData, ModelView<StoreData>> {
    switch (modelName) {
      case "SourceTransactionStoreModel":
        return this.sourceTransaction;
      case "SourceCategoryStoreModel":
        return this.sourceCategory;
      case "SourceBookStoreModel":
        return this.sourceAccount;
      case "SyncStatusStoreModel":
        return this.syncStatus;
      default: {
        this.log.warning(`Need to implement ImportStoreService ${modelName}`);
      }
    }
  }
}

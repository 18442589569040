import { ConsoleLogService } from "@bitwarden/common/services/consoleLog.service";
import { EstimateActionData } from "@bitwarden/web-vault/app/models/data/blobby/estimate.action.data";
import { EstimateActionResponse } from "@bitwarden/web-vault/app/models/data/response/estimate-action.response";
import { BalanceByAccountsSymbols } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import {
  TransferParameters,
  TransferOutput,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import { CreatedRecords } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { TransactionBalanceHelpers } from "@bitwarden/web-vault/app/services/DataCalculationService/transactionBalances/transactionBalanceHelpers";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { createTransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.utils";

export class TransactionTransferAction extends EstimateActionData {
  parameters: TransferParameters;
  createdRecords: TransferOutput;
  groupedBalance: BalanceGrouping;
  runningAccountBalances: BalanceByAccountsSymbols;

  logger: ConsoleLogService;

  constructor(response: EstimateActionResponse) {
    super(response);
    this.logger = new ConsoleLogService(false);
  }

  async run(
    parameters: TransferParameters,
    createdRecords?: CreatedRecords,
  ): Promise<TransferOutput> {
    this.fillInParameters(createdRecords, parameters);
    await super.run(parameters, createdRecords);
    const transactions: Array<TransactionView> = [];

    // create the out transaction
    const outTransaction = await this.createTransferTransaction(
      this.parameters.accountFrom.id,
      this.parameters.accountFrom.institutionLink.institutionId,
      "Mock transfer : Out",
      this.parameters.amount * -1,
    );

    // create the in transaction
    const inTransaction = await this.createTransferTransaction(
      this.parameters.accountTo.id,
      this.parameters.accountTo.institutionLink.institutionId,
      "Mock transfer : In",
      this.parameters.amount,
    );

    // link the two transactions as a transfer in the system so they are not counted as a directional value
    outTransaction.linkedTo = [inTransaction.id];
    inTransaction.linkedTo = [outTransaction.id];

    transactions.push(outTransaction);
    transactions.push(inTransaction);

    if (this.runningAccountBalances) {
      const transactionBalanceHelpers = new TransactionBalanceHelpers();
      transactionBalanceHelpers.addTransactionToBalanceByAccounts(
        this.runningAccountBalances,
        outTransaction,
      );
      transactionBalanceHelpers.addTransactionToBalanceByAccounts(
        this.runningAccountBalances,
        inTransaction,
      );
    }

    // create the output to return
    this.createdRecords = {
      transactions: transactions,
      runningAccountBalances: this.runningAccountBalances,
    };
    return this.createdRecords;
  }

  /**
   * createTransferTransaction - create a TransactionView to represent the transfer of funds from one account to the
   * other account
   * @param accountID
   * @param institutionID
   * @param description
   * @param amount
   */
  async createTransferTransaction(
    accountID: string,
    institutionID: string,
    description: string,
    amount: number,
  ): Promise<TransactionView> {
    return createTransactionView(
      accountID,
      institutionID,
      amount,
      this.parameters.symbol,
      this.parameters.transactionDate,
      description,
      [],
      [],
      ["placeholder"],
    );
  }

  fillInParameters(createdRecords: CreatedRecords, parameter: TransferParameters) {
    // transform the arrays from the web worker back into book objects
    if (parameter?.accountTo === null && createdRecords.accounts.length > 0) {
      parameter.accountTo = createdRecords.accounts[0];
    }
  }
}

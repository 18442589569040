import {
  bestExistingInterestRate,
  bestNewInterestRate,
  currentInterestRate,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { ScenarioView } from "../../../models/view/scenario/scenario.view";
import { ScenarioGroupView } from "../../../models/view/scenario-group/scenario-group.view";

export class DefaultScenarios {
  currentScenarioMeta = {
    name: "Current Account Growth",
    scenarioType: currentInterestRate,
  };

  bestExistingScenarioMeta = {
    name: "Best Existing Account",
    scenarioType: bestExistingInterestRate,
  };

  bestNewScenarioMeta = {
    name: "Top Regional Account",
    scenarioType: bestNewInterestRate,
  };

  defaultScenarioGroupMeta = {
    name: "Interest Comparison Scenarios",
  };

  createDefaultScenarios(symbol: string) {
    const currentScenario = new ScenarioView();
    Object.assign(currentScenario, this.currentScenarioMeta);
    currentScenario.symbol = symbol;

    const bestExistingScenario = new ScenarioView();
    Object.assign(bestExistingScenario, this.bestExistingScenarioMeta);
    bestExistingScenario.symbol = symbol;

    const bestNewScenario = new ScenarioView();
    Object.assign(bestNewScenario, this.bestNewScenarioMeta);
    bestNewScenario.symbol = symbol;

    return [currentScenario, bestExistingScenario, bestNewScenario];
  }

  createDefaultScenarioGroup(symbol: string, scenarios: ScenarioView[]) {
    const scenarioGroup = new ScenarioGroupView();
    Object.assign(scenarioGroup, this.defaultScenarioGroupMeta);
    scenarioGroup.symbol = symbol;
    scenarioGroup.scenariosId = [];
    for (const scenario of scenarios) {
      scenarioGroup.scenariosId.push(scenario.id);
    }

    return [scenarioGroup];
  }
}

import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { SourceCategoryStoreModel } from "../../../../models/store/source-category.store.model";
import { SourceCategory } from "../../../../models/data/blobby/source-category";
import { sourceCategory_v0_to_v1 } from "../model-dto/source-category";

export class SourceCategoryParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported VaultFile parser version ${version}`);
    }

    if (version === 0) {
      return SourceCategoryParser_v0;
    }
    return SourceCategoryParser_v1;
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}
const SourceCategoryParser_v1 = (categoryModel: any): SourceCategoryStoreModel =>
  categoryModel as SourceCategoryStoreModel;
const SourceCategoryParser_v0 = (categoryModel: any): SourceCategoryStoreModel => {
  const category = new SourceCategory(categoryModel);
  const upgraded = sourceCategory_v0_to_v1(category);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = categoryModel.vid ?? "";
  return SourceCategoryParser_v1(upgraded);
};

import {
  getOptions,
  TableRow,
} from "@bitwarden/web-vault/app/gloss/tables/transaction-table/transaction-table";
import { ScenarioTransactionTableSubscription } from "./scenario-transaction-table.subscription";
import { GridOptions } from "ag-grid-enterprise";

export class ScenarioTransactionTable extends ScenarioTransactionTableSubscription {
  options: GridOptions<TableRow>;

  constructor() {
    super();
    this.options = getOptions();
  }
}

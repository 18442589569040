import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { Transaction } from "../../../../models/data/blobby/transaction.data";
import { TransactionResponse } from "../../../../models/data/response/transaction-response";
import { transaction_v0_to_v1, transaction_v1_to_v2 } from "../model-dto/transaction";
import { TransactionStoreModel } from "@bitwarden/web-vault/app/models/store/transaction.store.model";

export class TransactionParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported VaultFile parser version ${version}`);
    }

    if (version === 0) {
      return TransactionParser_v0;
    }

    if (version === 1) {
      return TransactionParser_v1;
    }

    return TransactionParser_v2;
  }

  override getSupportedVersion(): number[] {
    return [0, 1, 2];
  }
}

const TransactionParser_v2 = (transactionModel: any): TransactionStoreModel =>
  transactionModel as TransactionStoreModel;

const TransactionParser_v1 = (transactionModel: any): TransactionStoreModel => {
  const upgraded = transaction_v1_to_v2(transactionModel);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = transactionModel.vid ?? "";
  return TransactionParser_v2(upgraded);
};

const TransactionParser_v0 = (transactionModel: any): TransactionStoreModel => {
  const response = new TransactionResponse(transactionModel);
  const transaction = new Transaction(response);
  const upgraded = transaction_v0_to_v1(transaction);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = transactionModel.vid ?? "";
  return TransactionParser_v1(upgraded);
};

import { StoreData } from "./store.data";

export type InstitutionModelVersion = 1;
export interface InstitutionStoreModel extends StoreData {
  /**  **Version Number** **/
  v: InstitutionModelVersion;

  /** Name of the Institution*/
  name: string;
  /** **swift** structure code of the institution */
  swt: SwiftStoreModel;
  /** bic : business identifier code of the institution */
  bic: BicStoreModel;
  /** **BasiqID** : is the id on the institution that is linked here from Basiq */
  fk: string;
  /** **Avalaible Account** **/
  avaAcc: InstitutionAccountStoreModel[];
}

/** account types of institution */
export interface InstitutionAccountStoreModel {
  /** **id** **/
  id: string;
  /** **name** **/
  nm: string;
  /** **institutionName** **/
  istNm: string;
  /** **interestRates** **/
  intRts: InstitutionInterestStoreModel[];
  /** **useForScenarioBestRate** **/
  ufsbr: string;
  /** **link** **/
  lk: string;
}

export interface BicStoreModel {
  /** **bankCode** **/
  bc: string;
  /** **countryCode** **/
  cc: string;
  /** **locationCode** **/
  lc: string;
}

export interface SwiftStoreModel {
  /** **bankCode** **/
  bac: string;
  /** **countryCode** **/
  cc: string;
  /** **locationCode** **/
  lc: string;
  /** **branchCode** **/
  brc: string;
}

export type InstitutionInterestStoreModel = {
  /** **range** **/
  rn: number;
  /** **banded** **/
  ba: boolean;
  /** **rate** **/
  ra: number;
  /** **symbol** **/
  sy: string;
  /** **last_updated** **/
  lu: string;
  /** **condition_intrinsic_desc** **/
  cid: string;
  /** **condition_action_desc** **/
  cad: string;
};

const DefaultInstitutionInterestStoreModel: InstitutionInterestStoreModel = {
  ba: false,
  cad: "",
  cid: "",
  lu: "",
  ra: 0,
  rn: 0,
  sy: "",
};
const DefaultInstitutionAccountStoreModel: InstitutionAccountStoreModel = {
  id: "",
  intRts: [DefaultInstitutionInterestStoreModel],
  istNm: "",
  nm: "",
  ufsbr: "",
  lk: "",
};
const DefaultSwiftStoreModel: SwiftStoreModel = { bac: "", brc: "", cc: "", lc: "" };
const DefaultBicStoreModel: BicStoreModel = { bc: "", cc: "", lc: "" };

export const DefaultInstitutionStoreModel: InstitutionStoreModel = {
  avaAcc: [DefaultInstitutionAccountStoreModel],
  bic: DefaultBicStoreModel,
  dc: "",
  dm: "",
  fk: "",
  id: "",
  name: "",
  swt: DefaultSwiftStoreModel,
  v: 1,
  vid: "",
};

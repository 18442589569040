import { inject, Injectable } from "@angular/core";

import { SourceTransaction } from "@bitwarden/web-vault/app/models/data/blobby/source-transaction.data";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { AccountValidator } from "@bitwarden/web-vault/app/validators/entity/account-validator";

import { TransactionService } from "../../app/services/DataService/transaction/transaction.service";

import { BaseValidator, potentialTransaction, validationResult } from "./base-validator";
import { Validator } from "./validator";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { dateDiffWithoutTime } from "@bitwarden/web-vault/app/shared/utils/helper.date/date-comparison";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";

@Injectable({
  providedIn: "root",
})
export class BasiqValidator extends BaseValidator implements Validator {
  private transactionStoreService: TransactionStoreService;

  constructor(
    private transactionService: TransactionService,
    public accountValidator: AccountValidator,
    public bookService: BookService,
  ) {
    super(accountValidator, bookService);
    this.transactionStoreService = inject(TransactionStoreService);
  }

  /**
   * @deprecated
   * This will check if the record coming from plaid has duplicate
   * if no matches found on the record then it will be a new record
   *
   * @param importTransactions
   */

  async matchTransactionRecord(
    importTransactions: Array<potentialTransaction>,
  ): Promise<validationResult> {
    const existingTransactions = await this.transactionService.getAllSourceTransactions();

    return await this.validate(importTransactions, existingTransactions);
  }

  validateTransaction(transaction: TransactionView): TransactionView {
    transaction.isDuplicate = this.isDuplicate(transaction);
    return transaction;
  }

  isDuplicate(item: TransactionView) {
    const existingTransactions = this.transactionStoreService.enrichTransaction.collection();

    /** If the amount is a new amount then this is a new transaction */
    const isSameAmount = existingTransactions.filter((et) => {
      return et.quantity.actualQuantity.amount === item.quantity.actualQuantity.amount;
    });

    if (!isSameAmount.length) {
      return false;
    }

    /** if there is any transactions of the same date and amount*/
    const isSameDate = isSameAmount.filter((et) => {
      return dateDiffWithoutTime(et.transactionDate, item.transactionDate) === 0;
    });

    if (!isSameDate.length) {
      return false;
    }

    /** if there is any transactions of the same account, amount and date*/
    const isSameAccount = isSameDate.filter((et) => {
      return et.accountLink.id === item.accountLink.id;
    });

    if (!isSameAccount.length) {
      return false;
    }

    /** if there is any transactions of the same account, amount and date*/
    const isSameDescription = isSameAccount.filter((et) => {
      return et.description === item.description;
    });

    return !!isSameDescription.length;
  }

  /** sourceTransaction and transaction are saved on the same account when coming from Basiq */
  async checkAccountDuplicity(
    existingTransaction: SourceTransaction,
    transaction: potentialTransaction,
  ) {
    return existingTransaction.accountId === transaction.accountId;
  }
}

import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { ScenarioStoreModel } from "../../../../models/store/scenario.store.model";
import { ScenarioResponse } from "../../../../models/data/response/scenario.response";
import { Scenario } from "../../../../models/data/blobby/scenario.data";
import { scenario_v0_to_v1 } from "../model-dto/scenario";

export class ScenarioParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Category parser version ${version}`);
    }

    if (version === 0) {
      return ScenarioParser_v0;
    } else {
      return ScenarioParser_v1;
    }
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const ScenarioParser_v1 = (scenarioObj: any): any => scenarioObj as ScenarioStoreModel;
const ScenarioParser_v0 = (scenarioObj: any): any => {
  const response = new ScenarioResponse(scenarioObj);
  const scenario = new Scenario(response);
  const upgraded = scenario_v0_to_v1(scenario);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = scenarioObj.vid ?? "";
  return ScenarioParser_v1(upgraded);
};

import { inject } from "@angular/core";
import { UserStoreService } from "../../../services/store/user/user.store.service";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { SignalSubscriptionImpl } from "../data-subscription";
import { CalculationStoreService } from "../../../services/store/calculation/calculation.store.service";
import { getName } from "@bitwarden/web-vault/app/shared/utils/helper-string";
import type { TableSource } from "./transaction-table";

export class PastTransactionTableSubscription extends SignalSubscriptionImpl<TableSource[]> {
  userStoreService = inject(UserStoreService);
  calculationStoreService = inject(CalculationStoreService);

  get source() {
    const accountViews = this.calculationStoreService.filteredAccounts.collection();
    const institutionViews = this.userStoreService.institutions.institutionViews();
    const transactionViews = this.calculationStoreService.filteredTransactions.collection();

    if (!transactionViews) {
      return [];
    }

    return transactionViews
      .filter((transaction) => !transaction.c_revalTransaction)
      .map((transaction) => {
        return {
          description: transaction.description,
          transactionDate: transaction.transactionDate,
          balance: transaction.c_valuation.normalizedValue.amount,
          symbol: transaction.quantity.actualQuantity.symbol,
          institution: getName(institutionViews, transaction.c_institutionLink.id),
          account: getName(accountViews, transaction.accountLink.id),
        };
      })
      .sort((a, b) => b?.transactionDate?.getTime() - a?.transactionDate?.getTime());
  }
}

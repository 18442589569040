import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { BalanceCalculatedUtils } from "@bitwarden/web-vault/app/services/store/calculation/balances/balance.calculated.utils";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services//web-worker/WebWorkerQueue";
import { PeriodType } from "@bitwarden/web-vault/app/services/store/calculation/controls/period.control";
import { GroupingSelection } from "@bitwarden/web-vault/app/services/store/calculation/controls/group.control";
import { FilterControlType } from "@bitwarden/web-vault/app/services/store/calculation/controls/filter.control";
import { Signal } from "@angular/core";

export type CalculatedBalanceCollectionNames =
  | "BalanceByAccounts"
  | "BalanceByAccountsAndSymbols"
  | "BalanceByTime"
  | "BalanceByTimeAndAccounts"
  | "BalanceByTransactions"
  | "ScenarioBalanceByTime"
  | "ScenarioBalanceByTimeAndAccounts";

export type CalculatedBalances = Map<string, number>;
export type CalculatedBalancesArray = Array<Map<string, number>>;
export type CalculatedBalancesGroupedTwice = Map<string, CalculatedBalances>;
export type CalculatedBalancesGroupedTwiceArray = Array<CalculatedBalancesGroupedTwice>;

export type CalculatedBalanceType =
  | CalculatedBalances
  | CalculatedBalancesGroupedTwice
  | CalculatedBalancesArray
  | CalculatedBalancesGroupedTwiceArray;

export type CalculatedBalanceParams = {
  webWorkerQueue?: WebWorkerQueue;
  calculatedTransactions?: TransactionView[];
  filter?: FilterControlType;
  group?: GroupingSelection;
  period?: PeriodType;
};

export type ScenarioBalanceParams = {
  webWorkerQueue?: WebWorkerQueue;
  scenarioTransactions?: TransactionView[][];
  group?: GroupingSelection;
  period?: PeriodType;
};

export abstract class CalculatedBalanceCollection extends BalanceCalculatedUtils {
  protected abstract type: CalculatedBalanceCollectionNames;
  protected abstract calculations: CalculatedBalanceType;
  protected abstract _balances: Signal<CalculatedBalanceType>;
  abstract readonly balances: Signal<CalculatedBalanceType>;

  abstract updateCalculations(parameters: CalculatedBalanceParams): Promise<void>;
  abstract clear(): void;
  abstract getCalculations(): CalculatedBalanceType;
  abstract triggerChanges(): void;
}

export type CalculatedStoreAdapterInterface<T extends CalculatedCollectionType> = {
  [n in keyof T]: CalculatedBalanceCollection;
};

type CalculatedCollectionType = BalanceCalculatedAdapterType;

export type BalanceCalculatedAdapterType = {
  balanceByAccount: CalculatedBalanceCollection;
  balanceByTime: CalculatedBalanceCollection;
  balanceByTransaction: CalculatedBalanceCollection;
};

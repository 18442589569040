import { VaultCodeEnum } from "./type/dali.type";
import { StoreModel } from "./vault-parser/parser.type";
import { ParserFactory } from "./vault-parser/parser.factory";
import { VaultItemType } from "./vault.reader";
import { CipherView } from "@bitwarden/common/src/models/view/cipher.view";

export class VaultModelUpgrader {
  private static instance: VaultModelUpgrader;
  private readonly parserFactory: ParserFactory = new ParserFactory();

  // Private constructor to prevent direct instantiation
  private constructor() {}

  /**
   * Get the shared singleton instance of VaultAdapter.
   */
  static getInstance(): VaultModelUpgrader {
    if (!this.instance) {
      this.instance = new VaultModelUpgrader();
    }
    return this.instance;
  }

  parseVaultData(
    storeData: Map<VaultCodeEnum, StoreModel[]>,
    collections: VaultItemType[],
    cipher: CipherView,
  ) {
    const vaultCode = this.getVaultObjectCode(cipher.name);
    let processedCount: number = 0;

    for (const vaultItem of collections) {
      const version = vaultItem.v ?? 0;
      /** Attach vault id to the element **/
      vaultItem["vid"] = cipher.id;
      processedCount++;
      const parser = this.parserFactory.getParserFunction(vaultCode, parseInt(version));
      if (storeData.has(vaultCode)) {
        storeData.get(vaultCode).push(parser(vaultItem));
      } else {
        storeData.set(vaultCode, [parser(vaultItem)]);
      }
    }

    return processedCount;
  }

  upgradeCollection(collections: VaultItemType[], vaultCode: VaultCodeEnum, targetVersion: number) {
    const upgradedItem = [];
    for (const vaultItem of collections) {
      const parser = this.parserFactory.getParserFunction(vaultCode, targetVersion);
      upgradedItem.push(parser(vaultItem));
    }
    return upgradedItem;
  }

  protected getVaultObjectCode(cipherName: string): VaultCodeEnum {
    return <VaultCodeEnum>cipherName.split("-")[0];
  }
}

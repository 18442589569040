import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { currencies } from "../../../../manage/manage-preferences/currencies";

@Component({
  selector: "app-amount-renderer",
  templateUrl: "./amount-renderer.component.html",
})
export class AmountRendererComponent implements ICellRendererAngularComp {
  balance!: number;
  symbol!: string;

  agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }

  setData(params: ICellRendererParams) {
    this.balance = params.data.balance || 0;
    this.symbol = params.data.symbol || "";
  }

  protected readonly currencies = currencies;
}

import { Injectable } from "@angular/core";

import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { PreferenceView } from "@bitwarden/web-vault/app/models/view/preference/preference.view";
import { toObservable } from "@angular/core/rxjs-interop";
import {
  DefaultCurrencyPerLocation,
  UserLocationType,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";

@Injectable({
  providedIn: "root",
})
export class UserLocationService {
  private preference: PreferenceView;
  private preference$ = toObservable(this.userStore.preferences.preferenceView);

  constructor(private userStore: UserStoreService) {
    this.preference$.subscribe((preference) => {
      this.preference = preference.clone();
    });
  }

  getBanks(location: UserLocationType): string[] {
    const banks = {
      AU: ["cba", "anzb", "nab"],
      US: ["wfo", "ctg", "boa"],
      HK: ["hsbc", "scb", "boc"],
      NZ: [""],
      EU: [""],
      JP: [""],
      CN: [""],
    };

    return banks[location];
  }

  /**
   * Template Pattern Method that will be executed after selecting the user location
   *
   * @param location
   */
  async processUserLocation(location: UserLocationType) {
    this.preference.userLocation = location;
    this.preference.baseCurrency = DefaultCurrencyPerLocation[location];
    await this.userStore.preferences.save(this.preference);
  }
}

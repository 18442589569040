import { ApiService } from "../../abstractions/api.service";
import { AppIdService } from "../../abstractions/appId.service";
import { AuthService } from "../../abstractions/auth.service";
import { CryptoService } from "../../abstractions/crypto.service";
import { EnvironmentService } from "../../abstractions/environment.service";
import { KeyConnectorService } from "../../abstractions/keyConnector.service";
import { LogService } from "../../abstractions/log.service";
import { MessagingService } from "../../abstractions/messaging.service";
import { PlatformUtilsService } from "../../abstractions/platformUtils.service";
import { StateService } from "../../abstractions/state.service";
import { TokenService } from "../../abstractions/token.service";
import { TwoFactorService } from "../../abstractions/twoFactor.service";
import { AuthenticationType } from "../../enums/authenticationType";
import { LogInCredentials } from "../../models/domain/log-in-credentials";

import { BiometricsLoginStrategy } from "./biometricsLogin.strategy";
import { PasswordLogInStrategy } from "./passwordLogin.strategy";
import { PasswordlessLogInStrategy } from "./passwordlessLogin.strategy";
import { SsoLogInStrategy } from "./ssoLogin.strategy";
import { UserApiLogInStrategy } from "./user-api-login.strategy";

type GeneralLogInStrategy =
  | BiometricsLoginStrategy
  | PasswordLogInStrategy
  | PasswordlessLogInStrategy;
type LoginStrategy = SsoLogInStrategy | UserApiLogInStrategy | GeneralLogInStrategy;

interface GeneralLogInStrategyConstructor {
  new (
    cryptoService: CryptoService,
    apiService: ApiService,
    tokenService: TokenService,
    appIdService: AppIdService,
    platformUtilsService: PlatformUtilsService,
    messagingService: MessagingService,
    logService: LogService,
    stateService: StateService,
    twoFactorService: TwoFactorService,
    authSerive: AuthService,
  ): GeneralLogInStrategy;
}

export class LogInStrategyFactory {
  constructor(
    protected cryptoService: CryptoService,
    protected apiService: ApiService,
    protected tokenService: TokenService,
    protected appIdService: AppIdService,
    protected platformUtilsService: PlatformUtilsService,
    protected messagingService: MessagingService,
    protected logService: LogService,
    protected keyConnectorService: KeyConnectorService,
    protected environmentService: EnvironmentService,
    protected stateService: StateService,
    protected twoFactorService: TwoFactorService,
    protected authSerive: AuthService,
  ) {}

  create(credentials: LogInCredentials): LoginStrategy {
    let strategy: LoginStrategy;
    switch (credentials.type) {
      case AuthenticationType.Password:
        strategy = this.buildGeneralLogInStrategy(PasswordLogInStrategy);
        break;
      case AuthenticationType.Biometrics:
        strategy = this.buildGeneralLogInStrategy(BiometricsLoginStrategy);
        break;
      case AuthenticationType.Sso:
        strategy = this.buildSsoLogInStrategy();
        break;
      case AuthenticationType.UserApi:
        strategy = this.buildUserApiLogInStrategy();
        break;
      case AuthenticationType.Passwordless:
        strategy = this.buildGeneralLogInStrategy(PasswordlessLogInStrategy);
        break;
    }
    return strategy;
  }

  private buildSsoLogInStrategy(): SsoLogInStrategy {
    return new SsoLogInStrategy(
      this.cryptoService,
      this.apiService,
      this.tokenService,
      this.appIdService,
      this.platformUtilsService,
      this.messagingService,
      this.logService,
      this.stateService,
      this.twoFactorService,
      this.keyConnectorService,
    );
  }

  private buildUserApiLogInStrategy(): UserApiLogInStrategy {
    return new UserApiLogInStrategy(
      this.cryptoService,
      this.apiService,
      this.tokenService,
      this.appIdService,
      this.platformUtilsService,
      this.messagingService,
      this.logService,
      this.stateService,
      this.twoFactorService,
      this.environmentService,
      this.keyConnectorService,
    );
  }

  private buildGeneralLogInStrategy(ctor: GeneralLogInStrategyConstructor): GeneralLogInStrategy {
    return new ctor(
      this.cryptoService,
      this.apiService,
      this.tokenService,
      this.appIdService,
      this.platformUtilsService,
      this.messagingService,
      this.logService,
      this.stateService,
      this.twoFactorService,
      this.authSerive,
    );
  }
}

import { ConsoleLogService } from "@bitwarden/common/services/consoleLog.service";
import { EstimateActionData } from "@bitwarden/web-vault/app/models/data/blobby/estimate.action.data";
import { EstimateActionResponse } from "@bitwarden/web-vault/app/models/data/response/estimate-action.response";
import {
  AccountCreationParameters,
  AccountCreationOutput,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import { CreatedRecords } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { isOfType } from "@bitwarden/web-vault/app/models/types/general-types";
import {
  AllowedCurrencies,
  CurrencyType,
  DefaultSystemCurrency,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";

export class AccountCreationAction extends EstimateActionData {
  private _mockAccount: AccountView;

  parameters: AccountCreationParameters;
  createdRecords: AccountCreationOutput;
  logger: ConsoleLogService;

  constructor(response: EstimateActionResponse) {
    super(response);
    this.logger = new ConsoleLogService(false);
  }

  async run(
    parameters: AccountCreationParameters,
    createdRecords?: CreatedRecords,
  ): Promise<AccountCreationOutput> {
    await super.run(parameters, createdRecords);

    const accounts: Array<AccountView> = [];

    // create the mock account
    this.createMockAccount();

    accounts.push(this._mockAccount);

    // create the output to return
    this.createdRecords = {
      accounts: accounts,
    };

    return this.createdRecords;
  }

  private createMockAccount() {
    this._mockAccount = new AccountView();
    this._mockAccount.name = this.parameters.accountName;

    if (isOfType<CurrencyType>(this.parameters.currency, AllowedCurrencies)) {
      this._mockAccount.currency = this.parameters.currency;
    } else {
      this._mockAccount.currency = DefaultSystemCurrency;
    }

    this._mockAccount.institutionLink = {
      institutionId: this.parameters.institution.id,
      institutionAccountId: this.parameters.newInstitutionalAccount.id,
    };
  }
}

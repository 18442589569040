import { Component, inject, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { ApiService } from "@bitwarden/common/abstractions/api.service";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { UserVerificationService } from "@bitwarden/common/abstractions/userVerification/userVerification.service.abstraction";
import { Verification } from "@bitwarden/common/types/verification";
import { BasiqIoMapper } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/basiq-io/basiq-io-mapper";
import { SideMenuService } from "@bitwarden/web-vault/app/services/menu/side-menu.service";

import { StoreLoaderService } from "@bitwarden/web-vault/app/services/dali/store.loader.service";

@Component({
  selector: "app-purge-vault",
  templateUrl: "purge-vault.component.html",
})
export class PurgeVaultComponent {
  private storeLoader = inject(StoreLoaderService);

  @Input() organizationId?: string = null;

  masterPassword: Verification;
  formPromise: Promise<unknown>;

  constructor(
    private apiService: ApiService,
    private i18nService: I18nService,
    private platformUtilsService: PlatformUtilsService,
    private userVerificationService: UserVerificationService,
    private router: Router,
    private logService: LogService,
    private glossMenuService: SideMenuService,
    private dialogRef: MatDialogRef<PurgeVaultComponent>,
  ) {}

  async submit() {
    try {
      this.formPromise = this.userVerificationService
        .buildRequest(this.masterPassword)
        .then((request) => this.apiService.postPurgeCiphers(request, this.organizationId));

      await this.formPromise;

      await this.clearState();
      this.platformUtilsService.showToast("success", null, this.i18nService.t("vaultPurged"));
      BasiqIoMapper.refresh = true;
      if (this.organizationId != null) {
        this.router.navigate(["organizations", this.organizationId, "vault"]);
      } else {
        this.glossMenuService.setShouldRunWizard(true, false);
        this.router.navigate(["views"]);
      }
      this.dialogRef.close();
    } catch (e) {
      this.logService.error(e);
    }
  }

  private async clearState() {
    this.storeLoader.clearStores();
    this.storeLoader.initializeStores();
  }
}

import { Signal, computed } from "@angular/core";
import { Observable, Subscription } from "rxjs";

export interface Subscribable<T> {
  source: T;
  start(): void;
  stop(): void;
}

export abstract class ObservableSubscriptionImpl<T> implements Subscribable<Observable<T>> {
  private subscription: Subscription;
  private _data: T;

  onData(data: T): void {
    this._data = data;
  }

  get data(): T {
    return this._data;
  }

  abstract get source(): Observable<T>;

  start(): void {
    this.subscription = this.source.subscribe((d) => this.onData(d));
  }

  stop(): void {
    this.subscription.unsubscribe();
  }
}

export abstract class SignalSubscriptionImpl<T> implements Subscribable<T> {
  private _data: Signal<T>;

  get data(): T {
    if (!this._data) {
      this._data = computed(() => this.source);
    }

    return this._data();
  }

  abstract get source(): T;

  start(): void {}

  stop(): void {}
}

import { inject } from "@angular/core";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import type { SymbolTableView } from "@bitwarden/web-vault/app/models/types/symbol.types";
import { SignalSubscriptionImpl } from "../data-subscription";

export type TableSource = SymbolTableView;

/**
 * This class only need to deal with angular dependency
 * and the core logic is in getAccountTableViews function which can be easily unit tested
 */
export class SymbolTableSubscription extends SignalSubscriptionImpl<TableSource[]> {
  private transactionStore = inject(TransactionStoreService);

  get source() {
    const symbols = this.transactionStore.symbols.collection();

    const tableData: SymbolTableView[] = [];

    for (const symbol of symbols) {
      if (symbol?.c_asOf) {
        tableData.push({
          symbol: symbol,
          rate: {
            rate: symbol.c_asOf.rate,
            cross: symbol.c_asOf.symbol,
          },
          rightChevron: true,
        });
      } else {
        tableData.push({
          symbol: symbol,
          rate: {
            rate: 1,
            cross: symbol.code,
          },
          rightChevron: false,
        });
      }
    }
    return tableData;
  }
}

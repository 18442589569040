<div (keydown.enter)="$event.preventDefault(); $event.stopPropagation()">
  <modal-header [title]="'findInstitutionBank' | i18n" (onBackClicked)="onBackClicked()">
  </modal-header>

  <div class="modal-body">
    <form class="tw-flex tw-flex-col">
      <app-countries (selectedCountry)="setCountry($event)"></app-countries>
      <br />
      @if (this.institutionsIsRefreshing) {
        <p>Loading...</p>
      } @else {
        <app-autocomplete
          [items]="dataPluginStoreService.theInstitutionList.theInstitutionViews()"
          [placeholder]="'institutionBankPlaceholder' | i18n"
          [label]="'institutionBank' | i18n"
          (selectedItem)="setInstitution($event)"
        ></app-autocomplete>
      }

      <modal-footer>
        <app-gloss-button [options]="nextButton" class="tw-w-full"></app-gloss-button>
      </modal-footer>
    </form>
  </div>
</div>

import { ColDef } from "ag-grid-community";

import { GlossDate } from "../../../models/data/shared/gloss-date";

import {
  amountComparator,
  amountValueGetter,
  commonDefs,
  sortingComparator,
} from "../../../shared/utils/table/helper.ag-grid";
import { AggregateAmountCellRenderer } from "../../cell-renderers/aggregate-amount-renderer/aggregate-amount-cell-renderer.component";
import { BalanceCellRenderer } from "../../cell-renderers/balance-renderer/balance-renderer.component";
import { CategoryCellRenderer } from "../../cell-renderers/category-renderer/category-renderer.component";
// import { CheckBoxCellRenderer } from "../cell-renderers/check-box-renderer/check-box-cell-renderer.component";
import { ClassificationCellRenderer } from "../../cell-renderers/classification-renderer/classification-renderer.component";
import { InAmountCellRenderer } from "../../cell-renderers/in-amount-renderer/in-amount-cell-renderer.component";
import { NormalizedInAmountCellRenderer } from "../../cell-renderers/normalized-in-amount-renderer/normalized-in-amount-cell-renderer.component";
import { NormalizedOutAmountCellRenderer } from "../../cell-renderers/normalized-out-amount-renderer/normalized-out-amount-cell-renderer.component";
import { OutAmountCellRenderer } from "../../cell-renderers/out-amount-renderer/out-amount-renderer.component";

const cellStyle = {
  // TODO: Move to .scss
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export const transactionColumnDefinitions: ColDef[] = [
  // TODO: will be re-introduced
  // {
  //   headerName: " ",
  //   field: "isSelected",
  //   maxWidth: 30,
  //   cellClass: "_sCheckBox",
  //   suppressColumnsToolPanel: true,
  //   cellRenderer: CheckBoxCellRenderer,
  //   ...commonDefs,
  // },
  {
    headerName: "Transaction Date",
    field: "transactionDate",
    suppressColumnsToolPanel: true,
    rowGroup: true, // this will be overriden for mobile
    valueFormatter: (params) => {
      const transactionViewGDate = params.data?.glossDate;
      return transactionViewGDate ? GlossDate.getTableDate(transactionViewGDate) : "";
    },
    cellDataType: "text",
    ...commonDefs,
    flex: 1,
    minWidth: 150,
    sort: "desc",
  },
  {
    headerName: "Institution",
    field: "institutionName",
    cellDataType: "text",
    flex: 3,
    minWidth: 200,
    comparator: sortingComparator,
    ...commonDefs,
    cellStyle: cellStyle,
  },
  {
    headerName: "Account",
    field: "accountName",
    suppressColumnsToolPanel: true,
    cellDataType: "text",
    flex: 3,
    minWidth: 200,
    comparator: sortingComparator,
    ...commonDefs,
    cellStyle: cellStyle,
  },
  {
    headerName: "Product",
    field: "productName",
    hide: true,
    comparator: sortingComparator,
    cellDataType: "text",
    flex: 3,
    minWidth: 200,
    ...commonDefs,
    cellStyle: cellStyle,
  },
  {
    headerName: "Description",
    field: "description",
    cellDataType: "text",
    comparator: sortingComparator,
    ...commonDefs,
    flex: 4,
    minWidth: 250,
    cellStyle: cellStyle,
  },
  {
    headerName: "Categories",
    field: "categories",
    ...commonDefs,
    flex: 3,
    minWidth: 200,
    cellRenderer: CategoryCellRenderer,
    comparator: sortingComparator,
    cellDataType: "text",
  },
  {
    headerName: "Classification",
    field: "classifications",
    hide: true,
    ...commonDefs,
    flex: 3,
    minWidth: 200,
    valueFormatter: (p: any) => "",
    cellDataType: "text",
    cellRenderer: ClassificationCellRenderer,
    comparator: sortingComparator,
  },
  {
    headerName: "In",
    field: "amountIn",
    ...commonDefs,
    flex: 1,
    minWidth: 115,
    valueFormatter: (p: any) => "",
    cellRenderer: InAmountCellRenderer,
    comparator: amountComparator,
    cellDataType: "text",
  },
  {
    headerName: "Out",
    field: "amountOut",
    ...commonDefs,
    flex: 1,
    minWidth: 115,
    valueFormatter: (p: any) => "",
    cellRenderer: OutAmountCellRenderer,
    comparator: amountComparator,
    cellDataType: "text",
  },
  {
    headerName: "In (Normalized)",
    field: "in_normalized",
    hide: true,
    suppressColumnsToolPanel: true,
    ...commonDefs,
    flex: 1,
    minWidth: 115,
    valueFormatter: (p: any) => "",
    cellRenderer: NormalizedInAmountCellRenderer,
    valueGetter: amountValueGetter,
    comparator: amountComparator,
    cellDataType: "text",
  },
  {
    headerName: "Out (Normalized)",
    field: "out_normalized",
    hide: true,
    suppressColumnsToolPanel: true,
    ...commonDefs,
    flex: 1,
    minWidth: 115,
    valueFormatter: (p: any) => "",
    cellRenderer: NormalizedOutAmountCellRenderer,
    valueGetter: amountValueGetter,
    comparator: amountComparator,
    cellDataType: "text",
  },
  {
    headerName: "Aggregate",
    field: "aggregateAmount",
    hide: true,
    ...commonDefs,
    flex: 1,
    minWidth: 115,
    valueFormatter: (p: any) => "",
    cellDataType: "text",
    cellRenderer: AggregateAmountCellRenderer,
  },
  {
    headerName: "Balance",
    field: "balance",
    suppressColumnsToolPanel: true,
    ...commonDefs,
    flex: 1,
    minWidth: 115,
    valueFormatter: (p: any) => "",
    cellRenderer: BalanceCellRenderer,
    valueGetter: (params: any) => params.data?.dailyBalance,
    cellDataType: "text",
    comparator: (valueA, valueB) => {
      return valueA - valueB;
    },
  },
  {
    headerName: "Currency",
    field: "currency",
    hide: true,
    ...commonDefs,
    flex: 1,
    minWidth: 75,
    cellDataType: "text",
    valueGetter: (params: any) => params.data?.quantity?.currency,
  },
];

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { DateFormatPipe } from "@bitwarden/web-vault/app/pipes/date-format.pipe";
import {
  ReferenceDataView,
  IReferenceData,
} from "@bitwarden/web-vault/app/models/view/reference-data/reference-data.view";
import { RefMetaData } from "@bitwarden/web-vault/app/models/types/revaluation.types";

export class ReferenceDataCalculationClass {
  private logger: LogService;
  private allReferenceData: Array<IReferenceData>;
  private globalReserveCurrency = "USD";

  constructor(logger: LogService, referenceData: IReferenceData[]) {
    this.logger = logger;
    if (referenceData) {
      this.allReferenceData = referenceData;
    }
  }

  /**
   * Given a symbol, base and date, retrieve the reference data for that record
   */
  getReferenceData(symbol: string, base: string, startDate: number): Array<IReferenceData> {
    this.logger.debug("ReferenceDataCalculationService: getReferenceData");
    const referenceData = [];

    const closestReference = this.filterLastPriceDate(startDate, (symbolData) => {
      const baseMatch = base === symbolData.base;
      const symbolMatch = symbolData.symbol === symbol;

      return symbolMatch && baseMatch;
    });

    if (closestReference) {
      referenceData.push(closestReference);
    }

    return referenceData;
  }

  /**
   * return the reference data for the given symbol, base, from date and to date
   */
  getRefData(refMetaData: RefMetaData): IReferenceData[] {
    const { symbol, fromDate, toDate, base } = refMetaData;
    return this.allReferenceData
      .filter((reference) => {
        const baseMatch = base === reference.base;
        const symbolMatch = reference.symbol === symbol;
        const refDate = new Date(reference.date.date);
        const dateMatch =
          fromDate.getTime() <= refDate.getTime() && toDate.getTime() >= refDate.getTime();

        return symbolMatch && baseMatch && dateMatch;
      })
      .sort((a, b) => new Date(a.date.date).getTime() - new Date(b.date.date).getTime());
  }

  /**
   * Given a symbol, base retrieve all the reference data that matches
   */
  getReferenceForSymbolDate(symbol: string, startDate: number): IReferenceData {
    return this.filterLastPriceDate(startDate, (symbolData) => {
      return symbolData.symbol === symbol;
    });
  }

  /**
   * Given a symbol retrieve all the reference data that matches this as the base
   */
  getReferenceForBaseDate(symbol: string, startDate: number): IReferenceData {
    return this.filterLastPriceDate(startDate, (symbolData) => {
      return symbol === symbolData.base;
    });
  }

  /**
   * Given the symbol and the base, try to find each of these relative to the global reserve currency.
   * This should be used when the symbol and base can reference data does not exist to link each other
   * @param symbol
   * @param base
   * @param startDate
   */
  getReferenceDataFromReserveCurrency(
    symbol: string,
    base: string,
    startDate: number,
  ): Array<IReferenceData> {
    const referenceData: Array<IReferenceData> = [];

    let reverseBase = false;
    let reverseSymbol = false;

    let baseData = this.getReferenceData(base, this.globalReserveCurrency, startDate);
    if (baseData.length === 0) {
      baseData = this.getReferenceData(this.globalReserveCurrency, base, startDate);
      reverseBase = true;
    }
    let symbolData = this.getReferenceData(symbol, this.globalReserveCurrency, startDate);
    if (symbolData.length === 0) {
      symbolData = this.getReferenceData(this.globalReserveCurrency, symbol, startDate);
      reverseSymbol = true;
    }

    const [baseRef] = baseData;
    const [symbolRef] = symbolData;

    if (baseRef && symbolRef) {
      let baseRate = baseRef.rate;
      let symbolRate = symbolRef.rate;

      if (!reverseBase) {
        baseRate = 1 / baseRate;
      }
      if (reverseSymbol) {
        symbolRate = 1 / symbolRate;
      }
      const rate = baseRate * symbolRate;
      const refDataMeta: IReferenceData = {
        date: { date: new Date(startDate).toISOString(), timeZone: null, time: null },
        base: base,
        symbol: symbol,
        rate: rate,
      };
      // better not to do that, will mess up the sorting when the date is missing
      const newReference = new ReferenceDataView();
      Object.assign(newReference, refDataMeta);
      referenceData.push(newReference);
    }

    return referenceData;
  }

  /**
   * Note that we currently assume that the most recent reference data to the date is the most accurate.
   * However, in the future the most accurate one might be the one that produces the greatest base value
   * when normalized unique on currency symbol.
   *
   * @param date
   * @param fn
   * @private
   */
  private filterLastPriceDate(date: number, fn: (data: IReferenceData) => boolean): IReferenceData {
    const pipe = new DateFormatPipe();
    let lowerBoundValue = 0;
    let upperBoundValue = Number.MAX_SAFE_INTEGER;
    let lowerBound: IReferenceData;
    let upperBound: IReferenceData;
    const estimatedVaule = date;
    const data = this.allReferenceData;

    for (let i = 0; i < data.length; i++) {
      const di = data[i];

      if (!fn(di)) {
        continue;
      }

      const n = new Date(pipe.transform(di.date.date ?? undefined)).getTime();
      if (n === estimatedVaule) {
        lowerBound = di;
        upperBound = di;
        break;
      }

      if (n <= upperBoundValue && estimatedVaule < n) {
        upperBoundValue = n;
        upperBound = di;
      } else if (n >= lowerBoundValue && n < estimatedVaule) {
        lowerBoundValue = n;
        lowerBound = di;
      }
    }
    return lowerBound ?? upperBound;
  }
}

import { StoreSubscriber } from "../dependency.subscriber.abstraction";
import { inject } from "@angular/core";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { filter, distinctUntilChanged, Observable } from "rxjs";
import { PreferenceView } from "@bitwarden/web-vault/app/models/view/preference/preference.view";

export class BaseCurrencyPreferenceSubscriber extends StoreSubscriber<PreferenceView> {
  protected dependentStore: UserStoreService = inject(UserStoreService);

  getCollection(): PreferenceView {
    return this.dependentStore.preferences.preferenceView();
  }

  getObservable(): Observable<PreferenceView> {
    return this.dependentStore.preferences.publisher$.pipe(
      filter((x) => x !== null),
      distinctUntilChanged((previous, current) => {
        return previous.baseCurrency === current.baseCurrency;
      }),
    );
  }
}

import { Component, inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import _ from "lodash";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { InstitutionsAddEditComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-institutions/institutions-add-edit/institutions-add-edit.component";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { InstitutionResponse } from "@bitwarden/web-vault/app/models/data/response/institution.response";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { BicType, SwiftType } from "@bitwarden/web-vault/app/models/types/institution.type";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import {
  InstitutionAccountStoreModel,
  InstitutionInterestStoreModel,
  InstitutionStoreModel,
} from "@bitwarden/web-vault/app/models/store/institution.store.model";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";

@Component({
  selector: "app-add-institution-form",
  templateUrl: "./add-institution-form.component.html",
})
export class AddInstitutionFormComponent implements OnInit {
  private userStoreService: UserStoreService;
  @Input() data: any;

  /** form controller of adding an institution */
  addForm: FormGroup;
  /** form controller of creating an institution */
  createForm: FormGroup;
  loading = true;
  /** hides institutions if country is not selected yet*/
  displayInstitutions = false;
  /** if an institution is being edited */
  editMode = false;
  /** institution id when editing. used in constructor */
  institutionId: string;
  /** in addition holds the selected country from the list */
  selectedCountry: Country;
  /** in addition holds the selected institution from the list */
  selectedInstitution: InstitutionView;
  /** holds the list of filtered countries based on user input in add section */
  filteredCountries: Country[];
  /** holds the institutions of the selected country in add section */
  countryInstitutions: InstitutionView[];
  /** holds the filtered institutions of the selected country based on user input in add section */
  filteredCountryInstitutions: InstitutionView[] = [];
  /** helps listening to change in addForm controls. */
  private destroy$ = new Subject<void>();
  /** hardcoded countries with name and code */
  protected countries: Country[] = [];

  constructor(
    private institutionService: InstitutionService,
    private dialogRef: MatDialogRef<InstitutionsAddEditComponent>,
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.userStoreService = inject(UserStoreService);
    /** define the add institution form to handle changes */
    this.addForm = this.formBuilder.group({
      addedInstitutionName: ["", Validators.required],
      addedInstitutionCountry: ["", Validators.required],
      addedInstitutionAccount: [""],
    });

    /** add a listener  to changes happen in country selection. If we control changes with onChange event in the selection list , because there is a conflict between mat-ui-autocomplete
     * and form input , it does not reflect accordingly. */
    this.addForm
      .get("addedInstitutionCountry")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newName) => {
        this.selectedCountry = this.filteredCountries?.find((country) => country.name === newName);
        this.addForm.controls.addedInstitutionName.setValue("");
      });

    /** add a listener  to changes happen in institution selection. If we control changes with onChange event in the selection list , because there is a conflict between mat-ui-autocomplete
     * and form input , it does not reflect accordingly. */
    this.addForm
      .get("addedInstitutionName")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newName) => {
        this.selectedInstitution = this.filteredCountryInstitutions?.find(
          (institution) => institution.name === newName,
        );
      });

    /** define the create institution form to handle changes*/
    this.createForm = this.formBuilder.group({
      createdInstitutionName: ["", Validators.required],
      createdInstitutionCountry: ["", Validators.required],
    });
  }
  async ngOnInit() {
    // Get avalaible institution country from API
    this.filteredCountries = this.countries = await this.institutionService.getCountryMasterList();

    /** set institution id if it is an update action */
    if (this.data?.institution?.id) {
      this.institutionId = this.data.institution.id;
    }
    this.editMode = this.institutionId != null;

    if (this.editMode) {
      this.editMode = true;
      await this.presetFormWithInstitution();
    }
    this.loading = false;
  }

  /** Fill the form with the institution that is being updated */
  async presetFormWithInstitution() {
    this.setSelectedCountryFromInstitution(this.data.institution);
    this.countryInstitutions = await this.institutionService.getCountryInstitutions(
      this.selectedCountry,
    );
    this.filteredCountryInstitutions = this.countryInstitutions;
    this.displayInstitutions = true;
    this.addForm.controls.addedInstitutionName.setValue(this.data.institution.name);
  }

  /** this is to keep memory clean from memory leaks. In case of a subscription waiting for a value but the component is killed */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /** set the country to the institution's country */
  async setSelectedCountryFromInstitution(institution: Institution) {
    const countries = await this.institutionService.getCountryMasterList();
    this.selectedCountry = countries.find(
      (country) => country.code === institution.swift.countryCode,
    );
    this.addForm.controls.addedInstitutionCountry.setValue(this.selectedCountry.name);
  }

  /** filters the list of country based on the entered input in addition section */
  searchForCountry() {
    const typed = this.addForm.value.addedInstitutionCountry;
    this.filteredCountries = this.countries.filter((country) =>
      country.name.toLowerCase().includes(typed.toString().toLowerCase()),
    );
  }

  /** filters the list of institutions based on the entered input in addition section */
  searchForInstitution() {
    const typed = this.addForm.value.addedInstitutionName;
    this.filteredCountryInstitutions = this.countryInstitutions.filter((institution) =>
      institution.name.toLowerCase().includes(typed.toString().toLowerCase()),
    );
  }

  /** updates state when user selects a country from the list in addition section*/
  async addCountrySelected(country: Country) {
    this.selectedCountry = country;
    this.displayInstitutions = true;
    this.countryInstitutions = await this.institutionService.getCountryInstitutions(
      this.selectedCountry,
    );
    this.filteredCountryInstitutions = this.countryInstitutions;
  }

  /** updates state when user selects an institution from the list */
  institutionSelected(institution: InstitutionView) {
    this.selectedInstitution = institution;
  }

  /** close the dialogue */
  closeDialogue() {
    this.dialogRef.close();
  }

  /** process a selected institution */
  async submitAddForm() {
    this.loading = true;

    try {
      if (this.addForm.valid) {
        if (this.editMode) {
          await this.updateInstitution();
        } else {
          await this.addInstitution();
        }
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
    this.loading = false;
  }

  /** select an institution from the list and add it to the system */
  async addInstitution() {
    this.selectedInstitution.availableAccounts = this.selectedInstitution.availableAccounts.map(
      (account) => {
        if (!account.id) {
          account.id = crypto.randomUUID();
        }
        return account;
      },
    );

    const instoStoreModel: InstitutionStoreModel = {
      name: this.selectedInstitution.name,
      fk: null,
      vid: "",
      dc: "",
      dm: "",
      id: crypto.randomUUID(),
      v: 1,
      swt: {
        brc: "",
        bac: this.selectedInstitution.swift.bankCode,
        cc: this.selectedCountry.code,
        lc: "",
      },
      bic: {
        bc: this.selectedInstitution.swift.bankCode,
        cc: this.selectedCountry.code,
        lc: "",
      },
      avaAcc: this.selectedInstitution.availableAccounts.map(
        (account): InstitutionAccountStoreModel => {
          return {
            id: account.id,
            nm: account.name,
            istNm: account.institutionName,
            ufsbr: account.useForScenarioBestRate,
            lk: account.link,
            intRts: account.interestRates.map((rate): InstitutionInterestStoreModel => {
              return {
                rn: rate.range,
                ba: rate.banded,
                ra: rate.rate,
                sy: rate.symbol,
                lu: rate.last_updated,
                cid: rate.condition_intrinsic_desc,
                cad: rate.condition_action_desc,
              };
            }),
          };
        },
      ),
    };
    /*const newInstitution = new Institution(new InstitutionResponse(institutionObject));*/
    const newInstitution = new InstitutionView(instoStoreModel);

    try {
      /* const createdInstitution = await this.institutionService.create(newInstitution);*/
      const createdInstitution = this.userStoreService.institutions.save(newInstitution);
      if (createdInstitution instanceof InstitutionView) {
        await this.data.actionSucceeded("createdSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async updateInstitution() {
    try {
      const clonedInstitution = _.cloneDeep(this.data.institution);

      clonedInstitution.name = this.selectedInstitution.name;
      clonedInstitution.swift = <SwiftType>{
        bankCode: this.selectedInstitution.swift.bankCode,
        countryCode: this.selectedCountry.code,
      };
      clonedInstitution.bic = <BicType>{
        bankCode: this.selectedInstitution.bic.bankCode,
        countryCode: this.selectedCountry.code,
      };
      clonedInstitution.availableAccounts = this.selectedInstitution.availableAccounts;

      const updatedInstitution = await this.institutionService.update(clonedInstitution, false);
      if (updatedInstitution instanceof Institution) {
        await this.data.actionSucceeded("updatedSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  async deleteInstitution(institution: Institution) {
    this.data.delete(institution);
  }

  async openContactForm() {
    /*TODO : Once Brain completed the form this button should open up the form*/
    this.dialogRef.close();
    await this.router.navigateByUrl("/manage/classifications");
  }
}

export class Queue<T> {
  // Array explicitly denoted as mutable by its type
  private items: T[] = [];

  /**
   * Add an item to the queue.
   */
  enqueue(item: T): void {
    this.items.push(item); // Mutates the array
  }

  /**
   * Remove and return an item from the front of the queue.
   */
  takeOne(): T | undefined {
    return this.items.shift(); // Mutates the array
  }

  takeAll(): T[] {
    return this.items.splice(0, this.items.length);
  }

  /**
   * Check if the queue is empty.
   */
  isEmpty(): boolean {
    return this.items.length === 0;
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { MacroNumbersDetails } from "../models/enum/macroNumber.enum";
import { DecimalPipe } from "@angular/common";

@Pipe({
  name: "macroNumber",
})
export class MacroNumberPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number, symbol?: string): string {
    if (!value) {
      return "";
    }

    symbol = Intl.NumberFormat("en", { style: "currency", currency: symbol })
      .formatToParts()
      .find((part) => part.type === "currency").value;
    return this.formatAmount(value, symbol);
  }

  formatAmount(amount: number, symbol: string): string {
    const details = MacroNumbersDetails.find((details) => Math.abs(amount) >= details.amount);

    if (details) {
      return this.format(symbol, amount, details);
    }

    return `${symbol}${this.decimalPipe.transform(amount, "1.0-0")}`;
  }

  format(
    symbol: string,
    amount: number,
    macroNumberDetails: { symbol: string; amount: number },
  ): string {
    const { symbol: baseSymbol, amount: baseAmount } = macroNumberDetails;
    const sign = amount < 0 ? "-" : "";
    const absoluteAmount = Math.abs(amount);
    const hasExactAmount = absoluteAmount % baseAmount === 0;
    const roundedAmount = Math.floor((absoluteAmount / baseAmount) * 100) / 100; // prevent rounding off coz 9955 becomes 9.96
    const digitsInfo = hasExactAmount ? "1.0-0" : "1.2-2";
    const formattedAmount = this.decimalPipe.transform(roundedAmount, digitsInfo);
    return `${symbol}${sign}${formattedAmount}${baseSymbol}`;
  }
}

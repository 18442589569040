import { TransactionView } from "../../../../models/view/transaction/transaction.view";
import {
  GeneratedCollection,
  GeneratedTransactionParams,
} from "../../generated.collection.abstraction";
import { TransactionBalancesWorkerResult } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.result";
import { BalanceAlignmentWorkerResult } from "@bitwarden/web-vault/app/services/web-worker/balance-alignment/balance-alignment.worker.result";
import { BalanceAlignmentWorkerMessage } from "@bitwarden/web-vault/app/services/web-worker/balance-alignment/balance-alignment.worker.message";
import { NormalizeTransaction } from "@bitwarden/web-vault/app/models/view/transaction/normalize/normalizeTransaction.utils";
import { Allocation } from "@bitwarden/web-vault/app/models/data/allocation.data";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { inject, signal, Signal, WritableSignal } from "@angular/core";
import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { Observable } from "rxjs";
import { getTransactionViewOf } from "@bitwarden/web-vault/app/models/view/transaction/transaction.utils";
import { toObservable } from "@angular/core/rxjs-interop";

export class BalanceAlignmentTransactionsGeneratedCollection extends GeneratedCollection<TransactionView> {
  protected log: LogService = inject(LogService);
  protected transactionStoreService: TransactionStoreService = inject(TransactionStoreService);
  protected userStoreService: UserStoreService = inject(UserStoreService);

  private _updating = false;
  protected generatedTransactions: TransactionView[] = [];
  protected updatedTransactions: Record<string, number>;

  // todo @michelle should clean that up I just add the require prop for the moment.
  protected _collection: WritableSignal<TransactionView[]> = signal<TransactionView[]>(null);
  collection: Signal<TransactionView[]> = this._collection.asReadonly();
  collection$: Observable<TransactionView[]> = toObservable(this.collection);

  update(transactions: TransactionView[]): void {
    this.generatedTransactions = transactions;
  }

  clear(): void {
    this.generatedTransactions = [];
    this.updatedTransactions = {};
    this._updating = false;
    this._collection.set(null);
  }

  async generateTransactions(parameters: GeneratedTransactionParams): Promise<void> {
    this._updating = true;
    this.generatedTransactions = [];
    this.updatedTransactions = {};

    const { webWorkerQueue, transactions } = parameters;

    const balanceAlignmentRequest = new BalanceAlignmentWorkerMessage(
      "realignTransactionBalances",
      transactions,
    );

    // run the balance alignment webworker on these transactions
    await webWorkerQueue.postMessagePromise(balanceAlignmentRequest).then(
      (workerMessage: BalanceAlignmentWorkerResult) => {
        this.generatedTransactions = [];
        this.updatedTransactions = {};

        if (workerMessage?.generatedTransactions) {
          const preference = this.userStoreService.preferences.preferenceView();
          const referenceData = this.transactionStoreService.referenceData.referenceDataViews();

          const normalizeTransaction = new NormalizeTransaction(
            this.log,
            preference.baseCurrency,
            referenceData,
          );

          // set the generated transactions
          for (const transactionObj of workerMessage.generatedTransactions) {
            const transaction = getTransactionViewOf(transactionObj);
            Allocation.setAllocation(transaction);
            normalizeTransaction.normalizeImportedTransaction(transaction);
            this.generatedTransactions.push(transaction);
          }
        }
        if (workerMessage?.updatedTransactions) {
          // set the updated transactions
          this.updatedTransactions = workerMessage.updatedTransactions;
        }
        this._updating = false;
        return Promise.resolve(workerMessage);
      },
      (error: Error) => {
        // handle the error
        this._updating = false;
        return Promise.reject(error);
      },
    );
  }

  applyBalanceAlignment(transactions: TransactionView[]): TransactionView[] {
    let updatedTransactions = transactions;
    if (this.generatedTransactions.length > 0) {
      updatedTransactions = transactions.concat(this.generatedTransactions);
    }
    return updatedTransactions;
  }
}

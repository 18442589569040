import { inject, Injectable } from "@angular/core";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import {
  BalanceCalculatedAdapterType,
  CalculatedBalanceParams,
  CalculatedStoreAdapterInterface,
} from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";
import { BalanceByAccountsCalculatedCollection } from "@bitwarden/web-vault/app/services/store/calculation/balances/balance-by-accounts.calculated.collection";
import { BalanceByTimeCalculatedCollection } from "@bitwarden/web-vault/app/services/store/calculation/balances/balance-by-time.calculated.collection";
import { BalanceByTimeAndAccountsCalculatedCollection } from "@bitwarden/web-vault/app/services/store/calculation/balances/balance-by-time-and-accounts.calculated.collection";
import { BalanceByTransactionsCalculatedCollection } from "@bitwarden/web-vault/app/services/store/calculation/balances/balance-by-transactions.calculated.collection";
import { RevaluationTransactionsGeneratedCollection } from "@bitwarden/web-vault/app/services/store/calculation/generated-transactions/revaluation-transactions.generated.collection";
import {
  GeneratedAdapterInterface,
  GeneratedTransactionParams,
  RevaluationAdapterType,
} from "@bitwarden/web-vault/app/services/store/generated.collection.abstraction";
import {
  FilterCollectionType,
  GroupingCollectionType,
  PeriodCollectionType,
  StateControlCollection,
} from "@bitwarden/web-vault/app/services/store/state-control.abstraction";

import { FilterControl } from "@bitwarden/web-vault/app/services/store/calculation/controls/filter.control";
import { GroupControl } from "@bitwarden/web-vault/app/services/store/calculation/controls/group.control";
import { PeriodController } from "@bitwarden/web-vault/app/services/store/calculation/controls/period.control";
import { CalculateTransactionGeneratedCollection } from "@bitwarden/web-vault/app/services/store/calculation/calculate-transaction.generated.collection";
import {
  BehaviorSubject,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  skipUntil,
  Subscription,
} from "rxjs";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { WebWorkerQueue } from "../../web-worker/WebWorkerQueue";
import { BalanceAlignmentTransactionsGeneratedCollection } from "@bitwarden/web-vault/app/services/store/calculation/generated-transactions/balance-alignment-transactions.generated.collection";
import { FilteredTransactionGeneratedCollection } from "@bitwarden/web-vault/app/services/store/calculation/filtered-transaction.generated.collection";
import { CalculatedStoreAbstraction } from "@bitwarden/web-vault/app/services/store/calculated.store.abstraction";
import { FilteredAccountGeneratedCollection } from "@bitwarden/web-vault/app/services/store/calculation/filtered-account.generated.collection";

import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";

import { TransactionStoreService } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.service";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";
import { BalanceByAccountsAndSymbolsCalculatedCollection } from "@bitwarden/web-vault/app/services/store/calculation/balances/balance-by-accounts-and-symbol.calculated.collection";

interface CalculatedStoreState {
  status: "initialise" | "idle" | "updatingControl" | "updatingCalculation" | "completed" | "error";
}

interface CalculatedStorePage {
  isDirtyFilteringOptions: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CalculationStoreService
  extends CalculatedStoreAbstraction
  implements
    CalculatedStoreAdapterInterface<BalanceCalculatedAdapterType>,
    GeneratedAdapterInterface<RevaluationAdapterType>,
    StateControlCollection<FilterCollectionType>,
    StateControlCollection<GroupingCollectionType>,
    StateControlCollection<PeriodCollectionType>
{
  /** Injected Services **/
  protected log: LogService = inject(LogService);
  protected appState: AppStateStoreService = inject(AppStateStoreService);
  protected storeState$: BehaviorSubject<CalculatedStoreState> = new BehaviorSubject({
    status: "idle",
  });
  protected userStore: UserStoreService = inject(UserStoreService);
  protected transactionStoreService: TransactionStoreService = inject(TransactionStoreService);

  webWorkerQueue = new WebWorkerQueue();

  /** Define the state control ,for component to use and update **/
  period = new PeriodController();
  filters = new FilterControl();
  groupings = new GroupControl();

  /** This contains all the transaction with the filter applied to be used for balance calculation **/
  calculateTransaction = new CalculateTransactionGeneratedCollection();

  /** This contains all the transaction with the filters and period applied on the collection. to be use for displaying the list **/
  filteredTransactions = new FilteredTransactionGeneratedCollection();
  filteredAccounts = new FilteredAccountGeneratedCollection();

  /** Balance Collection **/
  balanceByAccount = new BalanceByAccountsCalculatedCollection();
  balanceByTime = new BalanceByTimeCalculatedCollection();
  balanceByAccountSymbols = new BalanceByAccountsAndSymbolsCalculatedCollection();
  balanceByTimeAndAccounts = new BalanceByTimeAndAccountsCalculatedCollection();
  balanceByTransaction = new BalanceByTransactionsCalculatedCollection();

  /** Generated Transaction Collection **/
  balanceAlignmentTransactions = new BalanceAlignmentTransactionsGeneratedCollection();
  revaluationTransactions = new RevaluationTransactionsGeneratedCollection();

  /** Trigger subscriptions **/
  private subscriptions: Subscription[] = [];

  clearStore(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());

    /** Generated Transaction Collection **/
    this.balanceAlignmentTransactions.clear();
    this.revaluationTransactions.clear();

    /** Balance Collection **/
    this.balanceByAccount.clear();
    this.balanceByTime.clear();
    this.balanceByAccountSymbols.clear();
    this.balanceByTimeAndAccounts.clear();
    this.balanceByTransaction.clear();

    this.filteredAccounts.clear();
    this.filteredTransactions.clear();
    this.calculateTransaction.clear();
  }

  initialize(): void {
    this.log.info("Initializing CalculationStoreService");
    this.initialiseCollectionListener();
  }

  private onEnrichCollectionChange(): void {
    this.log.info("Change Detected **** Regenerating collection list ****");

    this.refreshState({
      isDirtyFilteringOptions: true,
    });
  }

  private onFilterChange(): void {
    this.log.info("Change Detected **** Filters ****");
    this.refreshState({
      isDirtyFilteringOptions: false,
    });
  }

  private onPeriodChange(): void {
    this.log.info("Change Detected **** Period ****");
    this.refreshState({
      isDirtyFilteringOptions: false,
    });
  }

  private onGroupingChange(): void {
    this.log.info("Change Detected **** Grouping ****");
    this.refreshState({
      isDirtyFilteringOptions: false,
    });
  }

  private refreshState(page: CalculatedStorePage): void {
    if (this.storeState$.value.status === "idle") {
      this.storeState$.next({ status: "updatingControl" });
      this.log.info("Starting control update");

      const enrichCollection = this.calculateTransaction.dependentStore.getCollection();

      /** Reset the filtering if the transaction list change **/
      if (page.isDirtyFilteringOptions) {
        this.log.info("--- initialiseFilteringOptions from enrich collection");
        this.initialiseFilteringOptions(enrichCollection);
      }

      this.log.info("--- Applying current filter to the enrich collection ");
      const filteredTransactionForCalculation =
        this.filters.applyFiltersOnTransaction(enrichCollection);
      this.calculateTransaction.update(filteredTransactionForCalculation);

      /** Refreshing the filtered Account List **/
      this.filteredAccounts.update(
        this.filters.applyFiltersOnAccounts(this.userStore.accounts.accountViews()),
      );

      this.log.info("--- update Period option and selection ");
      this.period.updateOptionAndSelectionFromTransactions(filteredTransactionForCalculation);

      this.log.info("--- update grouping option and selection");
      this.groupings.updateOptionAndSelectionFromPeriod(this.period.selected());

      this.storeState$.next({ status: "updatingCalculation" });
    }
  }

  private initialiseCollectionListener(): void {
    this.subscriptions.push(
      this.storeState$
        .pipe(
          distinctUntilKeyChanged("status"),
          filter((x) => x.status === "updatingCalculation"),
        )
        .subscribe(this.updateCalculation.bind(this)),
    );

    const enrichCollection$ = this.calculateTransaction.dependentStore.getObservable().pipe(
      filter((x) => !!x),
      distinctUntilChanged((previous, current) => {
        return previous === current || (previous.length === 0 && current.length === 0);
      }),
    );

    this.subscriptions.push(enrichCollection$.subscribe(this.onEnrichCollectionChange.bind(this)));

    this.subscriptions.push(
      this.filters.selected$
        .pipe(
          filter((x) => !!x),
          distinctUntilChanged((previous, current) => {
            return JSON.stringify(previous) === JSON.stringify(current);
          }),
          skipUntil(enrichCollection$),
        )
        .subscribe(this.onFilterChange.bind(this)),
    );

    this.subscriptions.push(
      this.period.selected$
        .pipe(
          filter((x) => !!x),
          distinctUntilChanged((previous, current) => {
            return JSON.stringify(previous) === JSON.stringify(current);
          }),
          skipUntil(enrichCollection$),
          skipUntil(this.filters.selected$),
        )
        .subscribe(this.onPeriodChange.bind(this)),
    );

    this.subscriptions.push(
      this.groupings.selected$
        .pipe(
          filter((x) => !!x),
          distinctUntilChanged((previous, current) => {
            return JSON.stringify(previous) === JSON.stringify(current);
          }),
          skipUntil(enrichCollection$),
          skipUntil(this.period.selected$),
        )
        .subscribe(this.onGroupingChange.bind(this)),
    );
  }

  private initialiseFilteringOptions(transactions: TransactionView[]) {
    this.filters.initialiseOptions(transactions);
    this.filters.updateSelection(this.filters.options());
  }

  private async updateCalculation() {
    this.appState.startProcess("balance-calculation", "Calculating Balances...");
    this.realignBalanceTransactions()
      .then(() => {
        //TODO: @mmeshel - need to make sure that the calculatedGeneratedTransactions is a deep copy of the array and that
        // we don't update the original transactionView array
        let calculatedGeneratedTransactions = this.applyBalanceAlignmentUpdates(
          this.calculateTransaction.collection(),
        );

        /** Calculate Reval **/
        this.appState.startProcess("revaluation", "Calculating Fluctuation...");
        this.revaluateTransactions(calculatedGeneratedTransactions).then(() => {
          this.appState.endProcess("revaluation");

          if (this.revaluationTransactions.collection()?.length > 0) {
            calculatedGeneratedTransactions = calculatedGeneratedTransactions.concat(
              this.revaluationTransactions.collection(),
            );
          }

          this.log.info("Applying Period");

          this.filteredTransactions.update(
            this.period.filterOnPeriod(calculatedGeneratedTransactions),
          );

          this.log.info(`Balance Calculation`);
          this.updateBalanceCalculations(calculatedGeneratedTransactions).then(() => {
            this.log.info("balances have all been recalculated");
          });
        });
        this.storeState$.next({ status: "completed" });
      })
      .catch((error) => {
        this.log.error(error);
        this.storeState$.next({ status: "error" });
      })
      .finally(() => {
        this.storeState$.next({ status: "idle" });
        this.appState.endProcess("balance-calculation");
      });
  }

  /**
   * This function is used to recalculate the balances for each balance collection in
   * the calculated store.
   */
  async updateBalanceCalculations(
    calculatedGeneratedTransactions: TransactionView[],
  ): Promise<void> {
    const parameters: CalculatedBalanceParams = {
      calculatedTransactions: calculatedGeneratedTransactions,
      webWorkerQueue: this.webWorkerQueue,
      filter: this.filters.selected(),
      group: this.groupings.selected(),
      period: this.period.selected(),
    };

    const promises: Promise<void>[] = [];

    promises.push(this.balanceByAccount.updateCalculations(parameters));
    promises.push(this.balanceByTime.updateCalculations(parameters));
    promises.push(this.balanceByAccountSymbols.updateCalculations(parameters));
    promises.push(this.balanceByTimeAndAccounts.updateCalculations(parameters));
    promises.push(this.balanceByTransaction.updateCalculations(parameters));

    await Promise.all(promises);
    return;
  }

  async realignBalanceTransactions(): Promise<void> {
    const transactions = this.calculateTransaction.collection();
    if (transactions?.length === 0) {
      return;
    }

    const parameters: GeneratedTransactionParams = {
      transactions: transactions,
      webWorkerQueue: this.webWorkerQueue,
    };
    await this.balanceAlignmentTransactions.generateTransactions(parameters);
    return;
  }

  applyBalanceAlignmentUpdates(transactions: TransactionView[]): TransactionView[] {
    return this.balanceAlignmentTransactions.applyBalanceAlignment(transactions);
  }

  async revaluateTransactions(transactions: TransactionView[]): Promise<void> {
    const revalMetaData = {
      transactionsToReval: transactions,
      referenceData: this.transactionStoreService.referenceData.referenceDataViews(),
      baseCurrency: this.userStore.preferences.preferenceView().baseCurrency,
      symbolsInSystem: this.transactionStoreService.symbols
        .collection()
        .map((symbol) => symbol.code),
      tillDate: this.period.selected().endDate,
    };

    await this.revaluationTransactions.setRevals(revalMetaData);
  }
}

import { ChangeDetectionStrategy, Component, computed, inject, OnInit } from "@angular/core";

import {
  DeviceDetection,
  DeviceDetector,
} from "@bitwarden/web-vault/app/shared/utils/device-detector";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";

@Component({
  selector: "app-user-layout",
  templateUrl: "user-layout.component.html",
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLayoutComponent implements OnInit {
  protected userStore = inject(UserStoreService);
  protected appState = inject(AppStateStoreService);

  deviceDetector: DeviceDetection;
  menuHeight: number = 0;

  constructor() {
    this.deviceDetector = new DeviceDetector();
  }

  // todo check to remove
  async ngOnInit() {
    document.body.classList.remove("layout_frontend");
  }

  protected showWizard = computed(() => {
    /** IF system is loaded **/
    if (!this.appState.state().isStoreInitialised) {
      return false;
    }

    /** If wizard is in progress ( State is control by the wizard component  **/
    if (this.appState.wizard().isVisible) {
      return true;
    }

    const accounts = this.userStore.accounts.accountViews();
    const accountWizard = this.userStore.wizards
      .wizardViews()
      ?.find((wizard) => wizard.type === "account");

    /** If we are resuming the wizard form the vault. **/
    /** Check if the account wizard last step is completed. **/
    if (accountWizard) {
      return !(accountWizard.step.stepIndex === 3 && accountWizard.step.isComplete === true);
    } else {
      /** Wizard never been saved. Check for if there is accounts. **/
      if (accounts?.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  });

  openHelpBox() {
    this.appState.helpBox.set({ isVisible: true });
  }

  closeHelpBox() {
    this.appState.helpBox.set({ isVisible: false });
  }

  updateMenuHeight(height: number) {
    this.menuHeight = height;
  }
}

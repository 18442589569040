import { Component, computed, inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ColDef } from "ag-grid-community";
import { GridApi, MenuItemDef } from "ag-grid-enterprise";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subject } from "rxjs";

import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { CabinetFileUploadComponent } from "@bitwarden/web-vault/app/gloss/cabinet/cabinet-file-upload/cabinet-file-upload.component";
import { columnDefinition } from "@bitwarden/web-vault/app/gloss/cabinet/column.definition";
import { VaultFile } from "@bitwarden/web-vault/app/models/data/blobby/vault-file.data";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { CabinetFileUploadedData } from "@bitwarden/web-vault/app/models/types/cabinet.types";
import { AgGridSizeStrategy } from "@bitwarden/web-vault/app/models/types/general-types";
import { VaultFileView } from "@bitwarden/web-vault/app/models/view/vault-file/vault-file.view";
import { CabinetService } from "@bitwarden/web-vault/app/services/DataService/vault-file/cabinet.service";
import { CabinetContextMenuService } from "@bitwarden/web-vault/app/services/cabinet/cabinet-context-menu.service";
import { ICabinetContextMenu } from "@bitwarden/web-vault/app/services/cabinet/context-menu.interface";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";
import "./cabinet.style.scss";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";

@Component({
  selector: "app-cabinet",
  templateUrl: "./cabinet.component.html",
  styles: ["cabient.component.scss"],
})
export class CabinetComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private userStore: UserStoreService = inject(UserStoreService);

  isDeleting = false;
  gridApi: GridApi;
  isMobile = false;
  cabinetFileUploadRef: MatDialogRef<CabinetFileUploadComponent>;
  vaultFiles: VaultFile[] = [];
  autoSizeStrategy: AgGridSizeStrategy = this.isMobileDevice()
    ? { type: "fitCellContents" }
    : { type: "fitGridWidth" };
  colDefs: ColDef[] = columnDefinition;
  uploadNewFileOptions: ActionButton;

  constructor(
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private cabinetComponentService: CabinetService,
    private contextMenuService: CabinetContextMenuService,
    private confirmationDialogService: ConfirmationDialogService,
  ) {
    this.uploadNewFileOptions = new ActionButton({
      text: "Upload New File",
      icon: "add",
      class: "neutral",
      onClick: this.uploadNewFile.bind(this),
    });
  }

  protected vaultFileGrid = computed(() => {
    const vaultFileViews = this.userStore.vaultFiles.vaultFileViews();
    const accountViews = this.userStore.accounts.accountViews();
    const institutionViews = this.userStore.institutions.institutionViews();
    const editVaultFileViews: VaultFileView[] = [];
    vaultFileViews.forEach((vaultFileView) => {
      const filteredAccountViews = accountViews.filter((accountView) =>
        vaultFileView.accountIds.includes(accountView.id),
      );
      const filterInstitutionViews: InstitutionView[] = [];
      filteredAccountViews.forEach((accountView) =>
        filterInstitutionViews.push(this.findInstitutionByAccount(institutionViews, accountView)),
      );
      const newVaultFileView = vaultFileView.clone();
      //add the banks from the dali data (which vaulFile data model didn't save this (too big))
      //same as statementAccounts
      newVaultFileView.banks = filterInstitutionViews;
      newVaultFileView.statementAccounts = filteredAccountViews;
      editVaultFileViews.push(newVaultFileView);
    });
    return editVaultFileViews;
  });

  findInstitutionByAccount(institutionViews: InstitutionView[], accountView: AccountView) {
    return institutionViews.find(
      (institutionView) => institutionView.id === accountView.institutionLink.institutionId,
    );
  }

  async ngOnInit(): Promise<void> {
    this.isMobile = this.isMobileDevice();
  }

  async onCellClicked(event: any) {
    event.node.setSelected(false);
    if (this.isMobile) {
      const params = {
        x: event.event.clientX,
        y: event.event.clientY,
        rowNode: event.node,
        column: event.column,
        value: event.value,
      };
      this.gridApi.showContextMenu(params);
    }
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    params.api.applyColumnState({
      state: [{ colId: "name", sort: "asc" }],
    });
    this.autoSizeStrategy = this.isMobile ? { type: "fitCellContents" } : { type: "fitGridWidth" };
  }

  /**
   * Generates context menu items, aka right-click buttons for the cabinet.
   * @param params - Parameters for the context menu comes from ag-grid.
   * @returns An array of context menu items.
   */
  getContextMenuItems(params: any): (string | MenuItemDef)[] {
    const contextMenuParams: ICabinetContextMenu = {
      params,
      downloadFile: this.downloadFile.bind(this),
      editFile: this.editFile.bind(this),
      deleteFile: this.deleteFile.bind(this),
    };
    return this.contextMenuService.getContextMenuItems(contextMenuParams);
  }

  /**
   * Downloads a file from the cabinet.
   * @param vaultFileView - The view model of the vault file to be downloaded.
   */
  async downloadFile(vaultFileView: VaultFileView) {
    const { id: fileId } = vaultFileView;
    await this.cabinetComponentService.downloadFile(fileId);
  }

  // TODO - Implement edit file once Horace provide the UI for form
  editFile(vaultFileView: VaultFileView) {
    // eslint-disable-next-line no-console
    console.log("Edit file", vaultFileView);
  }

  /**
   * Deletes a file from the cabinet after user confirmation.
   *
   * @param {VaultFileView} vaultFileView - The view model of the vault file to be deleted.
   */
  async deleteFile(vaultFileView: VaultFileView) {
    try {
      const confirm = await this.confirmationDialogService.confirmFor(
        ConfirmationEnum.deletingVaultFile,
      );
      if (!confirm) {
        return;
      }

      this.isDeleting = true;
      await this.cabinetComponentService.deleteFile(vaultFileView);
    } catch (e) {
      this.cabinetComponentService.displayCatchError(e);
    } finally {
      this.isDeleting = false;
    }
  }

  isMobileDevice(): boolean {
    return this.deviceService.isMobile();
  }

  uploadNewFile() {
    this.cabinetFileUploadRef = this.dialog.open(CabinetFileUploadComponent, {
      width: "unset",
      minWidth: "800px",
      panelClass: "no-background-dialog",
      data: {
        closeForm: this.closeForm.bind(this),
      },
      disableClose: true,
    });
  }

  async closeForm(data: boolean) {
    this.cabinetFileUploadRef.close(data);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

<div class="content">
  <div
    class="tw-flex tw-w-fit tw-items-center tw-justify-center tw-rounded-full tw-bg-white sm:tw-w-full sm:tw-bg-transparent tw-mb-2"
  >
    @for (button of timeButtons; track button) {
      <button
        class="tw-rounded-full tw-px-16 tw-py-3 tw-text-xl tw-font-medium sm:tw-px-8 sm:tw-py-1 sm:tw-text-lg tw-capitalize
        {{ button.value === viewsService.view && button.color }}"
        (click)="toggleTimeButtons(button.value)"
      >
        {{ button.value }}
      </button>
    }
  </div>

  <div class="tw-shadow-form tw-rounded-xl tw-gap-3 tw-flex tw-flex-col xs:tw-p-0">
    <ng-container>
      <app-views-control [currentView]="viewsService.view"></app-views-control>
    </ng-container>
    <div class="tw-flex tw-w-full tw-gap-4 sm:tw-flex-col">
      <div class="tw-flex tw-w-full tw-flex-col tw-gap-4">
        @switch (viewsService.view) {
          @case (ViewEnum.TODAY) {
            <app-current-balance></app-current-balance>
          }
        }
        <app-group-by-chart-control [currentView]="viewsService.view"></app-group-by-chart-control>
      </div>
      <div class="tw-w-full">
        @switch (viewsService.view) {
          @case (ViewEnum.PAST) {
            <app-widget [title]="'transactions' | i18n" [iconName]="'transactions'">
              <!--                <div>-->
              <!--                  <span class="tw-text-sm tw-text-neutral-300 tw-font-medium tw-uppercase"-->
              <!--                    >In and Out Comparison</span-->
              <!--                  >-->
              <!--                  <div class="tw-flex tw-w-full">-->
              <!--                    <div-->
              <!--                      class="tw-bg-gradient-to-r tw-from-success to-white tw-flex tw-items-center tw-px-4 tw-w-full tw-h-10 tw-rounded-full tw-text-success"-->
              <!--                    >-->
              <!--                      In $2,200.00-->
              <!--                    </div>-->
              <!--                    <div-->
              <!--                      class="tw-bg-gradient-to-r tw-from-danger to-white tw-flex tw-items-center tw-px-4 tw-w-full tw-h-10 tw-rounded-full tw-text-danger"-->
              <!--                    >-->
              <!--                      Out -$1,200.000-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <app-ag-grid-table
                [options]="pastTransactionTable?.options"
                [data]="pastTransactionTable?.data"
              />
            </app-widget>
          }
          @case (ViewEnum.TODAY) {
            <app-widget [title]="'accounts' | i18n" [iconName]="'wallet'">
              <app-ag-grid-table
                [options]="accountsViewTable?.options"
                [data]="accountsViewTable?.data"
              />
            </app-widget>
          }
          @case (ViewEnum.FUTURE) {
            <app-widget [title]="'transactions' | i18n" [iconName]="'transactions'">
              <app-tabs
                [options]="getTabOptions()"
                [defaultTabSelected]="viewsService.scenarioType"
                (onChange)="setScenarioPath($event)"
              ></app-tabs>
              <app-ag-grid-table
                [options]="scenarioTransactionTable?.options"
                [data]="scenarioTransactionTable?.data"
              />
            </app-widget>
            <div class="tw-flex tw-justify-end tw-gap-2">
              <app-gloss-button [options]="submitButtonOptions"></app-gloss-button>
            </div>
          }
        }
      </div>
    </div>
  </div>
</div>

import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { SourceBook } from "@bitwarden/web-vault/app/models/data/blobby/source-book";
import { NewAccountActionTypes } from "@bitwarden/web-vault/app/models/enum/account.enum";
import {
  accountManualType,
  UnrecognizedAccount,
} from "@bitwarden/web-vault/app/models/types/account.types";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { DataServiceAbstraction } from "../data.service.abstraction";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { toObservable } from "@angular/core/rxjs-interop";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { AccountStoreModel } from "@bitwarden/web-vault/app/models/store/account.store.model";
import { GlossEncryptedDataType } from "@bitwarden/web-vault/app/models/enum/glossEncryptedDataType";

@Injectable({
  providedIn: "root",
})
export class BookService implements DataServiceAbstraction {
  protected _accounts = new BehaviorSubject<Book[]>([]);
  private logService: LogService;

  protected userStore = inject(UserStoreService);

  private _accountViews: AccountView[] = [];
  private _accountViews$: Observable<AccountView[]> = toObservable(
    this.userStore.accounts.accountViews,
  );

  // TODO: not currently using this observable, but as we returned the book-renderer response from blobby
  // leaving it here in case we do want to use observables to trigger the refresh somewhere else
  accounts$ = this._accounts.asObservable();

  constructor(
    private dataRepositoryService: DataRepositoryService,
    private institutionService: InstitutionService,
  ) {
    this._accountViews$.subscribe((accountViews) => {
      this._accountViews = accountViews;
    });
  }

  /**
   * @deprecated
   */
  async getAll(): Promise<Book[]> {
    return await this.dataRepositoryService.getAllBooks();
  }

  /**
   * @deprecated
   */
  async getBooks(triggerObservable = false): Promise<Book[]> {
    try {
      const books = await this.dataRepositoryService.getAllBooks();
      if (triggerObservable) {
        await this.updateObservables();
      }
      return books;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async getAllInstitutionBooks(institution: Institution): Promise<Book[]> {
    try {
      const books = await this.dataRepositoryService.getAllBooks();
      return books.filter((book) => {
        if (book.institutionLink && book.institutionLink.institutionId) {
          return book.institutionLink.institutionId === institution.id;
        }
      });
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async getAllSourceBooks(): Promise<SourceBook[]> {
    try {
      return await this.dataRepositoryService.getAllSourceBooks();
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async createSourceBooks(newSourceBooks: UnrecognizedAccount[]) {
    const createdSourceBookArray: SourceBook[] = [];
    for (const newSourceBook of newSourceBooks) {
      if (newSourceBook.accountId) {
        /** The account is linked to an existing one */
        if (newSourceBook.action === NewAccountActionTypes.link) {
          const sourceBook = new SourceBook(newSourceBook);
          const createdSourceBook = await this.dataRepositoryService.createSourceBook(sourceBook);
          createdSourceBookArray.push(createdSourceBook);
        }

        /** The account is renamed ... this means a new account is created with this rename value and these are linked */
        if (newSourceBook.action === NewAccountActionTypes.rename) {
          const book = await this.dataRepositoryService.getBookByName(newSourceBook.accountId);
          newSourceBook.accountId = book.id;
          const sourceBook = new SourceBook(newSourceBook);
          const createdSourceBook = await this.dataRepositoryService.createSourceBook(sourceBook);
          createdSourceBookArray.push(createdSourceBook);
        }

        /** The account does not exist, so it is created and linked to itself*/
        if (newSourceBook.action === NewAccountActionTypes.default) {
          const book = await this.dataRepositoryService.getBookByName(newSourceBook.accountId);
          newSourceBook.accountId = book.id;
          const sourceBook = new SourceBook(newSourceBook);
          const createdSourceBook = await this.dataRepositoryService.createSourceBook(sourceBook);
          createdSourceBookArray.push(createdSourceBook);
        }
      }
    }

    return createdSourceBookArray;
  }

  /**
   * @deprecated
   */
  async update(book: Book, triggerObservable = false): Promise<Book> {
    try {
      const updatedBook = await this.dataRepositoryService.updateBook(book);
      if (triggerObservable) {
        // todo need to change how we process the Observables. I dont think it should be in the service. Maybe it should
        await this.updateObservables();
      }
      return updatedBook;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async removeInstitutionsFromAccounts(institution: Institution) {
    const accounts = await this.getAllInstitutionBooks(institution);
    for (const account of accounts) {
      account.institutionLink = null;
      await this.update(account);
    }
  }

  async get(id: string): Promise<Book> {
    // get the book-renderer from Blobby service
    // TODO: rework this function when we can get accounts by ID in blobby
    // They need to exist as separate objects in blobby filterable by ID
    const accounts = await this.getBooks();

    for (const account of accounts) {
      if (account.id === id) {
        return account;
      }
    }
    return;
  }

  async getByName(name: string): Promise<Book> {
    const accounts = await this.getBooks();
    return accounts.find((account) => account.name === name);
  }

  /**
   * @deprecated
   */
  async getTransactionsByAccount(id: string) {
    return await this.dataRepositoryService.getAllTransactionsByAccount(id);
  }

  /**
   * @deprecated
   */
  async create(book: Book): Promise<Book> {
    try {
      return await this.dataRepositoryService.createBook(book);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  private async updateObservables() {
    const allBooks = await this.getAll();
    this._accounts.next(allBooks);
  }

  async isThereBook(accountName: string): Promise<boolean> {
    const allBooks = await this.getAll();
    return allBooks.some((book) => book.name === accountName);
  }

  async getBookLink(accountName: string): Promise<SourceBook> {
    const allBooks = await this.getAllSourceBooks();
    return allBooks.find((sourceBook) => sourceBook.source === accountName);
  }

  /**
   * @deprecated
   */
  async deleteBookLink(sourceBook: SourceBook) {
    await this.dataRepositoryService.deleteSourceBook(sourceBook);
  }

  /**
   * @deprecated
   */
  getBooksView(): AccountView[] {
    const accountViews = this.getAccountViews();

    /*const institutions = await this.dataRepositoryService.getAllInstitutions();*/
    const institutions = this.userStore.institutions.institutionViews();
    // return books.map((book) => {
    //   const institution = institutions.find(
    //     (institution) => institution.id === book.institutionLink?.institutionId,
    //   );
    //   return new AccountView().legacyConstructor(book, institution);
    // });
    return accountViews;
  }

  /** @Sinan this is a placeholder the implementation is in account.delete.service*/
  delete(item: GlossEncryptedDataType, triggerObservable: boolean): Promise<boolean> {
    return;
  }

  toAccountStoreModel(account: AccountView, institution: InstitutionView): AccountStoreModel {
    return {
      bal: null,
      dc: new Date().toISOString(),
      dm: new Date().toISOString(),
      id: crypto.randomUUID(),
      v: 2,
      vid: "",
      crLm: Number(account.creditLimit),
      instLnk: {
        institutionId: institution?.id,
        institutionAccountId: account.institutionLink.institutionAccountId,
      },
      curr: account.currency,
      name: account.name,
      tz: "Australia/Sydney",
      dfltCla: [],
      dfltCat: [],
      ori: Origin.manual,
      bsqId: account.id ?? "",
      date: new Date().toISOString(),
      type: account.type ?? accountManualType.bank,
      st: [],
    };
  }

  getAccountViews(): AccountView[] {
    return this._accountViews;
  }

  getSyncedAccounts(): AccountView[] {
    const booksView = this.userStore.accounts.accountViews();

    return booksView.filter((book) => book.origin !== Origin.manual);
  }

  async getBooksViewWithMockAccounts(mockAccounts: Book[]): Promise<AccountView[]> {
    const actualBookViews: AccountView[] = await this.getBooksView();
    const mockBookViews: AccountView[] = await this.getMockAccountViews(mockAccounts);

    return actualBookViews.concat(mockBookViews);
  }

  async getMockAccountViews(mockAccounts: Book[]): Promise<AccountView[]> {
    const institutions = await this.institutionService.getInstitutionsMasterList();
    const mockAccountViews: AccountView[] = [];
    for (const mockAccount of mockAccounts) {
      const institution = institutions.find(
        (institution) => institution.id === mockAccount.institutionLink.institutionId,
      );
      const mockView = new AccountView().legacyConstructor(mockAccount, institution);
      mockAccountViews.push(mockView);
    }
    return mockAccountViews;
  }
}

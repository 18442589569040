import {
  CalculatedBalances,
  CalculatedBalanceCollection,
  CalculatedBalanceParams,
} from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";
import { signal } from "@angular/core";

export class BalanceByTransactionsCalculatedCollection extends CalculatedBalanceCollection {
  protected type: "BalanceByTransactions";
  protected calculations: CalculatedBalances = new Map<string, number>();

  protected _balances = signal<Map<string, number>>(new Map());
  readonly balances = this._balances.asReadonly();

  async updateCalculations(parameters: CalculatedBalanceParams): Promise<void> {}

  getCalculations(): CalculatedBalances {
    return this.calculations;
  }

  clear() {
    this.calculations.clear();
    this._balances.set(null);
  }

  triggerChanges(): void {}
}

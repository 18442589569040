import { inject, Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { subDays } from "date-fns";
import { BehaviorSubject, filter, Observable, take } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { BaseImporter } from "@bitwarden/common/importers/base-importer";
import { ImportTransactionResult } from "@bitwarden/common/models/domain/import-transaction-result";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { BasiqIoTransactionType } from "@bitwarden/web-vault/app/models/types/basiq-io-transaction.types";
import {
  BasiqAccountType,
  BasiqInstitution,
  BasiqJob,
  GlossConnectionResponse,
  GlossConnectionsResponse,
} from "@bitwarden/web-vault/app/models/types/basiq.types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { BasiqTransactionProcessingService } from "@bitwarden/web-vault/app/services/api/basiq/transaction.processing.service";
import { BasiqIoConfigService } from "@bitwarden/web-vault/app/services/api/basiqio-config.service";
import { BasiqAccountService } from "@bitwarden/web-vault/app/shared/utils/helper.basiq/accounts";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";
import { getOpeningAlignmentTransaction } from "@bitwarden/web-vault/app/shared/utils/helper.transactions/alignment-transactions";
import { validationResult } from "@bitwarden/web-vault/app/validators/base-validator";

import { TransactionImporter } from "./transaction-importer";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { InstitutionStoreModel } from "@bitwarden/web-vault/app/models/store/institution.store.model";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { DataPluginStoreService } from "@bitwarden/web-vault/app/services/store/data-plugin/data-plugin.store.service";
import { BasiqAccountSync } from "@bitwarden/web-vault/app/services/syncing/basiq/basiq.account.sync";
import { SourceAccountStoreModel } from "@bitwarden/web-vault/app/models/store/source-account-store.model";
import { getDateOnly } from "@bitwarden/web-vault/app/shared/utils/helper-string";
import { toObservable } from "@angular/core/rxjs-interop";
import { NotificationEnum } from "@bitwarden/web-vault/app/models/enum/notification.enum";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";
import { ConnectorView } from "@bitwarden/web-vault/app/models/view/connector/connector.view";
import { ConnectorService } from "@bitwarden/web-vault/app/services/DataService/connector/connector.service";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";

export type BasiqImportState = {
  started: boolean;
  ended: boolean;
  success: boolean;
};

@Injectable({
  providedIn: "root",
})
export class TransactionBasiqImporter extends BaseImporter implements TransactionImporter {
  private state = inject(AppStateStoreService);
  private institutionsSignal: Observable<InstitutionView[]>;
  private userStoreService: UserStoreService;
  private dataPluginStoreService: DataPluginStoreService;
  private confirmationDialogService: ConfirmationDialogService;
  private connectorService: ConnectorService;
  accountsMap: Map<string, Book> = new Map();
  private readonly BASIQ_TIMEZONE = "Australia/Sydney";
  private sortedExistingTransactions: Transaction[] = [];
  protected _basiqImportState = new BehaviorSubject<BasiqImportState>({
    started: false,
    ended: false,
    success: false,
  });
  basiqImportState$: Observable<BasiqImportState> = this._basiqImportState.asObservable();
  account: Book;
  basiqAccount: BasiqAccountType;
  openingBalance: GlossNumber;
  closingBalance: GlossNumber;
  totalAmount: GlossNumber;
  pendingAmount: GlossNumber;
  openingBalanceDate: string;
  hasBalance: boolean;
  private basiqAccounts: any;
  private records: validationResult;
  constructor(
    private dataRepositoryService: DataRepositoryService,
    private transactionProcessingService: BasiqTransactionProcessingService,
    private log: LogService,
    private basiqConfigService: BasiqIoConfigService,
    private basiqAccountService: BasiqAccountService,
    private basiqAuth: BasiqAuth,
    private route: ActivatedRoute,
    private router: Router,
    private dateFormat: DateFormat,
    private institutionService: InstitutionService,
    private injector: Injector,
  ) {
    super();
    this.userStoreService = inject(UserStoreService);
    this.dataPluginStoreService = inject(DataPluginStoreService);
    this.confirmationDialogService = inject(ConfirmationDialogService);
    this.institutionsSignal = toObservable(
      this.dataPluginStoreService.theInstitutionList.theInstitutionViews,
    );
  }

  startBasiqImport() {
    this.state.startProcess("importing-data", "Importing Banking Data...");
  }
  endFailureBasiqImport() {
    this.state.errorProcess("importing-data", "An error occurred while importing Banking Data...");
  }

  endSuccessBasiqImport() {
    this.state.endProcess("importing-data");
  }

  completeBasiqImport() {
    this._basiqImportState.complete();
  }

  isBalancedTransactions(transactions: BasiqIoTransactionType[]) {
    const postedTransactions = transactions.filter(
      (transaction) => transaction.status === "posted",
    );
    if (postedTransactions.length === 0) {
      this.hasBalance = false;
    }

    if (postedTransactions[0].balance === "") {
      this.hasBalance = false;
    }

    let isBalanced = false;
    const count = postedTransactions.length > 20 ? 20 : postedTransactions.length;
    /** if first 20 transactions' balances are 0 this means transactions are without balance*/
    for (let i = 0; i < count; i++) {
      if (Number(postedTransactions[i].balance) !== 0) {
        isBalanced = true;
        break;
      }
    }

    this.hasBalance = isBalanced;
  }

  addAlignmentTransactions(validatedTransactions: validationResult) {
    if (this.openingBalance.amount !== 0) {
      this.addOpeningBalanceAlignment(this.account, validatedTransactions);
    }
  }

  addOpeningBalanceAlignment(account: Book, validatedTransactions: validationResult) {
    const { openingBalance, openingBalanceDate } = this;
    validatedTransactions.newRecord.unshift(
      getOpeningAlignmentTransaction(account, openingBalance.amount, openingBalanceDate),
    );
  }

  async parse(
    transactions: BasiqIoTransactionType[],
    isAddAlignment = true,
  ): Promise<ImportTransactionResult> {
    this.isBalancedTransactions(transactions);
    await this.setAccountsMap();
    return await this.mapBasiqTransactions(transactions, isAddAlignment);
  }

  async setAccountsMap() {
    const accounts = await this.dataRepositoryService.getAllBooks();
    accounts.forEach((account) => {
      if (!account.basiqAccountId) {
        this.accountsMap.set(account.id, account);
      }

      this.accountsMap.set(account.basiqAccountId, account);
    });
  }

  resetImporter(basiqTransaction: BasiqIoTransactionType) {
    this.account = this.accountsMap.get(basiqTransaction.account);
    this.openingBalance = new GlossNumber();
    this.closingBalance = new GlossNumber();
    this.totalAmount = new GlossNumber();
    this.pendingAmount = new GlossNumber();
    this.openingBalanceDate = "";
  }

  getOpeningBalanceDate(basiqTransactions: BasiqIoTransactionType[]): string {
    const earliestTransaction = basiqTransactions[basiqTransactions.length - 1];
    const latestTransaction = basiqTransactions[0];
    this.log.info(`Earliest transaction: ${JSON.stringify(earliestTransaction)}`);
    this.log.info(`Latest transaction: ${JSON.stringify(latestTransaction)}`);
    const dateOfFirstTransaction = earliestTransaction.transactionDate
      ? getDateOnly(earliestTransaction.transactionDate)
      : getDateOnly(earliestTransaction.postDate);
    const previousDate = subDays(new Date(dateOfFirstTransaction), 1);
    return getDateOnly(previousDate);
  }

  async mapBasiqTransactions(
    basiqTransactions: BasiqIoTransactionType[],
    isAddAlignment = true,
  ): Promise<ImportTransactionResult> {
    this.log.info(`Number of basiq transactions: ${basiqTransactions.length}`);
    const importTransactionResult = new ImportTransactionResult();
    const fakeTransactions: any = [];
    this.resetImporter(basiqTransactions[0]);
    importTransactionResult.transactions = basiqTransactions.map((basiqTransaction) => {
      fakeTransactions.push({
        date: basiqTransaction.transactionDate
          ? basiqTransaction.transactionDate
          : basiqTransaction.postDate,
        amount: basiqTransaction.amount,
      });
      basiqTransaction.balance = "";
      const transactionAmount = new GlossNumber().setToGlossNumberObj({
        amount: Number(basiqTransaction.amount),
      });
      this.totalAmount.add(transactionAmount);
      if (basiqTransaction.status === "pending") {
        this.pendingAmount.add(transactionAmount);
      }
      return this.basiqBasedTransaction(basiqTransaction);
    });

    this.log.info(`fake transactions: ${JSON.stringify(fakeTransactions)}`);
    if (isAddAlignment) {
      await this.updateAccountBalance();
    }
    this.openingBalanceDate = this.getOpeningBalanceDate(basiqTransactions);

    return importTransactionResult;
  }

  private calculateBalanceTruth() {
    this.log.info(`Pending amount : ${this.pendingAmount.amount}`);
    this.log.info(`Total amount before calc: ${this.totalAmount.amount}`);

    this.basiqAccount = this.basiqAccounts.data.find(
      (basiqAccount: BasiqAccountType) => basiqAccount.id === this.account.basiqAccountId,
    );
    const accountBalance = new GlossNumber().setToGlossNumberObj({ amount: this.account.balance });
    this.log.info(`Account balance: ${accountBalance.amount}`);
    accountBalance.subtract(this.totalAmount);
    this.log.info(`this.totalAmount: ${this.totalAmount.amount}`);
    this.openingBalance = accountBalance;
    this.log.info(`this.openingBalance: ${this.openingBalance.amount}`);
    const basiqAvailableFunds = new GlossNumber().setToGlossNumberObj({
      amount: this.basiqAccount.availableFunds,
    });
    this.log.info(`Basiq available funds: ${basiqAvailableFunds.amount}`);
  }

  async updateAccountBalance() {
    this.calculateBalanceTruth();
  }

  basiqBasedTransaction(basiqTransaction: BasiqIoTransactionType) {
    /*TODO classifications and categories should be set on the basiqTransaction when they are being created in the flow */
    const {
      description,
      amount,
      account,
      balance,
      direction,
      transactionDate,
      postDate,
      classifications,
      categories,
      enrich,
    } = basiqTransaction;
    const glossAccount = this.accountsMap.get(account);
    const directionAndAmount = this.directionAndQuantity(basiqTransaction);
    //TODO need to make the Date concrete implementation (transactionDate and postDate is not equal)
    const basiqDate = transactionDate ? transactionDate : postDate;

    const glossDate = new GlossDate();
    glossDate.timeZone = glossAccount.timezone ?? this.BASIQ_TIMEZONE;
    glossDate.setToDateObj(basiqDate);

    const toResponseObj = {
      quantity: Number(amount),
      /*TODO check if it is ok to set convrate to 1 or just remove it entirely*/
      convrate: 1,
      currency: glossAccount.currency,
      balance: Number(balance) !== 0 ? Number(balance) : "",
      symbol: glossAccount.currency,
      accountId: glossAccount.id,
      classifications,
      categories,
      description:
        enrich?.cleanDescription && enrich?.cleanDescription !== ""
          ? enrich.cleanDescription
          : description,
      date: glossDate,
      direction: direction === "credit" ? TransactionDirection.In : TransactionDirection.Out,
      kind: basiqTransaction.class,
      definition:
        basiqTransaction.status === "pending"
          ? TransactionStatusEnum.pending
          : TransactionStatusEnum.transaction,
      ...directionAndAmount,
    };

    const transactionResponse = new TransactionResponse(toResponseObj);
    return new Transaction(transactionResponse);
  }

  private directionAndQuantity(basiqTransaction: BasiqIoTransactionType) {
    if (basiqTransaction.direction === "credit") {
      return {
        direction: TransactionDirection.In,
        in: basiqTransaction.amount,
      };
    } else {
      return {
        direction: TransactionDirection.Out,
        out: Math.abs(Number(basiqTransaction.amount)),
      };
    }
  }

  async saveAccounts(): Promise<AccountView[]> {
    const basiqAccountResponse = await this.basiqConfigService.getAccounts();
    this.basiqAccounts = { data: basiqAccountResponse.accounts };

    return await this.basiqAccountService.saveAccountsFromBasiq(
      basiqAccountResponse.accounts as BasiqAccountType[],
    );
  }

  /** @Michelle@Sinan : I want to make sure that the closing balance after sync is correct. When adding a closing for today it is not working
   * when adding opening as below it works fine ??? */
  async addTomorrowOpeningBalance(account: Book) {
    const basiqAccountResponse = await this.basiqConfigService.getAccounts();
    const basiqAccount = basiqAccountResponse.accounts.find(
      (basiqAccount) => basiqAccount.id === account.basiqAccountId,
    );

    const isCreditType = this.basiqAccountService.isCreditType(basiqAccount.class.type);
    const balance = isCreditType
      ? this.basiqAccountService.getCreditCardBalance(basiqAccount)
      : basiqAccount.availableFunds;
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const closingTransactionObject: any = {
      id: crypto.randomUUID(),
      convrate: 1,
      symbol: account.currency,
      currency: account.currency,
      description: "Generated Transaction (Opening Balance)",
      amount: "",
      accountId: account.id,
      classifications: account.defaultClassifications || [],
      categories: account.defaultCategories || [],
      balance: balance.toString(),
      direction: Number(balance) > 0 ? TransactionDirection.In : TransactionDirection.Out,
      date: today.toDateString(),
      definition: TransactionStatusEnum.opening,
    };

    const closingBalance = new Transaction(new TransactionResponse(closingTransactionObject));
    await this.transactionProcessingService.addTransaction(closingBalance);
  }

  async saveBasiqTransactionsOfAccount(account: AccountView) {
    try {
      const basiqAccountSync = new BasiqAccountSync(account, this.injector);
      await basiqAccountSync.syncAccount();
    } catch (e) {
      this.log.info(JSON.stringify(account));
      this.log.error(e);
    } finally {
      this.records = null;
    }
  }

  private isJobIdsValid(jobIds: string, jobId: string) {
    if (jobIds === "null" || jobId === "null") {
      return false;
    }

    return jobIds && jobId;
  }

  private isJobIdsDefined(jobIds: string, jobId: string) {
    return jobIds && jobId;
  }

  async handleBasiqImport() {
    const shouldCallBasiq = await this.isCallBasiqAfterRedirect();
    if (shouldCallBasiq) {
      this.initializeBasiqImport();
    }
  }

  /** Initialize the import process for Basiq */
  private initializeBasiqImport() {
    try {
      this.importAfterInstosInitialized();
    } catch (e) {
      this.endFailureBasiqImport();
      this.showGlobalError();
      this.logService.error(e);
    }
  }

  /**
   * take(1) : Coming from basiq it might not have been initialized before the import call, so this
   * is to make sure they are initialized and there will bo no error down the road
   * */
  private importAfterInstosInitialized() {
    this.institutionsSignal
      .pipe(
        filter((ins) => ins !== null),
        take(1),
      )
      .subscribe((instos) => {
        if (instos.length > 0) {
          this.startImporting();
        }
      });
  }

  /** Imports data from Basiq to Gloss*/
  private async startImporting() {
    this.startBasiqImport();

    await this.saveBasiqInstitutions();
    const newAccounts = await this.saveBasiqAccounts();

    await this.saveBasiqTransactions(newAccounts);
    this.endSuccessBasiqImport();
  }

  async saveBasiqInstitutions() {
    try {
      await this.saveInstitutions();
    } catch (e) {
      this.logService.error("saveBasiqInstitutions :");
      this.showGlobalError();
    }
  }

  async saveBasiqAccounts(): Promise<AccountView[]> {
    try {
      const accountViews = await this.saveAccounts();
      if (accountViews?.length > 0) {
        await this.userStoreService.accounts.saveToVault(accountViews, true);
      }
      return accountViews;
    } catch (e) {
      this.logService.error("saveBasiqAccounts :");

      this.showGlobalError();
      return [];
    }
  }

  async saveBasiqTransactions(newAccounts: AccountView[]) {
    try {
      for (const account of newAccounts) {
        const basiqConnector = await this.connectorService.getConnectorOf(Origin.basiq);

        await this.saveBasiqTransactionsOfAccount(account);

        if (!basiqConnector) {
          const connector = new ConnectorView();
          connector.name = Origin.basiq;
          connector.origin = Origin.basiq;
          connector.institutions = [];
          connector.accountStates = [];
          connector.connectionInfo = null;

          await this.userStoreService.connectors.save(connector);
        }
      }
    } catch (e) {
      this.logService.error("saveBasiqTransactions :");

      this.showGlobalError();
    }
  }

  showGlobalError() {
    this.confirmationDialogService.notify(NotificationEnum.error, "error", "somethingWentWrong");
  }

  async isCallBasiqAfterRedirect() {
    const state = this.route.snapshot.queryParamMap.get("state");

    /*TODO clean the logic here so it make sense */
    const jobId = this.route.snapshot.queryParamMap.get("jobId");
    const jobIds = this.route.snapshot.queryParamMap.get("jobIds");

    if (!this.isJobIdsDefined(jobIds, jobId)) {
      return false;
    }

    if (state && state === "toImport") {
      await this.clearUrlFromBasiqParams();
      return false;
    }

    if (this.isJobIdsValid(jobIds, jobId)) {
      await this.clearUrlFromBasiqParams();
      this.saveNewBasiqJobIdsToLocal(jobIds);
      return await this.basiqAuth.areJobsResolved(jobIds);
    } else if (this.isJobIdsDefined(jobIds, jobId)) {
      await this.router.navigate(["/manage/account"]);
    }

    return false;
  }

  private async clearUrlFromBasiqParams() {
    const currentURL = new URL(window.location.href);

    currentURL.searchParams.delete("jobId");
    currentURL.searchParams.delete("jobIds");

    const hashIndex = currentURL.href.indexOf("#");
    //TODO handle this better in case the route changes to something import-manager for eg
    const updatedURL = `${currentURL.href.substring(0, hashIndex)}#/manage/account`;

    history.replaceState({}, document.title, updatedURL);
  }

  private saveNewBasiqJobIdsToLocal(jobIds: string) {
    localStorage.setItem("basiqJobIds", jobIds);
  }

  async hasTransactions(): Promise<boolean> {
    this.sortedExistingTransactions = await this.dataRepositoryService.getAllTransactions();
    return !!this.sortedExistingTransactions.length;
  }

  getLastTransactionDate(basiqAccountInGloss: Book) {
    const latestAccountTransaction = this.sortedExistingTransactions.find(
      (transaction) => transaction.accountId === basiqAccountInGloss.id,
    );
    return latestAccountTransaction
      ? this.dateFormat.getDateStringFromStamp(
          latestAccountTransaction.transactionDate.date.getTime(),
        )
      : null;
  }

  async refreshConnections(): Promise<BasiqJob[]> {
    const glossConnectionsResponse = await this.basiqAuth.getConnectionResponse();
    const jobs: BasiqJob[] = [];
    for (const connection of glossConnectionsResponse.connections) {
      const glossConnectionRefreshResponse = await this.basiqAuth.refreshConnection(connection);
      jobs.push(glossConnectionRefreshResponse.job);
    }

    return jobs;
  }

  async getConnectionOfUrl(connectionId: string): Promise<GlossConnectionResponse> {
    return await this.basiqConfigService.getConnectionById(connectionId);
  }

  async getBasiqInstitutionById(institutionId: string) {
    return await this.basiqConfigService.getInstitutionById(institutionId);
  }

  private async _institutionMapperMapInstitution(basiqInstitutionDetails: BasiqInstitution) {
    const countries = this.dataPluginStoreService.theCountryList.theCountryViews();
    const country = countries.find(
      (country) => country.name.toLowerCase() === basiqInstitutionDetails.country.toLowerCase(),
    );
    const institutions = await this.institutionService.getInstitutionsMasterList();
    const institutionsOfCountry = institutions.filter(
      (testInstitution) => testInstitution.swift.countryCode === country.code,
    );
    const mappedInstos = institutionsOfCountry.filter((institution) => {
      const insto =
        basiqInstitutionDetails.shortName.toLowerCase() === institution.name.toLowerCase() ||
        basiqInstitutionDetails.name.toLowerCase().includes(institution.name.toLowerCase());

      if (insto) {
        for (const accountType of institution.availableAccounts) {
          accountType.id = crypto.randomUUID();
        }

        return institution;
      }
    });

    if (mappedInstos.length) {
      mappedInstos[0].basiqId = basiqInstitutionDetails.id;

      return mappedInstos[0];
    } else {
      const newGlossInstitutionObj: InstitutionStoreModel = {
        id: crypto.randomUUID(),
        vid: "",
        name: basiqInstitutionDetails.name,
        swt: {
          bac: null,
          cc: country.code,
          lc: null,
          brc: null,
        },
        bic: {
          bc: null,
          cc: country.code,
          lc: null,
        },
        fk: basiqInstitutionDetails.id,
        avaAcc: [],
        dc: new Date().toISOString(),
        v: 1,
        dm: new Date().toISOString(),
      };

      return new InstitutionView(newGlossInstitutionObj);
      /*return new Institution(new InstitutionResponse(newGlossInstitutionObj));*/
    }
  }
  async saveInstitutions() {
    const institutions = this.userStoreService.institutions.institutionViews();
    const newInstitutions: InstitutionView[] = [];
    const glossConnectionsResponse: GlossConnectionsResponse =
      await this.basiqConfigService.getConnections();
    for (const connection of glossConnectionsResponse.connections) {
      const glossConnectionResponse: GlossConnectionResponse = await this.getConnectionOfUrl(
        connection.id,
      );
      const basiqInstitution = glossConnectionResponse.connection.institution;
      const institutionExists = institutions?.some(
        (institution) => institution.basiqId === basiqInstitution.id,
      );

      if (!institutionExists) {
        const glossInstitutionResponse = await this.getBasiqInstitutionById(basiqInstitution.id);
        const institutionToSave = await this._institutionMapperMapInstitution(
          glossInstitutionResponse.institution,
        );
        newInstitutions.push(institutionToSave);
      }
    }

    if (newInstitutions.length) {
      await this.userStoreService.institutions.saveToVault(newInstitutions, true);
      // await this.dataRepositoryService.bulkCreateInstitutions(newInstitutions);
    }
  }

  /*TODO This method is a place holder for now . Once we got the flow from Lujane I will update it here */
  async connectToBasiqFromAccount() {
    if (this.basiqAuth.hasBasiqUser) {
      const userToken = await this.basiqAuth.getUserToken();
      window.location.href = `https://consent.basiq.io/home?&token=${userToken.token.access_token}&action=connect`;
    } else {
      await this.basiqAuth.authenticate();
      await this.basiqAuth.requestBasiqConsent();
    }
  }

  async checkBasiqUser() {
    return await this.basiqAuth.checkBasiqUser();
  }
}

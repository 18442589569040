import { TransactionStoreModel } from "../../../../models/store/transaction.store.model";
import { Transaction } from "../../../../models/data/blobby/transaction.data";

type TransactionModelV1 = Omit<TransactionStoreModel, "v" | "bIBO"> & {
  v: 1;
};

export const transaction_v0_to_v1 = (transaction: Transaction): TransactionModelV1 => {
  return {
    acId: transaction.accountId,
    bIB: transaction.bankImportedBalance,
    cls: transaction.classifications,
    cts: transaction.categories,
    dc: transaction.definition,
    dfn: transaction.definition,
    dir: transaction.direction,
    dm: new Date().toString(),
    dsc: transaction.description,
    id: transaction.id,
    knd: transaction.kind,
    lTid: transaction.linkedTo,
    qty: {
      cvrt: transaction.quantity.convrate,
      cvsym: transaction.quantity.convsym,
      ccy: transaction.quantity.currency,
      qty: {
        amt: transaction.quantity.actualQuantity.amount,
        prcs: transaction.quantity.actualQuantity.precision,
        sym: transaction.quantity.actualQuantity.symbol,
      },
    },
    srId: transaction.sourceId,
    tDt: {
      date: transaction.transactionDate.date.toDateString(),
      time: transaction.transactionDate.time,
      timeZone: transaction.transactionDate.timeZone,
    },
    v: 1,
    vid: "",
    vlPc: transaction.valuationPrice,
  };
};

export const transaction_v1_to_v2 = (transaction: TransactionStoreModel): TransactionStoreModel => {
  return {
    ...transaction,
    bIBO: null,
    v: 2,
  };
};

import { StateControlAbstraction } from "../../state-control.abstraction";
import { signal } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import {
  dayGranularity,
  GranularityDefaults,
  GranularityOptions,
  GranularityProperty,
  GroupingKeyType,
  monthGranularity,
  weekGranularity,
  yearGranularity,
} from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { PeriodType } from "./period.control";
import { addDays, addMonths, addWeeks, addYears, isBefore } from "date-fns";
import { filter } from "rxjs";

export type GroupingSelection = {
  countries: boolean;
  institutions: boolean;
  granularity: GranularityProperty;
};

const initialGrouping: GroupingSelection = {
  countries: false,
  institutions: false,
  granularity: "day",
};

export type GroupingOptions = {
  countries: [true, false];
  institutions: [true, false];
  granularity: GranularityProperty[];
};

const initialOptions: GroupingOptions = {
  countries: [true, false],
  institutions: [true, false],
  granularity: ["day"],
};

export class GroupControl extends StateControlAbstraction {
  /** Public signal for component to listen too **/
  private _options = signal<GroupingOptions>(initialOptions);
  options = this._options.asReadonly();

  private _selected = signal<GroupingSelection>(initialGrouping);
  selected = this._selected.asReadonly();

  clear() {
    this._options.set(null);
    this._selected.set(null);
  }

  /** **<span style="color: red;">This is for store use only</span>** **/
  readonly selected$ = toObservable(this.selected);

  updateSelection(selection: GroupingSelection) {
    this._selected.set(selection);
  }

  updateOption(option: GroupingOptions) {
    this._options.set(option);
  }

  updateOptionAndSelectionFromPeriod(period: PeriodType) {
    const defaultSelection = this.getDefaultGranularityForDates(period);
    const options = this.getGranularityOptionsForDates(period);

    this._options.set({
      ...this.options(),
      granularity: options,
    });

    if (!this.options().granularity.includes(this.selected().granularity) && options.length > 0) {
      this.updateSelection({
        ...this.selected(),
        granularity: defaultSelection,
      });
    }
  }

  private getDefaultGranularityForDates(period: PeriodType) {
    for (const granularityDefault of GranularityDefaults) {
      const endDateLimit = this.calculateEndDateLimit(
        period.startDate,
        granularityDefault.limit,
        granularityDefault.limitType,
      );

      if (endDateLimit === null || isBefore(period.endDate, endDateLimit)) {
        return granularityDefault.default;
      }
    }

    /** Should never reach here**/
    throw new Error("No default granularity found");
    // return GranularityDefaults[0].default;
  }

  private getGranularityOptionsForDates(period: PeriodType) {
    for (const granularityOption of GranularityOptions) {
      const endDateLimit = this.calculateEndDateLimit(
        period.startDate,
        granularityOption.limit,
        granularityOption.limitType,
      );

      if (endDateLimit === null || isBefore(period.endDate, endDateLimit)) {
        return granularityOption.options;
      }
    }
  }

  private calculateEndDateLimit(startDate: Date, limit: number, limitType: GranularityProperty) {
    switch (limitType) {
      case dayGranularity:
        return addDays(new Date(startDate), limit);
      case weekGranularity:
        return addWeeks(new Date(startDate), limit);
      case monthGranularity:
        return addMonths(new Date(startDate), limit);
      case yearGranularity:
        return addYears(new Date(startDate), limit);
      default:
        return new Date(startDate);
    }
  }
}

import { TransactionStoreService } from "../transaction/transaction.store.service";
import { StoreSubscriber } from "../dependency.subscriber.abstraction";
import { inject } from "@angular/core";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { filter, skip } from "rxjs";

export class TransactionSubscriber extends StoreSubscriber<TransactionView> {
  /** **<span style="color: red;">This is for store use only</span>** **/
  protected dependentStore: TransactionStoreService = inject(TransactionStoreService);

  /** **<span style="color: red;">This is for store use only</span>** **/
  getObservable() {
    return this.dependentStore.enrichTransaction.collection$.pipe(
      skip(1), // todo @alex need to clean up the sync of the store so that is not required anymore
      filter((x) => x !== null || typeof x !== "undefined"),
    );
  }

  /** **<span style="color: red;">This is for store use only</span>** **/
  getCollection(): TransactionView[] {
    return this.dependentStore.enrichTransaction.collection();
  }
}

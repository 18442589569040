import { ComponentType } from "@angular/cdk/portal";
import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";

import { ActionButton } from "../../components/buttons/gloss-button/actionButton";
import { DeauthorizeSessionsComponent } from "../deauthorize-sessions.component";
import { DeleteAccountComponent } from "../delete-account.component";
import { PurgeVaultComponent } from "../purge-vault.component";
import { RemoveBasiqConsentComponent } from "../remove-basiq-consent/remove-basiq-consent.component";
import { ScopeValue } from "@bitwarden/web-vault/app/models/data/role-access.data";

@Component({
  selector: "app-danger-zone",
  templateUrl: "advanced-features.component.html",
})
export class AdvancedFeaturesComponent {
  private dialogRef: MatDialogRef<any> | null = null;
  private destroy$ = new Subject<void>();
  contextList: { roleScope: ScopeValue; buttonText: string; buttonOptions: ActionButton }[] = [];

  constructor(
    private dialog: MatDialog,
    private i18nService: I18nService,
  ) {
    this.contextList = [
      {
        roleScope: "GLOSS",
        buttonText: "deauthorizeSessions",
        buttonOptions: this.generateButtonOptions(
          "deauthorizeSessions",
          DeauthorizeSessionsComponent,
        ),
      },
      {
        roleScope: "GLOSS",
        buttonText: "purgeVault",
        buttonOptions: this.generateButtonOptions("purgeVault", PurgeVaultComponent),
      },
      {
        roleScope: "BETA_ACCESS",
        buttonText: "removeBasiqConsent",
        buttonOptions: this.generateButtonOptions(
          "removeBasiqConsent",
          RemoveBasiqConsentComponent,
        ),
      },
      {
        roleScope: "GLOSS",
        buttonText: "deleteAccount",
        buttonOptions: this.generateButtonOptions("deleteAccount", DeleteAccountComponent),
      },
    ];
  }

  // todo now we can really have a factory for this
  generateButtonOptions(text: string, component: ComponentType<any>): ActionButton {
    return new ActionButton({
      text: this.i18nService.t(text),
      class: "danger",
      onClick: () => this.openDialog(component),
      isEnabled: !this.isDialogOpen(),
    });
  }

  openDialog(component: ComponentType<any>) {
    this.dialogRef = this.dialog.open(component, {
      panelClass: "mobile-dialog",
      width: "600px",
      maxWidth: "100vh",
    });
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef = null;
      });
  }

  isDialogOpen(): boolean {
    return this.dialogRef !== null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

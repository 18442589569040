import { Component, EventEmitter, Input, Output } from "@angular/core";

import { HelperTooltip } from "@bitwarden/web-vault/app/shared/utils/helper-tooltip";

@Component({
  selector: "modal-header",
  templateUrl: "./modal-header.component.html",
})
export class ModalHeaderComponent {
  @Input() title = "";
  @Input() showBackButton = true;
  @Input() showCloseButton = true;
  @Input() isMatDialog = true;
  @Input() enableTooltip = false; // for ngIf of markup
  @Input() tooltipInfo = "";
  @Output() onBackClicked = new EventEmitter<void>();
  @Output() onCloseClicked = new EventEmitter<void>();

  tooltip: HelperTooltip;

  constructor() {
    this.tooltip = new HelperTooltip();
  }

  handleBack() {
    this.onBackClicked.emit();
  }

  handleClose() {
    this.onCloseClicked.emit();
  }
}

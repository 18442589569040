@if (isVisible) {
  <app-help-box
    (changeHelpOpen)="onHelpBoxClose($event)"
    class="tw-absolute tw-bottom-0 tw-top-0 tw-z-[2] tw-flex tw-w-full"
  >
  </app-help-box>
}

<div
  *ngIf="scenarioDifferenceMessage"
  class="tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-between"
>
  <div class="tw-flex tw-flex-col tw-gap-5">
    <h1
      class="tw-font-montserrat tw-text-natural[800] tw-mb-5 tw-flex tw-items-center tw-justify-center tw-text-4xl tw-font-bold xs:tw-text-3xl"
    >
      <svg class="tw-mr-3 tw-h-7 tw-w-7">
        <use [attr.xlink:href]="'images/sprite.svg#' + scenarioDifferenceMessage.headerIcon" />
      </svg>
      {{ scenarioDifferenceMessage.headerText }}!
    </h1>
    <div class="tw-text-[20px]">
      <span class="tw-block">{{ scenarioDifferenceMessage.firstBlock.textOne }}</span>
      <span
        class="tw-block tw-text-2xl tw-font-bold"
        *ngIf="scenarioDifferenceMessage.firstBlock.numberToShow"
        >~
        {{
          scenarioDifferenceMessage.firstBlock.numberToShow | currency: data.baseCurrency : "symbol"
        }}</span
      >
      <span class="tw-block">{{ scenarioDifferenceMessage.firstBlock.textTwo }}</span>
    </div>
    <div class="tw-text-[20px]">
      <span class="tw-block">{{ scenarioDifferenceMessage.secondBlock.textOne }}</span>
      <span
        class="tw-block tw-text-2xl tw-font-bold"
        *ngIf="scenarioDifferenceMessage.secondBlock.numberToShow"
        >{{
          scenarioDifferenceMessage.secondBlock.numberToShow
            | currency: data.baseCurrency : "symbol"
        }}
        <svg *ngIf="isNotSyncedAccount" class="tw-mr-3 tw-h-7 tw-w-7">
          <use xlink:href="images/sprite.svg#edit-icon" />
        </svg>
      </span>
      <span class="tw-block">{{ scenarioDifferenceMessage.secondBlock.textTwo }}</span>
    </div>
    <div class="tw-text-[20px]">
      <span class="tw-block">{{ scenarioDifferenceMessage.thirdBlock.textOne }}</span>
      <span
        class="tw-block tw-text-2xl tw-font-bold"
        *ngIf="scenarioDifferenceMessage.thirdBlock.numberToShow"
        >~
        {{
          scenarioDifferenceMessage.thirdBlock.numberToShow | currency: data.baseCurrency : "symbol"
        }}</span
      >
      <span class="tw-block">{{ scenarioDifferenceMessage.thirdBlock.textTwo }}</span>
    </div>
  </div>
  <div class="tw-mt-2.5 tw-flex tw-w-full tw-flex-col tw-justify-between tw-gap-2">
    <div *ngIf="isNotSyncedAccount" class="tw-w-full">
      <app-gloss-button
        class="tw-w-full"
        [options]="{
          text: 'tryNewBalance' | i18n,
          type: 'submit',
          class: 'neutral',
          onClick: balanceEdit,
          isEnabled: true,
        }"
      ></app-gloss-button>
    </div>
    <div class="tw-w-full">
      <app-gloss-button
        mat-dialog-close
        aria-label="Close dialog"
        class="tw-w-full"
        [options]="{
          text: scenarioDifferenceMessage.buttonText,
          type: 'submit',
          class: 'primary',
          onClick: closeDialog,
          isEnabled: true,
        }"
      ></app-gloss-button>
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";
import { Transaction, Span } from "@elastic/apm-rum";
import { ApmService } from "@elastic/apm-rum-angular";

import { LogService } from "@bitwarden/common/abstractions/log.service";

import { flagEnabled } from "../../../utils/flags";

import { fibBenchmark, distBenchmark } from "./benchmark/benchmark";

@Injectable({
  providedIn: "root",
})
export class PerformanceService {
  private currentTransaction: Transaction;
  private currentSpan: Span = null;
  private fibonacciMark = 0;
  private squareMark = 0;
  private _vaultContext: Record<string, string | number> = {};
  constructor(
    private apmService: ApmService,
    private log: LogService,
  ) {
    if (flagEnabled("benchmark")) {
      this.fibonacciMark = fibBenchmark(41);
      this.squareMark = distBenchmark(100);
    }
  }

  setVaultContext(context: Record<string, string | number>) {
    this._vaultContext = context;

    if (this.apmService.apm.isActive() && this.currentTransaction) {
      if (flagEnabled("benchmark")) {
        this._vaultContext.BenchmarkFibonacci = this.fibonacciMark;
        this._vaultContext.BenchmarkSquares = this.squareMark;
      }

      this.apmService.apm.setCustomContext(this._vaultContext);
      // Add labels
      const label: Labels = { transaction_count: this._vaultContext.transactions };
      this.apmService.apm.addLabels(label);
      // this.currentTransaction.addLabels(label)
    }
  }

  start(name: string) {
    if (this.apmService.apm.isActive()) {
      if (this.currentTransaction) {
        this.currentTransaction.end();
      }
      this.currentTransaction = this.apmService.apm.startTransaction(name, "performance");
    }
  }

  mark(name: string) {
    if (this.apmService.apm.isActive()) {
      if (!this.currentTransaction) {
        this.log.info("Performance : " + name);

        this.start(name);
      } else {
        this.log.info("Performance Span: " + name);

        if (this.currentSpan) {
          this.currentSpan.end();
        }
        this.currentSpan = this.currentTransaction.startSpan(name);
      }
    }
  }

  markEnd() {
    if (this.apmService.apm.isActive()) {
      if (this.currentSpan) {
        this.currentSpan.end();
        this.currentSpan = null;
      } else if (this.currentTransaction) {
        this.currentTransaction.end();
        this.currentTransaction = null;
      }
    }
  }

  end() {
    if (this.apmService.apm.isActive() && this.currentTransaction) {
      if (this.currentSpan) {
        this.currentSpan.end();
      }

      this.currentTransaction.end();
      this.currentTransaction = null;
      this.currentSpan = null;
    }
  }
}

import { Component, EventEmitter, Output, Input, ChangeDetectorRef, inject } from "@angular/core";

import "./currencies.component.style.scss";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import {
  CurrencyType,
  DefaultCurrencyPerLocation,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";

/**
 * CountriesComponent is a component that provides a form field for selecting countries.
 * It uses Angular Material's autocomplete feature to suggest countries as the user types.
 * The selected country is emitted as events.
 * TODO: Add a feature to select multiple countries.
 * TODO: Add a feature of custom styling.
 */
@Component({
  selector: "app-currencies",
  templateUrl: "./currencies.component.html",
  styles: ["currencies.component.style.scss"],
})
export class CurrenciesComponent {
  private role = inject(RoleAccessService);
  private userStoreService = inject(UserStoreService);
  private cdr = inject(ChangeDetectorRef);

  allCurrencies: CurrencyType[] = this.role.getRoleAccess().getClaim().forex_currency;
  filteredCurrencies: CurrencyType[] = this.role.getRoleAccess().getClaim().forex_currency;
  currency: string;
  @Input() label: string;
  @Input() accountCurrency: string;
  @Input() disabled = false;

  /**
   * Event that is emitted when a currency is selected.
   */
  @Output() selectedCurrency: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Event that is emitted when multiple currencies are selected.
   */
  @Output() selectedCurrencies: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor() {
    this.setCurrency();
  }

  async ngOnChanges() {
    await this.checkAccountCurrency();
  }

  private async checkAccountCurrency() {
    if (this.accountCurrency) {
      this.setCurrency();
      this.cdr.detectChanges();
    }
  }

  private setCurrency() {
    if (!this.accountCurrency) {
      const userLocation = this.userStoreService.preferences.preferenceView().userLocation;
      this.currency = DefaultCurrencyPerLocation[userLocation];
    } else {
      this.currency = this.accountCurrency;
    }

    this.filterCurrenciesInWizard();
  }

  /** use the currency of the region only, in wizard */
  filterCurrenciesInWizard() {
    const isInWizard = !!localStorage.getItem("inWizard");
    if (isInWizard) {
      this.filteredCurrencies = this.allCurrencies.filter((currency) => currency === this.currency);
      this.currency = this.accountCurrency;
    }
  }

  /**
   * Emits the selectedCountry event with the provided country.
   * @param {string} currency - The country that was selected.
   */
  currencySelected(currency: string) {
    if (this.selectedCurrency) {
      this.selectedCurrency.emit(currency);
    }
  }
  /**
   * Clears the selection of the country.
   * This method is used to emit a null value to the `selectedCountry` event emitter,
   * indicating that the country selection has been cleared.
   */
  selectionCleared() {
    this.selectedCurrency.emit(null);
  }
}

export interface VaultStat {
  itemCount: number;
}

export class VaultStatistic {
  // Static property to hold the single instance
  private static instance: VaultStatistic;
  statistics: Map<string, VaultStat> = new Map();

  // Private constructor to prevent direct instantiation
  private constructor() {}

  /**
   * Get the shared singleton instance of VaultAdapter.
   */
  static getInstance(): VaultStatistic {
    if (!this.instance) {
      this.instance = new VaultStatistic();
    }
    return this.instance;
  }

  add(cipherId: string, statistic: VaultStat) {
    this.statistics.set(cipherId, statistic);
  }
}

import { MoneySeparatorType, MoneySeparatorValues } from "../../models/types/general-types";

export class MoneySeparator {
  private readonly _character: string;
  private _index: number[];
  private _type: MoneySeparatorType;
  private _possibleSeparators: string[];
  private _originalValue: string;
  constructor(
    separatorChar: string,
    cleanValue: string,
    originalValue: string,
    possibleSeparators: string[],
  ) {
    this._character = separatorChar;
    this.possibleSeparators = possibleSeparators;
    this.originalValue = originalValue;
    this.setType(separatorChar, cleanValue);
  }

  get originalValue(): string {
    return this._originalValue;
  }
  set originalValue(originalValue: string) {
    this._originalValue = originalValue;
  }

  get possibleSeparators(): string[] {
    return this._possibleSeparators;
  }
  set possibleSeparators(possibleSeparators: string[]) {
    this._possibleSeparators = possibleSeparators;
  }

  get character(): string {
    return this._character;
  }

  get index(): number[] {
    return this._index;
  }
  set index(indexArr: number[]) {
    this._index = indexArr;
  }

  get type(): MoneySeparatorType {
    return this._type;
  }
  set type(type: MoneySeparatorType) {
    this._type = type;
  }

  getCharacterIndexArr(separatorChar: string, cleanValue: string): number[] {
    const indices: number[] = [];
    for (let i = 0; i < cleanValue.length; i++) {
      if (cleanValue[i] === separatorChar) {
        indices.push(i);
      }
    }
    return indices;
  }

  setType(separatorChar: string, cleanValue: string) {
    let isDecimal = false;
    let isThousand = true;

    /** indices at where the separatorChar occurs */
    const charIndexArr = this.getCharacterIndexArr(separatorChar, cleanValue);
    const isMultipleOccurrence = charIndexArr.length > 1;
    const separatorSplits = cleanValue.split(separatorChar);

    const otherSeparator = this.possibleSeparators.find((separator) => separator !== separatorChar);
    if (isMultipleOccurrence) {
      for (const [index, separatorSplit] of separatorSplits.entries()) {
        let split = separatorSplit;
        if (index === separatorSplits.length - 1 && otherSeparator) {
          split = separatorSplit.split(otherSeparator)[0];
        }

        const splitLength = index === 0 && split.includes("-") ? split.length - 1 : split.length;
        if (index === 0 && splitLength > 3) {
          isThousand = false;
          break;
        } else if (index !== 0 && splitLength !== 3) {
          isThousand = false;
          break;
        }
      }
    } else {
      /** separatorSplits.length = 2 cuz there is just one separator*/
      const leftSplitLength = separatorSplits[0].includes("-")
        ? separatorSplits[0].length - 1
        : separatorSplits[0].length;
      let rightSplitLength = separatorSplits[1].length;
      if (otherSeparator) {
        rightSplitLength = separatorSplits[1].split(otherSeparator)[0].length;
      }
      if (!(leftSplitLength < 4 && rightSplitLength === 3)) {
        isDecimal = true;
        isThousand = false;
      } else {
        isThousand = otherSeparator
          ? leftSplitLength < 4 && rightSplitLength === 3
          : leftSplitLength === 3 && rightSplitLength === 3;
      }
    }

    if (isDecimal) {
      this.type = MoneySeparatorValues.decimal;
    }

    if (isThousand) {
      this.type = MoneySeparatorValues.thousands;
    }

    if (!isDecimal && !isThousand) {
      this.type = undefined;
      throw { messageKey: "notValidNumberDetected", value: this.originalValue };
    }
  }
}

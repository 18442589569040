import { Origin } from "../../types/general-types";
import { InstitutionAccountLink } from "../../types/institution.type";
import { SplitCategoryType } from "../../types/split-category-type";
import { SplitClassificationType } from "../../types/split-classification-type";

import { Book } from "../../data/blobby/book.data";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import {
  AccountModelVersion,
  AccountStoreModel,
  DefaultAccountStoreModel,
} from "@bitwarden/web-vault/app/models/store/account.store.model";

import {
  BalanceCalculatedProperties,
  CalculatedProperty,
} from "@bitwarden/web-vault/app/models/view/calculated.property";
import {
  AccountLinkedProperties,
  LinkedProperty,
  LinkedView,
} from "@bitwarden/web-vault/app/models/view/linked.property";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { InstitutionAccountView } from "@bitwarden/web-vault/app/models/view/institution/institution-account.view";
import { AccountType } from "@bitwarden/web-vault/app/models/types/account.types";
import { CurrencyType } from "@bitwarden/web-vault/app/models/types/location-currency.types";

export class AccountView
  extends ModelView<AccountStoreModel>
  implements
    CalculatedProperty<BalanceCalculatedProperties>,
    LinkedProperty<AccountLinkedProperties>
{
  /** Model version in Use **/
  protected version: AccountModelVersion;

  /** Updatable Properties **/
  name: string;
  type: AccountType;
  currency: CurrencyType;
  isDeleted: boolean;
  origin: Origin;
  institution: InstitutionView;
  institutionLink: InstitutionAccountLink;
  defaultClassifications: SplitClassificationType[];
  defaultCategories: SplitCategoryType[];
  institutionAccountType: InstitutionAccountView;
  timezone: string;
  basiqAccountId: string;
  creditLimit: number;

  /** use this instead of status */
  syncStatusLink: LinkedView[];
  source: string;

  /** BalanceCalculation **/
  c_balance: number;

  /** **/
  c_maxInterestRate: number = NaN;

  /**  @Deprecated */
  status: string[];
  /**  @Deprecated */
  originalBook: Book;
  /** @deprecated **/
  balance: number;
  /** @deprecated **/
  legacyId: string;

  constructor(model?: AccountStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.source = model.ori;
      /** @Alex I guess we need to remove this balance from the model cuz when it compares/loads it requires it */
      this.balance = model.bal;
      this.creditLimit = model.crLm;
      this.currency = model.curr;
      this.basiqAccountId = model.bsqId;
      this.defaultCategories = model.dfltCat;
      this.defaultClassifications = model.dfltCla;
      this.institutionLink = model.instLnk;
      this.name = model.name;
      this.origin = model.ori;
      this.type = model.type;
      this.timezone = model.tz;
      this.basiqAccountId = model.bsqId;
      this.syncStatusLink = model.st.map((id) => ({ id }));
      this.vaultId = model.vid;

      /** Remove after cleaning up. **/
      this.legacyId = this.id;
    } else {
      this.version = DefaultAccountStoreModel.v;
    }
  }

  toStoreModel(): AccountStoreModel {
    return {
      bsqId: this.basiqAccountId,
      curr: this.currency,
      crLm: this.creditLimit,
      date: "",
      dc: this.dateCreated.toISOString(),
      dfltCat: this.defaultCategories,
      dfltCla: this.defaultClassifications,
      dm: new Date().toISOString(),
      id: this.id,
      instLnk: this.institutionLink,
      name: this.name,
      bal: this.balance,
      ori: this.origin,
      type: this.type,
      st: this.syncStatusLink ? this.syncStatusLink.map((link) => link.id) : [],
      tz: this.timezone,
      v: this.version,
      vid: this.vaultId,
      del: this.isDeleted,
    };
  }

  clone(): AccountView {
    const copy = new AccountView(this.toStoreModel());
    copy.balance = this.balance;
    copy.c_balance = this.c_balance;
    return copy;
  }

  /**
   * @deprecated
   */
  legacyConstructor(account: Book, institution: InstitutionView): this {
    this.legacyId = account.id;
    this.originalBook = account;
    this.name = account.name;
    this.type = account.type;
    this.currency = account.currency;
    this.origin = account.origin;
    this.status = []; // TODO when it is implemented update this part as well & it's an array of status ids
    this.institutionLink = account.institutionLink;
    this.institution = this.setInstitution(institution);
    this.defaultClassifications = account.defaultClassifications;
    this.defaultCategories = account.defaultCategories;
    this.setInstitutionAccountType();
    this.setSource();
    return this;
  }

  /**
   * @deprecated
   */
  setInstitution(institution: InstitutionView) {
    if (institution) {
      return institution;
    } else {
      return { id: "", name: "unknown" } as unknown as InstitutionView;
    }
  }

  /**
   * @deprecated
   */
  setInstitutionAccountType() {
    if (
      this.institution &&
      this.institution.availableAccounts?.length &&
      this.institutionLink &&
      this.institutionLink.institutionAccountId
    ) {
      this.institutionAccountType = this.institution.availableAccounts.find(
        (institutionAccount) => institutionAccount.id === this.institutionLink.institutionAccountId,
      );
    } else {
      this.institutionAccountType = { id: "", name: "unknown", interestRates: [] };
    }
  }

  /**
   * @deprecated
   */
  setSource() {
    if (this.origin !== Origin.manual) {
      this.source = "Auto Sync";
    } else {
      this.source = "Manual Import";
    }
  }
}

export abstract class Menu {
  selectedKey: string;

  protected getSelectedIcon(item: any): string {
    return this.selectedKey === item.key ? `${item.icon}-selected` : item.icon;
  }

  protected onIsActiveChange(isActive: boolean, key: string) {
    if (isActive) {
      this.selectedKey = key;
    }
  }
}

import { Component, inject, Input } from "@angular/core";
import { View } from "@bitwarden/common/models/view/view";
import { View as ViewEnum } from "@bitwarden/web-vault/app/models/enum/view.enum";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-views-control",
  templateUrl: "./views-control.component.html",
  providers: [DatePipe],
})
export class ViewsControlComponent {
  @Input() currentView: View;
  protected ViewEnum = ViewEnum;
  currentDate: string;
  private datePipe = inject(DatePipe);

  constructor() {
    this.currentDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  }
}

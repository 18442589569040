import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { CategoryStoreModel } from "../../../models/store/category.store.model";
import { signal } from "@angular/core";
import { CategoryView } from "@bitwarden/web-vault/app/models/view/category/category.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";

// todo - implement CategoryView not here

export class CategoriesStoreCollection extends StoreModelCollection<
  CategoryStoreModel,
  CategoryView
> {
  readonly type: StoreModelNames = "CategoryStoreModel";
  protected models: Map<string, CategoryStoreModel> = new Map<string, CategoryStoreModel>();

  private _categoryViews = signal<CategoryView[]>(null);
  readonly categoryViews = this._categoryViews.asReadonly();

  triggerChanges(): void {
    const views: CategoryView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new CategoryView(model)) as CategoryView);
    });
    this._categoryViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._categoryViews.set(null);
  }
}

import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-row-group-renderer",
  templateUrl: "./account-table-row-group-renderer.component.html",
})
export class AccountTableRowGroupRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  isCountry!: boolean;
  isInstitution!: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isCountry = this.params.node.level === 0;
    this.isInstitution = this.params.node.level === 1;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}

<div class="tw-flex tw-justify-between tw-p-4 tw-uppercase tw-text-neutral-800 tw-text-sm">
  <div>
    <div *ngIf="isFirstLevel">
      {{ params.node.key }}
      <span class="tw-text-neutral-300 tw-text-xs">{{ "institution" }}</span>
    </div>
    <span *ngIf="isSecondLevel">{{ params.node.key }}</span>
  </div>
  <icon [name]="isExpanded ? 'up-arrow' : 'down-arrow'" [size]="5"></icon>
</div>

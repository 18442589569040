import {
  GlossNumber,
  GlossNumberUtils,
} from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
export type ValuationProperties = {
  __v: number;
  _value: {
    amount: number;
    precision: number;
    symbol: string;
  };
  _normalizedValue: {
    amount: number;
    precision: number;
    symbol: string;
  };
  normalizingRate: number;
};
export class ValuationUtilities {
  static fromSelfProperties(obj: Valuation): Valuation {
    const valuation = new Valuation();
    if (obj?.value) {
      valuation.value = GlossNumberUtils.fromSelfProperties(obj.value);
    }
    if (obj?.normalizedValue) {
      valuation.normalizedValue = GlossNumberUtils.fromSelfProperties(obj.normalizedValue);
    }
    if (obj?.normalizingRate) {
      valuation.normalizingRate = obj.normalizingRate;
    }
    return valuation;
  }
}

export class Valuation {
  private readonly __v = 1;
  // NOTE: we do not use a normalized pair because the value in valuation is not the original symbol,
  // it is the symbol from quantity converted into a value of currency it is valuedIn
  value: GlossNumber;
  normalizedValue: GlossNumber;

  // TODO: Replace the rates once we move to new Reference model
  // normalizingReference: Reference;
  // normalizingReferencePrior: Reference;

  // symbolReference: Reference;
  // symbolReferencePrior: Reference;

  normalizingRate: number; // the rate at which we are converting the valueCurrency into the normalizedCurrency
  normalizingRatePrior: number; // the normalizingRate of the transaction prior to this one

  symbolValue: number; // the last/close price of the asset
  symbolValuePrior: number; // the previous last/close price of the asset in the transaction before this one, to calculate the change

  constructor() {
    this.value = new GlossNumber();
    this.normalizedValue = new GlossNumber();
  }

  setValueAmountSymbol(value: number, symbol: string) {
    this.setValueAmount(value);
    this.setValueSymbol(symbol);
  }

  setValueAmount(value: number) {
    this.value.amount = value;
  }

  setValueSymbol(symbol: string) {
    this.value.symbol = symbol;
  }

  setValuePrecision(precision: number) {
    this.value.precision = precision;
  }

  setNormalizedAmountSymbol(value: number, symbol: string) {
    this.setNormalizedAmount(value);
    this.setNormalizedSymbol(symbol);
  }

  setNormalizedAmount(value: number) {
    this.normalizedValue.amount = value;
  }

  setNormalizedSymbol(symbol: string) {
    this.normalizedValue.symbol = symbol;
  }

  setNormalizedPrecision(precision: number) {
    this.normalizedValue.precision = precision;
  }

  setToValuationObj(response: Record<string, any>) {
    return response instanceof Valuation
      ? this.setFromObject(response)
      : this.setFromQueryString(response);
  }

  private setFromObject(valuation: Valuation) {
    return valuation;
  }

  private setFromQueryString(response: Record<string, any>) {
    if (typeof response.value === "object") {
      this.value = new GlossNumber().setToGlossNumberObj(response.value);
    } else if (typeof response._value === "object") {
      this.value = new GlossNumber().setToGlossNumberObj(response._value);
    } else {
      this.value = new GlossNumber();
      // TODO: We should change normalizedProperties to be more like Valuation with GlossNumbers and Reference
      if (response.value) {
        this.setValueAmount(response.value);
      }
      if (response.valueCurrency) {
        this.setValueSymbol(response.valueCurrency);
      }
    }

    if (typeof response.normalizedValue === "object") {
      this.normalizedValue = new GlossNumber().setToGlossNumberObj(response.normalizedValue);
    } else if (typeof response._normalizedValue === "object") {
      this.normalizedValue = new GlossNumber().setToGlossNumberObj(response._normalizedValue);
    } else {
      this.normalizedValue = new GlossNumber();
      // TODO: We should change normalizedProperties to be more like Valuation with GlossNumbers and Reference
      if (response.normalizedValue) {
        this.setNormalizedAmount(response.normalizedValue);
      }
      if (response.normalizedCurrency) {
        this.setNormalizedSymbol(response.normalizedCurrency);
      }
    }

    if (response.symbolValue) {
      this.symbolValue = response.symbolValue;
    }
    if (response.normalizingRate) {
      this.normalizingRate = response.normalizingRate;
    }
    if (response.symbolValuePrior) {
      this.symbolValuePrior = response.symbolValuePrior;
    }
    if (response.normalizingRatePrior) {
      this.normalizingRatePrior = response.normalizingRatePrior;
    }

    return this;
  }
}

import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { active } from "d3";
import { Subject, takeUntil } from "rxjs";

import { EstimatePeriods } from "@bitwarden/common/enums/recurringPeriod";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { SplitOptionsComponent } from "@bitwarden/web-vault/app/components/split-options/split-options.component";
import { FormEstimate } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimate/form/form-estimate";
import { FormHelper } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimate/form/form-helper";
import { EstimatesAddEditComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimates-add-edit.component";
import { AccountAddEditComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/accounts-add-edit/account-add-edit.component";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { EstimateResponse } from "@bitwarden/web-vault/app/models/data/response/estimate.response";
import {
  EstimateActionsEnum,
  UserEstimateActionsEnum,
} from "@bitwarden/web-vault/app/models/enum/estimate-actions.enum";
import {
  DayOfMonth,
  EstimateActionKeyNamePair,
  MonthlyOption,
  WeeklyOption,
  YearlyOption,
} from "@bitwarden/web-vault/app/models/enum/estimateType";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { EstimateService } from "@bitwarden/web-vault/app/services/DataService/estimates/estimate-service";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";

const UserEstimateActions = {
  Interest: {
    name: UserEstimateActionsEnum.interest,
    key: EstimateActionsEnum.interest,
  },
};

type DateModalType = 1 | 2 | 3 | 4;

@Component({
  selector: "app-estimate-form",
  templateUrl: "./estimate-form.component.html",
})
export class EstimateFormComponent implements OnInit {
  private role = inject(RoleAccessService);

  loading = false;
  description: string;
  existingAccounts: Book[];
  valueOptions: string[] = ["fixed", "variable"];
  selectedValueOption: "fixed" | "variable";
  amountTitle: "amount" | "startingAmount" = "amount";
  startDateLabel: "date" | "startDate" = "date";
  formPromise: Promise<any>;
  estimateTypes: EstimateActionKeyNamePair[] = Object.values(UserEstimateActions);
  estimateDirections: string[] = Object.values(TransactionDirection);
  recurringValues: string[] = ["No", "Yes"];
  categories: Category[];
  appliedCategories: Category[] = [];
  classifications: Classification[];
  appliedClassifications: Classification[] = [];
  recurringPeriod: EstimatePeriods;
  startDate: string;
  endDate: string;
  groupId: string;
  existingEstimates: Estimate[];
  selectedCategories: SplitCategoryType[] = [];
  selectedClassifications: SplitClassificationType[] = [];
  currencies = this.role.getRoleAccess().getClaim().forex_currency;
  activeRecurringVariable = false;
  activeRecurringFix = false;
  isCategoryOpen = false;
  isClassificationOpen = false;
  isAccountsOpen = false;
  estimateFormGroup: FormGroup = new FormGroup<any>({});
  periods: string[] = Object.values(EstimatePeriods);
  periodDateOption: WeeklyOption | MonthlyOption;
  periodWeeklyOptions = ["mon", "tue", "wed", "thr", "fri"];
  periodMonthlyOptions: MonthlyOption[];
  periodYearlyOptions: YearlyOption[];

  protected destroy$: Subject<boolean> = new Subject<boolean>();
  protected readonly active = active;
  protected readonly EstimatePeriods = EstimatePeriods;
  private formHelper = new FormHelper();

  @ViewChild("picker") startDatePicker: MatDatepicker<any>;
  @ViewChild("endDatePicker") endDatePicker: MatDatepicker<any>;
  @ViewChild(SplitOptionsComponent) splitOptionsComponent!: SplitOptionsComponent;

  @Input() isEditMode: boolean;
  @Input() isNewGroup: boolean;
  @Input() estimateGroupId: string;
  @Input() estimate: Estimate;
  @Output() actionSucceeded = new EventEmitter<{ message: string; data: Estimate }>();
  @Output() delete = new EventEmitter<Estimate>();
  constructor(
    private dialogRef: MatDialogRef<EstimatesAddEditComponent>,
    private globalService: GlobalService,
    private estimateService: EstimateService,
    private dataRepositoryService: DataRepositoryService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private accountDialogRef: MatDialogRef<AccountAddEditComponent>,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const estimate = this.estimate || new Estimate(null);
      const estimateForm = new FormEstimate().create(estimate);
      this.estimateFormGroup = this.formBuilder.group(estimateForm);
      this.existingAccounts = await this.dataRepositoryService.getAllBooks();
      this.existingEstimates = await this.dataRepositoryService.getAllEstimates();
      this.categories = await this.dataRepositoryService.getAllCategories();
      this.classifications = await this.dataRepositoryService.getAllClassifications();

      if (this.isEditMode) {
        this.setFormValue();
      }

      this.categories.forEach((cat, index) =>
        this.estimateFormGroup.addControl(`category_${cat.id}`, new FormControl(0)),
      );

      this.classifications.forEach((cls, index) =>
        this.estimateFormGroup.addControl(`classification_${cls.id}`, new FormControl(0)),
      );
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  private getDateModal(date: number): DateModalType {
    const modal = date % 7 === 0 ? date / 7 : parseInt((date / 7).toString()) + 1;
    return modal as DateModalType;
  }

  private addRequiredValidator(controlName: string) {
    this.estimateFormGroup.get(controlName)?.setValidators(Validators.required);
    this.estimateFormGroup.get(controlName)?.updateValueAndValidity();
  }

  private removeValidators(controlName: string) {
    this.estimateFormGroup.get(controlName)?.clearValidators();
    this.estimateFormGroup.get(controlName)?.updateValueAndValidity();
  }

  private isLastDayOfMonth(date: Date): boolean {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return date.getMonth() !== nextDay.getMonth();
  }

  private getEstimateAccount(estimate?: Estimate): Book {
    const item = estimate || this.estimate;
    return this.existingAccounts.find((b) => b.id === item.accountId);
  }

  private getEstimateCategories(estimate?: Estimate): string {
    const item = estimate || this.estimate;
    let categoriesValue = "";
    for (const category of item.categories) {
      const cat = this.categories.find((c) => c.id === category.categoryId);
      this.appliedCategories.push(cat);
      categoriesValue = `${categoriesValue}, ${cat.name}(${category.weight})`;
    }
    return categoriesValue.substring(1);
  }

  private getEstimateClassifications(estimate?: Estimate): string {
    const item = estimate || this.estimate;
    let classificationsValue = "";
    this.selectedClassifications = item.classifications;
    this.selectedCategories = item.categories;
    for (const classification of item.classifications) {
      const cls = this.classifications.find((c) => c.id === classification.classificationId);
      this.appliedClassifications.push(cls);
      classificationsValue = `${classificationsValue}, ${cls.name}(${classification.weight})`;
    }
    return classificationsValue.substring(1);
  }

  private setFormValue() {
    this.selectedClassifications = this.estimate.classifications;
    this.selectedCategories = this.estimate.categories;
    const estimateAccount = this.getEstimateAccount();
    this.estimateFormGroup.controls["account"].setValue(estimateAccount);
    this.estimateFormGroup.controls["accountName"].setValue(estimateAccount.name);
    this.activeRecurringFix = this.estimate.isRecurring;
    this.selectedValueOption = this.estimate.isFixedValue ? "fixed" : "variable";
    const calculateAs = this.estimateTypes.find(
      (et) => et.key === this.estimate.transactionGenerator,
    );
    this.estimateFormGroup.controls["calculateAs"].setValue(calculateAs);

    if (this.estimateFormGroup.controls["period"].value === "Monthly") {
      this.periodMonthlyOptions = this.generatePeriodMonthlyOptions();
      const selectedOption = this.periodMonthlyOptions.find((option) => {
        const estimateMonthlyOption = this.estimate
          .periodicTransactionDatesFormula as MonthlyOption;
        return JSON.stringify(option) === JSON.stringify(estimateMonthlyOption);
      });
      this.estimateFormGroup.controls["periodDateOption"].setValue(selectedOption);
    }
  }

  private setEstimate(isRecurring: boolean, transactionGenerator: any) {
    return {
      name: this.estimateFormGroup.controls["name"].value,
      categories: this.formHelper.generateSplitCategoriesFromForm(this.estimateFormGroup),
      classifications: this.formHelper.generateSplitClassificationsFromForm(this.estimateFormGroup),
      accountId: this.estimateFormGroup.controls["account"].value.id,
      direction: this.estimateFormGroup.controls["direction"].value,
      initialValue: this.estimateFormGroup.controls["amount"].value,
      symbol: this.estimateFormGroup.controls["symbol"].value,
      startDate: this.estimateFormGroup.controls["startDate"].value,
      isRecurring,
      endDate: this.estimateFormGroup.controls["endDate"].value,
      frequency: isRecurring && this.estimateFormGroup.controls["frequency"].value,
      period: isRecurring && this.estimateFormGroup.controls["period"].value,
      isFixedValue: this.estimateFormGroup.controls["valueOption"].value === "fixed",
      transactionGenerator,
      estimateActionKeys: [transactionGenerator],
      periodicTransactionDatesFormula: this.estimateFormGroup.controls["periodDateOption"].value,
    };
  }

  private generatePeriodMonthlyOptions(): MonthlyOption[] {
    const periodMonthlyOptions: MonthlyOption[] = [];
    const startDateValue = this.estimateFormGroup.controls["startDate"].value;
    const startDate = new Date(startDateValue);
    const date: DayOfMonth = <DayOfMonth>startDate.getDate();
    if (date < 29) {
      periodMonthlyOptions.push({
        dateSpecific: { date: date },
        degreeSpecific: null,
      });
    }

    if (this.isLastDayOfMonth(startDate)) {
      periodMonthlyOptions.push({
        dateSpecific: null,
        degreeSpecific: {
          lastDayOfMonth: true,
          degree: null,
          day: null,
        },
      });
    }

    const dateModal: 1 | 2 | 3 | 4 = this.getDateModal(date);
    const dayDegree = dateModal < 5 ? dateModal : "last";
    periodMonthlyOptions.push({
      dateSpecific: null,
      degreeSpecific: {
        lastDayOfMonth: false,
        degree: dayDegree,
        day: startDate.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      },
    });

    return periodMonthlyOptions;
  }

  private generatePeriodYearlyOptions(): YearlyOption[] {
    const monthlyOptions = this.generatePeriodMonthlyOptions();
    const startDateValue = this.estimateFormGroup.controls["startDate"].value;
    const startDate = new Date(startDateValue);
    const selectedMonth = startDate.getMonth();
    return monthlyOptions.map((option) => {
      return { ...option, month: selectedMonth } as YearlyOption;
    });
  }

  openAccountSelection() {
    this.isAccountsOpen = !this.isAccountsOpen;
    this.isCategoryOpen = false;
    this.isClassificationOpen = false;

    if (!this.existingAccounts.length) {
      this.globalService.showMessage(
        "info",
        "classificationsEmpty",
        "pleaseAddSomeClassifications",
      );
    }
  }

  async deleteEstimate() {
    this.delete.emit(this.estimate);
  }

  async openAccountCreationModal(event: Event) {
    event.preventDefault();
    this.accountDialogRef = await this.dialog.open(AccountAddEditComponent, {
      width: "800px",
      disableClose: true,
      data: {
        actionSucceeded: this.accountCreated.bind(this),
      },
    });

    this.accountDialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  async accountCreated() {
    this.accountDialogRef.close();
    this.existingAccounts = await this.dataRepositoryService.getAllBooks();
    const lastAccount = this.existingAccounts[this.existingAccounts.length - 1];
    this.estimateFormGroup.controls["account"].setValue(lastAccount);
    this.estimateFormGroup.controls["accountName"].setValue(lastAccount.name);
    this.openAccountSelection();
  }

  async submit() {
    const valueType = this.estimateFormGroup.controls["valueOption"].value;
    const isCalculatedAsValid = this.estimateFormGroup.controls["calculateAs"].status === "VALID";

    if (valueType === "variable" && !isCalculatedAsValid) {
      this.globalService.showMessage("info", "missingRequiredData", "pleaseProvideRequiredData");
      return;
    }

    if (valueType === "fixed") {
      const calculateAs = this.formHelper.getCalculationMethod(this.estimateFormGroup);
      this.estimateFormGroup.controls["calculateAs"].setValue(calculateAs);
    }

    if (!this.estimateFormGroup.valid) {
      this.globalService.showMessage("info", "missingRequiredData", "pleaseProvideRequiredData");
      return;
    }

    this.loading = true;
    const transactionGenerator = this.formHelper.getTransactionGenerator(this.estimateFormGroup);
    const isRecurring = this.estimateFormGroup.controls["isRecurring"].value === "Yes";
    const estimateData = this.setEstimate(isRecurring, transactionGenerator);
    try {
      if (this.isEditMode) {
        const estimateClone = { ...estimateData, _id: this.estimate.id };
        const estimateToBeUpdated = new Estimate(new EstimateResponse(estimateClone));
        const estimate: Estimate = await this.estimateService.update(estimateToBeUpdated);
        if (estimate instanceof Estimate) {
          this.actionSucceeded.emit({ message: "updatedSuccessfully", data: estimate });
        }
      } else {
        const newEstimate = new Estimate(new EstimateResponse(estimateData));
        const createdEstimate: Estimate = await this.estimateService.create(newEstimate);
        if (createdEstimate instanceof Estimate) {
          this.actionSucceeded.emit({ message: "createdSuccessfully", data: createdEstimate });
        }
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    } finally {
      this.loading = false;
    }
  }

  handleDatePicker = () => this.startDatePicker.open();

  handleEndDatePicker = () => this.endDatePicker.open();

  handleCategories() {
    this.isCategoryOpen = !this.isCategoryOpen;
    this.isClassificationOpen = false;
    this.isAccountsOpen = false;
    if (!this.categories.length) {
      this.globalService.showMessage("info", "categoriesEmpty", "pleaseAddSomeCategories");
    }
  }

  handleClassification() {
    this.isClassificationOpen = !this.isClassificationOpen;
    this.isCategoryOpen = false;
    this.isAccountsOpen = false;
    if (!this.classifications.length) {
      this.globalService.showMessage(
        "info",
        "classificationsEmpty",
        "pleaseAddSomeClassifications",
      );
    }
  }

  handleUseEstimate(event: MatSelectChange) {
    const estimate: Estimate = event.value;
    this.selectedClassifications = estimate.classifications;
    this.selectedCategories = estimate.categories;
    this.estimateFormGroup.controls["amount"].setValue(estimate.initialValue);
    this.estimateFormGroup.controls["direction"].setValue(estimate.direction);
    this.estimateFormGroup.controls["startDate"].setValue(estimate.startDate);
    this.estimateFormGroup.controls["endDate"].setValue(estimate.endDate);
    this.estimateFormGroup.controls["frequency"].setValue(estimate.frequency);
    this.estimateFormGroup.controls["period"].setValue(estimate.period);
    this.estimateFormGroup.controls["isRecurring"].setValue(estimate.isRecurring ? "Yes" : "No");
    this.estimateFormGroup.controls["valueOption"].setValue(
      estimate.isFixedValue ? "fixed" : "variable",
    );
    this.estimateFormGroup.controls["symbol"].setValue(estimate.symbol);
    const estimateClassifications = this.getEstimateClassifications(estimate);
    const estimateCategories = this.getEstimateCategories(estimate);
    const estimateAccount = this.getEstimateAccount(estimate);
    this.estimateFormGroup.controls["categories"].setValue(estimateCategories);
    this.estimateFormGroup.controls["classifications"].setValue(estimateClassifications);
    this.estimateFormGroup.controls["account"].setValue(estimateAccount);
    this.estimateFormGroup.controls["accountName"].setValue(estimateAccount.name);
    this.activeRecurringFix = estimate.isRecurring;
    this.selectedValueOption = estimate.isFixedValue ? "fixed" : "variable";
    const calculateAs = this.estimateTypes.find((et) => et.key === estimate.transactionGenerator);
    this.estimateFormGroup.controls["calculateAs"].setValue(calculateAs);
    this.estimateFormGroup.controls["periodDateOption"].setValue(
      estimate.periodicTransactionDatesFormula,
    );

    if (this.estimateFormGroup.controls["period"].value === "Monthly") {
      this.periodMonthlyOptions = this.generatePeriodMonthlyOptions();
      const selectedOption = this.periodMonthlyOptions.find((option) => {
        const estimateMonthlyOption = estimate.periodicTransactionDatesFormula as MonthlyOption;
        return JSON.stringify(option) === JSON.stringify(estimateMonthlyOption);
      });
      this.estimateFormGroup.controls["periodDateOption"].setValue(selectedOption);
    }
  }

  handleAccountSelection(account: Book) {
    this.estimateFormGroup.controls["account"].setValue(account);
    this.estimateFormGroup.controls["accountName"].setValue(account.name);
    this.estimateFormGroup.controls["symbol"].setValue(account.currency);
    this.openAccountSelection();
  }

  handlePeriodChange(period: any) {
    if (period === EstimatePeriods.weekly) {
      const startDate = this.estimateFormGroup.controls["startDate"].value;
      const startDayValue = new Date(startDate).getDay();
      this.estimateFormGroup.controls["periodDateOption"].setValue(startDayValue);
    }

    if (period === EstimatePeriods.monthly) {
      this.periodMonthlyOptions = this.generatePeriodMonthlyOptions();
      this.estimateFormGroup.controls["periodDateOption"].setValue(this.periodMonthlyOptions[0]);
    }

    if (period === EstimatePeriods.yearly) {
      this.periodYearlyOptions = this.generatePeriodYearlyOptions();
      this.estimateFormGroup.controls["periodDateOption"].setValue(this.periodYearlyOptions[0]);
    }
  }

  handlePeriodOptionChange = (periodDateOption: any) => (this.periodDateOption = periodDateOption);

  handleValueOptionChange() {
    this.selectedValueOption = this.estimateFormGroup.controls["valueOption"].value;
    this.amountTitle = this.selectedValueOption === "fixed" ? "amount" : "startingAmount";
  }

  handleEstimateRecurringChange(recurring: any) {
    this.activeRecurringFix = recurring === "Yes";
    if (!this.activeRecurringFix) {
      this.activeRecurringVariable = false;
      this.startDateLabel = "date";
      this.removeValidators("frequency");
      this.removeValidators("period");
    } else {
      this.startDateLabel = "startDate";
      this.addRequiredValidator("frequency");
      this.addRequiredValidator("period");
    }
    this.estimateFormGroup.controls["isRecurring"].setValue(recurring);
  }

  handleCloseDialogue = () => this.dialogRef.close();

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}

import { inject, Injector } from "@angular/core";
import { InstitutionService } from "../../../DataService/institution/institution.service";
import { CalculationStoreService } from "../../../store/calculation/calculation.store.service";
import { UserStoreService } from "../../../store/user/user.store.service";
import { BalanceByAccountsSymbols } from "../../../../models/types/balanceGroupingTypes";
import { TransactionBalanceHelpers } from "../../transactionBalances/transactionBalanceHelpers";
import { AccountView } from "../../../../models/view/account/account.view";
import { DataPluginStoreService } from "@bitwarden/web-vault/app/services/store/data-plugin/data-plugin.store.service";

export class ScenarioDataAggregator {
  private injector: Injector;
  private institutionService: InstitutionService;
  private calculationStoreService: CalculationStoreService;
  private dataPluginStoreService: DataPluginStoreService;
  private userStoreService: UserStoreService;

  constructor(injector: Injector) {
    this.injector = injector;
    this.institutionService = this.injector.get(InstitutionService);
    this.calculationStoreService = this.injector.get(CalculationStoreService);
    this.userStoreService = this.injector.get(UserStoreService);
    this.dataPluginStoreService = this.injector.get(DataPluginStoreService);
  }

  async getAllScenarioAccounts(): Promise<AccountView[]> {
    const preferences = this.userStoreService.preferences.preferenceView();
    const institutions = this.userStoreService.institutions.institutionViews();
    const location = preferences.userLocation;
    return this.calculationStoreService.filteredAccounts.collection().filter((book) => {
      const bookInsto = institutions.find((inst) => inst.id === book.institutionLink.institutionId);
      return institutions.find(
        (inst) => inst.id === bookInsto.id && inst.swift.countryCode === location,
      );
    });
  }

  async getInstitutionsMasterList() {
    return await this.institutionService.getInstitutionsMasterList();
  }

  async getBookById(id: string): Promise<AccountView> {
    return this.calculationStoreService.filteredAccounts
      .collection()
      .find((account) => account.id === id);
  }

  async createStartingTransactionsFromBalance(startDate: Date, balances: BalanceByAccountsSymbols) {
    const transactionBalanceHelpers = new TransactionBalanceHelpers();
    return await transactionBalanceHelpers.createStartingTransactionsFromBalance(
      startDate,
      balances,
      this.userStoreService,
      "Starting Transactions at Anchor Point",
    );
  }

  async getInstitutionById(id: string) {
    return this.dataPluginStoreService.theInstitutionList
      .theInstitutionViews()
      .find((institution) => institution.id === id);
  }

  async getFilterInstitutionAccountById(institutionId: string, institutionAccountId: string) {
    const institution = await this.getInstitutionById(institutionId);
    return await this.institutionService.filterInstitutionAccountById(
      institutionAccountId,
      institution,
    );
  }

  async getAccountType(account: AccountView) {
    let accountType = account.name;

    if (account?.institutionLink?.institutionId) {
      const id = account.institutionLink.institutionId;
      const institutionalAccount = this.userStoreService.institutions
        .institutionViews()
        .find((i) => {
          return i.id === id && i.availableAccounts.find((a) => a.id === id);
        });
      if (institutionalAccount && institutionalAccount?.name) {
        accountType = institutionalAccount.name;
      }
    }
    return accountType;
  }
}

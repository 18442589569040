<div
  class="tw-flex tw-h-[70px] tw-items-center tw-justify-between tw-rounded-t-xl tw-bg-neutral-50 tw-px-10 xs:tw-px-5"
>
  <app-help-tooltip
    [ngClass]="{ 'tw-hidden': !enableTooltip }"
    [showHelpTooltip]="tooltip.showHelpTooltip"
    [tooltipInfo]="tooltipInfo"
    [pointer]="'modal-header'"
    (onTooltipClose)="tooltip.close()"
  ></app-help-tooltip>

  <div
    class="tw-flex tw-items-center"
    [ngClass]="{ 'tw-w-full tw-justify-center': !showBackButton }"
  >
    <span class="tw-text-2xl xs:tw-text-lg">
      {{ title }}
    </span>
    <div *ngIf="enableTooltip" title="help" (click)="tooltip.show($event)">
      <tooltip-icon
        [isClicked]="tooltip.isClicked"
        [size]="4"
        id="help-info-button-modal"
      ></tooltip-icon>
    </div>
  </div>
  <div class="tw-flex tw-gap-2">
    <button
      *ngIf="showBackButton"
      (click)="handleBack()"
      class="btn-icon"
      data-testid="back-button"
    >
      <icon [name]="'left-arrow'" [size]="7"></icon>
    </button>
    <close-button
      [class]="'icon-button'"
      [closeButtonType]="'plain'"
      [isMatDialog]="isMatDialog"
      (closeClickedEvent)="handleClose()"
    >
    </close-button>
  </div>
</div>

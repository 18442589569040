<div class="autocomplete-container" [ngClass]="{ 'same-row': options.isSameRow }">
  <label *ngIf="!options.customisedInputOption" class="autocomplete-label">{{ label }}</label>
  <mat-form-field
    appearance="fill"
    class="autocomplete-form-field"
    floatLabel="always"
    [ngClass]="{
      'mat-form-field-error': !shouldDisplayHint,
      'mat-form-field-disabled': options.isDisabled,
    }"
  >
    <input
      #inputElement
      matInput
      class="autocomplete-input"
      [placeholder]="options.placeholder"
      [type]="options.type"
      [formControl]="inputValueControl"
      (blur)="onInputBlur()"
      (keyup.enter)="onInputBlur()"
      (input)="onInput($event)"
      (focus)="onInputFocus()"
    />
    <span matSuffix *ngIf="options.suffix">{{ options.suffix }}</span>
    <svg
      matSuffix
      class="tw-h-3 tw-w-3 tw-text-primary"
      *ngIf="!options.isDisabled && inputValue && !this.options.hideClearButton"
      (click)="clearInput()"
    >
      <use xlink:href="images/sprite.svg#close" />
    </svg>
    <mat-error class="input-error tw--ml-2.5" *ngIf="!shouldDisplayHint && !isFocused">{{
      "inputRequired" | i18n
    }}</mat-error>
    <ng-content select="svg"></ng-content>
    <ng-container *ngIf="options.hint">
      <div class="tw-absolute tw-mt-9 tw-block" *ngIf="shouldDisplayHint">
        <small class="tw-opacity-60">{{ options.hint }}</small>
      </div>
    </ng-container>
  </mat-form-field>
</div>

import { ReferenceData } from "../blobby/reference-data.data";
export enum MERGE_STRATEGIES {
  "latest",
  "best",
}

// TODO @ronald - convert this to a concrete class
export abstract class ReferenceDataUtils {
  static getSelfRate(symbol: string) {
    return 1;
  }

  // TODO @ronald - clean this code
  static processNewReferences(
    importedReferenceData: ReferenceData[],
    existingReferences: ReferenceData[],
  ) {
    const newRecords: ReferenceData[] = [];

    for (let i = 0; i < importedReferenceData.length; i++) {
      const newItem = importedReferenceData[i];
      const baseSymbol = newItem.base;
      if (existingReferences.length === 0) {
        /** Add a base symbol if not in the list of symbols */
        if (!newItem.symbols[baseSymbol]) {
          Object.assign(newItem.symbols, { [baseSymbol]: this.getSelfRate(baseSymbol) });
        }

        newRecords.push(newItem);
        delete importedReferenceData[i];
      } else {
        let isExist = false;
        for (const existingItem of existingReferences) {
          if (existingItem.id === newItem.id) {
            isExist = true;
            break;
          }
        }

        if (!isExist) {
          /** Add a base symbol if not in the list of symbols */
          if (!newItem.symbols[baseSymbol]) {
            Object.assign(newItem.symbols, { [baseSymbol]: this.getSelfRate(baseSymbol) });
          }

          newRecords.push(newItem);
          delete importedReferenceData[i];
        }
      }
    }

    importedReferenceData = importedReferenceData.filter((item) => item !== undefined);
    return { newRecords, importedReferenceData };
  }

  // TODO @ronald - clean this code
  static processUpdateReferences(
    newReferences: ReferenceData[],
    existingReferences: ReferenceData[],
    strategy: MERGE_STRATEGIES = MERGE_STRATEGIES.latest,
  ) {
    const recordsUpdate: ReferenceData[] = [];

    for (const newItem of newReferences) {
      let isUpdated = false;
      let isNewCurrency;
      for (const existingItem of existingReferences) {
        const newSymbols = newItem.symbols;
        const existingSymbols = existingItem.symbols;
        if (newItem.id === existingItem.id) {
          const isSymbolsMatch =
            JSON.stringify(newItem.symbols) === JSON.stringify(existingItem.symbols);
          if (isSymbolsMatch) {
            existingReferences = existingReferences.filter((item) => existingItem.id !== item.id);
            break;
          } else {
            for (const [newKey, newValue] of Object.entries(newSymbols)) {
              for (const [key, value] of Object.entries(existingSymbols)) {
                isNewCurrency = true;
                if (newKey === key) {
                  isNewCurrency = false;
                  if (newValue === value) {
                    break;
                  } else {
                    /** If the existing rate is smaller, replace it */
                    if (strategy === MERGE_STRATEGIES.best && value < newValue) {
                      existingSymbols[key] = newValue;
                    } else if (strategy === MERGE_STRATEGIES.latest) {
                      existingSymbols[key] = newValue;
                    }
                    isUpdated = true;
                    break;
                  }
                }
              }

              if (isNewCurrency) {
                isUpdated = true;
                Object.assign(existingItem.symbols, { [newKey]: newValue });
              }
            }
          }
        }

        if (isUpdated) {
          existingItem.symbols = existingSymbols;
          recordsUpdate.push(existingItem);
          newReferences = newReferences.filter((item) => newItem.id !== item.id);
          existingReferences = existingReferences.filter((item) => existingItem.id !== item.id);
          break;
        }
      }
    }

    return recordsUpdate;
  }
}

import { StoreData } from "./store.data";
import { StringToNumberPreference } from "@bitwarden/web-vault/app/models/types/PrefereneceTypes";
import {
  CurrencyType,
  UserLocationType,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";

export type DataProviderName = "fixer" | "instrumentStore" | "basiq" | "plaid";
export type SyncStatusType = {
  startDate: string;
  endDate: string | undefined;
  symbols: string[];
};

export type SyncStatusCollectionType = Record<DataProviderName, SyncStatusType>;

export type PreferenceModelVersion = 1;
export interface PreferenceStoreModel extends StoreData {
  /**  **Version Number** **/
  v: PreferenceModelVersion;

  /** baseCurrency */
  bc: CurrencyType;
  /** timeZone */
  tz: string;
  /** dateFormat */
  df: string;
  /** mode */
  md: string;
  /** weekDayStart */
  wds: StringToNumberPreference;
  /** YearMonthStart */
  yms: StringToNumberPreference;
  /** monthDayStart */
  mds: number;
  /** syncStatus */
  ss: SyncStatusCollectionType;
  /** userLocationOptions */
  ul: UserLocationType;
}

/** Default values for the preference store model */
export const DefaultPreferenceStoreModel: PreferenceStoreModel = {
  bc: "AUD",
  tz: "",
  df: "",
  md: "",
  wds: { Monday: 1 },
  yms: { January: 1 },
  mds: 0,
  ss: {
    fixer: { startDate: "", endDate: "", symbols: [] },
    instrumentStore: { startDate: "", endDate: "", symbols: [] },
    basiq: { startDate: "", endDate: "", symbols: [] },
    plaid: { startDate: "", endDate: "", symbols: [] },
  },
  ul: "AU",
  v: 1,
  vid: "",
  dc: "",
  dm: "",
  id: "",
};

import { inject, signal, WritableSignal } from "@angular/core";
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  IMenuActionParams,
  RowClickedEvent,
} from "ag-grid-enterprise";
import { AccountTableRowGroupRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/row-group/account-table/account-table-row-group-renderer.component";
import {
  AccountTableView,
  AccountManualType,
} from "@bitwarden/web-vault/app/models/types/account.types";
import { getIcon } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/context-menu";
import { DialogFactory } from "@bitwarden/web-vault/app/components/dialog/dialogFactory";
import { AccountIconRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/account-icon-renderer/account-icon-renderer.component";
import { AccountNameRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/account-name-renderer/account-name-renderer.component";
import { AccountStatusRendererComponent } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/account-status-renderer/account-status-renderer.component";
import { TableParent } from "@bitwarden/web-vault/app/models/types/table.types";
import { SyncTableSubscription, TableSource } from "./sync-table.subscription";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { getRowClass, getRowHeight } from "@bitwarden/web-vault/app/gloss/tables/tables.utils.js";
import { MoreCellRenderer } from "@bitwarden/web-vault/app/gloss/tables/renderers/cell/end-icon-renderer/end-icon-renderer.component";

type AccountSyncTableUIData = {
  icon: string;
  status: string;
  more: string;
  type: AccountManualType;
};
type AccountSyncTableSource = TableSource & AccountSyncTableUIData;

export class AccountSyncTable extends SyncTableSubscription {
  gridApi: GridApi;
  options: GridOptions<AccountSyncTableSource>;
  accountOnDetail: WritableSignal<AccountTableView> = signal<AccountTableView>(null);
  private i18nService = inject(I18nService);
  private dialogFactory: DialogFactory;
  private selectedRowIndex: number;

  constructor(parent: TableParent) {
    super();

    this.dialogFactory = new DialogFactory();
    this.options = {
      context: { parent },
      onGridReady: (params: GridReadyEvent) => {
        this.gridApi = params.api;
        params.api.sizeColumnsToFit();
      },
      groupDisplayType: "groupRows",
      groupRowRenderer: AccountTableRowGroupRendererComponent,
      rowDragManaged: false,
      groupDefaultExpanded: -1,
      columnDefs: [
        {
          field: "icon",
          cellRenderer: AccountIconRendererComponent,
          flex: 1,
          minWidth: 30,
          maxWidth: 70,
        },
        { field: "name", cellRenderer: AccountNameRendererComponent, flex: 2 },
        { field: "status", cellRenderer: AccountStatusRendererComponent, flex: 2 },
        {
          field: "more",
          width: 10,
          onCellClicked: (params) => this.onCellClicked(params),
          cellRenderer: MoreCellRenderer,
          suppressHeaderMenuButton: true,
        },
      ],
      onRowClicked: (params) => this.onRowClicked(params),
      getRowHeight: getRowHeight,
      getRowClass: (params) => getRowClass(params, this.selectedRowIndex),
      getContextMenuItems: () => {
        return [
          {
            name: this.i18nService.t("addBalance"),
            // disabled: !isBetaUser,
            action: async (params) => {
              await this.dialogFactory.addBalanceSelected(this.getAccountView(params));
            },
            icon: getIcon("add-entry"),
          },
          {
            name: this.i18nService.t("addTransaction"),
            // disabled: !isBetaUser,
            action: async (params) => {
              await this.dialogFactory.addTransaction(this.getAccountView(params));
            },
            icon: getIcon("add"),
          },
          {
            name: this.i18nService.t("accountDetail"),
            action: (params: IMenuActionParams) => {
              this.showAccountDetail(params);
            },
            icon: getIcon("detail"),
          },
          {
            name: this.i18nService.t("delete"),
            action: (params) => {
              params.context.parent.delete(params.node.data);
            },
            icon: getIcon("trash"),
          },
        ];
      },
    };
  }

  private getAccountView(params: { node: { id: string } }) {
    return this.data[parseInt(params.node.id)].original;
  }

  private showAccountDetail(params: any) {
    this.accountOnDetail.set(params.node.data);
  }

  private onRowClicked(params: RowClickedEvent) {
    this.selectedRowIndex = params.node.rowIndex;
    this.gridApi.redrawRows();
    this.showAccountDetail(params);
  }

  private onCellClicked(params: any) {
    params.node.setSelected(true);
    const parameters = {
      x: params.event.clientX,
      y: params.event.clientY,
      rowNode: params.node,
      column: params.column,
      value: params.value,
    };
    this.gridApi.showContextMenu(parameters);
  }
}

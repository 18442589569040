import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-transaction-table-row-group-renderer",
  templateUrl: "./transaction-table-row-group-renderer.component.html",
})
export class TransactionTableRowGroupRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  isFirstLevel!: boolean;
  isSecondLevel!: boolean;
  isExpanded!: boolean;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isFirstLevel = this.params.node.level === 0;
    this.isSecondLevel = this.params.node.level === 1;
    this.isExpanded = !this.params.node.expanded;
  }

  onExpanded() {
    this.isExpanded = !this.params.node.expanded;
    this.params.node.setExpanded(this.isExpanded);
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}

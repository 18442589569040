import {
  CalculatedBalanceCollection,
  CalculatedBalanceParams,
  CalculatedBalances,
} from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { inject, signal } from "@angular/core";
import { LogLevelType } from "@bitwarden/common/enums/logLevelType";

export class BalanceByTimeCalculatedCollection extends CalculatedBalanceCollection {
  protected log: LogService = inject(LogService);

  protected type: "BalanceByTime";
  protected calculations: CalculatedBalances = new Map<string, number>();

  protected _balances = signal<Map<string, number>>(new Map());
  readonly balances = this._balances.asReadonly();

  /**
   * Uses the filteredTransactions stored on the calculation store to update
   * the calculations for balance by account
   */
  async updateCalculations(parameters: CalculatedBalanceParams): Promise<void> {
    const balancesByTime: Record<string, number> = await this.getBalanceByTime(
      parameters.webWorkerQueue,
      parameters.calculatedTransactions,
      parameters.period.startDate,
      parameters.period.endDate,
      parameters.group.granularity,
    );

    this.setBalances(balancesByTime);
  }

  getCalculations(): CalculatedBalances {
    return this.calculations;
  }

  clear() {
    this.calculations.clear();
    this._balances.set(null);
  }

  triggerChanges(): void {}

  initialize() {}

  private setBalances(balancesByAccounts: Record<string, number>): void {
    this.calculations = new Map(Object.entries(balancesByAccounts));
    this._balances.set(this.calculations);
  }

  clone(): Map<string, number> {
    return new Map(this._balances());
  }
}

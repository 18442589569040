import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { CategoryResponse } from "../response/category.response";

export class Category extends ModelValidator {
  private readonly __v = 1;
  private _id: string;
  name: string;
  generalDefault?: boolean; // when we import data and create accounts if there are no category-renderer related to transaction
  // we create a default category-renderer as account has to have at least one default category-renderer
  get id(): string {
    return this._id;
  }
  weight: number;

  constructor(response: CategoryResponse) {
    super();
    this.checkVersion(this.__v, response.__v);
    if (!response) {
      return;
    }

    this._id = response.id ? response.id : crypto.randomUUID();
    this.name = response.name;
    this.weight = response.weight;
    this.generalDefault = response.generalDefault;
  }
}

/**
 * @deprecated
 * */
export function getDefaultCategory(): Category {
  return new Category(
    new CategoryResponse({
      __v: 1,
      name: "No Category",
      _id: "noCategory",
      weight: 1,
      generalDefault: true,
    }),
  );
}

import { Book } from "../../../../models/data/blobby/book.data";
import { AccountStoreModel } from "../../../../models/store/account.store.model";

type AccountModelV1 = Omit<AccountStoreModel, "v" | "crLm"> & {
  v: 1;
};

/**
 * Transforms a Book object from version 0 format to version 1 format.
 *
 * This function takes an input Book object with version "0" also known as Blobby  and maps
 * it to a new BookStoreModel object with version 1 properties. The transformation
 * includes updating metadata (such as the version and the current date for `dm`
 * and `dc`), while preserving and relabeling relevant fields from the input object.
 *
 * @param {Book} book - The input book object in version 0 Blobby format.
 * @returns {AccountStoreModel} A new book object in version 1 format.
 */
export const book_v0_to_v1 = (book: Book): AccountModelV1 => {
  return {
    v: 1,
    vid: "",
    id: book.id,
    dm: new Date().toString(),
    dc: new Date().toString(),
    instLnk: book.institutionLink,
    curr: book.currency,
    name: book.name,
    tz: book.timezone,
    dfltCla: book.defaultClassifications,
    dfltCat: book.defaultCategories,
    bal: book.balance,
    ori: book.origin,
    bsqId: book.basiqAccountId,
    date: book.date,
    type: book.type,
    st: [],
  };
};

export const book_v1_to_v2 = (book: AccountStoreModel): AccountStoreModel => {
  return {
    ...book,
    crLm: null,
    v: 2,
  };
};

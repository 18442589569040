import { Component, inject } from "@angular/core";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";

@Component({
  selector: "app-views-customize",
  templateUrl: "./views-customize.component.html",
})
export class ViewsCustomizeComponent {
  protected calculationStoreService: CalculationStoreService = inject(CalculationStoreService);
  viewsDropdown = false;

  toggleDropdown() {
    this.viewsDropdown = !this.viewsDropdown;
  }
}

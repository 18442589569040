import { computed, inject, signal } from "@angular/core";

import { ExternalCollection, FetchParams } from "../external.collection.abstraction";
import { InstitutionView } from "../../../models/view/institution/institution.view";
import { InstitutionConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";
import { InstitutionStoreModel } from "@bitwarden/web-vault/app/models/store/institution.store.model";
import { ExternalInstitutionStoreModel } from "@bitwarden/web-vault/app/models/store/external-models/external.institution.store.model";

import { DependencySubscriber } from "@bitwarden/web-vault/app/services/store/dependency.subscriber.abstraction";
import { distinctUntilChanged, filter, Subscription } from "rxjs";
import { PreferenceView } from "@bitwarden/web-vault/app/models/view/preference/preference.view";
import { PreferenceSubscriber } from "@bitwarden/web-vault/app/services/store/data-plugin/preference.subscriber";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import {
  DefaultCurrencyPerLocation,
  UserLocationType,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";

// todo - implement CategoryView not here

interface FetchInstitutionParams extends FetchParams {
  countryCode: string;
  symbol?: string;
}

export class ExternalInstitutionsStoreCollection
  extends ExternalCollection
  implements DependencySubscriber<PreferenceView>
{
  protected log: LogService = inject(LogService);
  protected appState = inject(AppStateStoreService);
  protected dataRepositoryService: DataRepositoryService = inject(DataRepositoryService);

  private _theInstitutionViews = signal<InstitutionView[]>(null);
  readonly theInstitutionViews = this._theInstitutionViews.asReadonly();

  /** The current Fetch theInstitutionViews country code **/
  countryCode = computed(() => {
    const codeMap = new Map<string, boolean>();
    this.theInstitutionViews()?.reduce(
      (acc, item) => acc.set(item.swift.countryCode, true),
      codeMap,
    );
    return Array.from(codeMap.keys());
  });

  dependentStore: PreferenceSubscriber = new PreferenceSubscriber();
  private preferenceSubscription: Subscription = this.dependentStore
    .getObservable()
    .pipe(
      filter((x) => !!x),
      distinctUntilChanged((previous, current) => previous.userLocation === current.userLocation),
    )
    .subscribe(this.triggerChanges.bind(this));

  clear() {
    this.preferenceSubscription.unsubscribe();
    this._theInstitutionViews.set(null);
  }

  triggerChanges(): void {
    const preferenceView = this.dependentStore.getCollection();
    if (preferenceView?.userLocation) {
      this.setInstitutions(preferenceView.userLocation);
    }
  }

  private setInstitutions(countryCode: UserLocationType) {
    const symbol = DefaultCurrencyPerLocation[countryCode];
    const params = { countryCode: countryCode.toString(), symbol: symbol };
    this.fetch(params).then(() => this.log.info("New institution List Fetched"));
  }

  async fetch(params: FetchInstitutionParams): Promise<InstitutionView[]> {
    this.appState.startProcess("fetching-institutions", "Fetching Institutions...");
    const institutionConfig = process.env.INSTITUTIONS as unknown as InstitutionConfigType;
    const payload = institutionConfig.endpoint.institutions.includes("v2")
      ? `${institutionConfig.endpoint.institutions}/${params.countryCode}`
      : `${institutionConfig.endpoint.institutions}`;

    const response = await this.dataRepositoryService.send(
      "GET",
      payload,
      null,
      true,
      true,
      `${institutionConfig.url}/${institutionConfig.apiStage}`,
    );

    const institutionViews = this.mapInstitutions(response.institutions, params.symbol);
    this._theInstitutionViews.set(institutionViews);
    this.appState.endProcess("fetching-institutions");
    return institutionViews;
  }

  /** This is public because we use it in institution.service.ts*/
  mapInstitutions(
    fetchedInstitutions: ExternalInstitutionStoreModel[],
    defaultSymbol: string,
  ): InstitutionView[] {
    return fetchedInstitutions.map((institution) => {
      const institutionStoreModel = this.mapToModel(institution, defaultSymbol);
      return this.mapToView(institutionStoreModel);
    });
  }

  protected mapToView(data: any): InstitutionView {
    return new InstitutionView(data);
  }

  protected mapToModel(
    fetchedInstitution: ExternalInstitutionStoreModel,
    defaultSymbol = "",
  ): InstitutionStoreModel {
    return {
      name: fetchedInstitution._name,
      swt: {
        bac: fetchedInstitution._swift.bankCode,
        cc: fetchedInstitution._swift.countryCode,
        lc: fetchedInstitution._swift.locationCode,
        brc: fetchedInstitution._swift.branchCode || "",
      },
      bic: {
        bc: fetchedInstitution._bic.bankCode,
        cc: fetchedInstitution._bic.countryCode,
        lc: fetchedInstitution._bic.locationCode,
      },
      fk: null,
      avaAcc: fetchedInstitution._availableAccounts.map((account) => ({
        id: account.id,
        nm: account.name,
        istNm: fetchedInstitution._name,
        intRts: account.interestRates.map((rate) => ({
          rn: rate.range,
          ba: rate.banded,
          ra: rate.rate,
          sy: defaultSymbol,
          lu: rate.last_updated,
          cid: rate.condition_intrinsic_desc,
          cad: rate.condition_action_desc,
        })),
        ufsbr: account.useForScenarioBestRate.toString(),
        lk: account.link,
      })),
      dc: "",
      dm: "",
      id: fetchedInstitution._id.toString(),
      v: 1 /** @Sinan Need to get the version from the model itself*/,
      vid: "",
    };
  }
}

import { SyncStatusAction, SyncStatusKey } from "../../types/general-types";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import {
  DefaultSyncStatusStoreModel,
  SyncStatusModelVersion,
  SyncStatusStoreModel,
} from "../../store/sync-status.store.model";
import { syncStates } from "./sync-status.constant";

export class SyncStatusView extends ModelView<SyncStatusStoreModel> {
  protected version: SyncStatusModelVersion;

  key: SyncStatusKey;
  accountId: string;
  messageKey: string;
  icon: string;
  actions: SyncStatusAction[];
  isDeleted: boolean;

  constructor(model?: SyncStatusStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.key = model.key;
      this.accountId = model.acId;
      this.messageKey = syncStates[model.key]?.messageKey;
      this.icon = syncStates[model.key]?.icon;
      this.actions = syncStates[model.key]?.actions;
    } else {
      this.version = DefaultSyncStatusStoreModel.v;
    }
  }

  toStoreModel(): SyncStatusStoreModel {
    return {
      key: this.key,
      acId: this.accountId,
      dc: this.dateCreated.toISOString(),
      dm: this.dateModified.toISOString(),
      id: this.id,
      v: this.version,
      vid: this.vaultId,
      del: this.isDeleted,
    };
  }

  clone(): SyncStatusView {
    return new SyncStatusView(this.toStoreModel());
  }
}

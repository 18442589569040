import { LogService } from "@bitwarden/common/src/abstractions/log.service";

export abstract class CalculatedStoreAbstraction {
  /** Store need implement Log **/
  protected abstract log: LogService;

  /** Store required method **/
  abstract clearStore(): void;
  abstract initialize(): void;
}

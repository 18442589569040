import { ParserFunctionType } from "../parser.type";
import { StoreData } from "../../../../models/store/store.data";
import { ParserAbstraction } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/parser.abstraction";

export class NoopParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    return noopParser;
  }
  override isSupportedVersion(version: number): boolean {
    return true;
  }
  override getSupportedVersion(): number[] {
    return [];
  }
}

const noopParser = (obj: any): StoreData => obj as StoreData;

export class HelperExpand {
  private _isExpanded = false;
  icon = "maximize";

  private setIcon() {
    this.icon = !this.isExpanded ? "maximize" : "minimize";
  }

  toggleContent(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.isExpanded = !this.isExpanded;
    this.setIcon();
  }

  setExpanded(value: boolean) {
    this.isExpanded = value;
    this.setIcon();
  }

  set isExpanded(value: boolean) {
    this._isExpanded = value;
    this.setIcon();
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }
}

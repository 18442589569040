import {
  interestCreation,
  transactionTransfer,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import {
  EstimateActionProperties,
  ScenarioHelpInfo,
  ScenarioPermutation,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { InterestRateScenarioOption } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/interest-rate-scenario-option";

export class BestExistingInterestRate extends InterestRateScenarioOption {
  /**
   * createPermutations - Create the different possible sets of permutations to run
   *                      By default, this should only run the ones from blobby so that it will
   *                      work for user generated scenarios eventually
   */
  async createPermutations() {
    this.permutations = [];

    if (!this.hasPositiveAccounts) {
      return;
    }

    const symbol = this.scenarioGroup.symbol;
    // should return an array of multiple interestActions for anything that currently has
    // AUD in it from an institution that has

    if (this.startingAccountBalances) {
      const possibleAccounts = await this.util.getAllScenarioAccounts();

      for (const newAccount of possibleAccounts) {
        const scenarioPermutation: ScenarioPermutation = { estimateActions: [] };
        const helpInfo: ScenarioHelpInfo = { symbol };

        // check for the account type being one with interest rates
        let interestRates = await this.util.getInterestRates(newAccount, symbol);

        if (
          (!interestRates || interestRates.length === 0) &&
          this.startingAccountBalances[newAccount.id]?.[symbol] > 0
        ) {
          interestRates = [
            {
              rate: 0,
              symbol: symbol,
              banded: false,
              range: -1,
              last_updated: "",
              condition_intrinsic_desc: "",
              condition_action_desc: "",
            },
          ];
        }
        const toAccountUrl: string = await this.util.getAccountUrl(newAccount);
        const toInstitutionName: string = await this.util.getInstitutionName(newAccount);

        if (interestRates && interestRates.length > 0) {
          for (const oldAccountID in this.startingAccountBalances) {
            // don't use the account if it's the same one
            if (oldAccountID === newAccount.id) {
              continue;
            }
            const oldAccount = await this.util.getBookById(oldAccountID);
            const oldAccountSymbolValue = this.util.getSymbolValue(oldAccountID, symbol, this);
            const creditAccount = this.util.isCredit(oldAccount);
            const fromInterestRate = await this.util.getInterestRates(oldAccount, symbol);
            const fromAccountUrl: string = await this.util.getAccountUrl(oldAccount);
            const fromInstitutionName: string = await this.util.getInstitutionName(oldAccount);

            // create a transfer estimate action to move the symbol to the new account
            if (oldAccount && !creditAccount && oldAccountSymbolValue > 0) {
              const transferParameters = this.util.createTransferParameters(
                oldAccount,
                newAccount,
                oldAccountSymbolValue,
                symbol,
                this,
              );
              await this.util.addToHelpFromTransferParameters(
                helpInfo,
                transferParameters,
                interestRates,
                fromInterestRate,
                toAccountUrl,
                fromAccountUrl,
                toInstitutionName,
                fromInstitutionName,
              );
              const permutation: EstimateActionProperties = {
                parameters: transferParameters,
                estimateActionType: transactionTransfer,
              };
              scenarioPermutation.estimateActions.push(permutation);
            }
          }

          // create an interest estimate action to create interest transactions
          const interestParameters = this.util.createInterestParameters(
            newAccount,
            interestRates,
            this,
          );
          const permutation: EstimateActionProperties = {
            parameters: interestParameters,
            estimateActionType: interestCreation,
          };
          scenarioPermutation.estimateActions.push(permutation);
        }
        scenarioPermutation.scenarioHelpInfo = helpInfo;
        this.permutations.push(scenarioPermutation);
      }
    }
  }
}

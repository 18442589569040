<form name="symbolForm">
  <div class="tw-flex tw-flex-col">
    <app-gloss-input [options]="symbolForm.nameInputOptions" class="tw-mb-6"></app-gloss-input>

    <div class="tw-flex tw-items-center tw-justify-between tw-w-full tw-mb-4">
      <app-gloss-input
        [options]="symbolForm.priceInputOptions"
        class="tw-w-1/2 tw-mr-2"
      ></app-gloss-input>
      <app-autocomplete
        [disabled]="isEditMode"
        [label]="'selectCurrency' | i18n"
        [items]="AllowedCurrencies"
        [preselectedValue]="symbolForm.formGroup.get('currency').value"
        (selectedItem)="symbolForm.selectCurrency($event)"
        class="tw-w-1/2"
      >
      </app-autocomplete>
    </div>

    <span class="tw-mb-2">{{ "asOnDate" | i18n }}</span>
    <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
      <app-date-picker
        (changeDateEvent)="symbolForm.onDateChange($event)"
        [dateText]="symbolForm.formGroup.get('date').value"
        class="tw-w-1/2"
      >
      </app-date-picker>
    </div>

    <modal-footer [isFullWidth]="isEditMode">
      <app-gloss-button
        [options]="symbolForm.saveButtonOptions"
        class="tw-w-full"
      ></app-gloss-button>
    </modal-footer>
  </div>
</form>

import { inject, Injectable } from "@angular/core";

import { SourceTransaction } from "../../../models/data/blobby/source-transaction.data";
import { GlossEncryptedDataType } from "../../../models/enum/glossEncryptedDataType";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { DataServiceAbstraction } from "../data.service.abstraction";
import { ImportStoreService } from "@bitwarden/web-vault/app/services/store/import/import.store.service";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { SourceTransactionsView } from "@bitwarden/web-vault/app/models/view/source-transactions/source-transactions.view";

@Injectable({
  providedIn: "root",
})
export class SourceTransactionService implements DataServiceAbstraction {
  importStore = inject(ImportStoreService);

  constructor(private dataRepositoryService: DataRepositoryService) {}

  create(item: GlossEncryptedDataType): Promise<GlossEncryptedDataType> {
    return Promise.resolve(undefined);
  }

  delete(item: GlossEncryptedDataType, triggerObservable: boolean): Promise<boolean> {
    return Promise.resolve(false);
  }

  async get(sourceTransactionId: string): Promise<SourceTransaction> {
    const sourceTransactions = await this.getAll();
    return sourceTransactions.find((source) => source.id === sourceTransactionId);
  }

  /**
   * @deprecated
   */
  async getAll(): Promise<SourceTransaction[]> {
    return await this.dataRepositoryService.getAllSourceTransactions();
  }

  /**
   * @deprecated
   */
  update(
    item: GlossEncryptedDataType,
    triggerObservable: boolean,
  ): Promise<GlossEncryptedDataType> {
    return Promise.resolve(undefined);
  }

  async bulkDeleteSourceTransactions(transactions: TransactionView[]) {
    const allSourceTransactions = this.importStore.sourceTransaction.sourceTransactionViews();
    const sourceTransactions: SourceTransactionsView[] = [];

    transactions.forEach((t) => {
      const source = allSourceTransactions.find((st) => st.id === t.sourceLink.id);

      if (source) {
        sourceTransactions.push(source);
      }
    });
    if (sourceTransactions.length) {
      return await this.importStore.sourceTransaction.deleteFromVault(sourceTransactions);
    }
  }
}

import { BaseResponse } from "@bitwarden/common/models/response/base.response";
import { EstimateActionsEnum } from "@bitwarden/web-vault/app/models/enum/estimate-actions.enum";
import {
  EstimatePeriod,
  MonthlyOption,
  WeeklyOption,
} from "@bitwarden/web-vault/app/models/enum/estimateType";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";

import { EstimateActionData } from "../blobby/estimate.action.data";

export class EstimateResponse extends BaseResponse {
  __v: number;
  id: string;
  name: string;
  estimateActionKeys: EstimateActionsEnum[]; /** instead of saving estimate actions in memory. we just save the keys for them and create them on the fly */
  estimateActions: EstimateActionData[];
  categories: SplitCategoryType[];
  classifications: SplitClassificationType[];
  accountId: string;
  direction: TransactionDirection;
  period: EstimatePeriod;
  initialValue: number; /** The value that estimate will generate transactions of off. */
  symbol: string;
  dateCreated: Date;
  dateUpdated: Date;
  startDate: Date;
  endDate: Date;
  periodicTransactionDatesFormula: WeeklyOption | MonthlyOption;
  isRecurring: boolean;
  isFixedValue: boolean;
  frequency: number;
  transactionGenerator: EstimateActionsEnum; /** Used to detect the right estimateAction to run to generate transactions */
  autoGenerated: boolean; /*TODO :  what does this mean @Sinan */

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    this.name = this.getResponseProperty("name");
    this.estimateActionKeys = this.getResponseProperty("estimateActionKeys");
    this.estimateActions = this.getResponseProperty("estimateActions");
    this.categories = this.getResponseProperty("categories");
    this.classifications = this.getResponseProperty("classifications");
    this.accountId = this.getResponseProperty("accountId");
    this.direction = this.getResponseProperty("direction");
    this.period = this.getResponseProperty("period");
    this.initialValue = this.getResponseProperty("initialValue");
    this.symbol = this.getResponseProperty("symbol");
    this.dateCreated = this.getResponseProperty("dateCreated");
    this.dateUpdated = this.getResponseProperty("dateUpdated");
    this.startDate = this.getResponseProperty("startDate");
    this.endDate = this.getResponseProperty("endDate");
    this.periodicTransactionDatesFormula = this.getResponseProperty(
      "periodicTransactionDatesFormula",
    );
    this.isRecurring = this.getResponseProperty("isRecurring");
    this.isFixedValue = this.getResponseProperty("isFixedValue");
    this.frequency = this.getResponseProperty("frequency");
    this.transactionGenerator = this.getResponseProperty("transactionGenerator");
    this.autoGenerated = this.getResponseProperty("autoGenerated")
      ? this.getResponseProperty("autoGenerated")
      : false;
  }
}

import { StoreModelNames } from "../../services/dali/type/dali.type";
import { StoreModel } from "../../services/dali/vault-parser/parser.type";
import { DefaultAccountStoreModel } from "./account.store.model";
import { DefaultInstitutionStoreModel } from "./institution.store.model";
import { DefaultStoreData } from "./store.data";
import { DefaultVaultFileStoreModel } from "./vault-file.store.model";
import { DefaultWizardStoreModel } from "./wizard.store.model";
import { DefaultTransactionStoreModel } from "./transaction.store.model";
import { DefaultSourceTransactionStoreModel } from "./source-transaction.store.model";
import { DefaultCategoryStoreModel } from "./category.store.model";
import { DefaultClassificationStoreModel } from "./classification.store.model";
import { DefaultSourceCategoryStoreModel } from "./source-category.store.model";
import { DefaultSourceBookStoreModel } from "./source-account-store.model";
import { DefaultConnectorStoreModel } from "./connector.store.model";
import { DefaultScenarioGroupStoreModel } from "./scenario-group.store.model";
import { DefaultScenarioStoreModel } from "./scenario.store.model";
import { DefaultReferenceDataStoreModel } from "./reference-data.store.model";
import { DefaultPreferenceStoreModel } from "./preference.store.model";
import { DefaultEstimateStoreModel } from "./estimate.store.model";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { inject } from "@angular/core";

export class ModelValidator {
  protected log: LogService = inject(LogService);

  assertModel(modelName: StoreModelNames, model: StoreModel): boolean {
    const defaultModel = this.getModelStructure(modelName);
    const difference = this.compareModels(defaultModel, model);
    if (difference.length > 0) {
      this.log.warning(
        `Model ${modelName} does not match the expected structure: ${difference.join(", ")}`,
      );
    }

    return difference.length === 0;
  }

  private getModelStructure(modelName: StoreModelNames): StoreModel {
    switch (modelName) {
      case "AccountStoreModel":
        return DefaultAccountStoreModel;
      case "InstitutionStoreModel":
        return DefaultInstitutionStoreModel;
      case "VaultFileStoreModel":
        return DefaultVaultFileStoreModel;
      case "WizardStoreModel":
        return DefaultWizardStoreModel;
      case "TransactionStoreModel":
        return DefaultTransactionStoreModel;
      case "CategoryStoreModel":
        return DefaultCategoryStoreModel;
      case "SourceTransactionStoreModel":
        return DefaultSourceTransactionStoreModel;
      case "SourceCategoryStoreModel":
        return DefaultSourceCategoryStoreModel;
      case "SourceBookStoreModel":
        return DefaultSourceBookStoreModel;
      case "ClassificationStoreModel":
        return DefaultClassificationStoreModel;
      case "ConnectorStoreModel":
        return DefaultConnectorStoreModel;
      case "ScenarioGroupStoreModel":
        return DefaultScenarioGroupStoreModel;
      case "ScenarioStoreModel":
        return DefaultScenarioStoreModel;
      case "ReferenceDataStoreModel":
        return DefaultReferenceDataStoreModel;
      case "EstimateStoreModel":
        return DefaultEstimateStoreModel;
      case "PreferenceStoreModel":
        return DefaultPreferenceStoreModel;
      default:
        return DefaultStoreData;
    }
  }

  private compareModels(defaultModel: StoreModel, model: Record<any, any>): string[] {
    if (typeof model !== "object") {
      return Object.keys(defaultModel);
    }

    return this.compareObject(defaultModel, model);
  }

  compareObject(expected: Record<any, any>, model: Record<any, any>): string[] {
    let difference: string[] = [];

    if (typeof model !== "undefined" && model !== null) {
      return difference;
    }

    try {
      for (const [expectedKey, expectedValue] of Object.entries(expected)) {
        if (!Object.prototype.hasOwnProperty.call(model, expectedKey)) {
          difference.push(expectedKey);
        } else {
          if (expectedValue && typeof expectedValue == "object" && !Array.isArray(expectedValue)) {
            difference = [...difference, ...this.compareObject(expectedValue, model[expectedKey])];
          }
        }
      }
    } catch (error) {
      this.log.error("Validation encounter critical error. Data field processed will be ignored");
      this.log.info(`Model: ${model}  Expected: ${expected}`);
      this.log.error(error);
    }

    return difference;
  }
}

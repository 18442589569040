import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "@bitwarden/angular/guards/auth.guard";
import { InitialRedirectGuard } from "@bitwarden/angular/guards/initialRoute.guard";
import { LockGuard } from "@bitwarden/angular/guards/lock.guard";
import { UnauthGuard } from "@bitwarden/angular/guards/unauth.guard";
import { PlanComponent } from "@bitwarden/web-vault/app/components/paywall/plan/plan.component";
import { CabinetComponent } from "@bitwarden/web-vault/app/gloss/cabinet/cabinet.component";
import { TransactionFormComponent } from "@bitwarden/web-vault/app/gloss/dash/transactions-form/transaction-form.component";
import { EstimatesComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates.component";
import { ManageAccountComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/manage-account.component";
import { SymbolComponent } from "@bitwarden/web-vault/app/gloss/manage/symbol/symbol.component";
import { ManageCategoriesComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-categories/manage-categories.component";
import { ManageClassificationsComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-classifications/manage-classifications.component";
import { ManageInstitutionsComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-institutions/manage-institutions.component";
import { ManagePreferencesComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-preferences/manage-preferences.component";
import { ViewsComponent } from "@bitwarden/web-vault/app/gloss/views/views.component";
import {
  GlossRouteEnum,
  GlossRoutes,
  MainSettingsRoute,
} from "@bitwarden/web-vault/app/models/enum/routes.enum";
import { AdvancedFeaturesComponent } from "@bitwarden/web-vault/app/settings/advanced-features/advanced-features.component";
import { ManageSecurityComponent } from "@bitwarden/web-vault/app/settings/manage-security/manage-security.component";
import { SecurityRoutingModule } from "@bitwarden/web-vault/app/settings/manage-security/security-routing.module";
import { ProfileComponent } from "@bitwarden/web-vault/app/settings/profile.component";
import { SettingsRouteComponent } from "@bitwarden/web-vault/app/settings/settings-route.component";
import { SettingsComponent } from "@bitwarden/web-vault/app/settings/settings.component";
import { SubscriptionRoutingModule } from "@bitwarden/web-vault/app/settings/subscription-routing.module";
import { SubscriptionComponent } from "@bitwarden/web-vault/app/settings/subscription.component";
import { UserAccessPageComponent } from "@bitwarden/web-vault/app/settings/user-access/user-access-page.component";

import { HintComponent } from "./accounts/hint.component";
import { LockComponent } from "./accounts/lock.component";
import { LoginWithDeviceComponent } from "./accounts/login/login-with-device.component";
import { LoginComponent } from "./accounts/login/login.component";
import { RecoverDeleteComponent } from "./accounts/recover-delete.component";
import { RecoverTwoFactorComponent } from "./accounts/recover-two-factor.component";
import { RegisterFormComponent } from "./accounts/register-form/register-form.component";
import { RemovePasswordComponent } from "./accounts/remove-password.component";
import { SetPasswordComponent } from "./accounts/set-password.component";
import { TwoFactorComponent } from "./accounts/two-factor.component";
import { UpdatePasswordComponent } from "./accounts/update-password.component";
import { VerifyEmailTokenComponent } from "./accounts/verify-email-token.component";
import { MemberRegisterComponents } from "./components/paywall/register/member-register.components";
import { DashPrimaryComponent } from "./gloss/dashboard-primary/dash-primary.component";
import { HomeGuard } from "./guards/home.guard";
import { FrontendLayoutComponent } from "./layouts/frontend-layout.component";
import { UserLayoutComponent } from "./layouts/user-layout.component";
import { GeneratorComponent } from "./tools/generator.component";
import { ToolsComponent } from "./tools/tools.component";
import { VaultModule } from "./vault/vault.module";

const routes: Routes = [
  {
    path: "",
    component: FrontendLayoutComponent,
    data: { doNotSaveUrl: true },
    children: [
      {
        path: "",
        pathMatch: "full",
        children: [], // Children lets us have an empty component.
        canActivate: [HomeGuard], // Redirects either to views, login or lock page.
      },
      { path: "login", component: LoginComponent, canActivate: [UnauthGuard] },
      { path: "membership-plan", component: PlanComponent, canActivate: [UnauthGuard] },
      { path: "register-member", component: MemberRegisterComponents, canActivate: [UnauthGuard] },
      {
        path: "login-with-device",
        component: LoginWithDeviceComponent,
        data: { titleId: "loginWithDevice" },
      },
      {
        path: "register",
        component: RegisterFormComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "createAccount" },
      },
      { path: "2fa", component: TwoFactorComponent, canActivate: [UnauthGuard] },
      {
        path: "trial",
        redirectTo: "register",
        pathMatch: "full",
      },
      {
        path: "set-password",
        component: SetPasswordComponent,
        data: { titleId: "setMasterPassword" },
      },
      {
        path: "hint",
        component: HintComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "passwordHint" },
      },
      {
        path: "lock",
        component: LockComponent,
        canActivate: [LockGuard],
      },
      { path: "verify-email", component: VerifyEmailTokenComponent },
      { path: "recover", pathMatch: "full", redirectTo: "recover-2fa" },
      {
        path: "recover-2fa",
        component: RecoverTwoFactorComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "recoverAccountTwoStep" },
      },
      {
        path: "recover-delete",
        component: RecoverDeleteComponent,
        canActivate: [UnauthGuard],
        data: { titleId: "deleteAccount" },
      },
      {
        path: "update-password",
        component: UpdatePasswordComponent,
        canActivate: [AuthGuard],
        data: { titleId: "updatePassword" },
      },
      {
        path: "remove-password",
        component: RemovePasswordComponent,
        canActivate: [AuthGuard],
        data: { titleId: "removeMasterPassword" },
      },
    ],
  },
  {
    path: "",
    component: UserLayoutComponent,
    canActivate: [AuthGuard, InitialRedirectGuard],
    children: [
      {
        path: "vault",
        loadChildren: () => VaultModule,
      },
      { path: GlossRoutes.Estimates, component: EstimatesComponent },
      {
        path: "views",
        component: ViewsComponent,
      },
      {
        path: GlossRoutes.Dashboard,
        component: DashPrimaryComponent,
      },
      { path: GlossRoutes.Cabinet, component: CabinetComponent },
      { path: GlossRoutes.Symbol, component: SymbolComponent },
      { path: GlossRoutes.Import, component: ManageInstitutionsComponent },
      { path: GlossRoutes.Reference, component: ManagePreferencesComponent },
      { path: "add-edit-transaction", component: TransactionFormComponent },
      {
        path: "manage",
        children: [
          { path: "", pathMatch: "full", redirectTo: GlossRouteEnum.account },
          {
            path: GlossRoutes.Account,
            component: ManageAccountComponent,
          },
          {
            path: GlossRoutes.Classifications,
            component: ManageClassificationsComponent,
          },
          {
            path: GlossRoutes.Categories,
            component: ManageCategoriesComponent,
          },
          {
            path: GlossRoutes.Institutions,
            component: ManageInstitutionsComponent,
          },
          {
            path: GlossRoutes.Preferences,
            component: ManagePreferencesComponent,
          },
        ],
      },
      {
        path: GlossRoutes.Settings,
        component: SettingsComponent,
      },
      {
        path: GlossRoutes.Settings,
        component: SettingsRouteComponent,
        children: [
          { path: "", pathMatch: "full", redirectTo: GlossRoutes.Settings },
          {
            path: MainSettingsRoute.Profile,
            component: ProfileComponent,
            data: { titleId: "profile" },
          },
          {
            path: MainSettingsRoute.Preferences,
            component: ManagePreferencesComponent,
            data: { titleId: "preferences" },
          },
          {
            path: MainSettingsRoute.Security,
            component: ManageSecurityComponent,
            data: { titleId: "security" },
          },
          {
            path: MainSettingsRoute.AdvancedFeatures,
            component: AdvancedFeaturesComponent,
            data: { titleId: "advancedFeatures" },
          },
          {
            path: MainSettingsRoute.Access,
            component: UserAccessPageComponent,
            data: { titleId: "access" },
          },
          { path: "subscription", component: SubscriptionComponent },
          {
            path: "subscription",
            loadChildren: () => SubscriptionRoutingModule,
          },
        ],
      },
      {
        path: `${GlossRoutes.Settings}/` + MainSettingsRoute.Security,
        loadChildren: () => SecurityRoutingModule,
      },
      {
        path: "tools",
        component: ToolsComponent,
        canActivate: [AuthGuard],
        children: [
          { path: "", pathMatch: "full", redirectTo: "generator" },
          {
            path: "",
            loadChildren: () =>
              import("./tools/import-export/import-export.module").then(
                (m) => m.ImportExportModule,
              ),
          },
          {
            path: "generator",
            component: GeneratorComponent,
            data: { titleId: "generator" },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      paramsInheritanceStrategy: "always",
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class OssRoutingModule {}

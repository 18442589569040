import { TransactionView } from "../../models/view/transaction/transaction.view";

export const getDailyBalance = (
  transactionsView: Array<TransactionView>,
  transactionBalances: Record<string, number>,
) => {
  const transactionView: TransactionView[] = [];

  for (let i = 0; i < transactionsView.length; i++) {
    const currentTrans = transactionsView[i];
    const transactionID = currentTrans.id;

    if (transactionBalances?.[transactionID]) {
      currentTrans.dailyBalance = transactionBalances[transactionID];
    }
    transactionView.push(currentTrans);
  }
  return transactionView;
};

import { StateProcess } from "./state-process";

/**
 * @tag vault-loading : System is loading the vault content and decrypting
 * @tag calculation : Calculation store is running calculation update
 * @tag enrichment : Transaction Store is updateing valuation and normalisation
 * @tag fetching-data: http fetch in progress
 * @tag importing-data: a process is importing new data ( e.g. basiq )
 * @tag syncing-data: vault data is being sync and enc with the server.
 */
export type ProcessType =
  | "vault-loading"
  | "store-initialisation"
  | "balance-calculation"
  | "revaluation"
  | "normalisation"
  | "enrichment"
  | "fetching-data"
  | "fetching-forex"
  | "fetching-institutions"
  | "fetching-country"
  | "importing-data"
  | "syncing-data"
  | "scenario";

/**
 * Goal is to have a way to multi-thread async process and combine them in a loading state.
 * For the moment we define general step and object the granularity could be improved as the needs growth
 */
export class StateProcessFactory {
  static create(type: ProcessType) {
    switch (type) {
      /** System init **/
      case "vault-loading":
        return new StateProcess(type, "system", "decrypting", "data");
      case "store-initialisation":
        return new StateProcess(type, "system", "initialising", "data");

      /** transaction level information **/
      case "revaluation":
      case "normalisation":
        // this should be split in valuation and normalization in the future
        return new StateProcess(type, "calculation", "generating", "valuation");

      /** allocation number and c_ prop **/
      case "enrichment":
        return new StateProcess(type, "calculation", "generating", "data");

      /** Balance calculation **/
      case "balance-calculation":
        return new StateProcess(type, "calculation", "generating", "balance");

      case "fetching-data":
      case "fetching-forex":
      case "fetching-institutions":
      case "fetching-country":
        return new StateProcess(type, "data", "fetching", "data");

      case "importing-data":
        return new StateProcess(type, "data", "fetching", "data");

      case "syncing-data":
        return new StateProcess(type, "data", "syncing", "data");

      /** Scenario calculation **/
      case "scenario":
        return new StateProcess(type, "data", "generating", "scenario");
    }
  }
}

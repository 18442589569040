import { inject } from "@angular/core";
import { WorkerMessage } from "@bitwarden/web-vault/web-worker/worker.message";
import { TransactionBalancesWorkerMessage } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.message";
import { TransactionBalancesWorkerResult } from "@bitwarden/web-vault/app/services/web-worker/transaction-balances/transaction-balances.worker.result";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import {
  FlattenedTransactions,
  FlattenedTransactionsByAccount,
  FlattenedTransactionsByAccountSymbol,
} from "@bitwarden/web-vault/app/models/types/balance.types";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { BalanceDatesUtils } from "@bitwarden/web-vault/app/services/store/calculation/balances/balanceDates.utils";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { getTransactionAmount } from "@bitwarden/web-vault/app/shared/utils/helper.transactionView/amount";

export abstract class BalanceCalculatedUtils {
  protected userStore = inject(UserStoreService);

  async getBalanceByAccount(
    webWorkerQueue: WebWorkerQueue,
    transactions: TransactionView[],
    balanceDate: Date,
  ): Promise<Record<string, number>> {
    // sort the balancedTransactions into accounts by the normalized value
    const sortedAccountTransactions = BalanceCalculatedUtils.sortTransactionsByAccounts(
      transactions,
      true,
    );

    const balanceTimestamp = BalanceDatesUtils.dateToTimestamp(balanceDate);

    const balanceByAccounts: Record<string, number> = {};
    const promises: Promise<WorkerMessage>[] = [];

    for (const accountId in sortedAccountTransactions) {
      const transactionDates = sortedAccountTransactions[accountId].transactionDates;
      const transactionsAmounts = sortedAccountTransactions[accountId].transactionAmounts;

      const transactionDatesBuffer = new Uint32Array(transactionDates);
      const transactionAmountsBuffer = new Float64Array(transactionsAmounts);

      const balanceRequest = new TransactionBalancesWorkerMessage(
        "getBalanceByAccount",
        "getAccountBalances",
        transactionDatesBuffer.buffer,
        transactionAmountsBuffer.buffer,
        0,
        new Uint32Array([balanceTimestamp]).buffer,
      );

      promises.push(
        webWorkerQueue.postMessagePromise(balanceRequest).then(
          (workerMessage: TransactionBalancesWorkerResult) => {
            if (workerMessage?.balanceAmounts) {
              const balanceArray = new Float64Array(workerMessage.balanceAmounts);
              if (balanceArray.length > 0) {
                balanceByAccounts[accountId] = balanceArray[0];
              }
            }
            return Promise.resolve(workerMessage);
          },
          (error: Error) => {
            return Promise.reject(error);
          },
        ),
      );
    }

    await Promise.all(promises);
    return balanceByAccounts;
  }

  async getBalanceByTime(
    webWorkerQueue: WebWorkerQueue,
    transactions: TransactionView[],
    startDate: Date,
    endDate: Date,
    granularity: GranularityProperty,
  ): Promise<Record<string, number>> {
    const balancesByTime: Record<string, number> = {};
    const balanceDates = BalanceDatesUtils.getBalanceDates(
      startDate,
      endDate,
      granularity,
      this.userStore,
    );
    const balanceDatesArray = new Uint32Array(balanceDates);

    const flattenedTransactions = this.buildTransactionArrays(transactions, true);

    const transactionDates = new Uint32Array(flattenedTransactions.transactionDates);
    const transactionAmounts = new Float64Array(flattenedTransactions.transactionAmounts);

    const balanceRequest = new TransactionBalancesWorkerMessage(
      "getBalanceByTime",
      "getBalances",
      transactionDates.buffer,
      transactionAmounts.buffer,
      0,
      balanceDatesArray.buffer,
    );

    await webWorkerQueue.postMessagePromise(balanceRequest).then(
      (workerMessage: TransactionBalancesWorkerResult) => {
        if (workerMessage?.balanceAmounts) {
          const balanceAmounts = new Float64Array(workerMessage.balanceAmounts);
          const balanceDates = new Uint32Array(workerMessage.balanceDates);

          for (let i = 0; i < balanceDates.length; i++) {
            balancesByTime[balanceDates[i]] = balanceAmounts[i];
          }
        }
      },
      (error: Error) => {
        return Promise.reject(error);
      },
    );

    return balancesByTime;
  }

  async getBalanceByTimeAccounts(
    webWorkerQueue: WebWorkerQueue,
    transactions: TransactionView[],
    startDate: Date,
    endDate: Date,
    granularity: GranularityProperty,
  ): Promise<Record<string, Record<string, number>>> {
    // split the transactions by account
    const sortedAccountTransactions = BalanceCalculatedUtils.sortTransactionsByAccounts(
      transactions,
      true,
    );

    const balanceDates = BalanceDatesUtils.getBalanceDates(
      startDate,
      endDate,
      granularity,
      this.userStore,
    );
    const balanceDatesArray = new Uint32Array(balanceDates);

    const balanceByTimeAccounts: Record<string, Record<string, number>> = {};
    const promises: Promise<WorkerMessage>[] = [];

    for (const accountId in sortedAccountTransactions) {
      const transactionDates = sortedAccountTransactions[accountId].transactionDates;
      const transactionsAmounts = sortedAccountTransactions[accountId].transactionAmounts;

      const transactionDatesArray = new Uint32Array(transactionDates);
      const transactionAmountsArray = new Float64Array(transactionsAmounts);

      const balanceRequest = new TransactionBalancesWorkerMessage(
        "getBalanceByAccount",
        "getAccountBalances",
        transactionDatesArray.buffer,
        transactionAmountsArray.buffer,
        0,
        balanceDatesArray.buffer,
      );

      promises.push(
        webWorkerQueue.postMessagePromise(balanceRequest).then(
          (workerMessage: TransactionBalancesWorkerResult) => {
            if (workerMessage?.balanceAmounts) {
              const balanceAmounts = new Float64Array(workerMessage.balanceAmounts);
              const balanceDates = new Uint32Array(workerMessage.balanceDates);

              for (let i = 0; i < balanceDates.length; i++) {
                const balanceDate = balanceDates[i];
                const balanceAmount = balanceAmounts[i];

                if (!balanceByTimeAccounts?.[balanceDate]) {
                  balanceByTimeAccounts[balanceDate] = {};
                }
                balanceByTimeAccounts[balanceDate][accountId] = balanceAmount;
              }
            }
            return Promise.resolve(workerMessage);
          },
          (error: Error) => {
            return Promise.reject(error);
          },
        ),
      );
    }

    await Promise.all(promises);

    return balanceByTimeAccounts;
  }

  async getBalanceByAccountsSymbols(
    webWorkerQueue: WebWorkerQueue,
    transactions: TransactionView[],
    balanceDate: Date,
  ): Promise<Record<string, Record<string, number>>> {
    // split the transactions by account
    const sortedAccountSymbolTransactions =
      BalanceCalculatedUtils.sortTransactionsByAccountsSymbols(transactions, false);

    const balanceTimestamp = BalanceDatesUtils.dateToTimestamp(balanceDate);

    const balanceByAccountsSymbols: Record<string, Record<string, number>> = {};
    const promises: Promise<WorkerMessage>[] = [];

    for (const accountId in sortedAccountSymbolTransactions) {
      for (const symbol in sortedAccountSymbolTransactions[accountId]) {
        const transactionDates =
          sortedAccountSymbolTransactions[accountId][symbol].transactionDates;
        const transactionsAmounts =
          sortedAccountSymbolTransactions[accountId][symbol].transactionAmounts;

        const transactionDatesArray = new Uint32Array(transactionDates);
        const transactionAmountsArray = new Float64Array(transactionsAmounts);

        const balanceRequest = new TransactionBalancesWorkerMessage(
          "getBalanceByAccountSymbols",
          "getAccountBalancesSymbols",
          transactionDatesArray.buffer,
          transactionAmountsArray.buffer,
          0,
          new Uint32Array([balanceTimestamp]).buffer,
        );

        promises.push(
          webWorkerQueue.postMessagePromise(balanceRequest).then(
            (workerMessage: TransactionBalancesWorkerResult) => {
              if (workerMessage?.balanceAmounts) {
                const balanceAmounts = new Float64Array(workerMessage.balanceAmounts);
                const balanceDates = new Uint32Array(workerMessage.balanceDates);

                for (let i = 0; i < balanceDates.length; i++) {
                  const balanceDate = balanceDates[i];
                  const balanceAmount = balanceAmounts[i];

                  if (!balanceByAccountsSymbols?.[accountId]) {
                    balanceByAccountsSymbols[accountId] = {};
                  }
                  balanceByAccountsSymbols[accountId][symbol] = balanceAmount;
                }
              }
              return Promise.resolve(workerMessage);
            },
            (error: Error) => {
              return Promise.reject(error);
            },
          ),
        );
      }
    }

    await Promise.all(promises);

    return balanceByAccountsSymbols;
  }

  /**
   * buildTransactionArrays - builds the transaction arrays for the web worker
   * @param transactions
   * @param useNormalized
   * @param ignoreTime
   */
  buildTransactionArrays(
    transactions: Array<TransactionView>,
    useNormalized = true,
  ): FlattenedTransactions {
    const transactionResult: FlattenedTransactions = {
      transactionDates: [],
      transactionAmounts: [],
    };

    if (!Array.isArray(transactions)) {
      return transactionResult;
    }
    for (const transaction of transactions.filter((x) => !!x)) {
      const transactionDate = transaction.transactionDate;
      const unixTime = BalanceDatesUtils.dateToTimestamp(transactionDate);
      transactionResult.transactionDates.push(unixTime);
      transactionResult.transactionAmounts.push(getTransactionAmount(transaction, useNormalized));
    }
    return transactionResult;
  }

  /**
   * sortTransactionsByAccounts - sorts the transactions by account and flattens the transactions
   * into the required format for the web worker
   * @param transactions
   * @param useNormalized
   */
  static sortTransactionsByAccounts(
    transactions: TransactionView[],
    useNormalized = true,
  ): FlattenedTransactionsByAccount {
    const transactionByAccountResults: FlattenedTransactionsByAccount = {};

    for (const transaction of transactions) {
      if (!transactionByAccountResults?.[transaction.accountLink.id]) {
        transactionByAccountResults[transaction.accountLink.id] = {
          transactionDates: [],
          transactionAmounts: [],
        };
      }
      transactionByAccountResults[transaction.accountLink.id].transactionDates.push(
        BalanceDatesUtils.dateToTimestamp(transaction.transactionDate),
      );

      const transactionAmount = getTransactionAmount(transaction, useNormalized);
      transactionByAccountResults[transaction.accountLink.id].transactionAmounts.push(
        transactionAmount,
      );
    }
    return transactionByAccountResults;
  }

  /**
   * sortTransactionsByAccounts - sorts the transactions by account and flattens the transactions
   * into the required format for the web worker
   * @param transactions // assumes the transactions are already sorted by date in ascending order
   * @param filters
   * @param ignoreTimes
   * @param useNormalized
   */
  static sortTransactionsByAccountsSymbols(
    transactions: TransactionView[],
    useNormalized = false,
  ): FlattenedTransactionsByAccountSymbol {
    const transactionByAccountSymbolResults: FlattenedTransactionsByAccountSymbol = {};

    for (const transaction of transactions) {
      if (!transactionByAccountSymbolResults?.[transaction.accountLink.id]) {
        transactionByAccountSymbolResults[transaction.accountLink.id] = {};
      }
      if (
        !transactionByAccountSymbolResults[transaction.accountLink.id]?.[
          transaction.quantity.actualQuantity.symbol
        ]
      ) {
        transactionByAccountSymbolResults[transaction.accountLink.id][
          transaction.quantity.actualQuantity.symbol
        ] = {
          transactionDates: [],
          transactionAmounts: [],
        };
      }
      transactionByAccountSymbolResults[transaction.accountLink.id][
        transaction.quantity.actualQuantity.symbol
      ].transactionDates.push(BalanceDatesUtils.dateToTimestamp(transaction.transactionDate));
      const transactionAmount = getTransactionAmount(transaction, useNormalized);
      transactionByAccountSymbolResults[transaction.accountLink.id][
        transaction.quantity.actualQuantity.symbol
      ].transactionAmounts.push(transactionAmount);
    }
    return transactionByAccountSymbolResults;
  }

  static mapToRecord(balanceMap: Map<string, Map<string, number>>) {
    const balanceRecord: Record<string, Record<string, number>> = {};
    for (const [key, innerMap] of balanceMap.entries()) {
      const innerRecord: Record<string, number> = {};
      for (const [innerKey, value] of innerMap.entries()) {
        innerRecord[innerKey] = value;
      }
      balanceRecord[key] = innerRecord;
    }
    return balanceRecord;
  }
}

import { TransactionStoreModel } from "../../store/transaction.store.model";
import { ModelView } from "../model.view";
import { TransactionView } from "./transaction.view";
import {
  GlossQuantity,
  GlossQuantityUtils,
} from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";
import { ValuationUtilities } from "@bitwarden/web-vault/app/models/data/valuation.data";
import { AccountTransactionsMap } from "@bitwarden/web-vault/app/models/types/revaluation.types";
import { SplitClassificationType } from "@bitwarden/web-vault/app/models/types/split-classification-type";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { GlossNumberUtils } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";

export type TransactionViewProperties = Omit<
  TransactionView,
  | keyof ModelView<TransactionStoreModel>
  | "constructor"
  | "legacyConstructor"
  | "setMockAccount"
  | "update"
  | "toStoreModel"
  | "clone"
>;

export function getTransactionViewOf(
  transactionViewJson: TransactionViewProperties,
): TransactionView {
  /* debugger;*/
  transactionViewJson.quantity = GlossQuantityUtils.fromSelfProperties(
    transactionViewJson.quantity,
  );
  transactionViewJson.c_valuation = ValuationUtilities.fromSelfProperties(
    transactionViewJson.c_valuation,
  );

  const transactionView = new TransactionView();
  Object.assign(transactionView, transactionViewJson);
  return transactionView;
}

export function setAccountTransactionsMap(transactions: TransactionView[]): AccountTransactionsMap {
  return transactions.reduce((accMap, transaction) => {
    if (accMap.has(transaction.accountLink.id)) {
      accMap.get(transaction.accountLink.id).push(transaction);
    } else {
      accMap.set(transaction.accountLink.id, [transaction]);
    }
    return accMap;
  }, new Map<string, TransactionView[]>());
}

export function createTransactionView(
  accountID: string,
  institutionID: string,
  symbolAmount: number,
  symbol: string,
  transactionDate: Date,
  description: string,
  classification: SplitClassificationType[] = [],
  categories: SplitCategoryType[] = [],
  linkedTo: Array<string> = [],
): TransactionView {
  const transactionView = new TransactionView();

  const direction = symbolAmount > 0 ? TransactionDirection.In : TransactionDirection.Out;

  const actualQuantity = GlossNumberUtils.fromSelfProperties({
    amount: Math.abs(symbolAmount),
    symbol: symbol,
    precision: 8,
  });

  const quantity = GlossQuantityUtils.fromSelfProperties({
    actualQuantity,
    currency: symbol,
    convrate: 1,
    convsym: "",
  } as GlossQuantity);

  transactionView.accountLink = { id: accountID };
  transactionView.c_institutionLink = { id: institutionID };
  transactionView.categories = categories;
  transactionView.classifications = classification;
  transactionView.description = description;
  transactionView.direction = direction;
  transactionView.quantity = quantity;
  transactionView.transactionDate = transactionDate;
  transactionView.definition = TransactionStatusEnum.fake;
  transactionView.linkedTo = linkedTo;

  const transactionMeta = {
    accountLink: { id: accountID },
    categories: categories,
    classifications: classification,
    description: description,
    direction: direction,
    quantity: quantity,
    transactionDate: transactionDate,
    definition: TransactionStatusEnum.fake,
    linkedTo: linkedTo,
  };
  Object.assign(transactionView, transactionMeta);
  return transactionView;
}

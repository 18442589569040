import { SyncStatusEnum, SyncStatusKey, SyncStatusType } from "../../types/general-types";
import { SyncIcon } from "../../enum/sync.enum";

export const syncStates: Record<SyncStatusKey, SyncStatusType> = {
  syncing: {
    key: SyncStatusEnum.syncing,
    icon: SyncIcon.syncAll,
    messageKey: "syncing",
    actions: null,
  },

  synced: {
    key: SyncStatusEnum.synced,
    icon: SyncIcon.checkCircle,
    messageKey: "synced",
    actions: null,
  },

  failed: {
    key: SyncStatusEnum.failed,
    icon: SyncIcon.xCircle,
    messageKey: "error",
    actions: ["retry"],
  },

  noConnector: {
    key: SyncStatusEnum.noConnector,
    icon: SyncIcon.xCircle,
    messageKey: "error",
    actions: ["retry", "connect"],
  },

  noBasiqUser: {
    key: SyncStatusEnum.noBasiqUser,
    icon: SyncIcon.xCircle,
    messageKey: "needToConnectToBasiq",
    actions: ["consent"],
  },

  noBasiqConsent: {
    key: SyncStatusEnum.noBasiqConsent,
    icon: SyncIcon.xCircle,
    messageKey: "needToConsentToBasiq",
    actions: ["consent"],
  },

  noConnection: {
    key: SyncStatusEnum.noConnection,
    icon: SyncIcon.xCircle,
    messageKey: "noConnectionFound",
    actions: ["connect"],
  },

  noConnectionRefresh: {
    key: SyncStatusEnum.noConnectionRefresh,
    icon: SyncIcon.xCircle,
    messageKey: "connectionRefreshFailed",
    actions: ["retry", "connect"],
  },

  basiqJobFetchFail: {
    key: SyncStatusEnum.basiqJobFetchFail,
    icon: SyncIcon.xCircle,
    messageKey: "couldNotRetrieveData",
    actions: ["retry"],
  },

  dataFetchFail: {
    key: SyncStatusEnum.dataFetchFail,
    icon: SyncIcon.xCircle,
    messageKey: "dataFetchFailed",
    actions: ["retry"],
  },
};

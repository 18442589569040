import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
})
export class CheckboxComponent {
  @Input() title: string;
  @Input() background = "primary";
  @Input() checked = false;
  @Input() disabled = false;
  @Output() onChange = new EventEmitter<boolean>();

  toggle() {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.onChange.emit(this.checked);
    }
  }
}

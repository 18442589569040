<!--<button mat-dialog-close aria-label="Close dialog" tabindex="-1" class="btn-icon">-->
<!--<button-->
<!--  [attr.mat-dialog-close]="isMatDialog ? '' : null"-->
<!--  aria-label="Close dialog"-->
<!--  tabindex="-1"-->
<!--  class="btn-icon"-->
<!--  (click)="handleClose()"-->
<!--&gt;-->
<!--  <svg-->
<!--    class="tw-h-4 tw-w-4 {{ closeButtonType === 'plain' ? 'tw-text-black' : 'tw-text-primary' }}"-->
<!--  >-->
<!--    <use xlink:href="images/sprite.svg#close" />-->
<!--  </svg>-->
<!--</button>-->
@if (isMatDialog) {
  <button mat-dialog-close aria-label="Close dialog" tabindex="-1" class="btn-icon">
    <svg
      class="tw-h-4 tw-w-4 {{ closeButtonType === 'plain' ? 'tw-text-black' : 'tw-text-primary' }}"
    >
      <use xlink:href="images/sprite.svg#close" />
    </svg>
  </button>
} @else {
  <button aria-label="Close" tabindex="-1" class="btn-icon" (click)="handleClose()">
    <svg
      class="tw-h-4 tw-w-4 {{ closeButtonType === 'plain' ? 'tw-text-black' : 'tw-text-primary' }}"
    >
      <use xlink:href="images/sprite.svg#close" />
    </svg>
  </button>
}

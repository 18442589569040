import { Component, computed, inject, Input, type Signal } from "@angular/core";
import { WidgetMenuType } from "../../../models/types/widget.types";
import { CalculationStoreService } from "@bitwarden/web-vault/app/services/store/calculation/calculation.store.service";
import { View } from "@bitwarden/web-vault/app/models/enum/view.enum";

type DataSet = { text: string; amount: number; percent: string }[];

@Component({
  selector: "app-group-by-chart-control",
  templateUrl: "./group-by-chart-control.html",
})
export class GroupByChartControlComponent {
  @Input() currentView: View;
  protected readonly ViewEnum = View;
  private calculationStore = inject(CalculationStoreService);

  protected curChartWidget = "bubbleChart";
  protected chartWidgetMenu = {
    type: "icon" as WidgetMenuType,
    options: [
      {
        displayValue: "bubble-chart",
        value: "bubbleChart",
        isSelected: true,
      },
    ],
  };

  protected getGranularityMenuOptions() {
    const granularity = this.calculationStore.groupings.selected().granularity;
    return {
      type: "text" as WidgetMenuType,
      options: [
        {
          displayValue: granularity,
          value: granularity,
          isSelected: true,
        },
      ],
    };
  }

  protected handleChartWidget(value: string) {
    this.curChartWidget = value;
  }

  // TODO: remove dataSet from computedData, fetch data from the store in the chart
  computedData: Signal<{ dataSet: DataSet }> = computed(() => {
    const accounts = this.calculationStore.filteredAccounts.collection();
    const balances = this.calculationStore.balanceByAccount.balances();
    const dataSet: DataSet = [];

    if (!accounts || !balances) {
      return { dataSet: [] };
    }

    for (const account of accounts) {
      dataSet.push({ text: account.name, amount: balances.get(account.id), percent: "$" });
    }

    return { dataSet: dataSet };
  });
}

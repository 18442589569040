import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-symbol-amount-renderer",
  templateUrl: "./symbol-amount-renderer.component.html",
  providers: [DecimalPipe],
})
export class SymbolAmountRendererComponent implements ICellRendererAngularComp {
  amount!: string;

  constructor(private decimalPipe: DecimalPipe) {}

  agInit(params: ICellRendererParams): void {
    this.setData(params);
  }

  setData(params: ICellRendererParams) {
    const initialAmount = params.data.amount || 0;
    this.amount = this.decimalPipe.transform(initialAmount, "1.2-2") || "Unavailable amount";
  }

  refresh(params: ICellRendererParams): boolean {
    this.setData(params);
    return true;
  }
}

import { inject, Injectable, Injector } from "@angular/core";
import { BasiqConnector } from "@bitwarden/web-vault/app/services/syncing/basiq/basiq-connector.service";
import { GlossConnectorService } from "@bitwarden/web-vault/app/services/syncing/gloss-connector.service";

import { AccountOriginGroup, Origin, SyncStatusEnum } from "../../models/types/general-types";
import { AccountView } from "../../models/view/account/account.view";
import { BookService } from "../DataService/book/book.service";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { SyncStatusView } from "@bitwarden/web-vault/app/models/view/sync-status/sync-status.view";
import { ImportStoreService } from "@bitwarden/web-vault/app/services/store/import/import.store.service";

import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";

@Injectable({
  providedIn: "root",
})
export class GlossSyncService {
  private userStoreService: UserStoreService;
  private importStoreService: ImportStoreService;
  private basiqAuth: BasiqAuth;
  private autoAccounts: AccountView[] = [];
  private accountOriginGroup: AccountOriginGroup = null;
  private bookService: BookService;
  private connectors: BasiqConnector[] = [];

  constructor(private injector: Injector) {
    this.userStoreService = inject(UserStoreService);
    this.importStoreService = inject(ImportStoreService);
    this.basiqAuth = inject(BasiqAuth);
    this.bookService = inject(BookService);
  }

  async startSync() {
    const isSyncableAccounts = this.isSyncableAccounts();
    if (!isSyncableAccounts) {
      /** @Sinan this.updateStateSoUserKnowWhatHappens(); Currently if no auto accounts there will be nothing to sync*/
      return;
    }

    await this.syncAccounts();
  }

  async syncAccounts() {
    const accountViews = this.userStoreService.accounts.accountViews().map((acc) => acc.clone());
    const institutionViews = this.userStoreService.institutions
      .institutionViews()
      .map((insto) => insto.clone());
    const newSyncStatuses: SyncStatusView[] = [];
    const syncingAccounts = accountViews.map((account) => {
      account.institution = institutionViews.find(
        (institution) => institution.id === account.institutionLink.institutionId,
      );
      account.institutionAccountType = account.institution?.availableAccounts.find(
        (availableAccount) => availableAccount.id === account.institutionLink?.institutionAccountId,
      );
      if (account.origin === Origin.manual) {
        return account;
      }
      const newSyncStatus = new SyncStatusView({
        id: crypto.randomUUID(),
        key: SyncStatusEnum.syncing,
        vid: null,
        dc: new Date().toISOString(),
        dm: new Date().toISOString(),
        v: 1,
        acId: account.id,
      });
      newSyncStatuses.push(newSyncStatus);
      account.syncStatusLink.push({ id: newSyncStatus.id });
      return account;
    });

    this.autoAccounts = syncingAccounts.filter((acc) => acc.origin !== Origin.manual);
    await this.importStoreService.syncStatus.saveToVault(newSyncStatuses, true);
    await this.userStoreService.accounts.saveToVault(syncingAccounts, true);

    this.connectors = this.getGlossConnectors();
    if (!this.connectors || this.connectors.length === 0) {
      const failedStatuses: SyncStatusView[] = [];
      const accounts = this.userStoreService.accounts.accountViews().map((acc) => acc.clone());
      const failedSyncingAccounts = accounts.map((account) => {
        if (account.origin === Origin.manual) {
          return account;
        }
        const failedNewSyncStatus = new SyncStatusView({
          id: crypto.randomUUID(),
          key: SyncStatusEnum.noConnector,
          vid: null,
          dc: new Date().toISOString(),
          dm: new Date().toISOString(),
          v: 1,
          acId: account.id,
        });
        failedStatuses.push(failedNewSyncStatus);
        account.syncStatusLink.push({ id: failedNewSyncStatus.id });
        return account;
      });

      await this.importStoreService.syncStatus.saveToVault(failedStatuses, true);
      await this.userStoreService.accounts.saveToVault(failedSyncingAccounts, true);
      return;
    }

    this.connectors.forEach((connector) => {
      connector.syncAccounts().then(() => null);
    });
  }

  async basiqReConsent() {
    await this.basiqAuth.requestBasiqConsent();
  }

  /** Checks if there are any auto-books based on Basiq or Plaid that needs a sync */
  isSyncableAccounts(): boolean {
    this.autoAccounts = this.bookService.getSyncedAccounts();
    return this.autoAccounts.length > 0;
  }

  /** Groups the accounts based on their origin being Basiq or Plaid */
  private getAccountOriginGroup(): AccountOriginGroup {
    const grouped = {} as AccountOriginGroup;
    for (const account of this.autoAccounts) {
      const accountOrigin = account.origin;
      if (!grouped[accountOrigin]) {
        grouped[accountOrigin] = [];
      }
      grouped[accountOrigin].push(account);
    }

    return grouped;
  }

  /** Get the connectors as a Class to be used*/
  getGlossConnectors(): BasiqConnector[] {
    this.accountOriginGroup = this.getAccountOriginGroup();
    const connectors: BasiqConnector[] = [];
    for (const origin in this.accountOriginGroup) {
      const connector = new GlossConnectorService(
        origin as Origin,
        this.accountOriginGroup[origin as Origin],
        this.injector,
      ).getConnector();
      connectors.push(connector);
    }
    return connectors;
  }

  /*
    @Sinan update the sync result method
    /!** Handles the saving the data part *!/
  async mergeSyncedData(accountStatuses: AccountState[]) {
    for (const accountStatus of accountStatuses) {
      if (accountStatus.rawTransactions.length === 0) {
        accountStatus.isMerged = true;
        continue;
      }
      const { accountView, rawTransactions } = accountStatus;

      const book = accountView.originalBook;
      const transactions = { data: rawTransactions };
      await this.transactionBasiqImporter.saveSyncedTransactions(book, false, transactions);
      await this.transactionBasiqImporter.addTomorrowOpeningBalance(book);
      /!** @Sinan add closing balance here *!/
      accountStatus.isMerged = true;
    }
    await this.updateConnectorsInBlobby(accountStatuses);
  }*/
}

<div class="step-holder">
  <div>
    <span [innerHTML]="'openAccountDescription' | i18n" class="step-description"></span>
    <best-scenario-stepper-earnings-card
      *ngIf="earningsCard"
      [earningsData]="earningsCard"
    ></best-scenario-stepper-earnings-card>

    <div class="section-gap">
      <span class="section-header">{{ "openA" | i18n }}</span>
      @if (rateInUse) {
        <app-best-account-tooltip [lastViewDate]="rateInUse.last_updated">
          {{ bestAccountInfo.accountName }}
        </app-best-account-tooltip>
      }
    </div>
  </div>

  <div class="secondary-buttons-holder">
    <button disabled class="step-action-button left-round-border">
      <span class="tw-block xs:tw-text-sm">{{ "openAccount" | i18n }}</span>
      <span class="tw-text-xs">{{ "comingSoon" | i18n }}</span>
    </button>

    <button
      (click)="openLinkToSite()"
      class="step-action-button right-round-border tw-shadow-button"
    >
      <span>{{ "linkToSite" | i18n }}</span>
    </button>
  </div>
</div>

import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { WizardStoreModel } from "../../../../models/store/wizard.store.model";
import { wizard_v0_to_v1 } from "../model-dto/wizard";
import { WizardResponse } from "../../../../models/data/response/wizard.response";
import { Wizard } from "../../../../models/data/blobby/wizard.data";

export class WizardParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported Wizard parser version ${version}`);
    }

    if (version === 0) {
      return WizardParser_v0;
    }
    return WizardParser_v1;
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}

const WizardParser_v1 = (wizardItem: any): any => wizardItem as WizardStoreModel;
const WizardParser_v0 = (wizardItem: any): any => {
  const response = new WizardResponse(wizardItem);
  const wizard = new Wizard(response);
  const upgraded = wizard_v0_to_v1(wizard);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = wizardItem.vid ?? "";
  return WizardParser_v1(upgraded);
};

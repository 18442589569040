<div class="tw-flex tw-gap-2">
  <div
    (click)="toggle()"
    class="tw-h-5 tw-w-5 tw-rounded tw-flex tw-items-center tw-justify-center
    {{ !checked ? 'tw-shadow-inner' : 'tw-bg-' + background }}"
  >
    <ng-container *ngIf="checked">
      <icon
        [size]="4"
        [name]="'check-only'"
        class="tw-flex tw-items-center tw-justify-center"
      ></icon>
    </ng-container>
  </div>
  <span>{{ title }}</span>
</div>

import { inject, Injectable } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { ConnectorView } from "@bitwarden/web-vault/app/models/view/connector/connector.view";

@Injectable({
  providedIn: "root",
})
export class ConnectorService {
  private logService: LogService;
  private userStoreService: UserStoreService;
  constructor(private dataRepositoryService: DataRepositoryService) {
    this.userStoreService = inject(UserStoreService);
  }

  /**
   * @deprecated
   */
  async createConnector(connector: Connector): Promise<Connector> {
    try {
      return await this.dataRepositoryService.createConnector(connector);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async updateConnector(connector: Connector) {
    try {
      return await this.dataRepositoryService.updateConnector(connector);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async getConnectorByOrigin(origin: Origin): Promise<Connector> {
    try {
      const connectors = await this.dataRepositoryService.getAllConnectors();
      return connectors.find((connector) => connector.origin === origin);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async getConnectorOf(origin: Origin): Promise<ConnectorView> {
    try {
      const userConnectors = this.userStoreService.connectors.connectorViews();
      return userConnectors.find((connector) => connector.origin === origin);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async testConnector(): Promise<Connector[]> {
    return await this.dataRepositoryService.getAllConnectors();
  }
}

import { SourceCategory } from "../../../../models/data/blobby/source-category";
import { SourceCategoryStoreModel } from "../../../../models/store/source-category.store.model";
export const sourceCategory_v0_to_v1 = (
  sourceCategory: SourceCategory,
): SourceCategoryStoreModel => {
  return {
    nm: sourceCategory.name,
    ctId: sourceCategory.categoryId,
    v: 1,
    vid: "",
    dc: new Date().toString(),
    dm: new Date().toString(),
    id: sourceCategory.id,
  };
};

import { ModelView } from "../model.view";
import { StoreModel } from "../../../services/dali/vault-parser/parser.type";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { EstimateType } from "@bitwarden/web-vault/app/models/enum/estimateType";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { Splitcategory } from "@bitwarden/web-vault/app/models/data/splitcategory";
import { Splitclassification } from "@bitwarden/web-vault/app/models/data/splitclassification";
import { RecurringData } from "@bitwarden/web-vault/app/models/data/recurring.data";
import { TransactionTemplateData } from "@bitwarden/web-vault/app/models/data/transaction-template.data";
import { InterestMatchData } from "@bitwarden/web-vault/app/models/data/interest-match.data";
import {
  DefaultEstimateActionStoreModel,
  EstimateActionModelVersion,
  EstimateActionStoreModel,
} from "@bitwarden/web-vault/app/models/store/estimate.action.store.model";

export class EstimateActionView extends ModelView<EstimateActionStoreModel> {
  protected version: EstimateActionModelVersion;

  actionType: string;
  name: string;
  account: Book; // todo use LinkedView
  estimateType: EstimateType; // transaction, interest
  startDate: string;
  direction: TransactionDirection; //<'in','out'> // the direction of the new transaction
  description: string; // the description that the new transaction will have
  categories: Splitcategory[];
  classifications: Splitclassification[];
  groupId: string;
  recurring: RecurringData; // // todo use LinkedView ???
  transactionTemplate: TransactionTemplateData; // todo is that some linked data type?  if not convert to interface move the def to the top update the EstimateActionStoreModel to map it
  interestMatch: InterestMatchData; // todo is that some linked data type?  if not convert to interface move the def to the top  update the EstimateActionStoreModel to map it

  constructor(model?: EstimateActionStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.actionType = model.atype;
      this.name = model.n;
      this.account = model.acc;
      this.estimateType = model.etype;
      this.startDate = model.sdate;
      this.direction = model.dir;
      this.description = model.des;
      this.categories = model.cat;
      this.classifications = model.cl;
      this.groupId = model.gid;
      this.recurring = model.r;
      this.transactionTemplate = model.ttemp;
      this.interestMatch = model.imatch;
    } else {
      this.version = DefaultEstimateActionStoreModel.v;
    }
  }

  toStoreModel(): EstimateActionStoreModel {
    return undefined;
  }

  clone(): EstimateActionView {
    return new EstimateActionView(this.toStoreModel());
  }
}

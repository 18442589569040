import { StoreModelCollection } from "../model.collection.abstraction";
import { StoreModelNames } from "../../dali/type/dali.type";
import { signal } from "@angular/core";
import { ScenarioGroupStoreModel } from "../../../models/store/scenario-group.store.model";
import { ScenarioGroupView } from "@bitwarden/web-vault/app/models/view/scenario-group/scenario-group.view";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import { toObservable } from "@angular/core/rxjs-interop";

export class ScenarioGroupStoreCollection extends StoreModelCollection<
  ScenarioGroupStoreModel,
  ModelView<ScenarioGroupStoreModel>
> {
  readonly type: StoreModelNames = "ScenarioGroupStoreModel";

  protected models: Map<string, ScenarioGroupStoreModel> = new Map<
    string,
    ScenarioGroupStoreModel
  >();

  private _scenarioGroupViews = signal<ScenarioGroupView[]>([]);
  readonly scenarioGroupView = this._scenarioGroupViews.asReadonly();
  scenarioGroupView$ = toObservable(this.scenarioGroupView);

  triggerChanges(): void {
    const views: ScenarioGroupView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new ScenarioGroupView(model)) as ScenarioGroupView);
    });
    this._scenarioGroupViews.set(views);
  }

  clear(): void {
    this.models.clear();
    this._scenarioGroupViews.set(null);
  }

  populateWithSystemDefaults(defaults: ScenarioGroupView[]) {
    let views: ScenarioGroupView[] = [];
    this.models.forEach((model) => {
      views.push(Object.freeze(new ScenarioGroupView(model)) as ScenarioGroupView);
    });
    views = [...this._scenarioGroupViews(), ...defaults];
    this._scenarioGroupViews.set(views);
  }
}

import { ModelView } from "../model.view";
import {
  DefaultInstitutionStoreModel,
  InstitutionModelVersion,
  InstitutionStoreModel,
} from "@bitwarden/web-vault/app/models/store/institution.store.model";
import { SwiftView } from "@bitwarden/web-vault/app/models/view/institution/swift.view";
import { BicView } from "@bitwarden/web-vault/app/models/view/institution/bic.view";
import { InstitutionAccountView } from "@bitwarden/web-vault/app/models/view/institution/institution-account.view";

export class InstitutionView extends ModelView<InstitutionStoreModel> {
  protected version: InstitutionModelVersion;

  split: number;
  name: string;
  swift: SwiftView;
  bic: BicView;
  basiqId: string; // todo need to change the name for something more generic. ExternalSourceId?
  availableAccounts: InstitutionAccountView[];

  constructor(model?: InstitutionStoreModel) {
    super(model);

    if (typeof model === "object") {
      this.name = model.name;
      this.swift = {
        bankCode: model.swt.bac,
        countryCode: model.swt.cc,
        locationCode: model.swt.lc,
        branchCode: model.swt.brc,
      };
      this.bic = {
        bankCode: model.bic.bc,
        countryCode: model.bic.cc,
        locationCode: model.bic.lc,
      };
      this.basiqId = model.fk;
      this.availableAccounts = model.avaAcc.map((account) => {
        return {
          institutionName: account.istNm,
          id: account.id,
          name: account.nm,
          interestRates: account.intRts.map((interest) => {
            const { rn, ba, ra, sy, lu, cid, cad } = interest;
            return {
              range: rn,
              banded: ba,
              rate: ra,
              symbol: sy,
              last_updated: lu,
              condition_intrinsic_desc: cid,
              condition_action_desc: cad,
            };
          }),
          useForScenarioBestRate: account.ufsbr,
          link: account.lk,
        };
      });
    } else {
      this.version = DefaultInstitutionStoreModel.v;
    }
  }

  toStoreModel(): InstitutionStoreModel {
    return {
      dc: this.dateCreated.toDateString(),
      dm: this.dateModified.toDateString(),
      id: this.id,
      v: this.version,
      vid: this.vaultId,
      name: this.name,
      swt: {
        bac: this.swift.bankCode,
        cc: this.swift.countryCode,
        lc: this.swift.locationCode,
        brc: this.swift.branchCode,
      },
      bic: {
        bc: this.bic.bankCode,
        cc: this.bic.countryCode,
        lc: this.bic.locationCode,
      },
      fk: this.basiqId,
      avaAcc: this.availableAccounts.map((account) => {
        return {
          istNm: account.institutionName,
          id: account.id,
          nm: account.name,
          intRts: account.interestRates.map((interest) => {
            const {
              range,
              banded,
              rate,
              symbol,
              last_updated,
              condition_intrinsic_desc,
              condition_action_desc,
            } = interest;
            return {
              rn: range,
              ba: banded,
              ra: rate,
              sy: symbol,
              lu: last_updated,
              cid: condition_intrinsic_desc,
              cad: condition_action_desc,
            };
          }),
          ufsbr: account.useForScenarioBestRate,
          lk: account.link,
        };
      }),
    };
  }

  clone(): InstitutionView {
    return new InstitutionView(this.toStoreModel());
  }
}

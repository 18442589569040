import { inject, Injectable } from "@angular/core";
import { ModelStoreAbstraction } from "@bitwarden/web-vault/app/services/store/model.store.abstraction";
import { StoreModelNames } from "@bitwarden/web-vault/app/services/dali/type/dali.type";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { TransactionStoreCollection } from "@bitwarden/web-vault/app/services/store/transaction/transaction.store.collection";
import { StoreModelCollection } from "@bitwarden/web-vault/app/services/store/model.collection.abstraction";
import { StoreData } from "@bitwarden/web-vault/app/models/store/store.data";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import { ReferenceDataStoreCollection } from "@bitwarden/web-vault/app/services/store/transaction/reference-data.store.collection";
import { BaseCurrencyPreferenceSubscriber } from "@bitwarden/web-vault/app/services/store/transaction/base-currency-preference.subscriber";
import { toObservable } from "@angular/core/rxjs-interop";
import { ReferenceDataView } from "@bitwarden/web-vault/app/models/view/reference-data/reference-data.view";
import { DataPluginStoreService } from "@bitwarden/web-vault/app/services/store/data-plugin/data-plugin.store.service";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";
import { combineLatest, debounceTime, filter, Observable, Subscription } from "rxjs";

import {
  getFirstTransactionDateSubscriber,
  getSymbolsSubscriber,
} from "@bitwarden/web-vault/app/services/store/transaction/transaction.subscriber.utils";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { EnrichTransactionGeneratedCollection } from "@bitwarden/web-vault/app/services/store/transaction/enrich-transaction.generated.collection";
import { SymbolsGeneratedCollection } from "@bitwarden/web-vault/app/services/store/transaction/symbols.generated.collection";

@Injectable({
  providedIn: "root",
})
export class TransactionStoreService extends ModelStoreAbstraction {
  protected log: LogService = inject(LogService);
  protected dataPluginStore = inject(DataPluginStoreService);

  /** Collection **/
  readonly referenceData: ReferenceDataStoreCollection = new ReferenceDataStoreCollection();
  readonly transaction: TransactionStoreCollection = new TransactionStoreCollection();
  readonly symbols: SymbolsGeneratedCollection = new SymbolsGeneratedCollection();
  readonly enrichTransaction: EnrichTransactionGeneratedCollection =
    new EnrichTransactionGeneratedCollection();

  /** Store dependencies and collection dependencies **/
  readonly dependentStore: BaseCurrencyPreferenceSubscriber =
    new BaseCurrencyPreferenceSubscriber();
  protected firstTransactionDate$: Observable<Date> = toObservable(
    this.transaction.firstTransactionDate,
  );

  referenceData$: Observable<ReferenceDataView[]> = toObservable(
    this.referenceData.referenceDataViews,
  );
  protected transaction$: Observable<TransactionView[]> = toObservable(
    this.transaction.transactionViews,
  );

  /** Subscription holder**/
  private forexRatesSubscription: Subscription;
  private enrichTransactionSubscription: Subscription;

  clearStore(): void {
    this.forexRatesSubscription.unsubscribe();
    this.enrichTransactionSubscription.unsubscribe();

    this.symbols.clear();
    this.transaction.clear();
    this.referenceData.clear();
    this.enrichTransaction.clear();
    this.log.info("TransactionStoreService cleared");
  }

  initialize(): void {
    this.log.info("Initializing TransactionStoreService");
    /** Load Reference Data and Transaction Data**/
    this.referenceData.triggerChanges();
    this.transaction.triggerChanges();

    /** On Reference Or Transaction update, update the enrich Collection**/
    this.enrichTransactionSubscription = combineLatest([
      this.referenceData$.pipe(filter((r) => !!r)),
      this.transaction$.pipe(filter((t) => !!t)),
    ]).subscribe(this.onReferenceDataUpdated.bind(this));

    /** On First Transaction date, symbols, or base currency changes. Pull new forex rates **/
    this.forexRatesSubscription = combineLatest([
      this.dependentStore.getObservable(), // base currency changed
      getFirstTransactionDateSubscriber(this.firstTransactionDate$),
      getSymbolsSubscriber(this.symbols.collection$),
    ])
      .pipe(debounceTime(10))
      .subscribe(this.pullNewForexRates.bind(this));

    this.log.info("TransactionStoreService initialized");
    this.log.info(`First transaction ${this.transaction.firstTransactionDate()}`);
    this.log.info(`Last transaction ${this.transaction.lastTransactionDate()}`);
  }

  onReferenceDataUpdated(
    combine: [referenceData: ReferenceDataView[], transaction: TransactionView[]],
  ) {
    const preference = this.dependentStore.getCollection();
    const referenceData = combine[0];
    const transactions = combine[1];

    this.symbols.generate(referenceData, transactions);
    this.enrichTransaction.generate(preference, referenceData, transactions);
    this.log.info(`User Symbols ${JSON.stringify(this.symbols.collection())}`);
  }

  protected pullNewForexRates() {
    // Need to pull the new rates from external store.
    const preference = this.dependentStore.getCollection();
    const currencies = this.symbols.collection().filter((s) => s.isCurrency());
    const baseCurrency = new SymbolView(preference.baseCurrency);
    /** No currency to fetch **/
    if (currencies.length === 1 && currencies[0].code === baseCurrency.code) {
      this.log.info("onBaseCurrencyChanges: No currencies to fetch");

      // Make sure to flush calculation as the base currency could have been reverted to an uniq symbol
      this.onReferenceDataUpdated([
        this.referenceData.referenceDataViews(),
        this.transaction.transactionViews(),
      ]);

      return;
    }

    /** Check what we need to fetch vs has **/
    // todo - Limit the number of currency that need to be fetched.
    // const crossRate = getCrossRateInformation(baseCurrency,currencies, this.referenceData.referenceDataViews()) => check views/reference-data
    this.dataPluginStore.forexRates
      .fetch({
        baseCurrency,
        currencies,
        startDate: this.transaction.firstTransactionDate(),
        endDate: new Date(),
      })
      .then((forexDataViews) => this.referenceData.processNewForexRates(forexDataViews))
      .then(() => this.log.info("New Forex Rates Imported"))
      .catch((error) => {
        this.log.error("Error fetching forex rates");
        this.log.error(error);
      });
  }

  protected getStorageCollection(
    modelName: StoreModelNames,
  ): StoreModelCollection<StoreData, ModelView<StoreData>> {
    switch (modelName) {
      case "TransactionStoreModel":
        return this.transaction;
      case "ReferenceDataStoreModel":
        return this.referenceData;
      default: {
        this.log.warning(`Need to implement TransactionStoreService ${modelName}`);
      }
    }
  }
}

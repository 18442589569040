import { isCurrency } from "./currency-utility";
import { ReferenceDataView } from "@bitwarden/web-vault/app/models/view/reference-data/reference-data.view";
import {
  AllowedCurrencies,
  CurrencyType,
  DefaultLocationPerCurrency,
  DefaultSystemLocation,
  UserLocationType,
} from "@bitwarden/web-vault/app/models/types/location-currency.types";
import { isOfType } from "@bitwarden/web-vault/app/models/types/general-types";

/** Not in use **/
export class SymbolView {
  name: string;
  code: string;
  c_asOf?: ReferenceDataView;

  constructor(code: string, name?: string) {
    this.code = code;
    this.name = name ?? "";
  }

  isCurrency(): boolean {
    return this.code.length === 3 && isCurrency(this);
  }

  get location(): UserLocationType {
    const cross = this.c_asOf?.nCross();
    const base = this.c_asOf?.base ?? this.code;

    if (isOfType<CurrencyType>(cross, AllowedCurrencies)) {
      return DefaultLocationPerCurrency[cross];
    } else if (isOfType<CurrencyType>(base, AllowedCurrencies)) {
      return DefaultLocationPerCurrency[base];
    } else {
      return DefaultSystemLocation;
    }
  }
}

import {
  CalculatedBalanceCollection,
  CalculatedBalances,
  CalculatedBalancesArray,
  ScenarioBalanceParams,
} from "@bitwarden/web-vault/app/services/store/calculated-balance.collection.abstraction";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { inject, signal } from "@angular/core";

export class ScenarioBalanceByTimeCalculatedCollection extends CalculatedBalanceCollection {
  protected log: LogService = inject(LogService);

  protected type: "ScenarioBalanceByTime";
  protected calculations: CalculatedBalancesArray = [];

  protected _balances = signal<CalculatedBalancesArray>([]);
  readonly balances = this._balances.asReadonly();

  /**
   * Uses the filteredTransactions stored on the calculation store to update
   * the calculations for balance by account
   */
  async updateCalculations(parameters: ScenarioBalanceParams): Promise<void> {
    const promises: Promise<Record<string, number>>[] = [];
    for (const transactions of parameters.scenarioTransactions) {
      promises.push(
        this.getBalanceByTime(
          parameters.webWorkerQueue,
          transactions,
          parameters.period.startDate,
          parameters.period.endDate,
          parameters.group.granularity,
        ),
      );
    }
    await Promise.all(promises).then((results: Record<string, number>[]) => {
      // console.log(results);
      this.setBalances(results);
    });
  }

  getCalculations(): CalculatedBalancesArray {
    return this.calculations;
  }

  clear() {
    this.calculations = [];
    this._balances.set(null);
  }

  triggerChanges(): void {}

  private setBalances(balancesByTimeArray: Array<Record<string, number>>): void {
    this.calculations = [];

    for (const balancesByTime of balancesByTimeArray) {
      const balanceMap = new Map(Object.entries(balancesByTime));
      this.calculations.push(balanceMap);
    }
    this._balances.set(this.calculations);
  }

  clone(): CalculatedBalancesArray {
    return structuredClone(this.calculations);
  }
}

import { Component, EventEmitter, inject, Output } from "@angular/core";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { CalculationStoreService } from "../../services/store/calculation/calculation.store.service";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";
import { FilterControlType } from "@bitwarden/web-vault/app/services/store/calculation/controls/filter.control";
import { ViewsFilter } from "@bitwarden/web-vault/app/gloss/views/views-control/views-customize/views.filter";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  AccountFilter,
  CountryFilter,
  InstitutionFilter,
  MainFilter,
} from "@bitwarden/web-vault/app/models/types/filter.types";
import { BiMap } from "@bitwarden/web-vault/app/shared/utils/bimap";

@Component({
  selector: "app-views-dropdown",
  templateUrl: "./views-dropdown.component.html",
})
export class ViewsDropdownComponent {
  @Output() selectedOptionsChange = new EventEmitter<any>();

  protected calculationStoreService: CalculationStoreService = inject(CalculationStoreService);
  protected i18nService: I18nService = inject(I18nService);
  protected confirmationDialogService = inject(ConfirmationDialogService);
  protected viewsFilter = inject(ViewsFilter);
  private idNameMap: BiMap<string, string> = new BiMap();

  protected cancelButtonOptions = new ActionButton({
    class: "neutral",
    fullClass: "round-corner-button",
    text: this.i18nService.t("reset"),
    onClick: this.onCancel.bind(this),
    testId: "viewsDropdownCancelButton",
  });

  protected confirmButtonOptions = new ActionButton({
    class: "primary",
    fullClass: "round-corner-button",
    text: this.i18nService.t("confirm"),
    onClick: this.onConfirm.bind(this),
    testId: "viewsDropdownConfirmButton",
  });

  countries: string[] = [];
  selectedCountries: string[] = [];

  institutions: string[] = [];
  selectedInstitutions: string[] = [];
  disabledInstitutions: string[] = [];

  accounts: string[] = [];
  selectedAccounts: string[] = [];
  disabledAccounts: string[] = [];

  constructor() {
    this.viewsFilter.filterObservable$.pipe(takeUntilDestroyed()).subscribe((filter) => {
      if (filter) {
        this.setLists(filter);
      }
    });

    this.viewsFilter.setFilter();
  }

  private setLists(filter: MainFilter) {
    const countriesFilter: CountryFilter[] = [];
    const institutionsFilter: InstitutionFilter[] = [];
    const accountsFilter: AccountFilter[] = [];
    for (const [countryCode, countryFilter] of filter) {
      countriesFilter.push(countryFilter);
      this.idNameMap.set(countryCode, countryFilter.countryView.name);
      for (const [institutionId, institutionFilter] of countryFilter.institutionFilter) {
        institutionsFilter.push(institutionFilter);
        this.idNameMap.set(institutionId, institutionFilter.institutionView.name);
        for (const [accountId, accountFilter] of institutionFilter.accountFilter) {
          this.idNameMap.set(accountId, accountFilter.accountView.name);
          accountsFilter.push(accountFilter);
        }
      }
    }

    this.setCountriesList(countriesFilter);
    this.setInstitutionsList(institutionsFilter);
    this.setAccountsList(accountsFilter);
  }

  private setCountriesList(countriesFilter: CountryFilter[]) {
    const countries: string[] = [];
    const selectedCountries: string[] = [];
    countriesFilter.forEach((countryFilter, index) => {
      countries.push(countryFilter.countryView.name);
      if (countryFilter.isSelected) {
        selectedCountries.push(countryFilter.countryView.name);
      }
    });
    this.countries = countries;
    this.selectedCountries = selectedCountries;
  }

  private setInstitutionsList(institutionsFilter: InstitutionFilter[]) {
    const institutions: string[] = [];
    const selectedInstitutions: string[] = [];
    const disabledInstitutions: string[] = [];
    institutionsFilter.forEach((institutionFilter, index) => {
      institutions.push(institutionFilter.institutionView.name);
      if (institutionFilter.isSelected) {
        selectedInstitutions.push(institutionFilter.institutionView.name);
      }
      if (institutionFilter.isDisabled) {
        disabledInstitutions.push(institutionFilter.institutionView.name);
      }
    });
    this.institutions = institutions;
    this.selectedInstitutions = selectedInstitutions;
    this.disabledInstitutions = disabledInstitutions;
  }

  private setAccountsList(accountsFilter: AccountFilter[]) {
    const accounts: string[] = [];
    const selectedAccounts: string[] = [];
    const disabledAccounts: string[] = [];
    accountsFilter.forEach((accountFilter, index) => {
      accounts.push(accountFilter.accountView.name);
      if (accountFilter.isSelected) {
        selectedAccounts.push(accountFilter.accountView.name);
      }
      if (accountFilter.isDisabled) {
        disabledAccounts.push(accountFilter.accountView.name);
      }
    });
    this.accounts = accounts;
    this.selectedAccounts = selectedAccounts;
    this.disabledAccounts = disabledAccounts;
  }

  protected onCountrySelect(countryNames: string[]) {
    const countryIds = countryNames.map((countryName: string) =>
      this.idNameMap.getKey(countryName),
    );
    this.viewsFilter.onCountrySelect(countryIds);
  }

  protected onInstitutionSelect(institutionNames: string[]) {
    const instoIds = institutionNames.map((instoName: string) => this.idNameMap.getKey(instoName));
    this.viewsFilter.onInstitutionSelect(instoIds);
  }

  protected onAccountSelect(accountNames: string[]) {
    const accountIds = accountNames.map((accountName: string) =>
      this.idNameMap.getKey(accountName),
    );
    this.viewsFilter.onAccountSelect(accountIds);
  }

  onCancel(): void {
    this.viewsFilter.resetFilter();
  }

  onConfirm(): void {
    const institutions = this.selectedInstitutions.map((si) => this.idNameMap.getKey(si));
    const accounts = this.selectedAccounts.map((sa) => this.idNameMap.getKey(sa));
    const countries = this.selectedCountries.map((sc) => this.idNameMap.getKey(sc));
    if (!institutions.length) {
      this.confirmationDialogService.notify(
        "error",
        "errorOccurred",
        "selectAtLeastOneInstitution",
      );
      return;
    }

    if (!accounts.length) {
      this.confirmationDialogService.notify("error", "errorOccurred", "selectAtLeastOneAccount");
      return;
    }

    if (!countries.length) {
      this.confirmationDialogService.notify("error", "errorOccurred", "selectAtLeastOneCountry");
      return;
    }

    const newFilter: FilterControlType = {
      ...this.calculationStoreService.filters.selected(),
      countries,
      institutions,
      accounts,
    };

    this.calculationStoreService.filters.updateSelection(newFilter);

    this.selectedOptionsChange.emit(this.calculationStoreService.filters.selected());
  }
}

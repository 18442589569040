<!--<app-autocomplete-->
<!--  [items]="filteredCurrencies"-->
<!--  [placeholder]="'searchForACurrency' | i18n"-->
<!--  [label]="label"-->
<!--  (selectedItem)="currencySelected($event)"-->
<!--  (clearSelection)="selectionCleared()"-->
<!--  [preselectedValue]="currency"-->
<!--  [disabled]="disabled"-->
<!--  [isRequired]="false"-->
<!--&gt;</app-autocomplete>-->

<app-dropdown-select
  [options]="filteredCurrencies"
  [label]="label"
  (optionSelected)="currencySelected($event)"
>
</app-dropdown-select>

export const ScenarioTypes = {
  CurrentInterestRate: "currentInterestRate",
  BestExistingInterestRate: "bestExistingInterestRate",
  BestNewInterestRate: "bestNewInterestRate",
};

export const ScenarioPaths: Record<ScenarioTypes, number> = {
  currentInterestRate: 0,
  bestExistingInterestRate: 1,
  bestNewInterestRate: 2,
};

export type ScenarioTypes = (typeof ScenarioTypes)[keyof typeof ScenarioTypes];

import { StoreModel } from "./vault-parser/parser.type";
import { StoreModelNameByVaultCode, StoreModelNames, VaultCodeEnum } from "./type/dali.type";
import { UserStoreService } from "../store/user/user.store.service";
import { inject, Injectable } from "@angular/core";
import { TransactionStoreService } from "../store/transaction/transaction.store.service";
import { ScenarioStoreService } from "../store/scenario/scenario.store.service";
import { ImportStoreService } from "../store/import/import.store.service";
import { DataPluginStoreService } from "../store/data-plugin/data-plugin.store.service";
import { CalculationStoreService } from "../store/calculation/calculation.store.service";
import { ModelStoreAbstraction } from "../store/model.store.abstraction";
import { DaliService } from "./dali.service";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";

@Injectable({
  providedIn: "root",
})
export class StoreLoaderService {
  private appState: AppStateStoreService = inject(AppStateStoreService);
  private daliService: DaliService = inject(DaliService);
  private userStore: UserStoreService = inject(UserStoreService);
  private transactionStore: TransactionStoreService = inject(TransactionStoreService);
  private scenarioStore: ScenarioStoreService = inject(ScenarioStoreService);
  private importStore: ImportStoreService = inject(ImportStoreService);
  private dataPluginStore: DataPluginStoreService = inject(DataPluginStoreService);
  private calculationStore: CalculationStoreService = inject(CalculationStoreService);

  async initialise() {
    this.appState.startProcess("store-initialisation", "Processing your data"); // change that for something else?
    const data = await this.daliService.initialise();
    this.loadModels(data);
    this.initializeStores();
    this.appState.endProcess("store-initialisation");
  }

  loadModels(map: Map<VaultCodeEnum, StoreModel[]>) {
    for (const [daliType, models] of map.entries()) {
      const store = this.getStore(daliType);
      const modelName = this.getModelName(daliType);
      store.addStoreModels(modelName, models);
    }
  }

  initializeStores(): void {
    // todo order matter, most like need await
    this.importStore.initialize();
    this.userStore.initialize();
    this.dataPluginStore.initialize();
    this.transactionStore.initialize();
    this.calculationStore.initialize();
    this.scenarioStore.initialize();
  }

  clearStores(): void {
    this.scenarioStore.clearStore();
    this.calculationStore.clearStore();
    this.transactionStore.clearStore();
    this.dataPluginStore.clearStore();
    this.importStore.clearStore();
    this.userStore.clearStore();
  }

  private getModelName(vaultCodeEnum: VaultCodeEnum): StoreModelNames {
    return StoreModelNameByVaultCode[vaultCodeEnum] ?? "StoreModel";
  }

  private getStore(vaultCode: VaultCodeEnum): ModelStoreAbstraction {
    switch (vaultCode) {
      case VaultCodeEnum.Book:
        return this.userStore;
      case VaultCodeEnum.Institution:
        return this.userStore;
      case VaultCodeEnum.Category:
        return this.userStore;
      case VaultCodeEnum.Classification:
        return this.userStore;
      case VaultCodeEnum.ReferenceData:
        return this.transactionStore;
      case VaultCodeEnum.Estimate:
        return this.scenarioStore;
      case VaultCodeEnum.Symbol:
        return this.transactionStore;
      case VaultCodeEnum.Transaction:
        return this.transactionStore;
      case VaultCodeEnum.SourceTransaction:
        return this.importStore;
      case VaultCodeEnum.SourceBook:
        return this.importStore;
      case VaultCodeEnum.Preference:
        return this.userStore;
      case VaultCodeEnum.EstimateAction:
        return this.scenarioStore;
      case VaultCodeEnum.Scenario:
        return this.scenarioStore;
      case VaultCodeEnum.ScenarioGroup:
        return this.scenarioStore;
      case VaultCodeEnum.SourceCategory:
        return this.importStore;
      case VaultCodeEnum.Connector:
        return this.userStore;
      case VaultCodeEnum.VaultFile:
        return this.userStore;
      case VaultCodeEnum.Wizard:
        return this.userStore;
      case VaultCodeEnum.SyncStatus:
        return this.importStore;
      default:
        throw new Error("StoreLoaderService: Store Not Implemented.");
    }
  }
}

import { inject, signal } from "@angular/core";

import { ExternalCollection } from "../external.collection.abstraction";
import { CountriesConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";
import {
  ExternalCountriesResponseModel,
  ExternalCountryResponseModel,
} from "../../../models/store/external-models/external.country.store.model";
import { CountryView } from "@bitwarden/web-vault/app/models/view/country/country.view";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

// todo - implement CategoryView not here

export class ExternalCountriesStoreCollection extends ExternalCollection {
  protected dataRepositoryService: DataRepositoryService = inject(DataRepositoryService);

  private _theCountryViews = signal<CountryView[]>(null);
  readonly theCountryViews = this._theCountryViews.asReadonly();

  triggerChanges(): void {
    this.fetch().then((response: ExternalCountriesResponseModel) => {
      const countryViews = this.mapCountry(response.countries);
      this._theCountryViews.set(countryViews);
    });
  }

  clear() {
    this._theCountryViews.set(null);
  }

  async fetch(): Promise<ExternalCountriesResponseModel> {
    const countriesConfig = process.env.COUNTRIES as unknown as CountriesConfigType;
    return await this.dataRepositoryService.send(
      "GET",
      `${countriesConfig.endpoint.countries}`,
      null,
      true,
      true,
      `${countriesConfig.url}/${countriesConfig.apiStage}`,
    );
  }

  protected mapCountry(fetchedCountries: ExternalCountryResponseModel[]): CountryView[] {
    return fetchedCountries.map((country) => {
      return this.mapToView(country);
    });
  }

  protected mapToView(data: any): CountryView {
    return new CountryView(data);
  }
}

import { Component, OnDestroy, HostListener, AfterViewInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";

import { MessagingService } from "@bitwarden/common/abstractions/messaging.service";
import { HelpBoxComponent } from "@bitwarden/web-vault/app/gloss/help-box/help-box.component";
import { MainSettingsRoute } from "@bitwarden/web-vault/app/models/enum/routes.enum";

@Component({
  selector: "app-settings",
  templateUrl: "settings.component.html",
})
export class SettingsComponent implements OnDestroy, AfterViewInit {
  private currentView: "mobile" | "web";
  protected readonly MainSettingsRoute = MainSettingsRoute;
  private destroy$ = new Subject<void>();

  earlyRequestDialogRef: MatDialogRef<HelpBoxComponent>;

  constructor(
    public dialog: MatDialog,
    private messagingService: MessagingService,
  ) {}

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.checkScreenSize();
  }

  ngAfterViewInit() {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    const width = window.innerWidth;
    const mdValue = 767; // tailwind config

    if (width <= mdValue) {
      this.currentView = "mobile";
    } else if (width > mdValue) {
      this.currentView = "web";
    }
  }

  protected handleNavClick(currentView: string, destination: string) {
    if (currentView == this.currentView) {
      return this.getSettingsRoute(destination);
    }
  }

  closeEarlyRequestDialog() {
    this.earlyRequestDialogRef.close();
  }

  lock() {
    this.messagingService.send("lockVault");
  }

  logOut() {
    this.messagingService.send("logout");
  }

  protected getSettingsRoute(destination = "") {
    return [`/settings/${destination}`];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Institution } from "../../../../models/data/blobby/institution.data";
import { InstitutionStoreModel } from "../../../../models/store/institution.store.model";

export const institution_v0_to_v1 = (institution: Institution): InstitutionStoreModel => {
  return {
    v: 1,
    vid: "",
    id: institution.id,
    dc: new Date().toDateString(),
    dm: new Date().toDateString(),
    fk: institution.basiqId,
    name: institution.name,
    swt: {
      bac: institution.swift.bankCode,
      cc: institution.swift.countryCode,
      brc: institution.swift.branchCode,
      lc: institution.swift.locationCode,
    },
    avaAcc: institution?.availableAccounts?.map((account) => {
      return {
        id: account?.id,
        /** **name** **/
        nm: account?.name,
        /** **institutionName** **/
        istNm: account?.institutionName,
        /** **interestRates** **/
        intRts: account?.interestRates?.map((rates) => {
          return {
            rn: rates?.range,
            /** **banded** **/
            ba: rates?.banded,
            /** **rate** **/
            ra: rates?.rate,
            /** **symbol** **/
            sy: rates?.symbol,
            /** **last_updated** **/
            lu: rates?.last_updated,
            /** **condition_intrinsic_desc** **/
            cid: rates?.condition_intrinsic_desc,
            /** **condition_action_desc** **/
            cad: rates?.condition_action_desc,
          };
        }),
        /** **useForScenarioBestRate** **/
        ufsbr: account?.useForScenarioBestRate,
        /** **link** **/
        lk: account?.link,
      };
    }),
    bic: {
      bc: institution?.bic?.bankCode,
      cc: institution?.bic?.countryCode,
      lc: institution?.bic?.locationCode,
    },
  };
};

import { ExternalView } from "@bitwarden/web-vault/app/models/view/external.view";

export class CountryView extends ExternalView {
  name: string;
  code: string;

  constructor(apiData: any) {
    super();

    if (typeof apiData === "object") {
      this.name = apiData.name;
      this.code = apiData.code;
    }
  }
}

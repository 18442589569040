import { ConnectorStoreModel } from "../../../../models/store/connector.store.model";
import { Origin } from "../../../../models/types/general-types";
import { Connector } from "../../../../models/data/blobby/connector.data";

export const connector_v0_to_v1 = (connectorItem: Connector): ConnectorStoreModel => {
  return {
    dc: new Date().toString(),
    dm: new Date().toString(),
    id: connectorItem.id,
    v: 1,
    vid: "",
    n: connectorItem.name,
    o: connectorItem.origin as Origin,
    ins: connectorItem.institution,
    cinfo: connectorItem.connectionInfo,
    astat: connectorItem.accountStatus,
  };
};

import { Book } from "../../data/blobby/book.data";
import { GlossDate } from "../../data/shared/gloss-date";
import { DetailedBalance } from "../../types/balanceGroupingTypes";
import { Origin, TransactionStatusType } from "../../types/general-types";

import { Allocation } from "../../data/allocation.data";
import { GlossQuantity, GlossQuantityUtils } from "../../data/shared/gloss-quantity";
import { Valuation } from "../../data/valuation.data";
import { TransactionDirection } from "../../enum/transactionDirection";
import { SplitCategoryType } from "../../types/split-category-type";
import { SplitClassificationType } from "../../types/split-classification-type";
import { ModelView } from "@bitwarden/web-vault/app/models/view/model.view";
import {
  DefaultTransactionStoreModel,
  TransactionModelVersion,
  TransactionStoreModel,
} from "@bitwarden/web-vault/app/models/store/transaction.store.model";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";

import {
  LinkedProperty,
  LinkedView,
  TransactionLinkedProperties,
} from "@bitwarden/web-vault/app/models/view/linked.property";
import {
  AllocationCalculatedProperties,
  BalanceCalculatedProperties,
  CalculatedProperty,
  RevaluationCalculatedProperties,
  ValuationCalculatedProperties,
} from "@bitwarden/web-vault/app/models/view/calculated.property";

export class TransactionView
  extends ModelView<TransactionStoreModel>
  implements
    LinkedProperty<TransactionLinkedProperties>,
    CalculatedProperty<RevaluationCalculatedProperties>,
    CalculatedProperty<BalanceCalculatedProperties>,
    CalculatedProperty<AllocationCalculatedProperties>,
    CalculatedProperty<ValuationCalculatedProperties>
{
  protected version: TransactionModelVersion;

  /** @deprecated **/
  sourceId: string; // todo use LinkedView
  /** use the new link proterties type **/
  sourceLink: LinkedView = { id: "" };

  /** @deprecated **/
  accountId: string; // todo use LinkedView
  /** use the new link proterties type **/
  accountLink: LinkedView = { id: "" };

  /**
   * Use for filtering, those are calculated properties in the Transaction Store
   * @tag Transaction Store
   */
  c_institutionLink: LinkedView = { id: "" };

  /**
   * Use for filtering, those are calculated properties in the Transaction Store
   * @tag Transaction Store
   */
  c_country: LinkedView = { id: "" };

  bankImportedBalance: number;
  bankImportedBalanceOrigin: Origin;
  categories: SplitCategoryType[] = [];
  classifications: SplitClassificationType[] = [];
  description: string;
  direction: TransactionDirection;
  linkedTo: Array<string> = []; // todo use LinkedView
  estimateTransaction: boolean;
  quantity: GlossQuantity;
  glossDate: GlossDate;
  definition: TransactionStatusType;
  transactionDate: Date;
  kind: string;
  isDuplicate: boolean;

  // @deprecated
  mockAccountName: string;
  // @deprecated
  isSelected = false;

  // @deprecated
  baseTransaction: Transaction; /** linked to situations */

  // /** Calculated : Valuation */
  /** @deprecated **/
  isUsingValuationPrice: boolean;
  /** @deprecated **/
  valuationPrice: number;
  /** @deprecated **/
  valuation: Valuation;

  /**
   * Use for filtering, those are calculated properties in the Transaction Store
   * @tag Calculation Store
   */
  c_valuationPrice: number;
  /**
   * Use for filtering, those are calculated properties in the Transaction Store
   * @tag Calculation Store
   */
  c_isUsingValuationPrice: boolean;
  /**
   * Use for filtering, those are calculated properties in the Transaction Store
   * @tag Calculation Store
   */
  c_valuation: Valuation;

  /** Calculated: Allocation */
  /** @deprecated **/
  allocations: Allocation[];
  c_allocations: Allocation[];
  /** Calculated: Balance */
  /** @deprecated **/
  balance: number;
  c_balance: number;

  /** @deprecated **/
  dailyBalance: number;

  /** @deprecated **/
  detailedBalance: DetailedBalance;

  /** Calculated: Revaluation */
  /** @deprecated **/
  revalTransaction: boolean;
  c_revalTransaction: boolean;

  /** use this property now **/
  c_isRevaluation: boolean;

  isDeleted: boolean;

  constructor(transactionModel?: TransactionStoreModel) {
    super(transactionModel);
    if (!transactionModel) {
      // for legacy constructor we need to set the version for dali structure
      this.version = DefaultTransactionStoreModel.v;
      return;
    }

    if (typeof transactionModel === "object") {
      this.c_country = { id: "" };
      this.sourceId = transactionModel.srId;
      this.sourceLink.id = transactionModel.srId;
      this.accountId = transactionModel.acId;
      this.accountLink.id = transactionModel.acId;
      this.bankImportedBalance = transactionModel.bIB;
      this.bankImportedBalanceOrigin = transactionModel.bIBO;
      this.categories = transactionModel.cts;
      this.classifications = transactionModel.cls;
      this.description = transactionModel.dsc;
      this.direction = transactionModel.dir;
      this.valuationPrice = transactionModel.vlPc;
      this.linkedTo = transactionModel.lTid;
      this.quantity = GlossQuantityUtils.fromModel(transactionModel.qty);
      this.glossDate = GlossDate.fromModel(transactionModel.tDt);
      this.transactionDate = new Date(transactionModel.tDt.date);
      this.definition = transactionModel.dfn;
      this.kind = transactionModel.knd;
      this.isDeleted = transactionModel.del;
    } else {
      this.version = DefaultTransactionStoreModel.v;
    }
  }

  /**
   * @deprecated
   */
  legacyConstructor(transaction: Transaction): this {
    this.sourceId = transaction.sourceId;
    this.accountLink.id = transaction.accountId;
    this.allocations = transaction.allocations;
    this.bankImportedBalance = transaction.bankImportedBalance;
    this.categories = transaction.categories;
    this.classifications = transaction.classifications;
    this.description = transaction.description;
    this.direction = transaction.direction;
    this.valuationPrice = transaction.valuationPrice;
    //  this.balance = transaction.balance.;
    this.dateModified = new Date(transaction.dateModified);
    this.estimateTransaction = transaction.estimateTransaction;
    this.isUsingValuationPrice = transaction.isUsingValuationPrice;
    this.linkedTo = transaction.linkedTo;
    this.quantity = transaction.quantity;
    this.revalTransaction = transaction.revalTransaction;
    this.transactionDate = transaction.transactionDate.date;
    this.glossDate = transaction.transactionDate;
    this.valuation = transaction.valuation;
    this.definition = transaction.definition;
    this.baseTransaction = transaction;
    return this;
  }

  /**
   * @deprecated
   * */
  setMockAccount(mockAccounts: Array<Book>) {
    const mockAccount = mockAccounts.find((account) => account.id == this.accountId);
    if (mockAccount) {
      this.mockAccountName = mockAccount.name;
      //this.setSortableFieldsFromMockAccount();
    }
  }

  /**
   * @deprecated
   * */
  update(transaction: TransactionView | Transaction): TransactionView {
    if (transaction instanceof Transaction) {
      return this.legacyConstructor(transaction);
    }
    return transaction;
  }

  toStoreModel(): TransactionStoreModel {
    return {
      dc: this.dateCreated.toDateString(),
      dm: this.dateModified.toDateString(),
      id: this.id,
      v: this.version,
      vid: this.vaultId,
      srId: this.sourceLink.id,
      acId: this.accountLink.id,
      bIB: this.bankImportedBalance,
      bIBO: this.bankImportedBalanceOrigin,
      cts: this.categories,
      cls: this.classifications,
      dsc: this.description,
      dir: this.direction,
      vlPc: null,
      lTid: this.linkedTo,
      qty: {
        qty: {
          amt: this.quantity.actualQuantity.amount,
          sym: this.quantity.actualQuantity.symbol,
          prcs: this.quantity.actualQuantity.precision,
        },
        ccy: this.quantity.currency,
        cvsym: this.quantity.convsym,
        cvrt: this.quantity.convrate,
      },
      tDt: {
        date: this.transactionDate.toISOString(),
        time: "", // we need to get the time from the user
        timeZone: "", // we need to get the time zone from the user
      },
      dfn: this.definition,
      knd: this.kind,
    };
  }

  clone(): TransactionView {
    // Cary over the c_ field
    const copy = new TransactionView(this.toStoreModel());
    copy.c_country = this.c_country;
    copy.c_institutionLink = this.c_institutionLink;
    copy.c_isUsingValuationPrice = this.c_isUsingValuationPrice;
    copy.c_isRevaluation = this.c_isRevaluation;
    copy.c_valuation = this.c_valuation;
    copy.c_valuationPrice = this.c_valuationPrice;
    copy.c_allocations = this.c_allocations;
    copy.c_balance = this.c_balance;
    copy.c_revalTransaction = this.c_revalTransaction;

    // copy deprecated prop
    copy.allocations = this.allocations;
    copy.revalTransaction = this.revalTransaction;
    copy.balance = this.balance;
    copy.detailedBalance = this.detailedBalance;
    copy.dailyBalance = this.dailyBalance;
    copy.isUsingValuationPrice = this.isUsingValuationPrice;
    copy.valuation = this.valuation;

    return copy;
  }
}

import { inject, Injectable, signal, WritableSignal } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { Institution } from "../../../models/data/blobby/institution.data";
import { DataPluginStoreService } from "@bitwarden/web-vault/app/services/store/data-plugin/data-plugin.store.service";
import { toObservable } from "@angular/core/rxjs-interop";
import { Observable } from "rxjs";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { InstitutionAccountView } from "@bitwarden/web-vault/app/models/view/institution/institution-account.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { CountryView } from "@bitwarden/web-vault/app/models/view/country/country.view";

@Injectable({
  providedIn: "root",
})
export class InstitutionService {
  protected userLocation: string;
  isExistingUser: boolean;

  institutionsList: WritableSignal<InstitutionView[]> = signal<InstitutionView[]>([]);
  countriesList: WritableSignal<Country[]> = signal<Country[]>([]);

  private dataPluginStoreService: DataPluginStoreService;
  private userStoreService: UserStoreService;
  theInstitutionList$: Observable<InstitutionView[]>;
  theCountryList$: Observable<CountryView[]>;

  constructor(
    private logService: LogService,
    private dataRepositoryService: DataRepositoryService,
  ) {
    this.dataPluginStoreService = inject(DataPluginStoreService);
    this.userStoreService = inject(UserStoreService);
    this.theInstitutionList$ = toObservable(
      this.dataPluginStoreService.theInstitutionList.theInstitutionViews,
    );
    this.theCountryList$ = toObservable(this.dataPluginStoreService.theCountryList.theCountryViews);
    this.theCountryList$.subscribe((countries) => {
      this.countriesList.set(countries);
    });

    this.theInstitutionList$.subscribe((institutions) => {
      this.institutionsList.set(institutions);
    });
  }

  /**
   * @deprecated
   */
  async create(newInstitution: InstitutionView): Promise<InstitutionView> {
    try {
      await this.userStoreService.institutions.save(newInstitution);
      return newInstitution;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async update(institution: Institution, triggerObservable: boolean): Promise<Institution> {
    try {
      const updatedInstitution = await this.dataRepositoryService.updateInstitution(institution);
      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return updatedInstitution;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async delete(institution: Institution, triggerObservable: boolean): Promise<boolean> {
    try {
      const success = await this.dataRepositoryService.deleteInstitution(institution);

      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return success;
    } catch (e) {
      this.logService.error(e.message);
      throw e;
    }
  }

  /**
   * @deprecated
   */
  async getAll(): Promise<Institution[]> {
    return await this.dataRepositoryService.getAllInstitutions();
  }
  /**
   * @deprecated
   */
  async filterInstitutionAccountById(
    institutionAccountId: string,
    institution: InstitutionView,
  ): Promise<InstitutionAccountView> {
    if (institution?.availableAccounts) {
      const institutionAccounts = institution.availableAccounts;
      return institutionAccounts.find((a) => a.id === institutionAccountId);
    }
  }

  /**
   * @deprecated
   */
  async getInstitutionById(institutionId: string): Promise<InstitutionView> {
    /*const institutions = await this.dataRepositoryService.getAllInstitutions();*/
    const institutions = this.dataPluginStoreService.theInstitutionList.theInstitutionViews();
    return institutions?.find((i) => i.id === institutionId);
  }

  /**
   * @deprecated
   */
  async getCountryInstitutions(selectedCountry: Country) {
    const institutions = await this.getInstitutionsMasterList();
    return institutions.filter((institution) => {
      if (institution instanceof Institution) {
        return institution.bic.countryCode === selectedCountry.code;
      }
      return institution.bic.countryCode === selectedCountry.code;
    });
  }

  /**
   * @deprecated
   */
  getInstitutionsMasterList(): InstitutionView[] {
    return this.institutionsList();
  }

  /**
   * @deprecated
   */
  getCountriesMasterListObservable(): Country[] {
    return this.countriesList();
  }

  /**
   * @deprecated
   */
  getCountryMasterList(): Country[] {
    return this.countriesList();
  }

  /**
   * @deprecated
   */
  async updateInstitution(institution: Institution) {
    return await this.dataRepositoryService.updateInstitution(institution);
  }

  /**
   * @deprecated
   */
  async updateInstitutionView(institution: InstitutionView) {
    return await this.userStoreService.institutions.save(institution);
  }

  /**
   * @deprecated
   */
  async isInstitutionExist(institution: InstitutionView): Promise<boolean> {
    const institutions = this.userStoreService.institutions.institutionViews();
    return institutions.some((inst) => inst.name === institution.name);
  }
}

<ng-container>
  <div
    class="side-menu tw-flex tw-flex-col tw-h-screen tw-text-center tw-text-[16px] tw-font-medium tw-text-neutral-800 tw-whitespace-nowrap"
  >
    <img
      class="tw-my-3 tw-w-[65%] tw-mx-auto"
      alt="Gloss Logo"
      src="../../../../images/icons/gloss-logo.png"
    />
    @for (item of navItems; track item) {
      <ng-container *appRoleScope="item.roleScope">
        <nav
          class="tw-flex tw-flex-col tw-mx-3 tw-items-center tw-gap-2 tw-px-6 tw-py-4 tw-rounded-lg hover:tw-bg-teal-600 hover:tw-bg-opacity-25 tw-cursor-pointer"
          [routerLink]="item.routerLink"
          [routerLinkActive]="['tw-bg-light', 'tw-text-neutral-800', 'tw-shadow-md']"
          (isActiveChange)="onIsActiveChange($event, item.key)"
        >
          <svg class="tw-h-8 tw-w-8" [ngClass]="{ 'tw-stroke-primary': selectedKey !== item.key }">
            <use [attr.xlink:href]="'images/sprite.svg#' + getSelectedIcon(item)" />
          </svg>
          <span>{{ item.label | i18n }}</span>
        </nav>
      </ng-container>
    }

    <div class="tw-mt-auto" (click)="openHelpBox()">
      <nav
        class="tw-flex tw-flex-col tw-mx-3 tw-items-center tw-gap-2 tw-px-6 tw-py-4 tw-rounded-lg hover:bg-teal-600 hover:tw-bg-opacity-25 tw-cursor-pointer"
      >
        <icon [name]="'help'" [size]="8"></icon>
        <span>{{ "help" | i18n }}</span>
      </nav>
    </div>
  </div>
</ng-container>

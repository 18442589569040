import { Institution } from "../../models/data/blobby/institution.data";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { getDateOnly } from "@bitwarden/web-vault/app/shared/utils/helper-string";

export const columnDefinition = [
  {
    headerName: "File name",
    headerClass: "wrap-header-text",
    field: "name",
    valueGetter: (params: any) => params.data?.name,
    cellClass: "truncate-text",
  },
  {
    headerName: "Statement period",
    headerClass: "wrap-header-text",
    field: "statementFromDate",
    valueGetter: (params: any) =>
      getDateOnly(params.data?.statementFromDate) +
      " - " +
      getDateOnly(params.data?.statementToDate),
    cellClass: "wrap-text",
  },
  {
    headerName: "Bank",
    headerClass: "wrap-header-text",
    field: "institution",
    valueGetter: (params: any) =>
      params.data?.banks.map((bank: Institution) => bank.name).join(", "),
    cellClass: "wrap-text",
  },
  {
    headerName: "Account Name",
    headerClass: "wrap-header-text",
    field: "statementAccounts",
    valueGetter: (params: any) =>
      params.data?.statementAccounts.map((account: AccountView) => account.name).join(", "),
    cellClass: "wrap-text",
  },
];

import { Injectable, signal, WritableSignal } from "@angular/core";
import { BehaviorSubject, filter, Observable } from "rxjs";

import { MainProgressBar } from "../../../services/progress-bar/main-progress-bar";

import { UserStoreService } from "../../../services/store/user/user.store.service";

import { StepView } from "./step.view";
import { WizardView } from "../../../models/view/wizard/wizard.view";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class WizardService {
  private renewStep$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private syncedAccount$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private buttonClickSubject = new BehaviorSubject<boolean>(false);
  buttonClick$ = this.buttonClickSubject.asObservable();
  private isWizardInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _daliWizard: WizardView;
  private _daliWizardArray: WizardView[];

  //steps array for the wizard stepper
  steps: StepView[] = [];

  //main wizard in the account has 3 steps
  private accountWizard = 3;

  wizardViews$: Observable<WizardView[]> = toObservable(this.userStore.wizards.wizardViews);

  constructor(
    private userStore: UserStoreService,
    private mainProgressBar: MainProgressBar,
  ) {
    this.wizardViews$
      .pipe(
        filter((wizardViews) => wizardViews !== null),
        takeUntilDestroyed(),
      )
      .subscribe((wizardViews) => {
        this._daliWizardArray = wizardViews;
        if (wizardViews.length > 0) {
          this._daliWizard = wizardViews[0];
          this.steps = this.getStepsCalculate(this._daliWizard.step, this.accountWizard);
        }
      });
  }

  getIsWizardInProgress() {
    return this.isWizardInProgress$;
  }

  async startWizard(): Promise<void> {
    if (this._daliWizardArray && this._daliWizardArray.length > 0) {
      this._daliWizard = this._daliWizardArray[0];
    }
  }

  async restartWizardAndSetStepTo(stepIndex: number, isComplete: boolean): Promise<void> {}

  // from the dali wizard to calculate the whole steps array
  getStepsCalculate(step: StepView, stepNumber: number): StepView[] {
    const steps: StepView[] = [];
    for (let i = 0; i < stepNumber; i++) {
      if (i < step.stepIndex - 1) {
        steps.push({ stepIndex: i + 1, isComplete: true });
      } else if (i === step.stepIndex - 1) {
        steps.push(step);
      } else {
        steps.push({ stepIndex: i + 1, isComplete: false });
      }
    }
    return steps;
  }

  async restartWizardInProgress() {
    await this.startWizard();
  }

  restartWizard() {
    this.renewStep$.next(true);
  }

  isSyncedAccount() {
    return this.syncedAccount$.asObservable();
  }

  emitButtonClick(value: boolean) {
    this.buttonClickSubject.next(value);
  }

  async resetWizardTo(step: StepView) {
    const wizards: WizardView[] = this.userStore.wizards.wizardViews();
    if (!wizards?.length) {
      return;
    }
    const lastWizard = wizards[wizards.length - 1].clone();

    lastWizard.step = step;

    await this.userStore.wizards.save(lastWizard);
  }
}

import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { SyncIcon } from "../../../../../models/enum/sync.enum";
import { SyncStatusView } from "../../../../../models/view/sync-status/sync-status.view";

@Component({
  selector: "app-account-status-renderer",
  templateUrl: "./account-status-renderer.component.html",
})
export class AccountStatusRendererComponent implements ICellRendererAngularComp {
  statusPoint!: SyncStatusView;

  agInit(params: ICellRendererParams): void {
    this.statusPoint = params.data.statusPoint || "Unavailable status";
  }

  refresh(params: ICellRendererParams): boolean {
    this.statusPoint = params.data.statusPoint || "Unavailable status";
    return true;
  }

  protected readonly SyncIcon = SyncIcon;
}

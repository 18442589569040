export const removeSpace = (string: string): string => {
  return string?.toLowerCase().replace(/\s/g, "");
};

export const addAsterisk = (label: string, isRequired: boolean): string => {
  return isRequired ? label + " *" : label;
};

export const extractFileType = (file: File) => {
  return file.name.split(".").pop();
};

export const getDateOnly = (date: Date | string) => {
  return date instanceof Date ? date.toISOString().split("T")[0] : date.split("T")[0];
};

export const getName = (itemViews: any, linkId: string) => {
  return itemViews.find((item: any) => item.id === linkId)?.name;
};

import { InstitutionFilter } from "@bitwarden/web-vault/app/models/types/filter.types";
import { InstitutionView } from "@bitwarden/web-vault/app/models/view/institution/institution.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { inject, Injectable } from "@angular/core";
import { FilterControlType } from "@bitwarden/web-vault/app/services/store/calculation/controls/filter.control";
import { CountryView } from "@bitwarden/web-vault/app/models/view/country/country.view";
import { ViewsFilterAccountHelper } from "@bitwarden/web-vault/app/gloss/views/views-control/views-customize/views.filter.helper.accounts";

@Injectable({
  providedIn: "root",
})
export class ViewsFilterInstitutionHelper {
  private userStore = inject(UserStoreService);
  private accountFilterHelper = inject(ViewsFilterAccountHelper);
  private accountViews = this.userStore.accounts.accountViews();
  institutionViews = this.userStore.institutions.institutionViews();

  getFilter(selected: FilterControlType, countryView: CountryView): Map<string, InstitutionFilter> {
    return this.institutionFilterForCountry(selected, countryView);
  }

  private institutionFilterForCountry(selected: FilterControlType, countryView: CountryView) {
    const isSelectedCountry = selected.countries.includes(countryView.code);
    const institutionFilterMap: Map<string, InstitutionFilter> = new Map();
    const countryInstitutions = this.institutionsOfCountry(countryView);

    for (const institutionView of countryInstitutions) {
      const isSelectedInsto = selected.institutions.includes(institutionView.id);
      const instoFilter: InstitutionFilter = {
        institutionView: institutionView,
        accountFilter: this.accountFilterHelper.getFilter(selected, institutionView),
        isSelected: isSelectedCountry && isSelectedInsto,
        isDisabled: !isSelectedCountry,
      };

      institutionFilterMap.set(institutionView.id, instoFilter);
    }
    return institutionFilterMap;
  }

  private institutionsOfCountry(countryView: CountryView) {
    return this.institutionViews.filter((institution) => {
      const isCountry = institution.swift.countryCode === countryView.code;
      const hasAccounts = this.accountViews.some(
        (av) => av.institutionLink.institutionId === institution.id,
      );
      return isCountry && hasAccounts;
    });
  }
}

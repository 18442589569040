import { BaseResponse } from "@bitwarden/common/models/response/base.response";
import { SyncStatusCollectionType } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { StringToNumberPreference } from "@bitwarden/web-vault/app/models/types/PrefereneceTypes";
import { UserLocationType } from "@bitwarden/web-vault/app/models/types/location-currency.types";

export class PreferenceResponse extends BaseResponse {
  __v: number;
  id: string;
  baseCurrency: string;
  timeZone: string;
  dateFormat: string;
  mode: string;
  weekDayStart: StringToNumberPreference;
  YearMonthStart: StringToNumberPreference;
  monthDayStart: number;
  syncStatus: SyncStatusCollectionType;
  userLocation: UserLocationType;

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    this.baseCurrency = this.getResponseProperty("baseCurrency");
    this.timeZone = this.getResponseProperty("timeZone");
    this.dateFormat = this.getResponseProperty("dateFormat");
    this.mode = this.getResponseProperty("mode");
    this.weekDayStart = this.getResponseProperty("weekDayStart");
    this.monthDayStart = this.getResponseProperty("monthDayStart");
    this.YearMonthStart = this.getResponseProperty("YearMonthStart");
    this.syncStatus = this.getResponseProperty("syncStatus");
    this.userLocation = this.getResponseProperty("userLocation");
  }
}

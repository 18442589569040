import { Injectable } from "@angular/core";

import { DateFormatIndex } from "@bitwarden/web-vault/app/models/types/general-types";

import { BaseImporter } from "../../../../../libs/common/src/importers/base-importer";
import { ImportTransactionResult } from "../../../../../libs/common/src/models/domain/import-transaction-result";

import { TransactionImporter } from "./transaction-importer";

@Injectable({
  providedIn: "root",
})
export class TransactionPlaidImporter extends BaseImporter implements TransactionImporter {
  account: string;

  getPlaidAccountName(transaction: any, plaidAccounts: any[]) {
    const account = plaidAccounts.find(
      (plaidAccount) => plaidAccount.account_id === transaction.account_id,
    );
    return account.name;
  }
  async getAccountedTransactionsOfPlaid(plaidFormattedTransactions: any[], plaidAccounts: any[]) {
    plaidFormattedTransactions.forEach((transaction) => {
      /** set transaction accountId to account's name . It is gonna be compared with source account down the road */
      transaction.account_id = this.getPlaidAccountName(transaction, plaidAccounts);
    });

    return plaidFormattedTransactions;
  }

  async parse(transactions: any[]): Promise<ImportTransactionResult> {
    const result = new ImportTransactionResult();
    const dateFormatPlaid: DateFormatIndex = {
      year: 0,
      month: 1,
      day: 2,
      separator: "-",
      format: "YYYY-MM-DD",
    };

    for (const transaction of transactions) {
      if (transaction.category) {
        const categories = [];
        for (const cat of transaction.category) {
          categories.push({ name: cat });
        }
        transaction.category = categories;
        result.transactions.push(transaction);
      }
    }

    result.dateFormatIndex = dateFormatPlaid;

    return result;
  }
}

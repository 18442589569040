import { Component, EventEmitter, Output, OnInit, inject, computed, Signal } from "@angular/core";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { DataPluginStoreService } from "@bitwarden/web-vault/app/services/store/data-plugin/data-plugin.store.service";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { AppStateStoreService } from "@bitwarden/web-vault/app/services/store/app-state/app-state.store.service";
import { CountryView } from "@bitwarden/web-vault/app/models/view/country/country.view";

@Component({
  selector: "app-countries",
  templateUrl: "./countries.component.html",
})
export class CountriesComponent {
  @Output() selectedCountry: EventEmitter<Country> = new EventEmitter<Country>();
  @Output() selectedCountries: EventEmitter<Country[]> = new EventEmitter<Country[]>();

  protected appState: AppStateStoreService = inject(AppStateStoreService);
  dataPluginStoreService: DataPluginStoreService;
  constructor(private userStore: UserStoreService) {
    this.dataPluginStoreService = inject(DataPluginStoreService);
  }

  countries: Signal<CountryView[]> = computed((): CountryView[] => {
    const countries = this.dataPluginStoreService.theCountryList.theCountryViews() ?? [];
    if (this.appState.wizard().isVisible === true) {
      return countries.filter(
        (country) => country.code === this.userStore.preferences.preferenceView()?.userLocation,
      );
    } else {
      return countries;
    }
  });

  preselectedValue = computed(() => {
    if (this.appState.wizard().isVisible && this.countries().length > 0) {
      return this.countries()[0].code;
    } else {
      return "";
    }
  });

  countrySelected(country: Country) {
    if (this.selectedCountry) {
      this.selectedCountry.emit(country);
    }
  }

  selectionCleared() {
    this.selectedCountry.emit(null);
  }
}

import { addDays } from "date-fns";

import { ConsoleLogService } from "@bitwarden/common/services/consoleLog.service";
import { EstimateActionData } from "@bitwarden/web-vault/app/models/data/blobby/estimate.action.data";
import { EstimateActionResponse } from "@bitwarden/web-vault/app/models/data/response/estimate-action.response";
import {
  InterestParameters,
  InterestOutput,
} from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import { InstitutionInterest } from "@bitwarden/web-vault/app/models/types/institution.type";
import { CreatedRecords } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { TransactionBalanceHelpers } from "@bitwarden/web-vault/app/services/DataCalculationService/transactionBalances/transactionBalanceHelpers";
import { createTransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.utils";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";

export class InterestAction extends EstimateActionData {
  parameters: InterestParameters;
  createdRecords: InterestOutput;
  NUMBER_DAYS_INTEREST_CALCULATED = 365;
  logger: ConsoleLogService;

  constructor(response: EstimateActionResponse) {
    super(response);
    this.logger = new ConsoleLogService(false);
  }

  setParameters(parameters: InterestParameters) {
    this.parameters = parameters;
  }

  /**
   * check if the date is the end of the month
   * */
  isEndOfMonth(date: Date): boolean {
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    return nextDay.getDate() === 1;
  }

  async run(
    parameters: InterestParameters,
    createdRecords?: CreatedRecords,
  ): Promise<InterestOutput> {
    const transactionBalanceHelpers = new TransactionBalanceHelpers();
    this.fillInParameters(createdRecords, parameters);
    await super.run(parameters, createdRecords);

    const transactions: Array<TransactionView> = [];

    const endDateTime = new Date(this.parameters.endDate.valueOf()).setHours(0, 0, 0, 0).valueOf();
    let currentDate = new Date(this.parameters.startDate.valueOf());
    // push first interest day to day after the anchor point
    currentDate = addDays(currentDate, 1);
    currentDate.setHours(0, 0, 0, 0);

    // Reset the monthly amount
    let monthlyInterestAmount = 0;

    while (currentDate.getTime() <= endDateTime) {
      // Process each day of the interest calculation
      const interestAmount = await this.getDailyInterestAmount(
        currentDate,
        transactionBalanceHelpers,
      );
      monthlyInterestAmount = monthlyInterestAmount + interestAmount;

      if (this.isEndOfMonth(currentDate)) {
        const interestTransaction = await this.createInterestTransaction(
          currentDate,
          monthlyInterestAmount,
        );

        transactionBalanceHelpers.addTransactionToBalanceByAccounts(
          this.runningAccountBalances,
          interestTransaction,
        );

        transactions.push(interestTransaction);

        // Reset the monthly amount
        monthlyInterestAmount = 0;
      }

      // add a day to the currentDate
      currentDate = addDays(currentDate, 1);
    }

    // run the calculations for the interest
    this.createdRecords = {
      transactions: transactions,
      runningAccountBalances: this.runningAccountBalances,
    };

    // console.log('end interest creation');
    return this.createdRecords;
  }

  async getDailyInterestAmount(
    currentDate: Date,
    transactionBalanceHelpers: TransactionBalanceHelpers,
  ): Promise<number> {
    // check for any transactions from user estimates that need to be added to the calculation before interest calc
    for (const transaction of this.parameters.userGeneratedEstimateTransactions) {
      const transactionDate = new Date(transaction.transactionDate);
      transactionDate.setHours(0, 0, 0, 0);

      if (transactionDate.valueOf() === currentDate.valueOf()) {
        transactionBalanceHelpers.addTransactionToBalanceByAccounts(
          this.runningAccountBalances,
          transaction,
        );
      }
    }

    // calculate the interest number for the day
    let accountBalance = 0;
    if (this.runningAccountBalances?.[this.parameters.account.id]?.[this.parameters.currency]) {
      accountBalance =
        this.runningAccountBalances[this.parameters.account.id][this.parameters.currency];
    }

    let interestAmount = 0;
    if (accountBalance > 0) {
      interestAmount = await this.getTotalInterestEarning(
        accountBalance,
        this.parameters.interestRates,
      );
    }

    return interestAmount;
  }

  /**
   * createInterestTransaction - given a date and an amount, create an interest transaction for it
   *
   * @param transactionDate
   * @param monthlyInterestAmount
   */
  async createInterestTransaction(
    transactionDate: Date,
    monthlyInterestAmount: number,
  ): Promise<TransactionView> {
    const description = "Monthly Interest Amount";

    return createTransactionView(
      this.parameters.account.id,
      this.parameters.account.institutionLink.institutionId,
      monthlyInterestAmount,
      this.parameters.currency,
      transactionDate,
      description,
      this.parameters.defaultSplitClassification,
      this.parameters.defaultSplitCategory,
    );
  }

  private async getTotalInterestEarning(
    accountBalance: number,
    interestRates: InstitutionInterest[],
  ): Promise<number> {
    if (accountBalance === 0) {
      return 0;
    }
    const sortedInterestRates = interestRates.sort((a, b) => a.range - b.range);
    if (this.shouldApplyBanded(sortedInterestRates)) {
      return this.getTotalBandedInterestEarning(accountBalance, sortedInterestRates);
    } else {
      return this.getTotalNonBandedInterestEarning(accountBalance, sortedInterestRates);
    }
  }

  private getEarningOfNonBandedInterest(
    accountBalance: number,
    institutionInterest: InstitutionInterest,
  ): number {
    return (
      (accountBalance * (institutionInterest.rate / this.NUMBER_DAYS_INTEREST_CALCULATED)) / 100
    );
  }

  private shouldApplyBanded(sortedInterestRates: InstitutionInterest[]) {
    if (sortedInterestRates.length > 0) {
      return sortedInterestRates[0].banded;
    } else {
      return false;
    }
  }

  private getTotalBandedInterestEarning(
    accountBalance: number,
    interestRates: InstitutionInterest[],
  ): number {
    let defaultRate;
    let previousRange = 0;
    let earning = 0;
    for (const interestRate of interestRates) {
      // put aside the default rate for last
      if (interestRate.range === -1) {
        defaultRate = interestRate;
        continue;
      }

      if (accountBalance >= previousRange) {
        const interestEarning = this.getEarningOfBandedInterest(
          previousRange,
          accountBalance,
          interestRate,
        );
        previousRange = interestRate.range;
        earning += interestEarning;
      }
    }

    // add the default on for any remaining balance
    if (defaultRate && accountBalance >= previousRange) {
      const interestEarning = this.getEarningOfBandedInterest(
        previousRange,
        accountBalance,
        defaultRate,
      );
      earning += interestEarning;
    }

    return earning;
  }

  private getEarningOfBandedInterest(
    previousRange: number,
    accountBalance: number,
    interestRate: InstitutionInterest,
  ): number {
    let amountEarningInterest = 0;
    if (interestRate) {
      if (interestRate.range === -1 || accountBalance < interestRate.range) {
        amountEarningInterest = accountBalance - previousRange;
      } else {
        amountEarningInterest = interestRate.range - previousRange;
      }
      return (
        (amountEarningInterest * (interestRate.rate / this.NUMBER_DAYS_INTEREST_CALCULATED)) / 100
      );
    }
    return 0;
  }

  private getApplicableNonBandedInterest(
    accountBalance: number,
    interestRates: InstitutionInterest[],
  ): InstitutionInterest {
    if (interestRates.length === 0 || accountBalance === 0) {
      return;
    }
    let defaultRate;
    for (const interestRate of interestRates) {
      if (interestRate.range === -1) {
        defaultRate = interestRate;
        continue;
      }
      if (accountBalance <= interestRate.range) {
        return interestRate;
      }
    }
    if (defaultRate) {
      return defaultRate;
    }
    return;
  }

  private getTotalNonBandedInterestEarning(
    accountBalance: number,
    interestRates: InstitutionInterest[],
  ): number {
    const applicableInterest: InstitutionInterest = this.getApplicableNonBandedInterest(
      accountBalance,
      interestRates,
    );
    if (applicableInterest) {
      return this.getEarningOfNonBandedInterest(accountBalance, applicableInterest);
    }
  }

  fillInParameters(createdRecords: CreatedRecords, parameter: InterestParameters) {
    if (parameter?.account === null && createdRecords.accounts.length > 0) {
      parameter.account = createdRecords.accounts[0];
    }
  }
}

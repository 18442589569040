import { StoreData } from "./store.data";
import { ReferenceId } from "@bitwarden/web-vault/app/models/view/reference-data/reference-data.view";

export type ReferenceDataModelVersion = 1;
/** @Sinan we need to set the id as data|base|cross */
export interface ReferenceDataStoreModel extends StoreData {
  /**  **Version Number** **/
  v: ReferenceDataModelVersion;

  id: ReferenceId;
  rate: number;
}

/** Default values for the reference data store model */
export const DefaultReferenceDataStoreModel: ReferenceDataStoreModel = {
  v: 1,
  vid: "",
  dc: "",
  dm: "",
  id: null,
  rate: null,
};

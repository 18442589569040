import { StoreData } from "./store.data";
import { AccountState, Origin } from "../types/general-types";
import { ConnectionInfoType } from "../types/connector.type";
import { CategoryStoreModel } from "./category.store.model";
import { GlossDate } from "../data/shared/gloss-date";
import { Institution } from "../data/blobby/institution.data";

export type ConnectorModelVersion = 1;
export interface ConnectorStoreModel extends StoreData {
  v: ConnectorModelVersion;

  /** Connector Name */
  n: string;

  /** Connector Origin */
  o: Origin;

  /** Connector Institutions */
  ins: Institution[];

  /** Connector Connection Info */
  cinfo: ConnectionInfoType;

  /** Connector Account Status */
  astat: AccountState[]; // todo @sinan need to make sure that type doesnt contain class that needs to be instanciated
}

export const DefaultConnectorStoreModel: ConnectorStoreModel = {
  dc: "",
  dm: "",
  id: "",
  v: 1,
  vid: "",
  n: "",
  o: Origin.manual,
  ins: [],
  cinfo: undefined,
  astat: [],
};

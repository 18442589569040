import { ParserFunctionType } from "./parser.type";
import { BookParser } from "./parser/book.parser";
import { NoopParser } from "./parser/noop.parser";
import { InstitutionParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/institution.parser";
import { VaultCodeEnum } from "@bitwarden/web-vault/app/services/dali/type/dali.type";
import { VaultFileParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/vault-file.parser";
import { WizardParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/wizard.parser";
import { TransactionParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/transaction.parser";
import { CategoryParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/category.parser";
import { SourceTransactionParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/source-transaction.parser";
import { SourceCategoryParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/source-category.parser";
import { ClassificationParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/classification.parser";
import { SourceBookParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/source-book.parser";
import { ScenarioGroupParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/scenario-group.parser";
import { EstimateActionParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/estimate.action.parser";
import { ScenarioParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/scenario.parser";
import { ReferenceDataParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/reference-data.parser";
import { ConnectorParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/connector.parser";
import { PreferenceParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/preference.parser";
import { EstimateParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/estimate.parser";
import { SyncStatusParser } from "@bitwarden/web-vault/app/services/dali/vault-parser/parser/sync-status.parser";

/**
 * A factory class responsible for creating and managing parsers based on given vault codes and item versions.
 * The ParserFactory class uses a map to maintain a cache of generated parsers to optimize performance
 * and avoid redundant parser creation for the same vault code and item version combination.
 */
export class ParserFactory {
  parserMap: Map<string, ParserFunctionType> = new Map();

  /**
   * Retrieves or generates a parser function corresponding to the provided vault code and item version.
   *
   * @param {string} vaultCode - A string representing the unique identifier for the vault.
   * @param {number} itemVersion - A string representing the version of the item.
   * @return {ParserFunctionType} The parser function associated with the specified vault code and item version.
   */
  getParserFunction(vaultCode: string, itemVersion: number): ParserFunctionType {
    const key = this.getKey(vaultCode, itemVersion);
    if (this.parserMap.has(key)) {
      return this.parserMap.get(key);
    } else {
      this.parserMap.set(key, this.getParser(vaultCode, itemVersion));
      return this.parserMap.get(key);
    }
  }

  /**
   * Generates a unique key by combining the vault code and item version for caching the parser
   *
   * @param {string} vaultCode - The code representing the vault.
   * @param {string} itemVersion - The version of the item.
   * @return {string} A concatenated string representing the unique key.
   */
  private getKey(vaultCode: string, itemVersion: number): string {
    return `${vaultCode}_v${itemVersion}`;
  }

  /**
   * Retrieves the appropriate parser function based on the given vault code and item version.
   *
   * @param {string} vaultCode - The identifier for the specific vault type.
   * @param {string} itemVersion - The version of the item to be parsed.
   * @return {ParserFunctionType} The parser function corresponding to the given vault code and item version.
   */
  private getParser(vaultCode: string, itemVersion: number): ParserFunctionType {
    switch (vaultCode) {
      case VaultCodeEnum.Book:
        return new BookParser().getParser(itemVersion);
      case VaultCodeEnum.Institution:
        return new InstitutionParser().getParser(itemVersion);
      case VaultCodeEnum.Category:
        return new CategoryParser().getParser(itemVersion);
      case VaultCodeEnum.Classification:
        return new ClassificationParser().getParser(itemVersion);
      case VaultCodeEnum.ReferenceData:
        return new ReferenceDataParser().getParser(itemVersion);
      case VaultCodeEnum.Estimate:
        return new EstimateParser().getParser(itemVersion);
      case VaultCodeEnum.Symbol:
        return new NoopParser().getParser(itemVersion);
      case VaultCodeEnum.Transaction:
        return new TransactionParser().getParser(itemVersion);
      case VaultCodeEnum.SourceTransaction:
        return new SourceTransactionParser().getParser(itemVersion);
      case VaultCodeEnum.SourceBook:
        return new SourceBookParser().getParser(itemVersion);
      case VaultCodeEnum.Preference:
        return new PreferenceParser().getParser(itemVersion);
      case VaultCodeEnum.EstimateAction:
        return new EstimateActionParser().getParser(itemVersion);
      case VaultCodeEnum.Scenario:
        return new ScenarioParser().getParser(itemVersion);
      case VaultCodeEnum.ScenarioGroup:
        return new ScenarioGroupParser().getParser(itemVersion);
      case VaultCodeEnum.SourceCategory:
        return new SourceCategoryParser().getParser(itemVersion);
      case VaultCodeEnum.Connector:
        return new ConnectorParser().getParser(itemVersion);
      case VaultCodeEnum.VaultFile:
        return new VaultFileParser().getParser(itemVersion);
      case VaultCodeEnum.Wizard:
        return new WizardParser().getParser(itemVersion);
      case VaultCodeEnum.SyncStatus:
        return new SyncStatusParser().getParser(itemVersion);
      default:
        return new NoopParser().getParser(itemVersion);
    }
  }
}

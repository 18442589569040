import {
  Component,
  inject,
  Injector,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { SymbolForm } from "@bitwarden/web-vault/app/gloss/manage/symbol/symbol-form/symbol-form";
import { SymbolView } from "@bitwarden/web-vault/app/models/view/symbol/symbol.view";
import { AllowedCurrencies } from "@bitwarden/web-vault/app/models/types/location-currency.types";

@Component({
  selector: "app-symbol-form",
  templateUrl: "./symbol-form.component.html",
})
export class SymbolFormComponent implements OnInit, OnChanges {
  protected i18nService = inject(I18nService);
  protected readonly AllowedCurrencies = AllowedCurrencies;

  @Input() isEditMode: boolean;
  @Input() currency: string;
  @Input() symbol: SymbolView;

  symbolForm: SymbolForm;

  constructor(private injector: Injector) {
    this.injector = injector; // todo remove injector
  }

  ngOnInit() {
    this.symbolForm = new SymbolForm(this.injector, this.isEditMode, this.symbol); // @Input only takes effect on ngOnInit
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["symbol"] && changes["symbol"].currentValue) {
      this.symbolForm = new SymbolForm(this.injector, this.isEditMode, this.symbol);
    }
  }
}

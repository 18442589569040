import { inject, Injectable } from "@angular/core";

import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";

import { SourceTransaction } from "../../../../models/data/blobby/source-transaction.data";
import { BasiqIoTransactionType } from "../../../../models/types/basiq-io-transaction.types";
import { validationResult } from "../../../../validators/base-validator";

import { CategoryStoreModel } from "@bitwarden/web-vault/app/models/store/category.store.model";
import { CategoryView } from "@bitwarden/web-vault/app/models/view/category/category.view";
import { UserStoreService } from "@bitwarden/web-vault/app/services/store/user/user.store.service";
import { SourceCategoryView } from "@bitwarden/web-vault/app/models/view/source-category/source-category.view";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account/account.view";
import { TransactionStoreModel } from "@bitwarden/web-vault/app/models/store/transaction.store.model";
import { SourceTransactionsView } from "@bitwarden/web-vault/app/models/view/source-transactions/source-transactions.view";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { SourceTransactionStoreModel } from "@bitwarden/web-vault/app/models/store/source-transaction.store.model";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction/transaction.view";
import { BasiqMapType } from "@bitwarden/web-vault/app/models/types/basiq.types";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { boolean } from "mathjs";

@Injectable({
  providedIn: "root",
})
export class BasiqIoMapper {
  private userStoreService: UserStoreService;
  static _records: validationResult = {
    currencies: [],
    duplicated: [],
    newRecord: [],
    preview: [],
    newAccounts: [],
    noCurrencyTransactions: [],
  };
  static sourceTransactions: SourceTransaction[];
  static refresh = false;
  private categoryService: CategoryService;

  totalAmount = 0;
  pendingAmount = new GlossNumber();
  constructor() {
    this.userStoreService = inject(UserStoreService);
    this.categoryService = inject(CategoryService);
  }

  getCategoryNamesFromBasiqIoTransaction(rawBasiqIoTransaction: BasiqIoTransactionType): string[] {
    if (rawBasiqIoTransaction.enrich?.category?.anzsic?.class?.title) {
      return [rawBasiqIoTransaction.enrich.category.anzsic.class.title];
    }

    if (rawBasiqIoTransaction.subClass?.title) {
      return [rawBasiqIoTransaction.subClass.title];
    }

    if (rawBasiqIoTransaction.enrich?.tags?.length) {
      const clearNames: string[] = [];
      const categoryNamesArray: string[] = rawBasiqIoTransaction.enrich.tags.map((tag: string) => {
        const tagSplit = tag.split(":");
        if (tagSplit.length > 1 && isNaN(Number(tagSplit[1]))) {
          return tagSplit[1];
        }
        return "";
      });

      categoryNamesArray.forEach((item, index) => {
        if (item !== "" && !clearNames.includes(item)) {
          clearNames.push(item);
        }
      });
      return clearNames;
    }

    if (rawBasiqIoTransaction.enrich?.cleanDescription) {
      const categories: string[] = [];
      if (rawBasiqIoTransaction.enrich.cleanDescription.startsWith("TRANSFER TO")) {
        categories.push("Transfer");
        return categories;
      } else {
        return [rawBasiqIoTransaction.enrich.cleanDescription];
      }
    }

    return [];
  }

  /**TODO implement a mapper or maybe a better way to map transactions' data to proper categories*/
  getMappedCategoryName(categoryName: string) {
    if (categoryName === "Unknown") {
      return "Uncategorized";
    }

    return categoryName;
  }

  addNewCategory(splitCategories: SplitCategoryType[], mappedCategoryName: string) {
    const categoryStoreModel: CategoryStoreModel = {
      dc: new Date().toISOString(),
      dm: new Date().toISOString(),
      gendef: false,
      id: crypto.randomUUID(),
      n: mappedCategoryName,
      v: 1,
      vid: null,
      w: 1,
    };

    const categoryView = new CategoryView(categoryStoreModel);

    if (categoryView) {
      const newSplitCategory: SplitCategoryType = {
        categoryId: categoryStoreModel.id,
        category: categoryView,
        weight: 1,
        name: categoryView.name,
      };

      splitCategories.push(newSplitCategory);
    }

    return splitCategories;
  }

  addExistingCategory(splitCategories: SplitCategoryType[], existingCat: CategoryView) {
    const newSplitCategory: SplitCategoryType = {
      categoryId: existingCat.id,
      category: existingCat,
      weight: 1,
      name: existingCat.name,
    };

    splitCategories.push(newSplitCategory);

    return splitCategories;
  }

  addSourceCategory(
    splitCategories: SplitCategoryType[],
    sourceCategory: SourceCategoryView,
    mappedCategoryName: string,
  ) {
    const categories = this.userStoreService.categories.categoryViews();
    const existingCat = categories.find((cat) => cat.id === sourceCategory.categoryId);

    if (existingCat) {
      splitCategories = this.addExistingCategory(splitCategories, existingCat);
    } else {
      splitCategories = this.addNewCategory(splitCategories, mappedCategoryName);
    }
    return splitCategories;
  }

  toCategoryView(
    rawBasiqIoTransaction: BasiqIoTransactionType,
    processedCategories: CategoryView[],
  ): CategoryView[] {
    const categoryNames = this.getCategoryNamesFromBasiqIoTransaction(rawBasiqIoTransaction);
    const categoryViews: CategoryView[] = [];
    if (categoryNames.length) {
      for (const categoryName of categoryNames) {
        const mappedCategoryName: string = this.getMappedCategoryName(categoryName);
        const existingCategory = this.categoryService.categoryViewByName(mappedCategoryName);
        const processedCategory = processedCategories.find(
          (category) => category.name === mappedCategoryName,
        );
        if (processedCategory) {
          categoryViews.push(processedCategory);
        } else if (existingCategory) {
          processedCategories.push(existingCategory);
          categoryViews.push(existingCategory);
        } else {
          const newCategory = new CategoryView({
            dc: new Date().toISOString(),
            dm: new Date().toISOString(),
            gendef: false,
            id: crypto.randomUUID(),
            n: mappedCategoryName,
            v: 1,
            vid: null,
            w: 1,
          });
          processedCategories.push(newCategory);
          categoryViews.push(newCategory);
        }
      }
    }

    return categoryViews;
  }

  get records(): validationResult {
    return BasiqIoMapper._records;
  }

  mapBasiqData(
    accountView: AccountView,
    rawBasiqIoTransactions: BasiqIoTransactionType[],
  ): BasiqMapType {
    this.totalAmount = 0;
    const mappedBasiqData: BasiqMapType = {
      categories: [],
      transactions: [],
      sourceTransactions: [],
    };

    let hasValidBalanceOnTransactions = false;

    for (const t of rawBasiqIoTransactions) {
      if (!isNaN(Number(t.balance)) && Number(t.balance) !== 0) {
        hasValidBalanceOnTransactions = true;
        break;
      }
    }

    rawBasiqIoTransactions.forEach((rawBasiqIoTransaction) => {
      const categories = this.toCategoryView(rawBasiqIoTransaction, mappedBasiqData.categories);
      const transaction = this.toTransactionView(
        accountView,
        rawBasiqIoTransaction,
        categories,
        hasValidBalanceOnTransactions,
      );
      const sourceTransaction = this.toSourceTransactionView(accountView, transaction);
      transaction.sourceLink.id = sourceTransaction.id;

      mappedBasiqData.transactions.push(transaction);
      mappedBasiqData.sourceTransactions.push(sourceTransaction);

      this.totalAmount += Number(rawBasiqIoTransaction.amount);
    });

    return mappedBasiqData;
  }

  toTransactionView(
    accountView: AccountView,
    basiqTransaction: BasiqIoTransactionType,
    categories: CategoryView[],
    isValidBalanceOnTransactions: boolean,
  ): TransactionView {
    const transactionStoreModel: TransactionStoreModel = this.toStoreModel(
      accountView,
      basiqTransaction,
      categories,
      isValidBalanceOnTransactions,
    );

    return new TransactionView(transactionStoreModel);
  }

  toStoreModel(
    accountView: AccountView,
    basiqTransaction: BasiqIoTransactionType,
    categories: CategoryView[],
    isValidBalanceOnTransactions: boolean,
  ): TransactionStoreModel {
    const bankImportedBalance = isValidBalanceOnTransactions
      ? Number(basiqTransaction.balance)
      : undefined;
    return {
      acId: accountView.id,
      bIB: bankImportedBalance,
      bIBO: !isNaN(bankImportedBalance) ? Origin.basiq : null,
      cls: [],
      cts: categories.map((category) => {
        return {
          categoryId: category.id,
          category: category,
          weight: 1,
          name: category.name,
        };
      }),
      dc: new Date().toISOString(),
      dfn: TransactionStatusEnum.transaction,
      dir:
        basiqTransaction.direction === "credit"
          ? TransactionDirection.In
          : TransactionDirection.Out,
      dm: new Date().toISOString(),
      dsc: basiqTransaction.description,
      id: basiqTransaction.id,
      knd: basiqTransaction.type,
      lTid: [],
      qty: {
        qty: {
          amt: Math.abs(Number(basiqTransaction.amount)),
          prcs: 8,
          sym: accountView.currency,
        },
        cvrt: 1,
        cvsym: "",
        ccy: accountView.currency,
      },
      srId: null,
      tDt: {
        date:
          basiqTransaction.postDate || basiqTransaction.transactionDate || new Date().toISOString(),
        time: "", //Need to get the time from the transaction
        timeZone: basiqTransaction.timezone,
      },
      v: 2,
      vid: "",
      vlPc: 0,
    };
  }

  toSourceTransactionView(
    accountView: AccountView,
    transactionView: TransactionView,
  ): SourceTransactionsView {
    const transactionStoreModel: SourceTransactionStoreModel = this.toSourceStoreModel(
      accountView,
      transactionView,
    );
    return new SourceTransactionsView(transactionStoreModel);
  }

  toSourceStoreModel(
    accountView: AccountView,
    transaction: TransactionView,
  ): SourceTransactionStoreModel {
    return {
      dc: new Date().toISOString(),
      dm: null,
      id: crypto.randomUUID(),
      v: 1,
      vid: "",
      acId: transaction.accountLink.id,
      sym: accountView.currency,
      ccy: accountView.currency,
      dsc: transaction.description,
      knd: transaction.kind,
      dir: transaction.direction,
      bIB: transaction.bankImportedBalance,
      qty: transaction.quantity.actualQuantity.amount.toString(),
      prc: null,
      cts: transaction.categories.map((category) => category.name),
      cls: [],
      srId: transaction.id,
      dfn: transaction.definition,
      tDt: transaction.transactionDate.toISOString(),
    };
  }
}

import { VaultCodeEnum } from "./type/dali.type";
import { StoreModel } from "./vault-parser/parser.type";
import { CipherView } from "@bitwarden/common/src/models/view/cipher.view";

import { inject } from "@angular/core";
import { CipherService } from "@bitwarden/common/abstractions/cipher.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { VaultStatistic } from "@bitwarden/web-vault/app/services/dali/vault.statistics";
import { VaultModelUpgrader } from "@bitwarden/web-vault/app/services/dali/vault.model.upgrader";

export type VaultItemType = Record<string, any>;

export class VaultReader {
  protected cipherService: CipherService = inject(CipherService);
  protected log: LogService = inject(LogService);
  protected vaultStatistics: VaultStatistic = VaultStatistic.getInstance();
  protected vaultModelUpgrader: VaultModelUpgrader = VaultModelUpgrader.getInstance();

  async loadVault(): Promise<Map<VaultCodeEnum, StoreModel[]>> {
    const ciphers: CipherView[] = await this.cipherService.getAllDecrypted();

    const vaultStoreData: Map<VaultCodeEnum, StoreModel[]> = new Map();

    for (const cipher of ciphers) {
      /** Collect vault statistic **/

      let collections: VaultItemType[] = [];

      try {
        collections = JSON.parse(cipher.notes) as VaultItemType[];
      } catch (e) {
        this.log.error("Could not cast vault content fo JSON");
        this.log.error(e);
      }

      const itemProcessed = this.vaultModelUpgrader.parseVaultData(
        vaultStoreData,
        collections,
        cipher,
      );
      this.vaultStatistics.add(cipher.id, {
        itemCount: itemProcessed,
      });
    }

    return vaultStoreData;
  }

  protected getVaultObjectCode(cipherName: string): VaultCodeEnum {
    return <VaultCodeEnum>cipherName.split("-")[0];
  }
}

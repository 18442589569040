import { ParserAbstraction } from "./parser.abstraction";
import { ParserFunctionType } from "../parser.type";
import { SourceTransactionStoreModel } from "../../../../models/store/source-transaction.store.model";
import { SourceTransactionResponse } from "../../../../../../../../libs/common/src/models/response/source-transaction-response";
import { SourceTransaction } from "../../../../models/data/blobby/source-transaction.data";
import { sourceTransaction_v0_to_v1 } from "../model-dto/source-transaction";

export class SourceTransactionParser extends ParserAbstraction {
  override getParser(version: number): ParserFunctionType {
    if (!this.isSupportedVersion(version)) {
      throw new Error(`Unsupported VaultFile parser version ${version}`);
    }

    if (version === 0) {
      return SourceTransactionParser_v0;
    }
    return SourceTransactionParser_v1;
  }

  override getSupportedVersion(): number[] {
    return [0, 1];
  }
}
const SourceTransactionParser_v1 = (transactionModel: any): SourceTransactionStoreModel =>
  transactionModel as SourceTransactionStoreModel;
const SourceTransactionParser_v0 = (transactionModel: any): SourceTransactionStoreModel => {
  const response = new SourceTransactionResponse(transactionModel);
  const transaction = new SourceTransaction(response);
  const upgraded = sourceTransaction_v0_to_v1(transaction);
  /** We need to remap the vid properties as this is new and was not map in the previous blobby type **/
  upgraded.vid = transactionModel.vid ?? "";
  return SourceTransactionParser_v1(upgraded);
};

import { SourceTransaction } from "../../../../models/data/blobby/source-transaction.data";
import { SourceTransactionStoreModel } from "../../../../models/store/source-transaction.store.model";
export const sourceTransaction_v0_to_v1 = (
  sourceTransaction: SourceTransaction,
): SourceTransactionStoreModel => {
  return {
    acId: sourceTransaction.accountId,
    sym: sourceTransaction.symbol,
    ccy: sourceTransaction.currency,
    dsc: sourceTransaction.description,
    knd: sourceTransaction.kind,
    dir: sourceTransaction.direction,
    bIB: sourceTransaction.bankImportedBalance,
    qty: sourceTransaction.quantity.actualQuantity.amount.toString(),
    prc: sourceTransaction.price,
    cts: sourceTransaction.categories,
    cls: sourceTransaction.classifications,
    srId: sourceTransaction.sourceId,
    dfn: sourceTransaction.definition,
    tDt: sourceTransaction.transactionDate.dateString,
    v: 1,
    vid: "",
    dc: new Date().toString(),
    dm: new Date().toString(),
    id: sourceTransaction.id,
  };
};
